import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';

export interface ListAdminInterface {
  id: string;
  email: string;
}
type Hook = (
  setListAdmins: (val: SetStateAction<ListAdminInterface[]>) => void
) => [boolean, boolean];

const requestAdmin = () =>
  Axios.get<ListAdminInterface[]>(`${API_URL}/administrator`);

export const useListAdmins: Hook = setListAdmins => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestAdmin();

        setListAdmins(list);
        setIsError(false);
      } catch (err) {
        setListAdmins([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setListAdmins]);

  return [isLoading, isError];
};
