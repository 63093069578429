import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { ListGuidesAdminInterface } from '../../types/list-guide.interface';

type Hook = (
  setListGuides: (val: SetStateAction<ListGuidesAdminInterface[]>) => void,
  info: {
    search?: string;
    showOnlyToApprove?: boolean;
    intermediateId?: string;
    showWithoutBoarding?: boolean;
  }
) => [boolean, boolean];

const requestAdmin = (
  cancel: CancelToken | undefined,
  search: string,
  showOnlyToApprove: boolean,
  intermediateId: string,
  showWithoutBoarding: boolean
) =>
  Axios.get<ListGuidesAdminInterface[]>(`${API_URL}/guide`, {
    params: {
      search,
      showOnlyToApprove,
      intermediateId,
      showWithoutBoarding,
    },
    cancelToken: cancel,
  });

export const useListGuides: Hook = (
  setListGuides,
  { search, showOnlyToApprove, intermediateId, showWithoutBoarding }
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestAdmin(
          cancel.current?.token,
          search || '',
          !!showOnlyToApprove,
          intermediateId || '',
          !!showWithoutBoarding
        );

        setListGuides(list);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setListGuides([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [
    dispatch,
    setListGuides,
    search,
    showOnlyToApprove,
    intermediateId,
    showWithoutBoarding,
  ]);

  return [isLoading, isError];
};
