export enum ErrorCode {
  SKILL_EMPTY = 'SKILL_EMPTY',
  BAD_PROFILE_TYPE = 'BAD_PROFILE_TYPE',
  USER_ALREADY_ADMIN = 'USER_ALREADY_ADMIN',
  USER_NAME_ALREADY_EXIST = 'USER_NAME_ALREADY_EXIST',
  LANGUAGES_EMPTY = 'LANGUAGES_EMPTY',
  DUPLICATED_SKILLS = 'DUPLICATED_SKILLS',
  DUPLICATED_LANGUAGES = 'DUPLICATED_LANGUAGES',
  USER_IS_GUIDE = 'USER_IS_GUIDE',
  BAD_DATE_SHOULD_BE_GREATER_THEN_CURRENT_DATE = 'BAD_DATE_SHOULD_BE_GREATER_THEN_CURRENT_DATE',
  INVALID_DATE = 'INVALID_DATE',
  SCHEDULE_CONFLICT = 'SCHEDULE_CONFLICT',
  ROUTE_SUBJECT_NAME_EMPTY = 'ROUTE_SUBJECT_NAME_EMPTY',
  USER_IS_VISITOR = 'USER_IS_VISITOR',
  DTO_IS_EMPTY = 'DTO_IS_EMPTY',
  ERROR_VALIDATING_DTO = 'ERROR_VALIDATING_DTO',
  EMPTY_SIGN_UP_GUIDE = 'EMPTY_SIGN_UP_GUIDE',
  EMPTY_SIGN_UP_VISITOR = 'EMPTY_SIGN_UP_VISITOR',
  RBAC_EMPTY = 'RBAC_EMPTY',
  NO_AGREEMENT = 'NO_AGREEMENT',
  DUPLICATED_SUBJECT = 'DUPLICATED_SUBJECT',
  NO_EMAIL_FOUND = 'NO_EMAIL_FOUND',
  GUIDE_DOES_NOT_OWN_ROUTE = 'GUIDE_DOES_NOT_OWN_ROUTE',
  ERROR_PARSING_FILES = 'ERROR_PARSING_FILES',
  BAD_BOOKING_DATE = 'BAD_BOOKING_DATE',
  BAD_SCHEDULE_ID = 'BAD_SCHEDULE_ID',
  VISITOR_ALREADY_BOOKED = 'VISITOR_ALREADY_BOOKED',
  BAD_ID = 'BAD_ID',
  BOOKING_ALREADY_REVIEWED = 'BOOKING_ALREADY_REVIEWED',
  UNDERAGE_USER = 'UNDERAGE_USER',
  PARSING_IMAGES = 'PARSING_IMAGES',
  DUPLICATED_ORDER = 'DUPLICATED_ORDER',
  ERROR_INSERTING_NEW_LOCATION = 'ERROR_INSERTING_NEW_LOCATION',
  EMAIL_NOT_CONFIRMED = 'EMAIL_NOT_CONFIRMED',
  NOT_APPROVED = 'NOT_APPROVED',
  USER_IS_COMPANY = 'USER_IS_COMPANY',
  LANDING_PAGE_NEED_TRANSLATION = 'LANDING_PAGE_NEED_TRANSLATION',
  EMAIL_REGISTERED = 'EMAIL_REGISTERED',
  EMAIL_PROFILE_ALREADY_REGISTERED = 'EMAIL_PROFILE_ALREADY_REGISTERED',
  EMAIL_ALREADY_REGISTERED_WITH_EXTERNAL_PROVIDER = 'EMAIL_ALREADY_REGISTERED_WITH_EXTERNAL_PROVIDER',
  PROFILE_TYPE_NOT_FOUNDED = 'PROFILE_TYPE_NOT_FOUNDED',
}
