import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Rate from 'antd/es/rate';
import { Moment } from 'moment';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { FallbackAvatar } from '../../../components/App/Avatar';
import { FallbackImage } from '../../../components/App/Image';
import { Text } from '../../../components/Typography';
import { ReactComponent as Visitante } from '../../../shared_assets/images/visitante_default.svg';
import { mobileThreshhold } from '../../../utils';

const useStyles = makeStyles({
  card: {
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    margin: '1rem 0',
    '& > .ant-divider': {
      margin: '1rem 0',
    },
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.1)',
    '&:last-child': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
  header: {
    display: 'flex',
    '& .left-section': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  },
  rate: {
    fontSize: 14,
    marginBottom: '0.5rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    avatar: {
      height: '3rem',
      width: '3rem',
    },
  },
});

interface CommentCardProps {
  visitorName: string;
  visitorImage: string;
  date: Moment;
  rating: number;
  description: string;
  routeTitle: string;
}

const CommentCard: React.FC<CommentCardProps> = ({
  visitorImage,
  visitorName,
  date,
  rating,
  description,
  routeTitle,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <div className="left-section">
          <Text noMargin weight="bold">
            {visitorName}
          </Text>
          <Text noMargin variant="faded">
            {date.format('LL')}
          </Text>
        </div>
        <FallbackAvatar
          className={classes.avatar}
          size="large"
          src={
            <FallbackImage
              src={visitorImage}
              fallbackComponent={<Visitante />}
            />
          }
        />
      </div>
      <Divider />
      <Rate className={classes.rate} disabled value={rating} />
      <Text noMargin>{description}</Text>
      <Divider />
      <Text noMargin variant="faded">
        <Translate id="reviews.routeTitleLabel" />
      </Text>
      <Text noMargin weight="bold">
        {routeTitle}
      </Text>
    </div>
  );
};

export default React.memo(CommentCard);
