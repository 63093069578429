import Axios from 'axios';
import { API_URL } from '../../consts';
import moment from 'moment';
import { useState, useEffect, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

export interface GuideLandingPageInterface {
  firstTitle: string;
  thinCallout: string;
  intro: string;
  whatIsTitle: string;
  carouselText: string;
  areaTitle: string;
  subjectTitle: string;
  lookForTitle: string;
  faqTitle: string;
  otherExperiencesTitle: string;
  language: string;
}

export interface LandingPageDto {
  landing: GuideLandingPageInterface[];
}

type Hook = (
  setListLandingPage: (val: SetStateAction<GuideLandingPageInterface[]>) => void
) => [boolean, boolean];

const requestLandingPageInformation = () =>
  Axios.get<GuideLandingPageInterface[]>(
    `${API_URL}/content-management/guide-landing-page`
  );

export const useGuideLandingPage: Hook = setGuideLandingPage => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestLandingPageInformation();
        setGuideLandingPage(list);
        setIsError(false);
      } catch (err) {
        setGuideLandingPage([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setGuideLandingPage]);

  return [isLoading, isError];
};
export const getHeaderImageUrl = () =>
  `${API_URL}/content-management/guide-landing-page/header-image?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
