import React from 'react';
import Typography from 'antd/es/typography';
import { motion, Variants } from 'framer-motion';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

export interface AnimatedErrorProps {
  reason?: string | React.ReactNode;
  isVisible?: boolean;
  className?: string;
}

const variants: Variants = {
  enter: {
    height: 'unset',
  },
  exit: {
    height: 0,
  },
};

const useStyles = makeStyles({
  transformSettings: {
    overflow: 'hidden',
    marginBottom: '1em',
  },
  paragraph: {
    marginBottom: '0px !important',
  },
});

export default function AnimatedError(
  { reason, isVisible, className }: AnimatedErrorProps = {
    reason: '',
    isVisible: false,
  }
) {
  const classes = useStyles();
  return (
    <motion.div
      animate={isVisible ? 'enter' : 'exit'}
      className={classNames(classes.transformSettings, className)}
      variants={variants}
    >
      <Typography.Paragraph className={classes.paragraph} type="danger">
        {reason}
      </Typography.Paragraph>
    </motion.div>
  );
}
