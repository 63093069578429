import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import { ReactComponent as Edit } from '../../../shared_assets/icons/edit.svg';
import { RootState } from '../../../store';
import { RouteActivity } from '../../../types/route-activity.interface';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';

const useStyles = makeStyles({
  container: {
    height: 40,
    borderRadius: 5,
    border: `2px ${getTheme().neutral4} solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0',
  },
});

interface ActivityEntryProps {
  activity: RouteActivity;
  onRequestActivityChange: () => void;
}

const ActivityEntry: React.FC<ActivityEntryProps> = ({
  activity,
  onRequestActivityChange,
}) => {
  const classes = useStyles();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );

  const activityName = useMemo(() => {
    const activityName = activity.descriptions.find(
      desc => desc.language === activeLanguage
    )?.description;
    if (activityName) return activityName;
    else return activity.descriptions[0].description;
  }, [activity, activeLanguage]);

  return (
    <div className={classes.container}>
      <Text noMargin inline>
        {`${activityName} (${activity.price}€)`}
      </Text>
      <Button
        type="link"
        onlyText
        onClick={onRequestActivityChange}
        prefix={<Edit />}
      >
        <Translate id="button.edit" />
      </Button>
    </div>
  );
};

export default ActivityEntry;
