import { makeStyles } from '@material-ui/styles';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Button as LinkButton } from '../../../../../components/App/Button';
import { FallbackImage } from '../../../../../components/App/Image';
import { TextArea } from '../../../../../components/App/Input';
import { TabPane, Tabs } from '../../../../../components/App/Tabs';
import { Text } from '../../../../../components/Typography';
import { postSubject } from '../../../../../hooks/administration/usePostSubject';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as Pediguia } from '../../../../../shared_assets/images/pediguia_default.svg';
import { TranslationInterface } from '../../../../../types/translation.interface';
import {
  complexTranslate,
  desktopPadding,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

// ANCHOR: IMPORTS
const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  headerDivider: {
    marginTop: 0,
  },

  divider: {
    margin: '1rem 0',
  },

  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
    noMargin: {
      margin: 0,
    },
    inlineBtn: {
      display: 'inline',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  },

  headerArea: {
    display: 'flex',
  },

  avatarArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarPreview: {
    maxHeight: '25rem',
    marginBottom: '1rem',
  },

  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5rem',
  },
});
interface ParamsInterface {
  subjectId: string;
}
interface InformationProps extends LocalizeContextProps {}
const CreateSubject = ({ languages, translate }: InformationProps) => {
  const classes = useStyles();

  const [jwt] = UseJWT();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const previewSource = useMemo(() => [image], [image]);
  const imagePreview = useFilePreview(previewSource);
  const [translations, setTranslations] = useState<TranslationInterface[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  // ANCHOR: VARIABLES
  useEffect(() => {
    setTranslations([]);
    setSelectedLanguages(languages.map(l => l.code)); //TODO: PERGUNTAR AO XYMPA COMO POR AQUI O ARRAY
  }, [languages]);
  //Quando mudam linguas cria descrições novas mas mantem as existentes

  useEffect(() => {
    setTranslations(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, translation: '' })),
    ]);
  }, [selectedLanguages]);

  const error = useMemo(() => {
    return translations.some(t => t.translation === '');
  }, [translations]);
  const submit = useCallback(async () => {
    if (!jwt) return;

    if (error) {
      message.error(translate('adminstration.subjectMandatory').toString());
      return;
    }
    if (image === null) {
      message.error(translate('adminstration.imageForce').toString());
      return;
    }
    try {
      await postSubject(
        {
          subjects: [
            {
              translations: translations.map(t => {
                return { language: t.language, description: t.translation };
              }),
            },
          ],
        },
        image
      );

      message.success(
        translate('adminstration.subjectCreateSuccess').toString()
      );
    } catch (err) {
      message.success(translate('adminstration.subjectCreateError').toString());
    } finally {
    }
  }, [error, image, jwt, translate, translations]);

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.avatarArea}>
        <FallbackImage
          className={classes.avatarPreview}
          src={imagePreview?.[0] || undefined}
          fallbackComponent={<Pediguia className={classes.avatarPreview} />}
        />
        <div className={classes.changeAvatarButton}>
          <Translate id="adminstration.changeSubjectImage" />
          <input
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <LinkButton
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </LinkButton>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
        </div>
      </div>

      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.bodyContainer}>
          <div className={classes.infoZone}>
            {/* DESCRIPTION */}
            <Text weight="semibold">
              <Translate id="adminstration.subjectTranslations" />
            </Text>
            <Tabs>
              {translations.map(iteratedLanguage => (
                <TabPane
                  key={iteratedLanguage.language}
                  tab={translate(`languages.${iteratedLanguage.language}`)}
                >
                  <TextArea
                    value={iteratedLanguage.translation}
                    rows={1}
                    onChange={ev => {
                      ev.persist();
                      setTranslations(previousDescriptions =>
                        previousDescriptions.map(oldDescription => {
                          if (
                            oldDescription.language ===
                            iteratedLanguage.language
                          )
                            return {
                              ...oldDescription,
                              translation: ev.target.value,
                            };
                          else return oldDescription;
                        })
                      );
                    }}
                  />
                  <div>
                    <AnimatedError
                      isVisible={validator.isEmpty(
                        iteratedLanguage.translation
                      )}
                      reason={<Translate id="adminstration.subjectMandatory" />}
                    />
                  </div>
                </TabPane>
              ))}
            </Tabs>

            <div className={classes.submitButton}>
              <Button
                onClick={() => {
                  submit();
                }}
                type="primary"
              >
                <Translate id="adminstration.submitSubjectCreation" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(CreateSubject);
