import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../../consts';
import Axios from 'axios';
import { Review } from '../../../types/review.interface';

type Hook = (
  guideId: string | undefined,
  setReviews: (val: SetStateAction<Review[]>) => void
) => [boolean, boolean];

const requestGuideReviews = (guideId: string) =>
  Axios.get<Review[]>(`${API_URL}/review/guide/${guideId}`);

export const useGuideReviews: Hook = (guideId, setReviews) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!guideId) {
        setIsError(true);
        setReviews([]);
        setIsLoading(false);
        return;
      }

      try {
        const { data } = await requestGuideReviews(guideId);
        setReviews(data);
        setIsError(false);
      } catch (err) {
        setReviews([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [guideId, setReviews]);

  return [isLoading, isError];
};
