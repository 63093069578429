import Axios from 'axios';
import { API_URL } from '../../consts';

export interface GuideLandingPageDto {
  firstTitle: string;
  thinCallout: string;
  intro: string;
  whatIsTitle: string;
  carouselText: string;
  areaTitle: string;
  subjectTitle: string;
  otherExperiencesTitle: string;
  lookForTitle: string;
  faqTitle: string;
  language: string;
}
export interface LandingPageDto {
  landing: GuideLandingPageDto[];
}
export const postGuideLandingPage = (
  dto: LandingPageDto,
  image?: File | null
) => {
  const formData = new FormData();
  formData.append(
    'createGuideLandingPageDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (image) {
    formData.append('headerImage', image);
  }
  return Axios({
    method: 'post',
    url: `${API_URL}/content-management/guide-landing-page`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
