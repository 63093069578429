/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/styles';
import { Divider, message, Space, Table, Tooltip } from 'antd';
import Button from 'antd/es/button';
import Axios from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';
import Badge2 from '../../../../../components/App/Badge2/Badge2';
import { Checkbox } from '../../../../../components/App/Checkbox';
import { Input } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import { useListGuides } from '../../../../../hooks/administration/useListGuides';
import { ReactComponent as Canceled } from '../../../../../shared_assets/icons/canceled.svg';
import { ReactComponent as Complete } from '../../../../../shared_assets/icons/complete.svg';
import { GuideStateType } from '../../../../../types/guide-state-type.enum';
import { ListGuidesAdminInterface } from '../../../../../types/list-guide.interface';
import { mobileThreshhold } from '../../../../../utils';
const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  filterZone: {
    '& > *:nth-child(2)': { flexGrow: 1, marginLeft: '1rem' },
    display: 'flex',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});

interface ParamsInterface {
  id: string;
}
const ListGuides = ({ translate }: LocalizeContextProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [guides, setListGuides] = useState<ListGuidesAdminInterface[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showOnlyToApprove, setShowOnlyToApprove] = useState<boolean>(false);
  const [showWithoutBoarding, setShowWithoutBoarding] = useState<boolean>(true);
  const [guideIdRemove, setGuideIdRemove] = useState<string>('');
  const dto = useMemo(
    () => ({
      search,
      showOnlyToApprove,
      showWithoutBoarding,
    }),
    [search, showOnlyToApprove, showWithoutBoarding]
  );
  const [fetchingGuides] = useListGuides(setListGuides, dto);

  const removeGuide = useCallback(async () => {
    try {
      const { data: currentGuides } = await Axios.delete(
        `${API_URL}/guide/remove-guide/${guideIdRemove}`
      );
      setListGuides(currentGuides as ListGuidesAdminInterface[]);
    } catch (error) {
      message.error(error.response.data.error);
    }
  }, [guideIdRemove]);
  const columns = useMemo(() => {
    return [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => {
          switch (text) {
            case GuideStateType.APPROVED:
              return (
                <Badge2
                  key={record.key}
                  text={translate('adminstration.guideApproved').toString()}
                  type="success"
                />
              );
            case GuideStateType.NOT_APPROVED:
              return (
                <Badge2
                  key={record.key}
                  text={translate('adminstration.guideNotApproved').toString()}
                  type="error"
                />
              );
            case GuideStateType.CANCELED:
              return (
                <Badge2
                  key={record.key}
                  text={translate('adminstration.guideCanceled').toString()}
                  type="error"
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  text={translate(
                    'adminstration.guideWaitingApproval'
                  ).toString()}
                  type="warning"
                />
              );
          }
        },

        width: '25%',
      },
      {
        title: <Translate id="adminstration.name" />,
        dataIndex: 'name',
        key: 'name',
      },

      {
        title: <Translate id="adminstration.gender" />,
        dataIndex: 'gender',
        key: 'gender',
      },

      {
        title: <Translate id="adminstration.email" />,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: <Translate id="adminstration.phoneNumber" />,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
      },
      {
        title: <Translate id="adminstration.companyName" />,
        dataIndex: 'companyName',
        key: 'companyName',
      },
      {
        title: <Translate id="adminstration.nif" />,
        dataIndex: 'nif',
        key: 'nif',
      },
      {
        title: <Translate id="adminstration.magnifinanceStatus" />,
        dataIndex: 'partner',
        key: 'partner',
        render: (value: any) => {
          if (!value) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartner" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else if (!value.magnifinanceToken) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartnerToken" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={<Translate id="adminstration.magnifinanceOk" />}>
                <Complete style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          }
        },
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <Link to={`/admin/area/guide/${record.id}`}>
              <Translate id="adminstration.showInformation" />
            </Link>
            <Divider type="vertical" />

            <Link to={`/admin/area/guide/${record.id}/list/routes`}>
              <Translate id="adminstration.showRoutes" />
            </Link>
            <Divider type="vertical" />

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={() => setGuideIdRemove(record.id)}>
              <Translate id="adminstration.removeGuide" />
            </a>
          </Space>
        ),
      },
    ];
  }, [translate]);

  const createGuideCallback = useCallback(() => {
    history.push('/admin/area/create-guide');
  }, [history]);

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setGuideIdRemove('');
              }}
            >
              <Translate id="button.cancel" />
            </Button>
            <div className={classes.addRemoveBtn}>
              <Button
                type="primary"
                onClick={() => {
                  removeGuide();
                  setGuideIdRemove('');
                }}
              >
                <Translate id="routeDetails.yes" />
              </Button>
            </div>
          </div>
        }
        visible={guideIdRemove !== '' ? true : false}
        closable={false}
      >
        <Text color={'red'} noMargin weight="semibold">
          <Translate id="adminstration.removeGuideWarning" />
        </Text>
      </Modal>
      <div className={classes.divAddButton}>
        <div className={classes.filterZone}>
          <Input
            value={search}
            placeholder={translate('adminstration.search').toString()}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <Checkbox
            checked={showOnlyToApprove}
            onChange={e => setShowOnlyToApprove(e.target.checked)}
          >
            <Translate id="adminstration.showOnlyNotApproved" />
          </Checkbox>
        </div>
        <div>
          <Button
            style={{ marginRight: '2rem' }}
            type="primary"
            size={'large'}
            href={`${API_URL}/administrator/export/guide-list`}
          >
            <Translate id="adminstration.exportGuideList" />
          </Button>
          <Button
            type="primary"
            size={'large'}
            onClick={ev => {
              createGuideCallback();
            }}
          >
            <Translate id="adminstration.createGuide" />
          </Button>
        </div>
      </div>

      {fetchingGuides ? (
        <Loader />
      ) : (
        <Table dataSource={guides} columns={columns} />
      )}
    </>
  );
};

export default withLocalize(ListGuides);
