/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import Axios from 'axios';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import validator from 'validator';
import { CompanyRegisterForm } from '../../../../../components/Administration/CompanyRegisterForm';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Error } from '../../../../../components/App/Error';
import { TextArea } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { Heading, Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { Company, useCompany } from '../../../../../hooks/company/useCompany';
import { setMarkerCount } from '../../../../../store/App/actions';
import { AdminStats } from '../../../../../store/App/types';
import { GuideStateType } from '../../../../../types/guide-state-type.enum';
import { RoleType } from '../../../../../types/role-type.enum';
import {
  desktopPadding,
  getTheme,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

interface ErrorInterface {
  error: boolean;
  reason: string;
}
interface EvaluateDto {
  state: GuideStateType;
  description: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
const useStyles = makeStyles({
  passwordInput: {
    maxWidth: '300px',
    marginRight: '1rem',
  },

  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: '25%',
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    flex: '1',
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },
  disableTextColor: {
    color: 'black !important',
  },
  divisorAvatar: {
    paddingBottom: '10px',
  },
  headerArea: {
    display: 'flex',
  },
  buttonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0.5',
  },
  avatarArea: {
    display: 'flex',
    height: '100px',
  },
  avatarPreview: {
    height: '100px',
    width: '100px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  evaluateApproveButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
    marginLeft: '50px',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  select: {
    minWidth: '300px',
    color: 'black !important',
  },
  spacer: {
    margin: '0 1rem',
  },
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // ANCHOR: CSS
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  invisible: {
    display: 'none',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },

  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
});
interface ParamsInterface {
  id: string;
}

interface InformationProps extends LocalizeContextProps {}
const CompanyInformation = ({ translate }: InformationProps) => {
  const params = useParams<ParamsInterface>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [jwt] = UseJWT();

  useState<boolean>(false);

  const [reason, setReason] = useState('');

  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [evaluateCompany, setEvaluateCompany] = useState<boolean>(false);

  const [fetchingCompany, errorFetchingCompany] = useCompany(
    params ? params.id : '',
    setCompany
  );

  const showEvaluateModal = useCallback(() => {
    setEvaluateCompany(true);
  }, []);
  const evalError = useMemo((): ErrorInterface => {
    if (validator.isEmpty(reason)) {
      return {
        error: true,
        reason: 'Reason should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [reason]);

  //****************************************************************** */
  const approveCompanyCallBack = useCallback(async () => {
    if (!company) return;
    try {
      await Axios.patch(`${API_URL}/company/${params.id}/evaluation`, {
        state: GuideStateType.APPROVED,
        description: 'APPROVED',
      });

      const { data } = await Axios.get<AdminStats>(
        `${API_URL}/administrator/stats`
      );
      dispatch(setMarkerCount(data as AdminStats));
      setReason('');
      setEvaluateCompany(false);
      message.success(translate('adminstration.evalCompany').toString());
    } catch (err) {
      if (err.response.status === 409) {
        message.error(err.response.data.message);
      }
    }
  }, [company, params.id, dispatch, translate]);
  const noApproveGuideCallBack = useCallback(async () => {
    if (!company) return;
    if (evalError.error) return;
    try {
      await Axios.patch(`${API_URL}/company/${params.id}/evaluation`, {
        state: GuideStateType.NOT_APPROVED,
        description: reason,
      });
      setReason('');
      setEvaluateCompany(false);
      message.success(translate('adminstration.evalCompany').toString());
    } catch (err) {}
  }, [company, evalError.error, params.id, reason, translate]);

  if (!company || fetchingCompany) {
    return <Loader />;
  }
  if (!jwt) {
    return <Redirect to="/company/landing" />;
  }

  if (errorFetchingCompany) return <Error />;
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.headerArea}>
          <Heading className={classes.title} level={2}>
            <Translate id="adminstration.profileOf" /> {`${company.name}`}
          </Heading>
        </div>

        <div className={classes.divisorAvatar} />
        <div className={classes.bodyContainer}>
          <CompanyRegisterForm company={company} editable={false} />
          <div className={classes.infoZone}>
            {jwt.roles.includes(RoleType.ADMINISTRATOR) ? (
              <>
                <div className={classes.evaluateButton}>
                  {/* //ANCHOR MODAL */}
                  <Modal
                    fullScreenOnMobile={false}
                    contentContainerClassName={classes.modalContent}
                    headerTitle={<Translate id="button.addSkill" />}
                    footer={
                      <div className={classes.footer}>
                        <Button
                          onClick={() => {
                            setReason('');
                            setEvaluateCompany(false);
                          }}
                        >
                          <Translate id="button.cancel" />
                        </Button>
                        <div className={classes.addRemoveBtn}>
                          <Button
                            type="primary"
                            onClick={() => {
                              noApproveGuideCallBack();
                            }}
                          >
                            <Translate id="button.save" />
                          </Button>
                        </div>
                      </div>
                    }
                    visible={evaluateCompany}
                    closable={false}
                  >
                    <Text weight="semibold">
                      <Translate id="adminstration.reason" />
                    </Text>
                    <TextArea
                      value={reason}
                      rows={10}
                      onChange={ev => {
                        ev.persist();
                        setReason(ev.target.value);
                      }}
                    />
                    <AnimatedError
                      isVisible={evalError.error}
                      reason={evalError.reason}
                    />
                  </Modal>
                  <Button
                    type="primary"
                    shape="round"
                    className={classes.cancelButton}
                    size={'large'}
                    onClick={ev => {
                      showEvaluateModal();
                    }}
                  >
                    <Translate id="adminstration.notApprove" />
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    className={classes.evaluateApproveButton}
                    size={'large'}
                    onClick={ev => {
                      approveCompanyCallBack();
                    }}
                  >
                    <Translate id="adminstration.approve" />
                  </Button>
                </div>
                <Divider className={classes.divider} />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(CompanyInformation);
