import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../components/App/AnimatedError';
import { Button } from '../../../../components/App/Button';
import { Input } from '../../../../components/App/Input';
import { Modal } from '../../../../components/App/Modal';
import { Option, Select } from '../../../../components/App/Select';
import { addHelp, AddHelpResponse } from '../../../../hooks/help/addHelp';
import { editHelp, EditHelpResponse } from '../../../../hooks/help/editHelp';
import { HelpResponse, HelpType } from '../../../../hooks/help/useHelp';

const useStyles = makeStyles({
  footer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: 20,
  },
});

interface HelpModalProps extends LocalizeContextProps {
  visible: boolean;
  onRequestClose: () => void;
  helpContent?: HelpResponse[0];
  onAdd?: (v: AddHelpResponse) => void;
  onEdit?: (v: EditHelpResponse) => void;
}

const HelpModal = ({
  visible,
  helpContent,
  onRequestClose,
  onAdd,
  onEdit,
  translate,
}: HelpModalProps) => {
  const [content, setContent] = useState<string>('');
  const [type, setType] = useState<HelpType>(HelpType.EMAIL);
  const classes = useStyles();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (helpContent) {
      setContent(helpContent.content);
      setType(helpContent.type);
    } else {
      setContent('');
      setType(HelpType.EMAIL);
    }
  }, [helpContent, visible]);

  const action = useCallback(async () => {
    try {
      setSubmitting(true);
      if (helpContent) {
        //edit

        const { data } = await editHelp({
          content,
          type,
          id: helpContent.id,
        });

        if (onEdit) onEdit(data);
      } else {
        //add
        const { data } = await addHelp({
          content,
          type,
        });

        if (onAdd) onAdd(data);
      }
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [helpContent, content, type, onAdd, onEdit]);

  const error = useMemo(() => {
    if (type === HelpType.EMAIL) {
      if (!validator.isEmail(content)) {
        return translate('adminstration.helpZone.badEmail');
      }
    }
  }, [type, content, translate]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      footer={
        <div className={classes.footer}>
          <Button
            type="primary"
            disabled={!!error || submitting}
            onClick={action}
          >
            <Translate id="button.save" />
          </Button>
          <Button disabled={submitting} type="primary" onClick={onRequestClose}>
            <Translate id="button.cancel" />
          </Button>
        </div>
      }
      visible={visible}
    >
      <div className={classes.content}>
        <Input
          value={content}
          onChange={e => {
            setContent(e.target.value);
          }}
        />
        <AnimatedError isVisible={!!error} reason={error} />
        <Select
          value={type}
          style={{ minWidth: '10rem' }}
          onChange={e => {
            setType(e.toString() as HelpType);
          }}
        >
          {Object.values(HelpType).map(h => (
            <Option value={h}>
              <Translate id={`adminstration.helpZone.types.${h}`} />
            </Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};

export default React.memo(withLocalize(HelpModal));
