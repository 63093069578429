import Axios from 'axios';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { store } from '../../store';
import { BookingState } from '../../types/booking-state.enum';
import { Title } from '../../types/title.interface';

interface PaymentFilter {
  from: Date;
  to: Date;
  routeId?: string;
}
export interface BookingsPaymentInterface {
  visitorId: string;
  visitorName: string;
  groupSize: number;
  bookingState: BookingState;
  maxVisitors: number;
}
interface Payment {
  routeTitles: Title[];
  id: string;
  amount: number;
  date: Date;
  invoiceDocumentId: number;
  invoiceDocument: boolean;
  bookings: BookingsPaymentInterface[];
}

type Hook = (filter: PaymentFilter) => [boolean, boolean, Payment[]];

const requestGuidePayments = (dto: PaymentFilter) =>
  Axios.get<Payment[]>(`${API_URL}/guide/payments`, {
    params: dto,
  });

export const useGuidePayments: Hook = filters => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState<Payment[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await requestGuidePayments(filters);
        setPayments(data);
        setIsError(false);
      } catch (err) {
        setPayments([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [filters]);

  return [isLoading, isError, payments];
};

export const getGuidePaymentsExportUrl = (filters: PaymentFilter) =>
  `${API_URL}/guide/payments-export?${qs.stringify({
    ...filters,
    auth_token: store.getState().userConfiguration.jwt,
  })}`;
