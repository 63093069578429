import { makeStyles } from '@material-ui/styles';
import ANTDCheckbox, { CheckboxProps as ANTDCheckboxProps } from 'antd/es/checkbox';
import classNames from 'classnames';
import React from 'react';

import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  check: () => ({
    '&:hover, & .ant-checkbox:hover .ant-checkbox-inner': {
      borderColor: getTheme().primary,
    },
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: getTheme().primary,
      borderColor: getTheme().primary,
    },
    '& .ant-checkbox-inner': {
      borderColor: getTheme().neutral4,
      borderRadius: 3,
      borderWidth: 2,
      '&::after': {
        left: '20%',
      },
    },
  }),
});
interface CheckboxProps extends ANTDCheckboxProps {}

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ANTDCheckbox className={classNames(className, classes.check)} {...rest}>
      {children}
    </ANTDCheckbox>
  );
};

export default React.memo(Checkbox);
