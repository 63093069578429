import { makeStyles } from '@material-ui/styles';
import Empty from 'antd/es/empty';
import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { CopyrightFooter } from '../../components/App/CopyrightFooter';
import { Error } from '../../components/App/Error';
import Loader from '../../components/App/Loader/Loader';
import NotificationComponent from '../../components/App/Notification/Notification';
import { Heading } from '../../components/Typography';
import { UseJWT } from '../../hooks/authentication/UseJWT';
import {
  NotificationOwnerType,
  useNotifications,
} from '../../hooks/notifications/useNotifications';
import { ReactComponent as NotificationBlue } from '../../shared_assets/icons/notification_blue.svg';
import { ReactComponent as Pedirota } from '../../shared_assets/icons/pedirota.svg';
import { GenericNotification } from '../../types/notification.interface';
import {
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../utils';
import { GenericPageTransition } from '../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    ...mobilePadding,
    '& .ant-divider': {
      margin: '1rem 0',
    },
    '& .ant-empty': {
      margin: '5rem 0',
    },
    '& .title': {
      display: 'flex',
      margin: '1.5rem 0',
      alignItems: 'center',
      '& > img, & > svg': {
        marginRight: '1rem',
        width: '40px',
      },
    },
  },
  marginLeft: {
    marginLeft: '0.5rem',
  },
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0.5rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    notificationsContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 56px',
    },
    container: {
      ...desktopPadding,
    },
  },
});

interface NotificationsProps extends LocalizeContextProps {
  user: NotificationOwnerType;
}

const Notifications = ({ translate, user }: NotificationsProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();
  const isMobile = useIsMobile();

  const [notifications, setNotifications] = useState<GenericNotification[]>([]);
  const removeNotifications = useCallback((id: string) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notif => notif.notificationId !== id)
    );
  }, []);

  const markNotificationAsRead = useCallback((id: string) => {
    setNotifications(prevNotifications =>
      prevNotifications.map(not =>
        not.notificationId === id ? { ...not, seen: true } : not
      )
    );
  }, []);

  const [isLoading, isError] = useNotifications(
    {
      id: user === 'GUIDE' ? jwt?.guideId : jwt?.visitorId,
      type: user,
      infinite: true,
      size: 5,
    },
    setNotifications
  );
  if (isLoading && notifications.length === 0) return <Loader />;

  if (isError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.container}>
        <div className={'title'}>
          {!isMobile && <NotificationBlue />}
          <Heading level={2} noMargin>
            {translate('notifications.title').toString()}
          </Heading>
        </div>
        <div className={classes.notificationsContainer}>
          {notifications.length > 0 ? (
            notifications.map(notification => (
              <NotificationComponent
                onHide={removeNotifications}
                onRead={markNotificationAsRead}
                key={notification.notificationId}
                notification={notification}
              />
            ))
          ) : (
            <Empty
              image={<Pedirota />}
              description={translate(
                'notifications.dontExistNotifications'
              ).toString()}
            />
          )}
          {isLoading && <Loader />}
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
      <CopyrightFooter showSocialLinks />
    </motion.div>
  );
};

export default withLocalize(Notifications);
