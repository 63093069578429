import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { VisitorBookingInterface } from '../../../hooks/visitor/booking/useVisitorBookings';
import { ReactComponent as PediRota } from '../../../shared_assets/images/pedirota.svg';
import { mobileThreshhold } from '../../../utils';
import { Text } from '../../Typography';
import VisitorBooking from './VisitorBooking';

export interface VisitorBookingListProps {
  data: VisitorBookingInterface[];
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  previewImage: {
    maxWidth: '50%',
    marginBottom: '1rem',
    objectFit: 'cover',
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  bookingsContainer: {
    flex: 1,
    '& > div': {},
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      padding: '0 54px',
    },
  },

  headerDivider: {
    marginTop: 0,
    marginBottom: '1rem',
  },
});

function VisitorBookingList({ data }: VisitorBookingListProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {data.length > 0 ? (
        <>
          <div className={classes.bookingsContainer}>
            {data.map((preview, i) => (
              <React.Fragment key={i.toString()}>
                <VisitorBooking {...preview} />
                <Divider />
              </React.Fragment>
            ))}
          </div>
        </>
      ) : (
        <div className={classes.emptyContainer}>
          <PediRota className={classes.previewImage} />
          <Text variant="faded">
            <Translate id="booking.withoutBookings" />
          </Text>
        </div>
      )}
    </div>
  );
}

export default VisitorBookingList;
