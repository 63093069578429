import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UseJWT } from '../../hooks/authentication/UseJWT';
import { NotificationOwnerType } from '../../hooks/notifications/useNotifications';
import { setLastAppZone } from '../../store/App/actions';
import { About } from '../About';
import { Notifications } from '../Notification';
import { RegistrationSuccess } from '../Registration';
import { BookingRouteRouter } from './BookingRoute';
import { BookingCancelConfirmed } from './BookingRoute/Cancel/Confirmed';
import { Invoices } from './Invoices';
import { VisitorLanding } from './VisitorLanding';
import { VisitorProfileRouter } from './VisitorProfile';

export default function VisitorRouter() {
  const [jwt] = UseJWT();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLastAppZone('visitor'));
  });

  return (
    <Switch>
      <Route path="/visitor/landing" component={VisitorLanding} />
      <Route
        path="/visitor/register/success"
        exact
        component={RegistrationSuccess}
      />
      <Route path="/visitor/about" component={About} />
      {jwt?.visitorId && [
        <Route
          path="/visitor/bookings"
          key="bookings"
          component={BookingRouteRouter}
        />,
        <Route
          path="/visitor/profile"
          key="profile"
          component={VisitorProfileRouter}
        />,
        <Route
          key="booking-canceled"
          path="/visitor/booking/cancel/confirm"
          exact
          component={BookingCancelConfirmed}
        />,
        <Route
          key="my-notifications"
          path="/visitor/my-notifications"
          exact
          component={() => (
            <Notifications user={NotificationOwnerType.VISITOR} />
          )}
        />,
        <Route
          key="invoices"
          path="/visitor/invoices"
          exact
          component={Invoices}
        />,
      ]}
      <Redirect to="/visitor/landing" />
    </Switch>
  );
}
