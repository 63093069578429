import { makeStyles } from '@material-ui/styles';
import ANTDSelect, { SelectProps as ANTDSelectProps, SelectValue } from 'antd/es/select';
import classNames from 'classnames';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import tinycolor from 'tinycolor2';

import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  hugeInput: () => ({
    fontSize: 14,

    '&:not(.ant-select-disabled):hover .ant-select-selector, &.ant-select-focused .ant-select-selector': {
      boxShadow: `0 0 0 2px ${tinycolor(getTheme().primary)
        .setAlpha(0.2)
        .toRgbString()}`,
      borderColor: getTheme().primary + '!important',
      borderWidth: '2px !important',
    },
    '& .ant-select-selector': {
      height: '40px !important',
      borderColor: getTheme().neutral4 + '!important',
      borderWidth: '2px !important',
      borderRadius: '5px !important',
      '& input': {
        // height: '44px !important',
      },
      '& > .ant-select-selection-item': {
        lineHeight: '36px !important',
        height: '36px !important',
      },
      '& > .ant-select-selection-item > *': {
        lineHeight: '34px',
      },
      '& > .ant-select-selection-placeholder': {
        lineHeight: '36px !important',
      },
    },
    //Need to make things smaller if it's a multi select with badges inside
    '&.ant-select-multiple .ant-select-selector > .ant-select-selection-item': {
      lineHeight: '26px !important',
      height: '26px !important',
    },
    '&.ant-select-multiple .ant-select-selector > .ant-select-selection-item > *': {
      lineHeight: '24px !important',
    },
    minHeight: 48,
    '& > .ant-select-arrow': {
      marginTop: '-10px',
    },
  }),
});

interface SelectProps<VT> extends Omit<ANTDSelectProps<VT>, 'icon'> {
  huge?: boolean;
}

function Select<ValueType extends SelectValue = SelectValue>({
  className,
  huge,
  showSearch,
  size,
  filterOption,
  optionFilterProp,
  ...props
}: SelectProps<ValueType>) {
  const classes = useStyles();

  return (
    <ANTDSelect
      {...props}
      showSearch={showSearch || true}
      size={huge ? 'large' : size}
      optionFilterProp={optionFilterProp || 'children'}
      filterOption={
        filterOption !== undefined
          ? filterOption
          : (input, option) => {
              if (!option?.children && !option?.label) return false;
              try {
                const string = option.label
                  ? option.label.toString()
                  : typeof option.children === 'object'
                  ? renderToStaticMarkup(option.children)
                  : option.children.toString();

                return string.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } catch (err) {
                return true;
              }
            }
      }
      className={
        huge ? classNames(classes.hugeInput, className) : classNames(className)
      }
    />
  );
}

export default React.memo(Select);
