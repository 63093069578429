import { makeStyles } from '@material-ui/styles';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { SetStateAction, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { AnimatedError } from '../../../../components/App/AnimatedError';
import { FallbackAvatar } from '../../../../components/App/Avatar';
import { Button } from '../../../../components/App/Button';
import { FallbackImage } from '../../../../components/App/Image';
import { Option, Select } from '../../../../components/App/Select';
import { Heading, Text } from '../../../../components/Typography';
import { useFilePreview } from '../../../../hooks/useFilePreview';
import { ReactComponent as BackWhite } from '../../../../shared_assets/icons/back_white.svg';
import { ReactComponent as CameraBlue } from '../../../../shared_assets/icons/camera_blue.svg';
import { ReactComponent as Next } from '../../../../shared_assets/icons/next.svg';
import { ReactComponent as Pediguia } from '../../../../shared_assets/images/pediguia_default.svg';
import { GenderType } from '../../../../types/gender-type.enum';
import { Nationality } from '../../../../types/nationality.enum';
import {
  castShadow,
  complexTranslate,
  desktopPadding,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
} from '../../../../utils';
import { SlideFromRightTransition } from '../../../PageUtils';
import { OnBoardingForm } from '../useOnboardingForm';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  invisible: {
    display: 'none',
  },
  avatarPreview: {
    width: '8rem',
    height: '8rem',
    alignSelf: 'center',
    margin: '1rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  file: {
    display: 'flex',
    backgroundColor: 'lightgrey',
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    textAlign: 'initial',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: '1rem',
  },
  icon: {
    marginRight: '1em',
  },
  marginTitle: {
    marginTop: '2rem',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },
    container: {
      padding: '2rem 2rem 3rem 2rem',
      boxShadow: castShadow,
      borderRadius: '10px',
    },
  },
});

interface PersonalStepProps {
  setAvatar: (val: SetStateAction<File | null>) => void;
  avatar: File | null;
  form: OnBoardingForm;
  onRequestPreviousStep: () => void;
  onRequestNextStep: () => void;
  translate: any;
}

interface SortedNationalities {
  key: string;
  translation: string;
}
function PersonalStep({
  setAvatar,
  avatar,
  form,
  onRequestPreviousStep,
  onRequestNextStep,
  translate,
}: PersonalStepProps) {
  const classes = useStyles();
  const previewSource = useMemo(() => [avatar], [avatar]);
  const avatarPreview = useFilePreview(previewSource);
  let avatarInputRef: HTMLInputElement | null = null;
  const sortedNationalities = Object.values(Nationality)
    .map(nat => {
      return {
        key: nat,
        translation: translate(`nationalities.${nat}`).toString(),
      };
    })
    .sort((a, b) => a.translation.localeCompare(b.translation));

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={SlideFromRightTransition}
    >
      <div className={classes.body}>
        <div className={classes.container}>
          <Heading level={4}>
            <Translate id="onboarding.personal" />
          </Heading>
          <Heading level={5}>
            <Translate id="onboarding.whatYourPersonalInfo" />
          </Heading>
          <div className={classes.inputsContainer}>
            <Text weight="bold" noMargin style={{ alignSelf: 'center' }}>
              <Translate id="onboarding.avatar" />
            </Text>
            <FallbackAvatar
              className={classes.avatarPreview}
              src={
                <FallbackImage
                  src={avatarPreview?.[0] || undefined}
                  fallbackComponent={<Pediguia />}
                />
              }
            />
            <Button
              type="ghost"
              onClick={() => {
                if (avatarInputRef) avatarInputRef.click();
              }}
              prefix={<CameraBlue className={classes.icon} />}
            >
              <Translate id="onboarding.uploadAvatar" />
            </Button>
            <input
              ref={ref => (avatarInputRef = ref)}
              onChange={ev => {
                if (
                  ev.currentTarget.files &&
                  ev.currentTarget.files.length === 1 &&
                  ev.currentTarget.files[0].size < maxAllowedImageSize
                ) {
                  setAvatar(ev.currentTarget.files[0]);
                } else {
                  message.info(
                    complexTranslate(
                      translate('error.imageFileSize').toString(),
                      {
                        '{linkTinyPNG}': (
                          <a key={'tinypnglink'} href="https://tinypng.com/">
                            <Button
                              key="1"
                              type="link"
                              className={classNames(
                                classes.noMargin,
                                classes.inlineBtn
                              )}
                              onlyText
                            >
                              {translate('error.linkTinyPNG').toString()}
                            </Button>
                          </a>
                        ),
                      }
                    )
                  );
                }
              }}
              type="file"
              className={classes.invisible}
            />
            <Text weight="bold" className={classes.marginTitle}>
              <Translate id="onboarding.gender" />
            </Text>
            <Select
              placeholder={<Translate id="onboarding.selectAGender" />}
              defaultValue={undefined}
              huge
              onChange={v => form.gender.set(v.toString())}
              value={form.gender.value || undefined}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  key={gender}
                  value={gender}
                  label={translate(`genders.${gender}`).toString()}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={form.gender.isInvalid}
              reason={form.gender.reason}
            />
            <Text weight="bold">
              <Translate id="onboarding.nationality" />
            </Text>
            <Select
              placeholder={<Translate id="onboarding.selectANationality" />}
              onChange={v => form.nationality.set(v.toString())}
              huge
              value={form.nationality.value || undefined}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={form.nationality.isInvalid}
              reason={form.nationality.reason}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          size="large"
          onClick={onRequestPreviousStep}
          prefix={<BackWhite />}
        >
          <Translate id="button.previous" />
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={form.gender.isInvalid || form.nationality.isInvalid}
          onClick={onRequestNextStep}
          suffix={<Next />}
        >
          <Translate id="button.next" />
        </Button>
      </div>
    </motion.div>
  );
}

export default React.memo(PersonalStep);
