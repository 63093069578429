import message from 'antd/es/message';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Error } from '../../../../../components/App/Error';
//import { EditRoute } from '../../../../hooks/routes/EditRoute';
import { Loader } from '../../../../../components/App/Loader';
import { RouteFormAdmin } from '../../../../../components/App/RouteForm';
import { RouteFormResult } from '../../../../../components/App/RouteForm/RouteForm';
import { EditRoute } from '../../../../../hooks/routes/EditRoute';
import { useRouteAdmin } from '../../../../../hooks/routes/useRouteAdmin';
import { RouteStateType } from '../../../../../types/route-state.enum';
import { Route } from '../../../../../types/route.interface';

interface RouteParams {
  guideId: string;
  routeId: string;
}

function EditRouteInformation() {
  const params = useParams<RouteParams>();
  const history = useHistory();
  const [route, setRoute] = useState<Route | null>(null);
  const [routeIsLoading, routeHasError] = useRouteAdmin(
    params.routeId,
    setRoute
  );

  const submitNewRoute = useCallback(
    (status: RouteStateType) => (form: RouteFormResult) => {
      if (!route) return;
      const routeEdited = {
        accessibilityIds: form.accessibilityIds,
        activities: form.activities.map(act => ({
          ...act,
        })),
        agreeTerms:
          form.agreeGpsSharing &&
          form.agreePhotoOwnership &&
          form.agreeTosAndPp,
        difficulty: form.selectedDifficulty,
        duration: form.selectedDuration,
        extraItems: form.extraItems.map(item => ({
          ...item,
        })),
        groupAge: form.selectedAgeGroup,
        groupSize: form.maxVisitors,
        location: form.routeLocation,
        locations: form.pointLocations.map((pL, i) => ({
          ...pL,
          order: i,
        })),
        languages: form.selectedLanguages.map(lang => ({ language: lang })),
        routeExperience: form.routeExperienceType,
        subjectIds: form.selectedSubjects,
        titles: form.routeTitles,
        descriptions: form.routeDescriptions,
        state: status,
        latitude: form.routeLatitude,
        longitude: form.routeLongitude,
        routeLength: form.routeLength,
      };
      EditRoute(routeEdited, route)
        .then(res => {
          history.replace(
            `/admin/area/guide/${params.guideId}/route/${params.routeId}`
          );
        })
        .catch(err => {
          message.error(err.toString());
        });
    },
    [history, params.guideId, params.routeId, route]
  );

  if (routeIsLoading) return <Loader />;
  if (routeHasError) return <Error />;

  return (
    <RouteFormAdmin
      guideId={params.guideId}
      route={route || undefined}
      onFinish={submitNewRoute(RouteStateType.APPROVED)}
      onSave={submitNewRoute(RouteStateType.DRAFT)}
    />
  );
}

export default EditRouteInformation;
