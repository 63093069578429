/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/styles';
import { Space, Table, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import Badge2 from '../../../../../components/App/Badge2/Badge2';
import { Checkbox } from '../../../../../components/App/Checkbox';
import { Input } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import {
  CompaniesListResponse,
  useListCompanies,
} from '../../../../../hooks/company/useListCompanies';
import { ReactComponent as Canceled } from '../../../../../shared_assets/icons/canceled.svg';
import { ReactComponent as Complete } from '../../../../../shared_assets/icons/complete.svg';
import { GuideStateType } from '../../../../../types/guide-state-type.enum';
import { mobileThreshhold } from '../../../../../utils';
const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  filterZone: {
    '& > *:nth-child(2)': { flexGrow: 1, marginLeft: '1rem' },
    display: 'flex',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});

const ListCompanies = ({ translate }: LocalizeContextProps) => {
  const classes = useStyles();
  const [companies, setListCompanies] = useState<CompaniesListResponse[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showOnlyToApprove, setShowOnlyToApprove] = useState<boolean>(false);
  const dto = useMemo(
    () => ({
      search,
      showOnlyToApprove,
    }),
    [search, showOnlyToApprove]
  );
  const [fetchingCompanies] = useListCompanies(setListCompanies, dto);
  const columns = useMemo(() => {
    return [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => {
          switch (text) {
            case GuideStateType.APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'success'}
                  text={translate('adminstration.guideApproved').toString()}
                />
              );
            case GuideStateType.NOT_APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideNotApproved').toString()}
                />
              );
            case GuideStateType.CANCELED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideCanceled').toString()}
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  type={'warning'}
                  text={translate(
                    'adminstration.guideWaitingApproval'
                  ).toString()}
                />
              );
          }
        },

        width: '20%',
      },
      {
        title: <Translate id="company.adminArea.name" />,
        dataIndex: 'name',
        key: 'name',
      },

      {
        title: <Translate id="company.adminArea.email" />,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: <Translate id="company.adminArea.phoneNumber" />,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
      },
      {
        title: <Translate id="company.adminArea.representativeName" />,
        dataIndex: 'representativeName',
        key: 'representativeName',
      },
      {
        title: <Translate id="adminstration.nif" />,
        dataIndex: 'nif',
        key: 'nif',
      },
      {
        title: <Translate id="company.adminArea.magnifinanceStatus" />,
        dataIndex: 'magnifinanceToken',
        key: 'magnifinanceToken',
        render: (value: any) => {
          if (!value) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartner" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else if (!value) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartnerToken" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={<Translate id="adminstration.magnifinanceOk" />}>
                <Complete style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          }
        },
      },
      {
        title: <Translate id="company.adminArea.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <Link to={`/admin/area/company/${record.id}`}>
              <Translate id="company.adminArea.showInformation" />
            </Link>
          </Space>
        ),
      },
    ];
  }, [translate]);

  return (
    <>
      <div className={classes.divAddButton}>
        <div className={classes.filterZone}>
          <Input
            value={search}
            placeholder={translate('adminstration.search').toString()}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <Checkbox
            checked={showOnlyToApprove}
            onChange={e => setShowOnlyToApprove(e.target.checked)}
          >
            <Translate id="adminstration.showOnlyNotApproved" />
          </Checkbox>
        </div>
      </div>

      {fetchingCompanies ? (
        <Loader />
      ) : (
        <Table dataSource={companies} columns={columns} />
      )}
    </>
  );
};

export default withLocalize(ListCompanies);
