import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { Guide } from '../../types/guide.interface';

type Hook = (
  guideId: string | undefined,
  setGuide: (val: SetStateAction<Guide | null>) => void
) => [boolean, boolean];

const requestGuide = (guideId: string) =>
  Axios.get<Guide>(`${API_URL}/company/guide/${guideId}`);

export const useCompanyGuide: Hook = (guideId, setGuide) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!guideId) {
        setIsError(true);
        setGuide(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: guide } = await requestGuide(guideId);

        setGuide(guide);
        setIsError(false);
      } catch (err) {
        setGuide(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setGuide, guideId]);

  return [isLoading, isError];
};
