import { makeStyles } from '@material-ui/styles';
import { Marker, OverlayView } from '@react-google-maps/api';
import { message } from 'antd';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { usePosition } from 'use-position';
import validator from 'validator';
import { getLocationPhotoUrl } from '../../../hooks/routes/location/useLocationPhoto';
import { fetchAddress } from '../../../hooks/useAddressFromCoordinates';
import { useFilePreview } from '../../../hooks/useFilePreview';
import { ReactComponent as CloseBlue } from '../../../shared_assets/icons/close_blue.svg';
import { ReactComponent as Floating } from '../../../shared_assets/icons/floating.svg';
import { ReactComponent as MapMarker } from '../../../shared_assets/icons/map_marker.svg';
import { RouteLocationPhotos } from '../../../types/route-location-photo.interface';
import {
  complexTranslate,
  getTheme,
  maxAllowedImageSize,
} from '../../../utils';
import { Heading, Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Error } from '../Error';
import { GoogleMap } from '../GoogleMap';
import { FallbackImage } from '../Image';
import { TextArea } from '../Input';
import Modal, { ModalProps } from '../Modal/Modal';
import { TabPane, Tabs } from '../Tabs';
import PlaceSearchBox, { ReturnedPlace } from './PlaceSearchBox';

const useStyles = makeStyles({
  map: {
    marginBottom: '0.3rem',
    minHeight: 400,
  },
  marginTextArea: {
    marginBottom: '0.3rem',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  photoPreviewContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowY: 'auto',
    '& > *': {
      minWidth: 150,
      maxWidth: 150,
      minHeight: 150,
      maxHeight: 150,
      display: 'flex',
      backgroundColor: getTheme().standout,
      alignItems: 'center',
      margin: '0.5rem 0.2rem',
      '& > img, & > svg': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    '& > *:first-child': {
      marginLeft: 0,
      marginRight: '0.5rem',
    },
    '& > *:last-child': {
      marginRight: 0,
    },
    '& > .add': {
      '& > img, & > svg': {
        flex: 0.7,
      },
      '& :last-child': {
        flex: 0.3,
      },
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      boxShadow: '0px 0px 7px -3px rgba(0,0,0,0.5)',
      borderRadius: '5px',
      justifyContent: 'center',
      backgroundColor: 'white',
      margin: '0.5rem 0.2rem',
    },
  },
  stickyFooter: {
    display: 'flex',
    height: 48,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
    backgroundColor: 'white',
    flexShrink: 0,
  },
  invisible: {
    display: 'none',
  },
  photoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 5,
    '& > img, & > svg': {
      position: 'absolute',
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& > button': {
      zIndex: 1000,
      marginRight: '0.5rem',
      marginTop: '0.5rem',
    },
  },
  bold: {
    fontWeight: `600 !important` as unknown as number,
  },
  inline: {
    display: 'inline-flex',
    '& :last-child': {
      marginLeft: '0.5rem',
    },
  },
  btnInnerMargin: {
    margin: '0 0.5rem !important',
  },
  noPadding: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBott: {
    marginBottom: '2rem !important',
  },
});

export interface Location {
  address: string;
  latitude: number;
  longitude: number;
  localBusiness: boolean;
  type: 'start' | 'middle' | 'end';
  descriptions: {
    description: string;
    language: string;
  }[];
  titles: {
    title: string;
    language: string;
  }[];
  photos: (File | RouteLocationPhotos)[];
}

interface LocationModalProps extends LocalizeContextProps, ModalProps {
  location?: Location;
  onCommitLocationChange: (v: Location) => void;
  isVisible?: boolean;
  onRequestClose: () => void;
  selectedLanguages: string[];
  type: 'start' | 'middle' | 'end';
  mainLocationLat: number;
  mainLocationLong: number;
}

const generateEmptyLocation = (
  type: 'start' | 'middle' | 'end',
  languages: string[]
): Location => ({
  address: '',
  latitude: 0,
  longitude: 0,
  localBusiness: false,
  descriptions: languages.map(l => ({ language: l, description: '' })),
  titles: languages.map(l => ({ language: l, title: '' })),
  photos: [],
  type,
});
const LocationModal: React.FC<LocationModalProps> = ({
  isVisible,
  location,
  translate,
  onRequestClose,
  selectedLanguages,
  type,
  onCommitLocationChange,
  mainLocationLat,
  mainLocationLong,
}) => {
  const pointPhotoInputRef = useRef<null | HTMLInputElement>(null);
  const DefaultCenter = {
    lat: mainLocationLat === 0 ? 38.722317 : mainLocationLat,
    lng: mainLocationLong === 0 ? -9.139351 : mainLocationLong,
  };
  const [center, setCenter] = useState(DefaultCenter);
  const classes = useStyles();
  const { latitude, longitude, errorMessage } = usePosition(true);
  const [localLocation, setLocalLocation] = useState<Location>(
    location || generateEmptyLocation(type, selectedLanguages)
  );
  const stuffToPreview = useMemo(
    () => localLocation.photos.map(p => (p instanceof File ? p : null)),
    [localLocation]
  );

  const [inputText, setInputText] = useState<string>('');
  const previews = useFilePreview(stuffToPreview);

  const changeSelectedPosition = useCallback(
    async ev => {
      try {
        const { data } = await fetchAddress(ev.latLng.lat(), ev.latLng.lng());

        if (
          !data.results[0].address_components.some(
            comp => comp.short_name === 'PT'
          )
        ) {
          message.error(translate('routes.outOfBoundsError'));
          return;
        }

        if (data.results[0].types.includes('country')) {
          message.error(translate('routes.notAnActualPlaceError'));
          return;
        }
        setLocalLocation(prevLocation => ({
          ...prevLocation,
          latitude: ev.latLng.lat(),
          longitude: ev.latLng.lng(),
          address: data.results[0].formatted_address,
        }));

        setInputText(data.results[0].formatted_address);

        if (mapRef.current)
          mapRef.current.panTo({ lat: ev.latLng.lat(), lng: ev.latLng.lng() });
      } catch (err) {}
    },
    [setLocalLocation, translate]
  );

  const addPointTitle = useCallback(() => {
    switch (type) {
      case 'start':
        return <Translate id="routes.addStartPointTitle" />;
      case 'middle':
        return <Translate id="routes.addPointTitle" />;
      case 'end':
        return <Translate id="routes.addEndPointTitle" />;
      default:
        return <Translate id="routes.addPointTitle" />;
    }
  }, [type]);

  const mapRef = useRef<null | google.maps.Map<Element>>(null);

  const modalValid =
    !validator.isEmpty(localLocation.address) &&
    localLocation.latitude &&
    localLocation.longitude &&
    !localLocation.descriptions.some(desc =>
      validator.isEmpty(desc.description)
    ) &&
    !localLocation.titles.some(desc => validator.isEmpty(desc.title));

  useEffect(() => {
    setLocalLocation(
      location || generateEmptyLocation(type, selectedLanguages)
    );
    setInputText(location?.address || '');
    if (location)
      setCenter({ lat: location.latitude, lng: location.longitude });
  }, [location, selectedLanguages, type]);

  const placeSelected = useCallback((place: ReturnedPlace | undefined) => {
    if (!place || !place.geometry) return;
    setLocalLocation(prevLocation => ({
      ...prevLocation,
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    }));

    setInputText(place.formatted_address);

    if (mapRef.current)
      mapRef.current.panTo({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
  }, []);

  useEffect(() => {
    if (
      mainLocationLong !== 0 &&
      mainLocationLat !== 0 &&
      !localLocation.latitude &&
      !localLocation.longitude
    ) {
      setCenter({
        lat: mainLocationLat,
        lng: mainLocationLong,
      });
      mapRef.current?.panTo({
        lat: mainLocationLat,
        lng: mainLocationLong,
      });
    } else if (
      longitude &&
      latitude &&
      !localLocation.latitude &&
      !localLocation.longitude
    ) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
      mapRef.current?.panTo({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude, localLocation, mainLocationLong, mainLocationLat]);

  if (errorMessage) return <Error />; //TODO: verificar

  return (
    <Modal
      widthModal="80%"
      visible={isVisible}
      onRequestClose={onRequestClose}
      headerTitle={addPointTitle()}
      maskClosable={false}
      footer={
        <div className={classes.stickyFooter}>
          <Button
            type="ghost"
            onClick={() => {
              onRequestClose();
              setLocalLocation(
                location || generateEmptyLocation(type, selectedLanguages)
              );
            }}
          >
            <Translate id="routes.cancel" />
          </Button>
          <Button
            disabled={!modalValid}
            type="primary"
            onClick={() => {
              onCommitLocationChange(localLocation);
              if (!location)
                setLocalLocation(
                  generateEmptyLocation(type, selectedLanguages)
                );
              onRequestClose();
            }}
          >
            <Translate id="routes.save" />
          </Button>
        </div>
      }
      bodyStyle={{ paddingBottom: 0 }}
    >
      <Text weight="semibold">
        <Translate id="routes.address" />
      </Text>
      <PlaceSearchBox
        value={inputText}
        onChange={setInputText}
        onPlaceSelected={placeSelected}
      />
      <AnimatedError
        isVisible={validator.isEmpty(localLocation.address)}
        reason={<Translate id="routes.mandatoryLocation" />}
      />
      <Text disableAutoscale>
        <Translate id="routes.mapIntroText" />
      </Text>

      <GoogleMap
        mapContainerClassName={classes.map}
        zoom={15}
        onLoad={map => {
          mapRef.current = map;
        }}
        onClick={changeSelectedPosition}
        center={center}
      >
        {localLocation.latitude && localLocation.longitude && (
          <OverlayView
            position={{
              lat: localLocation.latitude,
              lng: localLocation.longitude,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <MapMarker
              style={{
                width: 40,
                height: 40,
                transform: 'translate(-50%, -100%)',
              }}
            />
          </OverlayView>
        )}
        {latitude && longitude && (
          <Marker
            position={{ lat: latitude, lng: longitude }}
            icon={require('../../../shared_assets/icons/map pointer.svg')}
          />
        )}
      </GoogleMap>
      <Text variant="note" className={classes.marginBott}>
        <Translate id="routes.addressWarning" />
      </Text>

      <Text weight="semibold">
        <Translate id="routes.locationPointTitle" />
      </Text>

      <Tabs className={classes.marginTextArea}>
        {localLocation.titles.map(d => (
          <TabPane
            tab={<Translate id={`languages.${d.language}`} />}
            key={d.language}
          >
            <TextArea
              rows={1}
              key={`${d.language}_title`}
              value={d.title}
              onChange={ev => {
                ev.persist();
                setLocalLocation(prevState => ({
                  ...prevState,
                  titles: prevState.titles.map(desc => {
                    if (desc.language === d.language)
                      return {
                        ...desc,
                        title: ev.target.value,
                      };
                    else return desc;
                  }),
                }));
              }}
              placeholder={translate('routes.locationPointTitle').toString()}
            />
          </TabPane>
        ))}
      </Tabs>
      <AnimatedError
        className={classes.marginBott}
        isVisible={localLocation.titles.some(desc =>
          validator.isEmpty(desc.title)
        )}
        reason={<Translate id="routes.mandatoryPointTitle" />}
      />

      <Text weight="semibold">
        <Translate id="routes.whatToDoTitle" />
      </Text>
      <Text variant="faded" disableAutoscale>
        {type === 'start' ? (
          <Translate id="routes.firstWhatToDoInfo" />
        ) : type === 'middle' ? (
          <Translate id="routes.middleWhatToDoInfo" />
        ) : (
          <Translate id="routes.endWhatToDoInfo" />
        )}
      </Text>

      <Tabs className={classes.marginTextArea}>
        {localLocation.descriptions.map(d => (
          <TabPane
            tab={<Translate id={`languages.${d.language}`} />}
            key={d.language}
          >
            <TextArea
              rows={6}
              key={d.language}
              value={d.description}
              onChange={ev => {
                ev.persist();
                setLocalLocation(prevState => ({
                  ...prevState,
                  descriptions: prevState.descriptions.map(desc => {
                    if (desc.language === d.language)
                      return {
                        ...desc,
                        description: ev.target.value,
                      };
                    else return desc;
                  }),
                }));
              }}
              placeholder={
                type === 'middle'
                  ? translate('routes.whatToDoIntroMiddle').toString()
                  : translate('routes.whatToDoIntro').toString()
              }
            />
          </TabPane>
        ))}
      </Tabs>
      <AnimatedError
        className={classes.marginBott}
        isVisible={localLocation.descriptions.some(desc =>
          validator.isEmpty(desc.description)
        )}
        reason={<Translate id="routes.mandatoryPointDescription" />}
      />
      <Text>
        <Checkbox
          onChange={e => {
            setLocalLocation(prevState => ({
              ...prevState,
              localBusiness: e.target.checked,
            }));
          }}
          checked={localLocation.localBusiness}
        >
          <Text inline variant="faded">
            <Translate id="routes.localBusinessChecker" />
          </Text>
        </Checkbox>
      </Text>
      <div className={classes.inline}>
        <Heading level={5} className={classes.bold}>
          <Translate id="routes.addPhotosTitle" />
        </Heading>
        <Text color={getTheme().neutral1}>
          <Translate id="optional" />
        </Text>
      </div>
      <Text variant="faded" disableAutoscale>
        <Translate id="routes.addPhotosIntro" />
      </Text>
      <div className={classes.photoPreviewContainer}>
        {localLocation.photos.map((photo, i) => (
          <div key={i.toString()} className={classes.photoContainer}>
            <Button
              className={classes.noPadding}
              onClick={() => {
                setLocalLocation(prevLocation => ({
                  ...prevLocation,
                  photos: prevLocation.photos.filter((p, j) => p !== photo),
                }));
              }}
            >
              <CloseBlue />
            </Button>
            <FallbackImage
              fallback={require('../../../shared_assets/images/route-default.png')}
              src={
                photo instanceof File
                  ? (previews[i] as string)
                  : getLocationPhotoUrl(photo.id)
              }
              alt="img"
            />
          </div>
        ))}
        {(!previews || previews.length < 3) && (
          <div
            className="add"
            onClick={() => {
              pointPhotoInputRef.current?.click();
            }}
          >
            <Floating />
            <Text noMargin inline disableAutoscale>
              <Translate id="routes.addPhoto" />
            </Text>
          </div>
        )}
        <input
          className={classes.invisible}
          ref={ref => (pointPhotoInputRef.current = ref)}
          type="file"
          onChange={ev => {
            ev.persist();
            setLocalLocation(prevLocation => {
              let newLoc;
              if (
                ev.target.files &&
                ev.target.files[0] &&
                ev.target.files[0].size < maxAllowedImageSize
              ) {
                newLoc = {
                  ...prevLocation,
                  photos: [...prevLocation.photos, ev.target.files[0]],
                };
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <Button
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </Button>
                        </a>
                      ),
                    }
                  )
                );

                newLoc = prevLocation;
              }

              if (ev.target) ev.target.value = null as unknown as '';

              return newLoc;
            });
          }}
        />
      </div>
    </Modal>
  );
};

export default withLocalize(LocationModal);
