import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as StarWhite } from '../../../shared_assets/icons/star white.svg';
import { ellipsis, getTheme } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 180,
    maxWidth: 180,
    filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.5))',
  },
  masker: {
    '-webkit-mask': 'url(/stamp-cutout.svg)',
    '-webkit-mask-size': 'auto 100%',
    '-webkit-mask-position-x': 'center',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '1rem',
    borderRadius: '1rem',
  },
  previewImage: {
    width: '100%',
    objectFit: 'cover',
    height: 200,
    maxHeight: 200,
  },
  place: {
    textTransform: 'uppercase',
  },
  ratingContainer: {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'center',
    '& > *': {
      marginBottom: 0,
    },
  },
  ratingBadge: {
    display: 'flex',
    borderRadius: 10,
    padding: '0 5px',
    paddingTop: 2,
    alignItems: 'center',
    backgroundColor: getTheme().secondary,
    flex: 0,
    marginBottom: '0px !important',
  },
  star: {
    height: 10,
    width: 10,
    marginLeft: 5,
    transform: 'translateY(-1px)',
  },
  textZone: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    flex: 1,
    flexGrow: 1,
  },
  ratingCount: {
    marginBottom: '0px !important',
    marginLeft: '0.2rem',
  },
});

export interface RoutePreviewInterface {
  id: string;
  place: string;
  name: string;
  price: number;
  rating: number;
  numberOfRatings: number;
  image?: string;
}
export interface RoutePreviewProps
  extends LocalizeContextProps,
    RoutePreviewInterface {}

function RoutePreview({
  id,
  place,
  name,
  price,
  rating,
  numberOfRatings,
  image,
  translate,
}: RoutePreviewProps) {
  const classes = useStyles();
  return (
    <Link
      className={classes.container}
      to={`/route/${id}`}
      style={{ color: '#000' }}
    >
      <div className={classes.masker}>
        <FallbackImage
          fallback={require('../../../shared_assets/images/route-default.png')}
          className={classes.previewImage}
          src={image}
          alt="place"
        />
        <div className={classes.textZone}>
          <div style={{ flex: 1 }}>
            <Text
              size={10}
              weight="bold"
              noMargin
              color={getTheme().primary}
              className={classes.place}
            >
              {ellipsis(place)}
            </Text>
            <Heading level={6} noMargin>
              {ellipsis(name)}
            </Heading>
          </div>

          <Text variant="faded" size={10}>
            {translate('routeDetails.valuePerPerson')
              .toString()
              .replace('{value}', price.toString())}
          </Text>

          <div className={classes.ratingContainer}>
            <Text color="white" variant="note" className={classes.ratingBadge}>
              <span>{rating || 0}</span> <StarWhite className={classes.star} />
            </Text>
            <Text variant="faded" size={10} className={classes.ratingCount}>
              {`(${numberOfRatings})`}
            </Text>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default withLocalize(RoutePreview);
