import { SetStateAction, useState, useMemo } from 'react';
import {
  useSimilarRoutes,
  PartialRouteInterface,
} from '../routes/UseSimilarRoutes';
import { UseJWT } from '../authentication/UseJWT';
import { useVisitor } from '../visitor/UseVisitor';
import { Visitor } from '../../types/visitor.interface';

type Hook = (
  guideId: string | undefined,
  setRoutes: (val: SetStateAction<PartialRouteInterface[]>) => void
) => [boolean, boolean];

export const useGuidePublicRoutes: Hook = (guideId, setRoutes) => {
  const [jwt] = UseJWT();
  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const [visitorIsLoading] = useVisitor(jwt?.visitorId, setVisitor);

  const filter = useMemo(
    () => ({
      guideId,
      subjectsIds:
        visitor && visitor.showOnly ? visitor.accessibilities : undefined,
    }),
    [visitor, guideId]
  );

  const [routesAreLoading, routesHaveError] = useSimilarRoutes(
    filter,
    setRoutes
  );

  return [routesAreLoading || visitorIsLoading, routesHaveError];
};
