import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

export interface FaqEntry {
  question: string;
  answer: string;
  language: string;
  order: number;
}

type Hook = (
  setFaqs: (val: SetStateAction<FaqEntry[]>) => void,
  setOrders: (val: SetStateAction<number[]>) => void
) => [boolean, boolean];

export const requestFaqs = () =>
  Axios.get<FaqEntry[]>(
    `${API_URL}/content-management/guide-landing-page/faqs`
  );

export const useFaqs: Hook = (setFaqs, setOrders) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: faqEntries } = await requestFaqs();
        setOrders(getUnique(faqEntries.map(f => f.order)));
        setFaqs(faqEntries);
      } catch (err) {
        setFaqs([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setFaqs, setOrders]);

  return [isLoading, isError];
};
export function getUnique(array: number[]) {
  var uniqueArray = [];

  // Loop through array values
  for (let i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}
