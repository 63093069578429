import Axios from 'axios';
import { API_URL } from '../../consts';

interface TranslationDto {
  language: string;
  description: string;
}

export interface CreateAccessibilityDto {
  translations: TranslationDto[];
}

export const postAccessibility = (dto: CreateAccessibilityDto) =>
  Axios.post(`${API_URL}/accessibility`, dto).catch(err => {
    return Promise.reject(err);
  });

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
