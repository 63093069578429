import { makeStyles } from '@material-ui/styles';
import { Radio } from 'antd';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/App/Button';
import { Input } from '../../../components/App/Input';
import { Modal } from '../../../components/App/Modal';
import { Text } from '../../../components/Typography';
import { cancelGuideProfile } from '../../../hooks/guide/cancelGuideProfile';
import {
  CountsBookingsAndRepetitions,
  useHasActiveBookings,
} from '../../../hooks/guide/useHasActiveBookings';
import { logout } from '../../../store/UserConfiguration/actions';
import { CancelGuideProfileType } from '../../../types/cancel-guide-profile-type.enum';
import { complexTranslate } from '../../../utils';

interface CancelGuideProfileModalProps extends LocalizeContextProps {
  visible: boolean;
  onRequestClose: () => void;
  nameToConfirm: string;
  guideId: string;
}

const useStyles = makeStyles({
  modalContent: { padding: 24 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    paddingTop: 0,
    flexShrink: 0,
    '& > .segment': {
      display: 'flex',
      '& > *:first-child': {
        marginRight: '1rem',
      },
    },
  },
});

const CancelGuideProfileModal = ({
  visible,
  onRequestClose,
  translate,
  nameToConfirm,
  guideId,
}: CancelGuideProfileModalProps) => {
  const [nameConfirm, setNameConfirm] = useState<string>('');
  const [
    cancellationType,
    setCancellationType,
  ] = useState<CancelGuideProfileType | null>(null);
  const [
    hastActiveBookings,
    setHastActiveBookings,
  ] = useState<CountsBookingsAndRepetitions | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, hasError] = useHasActiveBookings(
    guideId,
    setHastActiveBookings
  );
  const handleNameChange = useCallback(e => {
    setNameConfirm(e.target.value);
  }, []);

  const classes = useStyles();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (hastActiveBookings === null) return;
    if (hastActiveBookings.hasBookings === false) {
      setCancellationType(CancelGuideProfileType.CANCEL);
    }
  }, [hastActiveBookings]);
  const onChange = (e: any) => {
    setCancellationType(e.target.value);
  };

  const doAction = useCallback(async () => {
    try {
      if (!cancellationType) return;
      setSubmitting(true);
      try {
        await cancelGuideProfile(guideId, cancellationType);
        if (
          cancellationType ===
          CancelGuideProfileType.CANCEL_APPOINTMENTS_WITHOUT_BOOKINGS
        ) {
          message.success(translate('cancelProfile.successCancelAllRep'));
        }
        if (
          cancellationType === CancelGuideProfileType.CANCEL_ALL_APPOINTMENTS ||
          cancellationType === CancelGuideProfileType.CANCEL
        ) {
          dispatch(logout());
        }
      } catch (error) {}
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [cancellationType, guideId, translate, dispatch]);

  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      title={<Translate id="cancelProfile.cancelTitle" />}
      contentContainerClassName={classes.modalContent}
      footer={
        <div className={classes.footer}>
          <Button disabled={submitting} onClick={onRequestClose}>
            <Translate id="button.exit" />
          </Button>
          <Button
            onClick={doAction}
            disabled={nameConfirm !== nameToConfirm || submitting}
          >
            <Translate id="button.confirm" />
          </Button>
        </div>
      }
    >
      <Text>
        <Translate id="cancelProfile.guideProfileConfirmation" />
      </Text>
      {hastActiveBookings?.hasBookings === true ? (
        <>
          <Radio.Group onChange={e => onChange(e)} value={cancellationType}>
            <Radio value={CancelGuideProfileType.CANCEL_ALL_APPOINTMENTS}>
              <Translate id="cancelProfile.CANCEL_ALL_APPOINTMENTS" />
            </Radio>
            {hastActiveBookings.hasRepetitions === true ? (
              <Radio
                value={
                  CancelGuideProfileType.CANCEL_APPOINTMENTS_WITHOUT_BOOKINGS
                }
              >
                <Translate id="cancelProfile.CANCEL_APPOINTMENTS_WITHOUT_BOOKINGS" />
              </Radio>
            ) : null}
          </Radio.Group>
        </>
      ) : null}

      <Divider />
      <Text variant="note">
        {complexTranslate(
          translate('cancelProfile.continueConfirmation').toString(),
          {
            '{NAME}': nameToConfirm,
          }
        )}
      </Text>
      <Input
        disabled={submitting}
        value={nameConfirm}
        onChange={handleNameChange}
      />
    </Modal>
  );
};

export default React.memo(withLocalize(CancelGuideProfileModal));
