import Axios from 'axios';
import { API_URL } from '../../consts';
import { RoleType } from '../../types/role-type.enum';

interface AddAdvertiserDto {
  name: string;
  phoneNumber: string;
  url?: string;
  address?: string;
  email: string;
  password: string;
  type: RoleType;
}

export interface AddAdvertiserResponse {
  name: string;
  phoneNumber: string;
  url: string;
  address: string;
  email: string;
  id: string;
}

export const addAdvertiser = (dto: AddAdvertiserDto, image: File) => {
  const formData = new FormData();
  formData.append(
    'createAdOwnerDto',
    JSON.stringify({
      ...dto,
    })
  );
  formData.append('logo-image', image);
  return Axios({
    method: 'post',
    url: `${API_URL}/administrator/create-ad-owner`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};
