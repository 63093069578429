import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as PediguiaDefault } from '../../../shared_assets/images/pediguia_default.svg';
import { mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Button } from '../Button';
import { FallbackImage } from '../Image';

const useListStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > *': {
        maxWidth: '45%',
        flex: 1,
      },
      '& > :first-child': {
        paddingRight: '1rem',
      },
      '& > :last-child': {
        paddingLeft: '1rem',
      },
    },
  },
});

const useTestimonyStyles = makeStyles({
  testimony: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: '450px',
    borderRadius: '10px',
    margin: '2rem 0 1rem 0',
    objectFit: 'cover',
  },
});

export interface GuideTestimonyProps {
  name: string;
  image: string;
  id: string;
  description: string;
}

export interface GuideTestimonyListProps {
  data: GuideTestimonyProps[];
}

export function GuideTestimonyList({ data }: GuideTestimonyListProps) {
  const classes = useListStyles();
  return (
    <div className={classes.container}>
      {data.map(testimony => (
        <GuideTestimony key={testimony.id} {...testimony} />
      ))}
    </div>
  );
}

export function GuideTestimony({
  id,
  name,
  description,
  image,
}: GuideTestimonyProps) {
  const classes = useTestimonyStyles();

  return (
    <div className={classes.testimony}>
      <FallbackImage
        fallbackComponent={<PediguiaDefault className={classes.image} />}
        className={classes.image}
        src={image}
        alt="place"
      />
      <Heading level={4}>{name}</Heading>
      <Text>{description}</Text>
      <Link to={`/guide/${id}`}>
        <Button onlyText type="link">
          <Translate id="landing.guide.seeProfileAndExperience" />
        </Button>
      </Link>
    </div>
  );
}
