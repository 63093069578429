import message from 'antd/es/message';
import Axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../consts';
import { GenderType } from '../../types/gender-type.enum';
import { VisitorFormProperty } from '../../types/visitor.interface';
import { getVisitorErrorMessagesFromField } from './VisitorUtils';

export interface EditVisitorDto {
  firstName: string;
  lastName: string;
  nif: string;
  nationality: string;
  gender: GenderType;
  dateOfBirth: Date;
  phoneNumber: string;
  address: string;
  location: string;
  agreeMarketing: boolean;
  postalCode: string;
  showOnly: boolean;
  accessibilityIds: string[];
}

export const editVisitor = (
  id: string,
  dto: EditVisitorDto,
  avatar?: File | null
) => {
  const formData = new FormData();

  formData.append(
    'editVisitorDto',
    JSON.stringify({
      ...dto,
      phoneNumber: dto.phoneNumber.trim() || undefined,
      gender: dto.gender.trim() || undefined,
      nationality: dto.nationality.trim() || undefined,
      dateOfBirth: moment.utc(dto.dateOfBirth).toISOString(),
    })
  );
  if (avatar) formData.append('avatar', avatar);

  return Axios({
    method: 'put',
    url: `${API_URL}/visitor/${id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      err.response.data.message.forEach(
        (t: { property: VisitorFormProperty }) => {
          message.error(getVisitorErrorMessagesFromField(t.property));
        }
      );
    }

    return Promise.reject(err);
  });
};
