import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CopyrightFooter } from '../../components/App/CopyrightFooter';
import { EnglishTOS, PortugueseTOS } from '../../components/App/TermsOfService';
import { RootState } from '../../store';
import { LanguageType } from '../../types/language-type.enum';
import { desktopPadding, mobilePadding, mobileThreshhold } from '../../utils';
import { GenericPageTransition } from '../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
  },
});

const TermsOfService = () => {
  const classes = useStyles();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const location = useLocation();

  const goToHash = useCallback(() => {
    const id = location.hash.split('#').filter(s => !!s)[0];

    if (id) document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    else
      document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  useEffect(goToHash, [goToHash]);

  return (
    <>
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        className={classNames(classes.page, classes.body)}
        variants={GenericPageTransition}
      >
        {activeLanguage === LanguageType.PT ? (
          <PortugueseTOS></PortugueseTOS>
        ) : (
          <EnglishTOS></EnglishTOS>
        )}
      </motion.div>
      <CopyrightFooter />
    </>
  );
};

export default React.memo(TermsOfService);
