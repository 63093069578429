import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import validator from 'validator';
import { ReactComponent as PlusBlue } from '../../../shared_assets/icons/plus_blue.svg';
import { RouteExtraItem } from '../../../types/route-extra-item.interface';
import { Button } from '../Button';
import { TextArea } from '../Input';
import { Modal } from '../Modal';
import { TabPane, Tabs } from '../Tabs';
import ExtraItemEntry from './ExtraItemEntry';

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
    '& > .segment': {
      display: 'flex',
      '& > *:first-child': {
        marginRight: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
});

const generateEmptyItem = (languages: string[]): RouteExtraItem => ({
  descriptions: languages.map(l => ({
    language: l,
    description: '',
  })),
});

interface RouteExtraItemsProps {
  extraItems?: RouteExtraItem[];
  languages: string[];
  onChange?: (newExtraItems: RouteExtraItem[]) => void;
  className?: string;
}

const RouteExtraItems: React.FC<RouteExtraItemsProps> = ({
  extraItems,
  languages,
  onChange,
  className,
}) => {
  const classes = useStyles();

  const [selectedExtraItems, setSelectedExtraItems] = useState<
    RouteExtraItem[]
  >(extraItems || []);

  const [editing, setEditing] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);

  const [activityToReplace, setExtraItemToReplace] = useState<RouteExtraItem>();
  const [extraItemToAdd, setExtraItemToAdd] = useState<RouteExtraItem>(
    generateEmptyItem(languages)
  );

  useEffect(() => {
    if (!extraItems) return;

    setSelectedExtraItems(extraItems);
  }, [extraItems]);

  useEffect(() => {
    if (!onChange) return;

    onChange(selectedExtraItems);
  }, [selectedExtraItems, onChange]);

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setAdding(false);
                setExtraItemToAdd(generateEmptyItem(languages));
              }}
            >
              <Translate id="button.cancel" />
            </Button>

            <Button
              disabled={extraItemToAdd.descriptions.some(desc =>
                validator.isEmpty(desc.description)
              )}
              type="primary"
              onClick={() => {
                if (extraItemToAdd) {
                  setSelectedExtraItems(prevActivities => [
                    ...prevActivities,
                    extraItemToAdd,
                  ]);
                  setExtraItemToAdd(generateEmptyItem(languages));
                  setAdding(false);
                }
              }}
            >
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={adding}
        closable={false}
      >
        <Tabs>
          {extraItemToAdd.descriptions.map(description => (
            <TabPane
              key={description.language}
              tab={<Translate id={`languages.${description.language}`} />}
            >
              <TextArea
                value={description.description}
                onChange={e => {
                  e.persist();
                  setExtraItemToAdd(prevItem => ({
                    ...prevItem,
                    descriptions: prevItem.descriptions.map(desc =>
                      desc.language === description.language
                        ? { ...desc, description: e.target.value }
                        : desc
                    ),
                  }));
                }}
              />
            </TabPane>
          ))}
        </Tabs>
      </Modal>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        footer={
          <div className={classes.footer}>
            <div className="segment">
              <Button
                onClick={() => {
                  setExtraItemToAdd(generateEmptyItem(languages));
                  setExtraItemToReplace(undefined);
                  setEditing(false);
                }}
              >
                <Translate id="button.cancel" />
              </Button>
              <Button
                disabled={!extraItemToAdd}
                danger
                onClick={() => {
                  if (extraItemToAdd) {
                    setSelectedExtraItems(prevActivities =>
                      prevActivities.filter(
                        activity => activity !== extraItemToAdd
                      )
                    );
                    setExtraItemToAdd(generateEmptyItem(languages));
                    setEditing(false);
                  }
                }}
              >
                <Translate id="button.remove" />
              </Button>
            </div>
            <Button
              disabled={extraItemToAdd.descriptions.some(desc =>
                validator.isEmpty(desc.description)
              )}
              type="primary"
              onClick={() => {
                if (extraItemToAdd) {
                  setSelectedExtraItems(prevActivities => [
                    ...prevActivities.filter(
                      activity => activity !== activityToReplace
                    ),
                    extraItemToAdd,
                  ]);
                  setExtraItemToAdd(generateEmptyItem(languages));
                  setExtraItemToReplace(undefined);
                  setEditing(false);
                }
              }}
            >
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={editing}
        closable={false}
      >
        <Tabs>
          {extraItemToAdd.descriptions.map(description => (
            <TabPane
              key={description.language}
              tab={<Translate id={`languages.${description.language}`} />}
            >
              <TextArea
                value={description.description}
                onChange={e => {
                  e.persist();
                  setExtraItemToAdd(prevItem => ({
                    ...prevItem,
                    descriptions: prevItem.descriptions.map(desc =>
                      desc.language === description.language
                        ? { ...desc, description: e.target.value }
                        : desc
                    ),
                  }));
                }}
              />
            </TabPane>
          ))}
        </Tabs>
      </Modal>
      <div className={className}>
        {selectedExtraItems.map((extraItem, i) => (
          <ExtraItemEntry
            key={i}
            onRequestExtraItemChange={() => {
              setExtraItemToReplace(extraItem);
              setExtraItemToAdd(extraItem);
              setEditing(true);
            }}
            extraItem={extraItem}
          />
        ))}
        <Button
          onClick={() => setAdding(true)}
          prefix={<PlusBlue />}
          type="link"
        >
          <Translate id="button.addExtraItem" />
        </Button>
      </div>
    </>
  );
};

export default React.memo(RouteExtraItems);
