import React from 'react';
import { VisitorBookingInterface } from '../../../../hooks/visitor/booking/useVisitorBookings';
import { useIsMobile } from '../../../../utils';
import DesktopBookingHistoryEntry from './DesktopBookingHistoryEntry';
import MobileBookingHistoryEntry from './MobileBookingHistoryEntry';

interface BookingHistorytEntryProps extends VisitorBookingInterface {
  onReview: (bookingId: string, reviewId: string) => void;
}

const BookingHistoryEntry = (props: BookingHistorytEntryProps) => {
  const isMobile = useIsMobile();

  if (isMobile) return <MobileBookingHistoryEntry {...props} />;
  else return <DesktopBookingHistoryEntry {...props} />;
};

export default BookingHistoryEntry;
