import { makeStyles } from '@material-ui/styles';
import { message, Space } from 'antd';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { Button } from '../../../../../components/App/Button';
import { FallbackImage } from '../../../../../components/App/Image';
import { Input } from '../../../../../components/App/Input';
import { Modal } from '../../../../../components/App/Modal';
import { Text } from '../../../../../components/Typography';
import {
  addAdvertiser,
  AddAdvertiserResponse,
} from '../../../../../hooks/advertiser/addAdvertiser';
import {
  changePassword,
  editAdvertiser,
  EditAdvertiserResponse,
} from '../../../../../hooks/advertiser/editAdvertiser';
import {
  AdvertiserResponse,
  getAdvertiserImageUrl,
} from '../../../../../hooks/advertiser/useAdvertiser';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as Pediguia } from '../../../../../shared_assets/images/pediguia_default.svg';
import { RoleType } from '../../../../../types/role-type.enum';
import { complexTranslate, maxAllowedImageSize } from '../../../../../utils';

const useStyles = makeStyles({
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  footer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: 20,
  },
  imageSize: {
    width: '10rem',
  },
});
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
interface CreateAdvertiserModalProps extends LocalizeContextProps {
  visible: boolean;
  onRequestClose: () => void;
  advertiserContent?: AdvertiserResponse[0];
  onAdd?: (v: AddAdvertiserResponse) => void;
  onEdit?: (v: EditAdvertiserResponse) => void;
}

const CreateAdvertiserModal = ({
  visible,
  advertiserContent,
  onRequestClose,
  onAdd,
  onEdit,
  translate,
}: CreateAdvertiserModalProps) => {
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassError, setShowPassError] = useState<boolean>(true);

  const imageInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const previewSource = useMemo(() => [image], [image]);
  const imagePreview = useFilePreview(previewSource);
  const classes = useStyles();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (advertiserContent) {
      setName(advertiserContent.name);
      setPhoneNumber(advertiserContent.phoneNumber.toString());
      setUrl(advertiserContent.url);
      setEmail(advertiserContent.email);
      setAddress(advertiserContent.address);
    }
  }, [advertiserContent, visible]);

  const action = useCallback(async () => {
    try {
      setSubmitting(true);
      if (advertiserContent) {
        //edit

        const { data } = await editAdvertiser(
          {
            name,
            phoneNumber,
            url,
            address,
            id: advertiserContent.id,
          },
          image
        );
        if (!validator.isEmpty(password)) {
          await changePassword(advertiserContent.id, {
            newPassword: password,
            oldPassword: 'none',
          });
        }
        if (onEdit) onEdit(data);
      } else {
        //add
        if (image === null) return;

        //FAZER ERROR
        const { data } = await addAdvertiser(
          {
            name,
            phoneNumber,
            url,
            address,
            email,
            password,
            type: RoleType.AD_OWNER,
          },
          image
        );

        if (onAdd) onAdd(data);
      }
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [
    advertiserContent,
    name,
    phoneNumber,
    url,
    address,
    email,
    image,
    password,
    onEdit,
    onAdd,
  ]);

  const errorImage = useMemo(() => {
    if (image === null && onAdd) {
      return translate('adminstration.adEventArea.mandatoryImage');
    }
  }, [image, onAdd, translate]);
  const errorPhone = useMemo(() => {
    if (!validator.isEmpty(phoneNumber) && !validator.isNumeric(phoneNumber)) {
      return translate('adminstration.adArea.phoneNumberError');
    }
  }, [phoneNumber, translate]);
  const errorName = useMemo(() => {
    if (validator.isEmpty(name)) {
      return translate('adminstration.adArea.nameError');
    }
  }, [name, translate]);

  const errorEmail = useMemo(() => {
    if (!validator.isEmail(email)) {
      return translate('adminstration.helpZone.badEmail');
    }
  }, [email, translate]);
  const errorPassword = useMemo(() => {
    let infos: Info[] = [];
    const formatStrength =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const hasRightLength = password.length >= 8 && password.length <= 20;
    const emailSplit = email.includes('@') && email.split('@');
    const isStrength = password.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (emailSplit && password.includes(emailSplit[0]) && email !== '') ||
      password === ''; //TODO: falta fazer validar o email

    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isStrength
    );

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [translate('register.passNoContainNameMail').toString()],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isStrength ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    switch (true) {
      case onAdd && validator.isEmpty(password):
        setShowPassError(true);

        return infos;

      case onEdit && validator.isEmpty(password):
        infos[0].infoType = 'success';
        setShowPassError(false);

        return infos;
      case onAdd &&
        !validator.isEmpty(password) &&
        infos[0].infoType === 'error':
        setShowPassError(true);
        return infos;
      default:
        setShowPassError(true);
        return infos;
    }
  }, [email, onAdd, onEdit, password, translate]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      footer={
        <div className={classes.footer}>
          <Button
            type="primary"
            disabled={
              !!errorName ||
              !!errorEmail ||
              !!errorPhone ||
              errorPassword[0].infoType === 'error' ||
              submitting
            }
            onClick={action}
          >
            <Translate id="button.save" />
          </Button>
          <Button disabled={submitting} type="primary" onClick={onRequestClose}>
            <Translate id="button.cancel" />
          </Button>
        </div>
      }
      visible={visible}
    >
      <div className={classes.content}>
        <Space
          direction="vertical"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
          size="large"
        >
          {advertiserContent !== undefined ? (
            <FallbackImage
              className={classes.imageSize}
              fallbackComponent={<Pediguia />}
              src={
                imagePreview?.[0] || getAdvertiserImageUrl(advertiserContent.id)
              }
            />
          ) : (
            <FallbackImage
              className={classes.imageSize}
              src={imagePreview?.[0] || undefined}
              fallback={require('../../../../../shared_assets/images/pediguia_default.svg')}
            />
          )}

          <Translate id="profile.changePhoto" />
          <input
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <Button
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </Button>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
          <AnimatedError isVisible={!!errorImage} reason={errorImage} />
        </Space>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.adArea.name" />
        </Text>
        <Input
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <AnimatedError isVisible={!!errorName} reason={errorName} />
        <Divider />
        <Text noMargin weight="semibold">
          <Translate id="adminstration.adArea.address" />
        </Text>
        <Input
          value={address}
          onChange={e => {
            setAddress(e.target.value);
          }}
        />
        <Divider />

        <Text noMargin weight="semibold">
          <Translate id="adminstration.adArea.phoneNumber" />
        </Text>
        <Input
          value={phoneNumber}
          onChange={e => {
            setPhoneNumber(e.target.value);
          }}
        />
        <AnimatedError isVisible={!!errorPhone} reason={errorPhone} />

        <Divider />
        <Text noMargin weight="semibold">
          <Translate id="adminstration.adArea.url" />
        </Text>
        <Input
          value={url}
          onChange={e => {
            setUrl(e.target.value);
          }}
        />
        <Divider />
        <Text noMargin weight="semibold">
          <Translate id="adminstration.adArea.email" />
        </Text>
        <Input
          disabled={!!onEdit}
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
        <AnimatedError isVisible={!!errorEmail} reason={errorEmail} />
        <Divider />
        <Text noMargin weight="semibold">
          <Translate id="adminstration.adArea.password" />
        </Text>
        <Input
          disabled={!!onEdit}
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
        <AnimatedInfo
          isVisible={showPassError}
          infos={errorPassword ? errorPassword : []}
        />
      </div>
    </Modal>
  );
};

export default React.memo(withLocalize(CreateAdvertiserModal));
