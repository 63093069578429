import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { Schedule } from '../../types/schedule-new';

type Hook = (
  routeId: string | undefined,
  setSchedules: (val: SetStateAction<Schedule[]>) => void
) => [boolean, boolean];

export const requestSchedules = (routeId: string) =>
  Axios.get<Schedule[]>(`${API_URL}/schedule/route/${routeId}`);

export const useRouteSchedules: Hook = (routeId, setSchedules) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!routeId) {
        setIsError(true);
        setSchedules([]);
        setIsLoading(false);
        return;
      }

      try {
        const { data: schedules } = await requestSchedules(routeId);

        setSchedules(schedules);
      } catch (err) {
        setSchedules([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [routeId, setSchedules]);

  return [isLoading, isError];
};
