import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import React, { useCallback } from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../store';
import { appendRecentSearch } from '../../../store/RecentSearches/actions';
import { FilterRoute } from '../../../types/filter-route.dto';
import { Text } from '../../Typography';
import ResultItem from './ResultItem';
import { ResultType, RouteResult } from './useSearchResults';
interface ResultListProps {
  results: ResultType[];
  recent: ResultType[];
  search: string;
  history: ReturnType<typeof useHistory>;
  setSearching: (v: boolean) => void;
  translate: TranslateFunction;
  filterDto: FilterRoute;
}

const useStyles = makeStyles({
  resultList: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
});

const ResultList = ({
  search,
  results,
  history,
  recent,
  setSearching,
  translate,
  filterDto,
}: ResultListProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const getRouteTitle = useCallback(
    (route: RouteResult) => {
      const translation = route.titles.find(t => t.language === activeLanguage);
      return translation
        ? translation.title
        : route.titles[0].title
        ? route.titles[0].title
        : '';
    },
    [activeLanguage]
  );
  return (
    <div className={classes.resultList}>
      {search.trim().length > 0 ? (
        results.length > 0 ? (
          results.map(r => {
            switch (r.type) {
              case 'location':
                return (
                  <ResultItem
                    type={r.type}
                    key={`${r.location.location.lat},${r.location.location.lng}`}
                    onClick={() => {
                      setSearching(false);
                      dispatch(appendRecentSearch(r));
                      history.push({
                        pathname: '/route/search',
                        state: {
                          filter: {
                            latitude: r.location.location.lat,
                            longitude: r.location.location.lng,
                          },
                          displayText: r.location.description,
                        },
                      });
                    }}
                    text={r.location.description}
                  />
                );
              case 'route':
                return (
                  <ResultItem
                    key={r.id}
                    type={r.type}
                    onClick={() => {
                      dispatch(appendRecentSearch(r));

                      history.push(`/route/${r.id}`);
                    }}
                    text={getRouteTitle(r)}
                    id={r.locations.map(l => l.photos.map(p => p.id)).flat()[0]}
                  />
                );
              case 'subject':
                return (
                  <ResultItem
                    key={r.id}
                    type={r.type}
                    text={r.name}
                    id={r.id}
                    onClick={() => {
                      dispatch(appendRecentSearch(r));

                      history.push({
                        pathname: '/route/search',
                        state: {
                          filter: {
                            subjectsIds: [r.id],
                          },
                        },
                      });
                    }}
                  />
                );
              default:
                return null;
            }
          })
        ) : (
          <ResultItem
            type="location"
            text={translate('search.noResultsFound').toString()}
          />
        )
      ) : null}

      {recent.length > 0 && (
        <>
          <Divider />
          <Text weight="bold" noMargin>
            <Translate id="search.recentSearches" />
          </Text>
          {recent.map(r => {
            switch (r.type) {
              case 'location':
                return (
                  <ResultItem
                    type={r.type}
                    key={`${r.location.location.lat},${r.location.location.lng}`}
                    onClick={() => {
                      setSearching(false);
                      dispatch(appendRecentSearch(r));

                      history.push({
                        pathname: '/route/search',
                        state: {
                          filter: {
                            latitude: r.location.location.lat,
                            longitude: r.location.location.lng,
                          },
                          displayText: r.location.description,
                        },
                      });
                    }}
                    text={r.location.description}
                  />
                );
              case 'route':
                return (
                  <ResultItem
                    key={r.id}
                    type={r.type}
                    onClick={() => {
                      dispatch(appendRecentSearch(r));

                      history.push(`/route/${r.id}`);
                    }}
                    text={getRouteTitle(r)}
                    id={r.locations.map(l => l.photos.map(p => p.id)).flat()[0]}
                  />
                );
              case 'subject':
                return (
                  <ResultItem
                    key={r.id}
                    type={r.type}
                    text={r.name}
                    id={r.id}
                    onClick={() => {
                      dispatch(appendRecentSearch(r));

                      history.push({
                        pathname: '/route/search',
                        state: {
                          filter: {
                            subjectsIds: [r.id],
                          },
                        },
                      });
                    }}
                  />
                );
              default:
                return null;
            }
          })}
        </>
      )}
      <Divider />
      <Text weight="bold" noMargin>
        <Translate id="search.advancedSearch" />
      </Text>
      <ResultItem
        onClick={() => {
          history.push({
            pathname: '/route/search',
            state: filterDto,
          });
          setSearching(false);
        }}
        type="filter"
        text={translate('search.filter').toString()}
      />
    </div>
  );
};

export default ResultList;
