import { SetStateAction, useState } from "react";

export class RefreshToken {
  constructor(setNewToken: (a: SetStateAction<RefreshToken | null>) => void) {
    this.refreshAction = () => {
      setNewToken(new RefreshToken(setNewToken));
    };
  }

  private refreshAction: () => void;

  refresh() {
    this.refreshAction();
  }
}

export const useRefreshToken = () => {
  const [token, setToken] = useState<RefreshToken | null>(null);

  if (!token) setToken(new RefreshToken(setToken));

  return [token];
};
