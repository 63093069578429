import message from 'antd/es/message';
import Axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../consts';
import { FinancialType } from '../../types/financial.type';
import { GenderType } from '../../types/gender-type.enum';
import {
  GuideDescriptionDto,
  GuideSkillDto,
  LanguageCompetenceDto,
} from '../../types/guide.dtos';
import { GuideFormProperty } from '../../types/guide.interface';
import { GuideSignupProfileDto } from './CreateGuide';
import { getGuideErrorMessagesFromField } from './GuideUtils';

export interface EditGuideDto
  extends Partial<Omit<GuideSignupProfileDto, 'agreeTerms'>> {
  companyName: string;
  nif: string;
  nationality: string;
  gender: GenderType;
  address: string;
  location: string;
  postalCode: string;
  iban: string;
  skills: GuideSkillDto[];
  languages: LanguageCompetenceDto[];
  descriptions: GuideDescriptionDto[];
  onboarding?: boolean;
  financialType?: FinancialType;
  ivaExemption?: string;
  retention?: string;
  company?: string;
  userEdit?: boolean;
}

export const editGuide = (
  id: string,
  dto: Partial<EditGuideDto>,
  avatar?: File | null,
  documents?: File[]
) => {
  const formData = new FormData();

  formData.append(
    'editGuideDto',
    JSON.stringify({
      ...dto,
      dateOfBirth: dto.dateOfBirth
        ? moment(dto.dateOfBirth).utc().toISOString()
        : null,
    })
  );
  if (avatar) {
    formData.append('avatar', avatar);
  }
  if (documents) {
    documents.forEach((doc, i) => {
      formData.append('document-' + i, doc);
    });
  }

  return Axios({
    method: 'put',
    url: `${API_URL}/guide/${id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      err.response.data.message.forEach(
        (t: { property: GuideFormProperty }) => {
          message.error(getGuideErrorMessagesFromField(t.property));
        }
      );
    }

    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
