import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
export interface CountsBookingsAndRepetitions {
  hasBookings: boolean;
  hasRepetitions: boolean;
}

type Hook = (
  guideId: string,
  setHasBooking: (
    val: SetStateAction<CountsBookingsAndRepetitions | null>
  ) => void
) => [boolean, boolean];

const hasActiveBooking = (guideId: string) =>
  Axios.get(`${API_URL}/guide/has-bookings/${guideId}`);

export const useHasActiveBookings: Hook = (guideId, setHasBooking) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!guideId) {
        setIsError(true);
        setHasBooking(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: hasBooking } = await hasActiveBooking(guideId);
        setHasBooking(hasBooking as CountsBookingsAndRepetitions);
        setIsError(false);
      } catch (err) {
        setHasBooking(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [guideId, setHasBooking]);

  return [isLoading, isError];
};
