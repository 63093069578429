import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal } from '../../../../../components/App/Modal';
import { ScheduleDateBookings } from '../../../../../types/ScheduleDateBookings.interface';
import { CheckPresence } from './CheckPresence';
import { ReadyToStart } from './ReadyToStart';

const useStyles = makeStyles({
  paddingModal: {
    padding: 24,
  },
});

export interface Checker {
  checked: boolean;
  visitorId?: string;
}

interface StartRouteProps {
  repetitionId: string;
  routeTitle: string;
  routeDuration: number;
  routeImageId: string;
  scheduleBookings: ScheduleDateBookings;
  isVisible: boolean;
  onBackInAboutToStartModal: () => void;
  onClose: () => void;
}

const StartRoute = ({
  repetitionId,
  routeTitle,
  routeDuration,
  routeImageId,
  scheduleBookings,
  isVisible,
  onBackInAboutToStartModal,
  onClose,
}: StartRouteProps) => {
  const classes = useStyles();
  const [checkPresence, setCheckPresence] = useState<Checker[][]>([]);
  const [isShowingReadyToStart, setIsShowingReadyToStart] = useState(false);

  const onNext = () => {
    setIsShowingReadyToStart(true);
  };

  const onPrevious = () => {
    setIsShowingReadyToStart(false);
  };

  const numberOfAllVisitors = useMemo(
    () =>
      scheduleBookings?.bookings
        .map(booking => booking.bookingGroupSize)
        .reduce((a, b) => a + b, 0),
    [scheduleBookings]
  );

  useEffect(() => {
    if (!scheduleBookings) return;

    setCheckPresence(
      scheduleBookings.bookings.map(booking => {
        return Array.from(new Array(booking.bookingGroupSize), (_, i) => ({
          checked: false,
        }));
      })
    );
  }, [scheduleBookings]);

  return (
    <Modal
      visible={isVisible}
      disableNaturalPadding
      contentContainerClassName={classes.paddingModal}
      onRequestClose={() => {
        onClose();
      }}
      footer={null}
    >
      {scheduleBookings.bookings.length === 0 && (
        <Redirect to={`/guide/schedules/`} />
      )}
      {isShowingReadyToStart ? (
        <ReadyToStart
          repetitionId={repetitionId}
          scheduleBookings={scheduleBookings}
          routeTitle={routeTitle}
          routeDuration={routeDuration}
          routeImageId={routeImageId}
          numberOfAllVisitors={numberOfAllVisitors ? numberOfAllVisitors : 0}
          checkPresence={checkPresence}
          onPrevious={onPrevious}
          onDone={onClose}
        />
      ) : (
        <CheckPresence
          scheduleBookings={scheduleBookings}
          numberOfAllVisitors={numberOfAllVisitors ? numberOfAllVisitors : 0}
          checkPresence={checkPresence}
          setCheckPresence={setCheckPresence}
          onBackInAboutToStartModal={onBackInAboutToStartModal}
          onNext={onNext}
        />
      )}

      {/* <div
        className={classNames(
          classes.bottomContainer,
          classes.standOutBackground
        )}
      >
        <Button type="primary" onClick={() => setIsShowingReadyToStart(false)}>
          <Translate id="button.previous" />
        </Button>
        <Button
          type="primary"
          onClick={() => setIsShowingReadyToStart(true)}
          // disabled={
          //   isShowingReadyToStart &&
          //   numberOfAllVisitors !== howMany &&
          //   validator.isEmpty(commentary)
          // }
        >
          {isShowingReadyToStart ? (
            <Translate id="button.startRoute" />
          ) : (
            <Translate id="button.next" />
          )}
        </Button>
      </div> */}
    </Modal>
  );
};

export default StartRoute;
