import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';

import { API_URL } from '../../consts';
import { Route } from '../../types/route.interface';

type Hook = (
  setAllPediroutes: (val: SetStateAction<Route[]>) => void,
  info: { search: string; showOnlyToApprove: boolean }
) => [boolean, boolean];

export const requestGuideRoutes = (
  cancelToken: CancelToken | undefined,
  info: { search: string }
) =>
  Axios.get(`${API_URL}/route/administration`, {
    params: info,
    cancelToken: cancelToken,
  });

export const useAllPediroutes: Hook = (setAllPediroutes, info) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: route } = await requestGuideRoutes(
          cancel.current?.token,
          info
        );
        setAllPediroutes(route as Route[]);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setAllPediroutes([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [info, setAllPediroutes]);

  return [isLoading, isError];
};
