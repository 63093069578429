import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { LinkProps, Link } from 'react-router-dom';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    // width: '90%',
    alignItems: 'center',
    // alignSelf: 'center',
    color: 'unset',
    padding: '0.5rem 0',
    justifyContent: 'space-between',
    '& h3': {
      display: 'flex',
    },
  },
  iconContainer: {
    fontSize: '1.5rem',
    color: 'gray',
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {},
  title: {
    margin: 0,
    fontWeight: 'bold',
  },
});

interface DrawerItemProps extends Omit<LinkProps, 'title' | 'to'> {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  subtitle?: React.ReactNode;
  to?: string;
}

export default function ShareDrawerItem({
  icon,
  subtitle,
  title,
  to,
  ...linkProps
}: DrawerItemProps) {
  const classes = useStyles();
  if (to)
    return (
      <Link className={classes.container} {...linkProps} to={to}>
        <div className={classes.textContainer}>
          <Text weight="bold" className={classes.title} noMargin>
            {title}
          </Text>
          {subtitle}
        </div>
        <div className={classes.iconContainer}>{icon}</div>
      </Link>
    );
  else
    return (
      <a className={classes.container} {...linkProps}>
        <div className={classes.textContainer}>
          <Text weight="bold" className={classes.title} noMargin>
            {title}
          </Text>
          {subtitle}
        </div>
        <div className={classes.iconContainer}>{icon}</div>
      </a>
    );
}
