import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/App/Button';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import Loader from '../../../components/App/Loader/Loader';
import { ProfileStrip } from '../../../components/App/ProfileStrip';
import { Heading, Text } from '../../../components/Typography';
import {
  AccessibilityWithSingleTranslation,
  useAccessibilities,
} from '../../../hooks/accessibilities/useAccessibilities';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import {
  getVisitorImageUrl,
  useVisitor,
} from '../../../hooks/visitor/UseVisitor';
import { Visitor } from '../../../types/visitor.interface';
import {
  desktopPadding,
  joinWithAnd,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import CancelVisitorProfileModal from './CancelVisitorProfileModal';

const useStyles = makeStyles({
  fill: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerDivider: {
    margin: 0,
    marginTop: 1,
  },
  statsContainer: {
    display: 'flex',
  },
  statItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > p:last-child': {
      fontWeight: 'bold',
    },
  },
  noMargin: {
    margin: 0,
  },
  divider: {
    margin: '1rem 0',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      flexDirection: 'row',
    },
    visitorSection: {
      flex: 0.4,
      boxShadow: '0px 0px 9px 4px rgba(0,0,0,0.1)',
      padding: '1rem',
      marginRight: '4rem',
      height: '100%',
      borderRadius: 10,
      maxWidth: 360,
    },
    infoSection: {
      flex: 0.8,
    },
  },
});

interface VisitorProfileProps extends LocalizeContextProps {}

const VisitorProfile = ({ translate }: VisitorProfileProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();
  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const [isLoading, hasError] = useVisitor(jwt?.visitorId, setVisitor);
  const [accs, setAccs] = useState<AccessibilityWithSingleTranslation[]>([]);
  const [accessibilitiesLoading, accessibilitiesHaveError] = useAccessibilities(
    setAccs
  );

  const isMobile = useIsMobile();

  const [cancelShowing, setCancelShowing] = useState<boolean>(false);

  const closeCancel = useCallback(() => {
    setCancelShowing(false);
  }, []);

  const openCancel = useCallback(() => {
    setCancelShowing(true);
  }, []);

  if (isLoading || accessibilitiesLoading) return <Loader />;
  if (hasError || !visitor || accessibilitiesHaveError) return <Error />;

  const visitorName = `${visitor.firstName} ${visitor.lastName}`;

  return (
    <motion.div
      className={classes.fill}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <div className={classes.page}>
        {!isMobile && (
          <Heading className={classes.title} level={2}>
            <Translate id="profile.profile" />
          </Heading>
        )}
        <div className={classes.body}>
          <div className={classes.visitorSection}>
            <ProfileStrip
              imageSource={getVisitorImageUrl(visitor.id)}
              name={visitorName}
              memberSince={visitor.dateOfCreation}
              type="visitor"
              edit={isMobile}
            />
            <Divider className={classes.divider} />
            <div className={classes.statsContainer}>
              <div className={classes.statItem}>
                <Text>
                  <Translate id="profile.kmsRan" />
                </Text>
                <Text weight="bold" variant="link" noMargin>
                  {visitor.travelledDistance.toFixed(2)}
                </Text>
              </div>
              <div className={classes.statItem}>
                <Text>
                  <Translate id="profile.routesPerformed" />
                </Text>
                <Text weight="bold" variant="link" noMargin>
                  {visitor.routesPerformed}
                </Text>
              </div>
            </div>
          </div>
          <div className={classes.infoSection}>
            {isMobile && <Divider className={classes.divider} />}
            <Text noMargin weight="semibold">
              <Translate id="profile.name" />
            </Text>
            <Text variant="faded" noMargin>
              {visitorName}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.email" />
            </Text>
            <Text variant="faded" noMargin>
              {visitor.email}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.birthDate" />
            </Text>
            <Text variant="faded" noMargin>
              {moment(visitor.dateOfBirth).format('L')}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.phoneNumber" />
            </Text>
            <Text noMargin variant="faded">
              {visitor.phoneNumber || <Translate id="notDefined" />}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.gender" />
            </Text>
            <Text noMargin variant="faded">
              {visitor.gender ? (
                <Translate id={`genders.${visitor.gender}`} />
              ) : (
                <Translate id="notDefined" />
              )}
            </Text>
            <Divider className={classes.divider} />
            <div className={classes.centeredRow}>
              <div>
                <Text noMargin weight="semibold">
                  <Translate id="profile.password" />
                </Text>
                <Text noMargin variant="faded">
                  ***************
                </Text>
              </div>
              <Link to="/visitor/profile/change-password">
                <Button type="ghost" extraWide>
                  <Translate id="profile.change" />
                </Button>
              </Link>
            </div>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.nationality" />
            </Text>
            <Text noMargin variant="faded">
              {visitor.nationality ? (
                <Translate id={`nationalities.${visitor.nationality}`} />
              ) : (
                <Translate id="notDefined" />
              )}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.nif" />
            </Text>
            <Text noMargin variant="faded">
              {visitor.nif ? visitor.nif : <Translate id="notDefined" />}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.address" />
            </Text>
            <Text noMargin variant="faded">
              {`${visitor.address || ''} ${visitor.postalCode || ''} ${
                visitor.location || ''
              }`.trim() || <Translate id="notDefined" />}
            </Text>
            <Divider />
            {visitor.accessibilities.length > 0 && (
              <>
                <Text noMargin weight="semibold">
                  <Translate id="profile.specialNeeds" />
                </Text>
                <Text noMargin variant="faded">
                  {joinWithAnd(
                    visitor.accessibilities.map(
                      acc =>
                        accs.find(remoteAcc => remoteAcc.id === acc)?.name ||
                        acc
                    ),
                    translate('and').toString()
                  )}
                </Text>
                <Divider className={classes.divider} />
              </>
            )}
            {!isMobile && (
              <div className={classes.centeredRow}>
                <Link to="/visitor/profile/edit">
                  <Button type="link" onlyText>
                    <Text variant="link">
                      <Translate id="profile.editProfile" />
                    </Text>
                  </Button>
                </Link>
                <Button type="ghost" danger onClick={openCancel}>
                  <Translate id="button.cancelProfile" />
                </Button>
              </div>
            )}
            {isMobile && (
              <>
                <div className={classes.buttonContainer}>
                  <Button type="ghost" danger onClick={openCancel}>
                    <Translate id="button.cancelProfile" />
                  </Button>
                </div>
                <Divider className={classes.divider} />
              </>
            )}
          </div>
        </div>
      </div>
      <CancelVisitorProfileModal
        visible={cancelShowing}
        onRequestClose={closeCancel}
        nameToConfirm={visitor.firstName + ' ' + visitor.lastName}
      />
      <CopyrightFooter />
    </motion.div>
  );
};

export default withLocalize(VisitorProfile);
