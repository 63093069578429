import { useState, useEffect } from "react";
import { API_URL } from "../../consts";
import Axios from "axios";

type Hook = () => [string[], boolean, boolean];

const requestLanguages = () => Axios.get(`${API_URL}/administrator/languages`);

export const useLanguages: Hook = () => {
  const [data, setData] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await requestLanguages();
        setData(data);
      } catch (err) {
        setData([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return [data, isLoading, isError];
};
