import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { ReactComponent as Difficulty } from '../../../shared_assets/icons/difficulty.svg';
import { ReactComponent as DifficultyWhite } from '../../../shared_assets/icons/difficulty_white.svg';
import { DifficultyType } from '../../../types/route-difficulty.enum';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    borderRadius: 0,
    maxWidth: 460,
    '& > :first-child': {
      borderRadius: '5px 0 0 5px',
    },
    '& > :last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  difficultyItem: () => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px ${getTheme().primary} solid`,
    marginBottom: '1rem',
    '& > :first-child': {
      flex: '0.8',
      padding: '1rem',
      '& img, & svg': {
        width: '1.5rem',
        height: '1.5rem',
      },
    },
    '& > :last-child': {
      flex: '0.2',
    },
    '&.selected': {
      backgroundColor: getTheme().primary,
    },
  }),
});

interface DifficultySelectorProps {
  onChange: (v: DifficultyType) => void;
  value?: DifficultyType;
}

function DifficultySelector({ onChange, value }: DifficultySelectorProps) {
  const classes = useStyles();

  const difficultyType = (type: DifficultyType, isSelected: boolean) => {
    switch (type) {
      case DifficultyType.EASY:
        return <div>{isSelected ? <DifficultyWhite /> : <Difficulty />}</div>;
      case DifficultyType.MEDIUM:
        return (
          <div>
            {isSelected ? <DifficultyWhite /> : <Difficulty />}
            {isSelected ? <DifficultyWhite /> : <Difficulty />}
          </div>
        );
      case DifficultyType.HARD:
        return (
          <div>
            {isSelected ? <DifficultyWhite /> : <Difficulty />}
            {isSelected ? <DifficultyWhite /> : <Difficulty />}
            {isSelected ? <DifficultyWhite /> : <Difficulty />}
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div className={classes.container}>
      {Object.values(DifficultyType).map(dif => (
        <div
          className={classNames(
            classes.difficultyItem,
            dif === value && 'selected'
          )}
          onClick={() => {
            onChange(dif);
          }}
        >
          {difficultyType(dif, dif === value)}
          <Text
            variant="note"
            color={dif === value ? 'white' : getTheme().primary}
          >
            <Translate id={`difficulties.${dif}`} />
          </Text>
        </div>
      ))}
    </div>
  );
}

export default DifficultySelector;
