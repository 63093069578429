import Axios from 'axios';

import { API_URL } from '../../consts';

interface EditThemeDto {
  primary: string;

  secondary: string;

  hostname: string;

  logo: File | null;

  logoWhite: File | null;
}

export const editTheme = (id: string, dto: EditThemeDto) => {
  const data = new FormData();

  data.append('createThemeDto', JSON.stringify(dto));

  if (dto.logo) data.append('logo', dto.logo);

  if (dto.logoWhite) data.append('whiteLogo', dto.logoWhite);

  return Axios.put(`${API_URL}/content-management/theme/${id}`, data);
};
