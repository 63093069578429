import { makeStyles } from '@material-ui/styles';
import { message, Table } from 'antd';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Axios from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { Error } from '../../../../../components/App/Error';
import { Input as AppInput } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import { postCreateAdmin } from '../../../../../hooks/administration/createAdmin';
import {
  ListAdminInterface,
  useListAdmins,
} from '../../../../../hooks/administration/useListAdmin';
import { ReactComponent as Lock } from '../../../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../../../shared_assets/icons/unsee.svg';
import { RoleType } from '../../../../../types/role-type.enum';
import { mobileThreshhold } from '../../../../../utils';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});

interface ErrorInterface {
  error: boolean;
  reason: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
interface ListAdminProps extends LocalizeContextProps {}
const ListAdmins = ({ translate }: ListAdminProps) => {
  const classes = useStyles();
  const [admins, setListAdmins] = useState<ListAdminInterface[]>([]);
  const [fetchingAdmins, errorFetchingAdmins] = useListAdmins(setListAdmins);
  const [showModalCreateAdmin, setShowModalCreateAdmin] = useState<boolean>(
    false
  );
  const [newAdminEmail, setNewAdminEmail] = useState<string>('');
  const [newAdminPassword, setNewAdminPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });
  const [showRemoveAdminModal, setShowRemoveAdminModal] = useState<boolean>(
    false
  );
  const [removeAdminId, setRemoveAdminId] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);

  const tableData = useMemo(() => {
    return admins.map(g => {
      return {
        key: g.id,
        email: g.email,
      };
    });
  }, [admins]);
  const emailError = useMemo((): ErrorInterface => {
    if (!validator.isEmail(newAdminEmail)) {
      return {
        error: true,
        reason: 'Invalid email',
      };
    }
    return { error: false, reason: '' };
  }, [newAdminEmail]);
  useMemo(() => {
    const formatNumSymbol = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const formatStrength = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    const hasRightLength = password.length >= 8 && password.length <= 20;
    const isStrength = password.match(formatStrength) ? true : false;
    const isContainNumSymbol =
      formatNumSymbol.test(password) && password.match(/\d/) ? true : false;
    const isInvalid: boolean = !(
      hasRightLength &&
      isContainNumSymbol &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isContainNumSymbol ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewAdminPassword({
      value: password,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [password, translate]);

  const showRemoveAdminCallback = useCallback((adminId: string) => {
    setRemoveAdminId(adminId);
    setShowRemoveAdminModal(true);
  }, []);
  const sendRemoveAdminCallback = useCallback(async () => {
    try {
      await Axios.delete(`${API_URL}/administrator/${removeAdminId}`);
    } catch (error) {
      console.log('ERROR', error.response.status);
    }
    const leftAdmins = admins.filter(e => e.id !== removeAdminId);
    setListAdmins([...leftAdmins]);
    setShowRemoveAdminModal(false);
    message.error(translate('adminstration.scheduleSuccessCancel').toString());
  }, [admins, removeAdminId, translate]);
  const columns = useMemo(
    () => [
      {
        title: <Translate id="adminstration.email" />,
        dataIndex: 'email',
        key: 'email',
      },

      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={ev => {
              showRemoveAdminCallback(record.key);
            }}
          >
            <Translate id="adminstration.removeAdmin" />
          </a>
        ),
      },
    ],
    [showRemoveAdminCallback]
  );
  const submit = useCallback(async () => {
    if (newAdminPassword.isInvalid || emailError.error) return;
    const dto = {
      email: newAdminEmail,
      password: newAdminPassword.value,
      type: RoleType.ADMINISTRATOR,
    };
    try {
      const { data: newAdmin } = await postCreateAdmin(dto);

      setListAdmins(admins => [...admins, newAdmin]);

      message.success(
        translate('adminstration.adminCreationSuccessful').toString()
      );
      setNewAdminEmail('');
      setNewAdminPassword({
        value: '',
        isInvalid: true,
      });
      setIsPasswordShowing(false);
      setShowModalCreateAdmin(false);
    } catch (error) {
      if (error.response.status === 409) {
        message.error(
          translate('adminstration.adminCreationEmailConflict').toString()
        );
      }
    }
  }, [newAdminEmail, newAdminPassword, emailError, translate]);

  if (fetchingAdmins) return <Loader />;
  if (errorFetchingAdmins) return <Error />;

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setNewAdminEmail('');
                setNewAdminPassword({
                  value: '',
                  isInvalid: true,
                });
                setIsPasswordShowing(false);
                setShowModalCreateAdmin(false);
              }}
            >
              <Translate id="button.cancel" />
            </Button>
            <div className={classes.addRemoveBtn}>
              <Button
                type="primary"
                onClick={() => {
                  submit();
                }}
              >
                <Translate id="button.save" />
              </Button>
            </div>
          </div>
        }
        visible={showModalCreateAdmin}
        closable={true}
      >
        <Text noMargin weight="semibold">
          <Translate id="profile.email" />
        </Text>
        <Input
          type={'email'}
          value={newAdminEmail}
          onChange={ev => {
            setNewAdminEmail(ev.target.value);
          }}
        ></Input>

        <AnimatedError
          isVisible={emailError.error}
          reason={emailError.reason}
        />
        <Text weight="semibold" noMargin>
          <Translate id="profile.password" />
        </Text>
        <AppInput
          huge
          type={isPasswordShowing ? 'text' : 'password'}
          placeholder={translate('register.password').toString()}
          onChange={ev => setPassword(ev.target.value)}
          value={password}
          prefix={<Lock className={classes.icon} />}
          suffix={
            isPasswordShowing ? (
              <div
                onClick={() => {
                  setIsPasswordShowing(prevState => !prevState);
                }}
              >
                <See className={classes.icon} />
              </div>
            ) : (
              <div
                onClick={() => {
                  setIsPasswordShowing(prevState => !prevState);
                }}
              >
                <Unsee className={classes.icon} />
              </div>
            )
          }
        />
        <AnimatedInfo
          isVisible={
            newAdminPassword.isVisible ? newAdminPassword.isVisible : false
          }
          infos={newAdminPassword.infos}
        />
      </Modal>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setShowRemoveAdminModal(false);
              }}
            >
              <Translate id="button.cancel" />
            </Button>
            <div className={classes.addRemoveBtn}>
              <Button
                type="primary"
                onClick={() => {
                  sendRemoveAdminCallback();
                }}
              >
                <Translate id="button.remove" />
              </Button>
            </div>
          </div>
        }
        visible={showRemoveAdminModal}
        closable={false}
      >
        <Text color={'red'} noMargin weight="semibold">
          <Translate id="adminstration.deleteAdmindWarning" />
        </Text>
      </Modal>
      <div className={classes.divAddButton}>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            setShowModalCreateAdmin(true);
          }}
        >
          <Translate id="adminstration.createAdmin" />
        </Button>
      </div>
      <Table dataSource={tableData} columns={columns} />
    </>
  );
};

export default withLocalize(ListAdmins);
