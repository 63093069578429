import { localizeReducer, LocalizeState } from 'react-localize-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import AppReducer from './App/reducer';
import { AppState } from './App/types';
import RecentSearchesReducer from './RecentSearches/reducer';
import { RecentSearchesState } from './RecentSearches/types';
import UserConfigurationReducer from './UserConfiguration/reducer';
import { UserConfigurationState } from './UserConfiguration/types';

//Reducer "criado"/combinado com todas as regras de persistencia
const root = combineReducers({
  localize: localizeReducer,
  userConfiguration: UserConfigurationReducer,
  recentSearches: RecentSearchesReducer,
  app: AppReducer,
});

const persistConfig = {
  key: 'root',
  // storage: new CookieStorage(Cookies/*, options */),
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['userConfiguration', 'app', 'recentSearches'],
};

export type RootState = {
  localize: LocalizeState;
  userConfiguration: UserConfigurationState;
  recentSearches: RecentSearchesState;
  app: AppState;
};

const pReducer = persistReducer<RootState, any>(persistConfig, root);

const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk))(
  createStore
);
export const store = createStoreWithMiddleware(pReducer);

export const persistor = persistStore(store);
