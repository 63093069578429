import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
import isTaxID from 'validator/lib/isTaxID';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { BirthDateSelector } from '../../../../../components/App/BirthDateSelector';
import { Input as AppInput } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Option, Select } from '../../../../../components/App/Select';
import { Heading, Text } from '../../../../../components/Typography';
import {
  createIntermediate,
  CreateIntermediateDto,
} from '../../../../../hooks/administration/createIntermediate';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { ReactComponent as Lock } from '../../../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../../../shared_assets/icons/unsee.svg';
import { GenderType } from '../../../../../types/gender-type.enum';
import { Nationality } from '../../../../../types/nationality.enum';
import { RoleType } from '../../../../../types/role-type.enum';
import {
  desktopPadding,
  getTheme,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

// ANCHOR: IMPORTS
interface ErrorInterface {
  error: boolean;
  reason: string;
}

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: '25%',
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    flex: '1',
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },
  disableTextColor: {
    color: 'black !important',
  },
  divisorAvatar: {
    paddingBottom: '10px',
  },
  headerArea: {
    display: 'flex',
  },
  buttonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0.5',
  },
  avatarArea: {
    display: 'flex',
    height: '100px',
  },
  avatarPreview: {
    height: '100px',
    width: '100px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  select: {
    minWidth: '300px',
    color: 'black !important',
  },
  spacer: {
    margin: '0 1rem',
  },
  divSubmitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // ANCHOR: CSS
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  invisible: {
    display: 'none',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
});
interface SortedNationalities {
  key: string;
  translation: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
interface CreateIntermediateProps extends LocalizeContextProps {}
const CreateIntermediate = ({ translate }: CreateIntermediateProps) => {
  const classes = useStyles();

  const [jwt] = UseJWT();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [localIntermediateFirstName, setLocalIntermediateFirstName] =
    useState('');
  const [localIntermediateLastName, setLocalIntermediateLastName] =
    useState('');
  const [localIntermediateNationality, setLocalIntermediateNationality] =
    useState('');
  const [localIntermediateAddress, setLocalIntermediateAddress] = useState('');
  const [localIntermediateLocation, setLocalIntermediateLocation] =
    useState('');
  const [localIntermediateFirstSection, setLocalIntermediateFirstSection] =
    useState('');
  const [localIntermediateLastSection, setLocalIntermediateLastSection] =
    useState('');
  const [localIntermediateBirthDate, setLocalBirthDate] = useState<Date>();
  const [localIntermediateEmail, setLocalIntermediateEmail] = useState('');
  const [localIntermediatePhoneNumber, setLocalIntermediatePhoneNumber] =
    useState('');
  const [localIntermediateGender, setLocalIntermediateGender] = useState('');
  const [localIntermediateCompanyName, setLocalIntermediateCompanyName] =
    useState('');
  const [localPassword, setLocalPassword] = useState('');
  const [localIntermediateIBAN, setLocalIntermediateIBAN] = useState('');
  const [localIntermediateNIF, setLocalIntermediateNIF] = useState('');
  //TODO ver o que é esta variavel
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localPercent, setLocalPercent] = useState<string>('');
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });

  const sortedNationalities = useMemo(
    () =>
      Object.values(Nationality)
        .map(nat => {
          return {
            key: nat,
            translation: translate(`nationalities.${nat}`).toString(),
          };
        })
        .sort((a, b) => a.translation.localeCompare(b.translation)),
    [translate]
  );
  // ANCHOR: VARIABLES
  useEffect(() => {
    setLocalIntermediateFirstName('');
    setLocalIntermediateLastName('');
    setLocalIntermediateNationality(Object.values(Nationality)[0]);
    setLocalIntermediateAddress('');
    setLocalIntermediateLocation('');
    setLocalPercent('');
    setLocalIntermediateFirstSection('');
    setLocalIntermediateLastSection('');
    setLocalBirthDate(moment().toDate());
    setLocalIntermediateEmail('');
    setLocalIntermediatePhoneNumber('');
    setLocalIntermediateGender(GenderType.OTHER);
    setLocalIntermediateCompanyName('');
    setLocalPassword('');
    setLocalIntermediateNIF('');
    setLocalIntermediateIBAN('');
    //EVALUATE
  }, [translate]);

  const changeDate = useCallback(newDate => {
    setLocalBirthDate(newDate);
  }, []);

  //** ERROR CATCH */
  const firstNameError = React.useMemo((): ErrorInterface => {
    if (localIntermediateFirstName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateFirstName]);

  const lastNameError = React.useMemo((): ErrorInterface => {
    if (localIntermediateLastName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateLastName]);
  const zipCode1Error = React.useMemo((): ErrorInterface => {
    if (localIntermediateFirstSection.length !== 4) {
      return {
        error: true,
        reason: 'ZipCode first section should have 4 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateFirstSection]);
  const zipCode2Error = React.useMemo((): ErrorInterface => {
    if (localIntermediateLastSection.length !== 3) {
      return {
        error: true,
        reason: 'ZipCode second section  should have 3 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateLastSection]);
  const addressError = React.useMemo((): ErrorInterface => {
    if (localIntermediateAddress === '') {
      return {
        error: true,
        reason: 'Address should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateAddress]);
  const locationError = React.useMemo((): ErrorInterface => {
    if (localIntermediateLocation === '') {
      return {
        error: true,
        reason: 'Location should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateLocation]);
  const emailError = React.useMemo((): ErrorInterface => {
    if (!validator.isEmail(localIntermediateEmail)) {
      return {
        error: true,
        reason: 'Invalid email',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateEmail]);
  const birthDateError = React.useMemo((): ErrorInterface => {
    var guideAge = moment().diff(localIntermediateBirthDate, 'years', false);
    if (guideAge < 18) {
      return {
        error: true,
        reason: 'Intermediate is under age',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateBirthDate]);
  const phoneNumberError = React.useMemo((): ErrorInterface => {
    if (!validator.isMobilePhone(localIntermediatePhoneNumber)) {
      return {
        error: true,
        reason: 'Phone number must have 9 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediatePhoneNumber]);
  const IBANError = React.useMemo((): ErrorInterface => {
    if (!validator.isIBAN(localIntermediateIBAN)) {
      return {
        error: true,
        reason: 'Bad phone number inserted',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateIBAN]);
  const nifError = useMemo((): ErrorInterface => {
    if (!isTaxID(localIntermediateNIF, 'pt-PT')) {
      return {
        error: true,
        reason: 'NIF is invalid',
      };
    }
    return { error: false, reason: '' };
  }, [localIntermediateNIF]);
  const submitForm = useCallback(async () => {
    if (!jwt) return;
    if (localIntermediateBirthDate === undefined) return;
    if (
      firstNameError.error ||
      lastNameError.error ||
      zipCode1Error.error ||
      zipCode2Error.error ||
      addressError.error ||
      locationError.error ||
      emailError.error ||
      birthDateError.error ||
      phoneNumberError.error ||
      IBANError.error ||
      newPassword.isInvalid ||
      nifError.error
    ) {
      return;
    }

    try {
      setIsSubmitting(true);
      const createDto: CreateIntermediateDto = {
        firstName: localIntermediateFirstName,
        lastName: localIntermediateLastName,
        nif: localIntermediateNIF,
        companyName: localIntermediateCompanyName,
        address: localIntermediateAddress,
        postalCode: `${localIntermediateFirstSection}-${localIntermediateLastSection}`,
        location: localIntermediateLocation,
        phoneNumber: localIntermediatePhoneNumber,
        gender: localIntermediateGender as GenderType,
        iban: localIntermediateIBAN,
        nationality: localIntermediateNationality,
        dateOfBirth: moment(localIntermediateBirthDate).toISOString(),
        email: localIntermediateEmail,
        password: newPassword.value,
        type: RoleType.INTERMEDIATE,
      };
      await createIntermediate(createDto);

      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
    } finally {
      setIsSubmitting(false);
    }
  }, [
    jwt,
    localIntermediateBirthDate,
    firstNameError.error,
    lastNameError.error,
    zipCode1Error.error,
    zipCode2Error.error,
    addressError.error,
    locationError.error,
    emailError.error,
    birthDateError.error,
    phoneNumberError.error,
    IBANError.error,
    newPassword.isInvalid,
    newPassword.value,
    nifError.error,
    localIntermediateFirstName,
    localIntermediateLastName,
    localIntermediateNIF,
    localIntermediateCompanyName,
    localIntermediateAddress,
    localIntermediateFirstSection,
    localIntermediateLastSection,
    localIntermediateLocation,
    localIntermediatePhoneNumber,
    localIntermediateGender,
    localIntermediateIBAN,
    localIntermediateNationality,
    localIntermediateEmail,
    translate,
  ]);
  useMemo(() => {
    const formatStrength =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const hasRightLength =
      localPassword.length >= 8 && localPassword.length <= 20;
    const emailSplit =
      localIntermediateEmail.includes('@') && localIntermediateEmail.split('@');
    const isStrength = localPassword.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (emailSplit &&
        localPassword.includes(emailSplit[0]) &&
        localIntermediateEmail !== '') ||
      localPassword === ''; //TODO: falta fazer validar o email

    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [translate('register.passNoContainNameMail').toString()],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isStrength ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewPassword({
      value: localPassword,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [localPassword, localIntermediateEmail, translate]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.headerArea}>
          <Heading className={classes.title} level={2}>
            <Translate id="adminstration.createIntermediate" />
          </Heading>
        </div>

        <div className={classes.bodyContainer}>
          <div className={classes.infoZone}>
            {/* NAME */}
            <Text noMargin weight="semibold">
              <Translate id="profile.firstName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediateFirstName}
              onChange={ev => {
                setLocalIntermediateFirstName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={firstNameError.error}
              reason={firstNameError.reason}
            />
            <Text noMargin weight="semibold">
              <Translate id="profile.lastName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediateLastName}
              onChange={ev => {
                setLocalIntermediateLastName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={lastNameError.error}
              reason={lastNameError.reason}
            />
            <Divider className={classes.divider} />
            {/* NACIONALIDADE */}
            <Text weight="semibold">
              <Translate id="profile.nationality" />
            </Text>
            <Select
              className={classes.select}
              onChange={v => setLocalIntermediateNationality(v.toString())}
              huge
              value={localIntermediateNationality || undefined}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>
            <Divider className={classes.divider} />
            {/* ADDRESS INFO */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.residence" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediateAddress}
              onChange={ev => {
                setLocalIntermediateAddress(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={addressError.error}
              reason={addressError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.location" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediateLocation}
              onChange={ev => {
                setLocalIntermediateLocation(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={locationError.error}
              reason={locationError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.postalCode" />
            </Text>
            <div className={classes.row}>
              <Input
                className={classes.disableTextColor}
                type="number"
                placeholder="XXXX"
                onChange={ev => {
                  setLocalIntermediateFirstSection(ev.currentTarget.value);
                }}
                value={localIntermediateFirstSection}
              />

              <span className={classes.spacer}>-</span>
              <Input
                className={classes.disableTextColor}
                placeholder="XXX"
                type="number"
                onChange={ev => {
                  setLocalIntermediateLastSection(ev.currentTarget.value);
                }}
                value={localIntermediateLastSection}
              />
            </div>
            <AnimatedError
              isVisible={zipCode1Error.error}
              reason={zipCode1Error.reason}
            />
            <AnimatedError
              isVisible={zipCode2Error.error}
              reason={zipCode2Error.reason}
            />
            <Divider className={classes.divider} />
            {/* EMAIL */}
            <Text noMargin weight="semibold">
              <Translate id="profile.email" />
            </Text>
            <Input
              type={'email'}
              className={classes.disableTextColor}
              value={localIntermediateEmail}
              onChange={ev => {
                setLocalIntermediateEmail(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={emailError.error}
              reason={emailError.reason}
            />
            {/* PASSWORD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.password" />
            </Text>
            <div className={classes.centeredRow}>
              <AppInput
                huge
                type={isPasswordShowing ? 'text' : 'password'}
                placeholder={translate('register.password').toString()}
                onChange={ev => setLocalPassword(ev.target.value)}
                value={localPassword}
                prefix={<Lock className={classes.icon} />}
                suffix={
                  isPasswordShowing ? (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <See className={classes.icon} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <Unsee className={classes.icon} />
                    </div>
                  )
                }
              />
            </div>
            <AnimatedInfo
              isVisible={newPassword.isVisible ? newPassword.isVisible : false}
              infos={newPassword.infos}
            />
            <Divider className={classes.divider} />
            {/* B-DAY */}
            <Text weight="semibold">
              <Translate id="profile.dateOfBirth" />
            </Text>
            <BirthDateSelector
              onDateChange={changeDate}
              isInvalid={true}
              reason={''}
              initialValue={localIntermediateBirthDate}
            />
            <AnimatedError
              isVisible={birthDateError.error}
              reason={birthDateError.reason}
            />
            <Divider className={classes.divider} />
            {/* PHONE NUMBER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.phoneNumber" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediatePhoneNumber}
              onChange={ev => {
                setLocalIntermediatePhoneNumber(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={phoneNumberError.error}
              reason={phoneNumberError.reason}
            />
            <Divider className={classes.divider} />
            {/* GENDER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.gender" />
            </Text>
            <Select
              className={classes.select}
              defaultValue={undefined}
              onChange={v => setLocalIntermediateGender(v.toString())}
              value={localIntermediateGender || undefined}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  key={gender}
                  huge
                  value={gender}
                  label={translate(`genders.${gender}`).toString()}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <Divider className={classes.divider} />
            {/* COMPANY NAME */}
            <Text weight="semibold" noMargin>
              <Translate id="adminstration.companyName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediateCompanyName}
              onChange={ev => {
                setLocalIntermediateCompanyName(ev.target.value);
              }}
            ></Input>
            <AnimatedError isVisible={false} reason={''} />
            {/* IBAN */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.iban" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediateIBAN}
              onChange={ev => {
                setLocalIntermediateIBAN(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={IBANError.error}
              reason={IBANError.reason}
            />
            {/* NIF */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.nif" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localIntermediateNIF}
              onChange={ev => {
                setLocalIntermediateNIF(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={nifError.error}
              reason={nifError.reason}
            />
            <Divider className={classes.divider} />
            <div className={classes.divSubmitButton}>
              <Button
                type="primary"
                shape="round"
                size={'large'}
                onClick={ev => {
                  submitForm();
                }}
              >
                <Translate id="adminstration.submitButton" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(CreateIntermediate);
