import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../../consts';
import { RequestFromTypes } from '../../../types/request-from.enum';
interface CancelBookingDto {
  bookingId: string;
  requestFrom: RequestFromTypes;
}
export const cancelBooking = (bookingId: string) => {
  const dto: CancelBookingDto = {
    bookingId,
    requestFrom: RequestFromTypes.WEB,
  };
  Axios.patch(`${API_URL}/booking/cancel`, dto);
};

type Hook = (
  setCut: (val: SetStateAction<number | null>) => void
) => [boolean, boolean];

export interface RefundInformationInterface {
  refunded: boolean;
  cut: number;
  value: number;
}

export const requestCut = () => Axios.get(`${API_URL}/administrator/cut`);

export const useCut: Hook = setCut => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: cut } = await requestCut();
        setCut(cut as number);
        setIsError(false);
      } catch (err) {
        setCut(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setCut]);

  return [isLoading, isError];
};
