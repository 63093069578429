import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { getTheme } from '../../../utils';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    border: `1px ${getTheme().strongBlue} solid`,
    borderRadius: 5,
    padding: '0.5em',
    minWidth: '60%',
    maxWidth: '60%',
    margin: '0 0.5rem',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  text: {
    color: 'grey',
    margin: 0,
  },
});

interface TipTextContent {
  text: string;
}

function TextContent({ text }: TipTextContent) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text className={classes.text}>{text}</Text>
    </div>
  );
}

export default TextContent;
