import Axios from 'axios';
import { API_URL } from '../../consts';

interface SubjectTranslationDto {
  language: string;
  description: string;
}

export interface EditSubjectDto {
  translations: SubjectTranslationDto[];
}

export const putSubject = (
  id: string,
  dto: EditSubjectDto,
  image?: File | null
) => {
  const formData = new FormData();
  formData.append(
    'editSubjectDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (image) {
    formData.append('image', image);
  }
  return Axios({
    method: 'put',
    url: `${API_URL}/subject/${id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
