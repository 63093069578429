import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Fragment, SetStateAction } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { FallbackAvatar } from '../../../../../../components/App/Avatar';
import { Button } from '../../../../../../components/App/Button';
import { Checkbox } from '../../../../../../components/App/Checkbox';
import { FallbackImage } from '../../../../../../components/App/Image';
import { Heading, Text } from '../../../../../../components/Typography';
import { getVisitorImageUrl } from '../../../../../../hooks/visitor/UseVisitor';
import { ReactComponent as ArrowBackBlue } from '../../../../../../shared_assets/icons/arrow_back_blue.svg';
import { ReactComponent as Next } from '../../../../../../shared_assets/icons/next.svg';
import { ReactComponent as Visitante } from '../../../../../../shared_assets/images/visitante_default.svg';
import { ScheduleDateBookings } from '../../../../../../types/ScheduleDateBookings.interface';
import { getTheme, mobileThreshhold, useIsMobile } from '../../../../../../utils';
import { GenericPageTransition } from '../../../../../PageUtils';
import { Checker } from '../StartRouteModal';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
  },
  circle: () => ({
    display: 'flex',
    position: 'relative',
    borderRadius: '50%',
    width: '1.5rem',
    height: '1.5rem',
    border: `1px solid ${getTheme().primary}`,
    marginRight: '1rem',
  }),
  circleText: () => ({
    position: 'absolute',
    alignSelf: 'center',
    textAlign: 'center',
    width: '100%',
    height: 'auto',
    color: `${getTheme().primary} !important`,
  }),
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    paddingBottom: '5rem',
    '& .ant-divider': {
      margin: '1rem 0',
    },
  },
  mainVisitorContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& .ant-checkbox-wrapper': {
      margin: '0.5rem 1rem 0 0',
    },
    '& .ant-avatar': {
      width: '2rem',
      height: '2rem',
      margin: '0.2rem 1rem 0 0',
    },
  },
  extraVisitorContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.5rem',
    alignItems: 'center',
    '& .ant-checkbox-wrapper': {
      margin: '0 0.5rem',
    },
    '& .ant-avatar': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '0.2rem',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  noMargin: {
    margin: '0',
  },
  standOutBackground: {
    backgroundColor: '#f1f5f7',
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
    backgroundColor: '#ffffff',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 1000,
    padding: '1rem',
    justifyContent: 'space-between',
  },
  bottomBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerDivider: {
    margin: '0.5rem 0 1rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      paddingBottom: '1rem',
    },
  },
});

interface CheckPresenceProps extends LocalizeContextProps {
  scheduleBookings: ScheduleDateBookings | null;
  numberOfAllVisitors: number;
  checkPresence: Checker[][];
  setCheckPresence: (v: SetStateAction<Checker[][]>) => void;
  onBackInAboutToStartModal: () => void;
  onNext: () => void;
}

const CheckPresence = ({
  translate,
  scheduleBookings,
  numberOfAllVisitors,
  checkPresence,
  setCheckPresence,
  onBackInAboutToStartModal,
  onNext,
}: CheckPresenceProps) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.headerContainer}>
        <div className={classes.circle}>
          <span className={classes.circleText}>1</span>
        </div>
        <Heading noMargin level={4} style={{ flex: 1 }}>
          {translate('startRoute.titleCheckPresence').toString()}
        </Heading>
      </div>
      <div className={classes.body}>
        <Heading level={5} color={getTheme().primary} noMargin>
          {numberOfAllVisitors === 1
            ? translate('replaceValue.oneVisitor')
                .toString()
                .replace('{value}', numberOfAllVisitors.toString())
            : translate('replaceValue.visitorsValue')
                .toString()
                .replace('{value}', numberOfAllVisitors.toString())}
        </Heading>
        <Divider className={classes.headerDivider} />
        {scheduleBookings?.bookings.map((booking, index) => (
          <Fragment key={index.toString()}>
            <div
              className={classes.mainVisitorContainer}
              style={
                booking.bookingGroupSize === 1 ? { alignItems: 'center' } : {}
              }
            >
              <Checkbox
                // indeterminate={this.state.indeterminate}
                onChange={() =>
                  setCheckPresence(prevState => {
                    prevState[index][0].checked = !prevState[index][0].checked;
                    prevState[index][0].visitorId = booking.visitorId;
                    return [...prevState];
                  })
                }
                checked={checkPresence[index][0].checked}
              />

              <FallbackAvatar
                src={
                  <FallbackImage
                    src={getVisitorImageUrl(booking.visitorId)}
                    fallbackComponent={<Visitante />}
                  />
                }
              />
              <div>
                <Heading level={5} noMargin>
                  {booking.visitorName}
                </Heading>
                {booking.bookingGroupSize > 1 && (
                  <>
                    <Text color={getTheme().neutral2}>
                      {translate('replaceValue.reservedForVisitors')
                        .toString()
                        .replace(
                          '{value}',
                          booking.bookingGroupSize.toString()
                        )}
                    </Text>
                    {Array.from(
                      new Array(booking.bookingGroupSize - 1),
                      (_, i) => (
                        <div
                          key={i.toString()}
                          className={classes.extraVisitorContainer}
                        >
                          <Visitante />
                          <Checkbox
                            //   options={plainOptions}
                            checked={checkPresence[index][i + 1].checked}
                            onChange={() =>
                              setCheckPresence(prevState => {
                                prevState[index][i + 1].checked = !prevState[
                                  index
                                ][i + 1].checked;
                                return [...prevState];
                              })
                            }
                          />
                          <Text noMargin>
                            {translate('startRoute.oneMoreVisitor').toString()}
                          </Text>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
            <Divider />
          </Fragment>
        ))}
      </div>
      <div
        className={
          isMobile
            ? classNames(classes.bottomContainer, classes.standOutBackground)
            : classes.bottomBetween
        }
      >
        <Button onClick={onBackInAboutToStartModal} prefix={<ArrowBackBlue />}>
          <Translate id="button.previous" />
        </Button>
        <Button type="primary" onClick={onNext} suffix={<Next />}>
          <Translate id="button.next" />
        </Button>
      </div>
    </motion.div>
  );
};

export default withLocalize(CheckPresence);
