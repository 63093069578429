import { useState, useEffect } from 'react';

export function useFilePreview(file: Array<File | null>) {
  const [filePreview, setFilePreview] = useState<(string | null)[]>([]);

  useEffect(() => {
    (async function () {
      if (!file) {
        setFilePreview([]);
        return;
      }

      const previews = await Promise.all(file.map(f => getFilePreview(f)));
      setFilePreview(previews);
    })();
  }, [file]);

  return filePreview;
}

const getFilePreview = (file: File | null): Promise<string | null> =>
  new Promise((resolve, reject) => {
    if (!file) return resolve(null);

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        // convert image file to base64 string
        resolve(reader.result as string);
      },
      false
    );

    reader.readAsDataURL(file);
  });
