import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { store } from '../../store';
import { InvoiceInterface } from '../../types/invoice.interface';

type Hook = (
  setInvoices: (val: SetStateAction<InvoiceInterface[]>) => void,
  id?: string
) => [boolean, boolean];
export const requestVisitorInvoices = () =>
  Axios.get<InvoiceInterface[]>(`${API_URL}/payment/invoices`);
export const requestVisitorInvoicesAdmin = (id: string) =>
  Axios.get<InvoiceInterface[]>(`${API_URL}/payment/invoices/${id}`);
export const useVisitorInvoices: Hook = (setInvoices, id) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let invoices;
        if (id) {
          const { data: response } = await requestVisitorInvoicesAdmin(id);
          invoices = response;
        } else {
          const { data: response } = await requestVisitorInvoices();
          invoices = response;
        }

        setInvoices(invoices);
        setIsError(false);
      } catch (err) {
        setInvoices([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, setInvoices]);

  return [isLoading, isError];
};
export const getInvoiceUrl = async (id: string) => {
  try {
    const { data: link } = await Axios.get<string>(
      `${API_URL}/payment/invoice/${id}?auth_token=${
        store.getState().userConfiguration.jwt || ''
      }&_=${moment().unix()}`
    );
    return link;
  } catch (error) {
    // if (error.response.data.error === ErrorCode.BOOKING_ALREADY_REVIEWED) {
    //  // message.error(translate('reviews.alreadyReviewedRoute'));
    // }
  }
};
