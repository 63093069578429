import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { Heading, Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { TextArea } from '../Input';
import { TabPane, Tabs } from '../Tabs';
import { Tips } from '../Tips';
import { RouteTitle } from './RouteForm';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  bold: {
    fontWeight: '600 !important' as unknown as number,
  },
  titleMargin: {
    marginTop: '2rem',
  },
});

interface TitleStepProps extends LocalizeContextProps {
  titles: RouteTitle[];
  setRouteTitles: (v: SetStateAction<RouteTitle[]>) => void;
}

function TitleStep({ translate, titles, setRouteTitles }: TitleStepProps) {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.mainContainer}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Heading disableAutoscale level={2}>
        <Translate id="routes.routeTitleTitle" />
      </Heading>
      <Text>
        <Translate id="routes.routeTitleSubtitle" />
      </Text>
      <Tips
        header={translate('routes.routeTitleTipTitle').toString()}
        positive={{
          title: translate('routes.routeTitleTipPositive').toString(),
          tips: [translate('routes.routeTitleTipPositive1').toString()],
        }}
        negative={{
          title: translate('routes.routeTitleTipNegative').toString(),
          tips: [translate('routes.routeTitleTipNegative1').toString()],
        }}
        examples={{
          title: translate('routes.routeTitleExampleTitle').toString(),
          content: [
            {
              image: require('../../../shared_assets/images/routeTitleExample1.jpg'),
              text: translate('routes.routeTitleExample1').toString(),
            },
            {
              image: require('../../../shared_assets/images/routeTitleExample2.jpg'),
              text: translate('routes.routeTitleExample2').toString(),
            },
          ],
        }}
      />
      <Heading level={5} className={classes.titleMargin}>
        <Translate id="routes.routeTitleLabel" />
      </Heading>
      <Tabs>
        {titles.map(t => (
          <TabPane
            tab={<Translate id={`languages.${t.language}`} />}
            key={t.language}
          >
            <TextArea
              rows={1}
              value={t.title}
              onChange={ev => {
                ev.persist();
                setRouteTitles(prevState =>
                  prevState.map(desc => {
                    if (desc.language === t.language)
                      return {
                        ...desc,
                        title: ev.target.value,
                      };
                    else return desc;
                  })
                );
              }}
              placeholder={translate('routes.mainTitle').toString()}
            />
          </TabPane>
        ))}
      </Tabs>
      <AnimatedError
        reason={<Translate id="routes.routeTitleMandatory" />}
        isVisible={titles.some(title => validator.isEmpty(title.title))}
      />
    </motion.div>
  );
}

export default withLocalize(TitleStep);
