import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_URL } from '../../consts';
import { store } from '../../store';
import { setGuideUnseenNotifications } from '../../store/App/actions';
import { Visitor } from '../../types/visitor.interface';

type Hook = (
  id: string | undefined,
  setVisitor: (val: SetStateAction<Visitor | null>) => void
) => [boolean, boolean];

export const requestVisitor = (id: string) =>
  Axios.get<Visitor>(`${API_URL}/visitor/${id}`);

export const useVisitor: Hook = (id, setVisitor) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setIsError(true);
        setVisitor(null);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);

        const { data: visitor } = await requestVisitor(id);

        visitor.dateOfBirth = new Date(visitor.dateOfBirth || '');
        visitor.dateOfCreation = new Date(visitor.dateOfCreation);

        setVisitor(visitor);
        setIsError(false);
        dispatch(setGuideUnseenNotifications(visitor.unseenNotificationsCount));
      } catch (err) {
        setVisitor(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, setVisitor, dispatch]);

  return [isLoading, isError];
};

export const getVisitorImageUrl = (id: string) =>
  `${API_URL}/visitor/${id}/avatar?auth_token=${
    store.getState().userConfiguration.jwt || ''
  }&_=${moment().unix()}`;
