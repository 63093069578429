import Divider from 'antd/es/divider';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../components/App/AnimatedError';
import { AnimatedInfo } from '../../components/App/AnimatedInfo';
import { BirthDateSelector } from '../../components/App/BirthDateSelector';
import { Button } from '../../components/App/Button';
import { Checkbox } from '../../components/App/Checkbox';
import { InformationPopup } from '../../components/App/InformationPopup';
import { Input } from '../../components/App/Input';
import Loader from '../../components/App/Loader/Loader';
import { Heading, Text } from '../../components/Typography';
import { useEmailRegistered } from '../../hooks/authentication/useEmailExists';
import { attemptSignUp } from '../../hooks/visitor/addVisitor';
import { ReactComponent as Email } from '../../shared_assets/icons/email.svg';
import { ReactComponent as Lock } from '../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../shared_assets/icons/unsee.svg';
import { ReactComponent as User } from '../../shared_assets/icons/user.svg';
import { ErrorCode } from '../../types/error.codes.enum';
import { RoleType } from '../../types/role-type.enum';
import { complexTranslate, getTheme } from '../../utils';
import { RegistrationMethod } from './ChooseMethod';
import { RegisterType } from './RegisterModal';
import { RegisterFormEntries, useRegisterForm } from './useRegisterForm';

interface birthDate {
  value: moment.Moment | undefined;
  isInvalid?: boolean;
  reason?: string;
}

interface EmailRegistrationProps extends LocalizeContextProps {
  registerType: RegisterType;
  onChangeMethod: (method: RegistrationMethod) => void;
  onRegistrationSuccess: () => void;
  classes: {
    icon: string;
    dateContainer: string;
    marginBetween: string;
    dividerBottom: string;
    justifiedContainer: string;
    inlineBtn: string;
    marginBottom: string;
  };
}

const EmailRegistration: React.FC<EmailRegistrationProps> = ({
  registerType,
  translate,
  classes,
  onChangeMethod,
  onRegistrationSuccess,
}) => {
  const dispatch = useDispatch();
  const form = useRegisterForm();
  const [thereWasARegisterError, setThereWasARegisterError] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);

  const [agreeTerms, setAgreeTerms] = useState<boolean | undefined>(undefined);
  const [agreeMarketing, setAgreeMarketing] = useState<boolean>(false);

  const [birthDate, setBirthDate] = useState<birthDate>({
    value: undefined,
    isInvalid: true,
  });

  const isRegisteredDto = useMemo(
    () => ({
      email: form.email.value,
      type: registerType === 'GUIDE' ? RoleType.GUIDE : RoleType.VISITOR,
    }),
    [form.email.value, registerType]
  );

  const isRegistered = useEmailRegistered(isRegisteredDto);

  const history = useHistory();

  const signUp = useCallback(async () => {
    if (
      Object.values(form).some(formEntry =>
        typeof formEntry === 'function' ? false : formEntry.isInvalid
      ) ||
      !agreeTerms ||
      !birthDate.value ||
      moment().diff(birthDate.value, 'years', true) < 18
    ) {
      if (!agreeTerms) {
        setAgreeTerms(false);
      }

      if (moment().diff(birthDate.value, 'years', true) < 18) {
        setBirthDate(prevState => ({
          ...prevState,
          reason: translate('register.underAge').toString(),
          isInvalid: true,
        }));
      }
      if (!birthDate.value) {
        setBirthDate(prevState => ({
          ...prevState,
          reason: translate('register.badDate').toString(),
          isInvalid: true,
        }));
      }
      Object.values(form).forEach(
        formEntry =>
          typeof formEntry.set === 'function' && formEntry.set(formEntry.value)
      );
      document.getElementsByClassName('modal-scroller')[0].scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }
    try {
      await dispatch(
        attemptSignUp({
          email: form.email.value,
          password: form.password.value,
          type: registerType === 'GUIDE' ? RoleType.GUIDE : RoleType.VISITOR,
          signUpProfile: {
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            dateOfBirth: birthDate.value.startOf('day').toISOString(),
            agreeTerms: agreeTerms ? agreeTerms : false,
            agreeMarketing: agreeMarketing,
          },
        })
      );
      setThereWasARegisterError(false);
      history.replace(
        registerType === 'GUIDE'
          ? '/guide/register/success'
          : '/visitor/register/success'
      );
      onRegistrationSuccess();
    } catch (err) {
      if (err && err.response && err.response.data) {
        if (
          err.response.data.message ===
          ErrorCode.EMAIL_ALREADY_REGISTERED_WITH_EXTERNAL_PROVIDER
        ) {
          setErrorMessage(
            translate(`error.API_ERROR.${err.response.data.message}`).toString()
          );
        } else {
          setErrorMessage(
            translate(`error.API_ERROR.${err.response.data.error}`).toString()
          );
        }
      } else {
        setErrorMessage(translate('error.connectionFailed').toString());
      }
      document.getElementsByClassName('modal-scroller')[0].scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setThereWasARegisterError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, form, agreeTerms, agreeMarketing, translate]);

  const changeDate = useCallback(
    newDate => {
      const isInValid =
        validator.isEmpty(newDate.day) ||
        validator.isEmpty(newDate.month) ||
        validator.isEmpty(newDate.year);

      if (isInValid) {
        setBirthDate(prevState => ({
          ...prevState,
          reason: translate('register.badDate').toString(),
          isInvalid: isInValid,
        }));
      } else {
        const dateBirth = moment(newDate);

        if (moment().diff(dateBirth, 'years', true) < 18) {
          setBirthDate(prevState => ({
            ...prevState,
            value: dateBirth,
            reason: translate('register.underAge').toString(),
            isInvalid: true,
          }));
        } else {
          setBirthDate(prevState => ({
            ...prevState,
            value: dateBirth,
            isInvalid: isInValid,
          }));
        }
      }
    },
    [translate]
  );

  const handleValueChange =
    (field: RegisterFormEntries) =>
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      form[field].set(ev.currentTarget.value);
    };

  return (
    <>
      <InformationPopup
        type="error"
        visible={thereWasARegisterError}
        message={errorMessage}
        className={classes.marginBottom}
      />
      <Input
        huge
        type="email"
        placeholder={translate('register.email').toString()}
        onChange={handleValueChange('email')}
        value={form.email.value}
        prefix={<Email className={classes.icon} />}
      />
      <AnimatedError
        isVisible={form.email.isInvalid || !!isRegistered}
        reason={
          form.email.reason ||
          (isRegistered === 'loading' ? (
            <Loader />
          ) : (
            isRegistered && <Translate id="register.emailIsAlreadyInUse" />
          ))
        }
      />
      <Input
        huge
        type="text"
        placeholder={translate('register.firstName').toString()}
        onChange={handleValueChange('firstName')}
        value={form.firstName.value}
        prefix={<User className={classes.icon} />}
      />
      <AnimatedError
        isVisible={form.firstName.isInvalid}
        reason={form.firstName.reason}
      />
      <Input
        huge
        type="text"
        placeholder={translate('register.lastName').toString()}
        onChange={handleValueChange('lastName')}
        value={form.lastName.value}
        prefix={<User className={classes.icon} />}
      />
      <AnimatedError
        isVisible={form.lastName.isInvalid}
        reason={form.lastName.reason}
      />
      <Input
        huge
        type={isPasswordShowing ? 'text' : 'password'}
        placeholder={translate('register.password').toString()}
        onChange={handleValueChange('password')}
        value={form.password.value}
        prefix={<Lock className={classes.icon} />}
        suffix={
          isPasswordShowing ? (
            <div
              onClick={() => {
                setIsPasswordShowing(prevState => !prevState);
              }}
            >
              <See className={classes.icon} />
            </div>
          ) : (
            <div
              onClick={() => {
                setIsPasswordShowing(prevState => !prevState);
              }}
            >
              <Unsee className={classes.icon} />
            </div>
          )
        }
      />
      <AnimatedInfo
        isVisible={form.password.isVisible ? form.password.isVisible : false}
        infos={form.password.infos}
      />
      <div className={classes.dateContainer}>
        <Text weight="bold">
          <Translate id="register.birthDate" />
        </Text>
        <BirthDateSelector
          onDateChange={changeDate}
          isInvalid={birthDate.isInvalid ? birthDate.isInvalid : false}
          reason={birthDate.reason}
        />
        <Text variant="note" color={getTheme().neutral1}>
          <Translate id="register.birthInfo" />
        </Text>
      </div>
      <Divider className={classNames(classes.marginBetween)} />
      <Text>
        <Translate id="register.emailSendingInfo" />
      </Text>
      <Text noMargin>
        <Checkbox
          onChange={() => {
            setAgreeMarketing(prevState => !prevState);
          }}
        >
          <Text inline variant="faded">
            <Translate id="register.emailChecker" />
          </Text>
        </Checkbox>
      </Text>
      <Divider className={classNames(classes.marginBetween)} />
      <div>
        <Heading level={4}>
          <Translate id="register.serviceTermsTitle" />
        </Heading>
        <Text noMargin>
          <Checkbox
            onChange={() => {
              setAgreeTerms(prevState => !prevState);
            }}
          >
            <Text inline variant="faded">
              {complexTranslate(
                translate('register.serviceTermsInfo').toString(),
                {
                  '{TOS}': (
                    <Link
                      to={`/terms-of-service/#${
                        registerType === 'GUIDE' ? 'guide' : 'visitor'
                      }`}
                    >
                      <Button
                        key={'1'}
                        type="link"
                        className={classes.inlineBtn}
                        onlyText
                      >
                        <Translate id="register.serviceTerms" />
                      </Button>
                    </Link>
                  ),
                  '{PP}': (
                    <Link to="/terms-of-service#privacy">
                      <Button
                        key={'2'}
                        type="link"
                        onlyText
                        className={classes.inlineBtn}
                      >
                        <Translate id="register.privacyPolicy" />
                      </Button>
                    </Link>
                  ),
                  '{TSP}': (
                    <Link to="/terms-of-service#market">
                      <Button
                        key={'3'}
                        type="link"
                        onlyText
                        className={classes.inlineBtn}
                      >
                        <Translate id="register.paymentServiceTerms" />
                      </Button>
                    </Link>
                  ),
                }
              )}
            </Text>
          </Checkbox>
        </Text>
        <div className={classNames(classes.marginBetween)}>
          <AnimatedError
            isVisible={agreeTerms === undefined ? false : !agreeTerms}
            reason={translate('register.noServiceTerms').toString()}
          />
        </div>
      </div>
      <Button type="primary" size="large" onClick={signUp}>
        <Translate id="register.signUp" />
      </Button>
      <Divider className={classNames(classes.dividerBottom)}>
        <Translate id="register.or" />
      </Divider>
      <Text
        style={{ marginBottom: '0rem' }}
        className={classNames(classes.justifiedContainer)}
      >
        {complexTranslate(translate('register.singUpAlternatives').toString(), {
          '{FB}': (
            <Button
              key={'1'}
              type="link"
              onClick={() => {
                onChangeMethod('facebook');
              }}
              onlyText
            >
              <Translate id="register.singUpFaceText" />
            </Button>
          ),
          '{GOOGLE}': (
            <Button
              key={'2'}
              type="link"
              onClick={() => {
                onChangeMethod('google');
              }}
              onlyText
            >
              <Translate id="register.singUpGoogleText" />
            </Button>
          ),
        })}
      </Text>
    </>
  );
};

export default React.memo(withLocalize(EmailRegistration));
