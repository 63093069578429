import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { API_URL } from '../../consts';
import { Description } from '../../types/description.interface';
import { FilterRoute } from '../../types/filter-route.dto';
import { GroupAgeType } from '../../types/group-age-type.enum';
import { RouteActivity } from '../../types/route-activity.interface';
import { DifficultyType } from '../../types/route-difficulty.enum';
import { RouteExperienceType } from '../../types/route-experience.enum';
import { RouteExtraItem } from '../../types/route-extra-item.interface';
import { RouteLocation } from '../../types/route-location.interface';
import { RouteSubject } from '../../types/route-subject.interface';
import { AccessibilityInterface } from '../../types/route.interface';
import { Title } from '../../types/title.interface';
//NOTE: CHANGED
export interface PartialRouteInterface {
  id: string;
  guideId: string;
  titles: Title[];
  languages: string[];
  difficulty: DifficultyType;
  accessibilities: AccessibilityInterface[];
  groupAge: GroupAgeType;
  routeExperience: RouteExperienceType;
  adultPrice: number;
  childrenGreater12yearPrice: number;
  childrenLower12yearPrice: number;
  seniorsPrice: number;
  routeLength: number;
  groupSize: number;
  duration: number;
  location: string;
  averageRating: number;
  numberOfRatings: number;
  activities: RouteActivity[];
  extraItems: RouteExtraItem[];
  minPrice: number;
  subjects: RouteSubject[];
  locations: RouteLocation[];
  descriptions: Description[];
}

type Hook = (
  filterRoute: FilterRoute | null,
  setRoutes: (val: SetStateAction<PartialRouteInterface[]>) => void
) => [boolean, boolean];

const requestSimilarRoutes = (
  filterRoute: FilterRoute,
  cancelToken?: CancelToken
) =>
  Axios.get<PartialRouteInterface[]>(`${API_URL}/route`, {
    params: filterRoute,
    cancelToken: cancelToken,
  });

export const useSimilarRoutes: Hook = (filterRoute, setRoutes) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);
      if (!filterRoute) {
        setIsError(true);
        setRoutes([]);
        setIsLoading(false);
        return;
      }

      try {
        const { data: route } = await requestSimilarRoutes(
          filterRoute,
          cancel.current?.token
        );
        setRoutes(route);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        setRoutes([]);
        if (Axios.isCancel(err)) {
          setIsError(false);
        } else {
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [filterRoute, setRoutes]);

  return [isLoading, isError];
};
