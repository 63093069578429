import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { Modal } from '../../../../../components/App/Modal';
import { Heading, Text } from '../../../../../components/Typography';
import { cancelBooking } from '../../../../../hooks/visitor/booking/cancelBooking';
import { RefundInformationInterface } from '../../../../../hooks/visitor/booking/useRefundInformation';
import { ErrorCode } from '../../../../../types/error.codes.enum';
import { complexTranslate } from '../../../../../utils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  directionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  refundTextContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
  },
  noMargin: {
    margin: '0',
  },
  cancelButton: {
    margin: '2rem 0 1rem 0',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

interface RouteBookingCancelModalProps extends LocalizeContextProps {
  bookingId: string;
  price: number;
  refundInformation: RefundInformationInterface;
  isVisible: boolean;
  onClose: () => void;
}

function RouteBookingCancelModal({
  translate,
  bookingId,
  price,
  refundInformation,
  isVisible,
  onClose,
}: RouteBookingCancelModalProps) {
  const classes = useStyles();
  const history = useHistory();

  const cancelVisitorBooking = useCallback(async () => {
    try {
      await cancelBooking(bookingId);

      //setThereWasABookingError(false);

      history.replace('/visitor/booking/cancel/confirm');
    } catch (err) {
      if (err && err.response && err.response.data) {
        if (err.response.data.error === ErrorCode.USER_IS_VISITOR) {
          //setErrorMessage(translate("register.signUpFailed").toString());
          return <Error />;
        } else {
          //setErrorMessage(err.response.data.message);
          return <Error />;
        }
      } else {
        //setErrorMessage(translate("error.connectionFailed").toString());
        return <Error />;
      }
      //setThereWasABookingError(true);
    }
  }, [bookingId, history]);

  return (
    <Modal
      visible={isVisible}
      variant="close"
      onRequestClose={() => {
        onClose();
      }}
      footer={null}
    >
      <div className={classes.container}>
        <Heading level={2}>
          <Translate id="booking.cancelBooking" />
        </Heading>
        <div className={classes.directionRow}>
          <Text noMargin>{translate('booking.bookingAmount').toString()}</Text>
          <Text noMargin>
            {complexTranslate(translate('replaceValue.totalValue').toString(), {
              '{value}': price.toFixed(2).toString(),
            })}
          </Text>
        </div>
        <Divider />
        <div className={classes.directionRow}>
          <div>
            <Text noMargin>
              <Translate id="booking.uniquepathsFee" />
            </Text>
            <Text
              noMargin
              variant="faded"
              className={classes.refundTextContainer}
            >
              {complexTranslate(translate('booking.notRefundable').toString(), {
                //FIXME: Refund link
                '{knowMore}': (
                  <Link to="/terms-of-service#refund-policy">
                    <Button
                      key="1"
                      type="link"
                      className={classNames(
                        classes.noMargin,
                        classes.inlineBtn
                      )}
                      onlyText
                    >
                      <Translate id="knowMore" />
                    </Button>
                  </Link>
                ),
              })}
            </Text>
          </div>
          <Text noMargin>
            {complexTranslate(translate('replaceValue.totalValue').toString(), {
              '{value}': refundInformation.cut.toFixed(2),
            })}
          </Text>
        </div>
        <Divider />
        <div>
          <Text noMargin>{translate('booking.refundMethod').toString()}</Text>
          <Text noMargin variant="faded">
            <Translate id="booking.descriptionRefundMethod" />
          </Text>
        </div>
        <Divider />
        <div className={classes.directionRow}>
          <Text noMargin>{translate('booking.fullRefund').toString()}</Text>
          <Heading level={3} noMargin>
            {complexTranslate(translate('replaceValue.totalValue').toString(), {
              '{value}': refundInformation.value.toFixed(2),
            })}
          </Heading>
        </div>
        <Button
          type="primary"
          size="large"
          className={classes.cancelButton}
          onClick={ev => {
            cancelVisitorBooking();
            //history.replace('/visitor/booking/cancel/confirm');
          }}
        >
          <Translate id="booking.cancelBooking" />
        </Button>
        <Text variant="faded">
          {complexTranslate(
            translate('booking.cancellationInformation').toString(),
            {
              '{CUT}': refundInformation.cut,
            }
          )}
        </Text>
      </div>
    </Modal>
  );
}

export default withLocalize(RouteBookingCancelModal);
