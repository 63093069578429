import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Check } from '../../../shared_assets/icons/check.svg';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  categoryContainer: ({ imageUrl }: { imageUrl: string }) => ({
    flexDirection: 'column',
    display: '-webkit-flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 5,
    justifyContent: 'space-around',
    padding: '0.5rem',
    cursor: 'pointer',
    position: 'relative',
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  selected: () => ({
    border: `2px solid ${getTheme().primary}`,
    '& > .selected-icon': { display: 'unset' },
  }),
  title: {
    textAlign: 'center',
    margin: 0,
    textTransform: 'initial',
  },
  image: {
    maxWidth: '40%',
  },
  selectedIcon: () => ({
    height: '1rem',
    width: '1rem',
    color: getTheme().strongBlue,
    position: 'absolute',
    top: '0.5rem', //take into account padding
    right: '0.5rem',
    display: 'none',
  }),
});

interface SubjectItemProps {
  name: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  selected: boolean;
  image: string;
}

function SubjectItem({ name, onClick, selected, image }: SubjectItemProps) {
  const classes = useStyles({ imageUrl: image });
  return (
    <div
      className={classNames(
        classes.categoryContainer,
        selected && classes.selected
      )}
      onClick={onClick}
    >
      <Check className={classNames('selected-icon', classes.selectedIcon)} />
      <Text color="white" weight="bold" noMargin>
        {name}
      </Text>
    </div>
  );
}

export default SubjectItem;
