import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { GuideStateType } from '../../types/guide-state-type.enum';

export type CompaniesListResponse = {
  id: string;
  name: string;
  representativeName: string;
  nif: number;
  location: string;
  email: string;
  phoneNumber: string;
  state: GuideStateType;
  magnifinanceToken?: string;
}[];

const requestCompanies = (
  cancel: CancelToken | undefined,
  search: string,
  showOnlyToApprove: boolean
) =>
  Axios.get<CompaniesListResponse[]>(`${API_URL}/company/list`, {
    params: {
      search,
      showOnlyToApprove,
    },
    cancelToken: cancel,
  });

type Hook = (
  setCompaniesList: (val: SetStateAction<CompaniesListResponse[]>) => void,
  info: {
    search?: string;
    showOnlyToApprove?: boolean;
    intermediateId?: string;
  }
) => [boolean, boolean];

export const useListCompanies: Hook = (
  setCompaniesList,
  { search, showOnlyToApprove, intermediateId }
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestCompanies(
          cancel.current?.token,
          search || '',
          !!showOnlyToApprove
        );

        setCompaniesList(list);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setCompaniesList([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [dispatch, search, showOnlyToApprove, intermediateId, setCompaniesList]);

  return [isLoading, isError];
};
