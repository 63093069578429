import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect, useHistory } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { AnimatedError } from '../../../components/App/AnimatedError';
import { FallbackAvatar } from '../../../components/App/Avatar';
import { BackButton } from '../../../components/App/BackButton';
import { BirthDateSelector } from '../../../components/App/BirthDateSelector';
import { Button } from '../../../components/App/Button';
import { Checkbox } from '../../../components/App/Checkbox';
import { Error } from '../../../components/App/Error';
import { FallbackImage } from '../../../components/App/Image';
import { Input } from '../../../components/App/Input';
import { Loader } from '../../../components/App/Loader';
import { Option, Select } from '../../../components/App/Select';
import { Heading, Text } from '../../../components/Typography';
import {
  AccessibilityWithSingleTranslation,
  useAccessibilities,
} from '../../../hooks/accessibilities/useAccessibilities';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../hooks/useFilePreview';
import { editVisitor } from '../../../hooks/visitor/EditVisitor';
import {
  getVisitorImageUrl,
  useVisitor,
} from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { ReactComponent as Contacts } from '../../../shared_assets/icons/contactos.svg';
import { ReactComponent as Home } from '../../../shared_assets/icons/home.svg';
import { ReactComponent as MapMarker } from '../../../shared_assets/icons/map_marker.svg';
import { ReactComponent as User } from '../../../shared_assets/icons/user.svg';
import { ReactComponent as Visitante } from '../../../shared_assets/images/visitante_default.svg';
import { GenderType } from '../../../types/gender-type.enum';
import { CodeISOCountries } from '../../../types/iso-code-countries.enum';
import { Nationality } from '../../../types/nationality.enum';
import { Visitor } from '../../../types/visitor.interface';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  headerHeight,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import { useProfileForm } from './useProfileForm';

const useStyles = makeStyles({
  nifArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  nifInputArea: {
    flex: '0.85',
  },
  countryCodeArea: {
    flex: '0.10',
  },
  select: {
    width: '100%',
  },
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    ...mobilePadding,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    ...mobilePadding,
    alignItems: 'center',
  },
  headerDivider: {
    marginTop: 0,
  },
  backIcon: {
    height: '25%',
  },
  row: { display: 'flex', alignItems: 'center' },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  spacer: {
    margin: '0 1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    marginTop: '1rem',
    '& > *': {
      marginBottom: '1rem',
    },
  },
  avatarPreview: {
    width: '8rem',
    height: '8rem',
    alignSelf: 'center',
  },
  invisible: {
    display: 'none',
  },
  cancelButton: {
    color: getTheme().neutral1,
  },
  infoSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  backZone: {
    flex: 0.2,
    minWidth: 150,
    '& > *:nth-child(2)': {
      marginTop: '1rem',
    },
  },
  visitorSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      flexDirection: 'row',
    },

    infoSection: {
      flex: 0.8,
    },
    visitorSection: {
      flex: 0.4,
      boxShadow: '0px 0px 9px 4px rgba(0,0,0,0.1)',
      padding: '1rem',
      marginRight: '4rem',
      height: '100%',
      borderRadius: 10,
      maxWidth: 360,
    },
  },
});
interface SortedNationalities {
  key: string;
  translation: string;
}
function VisitorProfileEdit({ translate }: LocalizeContextProps) {
  const classes = useStyles();
  const [visitorProfile, setVisitorProfile] = useState<Visitor | null>(null);
  const [jwt] = UseJWT();
  const [isLoading, hasError] = useVisitor(jwt?.visitorId, setVisitorProfile);
  const history = useHistory();
  const form = useProfileForm(visitorProfile);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const previewSource = useMemo(() => [avatar], [avatar]);
  const avatarPreview = useFilePreview(previewSource);
  const [agreeMarketing, setAgreeMarketing] = useState(false);
  const [accessibilities, setAccessibilities] = useState<
    AccessibilityWithSingleTranslation[]
  >([]);
  const [countryCode, setCountryCode] = useState<CodeISOCountries>(
    CodeISOCountries.PT
  );
  const [accessibilitiesLoading] = useAccessibilities(setAccessibilities);
  const [checkedAccessibilities, setCheckedAccessibilities] = useState<
    string[]
  >([]);
  const [onlyShowRoutesWithNeeds, setOnlyShowRoutesWithNeeds] =
    useState<boolean>(false);
  const sortedNationalities: SortedNationalities[] = Object.values(Nationality)
    .map(nat => {
      return {
        key: nat,
        translation: translate(`nationalities.${nat}`).toString(),
      };
    })
    .sort((a, b) => a.translation.localeCompare(b.translation));
  const isMobile = useIsMobile();
  const countryCodeOptions = useMemo(() => {
    const codeOptions = Object.keys(CodeISOCountries).map(d => (
      <Option key={d} value={d}>
        <Text noMargin>{d}</Text>
      </Option>
    ));
    const trueOptions = [
      <Option key={''} value={''}>
        <Text noMargin></Text>
      </Option>,
    ];
    return trueOptions.concat(codeOptions);
  }, []);
  useEffect(() => {
    if (!visitorProfile) return;
    setAgreeMarketing(visitorProfile.agreeMarketing || false);
    setCheckedAccessibilities(visitorProfile.accessibilities);
    setOnlyShowRoutesWithNeeds(visitorProfile.showOnly);
    const countryCode = visitorProfile.nif?.substring(0, 2);
    const setupThisCountryCode = countryCode
      ? (Object.keys(CodeISOCountries).find(
          cc => cc === countryCode
        ) as CodeISOCountries)
      : undefined;
    setCountryCode(
      setupThisCountryCode ? setupThisCountryCode : CodeISOCountries.PT
    );
  }, [visitorProfile]);

  const changeDate = useCallback(newDate => {
    form.birthDay.set(newDate.day);
    form.birthMonth.set(newDate.month);
    form.birthYear.set(newDate.year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editPersonalProfileCallback = useCallback(async () => {
    if (!jwt) return;

    try {
      if (
        Object.entries(form).find(([field, entry]) => {
          if (
            field === 'address' ||
            field === 'zip' ||
            field === 'location' ||
            field === 'nationality' ||
            field === 'nif'
          ) {
            return false;
          }

          if (entry.isInvalid) {
            message.error(entry.reason);
            return true;
          }
          return false;
        })
      ) {
        return;
      }

      if (form.nif.isInvalid) {
        message.error(form.nif.reason);
        return;
      }

      setIsUpdating(true);

      const date = moment.utc({
        year: parseInt(form.birthYear.value),
        month: parseInt(form.birthMonth.value),
        day: parseInt(form.birthDay.value),
      });

      if (!date.isValid()) {
        message.error(translate('profile.birthDateMandatory').toString());
        return;
      }

      await editVisitor(
        jwt.visitorId,
        {
          address: form.address.value,
          firstName: form.firstName.value,
          gender: form.gender.value as GenderType,
          lastName: form.lastName.value,
          location: form.location.value,
          nationality: form.nationality.value,
          nif: form.nif.value,
          phoneNumber: form.phoneNumber.value,
          postalCode: form.zip.value,
          dateOfBirth: date.toDate(),
          agreeMarketing,
          showOnly: onlyShowRoutesWithNeeds,
          accessibilityIds: checkedAccessibilities,
        },
        avatar
      );

      //  updatedVisitor.dateOfBirth = new Date(updatedVisitor.dateOfBirth);

      setAvatar(null);
      //setVisitorProfile(updatedVisitor);
      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
    } finally {
      setIsUpdating(false);
    }
  }, [
    jwt,
    form,
    agreeMarketing,
    onlyShowRoutesWithNeeds,
    checkedAccessibilities,
    avatar,
    translate,
  ]);

  if (!jwt) {
    return <Redirect to="/guide" />;
  }

  if (isLoading || accessibilitiesLoading) {
    return <Loader />;
  }

  if (!visitorProfile || hasError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      {isMobile && (
        <div className={classes.header}>
          <ArrowBack onClick={history.goBack} className={classes.backIcon} />
        </div>
      )}
      <div className={classes.page}>
        {!isMobile && (
          <Heading className={classes.title} level={2}>
            <Translate id="profile.profile" />
          </Heading>
        )}
        <div className={classes.body}>
          {!isMobile && (
            <div className={classes.backZone}>
              <Button
                disabled={isUpdating}
                type="primary"
                onClick={
                  editPersonalProfileCallback
                } /*disabled={saveDisabled}*/
              >
                {isUpdating ? <BeatLoader /> : <Translate id="profile.save" />}
              </Button>
              <BackButton />
            </div>
          )}
          <div className={classes.visitorSection}>
            <FallbackAvatar
              className={classes.avatarPreview}
              src={
                <FallbackImage
                  src={avatarPreview?.[0] || getVisitorImageUrl(jwt.visitorId)}
                  fallback={require('../../../shared_assets/images/visitante_default.svg')}
                  fallbackComponent={<Visitante />}
                />
              }
            />
            <Button
              type="link"
              size="large"
              disabled={isUpdating}
              onClick={ev => {
                avatarInputRef.current?.click();
              }}
            >
              <Translate id="profile.changePhoto" />
            </Button>
            <input
              disabled={isUpdating}
              type="file"
              accept="image/*"
              ref={avatarInputRef}
              className={classes.invisible}
              onChange={ev => {
                if (
                  ev.currentTarget.files &&
                  ev.currentTarget.files.length > 0 &&
                  ev.currentTarget.files[0].size < maxAllowedImageSize
                ) {
                  setAvatar(ev.currentTarget.files[0]);
                } else {
                  message.info(
                    complexTranslate(
                      translate('error.imageFileSize').toString(),
                      {
                        '{linkTinyPNG}': (
                          <a key={'tinypnglink'} href="https://tinypng.com/">
                            <Button
                              key="1"
                              type="link"
                              className={classNames(
                                classes.noMargin,
                                classes.inlineBtn
                              )}
                              onlyText
                            >
                              {translate('error.linkTinyPNG').toString()}
                            </Button>
                          </a>
                        ),
                      }
                    )
                  );
                }
              }}
            />
          </div>
          <div className={classes.infoSection}>
            <Text weight="semibold">
              <Translate id="profile.firstName" />
            </Text>
            <Input
              prefix={<User />}
              placeholder={translate('profile.firstName').toString()}
              huge
              disabled={isUpdating}
              onChange={ev => {
                form.firstName.set(ev.currentTarget.value);
              }}
              value={form.firstName.value}
            />
            <AnimatedError
              isVisible={form.firstName.isInvalid}
              reason={form.firstName.reason}
            />
            <Text weight="semibold">
              <Translate id="profile.lastName" />
            </Text>
            <Input
              prefix={<User />}
              placeholder={translate('profile.lastName').toString()}
              huge
              disabled={isUpdating}
              onChange={ev => {
                form.lastName.set(ev.currentTarget.value);
              }}
              value={form.lastName.value}
            />
            <AnimatedError
              isVisible={form.lastName.isInvalid}
              reason={form.lastName.reason}
            />

            <Text weight="semibold">
              <Translate id="profile.address" />
            </Text>
            <Input
              placeholder={translate('profile.selectAnAddress').toString()}
              huge
              prefix={<Home />}
              disabled={isUpdating}
              onChange={ev => {
                form.address.set(ev.currentTarget.value);
              }}
              value={form.address.value}
            />
            <AnimatedError />
            <Text weight="semibold">
              <Translate id="profile.postalCode" />
            </Text>
            <div className={classes.row}>
              <Input
                disabled={isUpdating}
                type="number"
                huge
                prefix={<Home />}
                placeholder="XXXX"
                onChange={ev => {
                  form.zip.set(
                    `${ev.currentTarget.value}-${form.zip.value.split('-')[1]}`
                  );
                }}
                value={form.zip.value.split('-')[0]}
              />
              <span className={classes.spacer}>-</span>
              <Input
                prefix={<Home />}
                placeholder="XXX"
                type="number"
                huge
                onChange={ev => {
                  form.zip.set(
                    `${form.zip.value.split('-')[0]}-${ev.currentTarget.value}`
                  );
                }}
                value={form.zip.value.split('-')[1]}
              />
            </div>
            <AnimatedError
              isVisible={form.zip.isInvalid}
              reason={form.zip.reason}
            />

            <Text weight="semibold">
              <Translate id="profile.location" />
            </Text>
            <Input
              prefix={<MapMarker />}
              placeholder={translate('profile.selectLocation').toString()}
              huge
              disabled={isUpdating}
              onChange={ev => {
                form.location.set(ev.currentTarget.value);
              }}
              value={form.location.value}
            />
            <AnimatedError />

            <Text weight="semibold">
              <Translate id="profile.birthDate" />
            </Text>

            <BirthDateSelector
              initialValue={visitorProfile.dateOfBirth}
              reason={
                form.birthDay.reason ||
                form.birthMonth.reason ||
                form.birthYear.reason
              }
              isInvalid={
                form.birthDay.isInvalid ||
                form.birthMonth.isInvalid ||
                form.birthYear.isInvalid
              }
              onDateChange={changeDate}
            />
            <AnimatedError />

            <Text weight="semibold">
              <Translate id="profile.phoneNumber" />
            </Text>
            <Input
              placeholder={translate('profile.phoneNumber').toString()}
              huge
              disabled={isUpdating}
              type="number"
              onChange={ev => {
                form.phoneNumber.set(ev.currentTarget.value);
              }}
              prefix={<Contacts />}
              value={form.phoneNumber.value}
            />
            <AnimatedError
              isVisible={form.phoneNumber.isInvalid}
              reason={form.phoneNumber.reason}
            />

            <Text weight="semibold">
              <Translate id="profile.gender" />
            </Text>

            <Select
              defaultValue={undefined}
              huge
              disabled={isUpdating}
              onChange={v => form.gender.set(v.toString())}
              value={form.gender.value || undefined}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  key={gender}
                  huge
                  value={gender}
                  label={translate(`genders.${gender}`).toString()}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <AnimatedError />

            <Text weight="semibold">
              <Translate id="profile.nationality" />
            </Text>
            <Select
              onChange={v => form.nationality.set(v.toString())}
              huge
              disabled={isUpdating}
              value={form.nationality.value || undefined}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>
            <AnimatedError />

            <div className={classes.nifArea}>
              <div className={classes.countryCodeArea}>
                <Text weight="semibold">
                  <Translate id="profile.countryCodeNif" />
                </Text>
                <Select
                  disabled={isUpdating}
                  className={classes.select}
                  value={countryCode}
                  onChange={ev => {
                    form.nif.set(`${ev}${form.nif.value.substring(2)}`);
                    setCountryCode(ev as CodeISOCountries);
                  }}
                  huge
                  getPopupContainer={() => document.body}
                >
                  {countryCodeOptions}
                </Select>
              </div>
              <div className={classes.nifInputArea}>
                <Text weight="semibold">
                  <Translate id="profile.nif" />
                </Text>
                <Input
                  placeholder={translate('profile.nif').toString()}
                  huge
                  disabled={isUpdating}
                  onChange={ev => {
                    form.nif.set(`${countryCode}${ev.currentTarget.value}`);
                  }}
                  value={
                    Object.keys(CodeISOCountries).includes(countryCode)
                      ? form.nif.value.substring(2)
                      : Object.keys(CodeISOCountries).includes(
                          form.nif.value.substring(0, 2)
                        )
                      ? form.nif.value.substring(2)
                      : form.nif.value
                  }
                />
              </div>
              <AnimatedError />
            </div>

            <Text weight="semibold">
              <Translate id="profile.specialNeeds" />
            </Text>

            {accessibilities.map(acc => (
              <div key={acc.id}>
                <Checkbox
                  onChange={() => {
                    if (checkedAccessibilities.includes(acc.id))
                      setCheckedAccessibilities(prevChecked =>
                        prevChecked.filter(checkedAcc => checkedAcc !== acc.id)
                      );
                    else
                      setCheckedAccessibilities(prevChecked => [
                        ...prevChecked,
                        acc.id,
                      ]);
                  }}
                  checked={checkedAccessibilities.includes(acc.id)}
                >
                  <Text inline disableAutoscale>
                    {acc.name}
                  </Text>
                </Checkbox>
              </div>
            ))}
            <br />

            <Checkbox
              checked={onlyShowRoutesWithNeeds}
              onChange={ev => setOnlyShowRoutesWithNeeds(ev.target.checked)}
            >
              <Text variant="note" inline>
                <Translate id="profile.showOnlyRoutesWithNeeds" />
              </Text>
            </Checkbox>
            <Divider />

            <Checkbox
              // className={classes.checkboxContainer}
              onChange={() => {
                setAgreeMarketing(prevState => !prevState);
              }}
            >
              <Text disableAutoscale inline>
                <Translate id="register.emailChecker" />
              </Text>
            </Checkbox>
            {isMobile && (
              <div className={classes.buttonContainer}>
                <Button
                  size="large"
                  disabled={isUpdating}
                  type="primary"
                  onClick={
                    editPersonalProfileCallback
                  } /*disabled={saveDisabled}*/
                >
                  {isUpdating ? (
                    <BeatLoader />
                  ) : (
                    <Translate id="profile.save" />
                  )}
                </Button>
                <Button
                  size="large"
                  type="link"
                  className={classes.cancelButton}
                  disabled={isUpdating}
                  onClick={history.goBack}
                >
                  <Text variant="faded" noMargin>
                    <Translate id="profile.cancel" />
                  </Text>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default withLocalize(VisitorProfileEdit);
