import Axios from 'axios';

import { API_URL } from '../../consts';
import { HelpType } from './useHelp';

interface EditHelpDto {
  content: string;
  type: HelpType;
  id: string;
}

export interface EditHelpResponse {
  content: string;
  type: HelpType;
  id: string;
}

export const editHelp = (dto: EditHelpDto) =>
  Axios.put<EditHelpResponse>(`${API_URL}/content-management/help`, dto);
