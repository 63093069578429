import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { getTheme, measureElement } from '../../../utils';
import { Text } from '../../Typography';

interface StyleProps {
  width: number | undefined;
  variant: DateVariant;
}

const useStyles = makeStyles({
  dayContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  dateCell: ({ width }: StyleProps) => ({
    '&.selected': {
      backgroundColor: getTheme().primary,
      color: 'white',
    },
    '&.today': {
      backgroundColor: getTheme().infoBase,
      color: 'white',
    },
    '&.disabled': {
      backgroundColor: 'white',
      color: getTheme().neutral1,
    },
    // "&:not(.selected):not(.today):hover": {
    //   backgroundColor: `${getTheme().neutral3} !important`,
    // }, // This looks weird on mobile
    borderRadius: '100%',
    padding: 5,
    pointerEvents: 'auto',
    backgroundColor: 'white',
    width,
    position: 'relative',
  }),
  badgy: ({ variant }: StyleProps) => ({
    height: 4,
    width: 4,
    borderRadius: '100%',
    backgroundColor:
      variant === 'selected' || variant === 'today'
        ? 'white'
        : getTheme().primary,
    color: 'white',
    position: 'absolute',
    zIndex: 2,
    bottom: 2,
    left: `calc(50% - ${4 / 2}px)`,
  }),
});

export type DateVariant =
  | 'disabled'
  | 'selected'
  | 'today'
  | 'event'
  | undefined;

interface DateCellProps {
  date: Moment;
  variant: DateVariant;
  eventCount?: number;
}

const DateCell: React.FC<DateCellProps> = ({ date, variant, eventCount }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [cellHeight, setCellHeight] = useState<number | undefined>(undefined);
  const classes = useStyles({ width: cellHeight, variant });

  useEffect(() => {
    if (!ref) return;

    setCellHeight(measureElement(ref.current).height);
  }, [ref]);

  return (
    <div ref={ref}>
      <div className={classes.dayContainer}>
        <Text
          noMargin
          size={12}
          weight={variant === 'disabled' ? undefined : 'bold'}
          variant={variant === 'disabled' ? 'faded' : undefined}
          className={classNames(
            classes.dateCell,
            variant,
            eventCount && eventCount > 0 ? 'event' : ''
          )}
        >
          {(eventCount && eventCount > 0 && (
            <div className={classes.badgy} />
          )) ||
            null}
          {date.get('date')}
        </Text>
      </div>
    </div>
  );
};

export default React.memo(DateCell);
