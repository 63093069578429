import { withStyles } from '@material-ui/styles';
import ConfigProvider from 'antd/es/config-provider';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import frFR from 'antd/es/locale/fr_FR';
import ptPT from 'antd/es/locale/pt_PT';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { CookieConfirmationModal } from '../../components/App/CookieConfirmationModal';
import { Header } from '../../components/App/Header';
import { Loader } from '../../components/App/Loader';
import { FACEBOOK_FIELDS, FACEBOOK_ID, FACEBOOK_SCOPES } from '../../consts';
import { fetchTheme, FullTheme } from '../../hooks/customization/fetchTheme';
import { getSize } from '../../hooks/useCurrentSize';
import AdministrationTranslationsPT from '../../localization/administration/pt_PT.json';
import CompaniesPT from '../../localization/company/pt_PT.json';
import LandingTranslationsEN from '../../localization/landing/en_US.json';
import LandingTranslationsES from '../../localization/landing/es_ES.json';
import LandingTranslationsFR from '../../localization/landing/fr_FR.json';
import LandingTranslationsPT from '../../localization/landing/pt_PT.json';
import { RootState } from '../../store';
import { setReachedBottom, setTheme } from '../../store/App/actions';
import { LastAppZone } from '../../store/App/types';
import { measureElement, mobileThreshhold, theme } from '../../utils';
import { About } from '../About';
import AdminRouter from '../Administration';
import CompanyRouter from '../Company';
import { Contacts } from '../Contacts';
import GuideLandingRouter from '../Guide';
import { ResetPassword } from '../Login/ResetPassword';
import { MobileRouter } from '../Mobile';
import { RouteRouter } from '../Route';
import { RouteBooking } from '../Route/Booking';
import { TermosOfService } from '../TermsOfService';
import { VisitorRouter } from '../Visitor';
import WhereWeAre from '../WhereWeAre/WhereWeAre';

const styles = {
  pageContainer: (props: MainRouterProps) => ({
    '& svg *[fill|="#009CDE"], & *[fill|="#009cde"], & *[fill|="#009ade"], & *[fill|="#009ADE"]':
      {
        fill: (props.theme && props.theme.primary) || undefined,
      },
    '& *[stroke|="#009CDE"], & *[stroke|="#009cde"], & *[stroke|="#009ade"], & *[stroke|="#009ADE"]':
      {
        stroke: (props.theme && props.theme.primary) || undefined,
      },
    '& svg *[fill|="#ffcd00"]': {
      fill: (props.theme && props.theme.secondary) || undefined,
    },
    '& svg *[stroke|="#ffcd00"]': {
      stroke: (props.theme && props.theme.secondary) || undefined,
    },
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    position: 'relative',
    '& > *': {
      flexShrink: 0,
    },
  }),
} as { pageContainer: {} };

interface MainRouterProps extends LocalizeContextProps {
  persistedActiveLanguage: string;
  lastAppZone: LastAppZone;
  jwt: string | null;
  classes: {
    pageContainer: string;
  };
  setReachedBottom: (v: boolean) => void;
  theme: FullTheme | undefined;
  setTheme: (v: FullTheme) => void;
}

interface State {
  shouldStopScroll: boolean;
  headerRef: {
    openLogin: () => void;
  };
}

class MainRouter extends React.PureComponent<MainRouterProps, State> {
  constructor(props: MainRouterProps) {
    super(props);

    this.state = {
      shouldStopScroll: false,
      headerRef: {
        openLogin: () => {},
      },
    };

    this.props.initialize({
      languages: [
        { name: 'Português', code: 'pt_PT' },
        { name: 'English', code: 'en_US' },
        { name: 'Français', code: 'fr_FR' },
        { name: 'Español', code: 'es_ES' },
      ],
      options: { renderToStaticMarkup, defaultLanguage: 'pt_PT' },
    });

    this.props.addTranslationForLanguage(AdministrationTranslationsPT, 'pt_PT');
    this.props.addTranslationForLanguage(LandingTranslationsPT, 'pt_PT');
    this.props.addTranslationForLanguage(LandingTranslationsFR, 'fr_FR');
    this.props.addTranslationForLanguage(LandingTranslationsES, 'es_ES');
    this.props.addTranslationForLanguage(LandingTranslationsEN, 'en_US');
    this.props.addTranslationForLanguage(CompaniesPT, 'pt_PT');
    this.props.setActiveLanguage(this.props.persistedActiveLanguage);

    this.getAntdLocale = this.getAntdLocale.bind(this);
    this.onDrawerStateChange = this.onDrawerStateChange.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.setHeaderRef = this.setHeaderRef.bind(this);
  }

  private containerRef: HTMLDivElement | null = null;

  componentDidMount() {
    this.containerRef?.parentNode?.addEventListener('scroll', this.onScroll);

    fetchTheme().then(cT => {
      this.props.setTheme({ ...theme, ...cT });
    });
  }

  getAntdLocale() {
    const { activeLanguage } = this.props;
    switch (activeLanguage && activeLanguage.code) {
      case 'en_US':
        return enUS;
      case 'fr_FR':
        return frFR;
      case 'es_ES':
        return esES;
      default:
        return ptPT;
    }
  }

  onDrawerStateChange(drawerOpen: boolean) {
    //the prevent scroll behaviour should only be applied to mobile
    if (getSize().width > mobileThreshhold) return;

    if (drawerOpen) {
      this.containerRef?.scrollTo({ top: 0, behavior: 'smooth' });
      this.setState({ shouldStopScroll: true });
    } else {
      this.setState({ shouldStopScroll: false });
    }
  }

  onScroll(ev: Event) {
    const { setReachedBottom } = this.props;
    if (
      //@ts-ignore
      measureElement(this.containerRef).height ===
      measureElement(this.containerRef?.parentNode).height +
        //@ts-ignore

        this.containerRef?.parentNode?.scrollTop
    ) {
      setReachedBottom(true);
    }
  }

  setHeaderRef(ref: any) {
    this.setState({ headerRef: ref });
  }
  // getLocation() {
  //   return useLocation();
  // }
  render() {
    const { classes, lastAppZone, jwt, theme } = this.props;

    const { shouldStopScroll } = this.state;

    if (!theme) return <Loader />;

    return (
      <ConfigProvider
        locale={this.getAntdLocale()}
        getPopupContainer={trigger => {
          return ((trigger && trigger.parentNode) ||
            document.body) as HTMLElement;
        }}
      >
        <>
          <FacebookLogin
            appId={FACEBOOK_ID}
            fields={FACEBOOK_FIELDS}
            scope={FACEBOOK_SCOPES}
            render={() => null}
          />

          <CookieConfirmationModal />

          <Router>
            <div
              id="scrolling-container"
              ref={ref => (this.containerRef = ref)}
              style={{ overflowY: shouldStopScroll ? 'hidden' : undefined }}
              className={classes.pageContainer}
            >
              <Header
                onDrawerStateChange={this.onDrawerStateChange}
                ref={this.setHeaderRef}
              />
              <Route
                render={() => (
                  <AnimatePresence exitBeforeEnter>
                    <Switch key="admin">
                      <Route path="/admin" component={AdminRouter} />
                      <Route path="/company" component={CompanyRouter} />
                      <Route
                        path="/guide"
                        render={(...params) => (
                          <GuideLandingRouter
                            openLogin={this.state.headerRef.openLogin}
                          />
                        )}
                      />
                      <Route path="/visitor" component={VisitorRouter} />
                      <Route
                        path="/route"
                        render={() => (
                          <RouteRouter
                            openLogin={this.state.headerRef.openLogin}
                          />
                        )}
                      />
                      <Route path="/about" component={About} />
                      <Route path="/where-we-are" component={WhereWeAre} />

                      <Route path="/contacts" component={Contacts} />
                      {/* <Route path="/mobile-payment" component={MobilePayment} /> */}
                      <Route
                        path="/password-change/:type"
                        exact
                        component={ResetPassword}
                      />
                      <Route
                        key="booking"
                        path="/booking/:routeId/:scheduleId"
                        exact
                        component={RouteBooking}
                      />
                      <Route
                        key="mobile"
                        path="/mobile"
                        component={MobileRouter}
                      />
                      <Route
                        key="tos"
                        path="/terms-of-service"
                        component={TermosOfService}
                      />
                      <Redirect to={jwt ? `/${lastAppZone}` : '/visitor'} />
                    </Switch>
                  </AnimatePresence>
                )}
              />
            </div>
          </Router>
        </>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  persistedActiveLanguage: state.userConfiguration.activeLanguage,
  lastAppZone: state.app.lastAppZone,
  jwt: state.userConfiguration.jwt,
  theme: state.app.theme,
});

const mapDispatchToProps = {
  setReachedBottom,
  setTheme,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(withStyles(styles)(MainRouter)));
