import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import { motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/App/Button';
import { CopyrightFooter } from '../../../../components/App/CopyrightFooter';
import { Error } from '../../../../components/App/Error';
import { GuideRouteList } from '../../../../components/App/GuideRouteList';
import { GuideRouteInterface } from '../../../../components/App/GuideRouteList/GuideRoute';
import { Loader } from '../../../../components/App/Loader';
import { Heading } from '../../../../components/Typography';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { useGuideRoutes } from '../../../../hooks/guide/UseGuideRoutes';
import { getRouteLocationImageUrl } from '../../../../hooks/routes/UseRoute';
import { ReactComponent as PediRota } from '../../../../shared_assets/icons/pedirota.svg';
import { RootState } from '../../../../store';
import { RouteStateType } from '../../../../types/route-state.enum';
import { Route } from '../../../../types/route.interface';
import {
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
} from '../../../../utils';
import { GenericPageTransition } from '../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    ...mobilePadding,
  },
  button: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  introIcon: {
    marginRight: '1rem',
    minHeight: '5rem',
    height: '5rem',
    minWidth: '5rem',
    width: '5rem',
    objectFit: 'contain',
  },
  dividerMargin: {
    margin: '0.5rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    headerContainer: {
      ...desktopPadding,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    container: {
      ...desktopPadding,
    },
  },
});

interface RouteListProps extends LocalizeContextProps {}

function RouteList({ translate }: RouteListProps) {
  const classes = useStyles();
  const [jwt] = UseJWT();
  const [routes, setRoutes] = useState<Route[]>([]);
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [isLoading, hasError] = useGuideRoutes(jwt?.guideId, setRoutes);
  const guideRoutesPreview: GuideRouteInterface[] = useMemo<
    GuideRouteInterface[]
  >(() => {
    return routes.map(route => {
      const translation = route.titles.find(t => t.language === activeLanguage);
      const title = translation
        ? translation.title
        : route.titles[0]
        ? route.titles[0].title
        : '';
      return {
        id: route.id,
        guideId: route.guideId,
        status: route.state,
        title: title,
        numBookings: route.countBookings,
        numSchedules: route.numberOfSchedules,
        image: route.locations.some(loc => loc.photos.length > 0)
          ? getRouteLocationImageUrl(
              route.locations
                .map(loc => loc.photos.map(photo => photo.id))
                .flat()[0]
            )
          : require('../../../../shared_assets/images/route-default.png'),
      };
    });
  }, [routes, activeLanguage]);

  if (isLoading) {
    return <Loader />;
  }

  if (hasError || !routes) {
    return <Error title={translate('error.basic').toString()} />;
  }

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <PediRota className={classes.introIcon} />
          <Heading level={2} noMargin>
            {translate('routeList.myRoutes').toString()}
          </Heading>
        </div>
        <span className={classes.button}>
          <Link
            to={{
              pathname: '/guide/my-routes/create',
              state: { showIntro: routes.length === 0 },
            }}
          >
            <Button type="primary" size="large">
              {translate('routeList.createRoute').toString()}
            </Button>
          </Link>
        </span>
      </div>
      <div className={classes.container}>
        <GuideRouteList
          onDelete={deletedId =>
            setRoutes(prevRoutes => prevRoutes.filter(r => deletedId !== r.id))
          }
          onHide={hiddenId => {
            setRoutes(prevRoutes =>
              prevRoutes.map(r =>
                r.id === hiddenId ? { ...r, state: RouteStateType.HIDDEN } : r
              )
            );
          }}
          onShow={shownId => {
            setRoutes(prevRoutes =>
              prevRoutes.map(r =>
                r.id === shownId ? { ...r, state: RouteStateType.APPROVED } : r
              )
            );
          }}
          data={guideRoutesPreview ? guideRoutesPreview : []}
        />
        <div style={{ flex: 1 }}></div>
        <Divider className={classes.dividerMargin} />
      </div>
      <CopyrightFooter showSocialLinks />
    </motion.div>
  );
}

export default withLocalize(RouteList);
