import { makeStyles } from '@material-ui/styles';
import { Marker } from '@react-google-maps/api';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { usePosition } from 'use-position';
import { FallbackAvatar } from '../../../../components/App/Avatar';
import { BackButton } from '../../../../components/App/BackButton';
import { Badge as Chip } from '../../../../components/App/Badge';
import { Button } from '../../../../components/App/Button';
import { CopyrightFooter } from '../../../../components/App/CopyrightFooter';
import { Error } from '../../../../components/App/Error';
import { GoogleMap } from '../../../../components/App/GoogleMap';
import { FallbackImage } from '../../../../components/App/Image';
import { Loader } from '../../../../components/App/Loader';
import { MakeReviewModal } from '../../../../components/App/MakeReviewModal';
import { Heading, Text } from '../../../../components/Typography';
import { getGuideImageUrl } from '../../../../hooks/guide/UseGuide';
import { getLocationPhotoUrl } from '../../../../hooks/routes/location/useLocationPhoto';
import { useBooking } from '../../../../hooks/visitor/booking/useBooking';
import {
  RefundInformationInterface,
  useRefundInformation,
} from '../../../../hooks/visitor/booking/useRefundInformation';
import { getInvoiceUrl } from '../../../../hooks/visitor/useVisitorInvoices';
import { ReactComponent as BackWhite } from '../../../../shared_assets/icons/back_white.svg';
import { ReactComponent as Pediguia } from '../../../../shared_assets/images/pediguia_default.svg';
import { BookingState } from '../../../../types/booking-state.enum';
import { Booking } from '../../../../types/booking.interface';
import {
  complexTranslate,
  desktopPadding,
  headerHeight,
  joinWithAnd,
  mobileThreshhold,
  useIsMobile,
} from '../../../../utils';
import { GenericPageTransition } from '../../../PageUtils';
import { RouteBookingCancelModal } from '../Cancel/RouteBookingCancelModal';

const useStyles = makeStyles({
  page: {},
  header: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: headerHeight,
    minHeight: headerHeight,
    alignSelf: 'stretch',
    padding: '0 1rem',
  },
  headerBody: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    '& .background': {
      objectFit: 'cover',
      width: '100%',
      maxHeight: 200,
    },
  },
  headerTextBody: {
    position: 'absolute',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0 5%',
    '& .ant-divider': {
      margin: '1rem 0',
    },
    '& > :last-child': {
      marginBottom: '3rem',
    },
  },
  guideContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  avatar: {
    width: '3rem',
    height: '3rem',
    '& > img, & > svg': {
      objectFit: 'cover',
    },
  },
  buttonMargin: {
    marginTop: '0.3rem',
  },
  marginBetween: {
    margin: '1.5rem 0',
  },
  noMargin: {
    margin: '0',
  },
  uppercase: {
    textTransform: 'uppercase',
    marginBottom: '1rem',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  map: { height: 300 },
  navigation: {
    height: headerHeight,
    padding: '0 5%',
  },
  navigationIcon: {
    height: '25%',
  },
  badge: {
    marginBottom: '0.5rem',
  },
  contentZone: {
    flex: 1,
  },
  row: {
    display: 'flex',
  },
  backZone: { flex: 0.2 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    row: {
      ...desktopPadding,
    },
    contentZone: {
      flex: 1,
    },
    headerBody: {
      borderRadius: 10,
      overflow: 'hidden',
    },
    headerTextBody: {
      justifyContent: 'flex-end',
    },
  },
});

interface RouteState {
  allowCancellation: boolean;
}
const getToken = (location: any): string => {
  if (location.search) {
    const queryString = location.search.split('?')[1];
    const token = queryString.split('=')[1];
    return token;
  } else {
    return '';
  }
};
interface BookingRouteProps extends LocalizeContextProps {}

function BookingRoute({ translate }: BookingRouteProps) {
  const headerSize = 4;
  const location = useLocation<RouteState | undefined>();
  const token = getToken(location);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [isShowingReviewModal, setIsShowingReviewModal] = useState(false);
  const [refundInformation, setRefundInformation] =
    useState<RefundInformationInterface | null>(null);
  const { bookingId } = useParams<{ bookingId: string }>();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [isLoading, hasError] = useBooking(bookingId, setBooking);
  const [fetchingRefundInformation, errorFetchingRefundInformation] =
    useRefundInformation(bookingId, setRefundInformation);
  const badgeType = useMemo(() => {
    if (booking?.state === BookingState.PAYED) return 'success';
    else if (booking?.state === BookingState.CANCELED) return 'error';
    else return 'warning';
  }, [booking]);
  const classes = useStyles();

  const history = useHistory();

  const { latitude, longitude } = usePosition(false);
  const isMobile = useIsMobile();
  useEffect(() => {
    if (token === 'true') {
      setIsShowingReviewModal(true);
    }
  }, [token]);

  const allowCancellation = useMemo(() => {
    if (
      booking?.state === BookingState.PAYED &&
      moment(booking?.date).diff(moment().utc(), 'hours') > 0
    ) {
      return true;
    }
    return false;
  }, [booking]);

  const downloadFile = useCallback(async (invoiceId: number) => {
    window.open(await getInvoiceUrl(invoiceId.toString()));
  }, []);
  if (isLoading || fetchingRefundInformation) return <Loader />;
  if (!booking || hasError || errorFetchingRefundInformation) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.row}>
        {!isMobile && (
          <div className={classes.backZone}>
            <BackButton />
          </div>
        )}
        <div className={classes.contentZone}>
          <div className={classNames(classes.headerBody)}>
            <FallbackImage
              fallback={require('../../../../shared_assets/images/route-default.png')}
              src={getLocationPhotoUrl(booking.routePhotoId)}
              alt="place"
              className="background"
            />
            <div className={classes.headerTextBody}>
              {isMobile && (
                <div className={classes.navigation}>
                  <BackWhite
                    onClick={history.goBack}
                    className={classes.navigationIcon}
                  />
                </div>
              )}
              <div>
                <Chip className={classes.badge} type={badgeType} small>
                  {translate(`bookingState.${booking.state}`).toString()}
                </Chip>
                <Heading
                  ellipsis={{ rows: 4 }}
                  noMargin
                  color="white"
                  level={3}
                >
                  {booking.routeTitle}
                </Heading>
              </div>
            </div>
          </div>
          <div className={classes.container}>
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.date" />
              </Heading>
              <Text variant="faded" noMargin>
                {moment(booking.date).diff(moment(), 'day') > 7
                  ? moment(booking.date).calendar()
                  : moment(booking.date).format('l LT').toString()}
              </Text>
            </div>
            <Divider />
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.local" />
              </Heading>
              <Text variant="faded" noMargin>
                {booking.location}
              </Text>
              <Button
                type="link"
                style={{ margin: '0.25rem 0 1rem 0' }}
                onlyText
              >
                <Translate id="button.directions" />
              </Button>
              <GoogleMap
                mapContainerClassName={classes.map}
                zoom={7}
                center={{
                  lat: booking.locationLatitude,
                  lng: booking.locationLongitude,
                }}
                key={'google_maps_map'}
              >
                <Marker
                  position={{
                    lat: booking.locationLatitude,
                    lng: booking.locationLongitude,
                  }}
                  icon={{
                    scale: 0.0002,
                    url: require('../../../../shared_assets/icons/map pointer.svg'),
                  }}
                  title={`${translate('routeDetails.start').toString()}: ${
                    booking.location
                  }`}
                />
                {latitude && longitude && (
                  <Marker
                    position={{
                      lat: latitude,
                      lng: longitude,
                    }}
                    icon={require('../../../../shared_assets/icons/map pointer.svg')}
                  />
                )}
              </GoogleMap>
            </div>
            <Divider />
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.duration" />
              </Heading>
              <Text variant="faded" noMargin>
                {complexTranslate(
                  translate('replaceValue.durationValue').toString(),
                  {
                    '{value}': (booking.duration / 60).toString(),
                  }
                )}
              </Text>
            </div>
            <Divider />
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.language" />
              </Heading>
              <Text variant="faded" noMargin>
                <Translate id={`languages.${booking.language}`} />
              </Text>
            </div>
            <Divider />
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.difficulty" />
              </Heading>
              <Text variant="faded" noMargin>
                <Translate id={`difficulties.${booking.difficulty}`} />
              </Text>
            </div>
            <Divider />
            <div>
              aaaa
              <Heading level={headerSize}>
                <Translate id="booking.routeExtension" />
              </Heading>
              <Text variant="faded" noMargin>
                {complexTranslate(
                  translate('replaceValue.extensionValue').toString(),
                  { '{value}': booking.routeLength.toFixed(2) }
                )}
              </Text>
            </div>
            <Divider />
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.seeRouteInformation" />
              </Heading>
              <Link to={`/route/${booking.routeId}`}>
                <Button type="link" onlyText>
                  <Translate id="button.seeDetails" />
                </Button>
              </Link>
            </div>
            <Divider />
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.guide" />
              </Heading>

              <div className={classes.guideContainer}>
                <div>
                  <Text noMargin variant="faded">
                    {booking.guideName}
                  </Text>
                  <Link to={`/guide/${booking.guideId}`}>
                    <Button type="link" onlyText>
                      <Translate id="button.seeProfile" />
                    </Button>
                  </Link>
                </div>
                <FallbackAvatar
                  className={classes.avatar}
                  src={
                    <FallbackImage
                      src={getGuideImageUrl(booking.guideId)}
                      fallbackComponent={<Pediguia />}
                    />
                  }
                />
              </div>
            </div>
            <Divider />
            {(booking.extraItems.length > 0 ||
              booking.extraActivities.length > 0) && (
              <>
                <div>
                  <Heading level={headerSize}>
                    <Translate id="booking.guideRequirements" />
                  </Heading>
                  {booking.extraActivities.length > 0 && (
                    <>
                      <Heading level={5}>
                        <Translate id="booking.activities" />
                      </Heading>
                      <Text variant="faded" noMargin>
                        {joinWithAnd(
                          booking.extraActivities.map(
                            activity =>
                              activity.descriptions.find(
                                activityDescription =>
                                  activityDescription.language ===
                                  booking.language
                              )?.description || ''
                          ),
                          translate('and').toString()
                        )}
                      </Text>
                    </>
                  )}
                  {booking.extraItems.length > 0 && (
                    <>
                      <Heading level={5}>
                        <Translate id="booking.extraItems" />
                      </Heading>
                      <Text variant="faded" noMargin>
                        {joinWithAnd(
                          booking.extraItems.map(
                            item =>
                              item.descriptions.find(
                                itemDescription =>
                                  itemDescription.language === booking.language
                              )?.description || ''
                          ),
                          translate('and').toString()
                        )}
                      </Text>
                    </>
                  )}
                </div>
                <Divider />
              </>
            )}
            <div>
              <Heading level={headerSize}>
                <Translate id="booking.totalCost" />
              </Heading>
              <Text variant="faded" noMargin>
                {complexTranslate(
                  translate('replaceValue.totalValue').toString(),
                  {
                    '{value}': booking.price.toFixed(2),
                  }
                )}
              </Text>
              {booking.invoiceId && (
                <Button
                  type="link"
                  onClick={() => {
                    if (booking.invoiceId) {
                      downloadFile(booking.invoiceId);
                    }
                  }}
                  onlyText
                  className={classes.buttonMargin}
                >
                  <Translate id="button.seeInvoice" />
                </Button>
              )}
            </div>
            {refundInformation?.refunded && allowCancellation ? (
              <>
                <Divider />
                <div>
                  <Heading level={headerSize}>
                    <Translate id="booking.cancelBooking" />
                  </Heading>
                  <Text variant="faded" noMargin>
                    {complexTranslate(
                      translate('booking.cancelBookingInfo').toString(),
                      {
                        '{CUT}': refundInformation.cut,
                      }
                    )}
                  </Text>
                  <RouteBookingCancelModal
                    refundInformation={refundInformation}
                    bookingId={booking.id}
                    price={booking.price}
                    onClose={() => {
                      setIsShowingModal(false);
                    }}
                    isVisible={isShowingModal}
                  />
                  <Button
                    className={classes.buttonMargin}
                    type="link"
                    onClick={ev => {
                      setIsShowingModal(true);
                    }}
                    onlyText
                  >
                    <Translate id="button.cancel" />
                  </Button>
                </div>
              </>
            ) : null}
            <MakeReviewModal
              visible={isShowingReviewModal}
              routeId={booking.routeId}
              bookingId={bookingId}
              onDismiss={() => {
                setIsShowingReviewModal(false);
              }}
              onFinish={() => {
                setIsShowingReviewModal(false);
              }}
            />
          </div>
        </div>
      </div>
      <CopyrightFooter />
    </motion.div>
  );
}

export default withLocalize(BookingRoute);
