import { makeStyles } from '@material-ui/styles';
import { message } from 'antd';
import { motion } from 'framer-motion';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useLocation } from 'react-router-dom';
import validator from 'validator';
import { Button } from '../../../../components/App/Button';
import { Calendar } from '../../../../components/App/Calendar';
import { DayOrganizer } from '../../../../components/App/DayOrganizer';
import { Error } from '../../../../components/App/Error';
import { Loader } from '../../../../components/App/Loader';
import { Option, Select } from '../../../../components/App/Select';
import { Heading, Text } from '../../../../components/Typography';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { useGuideRoutes } from '../../../../hooks/guide/UseGuideRoutes';
import { useRefreshToken } from '../../../../hooks/refresh/useRefreshToken';
import { useGuideSchedules } from '../../../../hooks/schedule/UseGuideSchedules';
import { ReactComponent as Add } from '../../../../shared_assets/icons/add.svg';
import { ReactComponent as CalendarIcon } from '../../../../shared_assets/icons/calendar.svg';
import { RouteStateType } from '../../../../types/route-state.enum';
import { Route } from '../../../../types/route.interface';
import { Schedule } from '../../../../types/schedule-new';
import { ScheduleRepetitionStateType } from '../../../../types/schedule-repetition-state-type.enum';
import {
  complexTranslate,
  desktopPadding,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../../utils';
import { GenericPageTransition } from '../../../PageUtils';
import ScheduleManagementModal from './ScheduleManagementModal';
import ScheduleModal from './ScheduleModal';
interface RouteState {
  preSelectedRouteId?: string;
}

const useStyles = makeStyles({
  page: {
    height: `calc(100vh - ${headerHeight + 1}px)`,
    maxHeight: `calc(100vh - ${headerHeight + 1}px)`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
  },
  addIcon: {
    width: '24px !important',
    height: '24px !important',
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'inherit',
    flex: 1,
    overflow: 'auto',
  },
  title: {
    ...mobilePadding,
  },
  informationDivParent: {
    display: 'flex',
    flexDirection: 'column',
  },
  informationDivLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`@media (min-width: ${mobileThreshhold}px)`]: {
    desktopPrefix: {
      width: 40,
      marginRight: '1rem',
    },
    title: {
      ...desktopPadding,
      display: 'flex',
      alignItems: 'center',
    },
    titleColumn: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 1,
    },
    page: {
      overflow: 'unset',
      maxHeight: 'unset',
    },
    pageContent: {
      ...desktopPadding,
      flexDirection: 'row',
      '& > *:first-child': {
        flex: 0.4,
      },
      '& > *:nth-child(2)': {
        flex: 0.6,
      },
    },
    filterArea: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
    },
    routeSelectorNonMobile: {
      width: '50%',
      marginRight: '5rem',
    },
    routeSelectorMobile: {
      width: '80%',
    },
  },
});

interface CreateSchedulesProps extends LocalizeContextProps {}

function CreateSchedules({ translate }: CreateSchedulesProps) {
  const [jwt] = UseJWT();
  const [refreshToken] = useRefreshToken();

  const [selectedDate, setSelectedDate] = useState<Moment>(
    moment().startOf('day')
  );
  const classes = useStyles();
  const [routes, setRoutes] = useState<Route[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [routesAreLoading, routesHaveError] = useGuideRoutes(
    jwt?.guideId,
    setRoutes
  );
  const [filteredSelection, setFilteredSelection] = useState<boolean>(false);
  const location = useLocation<RouteState>();

  const [modalShowing, setModalShowing] = useState<string>('none');
  const [schedulesAreLoading] = useGuideSchedules(
    jwt?.guideId,
    setSchedules,
    refreshToken
  );
  const [doneStart, setDoneStart] = useState<boolean>(false);
  const [approvedRoutes, setApprovedRoutes] = useState<Route[]>([]);
  const [selectedRoutes, setSelectedRoutes] = useState<Route[]>([]);

  const parsedQuery = useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true });
  }, [location.search]);
  const [selectedRouteId, setSelectedRouteId] = useState<string | undefined>(
    location.state?.preSelectedRouteId ||
      parsedQuery?.preSelectedRoute?.toString()
  );

  useEffect(() => {
    if (doneStart && schedulesAreLoading) return;

    if (selectedRouteId && filteredSelection === false) {
      setDoneStart(true);
      setSelectedDate(moment().startOf('day'));
      setModalShowing('new');
    }
    if (parsedQuery.repetition && filteredSelection === false) {
      if (validator.isUUID(parsedQuery.repetition.toString(), 4)) {
        const repDate = schedules
          .map(sch => sch.repetitions)
          .flat()
          .find(rep => rep.id === parsedQuery.repetition?.toString())?.date;
        if (repDate) {
          setDoneStart(true);
          setSelectedDate(moment(repDate).startOf('day'));
          setModalShowing(parsedQuery.repetition.toString());
        }
      } else if (parsedQuery.repetition === 'new') {
        message.info(translate('schedules.canCreateRepetition'), 5);
        parsedQuery.repetition = 'nope';
      }
    }
  }, [
    schedules,
    doneStart,
    schedulesAreLoading,
    parsedQuery.repetition,
    translate,
    selectedRouteId,
    filteredSelection,
  ]);

  const calendarRef = useRef<any>();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (routes.length === 0) return;
    const approvedRoutes = routes.filter(
      r =>
        r.state === RouteStateType.APPROVED ||
        r.state === RouteStateType.DUPLICATED ||
        r.state === RouteStateType.HIDDEN
    );

    setSelectedRouteId(approvedRoutes[0].id);
    setApprovedRoutes(approvedRoutes);
    setSelectedRoutes(
      routes.filter(
        r =>
          r.state === RouteStateType.APPROVED ||
          r.state === RouteStateType.DUPLICATED ||
          r.state === RouteStateType.HIDDEN
      )
    );
  }, [routes]);

  const schedulesWithDetails = useMemo(() => {
    const currentSchedules = schedules.map(sch => ({
      ...sch,
      route: selectedRoutes.find(r => r.id === sch.routeId) as Route,
    }));
    return currentSchedules.filter(cs => cs.route !== undefined);
  }, [schedules, selectedRoutes]);
  const selectApprovedRoutesOptions = useMemo(() => {
    const options = [];
    options.push(
      <Option key={`opt_all`} value={'all'} label={'all'}>
        {<Translate id="routeList.selectPlaceholder" />}
      </Option>
    );
    const viableRoutes = routes.filter(
      r =>
        r.state === RouteStateType.APPROVED ||
        r.state === RouteStateType.DUPLICATED ||
        r.state === RouteStateType.HIDDEN
    );
    const routeOptions = viableRoutes.map(r => {
      return (
        <Option key={`opt_${r.id}`} value={r.id} label={r.id}>
          {r.titles[0] ? r.titles[0].title : ''}
        </Option>
      );
    });
    return options.concat(routeOptions);
  }, [routes]);

  const eventDays = useMemo(() => {
    return schedulesWithDetails
      .map(sch =>
        sch.repetitions
          .filter(rep => rep.state !== ScheduleRepetitionStateType.CANCELED)
          .map(sch => moment(sch.date))
      )
      .flat();
  }, [schedulesWithDetails]);
  const selectRoute = useCallback(
    (id: string) => {
      if (id === 'all') {
        setSelectedRoutes(approvedRoutes);
      } else {
        const route = approvedRoutes.find(ap => ap.id === id);
        if (!route) {
          return;
        }

        setFilteredSelection(true);
        setSelectedRouteId(route.id);
        setSelectedRoutes([route]);
      }
    },
    [approvedRoutes]
  );
  const schedulesToday = useMemo(() => {
    return schedulesWithDetails
      .map(sch =>
        sch.repetitions
          .filter(
            rep =>
              moment(rep.date).isSame(selectedDate, 'day') &&
              rep.state !== ScheduleRepetitionStateType.CANCELED
          )
          .map(rep => ({
            ...rep,
            route: sch.route,
            start: moment(rep.date).format('HH:mm'),
            repetitionType: sch.repetitionType,
            dontAllowBookings: rep.reserveLimit,
            scheduleId: sch.id,
          }))
      )
      .flat();
  }, [schedulesWithDetails, selectedDate]);

  if (routesAreLoading || schedulesAreLoading) return <Loader />;

  if (routesHaveError) return <Error />;
  if (selectedRoutes.length === 0)
    return (
      <div>
        {complexTranslate(translate('schedules.noRoutes').toString(), {
          '{CREATE_ROUTE}': (
            <Button type="link" onlyText href="/guide/my-routes/create">
              <Translate id="routes.createRoute" />
            </Button>
          ),
        })}
      </div>
    );

  return (
    <>
      {modalShowing === 'new' ? (
        <ScheduleModal
          selectedDate={selectedDate}
          visible={modalShowing === 'new'}
          selectedRouteId={
            selectedRouteId ? selectedRouteId : approvedRoutes[0].id
          }
          setSelectedRouteId={setSelectedRouteId}
          onRequestClose={() => {
            refreshToken?.refresh();
            setModalShowing('none');
            setSelectedRouteId(undefined);
          }}
          onDoAction={() => {
            refreshToken?.refresh();
            setModalShowing('none');
          }}
          routes={approvedRoutes}
        />
      ) : null}

      {schedulesToday.map(sch => (
        <ScheduleManagementModal
          key={sch.id}
          scheduleRepetition={sch}
          visible={modalShowing === sch.id}
          onRequestClose={requestRefresh => {
            setModalShowing('none');
            setSelectedRouteId(undefined);
            if (requestRefresh) {
              refreshToken?.refresh();
            }
          }}
        />
      ))}
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        className={classes.page}
        variants={GenericPageTransition}
      >
        <Heading className={classes.title} level={2}>
          {!isMobile && <CalendarIcon className={classes.desktopPrefix} />}
          <div className={classes.titleColumn}>
            <Heading level={2}>
              <Translate id="schedules.calendar" />
            </Heading>

            {!isMobile && (
              <div className={classes.filterArea}>
                <Button
                  type="ghost"
                  onClick={() => calendarRef.current.selectToday()}
                >
                  <Translate id="schedules.today" />
                </Button>
                <Select
                  onChange={value => {
                    selectRoute(value.toString());
                  }}
                  placeholder={<Translate id="routeList.selectPlaceholder" />}
                  className={classes.routeSelectorNonMobile}
                >
                  {selectApprovedRoutesOptions}
                </Select>
              </div>
            )}
          </div>

          {!isMobile &&
            selectedRoutes.filter(e => e.state !== RouteStateType.DUPLICATED)
              .length > 0 && (
              <Button
                type="link"
                onClick={() => setModalShowing('new')}
                prefix={<Add className={classes.addIcon} />}
              >
                <Translate id="schedules.scheduleTime" />
              </Button>
            )}
        </Heading>

        <div className={classes.pageContent}>
          <div className={classes.informationDivParent}>
            <div className={classes.informationDivLeft}>
              {isMobile && (
                <Select
                  onChange={value => {
                    selectRoute(value.toString());
                  }}
                  placeholder={<Translate id="routeList.selectPlaceholder" />}
                  className={classes.routeSelectorMobile}
                >
                  {selectApprovedRoutesOptions}
                </Select>
              )}
              <Text weight="semibold">
                <Translate id="schedules.selectDateInformation" />
              </Text>
            </div>

            <Calendar
              ref={calendarRef}
              noShadow={!isMobile}
              noPadding={!isMobile}
              value={[selectedDate]}
              onChange={newDates => setSelectedDate(newDates[0].startOf('day'))}
              simple={!isMobile}
              extraElement={
                selectedDate.isBefore(moment().startOf('day'), 'day') ||
                selectedRoutes.filter(
                  e => e.state !== RouteStateType.DUPLICATED
                ).length === 0 ? null : (
                  <div onClick={() => setModalShowing('new')}>
                    <Add className={classes.addIcon} />
                  </div>
                )
              }
              events={eventDays}
              alwaysKeepOneDate
              maxSelectedDates={1}
              replaceWhenAtLimit
            />
          </div>
          <DayOrganizer
            date={selectedDate}
            events={schedulesToday.map(sch => {
              return {
                ...sch,
                date: moment(sch.date).utc(),
                title: sch.route.titles[0] ? sch.route.titles[0].title : '',
                duration: sch.route.duration,
                occupation: sch.route.groupSize - sch.freeSlots,
                reserveLimit: sch.route.groupSize,
                eventOnClick: () => {
                  setModalShowing(sch.id);
                },
              };
            })}
            scrollToHour={moment().get('hour')}
          />
        </div>
      </motion.div>
    </>
  );
}

export default withLocalize(CreateSchedules);
