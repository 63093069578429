import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Booking from './Booking/Booking';
import { BookingHistory } from './BookingHistory';
import { BookingRouteList } from './BookingRouteList';

function BookingRouteRouter() {
  return (
    <Switch>
      <Route
        path="/visitor/bookings/history"
        exact
        component={BookingHistory}
      />
      <Route path="/visitor/bookings" exact component={BookingRouteList} />
      <Route
        path="/visitor/bookings/payment-error"
        component={BookingRouteList}
      />
      <Route
        key="booking"
        path="/visitor/bookings/:bookingId"
        exact
        component={Booking}
      />
      ,
      <Redirect to="/visitor" />
    </Switch>
  );
}

export default BookingRouteRouter;
