import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Radio from 'antd/es/radio';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { SetStateAction } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { AccessibilityWithSingleTranslation } from '../../../hooks/accessibilities/useAccessibilities';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { ReactComponent as Info } from '../../../shared_assets/icons/info.svg';
import { GroupAgeType } from '../../../types/group-age-type.enum';
import { RouteActivity } from '../../../types/route-activity.interface';
import { DifficultyType } from '../../../types/route-difficulty.enum';
import { RouteExtraItem } from '../../../types/route-extra-item.interface';
import { Heading, Text } from '../../Typography';
import { Checkbox } from '../Checkbox';
import { DifficultySelector } from '../DifficultySelector';
import { RouteActivities } from '../RouteActivities';
import { RouteExtraItems } from '../RouteExtraItems';
import { Option, Select } from '../Select';

const useStyles = makeStyles({
  mainContainer: {
    '& .ant-radio-group': {
      display: 'flex',
      flexDirection: 'column',
      '& > * ': {
        height: 30,
      },
    },
  },
  tipContainer: {
    display: 'flex',
    '& > *:first-child': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '1rem',
      flexShrink: 0,
    },
  },
  verticalRadio: {
    display: 'block',
    height: 30,
    lineHeight: 30,
    flex: 0,
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  bold: {
    fontWeight: ('bold !important' as unknown) as number,
  },
  activityContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

interface RouteDetailsProps extends LocalizeContextProps {
  selectedDifficulty: DifficultyType;
  setSelectedDifficulty: (v: SetStateAction<DifficultyType>) => void;
  accessibilityIds: string[];
  accessibilities: AccessibilityWithSingleTranslation[];
  setAccessibilityIds: (v: SetStateAction<string[]>) => void;
  maxVisitors: number;
  setMaxVisitors: (v: SetStateAction<number>) => void;
  ageGroup: GroupAgeType;
  setAgeGroup: (v: SetStateAction<GroupAgeType>) => void;
  duration: number;
  setDuration: (v: SetStateAction<number>) => void;
  extraItems: RouteExtraItem[];
  setExtraItems: (v: SetStateAction<RouteExtraItem[]>) => void;
  selectedLanguages: string[];
  activities: RouteActivity[];
  setActivities: (v: SetStateAction<RouteActivity[]>) => void;
}

function RouteDetails({
  selectedDifficulty,
  setSelectedDifficulty,
  accessibilityIds,
  setAccessibilityIds,
  accessibilities,
  maxVisitors,
  setMaxVisitors,
  setAgeGroup,
  ageGroup,
  duration,
  setDuration,
  extraItems,
  setExtraItems,
  selectedLanguages,
  activities,
  translate,
  setActivities,
}: RouteDetailsProps) {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.mainContainer}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Heading disableAutoscale level={2} className={classes.bold}>
        <Translate id="routes.difficultyTitle" />
      </Heading>
      <Text size={16}>
        <Translate id="routes.difficultySubtitle" />
      </Text>
      <DifficultySelector
        value={selectedDifficulty}
        onChange={setSelectedDifficulty}
      />

      <Heading level={5}>
        <Translate id="routes.hasAccessibilityFeaturesTitle" />
      </Heading>
      {accessibilities.map(acc => (
        <div>
          <Checkbox
            checked={accessibilityIds.includes(acc.id)}
            onChange={ev => {
              if (ev.target.checked)
                setAccessibilityIds(prevIds => [...prevIds, acc.id]);
              else
                setAccessibilityIds(prevIds =>
                  prevIds.filter(id => id !== acc.id)
                );
            }}
          >
            <Text inline noMargin>
              {acc.name}
            </Text>
          </Checkbox>
        </div>
      ))}
      <Divider />
      <Heading level={5} className={classes.bold}>
        <Translate id="routes.visitorsTitle" />
      </Heading>
      <Text size={16}>
        <Translate id="routes.maxVisitorsSubtitle" />
      </Text>
      <Select
        size="large"
        value={maxVisitors}
        onChange={e => setMaxVisitors(parseInt(e.toString()))}
        className={classes.marginBottom}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(number => (
          <Option
            value={number}
            label={`${number} ${
              number === 1
                ? translate('route.person').toString()
                : translate('route.people').toString()
            }`}
          >
            {number}{' '}
            {number === 1 ? (
              <Translate id="routes.person" />
            ) : (
              <Translate id="routes.people" />
            )}
          </Option>
        ))}
      </Select>
      <Heading level={5}>
        <Translate id="routes.ageLimitTitle" />
      </Heading>
      <Radio.Group
        onChange={e => setAgeGroup(e.target.value)}
        value={ageGroup}
        className={classes.marginBottom}
      >
        {Object.values(GroupAgeType).map(type => (
          <Radio value={type}>
            <Text inline noMargin>
              <Translate id={`groupAgeType.${type}`} />
            </Text>
          </Radio>
        ))}
      </Radio.Group>
      <Divider />
      <Heading level={5} className={classes.bold}>
        <Translate id="routes.durationTitle" />
      </Heading>
      <Text size={16}>
        <Translate id="routes.durationSubtitle" />
      </Text>
      <Text variant="faded">
        <Translate id="routes.durationIntro" />
      </Text>
      <Select
        size="large"
        value={duration}
        onChange={e => setDuration(parseInt(e.toString()))}
        className={classes.marginBottom}
        style={{ width: '12rem' }}
      >
        {[
          0.5,
          1,
          1.5,
          2,
          2.5,
          3,
          3.5,
          4,
          4.5,
          5,
          5.5,
          6,
          6.5,
          7,
          7.5,
          8,
          8.5,
          9,
          9.5,
          10,
        ].map(number => (
          <Option value={number * 60}>
            {number !== 0.5
              ? `${moment.duration(Math.floor(number), 'hours').humanize()}  ${
                  number - Math.floor(number) > 0
                    ? ` e ${moment.duration(30, 'minutes').humanize()}`
                    : ''
                }`
              : moment.duration(30, 'minutes').humanize()}
          </Option>
        ))}
      </Select>
      <div className={classes.tipContainer}>
        <Info />
        <Text variant="note">
          <Translate id="routes.durationTip" />
        </Text>
      </div>
      <Divider />
      <Heading level={5} className={classes.bold}>
        <Translate id="routes.extraItemsTitle" />
      </Heading>
      <Text variant="faded">
        <Translate id="routes.extraItemsIntro" />
      </Text>
      <RouteExtraItems
        languages={selectedLanguages}
        onChange={setExtraItems}
        extraItems={extraItems}
      />
      <div className={classNames(classes.tipContainer, classes.marginBottom)}>
        <Info />
        <Text variant="note">
          <Translate id="routes.extraItemsTip" />
        </Text>
      </div>
      <Heading level={5} className={classes.bold}>
        <Translate id="routes.activitySubtitle" />
      </Heading>
      <Text variant="faded">
        <Translate id="routes.activityIntro" />
      </Text>
      <RouteActivities
        languages={selectedLanguages}
        onChange={setActivities}
        activities={activities}
      />
    </motion.div>
  );
}

export default React.memo(withLocalize(RouteDetails));
