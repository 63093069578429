import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { SetStateAction, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Button } from '../../../../components/App/Button';
import { CompanyFinancialUser } from '../../../../components/App/CompanyFinancialUser.tsx';
import { PrivateFinancialUser } from '../../../../components/App/PrivateFinancialUser';
import { Option, Select } from '../../../../components/App/Select';
import { Heading, Text } from '../../../../components/Typography';
import {
  CompanyOnboardingInterface,
  useCompanyOnboarding,
} from '../../../../hooks/guide/useCompanyOnboarding';
import { ReactComponent as BackWhite } from '../../../../shared_assets/icons/back_white.svg';
import { ReactComponent as Next } from '../../../../shared_assets/icons/next.svg';
import { FinancialType } from '../../../../types/financial.type';
import {
  castShadow,
  complexTranslate,
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
} from '../../../../utils';
import { SlideFromRightTransition } from '../../../PageUtils';
import { OnBoardingForm } from '../useOnboardingForm';

const useStyles = makeStyles({
  button1Default: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: castShadow,
    borderRadius: '10px',
    height: '45%',
    width: '45%',
    justifyContent: 'center',
    backgroundColor: '#009CDE',
  },
  button2Default: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: castShadow,
    borderRadius: '10px',
    height: '45%',
    width: '45%',
    justifyContent: 'center',
    backgroundColor: '#009CDE',
  },

  button1Over: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: castShadow,
    borderRadius: '10px',
    height: '45%',
    width: '45%',
    justifyContent: 'center',
    backgroundColor: 'yellow',
  },
  button2Over: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: castShadow,
    borderRadius: '10px',
    height: '45%',
    width: '45%',
    justifyContent: 'center',
    backgroundColor: 'yellow',
  },

  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  containerRow: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    padding: '1rem 0',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  alignToBottom: {
    alignSelf: 'flex-end',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    margin: '0 1rem',
  },
  inputsContainer: {
    marginTop: '1rem',
    textAlign: 'initial',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'flex-end',
    margin: '1rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },

    select: {
      width: '100%',
    },
    noMargin: {
      margin: '0',
    },
    inlineBtn: {
      display: 'inline',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  },
});

interface FinancialStepProps extends LocalizeContextProps {
  form: OnBoardingForm;
  setCertificates: (val: SetStateAction<File[]>) => void;
  certificates: File[];
  onRequestPreviousStep: () => void;
  onRequestNextStep: () => void;
}

function FinancialStep({
  translate,
  setCertificates,
  certificates,
  form,
  onRequestPreviousStep,
  onRequestNextStep,
}: FinancialStepProps) {
  const classes = useStyles();
  const [blockNext, setBlockNext] = useState<boolean>(true);
  const [showPrivateForm, setShowPrivateForm] = useState<boolean>(false);
  const [showCompanyForm, setShowCompanyForm] = useState<boolean>(false);
  const [companies, setCompanies] = useState<CompanyOnboardingInterface[]>([]);
  const [selectedFinancialOption, setSelectedFinancialOption] = useState<
    FinancialType | undefined
  >(undefined);
  useCompanyOnboarding(setCompanies);
  const financialOptions = useMemo(() => {
    return companies.length > 0
      ? [
          <Option key={'personalFinancial:_0'} value={FinancialType.PERSONAL}>
            <Text>
              <Translate id="onboarding.personalFinancial" />
            </Text>
          </Option>,
          <Option key={'companyFinancial_1'} value={FinancialType.COMPANY}>
            <Text>
              <Translate id="onboarding.companyFinancial" />
            </Text>
          </Option>,
        ]
      : [
          <Option key={'personalFinancial_1'} value={FinancialType.PERSONAL}>
            <Text>
              <Translate id="onboarding.personalFinancial" />
            </Text>
          </Option>,
        ];
  }, [companies]);
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={SlideFromRightTransition}
    >
      <div className={classes.body}>
        <div className={classes.mainContainerColumn}>
          <Heading level={4}>
            <Translate id="onboarding.bankData" />
          </Heading>
          <Heading level={5}>
            <Translate id="onboarding.whatYourBankData" />
          </Heading>
          <Text variant="faded">
            <Translate id="onboarding.bankDataInfo" />
          </Text>
          <Text weight="bold">
            <Translate id="onboarding.personalFinancialInformation" />
          </Text>
          <Text weight="bold">
            {complexTranslate(
              translate('onboarding.companyFinancialInformation').toString(),
              {
                '{companyRegistry}': (
                  <Button
                    key="1"
                    type="link"
                    className={classNames(classes.noMargin, classes.inlineBtn)}
                    href={`mailto:${translate(
                      'onboarding.companyRegistry'
                    ).toString()}`}
                    onlyText
                  >
                    <Translate id="onboarding.companyRegistry" />
                  </Button>
                ),
              }
            )}
          </Text>
          <Select
            placeholder={
              <Translate id="onboarding.selectFinancialProfilePlaceholder" />
            }
            className={classes.select}
            value={selectedFinancialOption}
            getPopupContainer={() => document.body}
            onChange={(ev: any) => {
              if ((ev as string) === FinancialType.PERSONAL) {
                setSelectedFinancialOption(FinancialType.PERSONAL);
                form.financialType.set(FinancialType.PERSONAL);
                form.company.set('');
                setShowCompanyForm(false);
                setShowPrivateForm(true);
              } else if ((ev as string) === FinancialType.COMPANY) {
                form.nif.set('');
                form.iban.set('');
                form.retention.set('');
                form.ivaExemption.set('');
                form.companyName.set('');
                setCertificates([]);
                setSelectedFinancialOption(FinancialType.COMPANY);
                form.financialType.set(FinancialType.COMPANY);
                setShowCompanyForm(true);
                setShowPrivateForm(false);
              } else {
                setSelectedFinancialOption(undefined);
                setShowCompanyForm(false);
                setShowPrivateForm(false);
              }
            }}
            huge
          >
            {financialOptions}
          </Select>
          <div
            className={
              showPrivateForm === true || showCompanyForm === true
                ? classes.containerColumn
                : classes.containerRow
            }
          >
            {showPrivateForm && (
              <PrivateFinancialUser
                form={form}
                setCertificates={setCertificates}
                certificates={certificates}
                setBlockNext={setBlockNext}
              />
            )}
            {showCompanyForm && (
              <CompanyFinancialUser
                form={form}
                setBlockNext={setBlockNext}
                companies={companies}
              />
            )}
          </div>
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          size="large"
          onClick={onRequestPreviousStep}
          prefix={<BackWhite />}
        >
          <Translate id="button.previous" />
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={blockNext}
          onClick={onRequestNextStep}
          suffix={<Next />}
        >
          <Translate id="button.next" />
        </Button>
      </div>
    </motion.div>
  );
}

export default React.memo(withLocalize(FinancialStep));
