import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import AnimatedError from '../../../../../../components/App/AnimatedError/AnimatedError';
import { FallbackAvatar } from '../../../../../../components/App/Avatar';
import { Button } from '../../../../../../components/App/Button';
import { Error } from '../../../../../../components/App/Error';
import { FallbackImage } from '../../../../../../components/App/Image';
import { TextArea } from '../../../../../../components/App/Input';
import { Heading, Text } from '../../../../../../components/Typography';
import { attemptStartTour } from '../../../../../../hooks/guide/StartTour';
import { getRouteLocationImageUrl } from '../../../../../../hooks/routes/UseRoute';
import { getVisitorImageUrl } from '../../../../../../hooks/visitor/UseVisitor';
import { ReactComponent as ArrowBackBlue } from '../../../../../../shared_assets/icons/arrow_back_blue.svg';
import { ReactComponent as Complete } from '../../../../../../shared_assets/icons/complete_full.svg';
import { ReactComponent as WarningYellow } from '../../../../../../shared_assets/icons/warning_yellow_solid.svg';
import { ReactComponent as Visitante } from '../../../../../../shared_assets/images/visitante_default.svg';
import { ErrorCode } from '../../../../../../types/error.codes.enum';
import { ScheduleDateBookings } from '../../../../../../types/ScheduleDateBookings.interface';
import {
  getTheme,
  mobileThreshhold,
  useIsMobile,
} from '../../../../../../utils';
import { GenericPageTransition } from '../../../../../PageUtils';
import { Checker } from '../StartRouteModal';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
    alignItems: 'center',
  },
  circle: () => ({
    display: 'flex',
    position: 'relative',
    borderRadius: '50%',
    width: '1.5rem',
    height: '1.5rem',
    border: `1px solid ${getTheme().primary}`,
    marginRight: '1rem',
    justifyContent: 'center',
  }),
  circleText: () => ({
    position: 'absolute',
    alignSelf: 'center',
    textAlign: 'center',
    width: '100%',
    height: 'auto',
    color: `${getTheme().primary} !important`,
  }),
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    paddingBottom: '5rem',
    '& .ant-divider': {
      margin: '1rem 0',
    },
  },
  bookingRouteContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '5px',
  },
  imageContainer: {
    flex: '1',
    marginRight: '1rem',
  },
  bookingRouteInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: '3',
    '& > *': {
      margin: '0',
    },
  },
  avatarsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '0.5rem',
    alignItems: 'center',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  marginBetween: {
    margin: '1.5rem 0',
  },
  noMargin: {
    margin: '0',
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  standOutBackground: {
    backgroundColor: '#f1f5f7',
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    height: '70px',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
    backgroundColor: '#ffffff',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 1000,
    padding: '1rem',
    justifyContent: 'space-between',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  iconContainer: {
    paddingRight: '1rem',
    '& img, & svg': {
      width: '1.5rem',
      height: '1.5rem',
      minWidth: '1.5rem',
      minHeight: '1.5rem',
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  confirmationInformation: {
    display: 'flex',
    flexDirection: 'row',
  },
  bottomBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      paddingBottom: '1rem',
    },
  },
});

interface ReadyToStartProps extends LocalizeContextProps {
  repetitionId: string;
  scheduleBookings: ScheduleDateBookings;
  routeTitle: string;
  routeDuration: number;
  routeImageId: string;
  numberOfAllVisitors: number;
  checkPresence: Checker[][];
  onPrevious: () => void;
  onDone: () => void;
}

const ReadyToStart = ({
  translate,
  repetitionId,
  scheduleBookings,
  routeTitle,
  routeDuration,
  routeImageId,
  numberOfAllVisitors,
  checkPresence,
  onPrevious,
  onDone,
}: ReadyToStartProps) => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [commentary, setCommentary] = useState('');

  const howMany = useMemo(
    () =>
      checkPresence.map(checks => checks.filter(check => check.checked)).flat()
        .length,
    [checkPresence]
  );

  const handleSubmit = async () => {
    try {
      await attemptStartTour(repetitionId, {
        bookingsPeoplePresent: scheduleBookings.bookings.map((booking, i) => ({
          bookingId: booking.bookingId,
          peoplePresent: checkPresence[i].filter(ch => ch.checked).length,
        })),
        commentary,
      });

      onDone();
      history.replace('/');
    } catch (err) {
      if (err && err.response && err.response.data) {
        if (err.response.data.error === ErrorCode.USER_IS_VISITOR) {
          //setErrorMessage(translate("register.signUpFailed").toString());
          return <Error />;
        } else {
          //setErrorMessage(err.response.data.message);
          return <Error />;
        }
      } else {
        //setErrorMessage(translate("error.connectionFailed").toString());
        return <Error />;
      }
      //setThereWasABookingError(true);
    }
  };

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.headerContainer}>
        <div className={classes.circle}>
          <span className={classes.circleText}>2</span>
        </div>
        <Heading noMargin level={4} style={{ flex: 1 }}>
          {translate('startRoute.titleStartRoute').toString()}
        </Heading>
      </div>
      <div className={classes.body}>
        <div
          className={classNames(
            classes.bookingRouteContainer,
            classes.marginBetween
          )}
        >
          <div className={classes.imageContainer}>
            <FallbackImage
              className={classes.image}
              src={getRouteLocationImageUrl(routeImageId)}
              fallback={require('../../../../../../shared_assets/images/route-default.png')}
              alt="place"
            />
          </div>
          <div className={classes.bookingRouteInfo}>
            <Heading
              level={5}
              ellipsis={{ rows: 2 }}
              noMargin
              className={classes.bold}
            >
              {routeTitle}
            </Heading>
            <Text color={getTheme().neutral2} noMargin>
              {`${moment(scheduleBookings.date).format(
                'D MMM YYYY, [das] LT'
              )} às ${moment(scheduleBookings.date)
                .add(routeDuration, 'hours')
                .format('LT')}`}
            </Text>
          </div>
        </div>
        <Heading level={2} className={classNames(classes.textAlignCenter)}>
          {translate('startRoute.questionAllReadytoStart').toString()}
        </Heading>
        <div
          className={classNames(classes.textAlignCenter, classes.marginBetween)}
        >
          <div className={classes.avatarsContainer}>
            {checkPresence.map(check =>
              check.map((checklist, i) =>
                checklist.checked ? (
                  checklist.visitorId ? (
                    <FallbackAvatar
                      key={i.toString()}
                      src={
                        <FallbackImage
                          src={getVisitorImageUrl(checklist.visitorId)}
                          fallbackComponent={<Visitante />}
                        />
                      }
                    />
                  ) : (
                    <Visitante key={i.toString()} />
                  )
                ) : null
              )
            )}
            {howMany > 8 && (
              <div className={classes.circle}>
                <Text noMargin size={12} className={classes.circleText}>
                  +{howMany - 8}
                </Text>
              </div>
            )}
          </div>
          <Heading level={4} color={getTheme().primary} noMargin>
            {howMany === 1
              ? translate('replaceValue.oneVisitor')
                  .toString()
                  .replace('{value}', howMany.toString())
              : translate('replaceValue.visitorsValue')
                  .toString()
                  .replace('{value}', howMany.toString())}
          </Heading>
          {numberOfAllVisitors !== howMany && (
            <Text color={getTheme().neutral2} noMargin>
              {translate('replaceValue.bookingsHaveBeenMade')
                .toString()
                .replace(
                  '{value}',
                  numberOfAllVisitors ? numberOfAllVisitors.toString() : ''
                )}
            </Text>
          )}
        </div>
        <div className={classNames(classes.confirmationInformation)}>
          <Text size={18} className={classes.iconContainer}>
            {numberOfAllVisitors === howMany ? <Complete /> : <WarningYellow />}
          </Text>

          {numberOfAllVisitors === howMany ? (
            <Text size={18}>
              {translate('startRoute.allVisitorsHaveBeenConfirmed').toString()}
            </Text>
          ) : (
            <div>
              <Text size={16} noMargin>
                {translate(
                  'startRoute.someVisitorsHaveNotBeenConfirmed'
                ).toString()}
              </Text>
              <Text>
                {translate('startRoute.evenIfNotYouCanStart').toString()}
              </Text>
              <TextArea
                value={commentary}
                onChange={ev => setCommentary(ev.target.value)}
              />
              <AnimatedError
                isVisible={validator.isEmpty(commentary)}
                reason={<Translate id="startRoute.commentaryMandatory" />}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          isMobile
            ? classNames(classes.bottomContainer, classes.standOutBackground)
            : classes.bottomBetween
        }
      >
        <Button onClick={onPrevious} prefix={<ArrowBackBlue />}>
          <Translate id="button.previous" />
        </Button>
        <Button
          type="primary"
          disabled={
            numberOfAllVisitors !== howMany && validator.isEmpty(commentary)
          }
          onClick={handleSubmit}
        >
          <Translate id="button.startRoute" />
        </Button>
      </div>
    </motion.div>
  );
};

export default withLocalize(ReadyToStart);
