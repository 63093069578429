import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { Route } from '../../types/route.interface';

type Hook = (
  setRoutes: (val: SetStateAction<Route[]>) => void
) => [boolean, boolean];

export const requestRoutes = () => Axios.get(`${API_URL}/route`);

export const useRoutesAdmin: Hook = setRoutes => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: route } = await requestRoutes();
        setRoutes(route as Route[]);
      } catch (err) {
        setRoutes([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setRoutes]);

  return [isLoading, isError];
};
