import { makeStyles } from '@material-ui/styles';
import Collapse from 'antd/es/collapse';
import React, { useCallback, useState } from 'react';

import { getTheme } from '../../../utils';
import CollapseHeader from './CollapseHeader';
import EditRouteCollapsibleContent from './EditRouteCollapsibleContent';

interface EditRouteCollapsibleProps {
  activeMajorStep: number;
  activeMinorStep: number;
  setMajorStep: (v: number) => void;
  setMinorStep: (v: number) => void;
}

export const useStyles = makeStyles({
  majorContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    '& .step-container': {
      width: '1.2rem',
      height: '1.2rem',
      borderRadius: '1.2rem',
      color: 'white',
      backgroundColor: getTheme().primary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '0.5rem',
    },
  }),
  minorContainer: () => ({
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '& .active': {
      cursor: 'default',
      color: `${getTheme().primary} !important`,
      backgroundColor: getTheme().neutral6,
    },
    '& > * > * ': {
      paddingLeft: '1.7rem',
    },
  }),
  divider: { margin: '0.5rem 0' },
});

const EditRouteCollapsible = ({
  activeMajorStep,
  activeMinorStep,
  setMajorStep,
  setMinorStep,
}: EditRouteCollapsibleProps) => {
  const classes = useStyles();
  const [activeKey, setActiveKey] = useState<string[] | string | undefined>();

  const proxiedSetMinor = useCallback(
    (v: number) => {
      setMinorStep(v);
      setActiveKey(undefined);
    },
    [setMinorStep]
  );

  return (
    <Collapse
      expandIconPosition="right"
      activeKey={activeKey}
      onChange={setActiveKey}
    >
      <Collapse.Panel
        key="1"
        header={
          <CollapseHeader
            activeMajorStep={activeMajorStep}
            activeMinorStep={activeMinorStep}
            classes={classes}
          />
        }
      >
        <EditRouteCollapsibleContent
          setMajorStep={setMajorStep}
          setMinorStep={proxiedSetMinor}
          activeMajorStep={activeMajorStep}
          activeMinorStep={activeMinorStep}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default EditRouteCollapsible;
