import { useState, useEffect, SetStateAction } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../consts';
import { Booking } from '../../../types/booking.interface';

type Hook = (
  bookingId: string | undefined,
  setBooking: (val: SetStateAction<Booking | null>) => void
) => [boolean, boolean];

export const requestVisitorBookings = (bookingId: string) =>
  Axios.get<Booking>(`${API_URL}/booking/${bookingId}`);

export const useBooking: Hook = (bookingId, setBookings) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!bookingId) {
        setIsError(true);
        setBookings(null);
        setIsLoading(false);

        return;
      }

      try {
        const { data: booking } = await requestVisitorBookings(bookingId);

        setBookings(booking as Booking);
      } catch (err) {
        setBookings(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [bookingId, setBookings]);

  return [isLoading, isError];
};
