import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { ReactComponent as ArrowBackBlue } from '../../../shared_assets/icons/arrow_back_blue.svg';
import { ReactComponent as Next } from '../../../shared_assets/icons/next.svg';
import { mobileThreshhold } from '../../../utils';
import { Button } from '../Button';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1rem',
    height: 64,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    alignItems: 'center',
    //     -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.75);
    // -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.75);
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
  },
  text: {
    margin: 0,
  },
  button: {
    textTransform: 'initial',
    fontWeight: ('600 !important' as unknown) as number,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    footer: {
      boxShadow: 'none',
      position: 'relative',
      padding: 0,
    },
  },
});

interface CreateNewRouteStickyFooterProps {
  moveForward: () => void;
  moveBack: () => void;
  showBackBtn: boolean;
  canMoveForward: boolean;
  showFinish: boolean;
  onFinish: () => void;
}

export default function CreateNewRouteStickyFooter({
  moveForward,
  canMoveForward,
  moveBack,
  showBackBtn,
  onFinish,
  showFinish,
}: CreateNewRouteStickyFooterProps) {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      {showBackBtn ? (
        <Button
          type="ghost"
          className={classes.button}
          onClick={moveBack}
          prefix={<ArrowBackBlue />}
        >
          <Translate id="button.previous" />
        </Button>
      ) : (
        <div />
      )}
      {showFinish ? (
        <Button
          type="primary"
          className={classes.button}
          onClick={onFinish}
          disabled={!canMoveForward}
          suffix={<Next />}
        >
          <Translate id="routes.sendForAnalysis" />
        </Button>
      ) : (
        <Button
          type="primary"
          className={classes.button}
          onClick={() => {
            moveForward();
          }}
          disabled={!canMoveForward}
          suffix={<Next />}
        >
          <Translate id="button.next" />
        </Button>
      )}
    </div>
  );
}
