import React, { useCallback, useEffect, useState } from 'react';

interface FallbackImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
  fallbackComponent?: React.ReactElement;
}

const FallbackImage: React.FC<FallbackImageProps> = ({
  fallback,
  src,
  fallbackComponent,
  alt,
  ...rest
}: FallbackImageProps) => {
  const [mySource, setMySource] = useState(src || fallback);
  const [error, setError] = useState<boolean>(false);

  const onError = useCallback(() => {
    setError(true);
    if (fallback) setMySource(fallback);
  }, [fallback]);

  const onLoad = useCallback(() => {
    setError(false);
  }, []);

  useEffect(() => {
    setMySource(src || fallback);
  }, [src, fallback]);

  if (((error && !fallback) || !src) && fallbackComponent) {
    return fallbackComponent;
  }

  return (
    <img {...rest} src={mySource} alt={alt} onError={onError} onLoad={onLoad} />
  );
};

export default FallbackImage;
