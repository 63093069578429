import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';

import { API_URL } from '../../consts';

export type ThemeResponse = {
  hostname: string;
  id: string;
  primary: string;
  secondary: string;
};

const fetchTheme = (id: string) =>
  Axios.get<ThemeResponse>(`${API_URL}/content-management/theme/${id}`);

type Hook = (
  id: string | undefined,
  setter: (v: SetStateAction<ThemeResponse | undefined>) => void
) => [boolean, boolean];

export const useTheme: Hook = (id, setTheme) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await fetchTheme(id);
        setTheme(data);
      } catch (err) {
        setTheme(undefined);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setTheme, id]);

  return [isLoading, isError];
};
