import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  div: {
    borderRadius: '5px',
    textAlign: 'center',
    display: 'inline-flex',
    padding: '0.1rem 0.6rem',
    border: 'solid',
    borderWidth: 'thin',
  },
});

interface Badge2Interface {
  type: 'default' | 'warning' | 'error' | 'success' | 'none' | 'primary';
  text: string;
}
// 0, 165, 132 GREEN
// 228, 221, 0 YELLOW
// 222, 65, 0 RED
//rgb(0,165,255)
const Badge2 = (badge2Interface: Badge2Interface) => {
  const classes = useStyles();
  let color = '#ffffff';
  let textColor = '#ffffff';
  switch (true) {
    case badge2Interface.type === 'warning':
      color = '#e4dd00';
      textColor = '#000000';
      break;
    case badge2Interface.type === 'success':
      color = '#00a584';
      break;
    case badge2Interface.type === 'error':
      color = '#de4100';
      break;
    case badge2Interface.type === 'primary':
      color = '#00a5ff';
      break;
    default:
      color = '#ffffff';
      textColor = '#000000';
      break;
  }
  return (
    <div className={classes.div} style={{ backgroundColor: color }}>
      <Text className={'text'} color={textColor} noMargin>
        {badge2Interface.text}
      </Text>
    </div>
  );
};

export default React.memo(Badge2);
