import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Heading, Text } from '../../Typography';
import { Button } from '../Button';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: ('600 !important' as unknown) as number,
  },
  button: {
    justifyContent: 'initial',
    padding: 0,
  },
});

export interface FaqProps {
  question: string;
  answer: string;
  link?: string;
}

export default function Faq({ question, answer, link }: FaqProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <Heading level={5} className={classes.bold}>
        {question}
      </Heading>
      <Text variant="faded" noMargin>
        {answer}
      </Text>
      {link && (
        <Button
          type="link"
          className={classes.button}
          onClick={() => history.push(`${link}`)}
        >
          <Translate id={'button.toKnowMore'} />
        </Button>
      )}
    </div>
  );
}
