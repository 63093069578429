import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { getLocationPhotoUrl } from '../../../hooks/routes/location/useLocationPhoto';
import { VisitorBookingInterface } from '../../../hooks/visitor/booking/useVisitorBookings';
import { getTheme, useIsMobile } from '../../../utils';
import { Text } from '../../Typography';
import { Badge } from '../Badge';
import { Button } from '../Button';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyItems: 'center',
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '150px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'cover',
    borderRadius: 6,
  },
  imageContainer: {
    flex: 1,
    overflow: 'hidden',
    alignItems: 'center',
    maxHeight: 100,
    maxWidth: 200,
  },
  bookingRouteInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    '& > *': {
      margin: 0,
    },
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstLetterUpper: {
    textTransform: 'initial',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  badgeMargin: {
    marginBottom: '0.5rem',
  },
});

export interface VisitorBookingProps extends VisitorBookingInterface {}

function VisitorBooking({
  id,
  state,
  routeTitle,
  locationPhoto,
  repetitionDate,
}: VisitorBookingProps) {
  const classes = useStyles();
  let stateColor = getTheme().warningBase;

  if (state === 'PAYED') stateColor = getTheme().successBase;
  else if (state === 'CANCELED') stateColor = getTheme().errorBase;

  const isMobile = useIsMobile();

  return (
    <div className={classes.page} key={id.toString()}>
      <div className={classes.bookingRouteInfo}>
        {isMobile ? (
          <Text
            variant="note"
            color={stateColor}
            weight="semibold"
            noMargin
            className={classNames(classes.uppercase)}
          >
            <Translate id={`bookingState.${state}`} />
          </Text>
        ) : (
          <Badge
            className={classes.badgeMargin}
            type={
              stateColor === getTheme().errorBase
                ? 'error'
                : stateColor === getTheme().successBase
                ? 'success'
                : 'warning'
            }
          >
            <Translate id={`bookingState.${state}`} />
          </Badge>
        )}

        <Text ellipsis={{ rows: 2 }} weight="semibold" noMargin>
          {routeTitle}
        </Text>
        <Text variant="note" color={getTheme().neutral1}>
          {moment(repetitionDate).format('l[,] LT')}
        </Text>
        <Link
          to={{
            pathname: `/visitor/bookings/${id}`,
            state: { allowCancellation: true },
          }}
        >
          <Button onlyText type="link">
            <Text
              variant="note"
              color={getTheme().primary}
              noMargin
              className={classes.firstLetterUpper}
            >
              <Translate id="button.showMore" />
            </Text>
          </Button>
        </Link>
      </div>
      <div className={classes.imageContainer}>
        <FallbackImage
          className={classes.image}
          fallback={require('../../../shared_assets/images/route-default.png')}
          src={getLocationPhotoUrl(locationPhoto)}
          alt="place"
        />
      </div>
    </div>
  );
}

export default VisitorBooking;
