/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles } from '@material-ui/styles';
import { DatePicker, Divider, Space, Table } from 'antd';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import Badge2 from '../../../../../components/App/Badge2/Badge2';
import { Error } from '../../../../../components/App/Error';
import { TextArea } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { Text } from '../../../../../components/Typography';
import { useRouteAdmin } from '../../../../../hooks/routes/useRouteAdmin';
import { useRouteSchedulesAdmin } from '../../../../../hooks/schedule/useSchedulesRepetitionAdmin';
import { Route } from '../../../../../types/route.interface';
import { Schedule } from '../../../../../types/schedule-new';
import { ScheduleRepetition } from '../../../../../types/schedule-repetition';
import { ScheduleRepetitionStateType } from '../../../../../types/schedule-repetition-state-type.enum';
import { mobileThreshhold } from '../../../../../utils';
import ScheduleAdminModal from '../../../../Guide/Schedules/CreateSchedules/schedulesModalAdmin';
const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});
interface ParamsInterface {
  guideId: string;
  routeId: string;
}
interface RouteScheduleListProps extends LocalizeContextProps {}
const RouteScheduleList = ({ translate }: RouteScheduleListProps) => {
  const params = useParams<ParamsInterface>();
  const classes = useStyles();
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [fetchingSchedules, hasError] = useRouteSchedulesAdmin(
    params.routeId,
    setSchedules
  );
  const [route, setRoute] = useState<Route | null>(null);
  const [createScheduleDateHour, setCreateScheduleDateHour] = useState(
    moment().startOf('day')
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [routeIsLoading, routeHasError] = useRouteAdmin(
    params.routeId,
    setRoute
  );
  const [modalShowing, setModalShowing] = useState<string>('none');
  const [dateFilter, setDateFilter] = useState<Date | null>(null);
  const [reason, setReason] = useState<string>('');
  const [editRepetition, setEditRepetition] = useState<ScheduleRepetition>();
  const [showCancelRepetitionModal, setShowCancelRepetitionModal] =
    useState<boolean>(false);
  const [cancelRepetition, setCancelRepetition] =
    useState<ScheduleRepetition | null>(null);
  const dataPicker = useCallback((event: any) => {
    setDateFilter(event);
  }, []);
  const editScheduleCallback = useCallback((repetition: ScheduleRepetition) => {
    setEditRepetition(repetition);
    setModalShowing('edit');
  }, []);
  const showCancelCallback = useCallback((repetition: ScheduleRepetition) => {
    setCancelRepetition(repetition);
    setShowCancelRepetitionModal(true);
  }, []);
  const columns = useMemo(
    () => [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => {
          switch (text) {
            case ScheduleRepetitionStateType.TO_START:
              return (
                <Badge2
                  key={record.key}
                  type={'primary'}
                  text={translate(
                    'adminstration.scheduleStateToStart'
                  ).toString()}
                />
              );
            case ScheduleRepetitionStateType.CANCELED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate(
                    'adminstration.scheduleStateCanceled'
                  ).toString()}
                />
              );
            case ScheduleRepetitionStateType.FINISHED:
              return (
                <Badge2
                  key={record.key}
                  type={'success'}
                  text={translate(
                    'adminstration.scheduleStateFinished'
                  ).toString()}
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  type={'warning'}
                  text={translate(
                    'adminstration.scheduleStateInProgress'
                  ).toString()}
                />
              );
          }
        },
      },
      {
        title: <Translate id="adminstration.schedulePrice" />,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: (
          <>
            <Translate id="adminstration.scheduleDate" />
            <Divider type="vertical" />
            <DatePicker onChange={ev => dataPicker(ev)} />
          </>
        ),
        dataIndex: 'start',
        key: 'start',
      },
      {
        title: <Translate id="adminstration.routeEndAt" />,
        dataIndex: 'end',
        key: 'end',
      },

      {
        title: <Translate id="adminstration.repetitionLanguage" />,
        dataIndex: 'language',
        key: 'language',
      },

      {
        title: <Translate id="adminstration.repetitionFreeSlots" />,
        dataIndex: 'free',
        key: 'free',
      },
      {
        title: <Translate id="adminstration.limitReserve" />,
        dataIndex: 'limit',
        key: 'limit',
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <Divider type="vertical" />
            <a
              onClick={ev => {
                editScheduleCallback(record.repetition);
              }}
            >
              <Translate id="adminstration.editSchedule" />
            </a>
            <Divider type="vertical" />
            <a
              onClick={ev => {
                showCancelCallback(record.repetition);
              }}
            >
              <Translate id="adminstration.cancelSchedule" />
            </a>
          </Space>
        ),
      },
    ],
    [dataPicker, editScheduleCallback, showCancelCallback, translate]
  );

  const localTableData = useMemo(() => {
    //TODO: VER ISTO
    if (dateFilter === null) {
      return schedules
        .map(s => {
          return s.repetitions.map(r => {
            return {
              key: r.id,
              state: r.state,
              price: r.adultPrice ? `${r.adultPrice}€` : 'por definir',
              start: moment(r.date).format('LL, LTS'),
              end: moment(r.date)
                .add({
                  minutes: route ? route.duration : 0, //TODO ALTERAR
                })
                .format('LL, LTS'),
              language: r.language ? r.language : 'por definir',
              limit: `${r.reserveLimit} min`,
              free: `${(route ? route.groupSize : 0) - r.freeSlots} / ${
                route?.groupSize
              }`,
              repetition: r,
            };
          });
        })
        .flat(1);
    } else {
      return schedules
        .map(s => {
          return s.repetitions.filter(r =>
            moment(dateFilter).isSame(r.date, 'day')
          );
        })
        .flat(1)
        .map(r => {
          return {
            key: r.id,
            state: r.state,
            price: r.adultPrice ? `${r.adultPrice}€` : 'por definir',
            start: moment(r.date).format('LL, LTS'),
            end: moment(r.date)
              .add({
                minutes: route ? route.duration : 0, //TODO ALTERAR
              })
              .format('LL, LTS'),
            language: r.language ? r.language : 'por definir',
            limit: `${r.reserveLimit} min`,
            free: `${r.freeSlots - (route ? route.groupSize : 0)} / ${
              route?.groupSize
            }`,
            repetition: r,
          };
        });
    }
  }, [dateFilter, schedules, route]);

  const createScheduleCallback = useCallback(() => {
    setModalShowing('new');
  }, []);

  const cancelRepetitionCallback = useCallback(() => {
    if (!cancelRepetition) return;

    if (validator.isEmpty(reason)) {
      return;
    }

    setSchedules(
      schedules.map(s => ({
        ...s,
        repetitions: s.repetitions.filter(r => r.id !== cancelRepetition.id),
      }))
    );
    setShowCancelRepetitionModal(false);
    message.error(translate('adminstration.scheduleSuccessCancel').toString());
  }, [cancelRepetition, schedules, reason, translate]);

  if (fetchingSchedules || !route) return <Loader />;
  if (hasError) return <Error />;
  return (
    <>
      <ScheduleAdminModal
        guideId={params.guideId}
        selectedDate={createScheduleDateHour}
        visible={modalShowing === 'new'}
        selectedRouteId={params ? params.routeId : ''}
        onRequestClose={() => {
          setCreateScheduleDateHour(
            moment().startOf('day').add(moment().hour(), 'hour')
          );
          setModalShowing('none');
        }}
        routeDuration={route.duration}
        onDoAction={(data: any) => {
          setModalShowing('none');
          setCreateScheduleDateHour(
            moment().startOf('day').add(moment().hour(), 'hour')
          );
          setSchedules(schedules => [...schedules, data]);

          message.success(
            translate('adminstration.scheduleSuccess').toString()
          );
        }}
      />

      <ScheduleAdminModal
        guideId={params.guideId}
        selectedDate={
          editRepetition?.date ? moment(editRepetition.date) : moment()
        }
        routeDuration={route.duration}
        visible={modalShowing === 'edit'}
        repetition={editRepetition}
        selectedRouteId={params ? params.routeId : ''}
        onRequestClose={() => setModalShowing('none')}
        onDoAction={(data: any) => {
          setModalShowing('none');
          setSchedules(schedules =>
            schedules.map(schedule =>
              schedule.id === data.id ? data : schedule
            )
          );
          message.success(
            translate('adminstration.editScheduleSuccess').toString()
          );
        }}
      />
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setReason('');
                setShowCancelRepetitionModal(false);
              }}
            >
              <Translate id="button.cancel" />
            </Button>
            <div className={classes.addRemoveBtn}>
              <Button
                type="primary"
                onClick={() => {
                  setReason('');
                  cancelRepetitionCallback();
                }}
              >
                <Translate id="button.save" />
              </Button>
            </div>
          </div>
        }
        visible={showCancelRepetitionModal}
        closable={false}
      >
        <Text weight="semibold">
          <Translate id="adminstration.reason" />
        </Text>
        <TextArea
          value={reason}
          rows={10}
          onChange={ev => {
            ev.persist();
            setReason(ev.target.value);
          }}
        />
        <AnimatedError
          isVisible={validator.isEmpty(reason)}
          reason={
            <Translate id="adminstration.guideLandingPage.mandatoryField" />
          }
        />
      </Modal>

      <div className={classes.divAddButton}>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            createScheduleCallback();
          }}
        >
          <Translate id="adminstration.createSchedule" />
        </Button>
      </div>
      <Table dataSource={localTableData} columns={columns} />
    </>
  );
};
export default withLocalize(RouteScheduleList);
