import Axios from 'axios';

import { API_URL } from '../../consts';

interface ChangePasswordDto {
  newPassword: string;
  oldPassword: string;
}

export const changePassword = (userId: string, dto: ChangePasswordDto) => {
  return Axios.patch(
    `${API_URL}/authentication/user/${userId}/reset-password`,
    dto
  );
};
