import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Text } from '../../Typography';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: 'unset',
    padding: '0.5rem 0',
    cursor: 'pointer',
  },
  iconContainer: {
    fontSize: '1.5rem',
    paddingRight: '1rem',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'start',
    '& img, & svg, & .ant-badge, & .ant-avatar': {
      height: 20,
      width: 20,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {},
  title: {
    margin: 0,
  },
});

interface DrawerItemProps extends Omit<LinkProps, 'title' | 'to'> {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  subtitle?: React.ReactNode;
  to?: string | { pathname: string; state: Object };
  onClick?: () => void;
  closeOnClick?: boolean;
  setOpenStatus?: (open: boolean) => void;
}

export default function DrawerItem({
  icon,
  subtitle,
  title,
  to,
  onClick,
  closeOnClick,
  setOpenStatus,
  ...linkProps
}: DrawerItemProps) {
  const classes = useStyles();

  const action = useCallback(() => {
    if (onClick) onClick();
    if (closeOnClick && setOpenStatus) setOpenStatus(false);
  }, [closeOnClick, onClick, setOpenStatus]);

  return (
    <>
      {to ? (
        <Link
          to={to}
          onClick={action}
          className={classes.container}
          {...linkProps}
        >
          <div className={classes.iconContainer}>{icon}</div>
          <div className={classes.textContainer}>
            <Text noMargin className={classes.title}>
              {title}
            </Text>
            {subtitle}
          </div>
        </Link>
      ) : (
        <div onClick={action} className={classes.container}>
          <div className={classes.iconContainer}>{icon}</div>
          <div className={classes.textContainer}>
            <Text noMargin className={classes.title}>
              {title}
            </Text>
            {subtitle}
          </div>
        </div>
      )}
    </>
  );
}
