import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import { ReactComponent as Edit } from '../../../shared_assets/icons/edit.svg';
import { RootState } from '../../../store';
import { RouteExtraItem } from '../../../types/route-extra-item.interface';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';

const useStyles = makeStyles({
  container: {
    height: 40,
    borderRadius: 5,
    border: `2px ${getTheme().neutral4} solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0',
  },
});

interface ExtraItemEntryProps {
  extraItem: RouteExtraItem;
  onRequestExtraItemChange: () => void;
}

const ExtraItemEntry: React.FC<ExtraItemEntryProps> = ({
  extraItem,
  onRequestExtraItemChange,
}) => {
  const classes = useStyles();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );

  const extraItemName = useMemo(() => {
    const extraItemName = extraItem.descriptions.find(
      desc => desc.language === activeLanguage
    )?.description;
    if (extraItemName) return extraItemName;
    else return extraItem.descriptions[0].description;
  }, [extraItem, activeLanguage]);

  return (
    <div className={classes.container}>
      <Text noMargin inline>
        {`${extraItemName}`}
      </Text>
      <Button
        type="link"
        onlyText
        onClick={onRequestExtraItemChange}
        prefix={<Edit />}
      >
        <Translate id="button.edit" />
      </Button>
    </div>
  );
};

export default ExtraItemEntry;
