import { makeStyles } from '@material-ui/styles';
import { Divider, Space, Table } from 'antd';
import Button from 'antd/es/button';
import { default as React, useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Badge2 from '../../../../../components/App/Badge2/Badge2';
import { Error } from '../../../../../components/App/Error';
import { Loader } from '../../../../../components/App/Loader';
import { useGuideRoutes } from '../../../../../hooks/guide/UseGuideRoutes';
import { RouteStateType } from '../../../../../types/route-state.enum';
import { Route } from '../../../../../types/route.interface';
const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
});

interface ParamsInterface {
  id: string;
}
const GuideRouteList = ({ translate }: LocalizeContextProps) => {
  const history = useHistory();
  const params = useParams<ParamsInterface>();
  const classes = useStyles();
  const [routes, setRoutes] = useState<Route[]>([]);
  const [fetchingRoutes, hasError] = useGuideRoutes(params?.id, setRoutes);
  const tableData = routes.map(r => {
    return {
      key: r.id,
      title: r.titles[0] ? r.titles[0].title : '',
      location: r.location,
      state: r.state,
      guideId: r.guideId,
    };
  });
  const createGuideCallback = useCallback(() => {
    history.push(`/admin/area/guide/${params.id}/create-route`);
  }, [history, params.id]);
  const columns = useMemo(() => {
    return [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => {
          switch (text) {
            case RouteStateType.APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'success'}
                  text={translate('adminstration.guideApproved').toString()}
                />
              );
            case RouteStateType.NOT_APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideNotApproved').toString()}
                />
              );
            case RouteStateType.DRAFT:
              return (
                <Badge2
                  key={record.key}
                  type={'primary'}
                  text={translate('adminstration.draft').toString()}
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  type={'warning'}
                  text={translate(
                    'adminstration.guideWaitingApproval'
                  ).toString()}
                />
              );
          }
        },
      },
      {
        title: <Translate id="adminstration.name" />,
        dataIndex: 'title',
        key: 'title',
      },

      {
        title: <Translate id="adminstration.location" />,
        dataIndex: 'location',
        key: 'location',
      },

      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            {record.state !== 'DRAFT' && (
              <>
                <Link
                  to={`/admin/area/guide/${record.guideId}/route/${record.key}`}
                >
                  <Translate id="adminstration.showRoute" />
                </Link>
                <Divider type="vertical" />
              </>
            )}
            <Link
              to={`/admin/area/guide/${record.guideId}/edit/route/${record.key}`}
            >
              <Translate id="adminstration.editRoute" />
            </Link>
            <>
              <Divider type="vertical" />
              <Link
                to={`/admin/area/guide/${record.guideId}/route/${record.key}/list/schedules`}
              >
                <Translate id="adminstration.scheduleRoute" />
              </Link>
            </>
          </Space>
        ),
      },
    ];
  }, [translate]);
  if (fetchingRoutes) return <Loader />;
  if (hasError) return <Error />;
  return (
    <>
      <div className={classes.divAddButton}>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            createGuideCallback();
          }}
        >
          <Translate id="adminstration.createRoute" />
        </Button>
      </div>
      <Table dataSource={tableData} columns={columns} />
    </>
  );
};

export default withLocalize(GuideRouteList);
