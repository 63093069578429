/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Axios from 'axios';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import validator from 'validator';
import isTaxID from 'validator/lib/isTaxID';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { FallbackAvatar } from '../../../../../components/App/Avatar';
import { BirthDateSelector } from '../../../../../components/App/BirthDateSelector';
import {
  Button as AppButton,
  Button as LinkButton,
} from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { FallbackImage } from '../../../../../components/App/Image';
import {
  Input as AppInput,
  TextArea,
} from '../../../../../components/App/Input';
import { LanguageSelection } from '../../../../../components/App/LanguageSelection';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { Option, Select } from '../../../../../components/App/Select';
import { SkillSelection } from '../../../../../components/App/SkillSelection';
import { TabPane, Tabs } from '../../../../../components/App/Tabs';
import { Heading, Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import {
  FinancialInterface,
  useFinancial,
} from '../../../../../hooks/administration/useFinancial';
import {
  IntermediateListInterface,
  useIntermediates,
} from '../../../../../hooks/administration/useIntermediates';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { editGuide } from '../../../../../hooks/guide/EditGuide';
import {
  getGuideImageUrl,
  useGuide,
} from '../../../../../hooks/guide/UseGuide';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as Bill } from '../../../../../shared_assets/icons/bill.svg';
import { ReactComponent as Lock } from '../../../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../../../shared_assets/icons/unsee.svg';
import { ReactComponent as Upload } from '../../../../../shared_assets/icons/upload.svg';
import { ReactComponent as Pediguia } from '../../../../../shared_assets/images/pediguia_default.svg';
import { setMarkerCount } from '../../../../../store/App/actions';
import { AdminStats } from '../../../../../store/App/types';
import { GenderType } from '../../../../../types/gender-type.enum';
import { GuideStateType } from '../../../../../types/guide-state-type.enum';
import { GuideDescriptionDto } from '../../../../../types/guide.dtos';
import {
  Guide,
  IntermediateInfoInterface,
} from '../../../../../types/guide.interface';
import { Nationality } from '../../../../../types/nationality.enum';
import { RoleType } from '../../../../../types/role-type.enum';
import { makeEnumArray, SkillType } from '../../../../../types/skill-type.enum';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  headerHeight,
  maxAllowedFileSize,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

// ANCHOR: IMPORTS
interface FormState {
  editing: boolean;
  guideId: string;
}
interface ErrorInterface {
  error: boolean;
  reason: string;
}
interface EvaluateDto {
  state: GuideStateType;
  description: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
const useStyles = makeStyles({
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  passwordInput: {
    maxWidth: '300px',
    marginRight: '1rem',
  },

  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: '25%',
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    flex: '1',
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },
  disableTextColor: {
    color: 'black !important',
  },
  divisorAvatar: {
    paddingBottom: '10px',
  },
  headerArea: {
    display: 'flex',
  },
  buttonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0.5',
  },
  avatarArea: {
    display: 'flex',
    height: '100px',
  },
  avatarPreview: {
    height: '100px',
    width: '100px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  evaluateApproveButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
    marginLeft: '50px',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  select: {
    minWidth: '300px',
    color: 'black !important',
  },
  spacer: {
    margin: '0 1rem',
  },
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // ANCHOR: CSS
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  invisible: {
    display: 'none',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
});
interface ParamsInterface {
  id: string;
}
interface SortedNationalities {
  key: string;
  translation: string;
}
interface SortedIntermediate {
  key: string;
  name: string;
}
interface LinkIntermediateDto {
  intermediateId: string;
  guideId: string;
}
interface InformationProps extends LocalizeContextProps {}
const GuideInformation = ({ translate }: InformationProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [guideProfile, setGuideProfile] = useState<Guide | null>(null);

  const [jwt] = UseJWT();
  const params = useParams<ParamsInterface | undefined>();
  const [form, setFormState] = useState<FormState>({
    editing: false,
    guideId: params ? params.id : '',
  });
  let certificatesInputRef: HTMLInputElement | null = null;
  const [isLoading, hasError] = useGuide(params?.id, setGuideProfile);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const previewSource = useMemo(() => [avatar], [avatar]);
  const avatarPreview = useFilePreview(previewSource);

  const [localGuideFirstName, setLocalGuideFirstName] = useState('');
  const [localGuideLastName, setLocalGuideLastName] = useState('');
  const [localGuideNationality, setLocalGuideNationality] = useState('');

  const [selectedIntermediate, setSelectedIntermediate] = useState<
    string | null
  >(null);
  const [localGuideAddress, setLocalGuideAddress] = useState('');
  const [localGuideLocation, setLocalGuideLocation] = useState('');
  const [localGuideFirstSection, setLocalGuideFirstSection] = useState('');
  const [localGuideLastSection, setLocalGuideLastSection] = useState('');
  const [localGuideBirthDate, setLocalBirthDate] = useState<Date>();
  const [localGuideEmail, setLocalGuideEmail] = useState('');
  const [localGuidePhoneNumber, setLocalGuidePhoneNumber] = useState('');
  const [localGuideGender, setLocalGuideGender] = useState('');
  const [localGuideCompanyName, setLocalGuideCompanyName] = useState('');
  const [localPassword, setLocalPassword] = useState('');
  const [localGuideIBAN, setLocalGuideIBAN] = useState('');
  const [localGuideNIF, setLocalGuideNIF] = useState('');
  const [reason, setReason] = useState('');
  //TODO ver isso
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localGuideState, setLocalGuideState] = useState<GuideStateType>(
    GuideStateType.PENDING_APPROVAL
  );
  const [localGuideIvaExemption, setLocalGuideIvaExemption] = useState<
    string | null
  >(null);
  const [localGuideRetention, setLocalGuideRetention] = useState<string | null>(
    null
  );
  const [certificates, setCertificates] = useState<File[]>([]);
  const [evaluateGuide, setEvaluateGuide] = useState<boolean>(false);
  const [descriptions, setDescriptions] = useState<GuideDescriptionDto[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [changePassword, setChangePassword] = useState(false);
  const [localIntermediate, setLocalIntermediate] = useState<
    IntermediateInfoInterface | undefined
  >(undefined);
  const [linkShowIntermediateModal, setShowLinkIntermediateModal] =
    useState<boolean>(false);

  const [intermediateList, setIntermediateList] = useState<
    IntermediateListInterface[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchingIntermediates] = useIntermediates(
    setIntermediateList,
    {
      search: '',
    },
    !jwt?.roles.includes(RoleType.INTERMEDIATE)
  );
  const [financial, setFinancial] = useState<FinancialInterface | null>(null);

  useFinancial(setFinancial);
  const ivaOptions = useMemo(() => {
    if (!financial) return;

    return financial.ivas.map(i => {
      return (
        <Option key={i.id} value={i.id}>
          {i.relevance === 'MINOR' ? (
            <Text variant="faded">{i.invoiceMention}</Text>
          ) : (
            <Text>{i.invoiceMention}</Text>
          )}
        </Option>
      );
    });
  }, [financial]);
  const retentionsOptions = useMemo(() => {
    if (!financial) return;

    return financial.retentions.map(r => {
      return (
        <Option key={r.id} value={r.id}>
          <Text>{r.invoiceMention}</Text>
        </Option>
      );
    });
  }, [financial]);
  const sortedNationalities = useMemo(
    () =>
      Object.values(Nationality)
        .map(nat => {
          return {
            key: nat,
            translation: translate(`nationalities.${nat}`).toString(),
          };
        })
        .sort((a, b) => a.translation.localeCompare(b.translation)),
    [translate]
  );
  const sortedIntermediate = useMemo(
    () =>
      intermediateList
        .map(nat => {
          return {
            key: nat.id,
            name: nat.name,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [intermediateList]
  );

  useEffect(() => {
    if (!guideProfile) return;
    setLocalIntermediate(guideProfile.intermediate);
    setLocalGuideFirstName(
      validator.isEmpty(guideProfile.firstName) ? '' : guideProfile.firstName
    );
    setLocalGuideLastName(
      validator.isEmpty(guideProfile.lastName) ? '' : guideProfile.lastName
    );
    setLocalGuideNationality(
      validator.isEmpty(guideProfile.nationality)
        ? ''
        : guideProfile.nationality
    );
    setLocalGuideAddress(
      validator.isEmpty(guideProfile.address) ? '' : guideProfile.address
    );
    setLocalGuideLocation(
      validator.isEmpty(guideProfile.location) ? '' : guideProfile.location
    );
    if (validator.isEmpty(guideProfile.postalCode)) {
      setLocalGuideFirstSection('');
      setLocalGuideLastSection('');
    } else {
      setLocalGuideFirstSection(guideProfile.postalCode.split('-')[0]);
      setLocalGuideLastSection(guideProfile.postalCode.split('-')[1]);
    }
    setLocalGuideIvaExemption(guideProfile.ivaExemptionId);
    setLocalGuideRetention(guideProfile.retentionId);
    setLocalBirthDate(guideProfile.dateOfBirth);
    setLocalGuideEmail(
      validator.isEmpty(guideProfile.email) ? '' : guideProfile.email
    );
    setLocalGuidePhoneNumber(
      validator.isEmpty(guideProfile.phoneNumber)
        ? ''
        : guideProfile.phoneNumber
    );
    setLocalGuideGender(guideProfile.gender);
    setLocalGuideCompanyName(
      validator.isEmpty(guideProfile.companyName)
        ? ''
        : guideProfile.companyName
    );
    setLocalPassword('');
    setLocalGuideNIF(
      validator.isEmpty(guideProfile.nif) ? '' : guideProfile.nif
    );
    setLocalGuideIBAN(
      validator.isEmpty(guideProfile.iban) ? '' : guideProfile.iban
    );

    setCertificates([]);
    setChangePassword(false);
    setDescriptions(guideProfile.descriptions || []);
    setSelectedLanguages((guideProfile.languages || []).map(l => l.language));
    setSelectedSkills(
      (guideProfile.skills || [])
        .map(sk => sk.skillType)
        .filter(ss => makeEnumArray(SkillType).includes(ss))
    );
    setLocalGuideState(guideProfile.state);
    //EVALUATE
    setEvaluateGuide(false);
    setReason('');
  }, [guideProfile, translate]);
  //Quando mudam linguas cria descrições novas mas mantem as existentes
  useEffect(() => {
    setDescriptions(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, description: '' })),
    ]);
  }, [selectedLanguages]);
  const changeDate = useCallback(newDate => {
    setLocalBirthDate(newDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelEditForm = useCallback(
    (guideProfile: Guide) => {
      setLocalGuideFirstName(guideProfile.firstName);
      setLocalGuideLastName(guideProfile.lastName);
      setLocalGuideNationality(guideProfile.nationality);
      setLocalGuideAddress(guideProfile.address);
      setLocalGuideLocation(guideProfile.location);
      setLocalGuideFirstSection(guideProfile.postalCode.split('-')[0]);
      setLocalGuideLastSection(guideProfile.postalCode.split('-')[1]);
      setLocalBirthDate(guideProfile.dateOfBirth);
      setLocalGuideEmail(guideProfile.email);
      setLocalGuidePhoneNumber(guideProfile.phoneNumber);
      setLocalGuideGender(guideProfile.gender);
      setLocalGuideCompanyName(guideProfile.companyName);
      setLocalPassword('');
      setLocalGuideNIF(guideProfile.nif);
      setLocalGuideIBAN(guideProfile.iban);
      if (!form) return;
      setFormState({ ...form, editing: false });
    },
    [setFormState, form]
  );
  const showEvaluateModal = useCallback(() => {
    setEvaluateGuide(true);
  }, []);
  const showLinkIntermediateModal = useCallback(() => {
    setShowLinkIntermediateModal(true);
  }, []);
  const requestChangePassword = useCallback(async () => {
    if (newPassword.isInvalid) return;
    try {
      await Axios.patch(
        `${API_URL}/authentication/profile/${form.guideId}/reset-password`,
        { newPassword: newPassword.value, oldPassword: 'none' }
      );
      //close modal
      message.success(
        translate('resetPassword.resetPasswordSuccess').toString()
      );
    } catch (err) {}
  }, [newPassword, form.guideId, translate]);

  const editForm = useCallback(() => {
    setFormState({ ...form, editing: true });
  }, [setFormState, form]);
  const selectIntermediate = useCallback(id => {
    setSelectedIntermediate(id);
  }, []);
  //** ERROR CATCH */
  const firstNameError = useMemo((): ErrorInterface => {
    if (localGuideFirstName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideFirstName]);
  const lastNameError = useMemo((): ErrorInterface => {
    if (localGuideLastName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideLastName]);
  const zipCode1Error = useMemo((): ErrorInterface => {
    if (localGuideFirstSection.length !== 4) {
      return {
        error: true,
        reason: 'ZipCode first section should have 4 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideFirstSection]);
  const zipCode2Error = useMemo((): ErrorInterface => {
    if (localGuideLastSection.length !== 3) {
      return {
        error: true,
        reason: 'ZipCode second section  should have 3 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideLastSection]);
  const addressError = useMemo((): ErrorInterface => {
    if (localGuideAddress === '') {
      return {
        error: true,
        reason: 'Address should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideAddress]);

  const nifError = useMemo((): ErrorInterface => {
    if (!isTaxID(localGuideNIF, 'pt-PT')) {
      return {
        error: true,
        reason: 'NIF is invalid',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideNIF]);

  const locationError = useMemo((): ErrorInterface => {
    if (localGuideLocation === '') {
      return {
        error: true,
        reason: 'Location should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideLocation]);
  const emailError = useMemo((): ErrorInterface => {
    if (!validator.isEmail(localGuideEmail)) {
      return {
        error: true,
        reason: 'Invalid email',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideEmail]);
  const birthDateError = useMemo((): ErrorInterface => {
    var guideAge = moment().diff(localGuideBirthDate, 'years', false);
    if (guideAge < 18) {
      return {
        error: true,
        reason: 'Guide is under age',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideBirthDate]);
  const phoneNumberError = useMemo((): ErrorInterface => {
    if (!validator.isMobilePhone(localGuidePhoneNumber)) {
      return {
        error: true,
        reason: 'Phone number must have 9 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localGuidePhoneNumber]);
  const IBANError = useMemo((): ErrorInterface => {
    if (!validator.isIBAN(localGuideIBAN)) {
      return {
        error: true,
        reason: 'Bad phone number inserted',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideIBAN]);
  const companyNameError = useMemo((): ErrorInterface => {
    if (!localGuideCompanyName.trim()) {
      return {
        error: true,
        reason: 'Company name is mandatory',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideCompanyName]);

  const evalError = useMemo((): ErrorInterface => {
    if (validator.isEmpty(reason)) {
      return {
        error: true,
        reason: 'Reason should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [reason]);
  const ivaExemptionError = useMemo((): ErrorInterface => {
    if (localGuideIvaExemption === null) {
      return {
        error: true,
        reason: 'Iva Exemption should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideIvaExemption]);
  const retentionError = useMemo((): ErrorInterface => {
    if (localGuideRetention === null) {
      return {
        error: true,
        reason: 'Retention should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideRetention]);
  //****************************************************************** */
  const approveGuideCallBack = useCallback(async () => {
    if (!guideProfile) return;
    if (
      firstNameError.error ||
      lastNameError.error ||
      zipCode1Error.error ||
      zipCode2Error.error ||
      addressError.error ||
      locationError.error ||
      emailError.error ||
      birthDateError.error ||
      phoneNumberError.error ||
      companyNameError.error ||
      IBANError.error ||
      nifError.error
    ) {
      message.error(
        translate('adminstration.missingGuideInformation').toString()
      );
      return;
    }
    try {
      const { data: state } = await Axios.patch(
        `${API_URL}/guide/${form.guideId}/evaluation`,
        {
          state: GuideStateType.APPROVED,
          description: 'APPROVED',
        }
      );
      setLocalGuideState(state);
      try {
        await Axios.post(`${API_URL}/partner/${form.guideId}`);
      } catch (error) {
        if (error.response.status === 503) {
          message.error(
            translate(
              'adminstration.magnifinanceServicesNotAvailable'
            ).toString()
          );
        }
        if (error.response.status === 409) {
          message.error(
            translate(
              'adminstration.magnifinanceServicesExistingPartner'
            ).toString()
          );
        }
      }

      const { data } = await Axios.get<AdminStats>(
        `${API_URL}/administrator/stats`
      );
      dispatch(setMarkerCount(data as AdminStats));
      setReason('');
      setEvaluateGuide(false);
      message.success(translate('adminstration.eval').toString());
    } catch (err) {
      console.log('err', err);
    }
  }, [
    guideProfile,
    firstNameError.error,
    lastNameError.error,
    zipCode1Error.error,
    zipCode2Error.error,
    addressError.error,
    locationError.error,
    emailError.error,
    birthDateError.error,
    phoneNumberError.error,
    companyNameError.error,
    IBANError.error,
    nifError.error,
    translate,
    form.guideId,
    dispatch,
  ]);
  const noApproveGuideCallBack = useCallback(async () => {
    if (!guideProfile) return;
    if (evalError.error) return;
    try {
      const { data: state } = await Axios.patch(
        `${API_URL}/guide/${form.guideId}/evaluation`,
        {
          state: GuideStateType.NOT_APPROVED,
          description: reason,
        }
      );
      setLocalGuideState(state);
      const { data } = await Axios.get<AdminStats>(
        `${API_URL}/administrator/stats`
      );
      dispatch(setMarkerCount(data as AdminStats));
      setReason('');
      setEvaluateGuide(false);
      message.success(translate('adminstration.eval').toString());
    } catch (err) {}
  }, [
    guideProfile,
    evalError.error,
    reason,
    form.guideId,
    dispatch,
    translate,
  ]);
  const linkIntermediateCallBack = useCallback(async () => {
    if (!guideProfile) return;
    if (!selectedIntermediate) return;
    try {
      await Axios.patch(`${API_URL}/administrator/link-intermediate`, {
        guideId: form.guideId,
        intermediateId: selectedIntermediate,
      });

      setShowLinkIntermediateModal(false);
      message.success(translate('adminstration.eval').toString());
    } catch (err) {}
  }, [guideProfile, form.guideId, translate, selectedIntermediate]);
  const submitForm = useCallback(async () => {
    if (!jwt) return;

    if (
      firstNameError.error ||
      lastNameError.error ||
      zipCode1Error.error ||
      zipCode2Error.error ||
      addressError.error ||
      locationError.error ||
      emailError.error ||
      birthDateError.error ||
      phoneNumberError.error ||
      companyNameError.error ||
      IBANError.error ||
      nifError.error ||
      localGuideIvaExemption === null ||
      localGuideRetention === null ||
      ivaExemptionError.error ||
      retentionError.error
    ) {
      return;
    }

    try {
      setIsUpdating(true);

      const { data: updatedGuide } = await editGuide(
        form.guideId,
        {
          firstName: localGuideFirstName,
          lastName: localGuideLastName,
          nif: localGuideNIF,
          companyName: localGuideCompanyName,
          descriptions: descriptions,
          languages: selectedLanguages.map(l => ({ language: l, rating: 0 })),
          skills: selectedSkills.map(sk => ({ skillType: sk, rating: 0 })),
          address: localGuideAddress,
          postalCode: `${localGuideFirstSection}-${localGuideLastSection}`,
          location: localGuideLocation,
          phoneNumber: localGuidePhoneNumber,
          gender: localGuideGender as GenderType,
          iban: localGuideIBAN,
          nationality: localGuideNationality,
          dateOfBirth: localGuideBirthDate,
          financialType: guideProfile?.financialType,
          userEdit: true,
          ivaExemption: localGuideIvaExemption,
          retention: localGuideRetention,
        },
        avatar,
        certificates
      );

      setGuideProfile(updatedGuide);
      setFormState({ editing: false, guideId: params ? params.id : '' });

      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
    } finally {
      setIsUpdating(false);
    }
  }, [
    jwt,
    firstNameError.error,
    lastNameError.error,
    zipCode1Error.error,
    zipCode2Error.error,
    addressError.error,
    locationError.error,
    emailError.error,
    birthDateError.error,
    phoneNumberError.error,
    companyNameError.error,
    IBANError.error,
    nifError.error,
    localGuideIvaExemption,
    localGuideRetention,
    ivaExemptionError.error,
    retentionError.error,
    form.guideId,
    localGuideFirstName,
    localGuideLastName,
    localGuideNIF,
    localGuideCompanyName,
    descriptions,
    selectedLanguages,
    selectedSkills,
    localGuideAddress,
    localGuideFirstSection,
    localGuideLastSection,
    localGuideLocation,
    localGuidePhoneNumber,
    localGuideGender,
    localGuideIBAN,
    localGuideNationality,
    localGuideBirthDate,
    guideProfile,
    avatar,
    certificates,
    params,
    translate,
  ]);
  useMemo(() => {
    const formatStrength =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const hasRightLength =
      localPassword.length >= 8 && localPassword.length <= 20;
    const emailSplit =
      localGuideEmail.includes('@') && localGuideEmail.split('@');
    const isStrength = localPassword.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (emailSplit &&
        localPassword.includes(emailSplit[0]) &&
        localGuideEmail !== '') ||
      localPassword === ''; //TODO: falta fazer validar o email

    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [translate('register.passNoContainNameMail').toString()],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isStrength ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewPassword({
      value: localPassword,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [localPassword, localGuideEmail, translate]);
  if (!guideProfile || isLoading) {
    return <Loader />;
  }
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }

  if (hasError) return <Error />;
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.headerArea}>
          <Heading className={classes.title} level={2}>
            <Translate id="adminstration.profileOf" />{' '}
            {`${guideProfile.firstName} ${guideProfile.lastName}`}
          </Heading>
          {guideProfile.state !== GuideStateType.CANCELED ? (
            <div className={classes.buttonsEdit}>
              {!form.editing ? null : (
                <Button
                  className={classes.cancelButton}
                  onClick={() => {
                    cancelEditForm(guideProfile);
                  }}
                >
                  <Translate id="adminstration.cancelEdit" />
                </Button>
              )}
              {!form.editing ? null : (
                <Button
                  onClick={() => {
                    submitForm();
                  }}
                  className={classes.confirmButton}
                >
                  {isUpdating ? (
                    <BeatLoader />
                  ) : (
                    <Translate id="profile.save" />
                  )}
                </Button>
              )}
              {!form.editing && jwt.roles.includes(RoleType.ADMINISTRATOR) ? (
                <Button
                  onClick={() => {
                    editForm();
                  }}
                  type="primary"
                >
                  <Translate id="adminstration.edit" />
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className={classes.avatarArea}>
          <FallbackAvatar
            className={classes.avatarPreview}
            src={
              <FallbackImage
                src={avatarPreview?.[0] || getGuideImageUrl(form.guideId)}
                fallback={require('../../../../../shared_assets/images/pediguia_default.svg')}
                fallbackComponent={
                  <Pediguia className={classes.avatarPreview} />
                }
              />
            }
          />
          {!form.editing ? null : (
            <div className={classes.changeAvatarButton}>
              <Translate id="profile.changePhoto" />
              <input
                type="file"
                accept="image/*"
                ref={avatarInputRef}
                onChange={ev => {
                  if (
                    ev.currentTarget.files &&
                    ev.currentTarget.files.length > 0 &&
                    ev.currentTarget.files[0].size < maxAllowedImageSize
                  ) {
                    setAvatar(ev.currentTarget.files[0]);
                  } else {
                    message.info(
                      complexTranslate(
                        translate('error.imageFileSize').toString(),
                        {
                          '{linkTinyPNG}': (
                            <a key={'tinypnglink'} href="https://tinypng.com/">
                              <LinkButton
                                key="1"
                                type="link"
                                className={classNames(
                                  classes.noMargin,
                                  classes.inlineBtn
                                )}
                                onlyText
                              >
                                {translate('error.linkTinyPNG').toString()}
                              </LinkButton>
                            </a>
                          ),
                        }
                      )
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.divisorAvatar} />
        <div className={classes.bodyContainer}>
          <div className={classes.infoZone}>
            {/* NAME */}
            <Text noMargin weight="semibold">
              <Translate id="profile.firstName" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideFirstName}
              onChange={ev => {
                setLocalGuideFirstName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={firstNameError.error}
              reason={firstNameError.reason}
            />
            <Text noMargin weight="semibold">
              <Translate id="profile.lastName" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideLastName}
              onChange={ev => {
                setLocalGuideLastName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={lastNameError.error}
              reason={lastNameError.reason}
            />
            <Divider className={classes.divider} />
            {/* NACIONALIDADE */}
            <Text weight="semibold">
              <Translate id="profile.nationality" />
            </Text>

            <Select
              className={classes.select}
              onChange={v => setLocalGuideNationality(v.toString())}
              huge
              disabled={!form.editing}
              value={localGuideNationality || undefined}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>

            <Divider className={classes.divider} />
            {/* ADDRESS INFO */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.residence" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideAddress}
              onChange={ev => {
                setLocalGuideAddress(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={addressError.error}
              reason={addressError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.location" />
            </Text>

            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideLocation}
              onChange={ev => {
                setLocalGuideLocation(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={locationError.error}
              reason={locationError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.postalCode" />
            </Text>
            <div className={classes.row}>
              <Input
                className={classes.disableTextColor}
                disabled={!form.editing}
                type="number"
                placeholder="XXXX"
                onChange={ev => {
                  setLocalGuideFirstSection(ev.currentTarget.value);
                }}
                value={localGuideFirstSection}
              />

              <span className={classes.spacer}>-</span>
              <Input
                className={classes.disableTextColor}
                disabled={!form.editing}
                placeholder="XXX"
                type="number"
                onChange={ev => {
                  setLocalGuideLastSection(ev.currentTarget.value);
                }}
                value={localGuideLastSection}
              />
            </div>
            <AnimatedError
              isVisible={zipCode1Error.error}
              reason={zipCode1Error.reason}
            />
            <AnimatedError
              isVisible={zipCode2Error.error}
              reason={zipCode2Error.reason}
            />
            <Divider className={classes.divider} />
            {/* EMAIL */}
            <Text noMargin weight="semibold">
              <Translate id="profile.email" />
            </Text>
            <Input
              type={'email'}
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideEmail}
              onChange={ev => {
                setLocalGuideEmail(ev.target.value);
              }}
            ></Input>

            <AnimatedError
              isVisible={emailError.error}
              reason={emailError.reason}
            />
            <Text weight="semibold" noMargin>
              <Translate id="profile.password" />
            </Text>
            <Modal
              fullScreenOnMobile={false}
              contentContainerClassName={classes.modalContent}
              headerTitle={<Translate id="button.addSkill" />}
              footer={
                <div className={classes.footer}>
                  <Button
                    onClick={() => {
                      setChangePassword(false);
                    }}
                  >
                    <Translate id="button.cancel" />
                  </Button>
                  <div className={classes.addRemoveBtn}>
                    <Button
                      type="primary"
                      onClick={() => {
                        requestChangePassword();
                        setChangePassword(false);
                      }}
                    >
                      <Translate id="button.save" />
                    </Button>
                  </div>
                </div>
              }
              visible={changePassword}
              closable={false}
            >
              <Text color={'red'} noMargin weight="semibold">
                <Translate id="adminstration.passwordWarning" />
              </Text>
            </Modal>
            <div className={classes.centeredRow}>
              <AppInput
                huge
                className={classes.passwordInput}
                type={isPasswordShowing ? 'text' : 'password'}
                placeholder={translate('register.password').toString()}
                onChange={ev => setLocalPassword(ev.target.value)}
                value={localPassword}
                disabled={!form.editing}
                prefix={<Lock className={classes.icon} />}
                suffix={
                  isPasswordShowing ? (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <See className={classes.icon} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <Unsee className={classes.icon} />
                    </div>
                  )
                }
              />
              <Button
                disabled={!form.editing}
                type="ghost"
                onClick={() => {
                  setChangePassword(true);
                }}
              >
                <Translate id="profile.change" />
              </Button>
            </div>
            {form.editing && !validator.isEmpty(newPassword.value) ? (
              <AnimatedInfo
                isVisible={
                  newPassword.isVisible ? newPassword.isVisible : false
                }
                infos={newPassword.infos}
              />
            ) : null}
            <Divider className={classes.divider} />
            {/* B-DAY */}
            <Text weight="semibold">
              <Translate id="profile.dateOfBirth" />
            </Text>
            <BirthDateSelector
              onDateChange={changeDate}
              isInvalid={true}
              reason={''}
              initialValue={localGuideBirthDate}
              disable={!form.editing}
            />
            <AnimatedError
              isVisible={birthDateError.error}
              reason={birthDateError.reason}
            />
            <Divider className={classes.divider} />
            {/* PHONE NUMBER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.phoneNumber" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuidePhoneNumber}
              onChange={ev => {
                setLocalGuidePhoneNumber(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={phoneNumberError.error}
              reason={phoneNumberError.reason}
            />
            <Divider className={classes.divider} />
            {/* GENDER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.gender" />
            </Text>

            <Select
              className={classes.select}
              defaultValue={undefined}
              disabled={!form.editing}
              onChange={v => setLocalGuideGender(v.toString())}
              value={localGuideGender || undefined}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  key={gender}
                  huge
                  value={gender}
                  label={translate(`genders.${gender}`).toString()}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <Divider className={classes.divider} />

            {/* COMPANY NAME */}
            <Text weight="semibold" noMargin>
              <Translate id="adminstration.companyName" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideCompanyName}
              onChange={ev => {
                setLocalGuideCompanyName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={companyNameError.error}
              reason={companyNameError.reason}
            />
            {/* IBAN */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.iban" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideIBAN}
              onChange={ev => {
                setLocalGuideIBAN(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={IBANError.error}
              reason={IBANError.reason}
            />
            {/* NIF */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.nif" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localGuideNIF}
              onChange={ev => {
                setLocalGuideNIF(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={nifError.error}
              reason={nifError.reason}
            />
            {localGuideIvaExemption && financial && (
              <>
                <Text noMargin weight="semibold">
                  <Translate id="onboarding.ivaExemption" />
                </Text>

                <Select
                  disabled={!form.editing}
                  placeholder={translate(
                    'onboarding.ivaExemptionPlaceholder'
                  ).toString()}
                  className={classes.select}
                  value={
                    localGuideIvaExemption === null
                      ? undefined
                      : localGuideIvaExemption
                  }
                  onChange={ev => setLocalGuideIvaExemption(ev as string)}
                  huge
                  getPopupContainer={() => document.body}
                >
                  {ivaOptions}
                </Select>
                <Text variant="faded">
                  {localGuideIvaExemption
                    ? financial.ivas.find(i => i.id === localGuideIvaExemption)
                        ?.applicableStandard
                    : ''}
                </Text>
                <AnimatedError
                  isVisible={ivaExemptionError.error}
                  reason={ivaExemptionError.reason}
                />
              </>
            )}
            {localGuideRetention && financial && (
              <>
                <Text noMargin weight="semibold">
                  <Translate id="onboarding.retention" />
                </Text>

                <Select
                  disabled={!form.editing}
                  placeholder={translate(
                    'onboarding.retentionPlaceholder'
                  ).toString()}
                  className={classes.select}
                  value={
                    localGuideRetention === null
                      ? undefined
                      : localGuideRetention
                  }
                  onChange={ev => setLocalGuideRetention(ev as string)}
                  huge
                  getPopupContainer={() => document.body}
                >
                  {retentionsOptions}
                </Select>
                <Text variant="faded">
                  {localGuideRetention
                    ? financial.retentions.find(
                        r => r.id === localGuideRetention
                      )?.applicableStandard
                    : ''}
                </Text>
                <AnimatedError
                  isVisible={retentionError.error}
                  reason={retentionError.reason}
                />
              </>
            )}
            <Divider className={classes.divider} />
            {/* DESCRIPTION */}
            <Text weight="semibold">
              <Translate id="profile.profileDescription" />
            </Text>
            <Tabs>
              {descriptions.map(iteratedLanguage => (
                <TabPane
                  key={iteratedLanguage.language}
                  tab={translate(`languages.${iteratedLanguage.language}`)}
                >
                  <TextArea
                    disabled={!form.editing}
                    value={iteratedLanguage.description}
                    rows={10}
                    onChange={ev => {
                      ev.persist();
                      setDescriptions(previousDescriptions =>
                        previousDescriptions.map(oldDescription => {
                          if (
                            oldDescription.language ===
                            iteratedLanguage.language
                          )
                            return {
                              ...oldDescription,
                              description: ev.target.value,
                            };
                          else return oldDescription;
                        })
                      );
                    }}
                  />
                  <div>
                    <AnimatedError
                      isVisible={validator.isEmpty(
                        iteratedLanguage.description
                      )}
                      reason={<Translate id="profile.bioMandatory" />}
                    />
                  </div>
                </TabPane>
              ))}
            </Tabs>
            <Divider className={classes.divider} />
            {/* LANGS */}
            <Text weight="semibold">
              <Translate id="profile.spokenLanguages" />
            </Text>
            <LanguageSelection
              disabled={!form.editing}
              onChange={setSelectedLanguages}
              value={selectedLanguages}
            />
            <Divider className={classes.divider} />
            <Text weight="semibold">
              <Translate id="profile.skills" />
            </Text>
            <SkillSelection
              disabled={!form.editing}
              value={selectedSkills}
              onChange={setSelectedSkills}
            />
            <Divider className={classes.divider} />
            <Text weight="semibold">
              {<Translate id="adminstration.documents" />} :{' '}
              {guideProfile.documents.length}
            </Text>
            {guideProfile.documents.map(docs => (
              <a
                target={'_blank'}
                href={`${API_URL}/guide/document/${
                  docs.id
                }?_=${moment().unix()}`}
              >
                {docs.docName}
              </a>
            ))}
            {/* ANCHOR UPLOAD */}
            <div hidden={!form.editing}>
              <Heading level={5} className={classes.titleMargin}>
                <Translate id="onboarding.certificates" />
              </Heading>
              <input
                multiple
                ref={ref => (certificatesInputRef = ref)}
                onChange={ev => {
                  if (!ev.currentTarget.files) {
                    setCertificates([]);
                    return;
                  }

                  let fileArray: File[] = [];

                  for (let i = 0; i < ev.currentTarget.files.length; i++) {
                    if (
                      ev.currentTarget.files[i] &&
                      ev.currentTarget.files[i].size > maxAllowedFileSize
                    ) {
                      fileArray = [];
                      break;
                    } else {
                      fileArray.push(ev.currentTarget.files[i]);
                    }
                  }
                  setCertificates(fileArray);
                }}
                type="file"
                className={classes.invisible}
              />
              <div className={classes.fileContainer}>
                {certificates.map(c => (
                  <div className={classes.file}>
                    <div className={classes.fileIconContainer}>
                      <Bill />
                    </div>
                    <div className={classes.textContainer}>
                      <Text>{c.name}</Text>
                      <span>{c.size}</span>
                    </div>
                  </div>
                ))}
              </div>
              <AppButton
                className={classes.marginTop}
                type="ghost"
                onClick={() => {
                  if (certificatesInputRef) certificatesInputRef.click();
                }}
                prefix={<Upload className={classes.icon} />}
              >
                <Translate id="onboarding.uploadCertificates" />
              </AppButton>
              <Text
                className={classNames(
                  classes.textAlignInitial,
                  classes.marginTop
                )}
              >
                <Translate id="onboarding.certificateSize" />
              </Text>
            </div>

            <Divider className={classes.divider} />
            {jwt.roles.includes(RoleType.ADMINISTRATOR) ? (
              <>
                <div className={classes.evaluateButton}>
                  {/* //ANCHOR MODAL */}
                  <Modal
                    fullScreenOnMobile={false}
                    contentContainerClassName={classes.modalContent}
                    headerTitle={<Translate id="button.addSkill" />}
                    footer={
                      <div className={classes.footer}>
                        <Button
                          onClick={() => {
                            setReason('');
                            setEvaluateGuide(false);
                          }}
                        >
                          <Translate id="button.cancel" />
                        </Button>
                        <div className={classes.addRemoveBtn}>
                          <Button
                            type="primary"
                            onClick={() => {
                              noApproveGuideCallBack();
                            }}
                          >
                            <Translate id="button.save" />
                          </Button>
                        </div>
                      </div>
                    }
                    visible={evaluateGuide}
                    closable={false}
                  >
                    <Text weight="semibold">
                      <Translate id="adminstration.reason" />
                    </Text>
                    <TextArea
                      value={reason}
                      rows={10}
                      onChange={ev => {
                        ev.persist();
                        setReason(ev.target.value);
                      }}
                    />
                    <AnimatedError
                      isVisible={evalError.error}
                      reason={evalError.reason}
                    />
                  </Modal>
                  <Button
                    type="primary"
                    shape="round"
                    className={classes.cancelButton}
                    size={'large'}
                    onClick={ev => {
                      showEvaluateModal();
                    }}
                  >
                    <Translate id="adminstration.notApprove" />
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    className={classes.evaluateApproveButton}
                    size={'large'}
                    onClick={ev => {
                      approveGuideCallBack();
                    }}
                  >
                    <Translate id="adminstration.approve" />
                  </Button>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.evaluateButton}>
                  <Modal
                    fullScreenOnMobile={false}
                    contentContainerClassName={classes.modalContent}
                    headerTitle={<Translate id="button.addSkill" />}
                    footer={
                      <div className={classes.footer}>
                        <Button
                          onClick={() => {
                            setShowLinkIntermediateModal(false);
                          }}
                        >
                          <Translate id="button.cancel" />
                        </Button>
                        <div className={classes.addRemoveBtn}>
                          <Button
                            type="primary"
                            onClick={() => {
                              linkIntermediateCallBack(); //SEND ACTION
                            }}
                          >
                            <Translate id="button.save" />
                          </Button>
                        </div>
                      </div>
                    }
                    visible={linkShowIntermediateModal}
                    closable={false}
                  >
                    <Text weight="semibold">
                      <Translate id="adminstration.intermediate" />
                    </Text>
                    <Select
                      className={classes.select}
                      onChange={v => {
                        selectIntermediate(v.toString());
                      }}
                      huge
                      defaultValue={
                        sortedIntermediate.find(
                          e => e.key === localIntermediate?.id
                        )?.name
                      }
                    >
                      {sortedIntermediate.map(nat => (
                        <Option key={nat.key} value={nat.key}>
                          {nat.name}
                        </Option>
                      ))}
                    </Select>
                  </Modal>
                  <Button
                    type="primary"
                    shape="round"
                    className={classes.confirmButton}
                    size={'large'}
                    onClick={ev => {
                      showLinkIntermediateModal();
                    }}
                  >
                    <Translate id="adminstration.linkIntermediate" />
                  </Button>
                </div>
              </>
            ) : null}

            {/* INTERMEDIATE */}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(GuideInformation);
