import classNames from 'classnames';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChangeProfile } from '../../../../shared_assets/icons/change_profile.svg';
import { CompleteProfileNotification } from '../../../../types/notification.interface';
import { Heading, Text } from '../../../Typography';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { NotificationsProps } from '../Notification';

interface NotificationCompleteProfileProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: CompleteProfileNotification;
}

const NotificationCompleteProfile: React.FC<NotificationCompleteProfileProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
}) => {
  const history = useHistory();
  const { seen, type } = notification;

  return (
    <>
      {!simpleVersion && (
        <div className={classes.rowDirection}>
          <ChangeProfile className={classes.icon} />
          <Heading level={5} noMargin>
            {translate(`notificationType.${type}`).toString()}
          </Heading>
          {!seen && (
            <Badge
              className={classNames(classes.marginLeft, classes.uppercase)}
              type="primary"
              small
            >
              {translate('notifications.new').toString()}
            </Badge>
          )}
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        <Text variant={!simpleVersion ? 'faded' : 'default'} noMargin>
          {translate('notifications.completeProfile').toString()}
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="link"
          onlyText
          onClick={() => {
            notification.seen = true;
            history.push(`/visitor/profile/edit`);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.completeProfile').toString()}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default withLocalize(NotificationCompleteProfile);
