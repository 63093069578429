import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

export interface AdEventTitleInterface {
  language: string;
  title: string;
}
export interface AdEventDescriptionInterface {
  language: string;
  description: string;
}

export type AdEventResponse = {
  id: string;
  startAd: Date;
  endAd: Date;
  titles: AdEventTitleInterface[];
  descriptions: AdEventDescriptionInterface[];
  longitude: number;
  latitude: number;
  location: string;
  active: boolean;
  radius: number;
}[];
interface activeDto {
  active: boolean;
}
const fetchAdEvents = (advertiserId: string) =>
  Axios.get<AdEventResponse>(
    `${API_URL}/publicity/list-ad-events/from/${advertiserId}`
  );

type Hook = (
  setAdEvents: (val: SetStateAction<AdEventResponse>) => void,
  advertiserId: string
) => [boolean, boolean];
export const useAdEvent: Hook = (setAdEvents, advertiserId) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await fetchAdEvents(advertiserId);
        setAdEvents(data);
      } catch (err) {
        setAdEvents([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [advertiserId, setAdEvents]);

  return [isLoading, isError];
};

export const getAdImageUrl = (id: string) =>
  `${API_URL}/publicity/${id}/ad-event-image?_=${moment().unix()}`;

export const changeAdEventState = (id: string, state: boolean) =>
  Axios.patch<void>(
    `${API_URL}/publicity/change-state-ad-event/${id}/${state}`
  );
