import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox, message } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { FallbackAvatar } from '../../../../../components/App/Avatar';
import { BirthDateSelector } from '../../../../../components/App/BirthDateSelector';
import { Button as LinkButton } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { FallbackImage } from '../../../../../components/App/Image';
import { Input as AppInput } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Option, Select } from '../../../../../components/App/Select';
import { Text } from '../../../../../components/Typography';
import {
  AccessibilityWithSingleTranslation,
  useAccessibilities,
} from '../../../../../hooks/accessibilities/useAccessibilities';
import {
  CreateVisitorAdminDto,
  postVisitorAdmin,
} from '../../../../../hooks/administration/usePostVisitorAdmin';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as Lock } from '../../../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../../../shared_assets/icons/unsee.svg';
import { ReactComponent as Visitante } from '../../../../../shared_assets/images/visitante_default.svg';
import { GenderType } from '../../../../../types/gender-type.enum';
import { Nationality } from '../../../../../types/nationality.enum';
import { RoleType } from '../../../../../types/role-type.enum';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  headerHeight,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

// ANCHOR: IMPORTS
interface ErrorInterface {
  error: boolean;
  reason: string;
}
interface AccessibilitiesOptions {
  value: string;
  label: string;
}
interface LocalAccessibilities {
  value: string;
  //label: string;
}
const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  divSubmitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: '25%',
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    flex: '1',
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },
  disableTextColor: {
    color: 'black !important',
  },
  divisorAvatar: {
    paddingBottom: '10px',
  },
  headerArea: {
    display: 'flex',
  },
  buttonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0.5',
  },
  avatarArea: {
    display: 'flex',
    height: '100px',
  },
  avatarPreview: {
    height: '100px',
    width: '100px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  select: {
    minWidth: '300px',
    color: 'black !important',
  },
  spacer: {
    margin: '0 1rem',
  },
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  // ANCHOR: CSS
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  invisible: {
    display: 'none',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
});
interface SortedNationalities {
  key: string;
  translation: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
interface CreateVisitorProps extends LocalizeContextProps {}
const CreateVisitor = ({ translate }: CreateVisitorProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [accessibilities, setAccessibilities] = useState<
    AccessibilityWithSingleTranslation[]
  >([]);
  const [fetchingAccessibilities, errorFetchingAccessibilities] =
    useAccessibilities(setAccessibilities);
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const previewSource = useMemo(() => [avatar], [avatar]);
  const avatarPreview = useFilePreview(previewSource);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [localVisitorFirstName, setLocalVisitorFirstName] = useState('');
  const [localVisitorLastName, setLocalVisitorLastName] = useState('');
  const [localVisitorNationality, setLocalVisitorNationality] = useState('');
  const [localVisitorAddress, setLocalVisitorAddress] = useState('');
  const [localVisitorLocation, setLocalVisitorLocation] = useState('');
  const [localVisitorFirstSection, setLocalVisitorFirstSection] = useState('');
  const [localVisitorLastSection, setLocalVisitorLastSection] = useState('');
  const [localVisitorBirthDate, setLocalBirthDate] = useState<Date>();
  const [localVisitorEmail, setLocalVisitorEmail] = useState('');
  const [localVisitorPhoneNumber, setLocalVisitorPhoneNumber] = useState('');
  const [localVisitorGender, setLocalVisitorGender] = useState('');
  const [localPassword, setLocalPassword] = useState('');
  const [localVisitorNIF, setLocalVisitorNIF] = useState('');

  const sortedNationalities = useMemo(
    () =>
      Object.values(Nationality)
        .map(nat => {
          return {
            key: nat,
            translation: translate(`nationalities.${nat}`).toString(),
          };
        })
        .sort((a, b) => a.translation.localeCompare(b.translation)),
    [translate]
  );
  useEffect(() => {
    setLocalVisitorLastName('');
    setLocalVisitorNationality('');
    setLocalVisitorAddress('');
    setLocalVisitorLocation('');
    setLocalVisitorFirstSection('');
    setLocalVisitorLastSection('');
    setLocalVisitorEmail('');
    setLocalVisitorPhoneNumber('');
    setLocalVisitorGender('');
    setLocalVisitorShowOnly(false);
    setLocalPassword('');
    setLocalVisitorPhoneNumber('');
    setLocalVisitorGender('');
    setLocalVisitorNIF('');
    setAccessibilitiesOptions(
      accessibilities.map(a => {
        return {
          value: a.id,
          label: a.name,
        };
      })
    );
    setLocalAccessibilities([]);
    //EVALUATE
  }, [accessibilities, translate]);
  const [localAccessibilities, setLocalAccessibilities] = useState<string[]>(
    []
  );
  const [localVisitorShowOnly, setLocalVisitorShowOnly] =
    useState<boolean>(false);
  const [accessibilitiesOptions, setAccessibilitiesOptions] = useState<
    AccessibilitiesOptions[]
  >([]);
  const changeDate = useCallback(newDate => {
    setLocalBirthDate(newDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstNameError = useMemo((): ErrorInterface => {
    if (localVisitorFirstName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorFirstName]);
  const lastNameError = useMemo((): ErrorInterface => {
    if (localVisitorLastName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorLastName]);
  const zipCode1Error = useMemo((): ErrorInterface => {
    if (localVisitorFirstSection.length !== 4) {
      return {
        error: true,
        reason: 'ZipCode first section should have 4 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorFirstSection]);
  const zipCode2Error = useMemo((): ErrorInterface => {
    if (localVisitorLastSection.length !== 3) {
      return {
        error: true,
        reason: 'ZipCode second section  should have 3 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorLastSection]);
  const addressError = useMemo((): ErrorInterface => {
    if (localVisitorAddress === '') {
      return {
        error: true,
        reason: 'Address should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorAddress]);
  const locationError = useMemo((): ErrorInterface => {
    if (localVisitorLocation === '') {
      return {
        error: true,
        reason: 'Location should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorLocation]);
  const emailError = useMemo((): ErrorInterface => {
    if (!validator.isEmail(localVisitorEmail)) {
      return {
        error: true,
        reason: 'Invalid email',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorEmail]);
  const birthDateError = useMemo((): ErrorInterface => {
    var guideAge = moment().diff(localVisitorBirthDate, 'years', false);
    if (guideAge < 18) {
      return {
        error: true,
        reason: 'Visitor is under age',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorBirthDate]);
  const phoneNumberError = useMemo((): ErrorInterface => {
    if (!validator.isMobilePhone(localVisitorPhoneNumber)) {
      return {
        error: true,
        reason: 'Phone number must have 9 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorPhoneNumber]);

  const genderError = useMemo((): ErrorInterface => {
    if (validator.isEmpty(localVisitorGender)) {
      return {
        error: true,
        reason: 'Select a gender',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorGender]);
  const nationalityError = useMemo((): ErrorInterface => {
    if (validator.isEmpty(localVisitorNationality)) {
      return {
        error: true,
        reason: 'Select a nationality',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorNationality]);

  const submitForm = useCallback(async () => {
    if (!jwt) return;
    if (localVisitorBirthDate === undefined) return;
    if (
      firstNameError.error ||
      lastNameError.error ||
      zipCode1Error.error ||
      zipCode2Error.error ||
      addressError.error ||
      locationError.error ||
      emailError.error ||
      birthDateError.error ||
      phoneNumberError.error ||
      genderError.error ||
      nationalityError.error ||
      newPassword.isInvalid
    ) {
      return;
    }

    try {
      setIsSubmitting(true);
      const createDto: CreateVisitorAdminDto = {
        firstName: localVisitorFirstName,
        lastName: localVisitorLastName,
        nif: localVisitorNIF,
        address: localVisitorAddress,
        postalCode: `${localVisitorFirstSection}-${localVisitorLastSection}`,
        location: localVisitorLocation,
        phoneNumber: localVisitorPhoneNumber,
        gender: localVisitorGender as GenderType,
        nationality: localVisitorNationality,
        dateOfBirth: localVisitorBirthDate,
        agreeMarketing: true,
        agreeTerms: true,
        email: localVisitorEmail,
        password: localPassword,
        type: RoleType.VISITOR,
        accessibilities: localAccessibilities,
        showOnly: localVisitorShowOnly,
      };
      await postVisitorAdmin(createDto, avatar);

      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
    } finally {
      setIsSubmitting(false);
    }
  }, [
    localAccessibilities,
    localVisitorShowOnly,
    localVisitorFirstName,
    localVisitorLastName,
    localVisitorNIF,
    localVisitorAddress,
    localVisitorFirstSection,
    localVisitorLastSection,
    localVisitorLocation,
    localVisitorPhoneNumber,
    localVisitorGender,
    localVisitorNationality,
    localVisitorBirthDate,
    localVisitorEmail,
    localPassword,
    firstNameError,
    lastNameError,
    zipCode1Error,
    zipCode2Error,
    addressError,
    locationError,
    emailError,
    birthDateError,
    phoneNumberError,
    genderError,
    nationalityError,
    avatar,
    jwt,
    newPassword,
    translate,
  ]);
  useMemo(() => {
    const formatStrength =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const hasRightLength =
      localPassword.length >= 8 && localPassword.length <= 20;
    const emailSplit =
      localVisitorEmail.includes('@') && localVisitorEmail.split('@');
    const isStrength = localPassword.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (emailSplit &&
        localPassword.includes(emailSplit[0]) &&
        localVisitorEmail !== '') ||
      localPassword === ''; //TODO: falta fazer validar o email

    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [translate('register.passNoContainNameMail').toString()],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isStrength ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewPassword({
      value: localPassword,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [localPassword, localVisitorEmail, translate]);
  const selectAccessibilities = useCallback((checkedValues: any) => {
    setLocalAccessibilities(checkedValues);
  }, []);
  const selectShowOnly = useCallback((checkedValue: any) => {
    setLocalVisitorShowOnly(checkedValue.target.checked);
  }, []);

  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }
  if (isUpdating || fetchingAccessibilities || isSubmitting) {
    return <Loader />;
  }

  if (errorFetchingAccessibilities) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.avatarArea}>
          <FallbackAvatar
            className={classes.avatarPreview}
            src={
              <FallbackImage
                src={avatarPreview?.[0] || undefined}
                fallback={require('../../../../../shared_assets/images/visitante_default.svg')}
                fallbackComponent={<Visitante />}
              />
            }
          />
          <div className={classes.changeAvatarButton}>
            <Translate id="profile.changePhoto" />
            <input
              type="file"
              accept="image/*"
              ref={avatarInputRef}
              onChange={ev => {
                if (
                  ev.currentTarget.files &&
                  ev.currentTarget.files.length > 0 &&
                  ev.currentTarget.files[0].size < maxAllowedImageSize
                ) {
                  setAvatar(ev.currentTarget.files[0]);
                } else {
                  message.info(
                    complexTranslate(
                      translate('error.imageFileSize').toString(),
                      {
                        '{linkTinyPNG}': (
                          <a key={'tinypnglink'} href="https://tinypng.com/">
                            <LinkButton
                              key="1"
                              type="link"
                              className={classNames(
                                classes.noMargin,
                                classes.inlineBtn
                              )}
                              onlyText
                            >
                              {translate('error.linkTinyPNG').toString()}
                            </LinkButton>
                          </a>
                        ),
                      }
                    )
                  );
                }
              }}
            />
          </div>
        </div>
        <div className={classes.divisorAvatar} />
        <div className={classes.bodyContainer}>
          <div className={classes.infoZone}>
            {/* NAME */}
            <Text noMargin weight="semibold">
              <Translate id="profile.firstName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localVisitorFirstName}
              onChange={ev => {
                setLocalVisitorFirstName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={firstNameError.error}
              reason={firstNameError.reason}
            />
            <Text noMargin weight="semibold">
              <Translate id="profile.lastName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localVisitorLastName}
              onChange={ev => {
                setLocalVisitorLastName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={lastNameError.error}
              reason={lastNameError.reason}
            />
            <Divider className={classes.divider} />
            {/* NACIONALIDADE */}
            <Text weight="semibold">
              <Translate id="profile.nationality" />
            </Text>
            <Select
              className={classes.select}
              onChange={v => setLocalVisitorNationality(v.toString())}
              huge
              value={localVisitorNationality || 'Selecionar uma nacionalidade'}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={nationalityError.error}
              reason={nationalityError.reason}
            />
            <Divider className={classes.divider} />
            {/* ADDRESS INFO */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.residence" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localVisitorAddress}
              onChange={ev => {
                setLocalVisitorAddress(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={addressError.error}
              reason={addressError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.location" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localVisitorLocation}
              onChange={ev => {
                setLocalVisitorLocation(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={locationError.error}
              reason={locationError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.postalCode" />
            </Text>
            <div className={classes.row}>
              <Input
                className={classes.disableTextColor}
                type="number"
                placeholder="XXXX"
                onChange={ev => {
                  setLocalVisitorFirstSection(ev.currentTarget.value);
                }}
                value={localVisitorFirstSection}
              />

              <span className={classes.spacer}>-</span>
              <Input
                className={classes.disableTextColor}
                placeholder="XXX"
                type="number"
                onChange={ev => {
                  setLocalVisitorLastSection(ev.currentTarget.value);
                }}
                value={localVisitorLastSection}
              />
            </div>
            <AnimatedError
              isVisible={zipCode1Error.error}
              reason={zipCode1Error.reason}
            />
            <AnimatedError
              isVisible={zipCode2Error.error}
              reason={zipCode2Error.reason}
            />
            <Divider className={classes.divider} />
            {/* EMAIL */}
            <Text noMargin weight="semibold">
              <Translate id="profile.email" />
            </Text>
            <Input
              type={'email'}
              className={classes.disableTextColor}
              value={localVisitorEmail}
              onChange={ev => {
                setLocalVisitorEmail(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={emailError.error}
              reason={emailError.reason}
            />
            {/* PASSWORD */}
            <div className={classes.centeredRow}>
              <AppInput
                huge
                className={classes.passwordInput}
                type={isPasswordShowing ? 'text' : 'password'}
                placeholder={translate('register.password').toString()}
                onChange={ev => setLocalPassword(ev.target.value)}
                value={localPassword}
                prefix={<Lock className={classes.icon} />}
                suffix={
                  isPasswordShowing ? (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <See className={classes.icon} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <Unsee className={classes.icon} />
                    </div>
                  )
                }
              />
            </div>
            <AnimatedInfo
              isVisible={newPassword.isVisible ? newPassword.isVisible : false}
              infos={newPassword.infos}
            />

            <Divider className={classes.divider} />
            {/* B-DAY */}
            <Text weight="semibold">
              <Translate id="profile.dateOfBirth" />
            </Text>
            <BirthDateSelector
              onDateChange={changeDate}
              isInvalid={true}
              reason={''}
              initialValue={localVisitorBirthDate}
            />
            <AnimatedError
              isVisible={birthDateError.error}
              reason={birthDateError.reason}
            />
            <Divider className={classes.divider} />
            {/* PHONE NUMBER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.phoneNumber" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localVisitorPhoneNumber}
              onChange={ev => {
                setLocalVisitorPhoneNumber(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={phoneNumberError.error}
              reason={phoneNumberError.reason}
            />
            <Divider className={classes.divider} />
            {/* GENDER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.gender" />
            </Text>
            <Select
              className={classes.select}
              defaultValue={undefined}
              onChange={v => setLocalVisitorGender(v.toString())}
              value={localVisitorGender || 'Selecionar um genero'}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  key={gender}
                  huge
                  value={gender}
                  label={translate(`genders.${gender}`).toString()}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={genderError.error}
              reason={genderError.reason}
            />
            <Divider className={classes.divider} />
            {/* NIF */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.nif" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localVisitorNIF}
              onChange={ev => {
                setLocalVisitorNIF(ev.target.value);
              }}
            ></Input>

            <Divider className={classes.divider} />
            <Text weight="semibold" noMargin>
              Acessibilidades
            </Text>
            <Checkbox
              key={'showOnly'}
              onChange={selectShowOnly}
              defaultChecked={localVisitorShowOnly}
            >
              Mostrar apenas
            </Checkbox>
            <Divider className={classes.divider} />

            <Checkbox.Group
              options={accessibilitiesOptions}
              defaultValue={localAccessibilities}
              onChange={selectAccessibilities}
            />
          </div>
          <Divider className={classes.divider} />
          <div className={classes.divSubmitButton}>
            <Button
              type="primary"
              shape="round"
              size={'large'}
              onClick={ev => {
                submitForm();
              }}
            >
              <Translate id="adminstration.submitButton" />
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(CreateVisitor);
