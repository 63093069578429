import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useHistory } from 'react-router';
import validator from 'validator';
import isTaxID from 'validator/lib/isTaxID';
import { AnimatedError } from '../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../components/App/AnimatedInfo';
import { Button as AppButton } from '../../../components/App/Button';
import { Input as AppInput } from '../../../components/App/Input';
import { Loader } from '../../../components/App/Loader';
import { Modal } from '../../../components/App/Modal';
import { Option, Select } from '../../../components/App/Select';
import { Heading, Text } from '../../../components/Typography';
import { API_URL } from '../../../consts';
import {
  FinancialInterface,
  useFinancial,
} from '../../../hooks/administration/useFinancial';
import {
  createCompany,
  CreateCompanyDto,
} from '../../../hooks/company/createCompany';
import {
  editCompany,
  EditCompanyDto,
} from '../../../hooks/company/editCompany';
import { Company } from '../../../hooks/company/useCompany';
import { useNifExist } from '../../../hooks/guide/useNifExist';
import { ReactComponent as Bill } from '../../../shared_assets/icons/bill.svg';
import { ReactComponent as Lock } from '../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../shared_assets/icons/unsee.svg';
import { ReactComponent as Upload } from '../../../shared_assets/icons/upload.svg';
import { ErrorCode } from '../../../types/error.codes.enum';
import { RoleType } from '../../../types/role-type.enum';
import { getTheme, maxAllowedFileSize, mobileThreshhold } from '../../../utils';

const useStyles = makeStyles({
  firstInputContainer: {
    padding: '1.5rem 1.5rem 0rem 1.5rem',
  },
  middleInputContainer: {
    padding: '0.5rem 1.5rem 0rem 1.5rem',
  },
  lastInputContainer: {
    padding: '0.5rem 1.5rem 0rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mainInputContainer: {
    width: '75vw',
  },
  spacer: {
    margin: '0 1rem',
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  divider: {
    margin: '1rem 0',
  },
  invisible: {
    display: 'none',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  disableTextColor: {
    color: 'black !important',
  },
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitEditButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
    marginLeft: '50px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  passwordInput: {
    marginRight: '1rem',
  },
  passwordArea: {
    display: 'flex',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  select: {
    width: '100%',
  },
});

interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}

interface CompanyRegisterFormProps extends LocalizeContextProps {
  editable: boolean;
  company?: Company;
}
const CompanyRegisterForm = ({
  translate,
  editable,
  company,
}: CompanyRegisterFormProps) => {
  const classes = useStyles();
  const history = useHistory();
  let documentInputRef: HTMLInputElement | null = null;
  const [editing, setEditing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [financial, setFinancial] = useState<FinancialInterface | null>(null);
  const [companyRepresentativeName, setCompanyRepresentativeName] =
    useState<string>('');
  const [showUploader, setShowUploader] = useState(false);
  const [companyEmail, setCompanyEmail] = useState<string>('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState<string>('');
  const [postalCodePartOne, setPostalCodePartOne] = useState<string>('');
  const [postalCodePartTwo, setPostalCodePartTwo] = useState<string>('');
  const [companyLocation, setCompanyLocation] = useState<string>('');
  const [companyNIF, setCompanyNIF] = useState<string>('');
  const [companyAddress, setCompanyAddress] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [newPassword, setNewPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });
  const [companyIban, setCompanyIban] = useState<string>('');
  const [ivaExemption, setIvaExemption] = useState<string | null>(null);
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [companyDocument, setDocument] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [nifError, setNifError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [companyIbanError, setCompanyIbanError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [companyRepresentativeNameError, setCompanyRepresentativeNameError] =
    useState({
      error: true,
      reason: 'registerError.mandatoryField',
      visible: false,
    });
  const [companyNameError, setCompanyNameError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [phoneNumberError, setPhoneNumberError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [postalCodeError, setPostalCodeError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [companyLocationError, setCompanyLocationError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [companyAddressError, setCompanyAddressError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [emailError, setEmailError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [documentError, setDocumentError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });
  const [ivaExemptionError, setIvaExemptionError] = useState({
    error: true,
    reason: 'registerError.mandatoryField',
    visible: false,
  });

  const [changePassword, setChangePassword] = useState(false);
  useFinancial(setFinancial);
  const isRegisteredDto = useMemo(
    () => ({
      nif: companyNIF,
    }),
    [companyNIF]
  );
  useMemo(() => companyEmail, [companyEmail]);
  //const isEmailRegistered = useIsEmailRegistered(validateEmail);
  const isNifRegistered = useNifExist(isRegisteredDto);
  useEffect(() => {
    setEditing(editable);
  }, [editable]);
  const ivaOptions = useMemo(() => {
    if (!financial) return;

    return financial.ivas.map(i => {
      return (
        <Option key={i.id} value={i.id}>
          {i.relevance === 'MINOR' ? (
            <Text variant="faded">{i.invoiceMention}</Text>
          ) : (
            <Text>{i.invoiceMention}</Text>
          )}
        </Option>
      );
    });
  }, [financial]);

  useMemo(() => {
    if (editable) return;
    if (!company) return;
    if (editing) return;
    setCompanyEmail(company.email);
    setCompanyPhoneNumber(company.phoneNumber.toString());
    setPostalCodePartOne(company.postalCode.split('-')[0]);
    setPostalCodePartTwo(company.postalCode.split('-')[1]);
    setCompanyLocation(company.location);
    setCompanyNIF(company.nif.toString());
    setCompanyIban(company.iban);
    setCompanyAddress(company.address);
    setCompanyName(company.name);
    setCompanyRepresentativeName(company.representativeName);
    setIvaExemption(company.ivaExemptionId);
  }, [company, editable, editing]);
  //ANCHOR:REP NAME ERROR
  useMemo(() => {
    switch (true) {
      case companyIban.length === 0:
        setCompanyIbanError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      default:
        setCompanyIbanError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyIban]);
  useMemo(() => {
    switch (true) {
      case companyRepresentativeName.length === 0:
        setCompanyRepresentativeNameError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      case companyRepresentativeName.length < 2:
        setCompanyRepresentativeNameError({
          error: true,
          reason: 'registerError.name',
          visible: true,
        });
        break;

      case validator.isAlphanumeric(companyRepresentativeName):
        setCompanyRepresentativeNameError({
          error: true,
          reason: 'registerError.name',
          visible: true,
        });
        break;

      case validator.isNumeric(companyRepresentativeName):
        setCompanyRepresentativeNameError({
          error: true,
          reason: 'registerError.name',
          visible: true,
        });
        break;

      default:
        setCompanyRepresentativeNameError({
          error: false,
          reason: '',
          visible: false,
        });
        break;
    }
  }, [companyRepresentativeName]);
  //ANCHOR:COMPANY NAME ERROR
  useMemo(() => {
    switch (true) {
      case companyName.length === 0:
        setCompanyNameError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      default:
        setCompanyNameError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyName]);

  useMemo(() => {
    switch (true) {
      case ivaExemption === null:
        setIvaExemptionError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      default:
        setIvaExemptionError({ error: false, reason: '', visible: false });
        break;
    }
  }, [ivaExemption]);

  //ANCHOR:NIF ERROR
  useMemo(() => {
    const firstDigit = companyNIF.substring(0, 1);

    switch (true) {
      case isNifRegistered === true:
        setNifError({
          error: true,
          reason: 'registerError.nifAlreadyRegistered',
          visible: true,
        });
        break;
      case firstDigit === '1' || firstDigit === '2' || firstDigit === '3':
        setNifError({
          error: true,
          reason: 'registerError.nifIsNotCompany',
          visible: true,
        });
        break;
      case companyNIF.length === 0:
        setNifError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      case !isTaxID(companyNIF, 'pt-PT'):
        setNifError({
          error: true,
          reason: 'registerError.nif',
          visible: true,
        });
        break;
      case !validator.isNumeric(companyNIF):
        setNifError({
          error: true,
          reason: 'registerError.nif',
          visible: true,
        });
        break;
      default:
        setNifError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyNIF, isNifRegistered]);
  //ANCHOR:PHONE ERROR
  useMemo(() => {
    switch (true) {
      case companyPhoneNumber.length === 0:
        setPhoneNumberError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      case !validator.isNumeric(companyPhoneNumber):
        setPhoneNumberError({
          error: true,
          reason: 'registerError.phoneNumber',
          visible: true,
        });
        break;
      case companyPhoneNumber.length > 9:
        setPhoneNumberError({
          error: true,
          reason: 'registerError.phoneNumber',
          visible: true,
        });
        break;

      default:
        setPhoneNumberError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyPhoneNumber]);
  //ANCHOR:POSTAL CODE ERROR
  useMemo(() => {
    switch (true) {
      case postalCodePartOne.length === 0 || postalCodePartTwo.length === 0:
        setPostalCodeError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      case !validator.isPostalCode(
        `${postalCodePartOne}-${postalCodePartTwo}`,
        'PT'
      ):
        setPostalCodeError({
          error: true,
          reason: 'registerError.postalCode',
          visible: true,
        });
        break;
      default:
        setPostalCodeError({ error: false, reason: '', visible: false });
        break;
    }
  }, [postalCodePartOne, postalCodePartTwo]);
  //ANCHOR:DocumentError ERROR
  useMemo(() => {
    switch (true) {
      case companyDocument === null && editable:
        setDocumentError({
          error: true,
          reason: 'registerError.mandatoryDocument',
          visible: false,
        });
        break;
      default:
        setDocumentError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyDocument, editable]);

  //ANCHOR:CompanyLocation ERROR
  useMemo(() => {
    switch (true) {
      case companyLocation.length === 0:
        setCompanyLocationError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      default:
        setCompanyLocationError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyLocation]);
  //ANCHOR:companyAddress ERROR
  useMemo(() => {
    switch (true) {
      case companyAddress.length === 0:
        setCompanyAddressError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      default:
        setCompanyAddressError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyAddress]);
  //ANCHOR:company email ERROR

  useMemo(() => {
    switch (true) {
      case companyEmail.length === 0:
        setEmailError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      case !validator.isEmail(companyEmail):
        setEmailError({
          error: true,
          reason: 'registerError.email',
          visible: true,
        });
        break;
      default:
        setEmailError({ error: false, reason: '', visible: false });
        break;
    }
  }, [companyEmail]);
  useMemo(() => {
    switch (true) {
      case ivaExemption === null:
        setIvaExemptionError({
          error: true,
          reason: 'registerError.mandatoryField',
          visible: false,
        });
        break;
      default:
        setIvaExemptionError({ error: false, reason: '', visible: false });
        break;
    }
  }, [ivaExemption]);
  useMemo(() => {
    if (password.length === 0) {
      setNewPassword({
        value: password,
        isInvalid: true,
        isVisible: false,
        infos: [],
      });
      return;
    }
    const formatNumSymbol = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const formatStrength =
      /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    const hasRightLength = password.length >= 8 && password.length <= 20;
    const emailSplit = companyEmail.includes('@') && companyEmail.split('@');
    const isStrength = password.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (companyEmail.includes(companyRepresentativeName.split(' ')[0]) &&
        companyRepresentativeName.split(' ')[0] !== '') ||
      (password.includes(companyRepresentativeName.split(' ')[1]) &&
        companyRepresentativeName.split(' ')[1] !== '') ||
      (emailSplit && password.includes(emailSplit[0]) && companyEmail !== '') ||
      password === '';
    const isContainNumSymbol =
      formatNumSymbol.test(password) && password.match(/\d/) ? true : false;
    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isContainNumSymbol &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('company.registerError.passwordStrengthInfo').toString(),
            translate('company.registerError.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [
            translate('company.registerError.passNoContainNameMail').toString(),
          ],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [
            translate('company.registerError.rightLengthPassword').toString(),
          ],
        },
        {
          infoType: isContainNumSymbol ? 'success' : 'default',
          phrase: [
            translate('company.registerError.passwordRestrictions').toString(),
          ],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('company.registerError.passwordStrengthInfo').toString(),
            translate('company.registerError.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewPassword({
      value: password,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [password, companyRepresentativeName, companyEmail, translate]);

  const submitFormCreation = useCallback(async () => {
    if (
      companyNameError.error ||
      postalCodeError.error ||
      companyAddressError.error ||
      companyLocationError.error ||
      phoneNumberError.error ||
      emailError.error ||
      nifError.error ||
      companyRepresentativeNameError.error ||
      newPassword.isInvalid === true ||
      companyDocument === null ||
      ivaExemptionError.error ||
      ivaExemption === null ||
      companyIbanError.error
    ) {
      if (companyDocument === null) {
        setDocumentError({
          error: true,
          reason: 'registerError.mandatoryDocument',
          visible: true,
        });
      }

      if (ivaExemptionError.error) {
        setIvaExemptionError({
          error: true,
          reason: ivaExemptionError.reason,
          visible: true,
        });
      }
      if (companyIbanError.error) {
        setCompanyIbanError({
          error: true,
          reason: companyIbanError.reason,
          visible: true,
        });
      }
      if (nifError.error) {
        setNifError({
          error: true,
          reason: nifError.reason,
          visible: true,
        });
      }
      if (companyRepresentativeNameError.error) {
        setCompanyRepresentativeNameError({
          error: true,
          reason: companyRepresentativeNameError.reason,
          visible: true,
        });
      }
      if (companyNameError.error) {
        setCompanyNameError({
          error: true,
          reason: companyNameError.reason,
          visible: true,
        });
      }
      if (phoneNumberError.error) {
        setPhoneNumberError({
          error: true,
          reason: phoneNumberError.reason,
          visible: true,
        });
      }
      if (postalCodeError.error) {
        setPostalCodeError({
          error: true,
          reason: postalCodeError.reason,
          visible: true,
        });
      }
      if (companyLocationError.error) {
        setCompanyLocationError({
          error: true,
          reason: companyLocationError.reason,
          visible: true,
        });
      }
      if (companyAddressError.error) {
        setCompanyAddressError({
          error: true,
          reason: companyAddressError.reason,
          visible: true,
        });
      }
      if (emailError.error === true) {
        setEmailError({
          error: true,
          reason: emailError.reason,
          visible: true,
        });
      }
      if (newPassword.isInvalid === true) {
        setNewPassword({
          value: password,
          isInvalid: true,
          isVisible: true,
          infos: [
            {
              infoType: 'error',
              phrase: [
                translate('company.registerError.passwordEmpty').toString(),
                translate('company.registerError.mandatoryField').toString(),
              ],
            },
          ],
        });
      }
      return;
    }

    try {
      setIsSubmitting(true);
      const createDto: CreateCompanyDto = {
        companyEmail: companyEmail,
        companyLocation: companyLocation,
        companyAddress: companyAddress,
        companyNIF: companyNIF,
        companyName: companyName,
        password: password,
        postalCode: `${postalCodePartOne}-${postalCodePartTwo}`,
        companyRepresentativeName: companyRepresentativeName,
        companyPhoneNumber: parseInt(companyPhoneNumber),
        type: RoleType.COMPANY,
        ivaExemptionId: ivaExemption,
        companyIban: companyIban,
      };

      await createCompany(createDto, companyDocument);

      history.replace(`/company/landing?signUp=success`);
    } catch (err) {
      setIsSubmitting(false);
      setIsUpdating(false);
      switch (true) {
        case err.response.data.error === ErrorCode.USER_IS_COMPANY:
          message.error(
            translate(`error.API_ERROR.${ErrorCode.USER_IS_COMPANY}`)
          );

          break;
        case err.response.data.error === ErrorCode.EMAIL_REGISTERED:
          setEmailError({
            error: true,
            reason: 'registerError.emailAlreadyRegistered',
            visible: true,
          });
          break;
      }
      // message.error(err.response.data.message);
    }
  }, [
    companyNameError.error,
    companyNameError.reason,
    postalCodeError.error,
    postalCodeError.reason,
    companyAddressError.error,
    companyAddressError.reason,
    companyLocationError.error,
    companyLocationError.reason,
    phoneNumberError.error,
    phoneNumberError.reason,
    emailError.error,
    emailError.reason,
    nifError.error,
    nifError.reason,
    companyRepresentativeNameError.error,
    companyRepresentativeNameError.reason,
    newPassword.isInvalid,
    companyDocument,
    ivaExemptionError.error,
    ivaExemptionError.reason,
    ivaExemption,
    companyIbanError.error,
    companyIbanError.reason,
    password,
    translate,
    companyEmail,
    companyLocation,
    companyAddress,
    companyNIF,
    companyName,
    postalCodePartOne,
    postalCodePartTwo,
    companyRepresentativeName,
    companyPhoneNumber,
    companyIban,
    history,
  ]);
  const requestChangePassword = useCallback(async () => {
    if (newPassword.isInvalid) return;
    if (!company) return;
    try {
      await Axios.patch(
        `${API_URL}/authentication/profile/${company.id}/reset-password`,
        { newPassword: newPassword.value, oldPassword: 'none' }
      );
      //close modal
      message.success(
        translate('resetPassword.resetPasswordSuccess').toString()
      );
    } catch (err) {}
  }, [newPassword, company, translate]);
  const submitFormEditing = useCallback(async () => {
    if (!editing || !company) return;
    if (
      companyNameError.error ||
      postalCodeError.error ||
      companyAddressError.error ||
      companyLocationError.error ||
      phoneNumberError.error ||
      emailError.error ||
      nifError.error ||
      companyRepresentativeNameError.error ||
      documentError.error ||
      ivaExemption === null
    ) {
      return;
    }
    try {
      setIsUpdating(true);
      const dto: EditCompanyDto = {
        companyAddress: companyAddress,
        companyEmail: companyEmail,
        companyLocation: companyLocation,
        companyName: companyName,
        companyNIF: companyNIF,
        companyPhoneNumber: parseInt(companyPhoneNumber),
        postalCode: `${postalCodePartOne}-${postalCodePartTwo}`,
        companyRepresentativeName: companyRepresentativeName,
        ivaExemption: ivaExemption,
        companyIban: companyIban,
      };
      await editCompany(company.id, dto, companyDocument);

      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setIsUpdating(false);
    }
  }, [
    editing,
    company,
    companyNameError.error,
    postalCodeError.error,
    companyAddressError.error,
    companyLocationError.error,
    phoneNumberError.error,
    emailError.error,
    nifError.error,
    companyRepresentativeNameError.error,
    documentError.error,
    ivaExemption,
    companyAddress,
    companyEmail,
    companyLocation,
    companyName,
    companyNIF,
    companyPhoneNumber,
    postalCodePartOne,
    postalCodePartTwo,
    companyRepresentativeName,
    companyIban,
    companyDocument,
    translate,
  ]);
  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div className={classes.mainInputContainer}>
      {/* NAME */}
      <div className={classes.firstInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id="company.register.name" />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyRepresentativeName}
          onChange={ev => {
            setCompanyRepresentativeName(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={
            companyRepresentativeNameError.error &&
            companyRepresentativeNameError.visible
          }
          reason={
            <Translate
              id={`company.${companyRepresentativeNameError.reason}`}
            />
          }
        />
      </div>
      {/* COMPANY NAME */}
      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id="company.register.companyName" />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyName}
          onChange={ev => {
            setCompanyName(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={companyNameError.error && companyNameError.visible}
          reason={<Translate id={`company.${companyNameError.reason}`} />}
        />
      </div>
      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id="company.register.iban" />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyIban}
          onChange={ev => {
            setCompanyIban(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={companyIbanError.error && companyIbanError.visible}
          reason={<Translate id={`company.${companyIbanError.reason}`} />}
        />
      </div>
      {/* NIF*/}

      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id="company.register.nif" />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyNIF}
          onChange={ev => {
            setCompanyNIF(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={nifError.error && nifError.visible}
          reason={<Translate id={`company.${nifError.reason}`} />}
        />
      </div>

      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id="onboarding.ivaExemption" />
        </Text>
        {financial && (
          <>
            <Select
              disabled={!editing}
              placeholder={translate(
                'onboarding.ivaExemptionPlaceholder'
              ).toString()}
              className={classes.select}
              value={ivaExemption === null ? undefined : ivaExemption}
              onChange={ev => setIvaExemption(ev as string)}
              huge
              getPopupContainer={() => document.body}
            >
              {ivaOptions}
            </Select>
            <Text variant="faded">
              {ivaExemption
                ? financial.ivas.find(i => i.id === ivaExemption)
                    ?.applicableStandard
                : ''}
            </Text>
            <AnimatedError
              isVisible={ivaExemptionError.error && ivaExemptionError.visible}
              reason={<Translate id={`company.${ivaExemptionError.reason}`} />}
            />
          </>
        )}
      </div>

      {/* EMAIL */}
      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id={'company.register.email'} />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyEmail}
          type="email"
          onChange={ev => {
            setCompanyEmail(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={emailError.error && emailError.visible}
          reason={<Translate id={`company.${emailError.reason}`} />}
        />
      </div>
      {/* PASSWORD */}
      {!editable ? (
        <>
          <Modal
            fullScreenOnMobile={false}
            contentContainerClassName={classes.modalContent}
            headerTitle={<Translate id="button.addSkill" />}
            footer={
              <div className={classes.footer}>
                <Button
                  onClick={() => {
                    setChangePassword(false);
                  }}
                >
                  <Translate id="button.cancel" />
                </Button>
                <div className={classes.addRemoveBtn}>
                  <Button
                    type="primary"
                    onClick={() => {
                      requestChangePassword();
                      setChangePassword(false);
                    }}
                  >
                    <Translate id="button.save" />
                  </Button>
                </div>
              </div>
            }
            visible={changePassword}
            closable={false}
          >
            <Text color={'red'} noMargin weight="semibold">
              <Translate id="adminstration.passwordWarning" />
            </Text>
          </Modal>
        </>
      ) : null}
      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id={'company.register.password'} />
        </Text>
        <div className={classes.passwordArea}>
          <AppInput
            huge
            className={editable ? '' : classes.passwordInput}
            type={isPasswordShowing ? 'text' : 'password'}
            placeholder={translate('register.password').toString()}
            onChange={(ev: {
              target: { value: React.SetStateAction<string> };
            }) => {
              setPassword(ev.target.value);
            }}
            value={password}
            disabled={!editing}
            prefix={<Lock />}
            suffix={
              isPasswordShowing ? (
                <div
                  onClick={() => {
                    setIsPasswordShowing(prevState => !prevState);
                  }}
                >
                  <See />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsPasswordShowing(prevState => !prevState);
                  }}
                >
                  <Unsee />
                </div>
              )
            }
          />
          {!editable ? (
            <Button
              disabled={!editing}
              type="ghost"
              onClick={() => {
                setChangePassword(true);
              }}
            >
              <Translate id="profile.change" />
            </Button>
          ) : null}
        </div>
        <AnimatedInfo
          isVisible={newPassword.isVisible ? newPassword.isVisible : false}
          infos={newPassword.infos}
        />
      </div>

      {/* PHONE NUMBER */}
      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id={'company.register.phoneNumber'} />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyPhoneNumber}
          onChange={ev => {
            setCompanyPhoneNumber(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={phoneNumberError.error && phoneNumberError.visible}
          reason={<Translate id={`company.${phoneNumberError.reason}`} />}
        />
      </div>
      {/* ADDRESS */}

      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id={'company.register.companyAddress'} />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyAddress}
          onChange={ev => {
            setCompanyAddress(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={companyAddressError.error && companyAddressError.visible}
          reason={<Translate id={`company.${companyAddressError.reason}`} />}
        />
      </div>
      {/* LOCATION */}
      <div className={classes.middleInputContainer}>
        <Text noMargin weight="semibold">
          <Translate id={'company.register.companyLocation'} />
        </Text>
        <Input
          disabled={!editing}
          className={classes.disableTextColor}
          value={companyLocation}
          onChange={ev => {
            setCompanyLocation(ev.target.value);
          }}
        ></Input>
        <AnimatedError
          isVisible={companyLocationError.error && companyLocationError.visible}
          reason={<Translate id={`company.${companyLocationError.reason}`} />}
        />
      </div>
      {/* POSTAL CODE */}
      <div className={classes.middleInputContainer}>
        <Text weight="semibold" noMargin>
          <Translate id="company.register.postalCode" />
        </Text>
        <div className={classes.row}>
          <Input
            disabled={!editing}
            className={classes.disableTextColor}
            type="number"
            placeholder="XXXX"
            min="0"
            onChange={ev => {
              setPostalCodePartOne(ev.currentTarget.value);
            }}
            value={postalCodePartOne}
          />

          <span className={classes.spacer}>-</span>
          <Input
            disabled={!editing}
            className={classes.disableTextColor}
            placeholder="XXX"
            min="0"
            type="number"
            onChange={ev => {
              setPostalCodePartTwo(ev.currentTarget.value);
            }}
            value={postalCodePartTwo}
          />
        </div>
        <AnimatedError
          isVisible={postalCodeError.error && postalCodeError.visible}
          reason={<Translate id={`company.${postalCodeError.reason}`} />}
        />
      </div>
      {!editable ? (
        <>
          <Divider className={classes.divider} />
          {company ? (
            <div>
              <Heading level={5} className={classes.titleMargin}>
                <Translate id="company.adminArea.downloadDocument" />
              </Heading>
              <a
                // eslint-disable-next-line react/jsx-no-target-blank
                target={'_blank'}
                href={`${API_URL}/company/${
                  company.id
                }/document?_=${moment().unix()}`}
              >
                {company.documentName}
              </a>
            </div>
          ) : null}
        </>
      ) : null}

      {/* ANCHOR UPLOAD */}

      {(editable && editing) || showUploader ? (
        <>
          <Heading level={5} className={classes.titleMargin}>
            <Translate id="company.register.documentRequest" />
          </Heading>
          <input
            ref={ref => (documentInputRef = ref)}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedFileSize
              ) {
                setDocument(ev.currentTarget.files[0]);
              } else {
                message.info(translate('error.fileSize').toString());
              }
            }}
            type="file"
            className={classes.invisible}
          />
          <div className={classes.fileContainer}>
            {companyDocument ? (
              <div className={classes.file}>
                <div className={classes.fileIconContainer}>
                  <Bill />
                </div>
                <div className={classes.textContainer}>
                  <Text>{companyDocument.name}</Text>
                  <span>{companyDocument.size}</span>
                </div>
              </div>
            ) : null}
          </div>
          <AppButton
            type="ghost"
            onClick={() => {
              if (documentInputRef) documentInputRef.click();
            }}
            prefix={<Upload />}
          >
            <Translate id="onboarding.uploadCertificates" />
          </AppButton>
          <Text className={classes.textAlignInitial}>
            <Translate id="onboarding.certificateSize" />
          </Text>
          <AnimatedError
            isVisible={documentError.error && documentError.visible}
            reason={<Translate id={`company.${documentError.reason}`} />}
          />
        </>
      ) : null}

      {!editable ? (
        <div className={classes.lastInputContainer}>
          {editing ? (
            <div className={classes.evaluateButton}>
              <Button
                className={classes.cancelButton}
                type="primary"
                shape="round"
                size={'large'}
                onClick={ev => {
                  setEditing(false);
                  setShowUploader(false);
                }}
              >
                <Translate id="company.buttons.cancel" />
              </Button>
              <Button
                className={classes.submitEditButton}
                type="primary"
                shape="round"
                size={'large'}
                onClick={ev => {
                  submitFormEditing();
                }}
              >
                <Translate id="company.buttons.save" />
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              shape="round"
              size={'large'}
              onClick={ev => {
                setEditing(true);
                setShowUploader(true);
              }}
            >
              <Translate id="company.buttons.edit" />
            </Button>
          )}
        </div>
      ) : (
        <div className={classes.evaluateButton}>
          <Button
            type="primary"
            shape="round"
            size={'large'}
            onClick={ev => {
              submitFormCreation();
            }}
          >
            <Translate id="company.buttons.register" />
          </Button>
        </div>
      )}
      <Divider className={classes.divider} />
    </div>
  );
};
export default withLocalize(CompanyRegisterForm);
