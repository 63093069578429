import { makeStyles } from '@material-ui/styles';
import Badge from 'antd/es/badge';
import Divider from 'antd/es/divider';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useGuide } from '../../../hooks/guide/UseGuide';
import { ReactComponent as Calendar } from '../../../shared_assets/icons/calendar.svg';
import { ReactComponent as ChangeProfile } from '../../../shared_assets/icons/change_profile.svg';
import { ReactComponent as Comments } from '../../../shared_assets/icons/comments.svg';
import { ReactComponent as Logout } from '../../../shared_assets/icons/exit.svg';
import { ReactComponent as Help } from '../../../shared_assets/icons/help.svg';
import { ReactComponent as Language } from '../../../shared_assets/icons/language.svg';
import { ReactComponent as NotificationBlue } from '../../../shared_assets/icons/notification_blue.svg';
import { ReactComponent as NotificationYellow } from '../../../shared_assets/icons/notification_yellow.svg';
import { ReactComponent as Payment } from '../../../shared_assets/icons/payment.svg';
import { ReactComponent as About } from '../../../shared_assets/icons/quem_somos.svg';
import { ReactComponent as Routes } from '../../../shared_assets/icons/routes.svg';
import { ReactComponent as User } from '../../../shared_assets/icons/user.svg';
import { ReactComponent as WarningYellow } from '../../../shared_assets/icons/warning_yellow_solid.svg';
import { RootState } from '../../../store';
import { logout } from '../../../store/UserConfiguration/actions';
import { Guide } from '../../../types/guide.interface';
import { useIsMobile } from '../../../utils';
import { Text } from '../../Typography';
import { Badge as Chip } from '../Badge';
import { Error } from '../Error';
import { HelpModal } from '../HelpModal';
import { Loader } from '../Loader';
import { DrawerFragmentProps } from './Drawer';
import DrawerItem from './DrawerItem';

export interface AuthenticatedDrawerFragmentProps extends DrawerFragmentProps {
  id: string;
  setOpenStatus: (open: boolean) => void;
  setChangingAccountType: (v: boolean) => void;
}

interface GuideAuthenticatedDrawerFragmentProps
  extends AuthenticatedDrawerFragmentProps {
  guideIsApproved: boolean;
}

const useStyles = makeStyles({
  profile: {
    display: 'flex',
    alignItems: 'center',
    '& > .ant-typography': {
      marginRight: '0.5rem !important',
    },
  },
  badge: {
    '& .ant-badge-dot': {
      width: '7px',
      height: '7px',
      top: '5px',
      right: '5px',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  divider: { margin: '0.5rem 0' },
});

function AuthenticatedGuideDrawerFragment({
  reduxActiveLanguage,
  id: guideId,
  setOpenStatus,
  setShowLanguageModal,
  setChangingAccountType,
  guideIsApproved,
}: GuideAuthenticatedDrawerFragmentProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [guide, setGuide] = useState<Guide | null>(null);
  const [isLoadingGuide, hasErrorGuide] = useGuide(guideId, setGuide);
  const [showingHelp, setShowingHelp] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const unseenNotifications = useSelector(
    (state: RootState) => state.app.guideUnseenNotifications
  );

  if (isLoadingGuide) {
    return <Loader />;
  }

  if (hasErrorGuide || !guide) {
    return <Error />;
  }
  return (
    <>
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/guide/profile"
        title={
          <Text weight="bold" noMargin className={classes.capitalize}>
            {`${guide.firstName} ${guide.lastName}`}
          </Text>
        }
        subtitle={
          <div className={classes.profile}>
            <Text variant="faded" noMargin>
              <Translate id="landing.drawer.profile" />
            </Text>
            <Chip type="primary">
              <Translate id="landing.drawer.pediguide" />
            </Chip>
          </div>
        }
        icon={<User />}
      />
      <Divider className={classes.divider} />
      {unseenNotifications ? (
        <DrawerItem
          setOpenStatus={setOpenStatus}
          closeOnClick
          to="/guide/my-notifications"
          title={<Translate id="landing.drawer.notifications" />}
          icon={
            Number(unseenNotifications) === 0 ? (
              <NotificationBlue />
            ) : (
              <Badge dot className={classes.badge}>
                <NotificationYellow />
              </Badge>
            )
          }
        />
      ) : null}

      {guideIsApproved ? (
        <>
          <Divider className={classes.divider} />
          {guide.numberOfRoutes > 0 ? (
            <>
              <DrawerItem
                setOpenStatus={setOpenStatus}
                closeOnClick
                to="/guide/my-routes"
                title={<Translate id="landing.drawer.myRoutes" />}
                icon={<Routes />}
              />
              <DrawerItem
                setOpenStatus={setOpenStatus}
                closeOnClick
                to="/guide/schedules"
                title={<Translate id="landing.drawer.calendar" />}
                icon={<Calendar />}
              />
              <DrawerItem
                setOpenStatus={setOpenStatus}
                closeOnClick
                to="/guide/payment-history"
                title={<Translate id="landing.drawer.paymentHistory" />}
                icon={<Payment />}
              />
              <DrawerItem
                setOpenStatus={setOpenStatus}
                closeOnClick
                to="/guide/my-comments"
                title={<Translate id="landing.drawer.comments" />}
                icon={<Comments />}
              />
            </>
          ) : (
            <DrawerItem
              setOpenStatus={setOpenStatus}
              closeOnClick
              to="/guide/my-routes"
              title={<Translate id="landing.drawer.organizeRoute" />}
              icon={<Routes />}
            />
          )}
        </>
      ) : (
        <>
          <Divider className={classes.divider} />

          <DrawerItem
            title={<Translate id="landing.drawer.notApproved" />}
            icon={<WarningYellow />}
          />
        </>
      )}

      {isMobile && (
        <>
          <Divider className={classes.divider} />
          <DrawerItem
            setOpenStatus={setOpenStatus}
            closeOnClick
            onClick={() => {
              setShowLanguageModal(true);
            }}
            title={<Translate id="landing.drawer.language" />}
            subtitle={
              <span>
                <Translate id={`languages.${reduxActiveLanguage}`} />
                {` (${reduxActiveLanguage.split('_')[1]})`}
              </span>
            }
            icon={<Language />}
          />
          <Divider className={classes.divider} />
          <DrawerItem
            setOpenStatus={setOpenStatus}
            closeOnClick
            to="/about"
            title={<Translate id="landing.drawer.aboutUs" />}
            icon={<About />}
          />
          <DrawerItem
            setOpenStatus={setOpenStatus}
            closeOnClick
            onClick={() => {
              setShowingHelp(true);
            }}
            title={<Translate id="landing.drawer.help" />}
            icon={<Help />}
          />
        </>
      )}
      <Divider className={classes.divider} />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setChangingAccountType(true);
        }}
        title={<Translate id="landing.drawer.changeAccount" />}
        icon={<ChangeProfile />}
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/guide"
        onClick={() => {
          dispatch(logout());
        }}
        title={<Translate id="landing.drawer.logout" />}
        icon={<Logout />}
      />
      <HelpModal
        visible={showingHelp}
        onRequestClose={() => {
          setShowingHelp(false);
        }}
      />
    </>
  );
}

export default AuthenticatedGuideDrawerFragment;
