import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CreateSchedules } from './CreateSchedules';

function ScheduleRouter() {
  return (
    <Switch>
      <Route path="/guide/schedules" component={CreateSchedules} />
      <Redirect to="/guide/schedules" />
    </Switch>
  );
}

export default ScheduleRouter;
