import { LoadScript } from '@react-google-maps/api';
import message from 'antd/es/message';
import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { getTranslate, LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { GOOGLE_API_KEY, GOOGLE_LIBRARIES } from './consts';
import { MainRouter } from './pages/MainRouter';
import { persistor, store } from './store';
import { LOGOUT } from './store/UserConfiguration/types';

//When booting up, if there is a token persisted tell axios to use it
const SetAxiosToken = () => {
  const token = store.getState().userConfiguration.jwt;
  Axios.defaults.headers['source-url'] = window.location.hostname;
  moment.locale(store.getState().userConfiguration.activeLanguage);
  if (token) Axios.defaults.headers['Authorization'] = 'Bearer ' + token;
};

Axios.interceptors.response.use(
  response => response,
  error => {
    const translate = getTranslate(store.getState().localize);
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch({ type: LOGOUT });
      }
      if (error.response.status === 403) {
        message.error(translate('error.notAuthorized'));
        window.location.replace('/');
      }
    }

    return Promise.reject(error);
  }
);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<div>LoadingState</div>}
        persistor={persistor}
        onBeforeLift={SetAxiosToken}
      >
        <LocalizeProvider store={store}>
          <LoadScript
            googleMapsApiKey={GOOGLE_API_KEY}
            libraries={GOOGLE_LIBRARIES}
          >
            <MainRouter />
          </LoadScript>
        </LocalizeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
