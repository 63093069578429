import { makeStyles } from '@material-ui/styles';
import { Space } from 'antd';
import Table from 'antd/es/table';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import Loader from 'react-spinners/BeatLoader';
import { Button } from '../../../../../components/App/Button';
import { Modal } from '../../../../../components/App/Modal';
import { Text } from '../../../../../components/Typography';
import { AddAdEventResponse } from '../../../../../hooks/advertiser/addAdEvent';
import { EditAdEventResponse } from '../../../../../hooks/advertiser/editAdEvent';
import { removeAdEvent } from '../../../../../hooks/advertiser/removeAdEvent';
import {
  AdEventResponse,
  useAdEvent,
} from '../../../../../hooks/advertiser/useAdEvent';
import CreateAdEventModal from './CreateAdEventModal';

const useStyles = makeStyles({
  addDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },
  header: {
    paddingTop: 20,
  },
});
interface ParamsInterface {
  id: string;
}
const dateFormat = 'DD/MM/YYYY';

const ListAdEvents = () => {
  const params = useParams<ParamsInterface>();

  const [events, setEvents] = useState<AdEventResponse>([]);
  const classes = useStyles();
  const [isLoading] = useAdEvent(setEvents, params.id);

  const [adding, setAdding] = useState<boolean>(false);
  const [editing, setEditing] = useState<string | undefined>();
  const [removing, setRemoving] = useState<string | undefined>();

  const closeAdding = useCallback(() => {
    setAdding(false);
  }, []);
  const onAdd = useCallback((newHelp: AddAdEventResponse) => {
    setEvents(prevHelp => [...prevHelp, newHelp]);
    setAdding(false);
  }, []);

  const onEdit = useCallback((newHelp: EditAdEventResponse) => {
    setEvents(prevHelp =>
      prevHelp.map(h => (h.id === newHelp.id ? newHelp : h))
    );
    setEditing(undefined);
  }, []);
  // const onChange = useCallback((e: boolean, id: string) => {
  //   const el = document.getElementById(`switch_${id}`);

  //   changeAdEventState(id, e);
  // }, []);

  const columns = useMemo(
    () => [
      // {
      //   title: <Translate id="adminstration.adEventArea.active" />,
      //   dataIndex: 'active',
      //   key: 'active',
      //   render: (text: any, record: any) => (
      //     <Switch
      //       key={`switch_${record.id}`}
      //       checked={record.active}
      //       onChange={e => {
      //         onChange(e, record.id);
      //       }}
      //     />
      //   ),
      // },
      {
        title: <Translate id="adminstration.adEventArea.titles" />,
        dataIndex: 'titles',
        key: 'titles',
        render: (text: any, record: any) => (
          <Text noMargin size={12}>
            {record.titles[0] ? record.titles[0].title : ''}
          </Text>
        ),
      },
      {
        title: <Translate id="adminstration.adEventArea.location" />,
        dataIndex: 'location',
        key: 'location',
      },

      {
        title: <Translate id="adminstration.adEventArea.radius" />,
        dataIndex: 'radius',
        key: 'radius',
      },
      {
        title: <Translate id="adminstration.adEventArea.startAd" />,
        dataIndex: 'startAd',
        key: 'startAd',
        render: (text: any, record: any) => (
          <Text noMargin size={12}>
            {moment(record.startAd).format(dateFormat)}
          </Text>
        ),
      },
      {
        title: <Translate id="adminstration.adEventArea.endAd" />,
        dataIndex: 'endAd',
        key: 'endAd',
        render: (text: any, record: any) => (
          <Text noMargin size={12}>
            {moment(record.endAd).format(dateFormat)}
          </Text>
        ),
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <CreateAdEventModal
              adEventContent={record}
              onEdit={onEdit}
              visible={record.id === editing}
              advertiserId={params.id}
              onRequestClose={() => {
                setEditing(undefined);
              }}
            />
            <Button
              type="link"
              onClick={() => {
                setEditing(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.edit" />
            </Button>

            <Button
              type="link"
              onClick={() => {
                setRemoving(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.remove" />
            </Button>
          </Space>
        ),
      },
    ],
    [editing, onEdit, params.id]
  );

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onDelete = useCallback(async () => {
    try {
      setSubmitting(true);
      if (removing) await removeAdEvent(removing);
      setRemoving(undefined);
      setEvents(prev => prev.filter(h => h.id !== removing));
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [removing]);

  return (
    <div>
      <Modal
        visible={!!removing}
        onRequestClose={() => {
          setRemoving(undefined);
        }}
        footer={
          <div className={classes.footer}>
            <Button
              disabled={submitting}
              onClick={() => setRemoving(undefined)}
            >
              <Translate id="button.cancel" />
            </Button>
            <Button disabled={submitting} onClick={onDelete}>
              <Translate id="button.remove" />
            </Button>
          </div>
        }
        contentContainerClassName={classes.header}
      >
        <Translate id="adminstration.helpZone.removeConfirmation" />
      </Modal>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.addDiv}>
            <Button
              type="primary"
              onClick={() => {
                setAdding(true);
              }}
            >
              <Translate id="adminstration.helpZone.add" />
            </Button>
          </div>
          <CreateAdEventModal
            onAdd={onAdd}
            advertiserId={params.id}
            onRequestClose={closeAdding}
            visible={adding}
          />
          <Table columns={columns} dataSource={events} />
        </>
      )}
    </div>
  );
};

export default React.memo(ListAdEvents);
