import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { Button as LinkButton } from '../../../../../../components/App/Button';
import { FallbackImage } from '../../../../../../components/App/Image';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { Text } from '../../../../../../components/Typography';
import {
  getImageUrl,
  LookFor,
  LookForInterface,
  useLookingFor,
} from '../../../../../../hooks/administration/useLookingFor';
import { postLookFor } from '../../../../../../hooks/administration/usePostLookFor';
import { UseJWT } from '../../../../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../../../../hooks/useFilePreview';
import { ReactComponent as Pediguia } from '../../../../../../shared_assets/images/pediguia_default.svg';
import { ErrorCode } from '../../../../../../types/error.codes.enum';
import { complexTranslate, maxAllowedImageSize } from '../../../../../../utils';
import { GenericPageTransition } from '../../../../../PageUtils';
// ANCHOR: IMPORTS
interface ErrorInterface {
  error: boolean;
  reason: string;
}

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  divider: {
    margin: '1rem 0',
  },
  titleDiv: {
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  avatarArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarPreview: {
    maxHeight: '25rem',
    marginBottom: '1rem',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  button: {
    marginLeft: '1rem',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

interface LookingForProps extends LocalizeContextProps {}
const LookingFor = ({ languages, translate }: LookingForProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();

  const [lookFor, setLookFor] = useState<LookFor | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, errorLoading] = useLookingFor(setLookFor);

  const imageInputRef0 = useRef<HTMLInputElement>(null);
  const [image0, setImage0] = useState<File | null>(null);
  const previewSource0 = useMemo(() => [image0], [image0]);
  const imagePreview0 = useFilePreview(previewSource0);

  const imageInputRef1 = useRef<HTMLInputElement>(null);
  const [image1, setImage1] = useState<File | null>(null);
  const previewSource1 = useMemo(() => [image1], [image1]);
  const imagePreview1 = useFilePreview(previewSource1);

  const imageInputRef2 = useRef<HTMLInputElement>(null);
  const [image2, setImage2] = useState<File | null>(null);
  const previewSource2 = useMemo(() => [image2], [image2]);
  const imagePreview2 = useFilePreview(previewSource2);

  const [position1, setPosition1] = useState<LookForInterface[]>([]);
  const [position2, setPosition2] = useState<LookForInterface[]>([]);
  const [position3, setPosition3] = useState<LookForInterface[]>([]);

  useEffect(() => {
    if (lookFor === null) return;
    setPosition1(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = lookFor?.position1.find(lp => lp.language === l);
          if (translation) {
            return translation;
          }
          return {
            title: '3',
            description: '3',
            position: 0,
            language: l,
          };
        })
    );

    setPosition2(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = lookFor?.position2.find(lp => lp.language === l);
          if (translation) {
            return translation;
          }
          return {
            title: '2',
            description: '2',
            position: 1,
            language: l,
          };
        })
    );

    setPosition3(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = lookFor?.position3.find(lp => lp.language === l);
          if (translation) {
            return translation;
          }
          return {
            title: '1',
            description: '1',
            position: 2,
            language: l,
          };
        })
    );
  }, [languages, lookFor]);
  const submit = useCallback(async () => {
    if (
      position1.some(
        p =>
          validator.isEmpty(p.title) ||
          validator.isEmpty(p.language) ||
          validator.isEmpty(p.description)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    if (
      position2.some(
        p =>
          validator.isEmpty(p.title) ||
          validator.isEmpty(p.language) ||
          validator.isEmpty(p.description)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    if (
      position3.some(
        p =>
          validator.isEmpty(p.title) ||
          validator.isEmpty(p.language) ||
          validator.isEmpty(p.description)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    try {
      await postLookFor(
        {
          lookFors: position1.concat(position2, position3),
        },
        image0,
        image1,
        image2
      );
      message.success(
        translate('adminstration.guideSelection.success').toString()
      );
    } catch (error) {
      message.error(
        `${translate(
          `error.API_ERROR.${ErrorCode.LANDING_PAGE_NEED_TRANSLATION}`
        )} ${translate(`languages.${error.message}`)}`
      );
    }
  }, [position1, position2, position3, image0, image1, image2, translate]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {/* SECÇÂO 1********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.lookForPage.position1" />
        </Text>
      </div>
      <div className={classes.avatarArea}>
        <FallbackImage
          className={classes.avatarPreview}
          src={imagePreview0?.[0] || getImageUrl(0)}
          fallbackComponent={<Pediguia className={classes.avatarPreview} />}
        />
        <div className={classes.changeAvatarButton}>
          <Translate id="adminstration.changeSubjectImage" />
          <input
            className={classes.button}
            type="file"
            accept="image/*"
            ref={imageInputRef0}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage0(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <LinkButton
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </LinkButton>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <Tabs type="card">
        {position1.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.lookForPage.titleCard1" />
              </Text>

              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setPosition1(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.lookForPage.description1" />
              </Text>
              <Input
                value={state.description}
                onChange={ev => {
                  ev.persist();
                  setPosition1(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          description: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.description)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>

      {/* SECÇÂO 2********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.lookForPage.position2" />
        </Text>
      </div>
      <div className={classes.avatarArea}>
        <FallbackImage
          className={classes.avatarPreview}
          src={imagePreview1?.[0] || getImageUrl(1)}
          fallbackComponent={<Pediguia className={classes.avatarPreview} />}
        />
        <div className={classes.changeAvatarButton}>
          <Translate id="adminstration.changeSubjectImage" />
          <input
            className={classes.button}
            type="file"
            accept="image/*"
            ref={imageInputRef1}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage1(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <LinkButton
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </LinkButton>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <Tabs type="card">
        {position2.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.lookForPage.titleCard2" />
              </Text>

              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setPosition2(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.lookForPage.description2" />
              </Text>
              <Input
                value={state.description}
                onChange={ev => {
                  ev.persist();
                  setPosition2(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          description: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.description)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>
      {/* SECÇÂO 3********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.lookForPage.position3" />
        </Text>
      </div>

      <div className={classes.avatarArea}>
        <FallbackImage
          className={classes.avatarPreview}
          src={imagePreview2?.[0] || getImageUrl(2)}
          fallbackComponent={<Pediguia className={classes.avatarPreview} />}
        />
        <div className={classes.changeAvatarButton}>
          <Translate id="adminstration.changeSubjectImage" />
          <input
            className={classes.button}
            type="file"
            accept="image/*"
            ref={imageInputRef2}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage2(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <LinkButton
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </LinkButton>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <Tabs type="card">
        {position3.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.lookForPage.titleCard3" />
              </Text>

              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setPosition3(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.lookForPage.description3" />
              </Text>
              <Input
                value={state.description}
                onChange={ev => {
                  ev.persist();
                  setPosition3(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          description: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.description)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>

      <Divider className={classes.divider} />
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </motion.div>
  );
};

export default withLocalize(LookingFor);
