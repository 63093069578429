import { makeStyles } from '@material-ui/styles';
import { message } from 'antd';
import Divider from 'antd/es/divider';
import React, { useCallback, useRef, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../components/App/Button';
import { Text } from '../../components/Typography';
import {
  FACEBOOK_FIELDS,
  FACEBOOK_ID,
  FACEBOOK_SCOPES,
  GOOGLE_CLIENT_ID,
  GOOGLE_SCOPES,
} from '../../consts';
import { ReactComponent as EmailWhite } from '../../shared_assets/icons/email_white.svg';
import { ReactComponent as Facebook } from '../../shared_assets/icons/facebook.svg';
import { ReactComponent as Google } from '../../shared_assets/icons/google.svg';
import { RootState } from '../../store';
import {
  authenticateFacebook,
  authenticateGoogle,
} from '../../store/UserConfiguration/actions';
import { complexTranslate } from '../../utils';
import { RegisterType } from './RegisterModal';

export type RegistrationMethod = 'email' | 'google' | 'facebook';

interface ChooseMethodProps extends LocalizeContextProps {
  onChooseMethod: (method: RegistrationMethod) => void;
  classes: { marginBetween: string };
  signUpType: RegisterType;
  onRequestClose: () => void;
}
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 24,
  },
  dividerBottom: {
    margin: '1rem 0 0.5rem 0',
  },
  formBottom: {
    display: 'flex',
    padding: 24,
    paddingTop: 0,
    justifyContent: 'space-between',
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});
const ChooseMethod: React.FC<ChooseMethodProps> = ({
  onChooseMethod,
  classes,
  signUpType,
  onRequestClose,
  translate,
}) => {
  const innerClasses = useStyles();
  const lastAppZone = useSelector((state: RootState) => state.app.lastAppZone);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSignUp, setIsSignUp] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [thereWasASignUpError, setThereWasASignUpError] =
    useState<boolean>(false);

  const googleOnClick = useRef<() => void>(() => {});
  const fbOnClick = useRef<() => void>(() => {});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastClicked, setLastClicked] = useState<'fb' | 'google'>('fb');

  const loginWithFacebook = useCallback(
    async response => {
      try {
        setIsSignUp(true);
        await authenticateFacebook(signUpType)(response);
        setThereWasASignUpError(false);

        onRequestClose();
      } catch (err) {
        setThereWasASignUpError(true);
      } finally {
        setIsSignUp(false);
      }
    },
    [signUpType, onRequestClose]
  );
  const loginWithGoogle = useCallback(
    async response => {
      try {
        setIsSignUp(true);
        await authenticateGoogle(signUpType)(response);
        setThereWasASignUpError(false);

        onRequestClose();
      } catch (err) {
        setThereWasASignUpError(true);
      } finally {
        setIsSignUp(false);
      }
    },
    [signUpType, onRequestClose]
  );
  return (
    <>
      <Button
        size="large"
        type="primary"
        className={classes.marginBetween}
        prefix={<EmailWhite />}
        onClick={() => {
          onChooseMethod('email');
        }}
      >
        <Translate id="register.signUpEmailBtn" />
      </Button>

      <Divider className={classes.marginBetween}>
        <Translate id="register.or" />
      </Divider>
      <FacebookLogin
        appId={FACEBOOK_ID}
        fields={FACEBOOK_FIELDS}
        callback={loginWithFacebook}
        scope={FACEBOOK_SCOPES}
        render={(props: any) => {
          fbOnClick.current = props.onClick as () => void;
          // RUSH FIX 2 onClick={hasAgreedToTerms ? props.onClick : openTOSModal('fb')}
          return (
            <Button
              {...props}
              onClick={props.onClick}
              type="ghost"
              size="large"
              prefix={<Facebook />}
            >
              <Translate id="register.signUpFaceBtn" />
            </Button>
          );
        }}
      />

      <GoogleLogin
        render={props => {
          googleOnClick.current = props.onClick as () => void;

          return (
            <Button
              {...props}
              prefix={<Google />}
              onClick={props.onClick}
              className={classes.marginBetween}
              size="large"
              type="ghost"
            >
              <Translate id="register.signUpGoogleBtn" />
            </Button>
          );
        }}
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Login"
        onSuccess={loginWithGoogle}
        onFailure={err => {
          if (
            err.response.data.message === 'User must be at least 18 years old'
          ) {
            message.error(translate('register.underAge'));
          } else {
            console.error(err);
          }
        }}
        cookiePolicy="single_host_origin"
        scope={GOOGLE_SCOPES}
      />
      <Text>
        {complexTranslate(
          translate('register.serviceTermsInfoModal').toString(),
          {
            '{TOS}': (
              <Link to={`/terms-of-service/#${lastAppZone}`} target="_blank">
                <Button
                  key={'1'}
                  type="link"
                  className={innerClasses.inlineBtn}
                  onlyText
                >
                  <Translate id="register.serviceTerms" />
                </Button>
              </Link>
            ),
            '{PP}': (
              <Link to="/terms-of-service#privacy" target="_blank">
                <Button
                  key={'2'}
                  type="link"
                  onlyText
                  className={innerClasses.inlineBtn}
                >
                  <Translate id="register.privacyPolicy" />
                </Button>
              </Link>
            ),
            '{TSP}': (
              <Link to="/terms-of-service#market" target="_blank">
                <Button
                  key={'3'}
                  type="link"
                  onlyText
                  className={innerClasses.inlineBtn}
                >
                  <Translate id="register.paymentServiceTerms" />
                </Button>
              </Link>
            ),
          }
        )}
      </Text>
    </>
  );
};
export default withLocalize(ChooseMethod);
