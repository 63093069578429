import Axios from 'axios';
import { API_URL } from '../../consts';

interface LookForInterface {
  title: string;
  description: string;
  language: string;
  position: number;
}

export interface LookForDto {
  lookFors: LookForInterface[];
}
export const postLookFor = (
  dto: LookForDto,
  image1: File | null,
  image2: File | null,
  image3: File | null
) => {
  const formData = new FormData();
  formData.append(
    'lookForDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (image1) {
    formData.append('image-0', image1);
  }
  if (image2) {
    formData.append('image-1', image2);
  }
  if (image3) {
    formData.append('image-2', image3);
  }
  return Axios({
    method: 'post',
    url: `${API_URL}/content-management/guide-landing/look-for`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
