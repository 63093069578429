import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../../consts';
import { RootState } from '../../store';
import { setReachedBottom } from '../../store/App/actions';
import { GenericNotification } from '../../types/notification.interface';

export enum NotificationOwnerType {
  GUIDE = 'GUIDE',
  VISITOR = 'VISITOR',
}

type Hook = (
  info: {
    id: string | undefined;
    type: NotificationOwnerType;
    size?: number;
    infinite?: boolean;
  },
  setNotifications: (nots: SetStateAction<GenericNotification[]>) => void
) => [boolean, boolean];

interface NotificationDto {
  profileId: string;
  size: number;
  page: number;
  profileType: NotificationOwnerType;
}

const fetchNotifications = (dto: NotificationDto) =>
  Axios.get<GenericNotification[]>(`${API_URL}/notification`, {
    params: dto,
  });

export const useNotifications: Hook = (
  { id, type, size, infinite },
  setNotifications
) => {
  const reachedBottom = useSelector(
    (state: RootState) => state.app.reachedBottom
  );

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch();
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);

  useEffect(() => {
    setNotifications([]);
    setPage(1);
  }, [size, type, id, setNotifications]);

  useEffect(() => {
    if (reachedBottom && !isLoading && infinite) {
      setPage(prevPage => prevPage + 1);
      dispatch(setReachedBottom(false));
    }
  }, [reachedBottom, isLoading, dispatch, infinite]);

  useEffect(() => {
    if (reachedEnd) return;

    const runFetch = async () => {
      setIsLoading(true);
      if (!id) {
        setIsError(false);
        setNotifications([]);
        setIsLoading(false);
        return;
      }

      try {
        if (!id) return;

        const dto = {
          page,
          profileId: id,
          profileType: type,
          size: size || 3,
        };

        const { data } = await fetchNotifications(dto);
        if (data.length < (size || 3)) {
          setReachedEnd(true);
        }
        setNotifications(prevNotifications => {
          return [...prevNotifications, ...data];
        });
        setIsError(false);
      } catch (err) {
        setNotifications([]);
        setIsError(true);
      }
      setIsLoading(false);
    };

    runFetch();
  }, [page, size, id, reachedEnd, type, setNotifications]);

  return [isLoading, isError];
};

type FixedHook = (
  info: {
    id: string | undefined;
    type: NotificationOwnerType;
    size?: number;
  },
  setNotifications: (nots: SetStateAction<GenericNotification[]>) => void
) => [boolean, boolean];

export const useFixedNotifications: FixedHook = (
  { id, type, size },
  setNotifications
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const runFetch = async () => {
      setIsLoading(true);
      if (!id) {
        setIsError(false);
        setNotifications([]);
        setIsLoading(false);
        return;
      }

      try {
        if (!id) return;

        const dto = {
          page: 1,
          profileId: id,
          profileType: type,
          size: size || 3,
        };

        const { data } = await fetchNotifications(dto);

        setNotifications(data);
        setIsError(false);
      } catch (err) {
        setNotifications([]);
        setIsError(true);
      }
      setIsLoading(false);
    };

    runFetch();
  }, [size, id, type, setNotifications]);

  return [isLoading, isError];
};
