import { makeStyles } from '@material-ui/styles';
import DatePicker from 'antd/es/date-picker';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import Popover from 'antd/es/popover';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Button } from '../../../../components/App/Button';
import { Modal } from '../../../../components/App/Modal';
import { Option, Select } from '../../../../components/App/Select';
import { Heading, Text } from '../../../../components/Typography';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { getLocationPhotoUrl } from '../../../../hooks/routes/location/useLocationPhoto';
import { getRouteLocationImageUrl } from '../../../../hooks/routes/UseRoute';
import { createRouteSchedule } from '../../../../hooks/schedule/createRouteSchedule';
import { editScheduleRepetition } from '../../../../hooks/schedule/editSchedule';
import { ReactComponent as CalendarIcon } from '../../../../shared_assets/icons/calendar.svg';
import { ReactComponent as Time } from '../../../../shared_assets/icons/time.svg';
import { ErrorCode } from '../../../../types/error.codes.enum';
import { Route } from '../../../../types/route.interface';
import { Schedule } from '../../../../types/schedule-new';
import { ScheduleRepetition } from '../../../../types/schedule-repetition';
import { ScheduleType } from '../../../../types/schedule-type.enum';
import { getTheme, headerHeight } from '../../../../utils';

const useStyles = makeStyles({
  routePresentation: ({ routeImage }: { routeImage: string }) => ({
    backgroundImage: `url('${routeImage}')`,
    backgroundSize: 'cover',
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '1rem',
    position: 'relative',
    marginBottom: '1rem',
    '& .ant-popover-inner-content': {
      padding: '0.5rem',
    },
    marginTop: '1rem',
    borderRadius: 10,
  }),
  moreButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    padding: '2px 4px',
    height: 'unset',
  },
  choosePopover: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '50vh',
    maxWidth: '20vw',
    padding: '10px',
  },
  dividerPadding: {
    margin: '0px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  routeChoice: {
    display: 'flex',
    height: 48,
    maxHeight: 48,
    alignItems: 'center',
    margin: '0.5rem 0',
    '& > *': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  routeImage: {
    width: 32,
    height: 32,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: '1rem',
    display: 'flex',
    borderRadius: 5,
  },
  routeInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1rem',
      '&:last-child': {
        marginRight: 0,
      },
      '& > *:first-child': {
        marginRight: '0.5rem',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: headerHeight,
    alignItems: 'center',
    padding: '0 24px',
    flexShrink: 0,
  },
  datepicker: {
    '& input': {
      fontSize: '14px !important',
    },
    borderColor: getTheme().neutral4 + ' !important',
    borderWidth: 2,
    borderRadius: 5,
  },
  textIcon: {
    height: '1.2rem',
    width: '1.2rem',
  },
});

interface ScheduleModalProps extends LocalizeContextProps {
  guideId?: string;
  selectedRouteId: string;
  setSelectedRouteId: (v: SetStateAction<string | undefined>) => void;
  visible: boolean;
  onRequestClose: () => void;
  onDoAction: (newRepetition: ScheduleRepetition | Schedule) => void;
  routes: Route[];
  selectedDate: Moment;
  repetition?: ScheduleRepetition;
}

type BookingTimes = 10 | 30 | 60 | 180 | 360 | 1440 | 2880;
//TODO: compor titles[0].title
function ScheduleModal({
  guideId,
  visible,
  onRequestClose,
  routes,
  selectedRouteId,

  setSelectedRouteId,
  selectedDate,
  translate,
  repetition,
  onDoAction,
}: ScheduleModalProps) {
  const [jwt] = UseJWT();
  const [selectedRouteImage, setSelectedRouteImage] = useState<
    string | undefined
  >(undefined);
  const [selectTimeOptions, setSelectTimeOptions] = useState<any[]>([]);
  const [repetitionType, setRepetitionType] = useState<ScheduleType>(
    ScheduleType.NONE
  );
  const localHour = moment(repetition?.date).format('HH:mm').toString();
  const [dontAllowBookingsTime, setDontAllowBookingsTime] =
    useState<BookingTimes>(10);
  const [hours, setHours] = useState<string>(() => {
    const start = moment();
    const remainder = 30 - (start.minute() % 30);
    return moment(start).add(remainder, 'minutes').format('HH:mm');
  });

  const currentRoute = useMemo(
    () => routes.find(route => route.id === selectedRouteId),
    [routes, selectedRouteId]
  );
  const [giveAway, setGiveAway] = useState(false);
  const [referenceDate, setReferenceDate] = useState<Moment>(selectedDate);
  useEffect(() => {
    setReferenceDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (!repetition) return;

    setHours(moment(repetition.date).format('HH:mm').toString());
    setDontAllowBookingsTime(repetition.reserveLimit as BookingTimes);
    if (repetition.adultPrice === 0) {
      setGiveAway(true);
    }
  }, [repetition]);

  useMemo(() => {
    let hour = 0;
    let id = 1;
    const options = [];
    while (hour < 24) {
      const minutes = id % 2 === 0 ? 30 : 0;
      const option = (
        <Option
          value={moment({
            hour: hour,
            minutes: minutes,
          }).format('HH:mm')}
          key={`time_${id}`}
          disabled={
            referenceDate.isSame(moment(), 'day') &&
            localHour >
              moment({
                hour: hour,
                minutes: minutes,
              }).format('HH:mm')
          }
        >
          {moment({
            hour: hour,
            minutes: minutes,
          }).format('HH:mm')}
        </Option>
      );
      options.push(option);
      if (id % 2 === 0) {
        hour = hour + 1;
      }
      id = id + 1;
    }
    setSelectTimeOptions(options);
  }, [localHour, referenceDate]); //DO NOT ADD DEPENDENCIES
  useEffect(() => {
    const photoId = currentRoute?.locations
      .map(location => location.photos.map(photo => photo.id))
      .flat()[0];

    setSelectedRouteImage(photoId && getRouteLocationImageUrl(photoId));
  }, [currentRoute]);

  const classes = useStyles({
    routeImage:
      selectedRouteImage ||
      require('../../../../shared_assets/images/route-default.png'),
  });

  const createSchedule = useCallback(async () => {
    if (!jwt) return;

    try {
      const response = await createRouteSchedule({
        guideId: guideId ? guideId : jwt.guideId,
        repetitionType: repetitionType,
        reserveLimit: dontAllowBookingsTime,
        routeId: selectedRouteId,
        start: moment(referenceDate)
          .add(hours.split(':')[0], 'hours')
          .add(hours.split(':')[1], 'minutes')
          .toISOString(),
      });
      onDoAction(response.data);
      message.success(translate(`schedules.scheduleSuccess`), 10);
    } catch (err) {
      if (err.response.code === 404) {
        message.error(translate(ErrorCode.BAD_SCHEDULE_ID), 10);
      }

      if (
        err?.response?.data?.error &&
        typeof err.response.data.error === 'string'
      ) {
        message.error(
          translate(`error.API_ERROR.${err.response.data.error}`).toString(),
          10
        );
      }
    }
  }, [
    jwt,
    referenceDate,
    hours,
    guideId,
    repetitionType,
    dontAllowBookingsTime,
    selectedRouteId,
    onDoAction,
    translate,
  ]);

  const otherRoutes = useMemo(
    () => routes.filter(r => r.id !== selectedRouteId),
    [routes, selectedRouteId]
  );

  const editSchedule = useCallback(async () => {
    if (!repetition) return;

    try {
      const response = await editScheduleRepetition(repetition.id, {
        newDate: moment(referenceDate)
          .add(parseInt(hours.split(':')[0]), 'hours')
          .add(parseInt(hours.split(':')[1]), 'minutes')
          .utc()
          .toDate(),
        newReserveLimit: dontAllowBookingsTime,
        giveAway,
      });

      onDoAction(response.data);
    } catch (err) {
      if (err.response.code === 404) {
        message.error(translate(ErrorCode.BAD_SCHEDULE_ID));
      }

      if (
        err?.response?.data?.error &&
        typeof err.response.data.error === 'string'
      ) {
        message.error(
          translate(`error.API_ERROR.${err.response.data.error}`).toString()
        );
      }
    }
  }, [
    referenceDate,
    hours,
    dontAllowBookingsTime,
    onDoAction,
    translate,
    repetition,
    giveAway,
  ]);
  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      footer={
        <div className={classes.footer}>
          <Button onClick={onRequestClose}>
            <Translate id="schedules.cancel" />
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (repetition) editSchedule();
              else createSchedule();
            }}
          >
            <Translate id="schedules.doSchedule" />
          </Button>
        </div>
      }
      variant="close"
      headerTitle={<Translate id="schedules.scheduleTitle" />}
    >
      <div className={classes.routePresentation}>
        <Text color="white" size={12} weight="bold">
          {currentRoute?.location}
        </Text>
        <Heading color="white" level={4}>
          {currentRoute?.titles[0] ? currentRoute.titles[0].title : ''}
        </Heading>
        {otherRoutes.filter(e => e.state !== 'DUPLICATED').length > 0 && (
          <Popover
            trigger="click"
            placement="bottomRight"
            content={
              <div className={classes.choosePopover}>
                {otherRoutes
                  .filter(e => e.state !== 'DUPLICATED')
                  .map((route, index) => {
                    const photoToDisplay = route.locations
                      .map(loc => loc.photos.map(p => p.id))
                      .flat()[0];

                    return (
                      <>
                        <div
                          key={route.id}
                          className={classes.routeChoice}
                          onClick={() => {
                            setSelectedRouteId(route.id);
                          }}
                        >
                          <div
                            className={classes.routeImage}
                            style={{
                              backgroundImage: `url('${
                                (photoToDisplay &&
                                  getLocationPhotoUrl(photoToDisplay)) ||
                                require('../../../../shared_assets/images/route-default.png')
                              }')`,
                            }}
                          />
                          <div className={classes.routeInfo}>
                            <Text noMargin>
                              {route.titles[0] ? route.titles[0].title : ''}
                            </Text>
                            <Text noMargin variant="note">
                              {route.location}
                            </Text>
                          </div>
                        </div>
                        {otherRoutes[index + 1] ? (
                          <Divider className={classes.dividerPadding}></Divider>
                        ) : null}
                      </>
                    );
                  })}
              </div>
            }
          >
            <Button className={classes.moreButton}>
              <Text noMargin size={12} color={getTheme().primary}>
                <Translate id="schedules.chooseAnotherRoute" />
              </Text>
            </Button>
          </Popover>
        )}
      </div>
      <Text weight="semibold">
        <Translate id="schedules.starts" />
      </Text>
      <DatePicker
        suffixIcon={<CalendarIcon />}
        className={classNames(classes.marginBottom, classes.datepicker)}
        size="large"
        value={referenceDate}
        onChange={ev => ev && setReferenceDate(ev)}
        format="L"
        disabledDate={currentDate =>
          currentDate.isBefore(moment().startOf('day'))
        }
        inputReadOnly={!repetition}
      />
      <Select
        className={classes.marginBottom}
        value={hours}
        onChange={e => {
          setHours(e.toString());
        }}
        huge
        key={'select0'}
      >
        {selectTimeOptions}
      </Select>

      <Text weight="semibold">
        <Translate id="schedules.ends" />
      </Text>
      <div className={classes.row}>
        <Text inline>
          <Time className={classes.textIcon} />
          {moment(referenceDate)
            .add({
              hour: parseInt(hours.split(':')[0]),
              minutes: parseInt(hours.split(':')[1]),
            })
            .add({
              minutes: currentRoute?.duration,
            })
            .format('HH:mm')}
        </Text>
        <Text inline>
          <CalendarIcon className={classes.textIcon} />

          {moment(referenceDate)
            .add({
              hour: parseInt(hours.split(':')[0]),
              minutes: parseInt(hours.split(':')[1]),
            })
            .add({
              minutes: currentRoute?.duration,
            })
            .format('LL')}
        </Text>
      </div>
      <Text variant="note">
        <Translate id="schedules.estimatedTimeWarning" />
      </Text>
      {!repetition && (
        <>
          <Text weight="semibold">
            <Translate id="schedules.repeatLabel" />
          </Text>
          <Select
            className={classes.marginBottom}
            huge
            value={repetitionType}
            onChange={e => setRepetitionType(e.toString() as ScheduleType)}
            key={'select1'}
          >
            {Object.keys(ScheduleType).map(type => (
              <Option
                label={translate(`scheduleRepetitionType.${type}`).toString()}
                key={`sch_type_${type}`}
                value={type}
              >
                <Translate id={`scheduleRepetitionType.${type}`} />
              </Option>
            ))}
          </Select>
        </>
      )}
      <Text weight="semibold">
        <Translate id="schedules.dontAllowBookings" />
      </Text>
      <Select
        className={classes.marginBottom}
        huge
        value={dontAllowBookingsTime}
        onChange={ev =>
          setDontAllowBookingsTime(parseInt(ev.toString()) as BookingTimes)
        }
        key={'select2'}
      >
        {[10, 30, 60, 180, 360, 1440, 2880].map(minutes => (
          <Option key={minutes} value={minutes}>
            {moment.duration({ minutes: minutes }).humanize()}
          </Option>
        ))}
      </Select>
    </Modal>
  );
}

export default withLocalize(React.memo(ScheduleModal));
