import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ChangePassword from './ChangePassword';
import ProfilePersonal from './ProfilePersonal';
import ProfilePersonalEdit from './ProfilePersonalEdit';
import PublicProfileEdit from './PublicProfileEdit';
import PublicProfilePreview from './PublicProfilePreview';

export default function Profile() {
  return (
    <Switch>
      <Route path="/guide/profile/public/edit" component={PublicProfileEdit} />
      <Route
        path="/guide/profile/personal/edit"
        component={ProfilePersonalEdit}
      />
      <Route
        path="/guide/profile/personal/change-password"
        component={ChangePassword}
      />
      <Route path="/guide/profile/personal" component={ProfilePersonal} />
      <Route
        path="/guide/profile/public"
        exact
        component={PublicProfilePreview}
      />
      <Redirect to="/guide/profile/public" />
    </Switch>
  );
}
