import Axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../../consts';

export interface NifRegisteredDto {
  nif: string;
}
const fetchNifExist = (dto: NifRegisteredDto) =>
  Axios.post<boolean>(`${API_URL}/administrator/nif-registered`, dto);

export const useNifExist = (dto: NifRegisteredDto) => {
  const [isRegistered, setExist] = useState<boolean | 'loading'>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setExist('loading');
        const { data: registered } = await fetchNifExist(dto);
        setExist(registered);
      } catch (err) {
        setExist('loading');
      }
    };
    fetchData();
  }, [dto]);

  return isRegistered;
};
