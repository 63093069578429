import { makeStyles } from '@material-ui/styles';
import Rate from 'antd/es/rate';
import moment from 'moment';
import React from 'react';

import { getVisitorImageUrl } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as Visitante } from '../../../shared_assets/images/visitante_default.svg';
import { Text } from '../../Typography';
import { FallbackAvatar } from '../Avatar';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  comment: {
    display: 'flex',
    flexDirection: 'column',
  },
  commentHeader: {
    display: 'flex',
    marginBottom: '1rem',
  },
  headerRightSide: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  visitorAvatar: {
    height: '5rem',
    width: '5rem',
    marginRight: '1rem',
  },
  rate: {
    fontSize: 12,
  },
});

interface VisitorCommentProps {
  visitorId: string;
  visitorName: string;
  routeName?: string;
  rating: number;
  commentText: string;
  date?: Date;
}

function VisitorComment({
  visitorId,
  visitorName,
  routeName,
  rating,
  commentText,
  date,
}: VisitorCommentProps) {
  const classes = useStyles();

  return (
    <div className={classes.comment}>
      <div className={classes.commentHeader}>
        <FallbackAvatar
          className={classes.visitorAvatar}
          src={
            <FallbackImage
              src={getVisitorImageUrl(visitorId)}
              fallbackComponent={<Visitante />}
            />
          }
        />
        <div className={classes.headerRightSide}>
          <Text noMargin={!!routeName}>{visitorName}</Text>
          {routeName && (
            <Text variant="faded" size={12} noMargin>
              {routeName}
            </Text>
          )}
          <Text noMargin variant="faded" size={12}>
            {moment(date).format('D MMM YYYY')}
          </Text>
          <Rate className={classes.rate} value={rating} disabled />
        </div>
      </div>
      <Text size={12} noMargin ellipsis={{ rows: 5 }}>
        {commentText}
      </Text>
    </div>
  );
}

export default React.memo(VisitorComment);
