import { makeStyles } from '@material-ui/styles';
import Input from 'antd/es/input';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import validator from 'validator';
import { getLocationPhotoUrl } from '../../../hooks/routes/location/useLocationPhoto';
import { useCurrentSize } from '../../../hooks/useCurrentSize';
import { useFilePreview } from '../../../hooks/useFilePreview';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { ReactComponent as Delete } from '../../../shared_assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../../shared_assets/icons/edit.svg';
import { ReactComponent as PlusBlue } from '../../../shared_assets/icons/plus_blue.svg';
import { RouteLocation } from '../../../types/route-location.interface';
import { getTheme, measureElement } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { Button } from '../Button';
import { FallbackImage } from '../Image';
import LocationModal, { Location } from './LocationModal';
import PlaceSearchBox, { ReturnedPlace } from './PlaceSearchBox';
import PointStepper from './PointStepper';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  startPoint: {
    display: 'flex',
    flexDirection: 'column',
    '& > button': {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
    },
  },
  middlePoint: () => ({
    display: 'flex',
    '& > div:first-child': {
      minWidth: 'calc(1rem + 1px)',
      height: 'calc(1rem + 1px)',
      borderRadius: 'calc(1rem + 1px)',
      border: `1px ${getTheme().primary} solid`,
      margin: '0 0.25rem',
      backgroundColor: 'white',
      position: 'relative',
      marginTop: '0.15rem',
    },
    '& > div:last-child': {
      marginLeft: '0.5rem',
    },
  }),
  point: {
    paddingLeft: '2rem',
  },
  insertedPoint: () => ({
    border: `1px ${getTheme().neutral4} solid`,
    marginLeft: '0.75rem',
    padding: '0.5rem',
    paddingLeft: '1.5rem',
    margin: '1rem 0',
    borderRadius: 5,
    display: 'flex',
    flex: 0,
    '& > *:first-child': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'flex-start',

      '& > button': {
        flex: 0,
      },
    },
    '& > *:last-child': {
      flex: 0,
      maxHeight: '100px',
      maxWidth: '25%',
      objectFit: 'cover',
      borderRadius: '5px',
    },
  }),
  addMiddlePoint: {
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center',
    '& > div:first-child': {
      minWidth: 'calc(1rem + 1px)',
      height: 'calc(1rem + 1px)',
      maxHeight: 'calc(1rem + 1px)',
      borderRadius: 'calc(1rem + 1px)',
      border: `1px ${getTheme().primary} solid`,
      margin: '0 0.25rem',
      backgroundColor: 'white',
      position: 'relative',
    },
    '& > *:last-child': {
      marginLeft: '0.5rem',
    },
  },
  linkIcon: {
    marginRight: '1rem',
  },
  button: {
    marginBottom: '1rem',
  },
  inline: {
    display: 'inline-flex',
    '& :last-child': {
      marginLeft: '0.5rem',
    },
  },
});

interface PointsFormProps {
  location: string;
  setLocation: (v: SetStateAction<string>) => void;
  pointLocations: Location[];
  setPointLocations: (v: SetStateAction<Location[]>) => void;
  availableLanguages: string[];
  setRouteLongitude: (v: number) => void;
  setRouteLatitude: (v: number) => void;
  setRouteLength: (v: number) => void;
  routeLength: number;
}

function PointsForm({
  pointLocations,
  availableLanguages,
  setPointLocations,
  location,
  setLocation,
  setRouteLatitude,
  setRouteLongitude,
  setRouteLength,
  routeLength,
}: PointsFormProps) {
  const classes = useStyles();

  const { width } = useCurrentSize();
  const [bottomHeight, setBottomHeight] = useState(0);
  const [mainLocationLat, setMainLocationLat] = useState<number>(0);
  const [mainLocationLong, setMainLocationLong] = useState<number>(0);
  const refs = useRef<HTMLDivElement[]>([]); // or an {}
  // Make it empty at every render cycle as we will get the full list of it at the end of the render cycle
  refs.current = []; // or an {}

  // since it is an array we need to method to add the refs
  const addToRefs = (el: HTMLDivElement) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  useEffect(() => {
    setBottomHeight(
      refs.current.reduce((acc, ref) => {
        return acc + measureElement(ref).height;
      }, 0)
    );
  }, [width, refs, pointLocations]);

  const [openModal, setOpenModal] = useState<number | undefined | null>(null);

  const closeModal = useCallback(() => {
    setOpenModal(undefined);
  }, []);

  const [filesForPreview, setFilesForPreview] = useState<(File | null)[]>([]);

  const previews = useFilePreview(filesForPreview);

  useEffect(() => {
    setFilesForPreview(
      pointLocations.map(p =>
        p.photos[0] instanceof File ? p.photos[0] : null
      )
    );
  }, [pointLocations]);

  const start = pointLocations.find(pL => pL.type === 'start');
  const hasMiddle = pointLocations.some(pL => pL.type === 'middle');
  const end = pointLocations.find(pL => pL.type === 'end');

  const addPoint = (location: Location) => {
    let start = pointLocations.find(pL => pL.type === 'start');
    let end = pointLocations.find(pL => pL.type === 'end');
    let middles = pointLocations.filter(pL => pL.type === 'middle');

    if (location.type === 'start') {
      if (start) console.warn('A start location already exists, replacing it!');
      start = location;
    } else if (location.type === 'end') {
      if (end) console.warn('An end location already exists, replacing it!');
      end = location;
    } else if (location.type === 'middle') middles.push(location);

    const properLocations: Location[] = [];

    if (start) properLocations.push(start);

    properLocations.push(...middles);
    if (end) properLocations.push(end);

    setPointLocations(properLocations);
  };

  const setRouteAddress = useCallback(
    (place: ReturnedPlace | undefined) => {
      if (!place || !place.geometry) return;

      setRouteLatitude(place.geometry.location.lat());
      setRouteLongitude(place.geometry.location.lng());
      setMainLocationLat(place.geometry.location.lat());
      setMainLocationLong(place.geometry.location.lng());
      setLocation(place.formatted_address);
      setInputText(place.formatted_address);
    },
    [setLocation, setRouteLatitude, setRouteLongitude]
  );

  const [inputText, setInputText] = useState<string>('');
  const [length, setLength] = useState<number>(0);
  useEffect(() => {
    setLength(routeLength);
  }, [routeLength]);
  return (
    <>
      <motion.div
        className={classes.mainContainer}
        initial="exit"
        animate="enter"
        exit="exit"
        variants={GenericPageTransition}
      >
        <Heading disableAutoscale level={2}>
          <Translate id="routes.locationTitle" />
        </Heading>
        <Heading level={5}>
          <Translate id="routes.locationSubtitle" />
        </Heading>
        <PlaceSearchBox
          value={inputText}
          onChange={setInputText}
          onPlaceSelected={setRouteAddress}
        />
        <AnimatedError
          isVisible={validator.isEmpty(location)}
          reason={<Translate id="routes.badRouteLocation" />}
        />
        <Heading level={5}>
          <Translate id="routes.routeLength" />
        </Heading>
        <Input
          type={'number'}
          value={length}
          min={0}
          onChange={ev => {
            setLength(parseFloat(ev.target.value));
            setRouteLength(parseFloat(ev.target.value));
          }}
        ></Input>
        <AnimatedError
          isVisible={length === 0}
          reason={<Translate id="routes.badRouteLength" />}
        />
        <PointStepper lastPointOffset={bottomHeight}>
          <div className={classNames(classes.startPoint, classes.point)}>
            <Heading level={5}>
              <Translate id="routes.startingPointTitle" />
            </Heading>
            <Text variant="faded">
              <Translate id="routes.startingPointSubtitle" />
            </Text>
            {!start && (
              <>
                <Button
                  type="link"
                  size="large"
                  onClick={() => {
                    setOpenModal(-1);
                  }}
                  onlyText
                  prefix={<PlusBlue className={classes.linkIcon} />}
                >
                  <Translate id="routes.addStart" />
                </Button>
                <LocationModal
                  onCommitLocationChange={addPoint}
                  selectedLanguages={availableLanguages}
                  type="start"
                  isVisible={openModal === -1}
                  onRequestClose={closeModal}
                  mainLocationLat={mainLocationLat}
                  mainLocationLong={mainLocationLong}
                />
              </>
            )}
          </div>
          {start && (
            <>
              <div className={classNames(classes.insertedPoint, classes.point)}>
                <div>
                  <Text noMargin>{start.address}</Text>
                  <Button
                    onClick={() => {
                      setOpenModal(
                        pointLocations.findIndex(pL => pL.type === 'start')
                      );
                    }}
                    onlyText
                    type="link"
                    prefix={<Edit className={classes.linkIcon} />}
                  >
                    <Translate id="routes.edit" />
                  </Button>
                  <AnimatedError
                    isVisible={start.descriptions.some(d =>
                      validator.isEmpty(d.description)
                    )}
                    reason={<Translate id="routes.badPoint" />}
                  />
                </div>
                <FallbackImage
                  fallback={require('../../../shared_assets/images/route-default.png')}
                  src={
                    previews?.[0] ||
                    ((start.photos[0] as RouteLocation | undefined)?.id &&
                      getLocationPhotoUrl(
                        (start.photos[0] as RouteLocation).id
                      )) ||
                    require('../../../shared_assets/images/route-default.png')
                  }
                  alt="logo"
                />
              </div>
              <LocationModal
                onCommitLocationChange={newPoint => {
                  setPointLocations(prevPoints =>
                    prevPoints.map(prevPoint =>
                      prevPoint.type === 'start' ? newPoint : prevPoint
                    )
                  );
                }}
                selectedLanguages={availableLanguages}
                location={pointLocations.find(pL => pL.type === 'start')}
                type="start"
                isVisible={
                  openModal ===
                  pointLocations.findIndex(pL => pL.type === 'start')
                }
                onRequestClose={closeModal}
                mainLocationLat={mainLocationLat}
                mainLocationLong={mainLocationLong}
              />
            </>
          )}
          <div className={classNames(classes.middlePoint)}>
            <div />
            <div>
              <div className={classes.inline}>
                <Heading level={5}>
                  <Translate id="routes.middlePointTitle" />
                </Heading>
                <Heading level={5} color={getTheme().neutral1}>
                  <Translate id="optional" />
                </Heading>
              </div>
              <Text variant="faded">
                <Translate id="routes.middlePointSubtitle" />
              </Text>
              <LocationModal
                //add middle point modal
                onCommitLocationChange={addPoint}
                selectedLanguages={availableLanguages}
                type="middle"
                isVisible={openModal === -2}
                onRequestClose={closeModal}
                mainLocationLat={mainLocationLat}
                mainLocationLong={mainLocationLong}
              />
              {start && !hasMiddle && (
                <>
                  <Button
                    type="link"
                    onlyText
                    className={classes.button}
                    prefix={<PlusBlue className={classes.linkIcon} />}
                    onClick={() => setOpenModal(-2)}
                  >
                    <Translate id="routes.addMiddlePoint" />
                  </Button>
                </>
              )}
            </div>
          </div>
          {pointLocations
            .filter(pL => pL.type === 'middle')
            .map((pL, i) => (
              <div
                className={classNames(classes.insertedPoint, classes.point)}
                key={i.toString()}
              >
                <div>
                  <Text noMargin>{pL.address}</Text>
                  <Button
                    onlyText
                    type="link"
                    prefix={<Edit className={classes.linkIcon} />}
                    onClick={() => {
                      setOpenModal(pointLocations.findIndex(f => f === pL));
                    }}
                  >
                    <Translate id="routes.edit" />
                  </Button>
                  <Button
                    onlyText
                    type="link"
                    prefix={<Delete className={classes.linkIcon} />}
                    onClick={() => {
                      setPointLocations(prevPoints =>
                        prevPoints.filter(p => p !== pL)
                      );
                    }}
                  >
                    <Translate id="routes.remove" />
                  </Button>
                  <AnimatedError
                    isVisible={pL.descriptions.some(d =>
                      validator.isEmpty(d.description)
                    )}
                    reason={<Translate id="routes.badPoint" />}
                  />
                </div>
                <FallbackImage
                  fallback={require('../../../shared_assets/images/route-default.png')}
                  src={
                    previews[pointLocations.findIndex(f => f === pL)] ||
                    ((pL.photos[0] as RouteLocation | undefined)?.id &&
                      getLocationPhotoUrl(
                        (pL.photos[0] as RouteLocation).id
                      )) ||
                    require('../../../shared_assets/images/route-default.png')
                  }
                  alt="logo"
                />
                <LocationModal
                  onCommitLocationChange={newPoint => {
                    setPointLocations(prevPoints =>
                      prevPoints.map((prevPoint, i) =>
                        i === pointLocations.findIndex(p => p === pL)
                          ? newPoint
                          : prevPoint
                      )
                    );
                  }}
                  mainLocationLat={mainLocationLat}
                  mainLocationLong={mainLocationLong}
                  location={pL}
                  selectedLanguages={availableLanguages}
                  type="middle"
                  isVisible={
                    openModal === pointLocations.findIndex(f => f === pL)
                  }
                  onRequestClose={closeModal}
                />
              </div>
            ))}
          {start && hasMiddle && (
            <div className={classes.addMiddlePoint}>
              <div />
              <Button
                type="link"
                onClick={() => setOpenModal(-2)}
                onlyText
                prefix={<PlusBlue className={classes.linkIcon} />}
              >
                <Translate id="routes.addAnotherMiddlePoint" />
              </Button>
            </div>
          )}

          <div
            className={classNames(classes.startPoint, classes.point)}
            ref={addToRefs}
          >
            <Heading level={5}>
              <Translate id="routes.endingPointTitle" />
            </Heading>
            <Text variant="faded">
              <Translate id="routes.endingPointSubtitle" />
            </Text>
            {!end && (
              <>
                <Button
                  type="link"
                  onlyText
                  onClick={() => setOpenModal(-3)}
                  prefix={<PlusBlue className={classes.linkIcon} />}
                >
                  <Translate id="routes.addEnd" />
                </Button>
                <LocationModal
                  onCommitLocationChange={addPoint}
                  selectedLanguages={availableLanguages}
                  type="end"
                  isVisible={openModal === -3}
                  onRequestClose={closeModal}
                  mainLocationLat={mainLocationLat}
                  mainLocationLong={mainLocationLong}
                />
              </>
            )}
          </div>
          {end && (
            <div
              className={classNames(classes.insertedPoint, classes.point)}
              ref={addToRefs}
            >
              <div>
                <Text noMargin>{end.address}</Text>
                <Button
                  onlyText
                  type="link"
                  prefix={<Edit className={classes.linkIcon} />}
                  onClick={() => {
                    setOpenModal(pointLocations.findIndex(p => p === end));
                  }}
                >
                  <Translate id="routes.edit" />
                </Button>
                <LocationModal
                  onCommitLocationChange={newPoint => {
                    setPointLocations(prevPoints =>
                      prevPoints.map(prevPoint =>
                        prevPoint.type === 'end' ? newPoint : prevPoint
                      )
                    );
                  }}
                  selectedLanguages={availableLanguages}
                  type="end"
                  location={end}
                  isVisible={
                    openModal === pointLocations.findIndex(p => p === end)
                  }
                  onRequestClose={closeModal}
                  mainLocationLat={mainLocationLat}
                  mainLocationLong={mainLocationLong}
                />
                <AnimatedError
                  isVisible={end.descriptions.some(d =>
                    validator.isEmpty(d.description)
                  )}
                  reason={<Translate id="routes.badPoint" />}
                />
              </div>
              <FallbackImage
                fallback={require('../../../shared_assets/images/route-default.png')}
                src={
                  previews[pointLocations.findIndex(f => f === end)] ||
                  ((end.photos[0] as RouteLocation | undefined)?.id &&
                    getLocationPhotoUrl((end.photos[0] as RouteLocation).id)) ||
                  require('../../../shared_assets/images/route-default.png')
                }
                alt="logo"
              />
            </div>
          )}
        </PointStepper>
      </motion.div>
    </>
  );
}

export default PointsForm;
