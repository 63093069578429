import { makeStyles } from '@material-ui/styles';
import Rate from 'antd/es/rate';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getGuideImageUrl } from '../../../hooks/guide/UseGuide';
import { submitReview } from '../../../hooks/routes/review/submitReview';
import {
  getRouteLocationImageUrl,
  useRoute,
} from '../../../hooks/routes/UseRoute';
import { ReactComponent as Pediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { RootState } from '../../../store';
import { Route } from '../../../types/route.interface';
import { complexTranslate, getTheme } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { FallbackAvatar } from '../Avatar';
import { Button } from '../Button';
import { Error } from '../Error';
import { FallbackImage } from '../Image';
import { TextArea } from '../Input';
import Loader from '../Loader/Loader';
import { Modal } from '../Modal';
const useStyles = makeStyles({
  imageContainer: ({ imageUrl }: { imageUrl: string }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    ẃidth: '100%',
    height: 150,
    margin: '2.5rem 0',
  }),
  guideAvatar: {
    transform: 'translate(-25%, -50%)',
    height: '5rem',
    width: '5rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
  },
  rate: {
    fontSize: 28,
    marginBottom: '2rem',
  },
  cancelButton: {
    whiteSpace: 'pre-wrap',
  },
  spacedButton: {
    margin: '1rem 0',
    width: '100%',
  },
  spaceButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 200,
  },
});

interface MakeReviewModalProps extends LocalizeContextProps {
  routeId: string;
  bookingId: string;
  visible: boolean;
  onFinish: (reviewId: string) => void;
  onDismiss: () => void;
}

const MakeReviewModal = ({
  routeId,
  bookingId,
  visible,
  translate,
  onFinish,
  onDismiss,
}: MakeReviewModalProps) => {
  const [route, setRoute] = useState<Route | null>(null);
  const [isRouteLoading, routeHasError] = useRoute(routeId, setRoute, true);
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [rating, setRating] = useState<number>(4);
  const [reviewDescription, setReviewDescription] = useState<string>('');
  const routeTitle = useMemo(() => {
    if (!route) return '';
    const translation = route.titles.find(t => t.language === activeLanguage);
    return translation
      ? translation.title
      : route.titles[0]
      ? route.titles[0].title
      : '';
  }, [activeLanguage, route]);
  const routeImage = useMemo(() => {
    const attemptedImage = route?.locations
      .map(l => l.photos.map(p => p.id))
      .flat()[0];

    if (attemptedImage) return getRouteLocationImageUrl(attemptedImage);
    else return require('../../../shared_assets/images/route-default.png');
  }, [route]);

  const [finishing, setFinishing] = useState(false);

  const [performedReviewId, setPerformedReviewId] = useState<string>('');

  const _submitReview = useCallback(async () => {
    const { data } = await submitReview({
      bookingId,
      rating,
      description: reviewDescription,
    });

    setPerformedReviewId(data.id);

    setFinishing(true);
  }, [bookingId, rating, reviewDescription]);

  const finish = useCallback(() => {
    onFinish(performedReviewId);
  }, [onFinish, performedReviewId]);
  const classes = useStyles({ imageUrl: routeImage });
  return (
    <Modal
      visible={visible}
      variant="close"
      onRequestClose={finishing ? finish : onDismiss}
      headerTitle={finishing && <Translate id="reviews.reviewSent" />}
      footer={
        finishing || routeHasError || isRouteLoading ? null : (
          <div className={classes.footer}>
            <Button type="primary" size="large" onClick={_submitReview}>
              <Heading level={5} noMargin color="white">
                <Translate id="reviews.makeReview" />
              </Heading>
            </Button>
            <Button
              type="link"
              className={classes.cancelButton}
              onlyText
              onClick={onDismiss}
            >
              <Text variant="link" noMargin inline>
                <Translate id="reviews.reviewLater" />
              </Text>
            </Button>
          </div>
        )
      }
    >
      {isRouteLoading ? (
        <Loader />
      ) : routeHasError || !route ? (
        <Error />
      ) : finishing ? (
        <>
          <div className={classes.spaceButtonContainer}>
            <Button
              onClick={finish}
              size="large"
              className={classes.spacedButton}
              type="primary"
            >
              <Heading level={5} noMargin color="white">
                <Translate id="button.goBack" />
              </Heading>
            </Button>
            <Link to="/visitor/invoices" onClick={finish}>
              <Button
                type="ghost"
                size="large"
                className={classes.spacedButton}
              >
                <Heading level={5} noMargin color={getTheme().primary}>
                  <Translate id="reviews.seeInvoices" />
                </Heading>
              </Button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className={classes.imageContainer}>
            <FallbackAvatar
              className={classes.guideAvatar}
              size="large"
              src={
                <FallbackImage
                  src={getGuideImageUrl(route.guideId)}
                  fallbackComponent={<Pediguia />}
                />
              }
            />
          </div>
          <Heading level={4}>
            <Translate id="reviews.makeReviewTitle" />
          </Heading>
          <Text>
            {complexTranslate(translate('reviews.makeReviewText').toString(), {
              '{ROUTENAME}': routeTitle,
            })}
          </Text>
          <Rate
            className={classes.rate}
            value={rating}
            allowHalf
            onChange={newVal => setRating(newVal)}
          />
          <TextArea
            rows={5}
            placeholder={translate('reviews.makeReviewPlaceholder').toString()}
            onChange={ev => setReviewDescription(ev.target.value)}
            value={reviewDescription}
          />
        </>
      )}
    </Modal>
  );
};

export default withLocalize(MakeReviewModal);
