import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import {
  setGuideUnseenNotifications,
  setLastGuideState,
} from '../../store/App/actions';
import { setOngoingRoute } from '../../store/UserConfiguration/actions';
import { Guide } from '../../types/guide.interface';

type Hook = (
  id: string | undefined,
  setGuide: (val: SetStateAction<Guide | null>) => void
) => [boolean, boolean];

const requestGuide = (id: string) => Axios.get<Guide>(`${API_URL}/guide/${id}`);

export const useGuide: Hook = (id, setGuide) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!id) {
        setIsError(true);
        setGuide(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: guide } = await requestGuide(id);
        dispatch(setOngoingRoute(guide.inTour));
        dispatch(setGuideUnseenNotifications(guide.unseenNotificationsCount));
        dispatch(setLastGuideState(guide.state));
        setGuide(guide);
        setIsError(false);
      } catch (err) {
        setGuide(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, dispatch, setGuide]);

  return [isLoading, isError];
};

export const getGuideImageUrl = (id: string) =>
  `${API_URL}/guide/${id}/avatar?_=${moment().unix()}`;
