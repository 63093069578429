import Axios from 'axios';

import { API_URL } from '../../../consts';
import { GetVisitorStepsResponse } from './useSteps';

interface VisitorStep {
  language: string;
  title: string;
  description: string;
}

type InsertVisitorStepsDto = { steps: VisitorStep[] };

export const insertVisitorStep = (dto: InsertVisitorStepsDto) =>
  Axios.post<GetVisitorStepsResponse>(
    `${API_URL}/content-management/visitor-landing-page/steps`,
    dto
  );
