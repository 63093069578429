import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect, useHistory } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import validator from 'validator';
import { AnimatedError } from '../../../components/App/AnimatedError';
import { FallbackAvatar } from '../../../components/App/Avatar';
import { BackButton } from '../../../components/App/BackButton';
import { Button } from '../../../components/App/Button';
import { Error } from '../../../components/App/Error';
import { FallbackImage } from '../../../components/App/Image';
import { Input, TextArea } from '../../../components/App/Input';
import { LanguageSelection } from '../../../components/App/LanguageSelection';
import { Loader } from '../../../components/App/Loader';
import { SkillSelection } from '../../../components/App/SkillSelection';
import { TabPane, Tabs } from '../../../components/App/Tabs';
import { Heading, Text } from '../../../components/Typography';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { editGuide } from '../../../hooks/guide/EditGuide';
import { getGuideImageUrl, useGuide } from '../../../hooks/guide/UseGuide';
import { useFilePreview } from '../../../hooks/useFilePreview';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { ReactComponent as User } from '../../../shared_assets/icons/user.svg';
import { ReactComponent as Pediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { GuideDescriptionDto } from '../../../types/guide.dtos';
import { Guide } from '../../../types/guide.interface';
import { makeEnumArray, SkillType } from '../../../types/skill-type.enum';
import {
  castShadow,
  complexTranslate,
  desktopPadding,
  headerHeight,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import { usePublicProfileForm } from './usePublicProfileForm';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },
  noMargin: {
    margin: 0,
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: 16,
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  introIcon: {
    marginRight: '1rem',
    height: '2rem',
  },
  avatarPreview: {
    width: '8rem',
    height: '8rem',
    alignSelf: 'center',
  },
  invisible: {
    display: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    marginTop: '1rem',
    '& > *': {
      marginBottom: '1rem',
    },
  },
  introContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    marginBottom: '2rem !important',
  },
  avatarZone: {
    display: 'flex',
    flexDirection: 'column',
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    backZone: {
      flex: 0.1,
      minWidth: 150,
      '& > *:nth-child(2)': {
        marginTop: '1rem',
      },
    },
    contentContainer: {
      flex: 0.8,
      display: 'flex',
      flexDirection: 'row',
    },
    avatarZone: {
      flex: 0.2,
      boxShadow: castShadow,
      alignItems: 'center',
      padding: '1rem',
      marginRight: '4rem',
      height: '100%',
      borderRadius: 10,
    },
    infoZone: {
      flex: 0.8,
    },
    bodyContent: {
      display: 'flex',
      flexDirection: 'row',
    },
    subColumn: {
      flex: 0.9,
    },
    body: {
      ...desktopPadding,
    },
  },
});

interface PublicProfileEditProps extends LocalizeContextProps {}

function PublicProfileEdit({ translate }: PublicProfileEditProps) {
  const classes = useStyles();
  const [descriptions, setDescriptions] = useState<GuideDescriptionDto[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [guideProfile, setGuideProfile] = useState<Guide | null>(null);
  const [jwt] = UseJWT();
  const [isLoading, hasError] = useGuide(jwt?.guideId, setGuideProfile);
  const history = useHistory();
  const form = usePublicProfileForm(guideProfile);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const previewSource = useMemo(() => [avatar], [avatar]);
  const avatarPreview = useFilePreview(previewSource);

  useEffect(() => {
    if (!guideProfile) return;

    setDescriptions(guideProfile.descriptions || []);
    setSelectedLanguages((guideProfile.languages || []).map(l => l.language));
    setSelectedSkills(
      (guideProfile.skills || [])
        .map(sk => sk.skillType)
        .filter(ss => makeEnumArray(SkillType).includes(ss))
    );
  }, [guideProfile]);

  //Quando mudam linguas cria descrições novas mas mantem as existentes
  useEffect(() => {
    setDescriptions(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, description: '' })),
    ]);
  }, [selectedLanguages]);

  const editPublicProfileCallback = useCallback(async () => {
    if (!jwt) return;

    try {
      if (descriptions.some(d => validator.isEmpty(d.description))) {
        message.error(translate('profile.bioMandatory'));
        return;
      }

      if (
        Object.values(form).find(entry => {
          if (entry.isInvalid) {
            message.error(entry.reason);
            return true;
          }
          return false;
        })
      ) {
        return;
      }

      if (selectedLanguages.length === 0) {
        message.error(translate('onboarding.mandatoryLanguages'));
        return;
      }

      setIsUpdating(true);

      const { data: updatedGuide } = await editGuide(
        jwt.guideId,
        {
          firstName: form.firstName.value,
          lastName: form.lastName.value,
          descriptions,
          languages: selectedLanguages.map(l => ({ language: l, rating: 0 })),
          skills: selectedSkills.map(sk => ({ skillType: sk, rating: 0 })),
          userEdit: true,
        },

        avatar,
        []
      );
      setGuideProfile(updatedGuide);
      setAvatar(null);
      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
      message.error(err);
    } finally {
      setIsUpdating(false);
    }
  }, [
    jwt,
    descriptions,
    form,
    selectedLanguages,
    selectedSkills,
    avatar,
    translate,
  ]);

  const isMobile = useIsMobile();

  if (!jwt) {
    return <Redirect to="/guide" />;
  }

  //GUIDE IS INCOMPLETE
  if (
    !guideProfile ||
    !guideProfile.skills ||
    !guideProfile.languages ||
    isLoading
  ) {
    return <Loader />;
  }

  if (hasError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {isMobile && (
        <>
          <div className={classes.header}>
            <div onClick={history.goBack}>
              <ArrowBack />
            </div>
          </div>
          <Divider className={classes.headerDivider} />
        </>
      )}
      <div className={classNames(classes.body, classes.page)}>
        <Heading className={classes.title} level={2}>
          <Translate id="profile.editPublicProfile" />
        </Heading>
        <div className={classes.bodyContent}>
          {!isMobile && (
            <div className={classes.backZone}>
              <Button
                disabled={isUpdating}
                type="primary"
                onClick={editPublicProfileCallback} /*disabled={saveDisabled}*/
              >
                {isUpdating ? <BeatLoader /> : <Translate id="profile.save" />}
              </Button>
              <BackButton />
            </div>
          )}
          <div className={classes.subColumn}>
            <div className={classes.introContainer}>
              <User className={classes.introIcon} />
              <Text noMargin inline>
                <Translate id="profile.myPublicProfile" />
              </Text>
            </div>
            <div className={classes.contentContainer}>
              <div className={classes.avatarZone}>
                <FallbackAvatar
                  className={classes.avatarPreview}
                  src={
                    <FallbackImage
                      src={avatarPreview?.[0] || getGuideImageUrl(jwt.guideId)}
                      fallback={require('../../../shared_assets/images/pediguia_default.svg')}
                      fallbackComponent={<Pediguia />}
                    />
                  }
                />
                <Button
                  type="link"
                  size="large"
                  disabled={isUpdating}
                  onClick={ev => {
                    avatarInputRef.current?.click();
                  }}
                >
                  <Translate id="profile.changePhoto" />
                </Button>
                <input
                  disabled={isUpdating}
                  type="file"
                  accept="image/*"
                  ref={avatarInputRef}
                  className={classes.invisible}
                  onChange={ev => {
                    if (
                      ev.currentTarget.files &&
                      ev.currentTarget.files.length > 0 &&
                      ev.currentTarget.files[0].size < maxAllowedImageSize
                    ) {
                      setAvatar(ev.currentTarget.files[0]);
                    } else {
                      message.info(
                        complexTranslate(
                          translate('error.imageFileSize').toString(),
                          {
                            '{linkTinyPNG}': (
                              <a
                                key={'tinypnglink'}
                                href="https://tinypng.com/"
                              >
                                <Button
                                  key="1"
                                  type="link"
                                  className={classNames(
                                    classes.noMargin,
                                    classes.inlineBtn
                                  )}
                                  onlyText
                                >
                                  {translate('error.linkTinyPNG').toString()}
                                </Button>
                              </a>
                            ),
                          }
                        )
                      );
                    }
                  }}
                />
              </div>
              <div className={classes.infoZone}>
                <Text weight="semibold">
                  <Translate id="profile.firstName" />
                </Text>
                <Input
                  huge
                  disabled={isUpdating}
                  onChange={ev => {
                    form.firstName.set(ev.currentTarget.value);
                  }}
                  value={form.firstName.value}
                />
                <AnimatedError
                  isVisible={form.firstName.isInvalid}
                  reason={form.firstName.reason}
                />

                <Text weight="semibold">
                  <Translate id="profile.lastName" />
                </Text>
                <Input
                  huge
                  disabled={isUpdating}
                  onChange={ev => {
                    form.lastName.set(ev.currentTarget.value);
                  }}
                  value={form.lastName.value}
                />
                <AnimatedError
                  isVisible={form.lastName.isInvalid}
                  reason={form.lastName.reason}
                />
                <Text weight="semibold">
                  <Translate id="profile.profileDescription" />
                </Text>
                <Tabs>
                  {descriptions.map(iteratedLanguage => (
                    <TabPane
                      disabled={isUpdating}
                      key={iteratedLanguage.language}
                      tab={translate(`languages.${iteratedLanguage.language}`)}
                    >
                      <TextArea
                        disabled={isUpdating}
                        value={iteratedLanguage.description}
                        rows={10}
                        onChange={ev => {
                          ev.persist();
                          setDescriptions(previousDescriptions =>
                            previousDescriptions.map(oldDescription => {
                              if (
                                oldDescription.language ===
                                iteratedLanguage.language
                              )
                                return {
                                  ...oldDescription,
                                  description: ev.target.value,
                                };
                              else return oldDescription;
                            })
                          );
                        }}
                      />
                      <div>
                        <AnimatedError
                          isVisible={validator.isEmpty(
                            iteratedLanguage.description
                          )}
                          reason={<Translate id="profile.bioMandatory" />}
                        />
                      </div>
                    </TabPane>
                  ))}
                </Tabs>
                <Text weight="semibold">
                  <Translate id="profile.spokenLanguages" />
                </Text>

                <LanguageSelection
                  onChange={setSelectedLanguages}
                  value={selectedLanguages}
                />
                <AnimatedError
                  isVisible={selectedLanguages.length === 0}
                  reason={translate('onboarding.mandatoryLanguages').toString()}
                />
                <h3>
                  <Translate id="profile.skills" />
                </h3>

                <SkillSelection
                  value={selectedSkills}
                  onChange={setSelectedSkills}
                />
                {isMobile && (
                  <div className={classes.buttonContainer}>
                    <Button
                      disabled={isUpdating}
                      type="primary"
                      size="large"
                      onClick={
                        editPublicProfileCallback
                      } /*disabled={saveDisabled}*/
                    >
                      {isUpdating ? (
                        <BeatLoader />
                      ) : (
                        <Translate id="profile.save" />
                      )}
                    </Button>
                    <Button
                      type="link"
                      disabled={isUpdating}
                      onClick={history.goBack}
                    >
                      <Text variant="faded" noMargin>
                        <Translate id="profile.cancel" />
                      </Text>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default withLocalize(PublicProfileEdit);
