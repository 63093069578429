import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../../consts';
import Axios from 'axios';
import { Review } from '../../../types/review.interface';

type Hook = (
  routeId: string | undefined,
  setReviews: (val: SetStateAction<Review[]>) => void
) => [boolean, boolean];

const requestReviews = (routeId: string) =>
  Axios.get(`${API_URL}/review/route/${routeId}`);

export const useRouteReview: Hook = (routeId, setReviews) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!routeId) {
        setIsError(true);
        setReviews([]);
        setIsLoading(false);
        return;
      }

      try {
        const { data: reviews } = await requestReviews(routeId);
        setReviews(reviews as Review[]);
      } catch (err) {
        setReviews([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [routeId, setReviews]);

  return [isLoading, isError];
};
