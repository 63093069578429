import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from '../../components/App/Button';
import { CopyrightFooter } from '../../components/App/CopyrightFooter';
import { InformationPopup } from '../../components/App/InformationPopup';
import { Text } from '../../components/Typography';
import { desktopPadding, headerHeight, mobilePadding, mobileThreshhold } from '../../utils';
import { GenericPageTransition } from '../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    textAlign: 'center',
    padding: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    ...mobilePadding,
    flex: 1,
  },
  btn: {
    placeSelf: 'center',
    padding: '1.2rem 2rem',
  },
  img: {
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    margin: '1rem 0',
    borderRadius: '10px',
  },
  logo: {
    maxHeight: headerHeight,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  smallLogo: {
    maxHeight: 32,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: '50%',
      alignSelf: 'center',
      flex: 1,
      ...desktopPadding,
    },
    btn: {
      padding: '0 1rem',
    },
    img: {
      margin: '2rem 0',
    },
  },
});

interface RegisterProps extends LocalizeContextProps {
  redirectTo: string;
}

function RegistrationSuccess({ translate, redirectTo }: RegisterProps) {
  const history = useHistory();
  const classes = useStyles();
  const guideMatch = useRouteMatch('/guide');

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.container}>
        <InformationPopup
          type="success"
          visible={true}
          message={translate('register.registrationSuccess').toString()}
        />
        <img
          className={classes.img}
          alt="registration_success"
          src={require('../../shared_assets/images/registration_success.png')}
        />

        <Button
          type="primary"
          onClick={() => {
            guideMatch
              ? history.replace('/guide')
              : history.replace('/visitor');
          }}
          className={classes.btn}
        >
          <Text color="white" noMargin>
            <Translate id="register.backToHomePage" />
          </Text>
        </Button>
      </div>
      <div style={{ flex: 1 }}></div>
      <CopyrightFooter />
    </motion.div>
  );
}

export default withLocalize(RegistrationSuccess);
