import { makeStyles } from '@material-ui/styles';
import React, { SetStateAction, useEffect, useMemo } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { CompanyOnboardingInterface } from '../../../hooks/guide/useCompanyOnboarding';
import { OnBoardingForm } from '../../../pages/Guide/Onboarding/useOnboardingForm';
import {
  castShadow,
  desktopPadding,
  getTheme,
  mobilePadding,
  mobileThreshhold,
} from '../../../utils';
import { Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { Option, Select } from '../Select';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    padding: '1rem 0',
  },
  alignToBottom: {
    alignSelf: 'flex-end',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    margin: '0 1rem',
  },
  inputsContainer: {
    marginTop: '1rem',
    textAlign: 'initial',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'flex-end',
    margin: '1rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },
    container: {
      padding: '2rem 2rem 3rem 2rem',
      boxShadow: castShadow,
      borderRadius: '10px',
    },
  },
  select: {
    width: '100%',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
  icon: {
    marginRight: '1em',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  invisible: {
    display: 'none',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
});

interface CompanyFinancialUserProps {
  form: OnBoardingForm;
  setBlockNext: (blockNext: SetStateAction<boolean>) => void;
  companies: CompanyOnboardingInterface[];
}
interface CompanyFinancialUserProps extends LocalizeContextProps {}
const CompanyFinancialUser = ({
  translate,
  form,
  setBlockNext,
  companies,
}: CompanyFinancialUserProps) => {
  const classes = useStyles();

  const companiesOptions = useMemo(() => {
    return companies.map(c => {
      return (
        <Option key={c.id} value={c.id}>
          <Text>
            {c.name} - {c.nif}
          </Text>
        </Option>
      );
    });
  }, [companies]);

  useEffect(() => {
    setBlockNext(form.company.isInvalid);
  }, [form.company.isInvalid, setBlockNext]);

  return (
    <>
      <div className={classes.inputsContainer}>
        <Text weight="bold">
          <Translate id="onboarding.registeredCompanies" />
        </Text>
        {companies.length > 0 && (
          <>
            <Select
              placeholder={translate(
                'onboarding.companySelectPlaceholder'
              ).toString()}
              className={classes.select}
              value={form.company.value === '' ? undefined : form.company.value}
              onChange={ev => {
                const id = ev as string;
                form.company.set(id);
                const companyName = companies.find(c => c.id === id)?.name;
                form.companyName.set(companyName ? companyName : '');
              }}
              huge
              getPopupContainer={() => document.body}
            >
              {companiesOptions}
            </Select>
            <AnimatedError
              isVisible={form.company.isInvalid}
              reason={form.company.reason}
            />
          </>
        )}
      </div>
    </>
  );
};
export default withLocalize(CompanyFinancialUser);
