import {
  GoogleMap as GMAP,
  GoogleMapProps as GMAPProps,
} from '@react-google-maps/api';
import React, { ReactNode } from 'react';
import { PlACE_BOUNDS } from '../../../utils';

interface GoogleMapsProps extends GMAPProps {
  children: React.ReactNode | ReactNode[];
}

const GoogleMap: React.FC<GoogleMapsProps> = ({
  options,
  children,
  clickableIcons,
  ...props
}) => {
  if (!options) options = {};

  options = {
    ...options,
    panControl: true,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    gestureHandling: 'cooperative',
    restriction: {
      latLngBounds: PlACE_BOUNDS,
      strictBounds: false,
    },
  };

  return (
    <GMAP {...props} options={options} clickableIcons={clickableIcons || false}>
      {children}
    </GMAP>
  );
};

export default React.memo(GoogleMap);
