import { useState, useEffect, SetStateAction } from 'react';
import Axios from 'axios';
import { API_URL } from '../../consts';
import { AccessibilityInterface } from '../../types/route.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/index';

export interface AccessibilityWithSingleTranslation {
  id: string;
  name: string;
}

type Hook = (
  setAccessibilities: (
    val: SetStateAction<AccessibilityWithSingleTranslation[]>
  ) => void
) => [boolean, boolean];

const requestAccessibilities = () =>
  Axios.get<AccessibilityInterface[]>(`${API_URL}/accessibility`);

export const useAccessibilities: Hook = setAccessibilities => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: accessibilities } = await requestAccessibilities();
        setAccessibilities(
          accessibilities.map(acc => ({
            id: acc.id,
            name:
              acc.translations?.find(tr => tr.language === activeLanguage)
                ?.translation || 'NO TRANSLATION FOUND',
          }))
        );
      } catch (err) {
        setAccessibilities([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [activeLanguage, setAccessibilities]);

  return [isLoading, isError];
};
