import React from 'react';
import Alert, { AlertProps } from 'antd/es/alert';
import { motion, Variants, AnimatePresence } from 'framer-motion';

const slideVariants: Variants = {
  exit: { height: 0, type: 'spring' },
  enter: { height: 'unset', type: 'spring' },
};

interface AnimatedAlertProps extends AlertProps {
  visible: boolean;
}

export const AnimatedAlert = ({ visible, ...rest }: AnimatedAlertProps) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {visible && (
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={slideVariants}
        >
          <Alert {...rest} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
