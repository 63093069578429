import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Axios from 'axios';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect, useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { FallbackAvatar } from '../../../../../components/App/Avatar';
import { BirthDateSelector } from '../../../../../components/App/BirthDateSelector';
import { Button as LinkButton } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { FallbackImage } from '../../../../../components/App/Image';
import { Input as AppInput } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { Option, Select } from '../../../../../components/App/Select';
import { Heading, Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import {
  AccessibilityWithSingleTranslation,
  useAccessibilities,
} from '../../../../../hooks/accessibilities/useAccessibilities';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { editVisitor } from '../../../../../hooks/visitor/EditVisitor';
import {
  getVisitorImageUrl,
  useVisitor,
} from '../../../../../hooks/visitor/UseVisitor';
import { ReactComponent as Lock } from '../../../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../../../shared_assets/icons/unsee.svg';
import { ReactComponent as Pediguia } from '../../../../../shared_assets/images/pediguia_default.svg';
import { GenderType } from '../../../../../types/gender-type.enum';
import { Nationality } from '../../../../../types/nationality.enum';
import { Visitor } from '../../../../../types/visitor.interface';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  headerHeight,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

// ANCHOR: IMPORTS
interface FormState {
  editing: boolean;
  visitorId: string;
}
interface ErrorInterface {
  error: boolean;
  reason: string;
}
interface AccessibilitiesOptions {
  value: string;
  label: string;
}
const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: '25%',
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  passwordInput: {
    maxWidth: '300px',
    marginRight: '1rem',
  },

  centeredRow: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    flex: '1',
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },
  disableTextColor: {
    color: 'black !important',
  },
  divisorAvatar: {
    paddingBottom: '10px',
  },
  headerArea: {
    display: 'flex',
  },
  buttonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0.5',
  },
  avatarArea: {
    display: 'flex',
    height: '100px',
  },
  avatarPreview: {
    height: '100px',
    width: '100px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  select: {
    minWidth: '300px',
    color: 'black !important',
  },
  spacer: {
    margin: '0 1rem',
  },
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // ANCHOR: CSS
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  invisible: {
    display: 'none',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});
interface ParamsInterface {
  id: string;
}
interface SortedNationalities {
  key: string;
  translation: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
interface InformationProps extends LocalizeContextProps {}
const VisitorInformation = ({ translate }: InformationProps) => {
  const classes = useStyles();

  const [visitorProfile, setVisitorProfile] = useState<Visitor | null>(null);

  const [jwt] = UseJWT();
  const params = useParams<ParamsInterface>();
  const [form, setFormState] = useState<FormState>({
    editing: false,
    visitorId: params ? params.id : '',
  });
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });
  const [isLoading, hasError] = useVisitor(params?.id, setVisitorProfile);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [accessibilities, setAccessibilities] = useState<
    AccessibilityWithSingleTranslation[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [t, te] = useAccessibilities(setAccessibilities);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const previewSource = useMemo(() => [avatar], [avatar]);
  const avatarPreview = useFilePreview(previewSource);
  const [localVisitorFirstName, setLocalVisitorFirstName] = useState('');
  const [localVisitorLastName, setLocalVisitorLastName] = useState('');
  const [localVisitorNationality, setLocalVisitorNationality] = useState('');
  const [localVisitorAddress, setLocalVisitorAddress] = useState('');
  const [localVisitorLocation, setLocalVisitorLocation] = useState('');
  const [localVisitorFirstSection, setLocalVisitorFirstSection] = useState('');
  const [localVisitorLastSection, setLocalVisitorLastSection] = useState('');
  const [localVisitorBirthDate, setLocalBirthDate] = useState<Date>();
  const [localVisitorEmail, setLocalVisitorEmail] = useState('');
  const [localVisitorPhoneNumber, setLocalVisitorPhoneNumber] = useState('');
  const [localVisitorGender, setLocalVisitorGender] = useState('');
  const [localPassword, setLocalPassword] = useState('');
  const [localVisitorNIF, setLocalVisitorNIF] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [localAccessibilities, setLocalAccessibilities] = useState<string[]>(
    []
  );
  const [localVisitorShowOnly, setLocalVisitorShowOnly] =
    useState<boolean>(false);
  const [accessibilitiesOptions, setAccessibilitiesOptions] = useState<
    AccessibilitiesOptions[]
  >([]);

  const [sortedNationalities, setSortedNationalities] = useState<
    SortedNationalities[]
  >([]);

  // ANCHOR: VARIABLES
  useEffect(() => {
    if (!visitorProfile) return;
    setSortedNationalities(
      Object.values(Nationality)
        .map(nat => {
          return {
            key: nat,
            translation: translate(`nationalities.${nat}`).toString(),
          };
        })
        .sort((a, b) => a.translation.localeCompare(b.translation))
    );
    setLocalVisitorFirstName(
      validator.isEmpty(visitorProfile.firstName)
        ? ''
        : visitorProfile.firstName
    );
    setLocalVisitorLastName(
      validator.isEmpty(visitorProfile.lastName) ? '' : visitorProfile.lastName
    );
    if (visitorProfile.nationality) {
      setLocalVisitorNationality(
        validator.isEmpty(visitorProfile.nationality)
          ? ''
          : visitorProfile.nationality
      );
    } else {
      setLocalVisitorNationality('');
    }
    if (visitorProfile.address) {
      setLocalVisitorAddress(
        validator.isEmpty(visitorProfile.address) ? '' : visitorProfile.address
      );
    } else {
      setLocalVisitorAddress('');
    }
    if (visitorProfile.location) {
      setLocalVisitorLocation(
        validator.isEmpty(visitorProfile.location)
          ? ''
          : visitorProfile.location
      );
    } else {
      setLocalVisitorLocation('');
    }
    if (visitorProfile.postalCode) {
      if (validator.isEmpty(visitorProfile.postalCode)) {
        setLocalVisitorFirstSection('');
        setLocalVisitorLastSection('');
      } else {
        setLocalVisitorFirstSection(visitorProfile.postalCode.split('-')[0]);
        setLocalVisitorLastSection(visitorProfile.postalCode.split('-')[1]);
      }
    } else {
      setLocalVisitorFirstSection('');
      setLocalVisitorLastSection('');
    }

    setLocalBirthDate(visitorProfile.dateOfBirth);
    setLocalVisitorEmail(
      validator.isEmpty(visitorProfile.email) ? '' : visitorProfile.email
    );

    if (visitorProfile.phoneNumber) {
      setLocalVisitorPhoneNumber(
        validator.isEmpty(visitorProfile.phoneNumber)
          ? ''
          : visitorProfile.phoneNumber
      );
    } else {
      setLocalVisitorPhoneNumber('');
    }
    if (visitorProfile.gender) {
      setLocalVisitorGender(visitorProfile.gender);
    } else {
      setLocalVisitorGender('');
    }
    setLocalVisitorShowOnly(visitorProfile.showOnly);
    setLocalPassword('');
    if (visitorProfile.phoneNumber) {
      setLocalVisitorPhoneNumber(
        validator.isEmpty(visitorProfile.phoneNumber)
          ? ''
          : visitorProfile.phoneNumber
      );
    } else {
      setLocalVisitorPhoneNumber('');
    }
    if (visitorProfile.gender) {
      setLocalVisitorGender(visitorProfile.gender);
    } else {
      setLocalVisitorGender('');
    }
    if (visitorProfile.nif) {
      setLocalVisitorNIF(
        validator.isEmpty(visitorProfile.nif) ? '' : visitorProfile.nif
      );
    } else {
      setLocalVisitorNIF('');
    }

    setChangePassword(false);
    setAccessibilitiesOptions(
      accessibilities.map(a => {
        return {
          value: a.id,
          label: a.name,
        };
      })
    );
    setLocalAccessibilities(visitorProfile.accessibilities);
    //EVALUATE
  }, [visitorProfile, accessibilities, translate]);
  useMemo(() => {
    const formatStrength =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const hasRightLength =
      localPassword.length >= 8 && localPassword.length <= 20;
    const emailSplit =
      localVisitorEmail.includes('@') && localVisitorEmail.split('@');
    const isStrength = localPassword.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (emailSplit &&
        localPassword.includes(emailSplit[0]) &&
        localVisitorEmail !== '') ||
      localPassword === ''; //TODO: falta fazer validar o email

    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [translate('register.passNoContainNameMail').toString()],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isStrength ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewPassword({
      value: localPassword,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [localPassword, localVisitorEmail, translate]);
  const changeDate = useCallback(newDate => {
    setLocalBirthDate(newDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelEditForm = useCallback(
    (visitorProfile: Visitor) => {
      if (!visitorProfile) return;
      setLocalVisitorFirstName(
        validator.isEmpty(visitorProfile.firstName)
          ? ''
          : visitorProfile.firstName
      );
      setLocalVisitorLastName(
        validator.isEmpty(visitorProfile.lastName)
          ? ''
          : visitorProfile.lastName
      );
      if (visitorProfile.nationality) {
        setLocalVisitorNationality(
          validator.isEmpty(visitorProfile.nationality)
            ? ''
            : visitorProfile.nationality
        );
      } else {
        setLocalVisitorNationality('');
      }
      if (visitorProfile.address) {
        setLocalVisitorAddress(
          validator.isEmpty(visitorProfile.address)
            ? ''
            : visitorProfile.address
        );
      } else {
        setLocalVisitorAddress('');
      }
      if (visitorProfile.location) {
        setLocalVisitorLocation(
          validator.isEmpty(visitorProfile.location)
            ? ''
            : visitorProfile.location
        );
      } else {
        setLocalVisitorLocation('');
      }
      if (visitorProfile.postalCode) {
        if (validator.isEmpty(visitorProfile.postalCode)) {
          setLocalVisitorFirstSection('');
          setLocalVisitorLastSection('');
        } else {
          setLocalVisitorFirstSection(visitorProfile.postalCode.split('-')[0]);
          setLocalVisitorLastSection(visitorProfile.postalCode.split('-')[1]);
        }
      } else {
        setLocalVisitorFirstSection('');
        setLocalVisitorLastSection('');
      }

      setLocalBirthDate(visitorProfile.dateOfBirth);
      setLocalVisitorEmail(
        validator.isEmpty(visitorProfile.email) ? '' : visitorProfile.email
      );

      if (visitorProfile.phoneNumber) {
        setLocalVisitorPhoneNumber(
          validator.isEmpty(visitorProfile.phoneNumber)
            ? ''
            : visitorProfile.phoneNumber
        );
      } else {
        setLocalVisitorPhoneNumber('');
      }

      if (visitorProfile.gender) {
        setLocalVisitorGender(visitorProfile.gender);
      } else {
        setLocalVisitorGender('');
      }

      setLocalPassword('');
      if (visitorProfile.phoneNumber) {
        setLocalVisitorPhoneNumber(
          validator.isEmpty(visitorProfile.phoneNumber)
            ? ''
            : visitorProfile.phoneNumber
        );
      } else {
        setLocalVisitorPhoneNumber('');
      }
      if (visitorProfile.gender) {
        setLocalVisitorGender(visitorProfile.gender);
      } else {
        setLocalVisitorGender('');
      }
      if (visitorProfile.nif) {
        setLocalVisitorNIF(
          validator.isEmpty(visitorProfile.nif) ? '' : visitorProfile.nif
        );
      } else {
        setLocalVisitorNIF('');
      }

      setChangePassword(false);
      if (!form) return;
      setFormState({ ...form, editing: false });
    },
    [setFormState, form]
  );

  const requestChangePassword = useCallback(async () => {
    try {
      await Axios.patch(
        `${API_URL}/authentication/profile/${form.visitorId}/reset-password`, // TENHO DE CRIAR END POINT
        { newPassword: newPassword.value, oldPassword: 'none' }
      );
      //close modal
      message.success(
        translate('resetPassword.resetPasswordSuccess').toString()
      );
    } catch (err) {}
  }, [newPassword, form, translate]);

  const editForm = useCallback(() => {
    setFormState({ ...form, editing: true });
  }, [setFormState, form]);

  //** ERROR CATCH */
  const firstNameError = useMemo((): ErrorInterface => {
    if (localVisitorFirstName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorFirstName]);
  const lastNameError = useMemo((): ErrorInterface => {
    if (localVisitorLastName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorLastName]);
  const zipCode1Error = useMemo((): ErrorInterface => {
    if (localVisitorFirstSection.length !== 4) {
      return {
        error: true,
        reason: 'ZipCode first section should have 4 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorFirstSection]);
  const zipCode2Error = useMemo((): ErrorInterface => {
    if (localVisitorLastSection.length !== 3) {
      return {
        error: true,
        reason: 'ZipCode second section  should have 3 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorLastSection]);
  const addressError = useMemo((): ErrorInterface => {
    if (localVisitorAddress === '') {
      return {
        error: true,
        reason: 'Address should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorAddress]);
  const locationError = useMemo((): ErrorInterface => {
    if (localVisitorLocation === '') {
      return {
        error: true,
        reason: 'Location should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorLocation]);
  const emailError = useMemo((): ErrorInterface => {
    if (!validator.isEmail(localVisitorEmail)) {
      return {
        error: true,
        reason: 'Invalid email',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorEmail]);
  const birthDateError = useMemo((): ErrorInterface => {
    var guideAge = moment().diff(localVisitorBirthDate, 'years', false);
    if (guideAge < 18) {
      return {
        error: true,
        reason: 'Visitor is under age',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorBirthDate]);
  const phoneNumberError = useMemo((): ErrorInterface => {
    if (!validator.isMobilePhone(localVisitorPhoneNumber)) {
      return {
        error: true,
        reason: 'Phone number must have 9 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorPhoneNumber]);

  const GenderError = useMemo((): ErrorInterface => {
    if (validator.isEmpty(localVisitorGender)) {
      return {
        error: true,
        reason: 'Select a gender',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorGender]);

  const NationalityError = useMemo((): ErrorInterface => {
    if (validator.isEmpty(localVisitorNationality)) {
      return {
        error: true,
        reason: 'Select a nationality',
      };
    }
    return { error: false, reason: '' };
  }, [localVisitorNationality]);

  const selectAccessibilities = useCallback((checkedValues: any) => {
    setLocalAccessibilities(checkedValues);
  }, []);
  const selectShowOnly = useCallback((checkedValue: any) => {
    setLocalVisitorShowOnly(checkedValue.target.checked);
  }, []);
  //****************************************************************** */
  const submitForm = useCallback(async () => {
    if (!jwt) return;

    if (
      firstNameError.error ||
      lastNameError.error ||
      zipCode1Error.error ||
      zipCode2Error.error ||
      addressError.error ||
      locationError.error ||
      emailError.error ||
      birthDateError.error ||
      phoneNumberError.error ||
      NationalityError.error ||
      GenderError.error
    ) {
      message.error('All fields must be filled');

      return;
    }
    if (!localVisitorBirthDate) return;
    try {
      setIsUpdating(true);
      const { data: updatedVisitor } = await editVisitor(
        form.visitorId,
        {
          firstName: localVisitorFirstName,
          lastName: localVisitorLastName,
          nif: localVisitorNIF,
          nationality: localVisitorNationality,
          gender: localVisitorGender as GenderType,
          dateOfBirth: localVisitorBirthDate,
          phoneNumber: localVisitorPhoneNumber,
          address: localVisitorAddress,
          location: localVisitorLocation,
          agreeMarketing: true,
          postalCode: `${localVisitorFirstSection}-${localVisitorLastSection}`,
          showOnly: localVisitorShowOnly,
          accessibilityIds: localAccessibilities,
        },
        avatar
      );
      setVisitorProfile(updatedVisitor);
      setFormState({ editing: false, visitorId: params ? params.id : '' });
      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
    } finally {
      setIsUpdating(false);
    }
  }, [
    firstNameError,
    lastNameError,
    zipCode1Error,
    zipCode2Error,
    addressError,
    locationError,
    emailError,
    birthDateError,
    phoneNumberError,
    jwt,
    translate,
    localVisitorFirstName,
    localVisitorLastName,
    localVisitorNIF,
    localVisitorAddress,
    localVisitorFirstSection,
    localVisitorLastSection,
    localVisitorLocation,
    localVisitorPhoneNumber,
    localVisitorBirthDate,
    localVisitorNationality,
    localVisitorGender,
    localVisitorShowOnly,
    GenderError,
    NationalityError,
    avatar,
    form,
    localAccessibilities,
    params,
  ]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }

  if (!visitorProfile || isLoading) {
    return <Loader />;
  }

  if (hasError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.headerArea}>
          <Heading className={classes.title} level={2}>
            <Translate id="adminstration.profileOf" />{' '}
            {`${visitorProfile.firstName} ${visitorProfile.lastName}`}
          </Heading>
          <div className={classes.buttonsEdit}>
            {!form.editing ? null : (
              <Button
                className={classes.cancelButton}
                onClick={() => {
                  cancelEditForm(visitorProfile);
                }}
              >
                <Translate id="adminstration.cancelEdit" />
              </Button>
            )}
            {!form.editing ? null : (
              <Button
                onClick={() => {
                  submitForm();
                }}
                className={classes.confirmButton}
              >
                {isUpdating ? <BeatLoader /> : <Translate id="profile.save" />}
              </Button>
            )}
            {!form.editing ? (
              <Button
                onClick={() => {
                  editForm();
                }}
                type="primary"
              >
                <Translate id="adminstration.edit" />
              </Button>
            ) : null}
          </div>
        </div>

        <div className={classes.avatarArea}>
          <FallbackAvatar
            className={classes.avatarPreview}
            src={
              <FallbackImage
                src={avatarPreview?.[0] || getVisitorImageUrl(form.visitorId)}
                fallback={require('../../../../../shared_assets/images/visitante_default.svg')}
                fallbackComponent={<Pediguia />}
              />
            }
          />
          {!form.editing ? null : (
            <div className={classes.changeAvatarButton}>
              <Translate id="profile.changePhoto" />
              <input
                type="file"
                accept="image/*"
                ref={avatarInputRef}
                onChange={ev => {
                  if (
                    ev.currentTarget.files &&
                    ev.currentTarget.files.length > 0 &&
                    ev.currentTarget.files[0].size < maxAllowedImageSize
                  ) {
                    setAvatar(ev.currentTarget.files[0]);
                  } else {
                    message.info(
                      complexTranslate(
                        translate('error.imageFileSize').toString(),
                        {
                          '{linkTinyPNG}': (
                            <a key={'tinypnglink'} href="https://tinypng.com/">
                              <LinkButton
                                key="1"
                                type="link"
                                className={classNames(
                                  classes.noMargin,
                                  classes.inlineBtn
                                )}
                                onlyText
                              >
                                {translate('error.linkTinyPNG').toString()}
                              </LinkButton>
                            </a>
                          ),
                        }
                      )
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.divisorAvatar} />
        <div className={classes.bodyContainer}>
          <div className={classes.infoZone}>
            {/* NAME */}
            <Text noMargin weight="semibold">
              <Translate id="profile.firstName" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localVisitorFirstName}
              onChange={ev => {
                setLocalVisitorFirstName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={firstNameError.error}
              reason={firstNameError.reason}
            />
            <Text noMargin weight="semibold">
              <Translate id="profile.lastName" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localVisitorLastName}
              onChange={ev => {
                setLocalVisitorLastName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={lastNameError.error}
              reason={lastNameError.reason}
            />
            <Divider className={classes.divider} />
            {/* NACIONALIDADE */}
            <Text weight="semibold">
              <Translate id="profile.nationality" />
            </Text>
            <Select
              className={classes.select}
              onChange={v => setLocalVisitorNationality(v.toString())}
              huge
              disabled={!form.editing}
              value={localVisitorNationality || 'Selecionar uma nacionalidade'}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={NationalityError.error}
              reason={NationalityError.reason}
            />
            <Divider className={classes.divider} />
            {/* ADDRESS INFO */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.residence" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localVisitorAddress}
              onChange={ev => {
                setLocalVisitorAddress(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={addressError.error}
              reason={addressError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.location" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localVisitorLocation}
              onChange={ev => {
                setLocalVisitorLocation(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={locationError.error}
              reason={locationError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.postalCode" />
            </Text>
            <div className={classes.row}>
              <Input
                className={classes.disableTextColor}
                disabled={!form.editing}
                type="number"
                placeholder="XXXX"
                onChange={ev => {
                  setLocalVisitorFirstSection(ev.currentTarget.value);
                }}
                value={localVisitorFirstSection}
              />

              <span className={classes.spacer}>-</span>
              <Input
                className={classes.disableTextColor}
                disabled={!form.editing}
                placeholder="XXX"
                type="number"
                onChange={ev => {
                  setLocalVisitorLastSection(ev.currentTarget.value);
                }}
                value={localVisitorLastSection}
              />
            </div>
            <AnimatedError
              isVisible={zipCode1Error.error}
              reason={zipCode1Error.reason}
            />
            <AnimatedError
              isVisible={zipCode2Error.error}
              reason={zipCode2Error.reason}
            />
            <Divider className={classes.divider} />
            {/* EMAIL */}
            <Text noMargin weight="semibold">
              <Translate id="profile.email" />
            </Text>
            <Input
              type={'email'}
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localVisitorEmail}
              onChange={ev => {
                setLocalVisitorEmail(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={emailError.error}
              reason={emailError.reason}
            />
            {/* PASSWORD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.password" />
            </Text>
            <Modal
              fullScreenOnMobile={false}
              contentContainerClassName={classes.modalContent}
              headerTitle={<Translate id="button.addSkill" />}
              footer={
                <div className={classes.footer}>
                  <Button
                    onClick={() => {
                      setChangePassword(false);
                    }}
                  >
                    <Translate id="button.cancel" />
                  </Button>
                  <div className={classes.addRemoveBtn}>
                    <Button
                      type="primary"
                      onClick={() => {
                        requestChangePassword();
                        setChangePassword(false);
                      }}
                    >
                      <Translate id="button.save" />
                    </Button>
                  </div>
                </div>
              }
              visible={changePassword}
              closable={false}
            >
              <Text color={'red'} noMargin weight="semibold">
                <Translate id="adminstration.passwordWarning" />
              </Text>
            </Modal>
            {/* ANCHOR: PASSWORD */}
            <div className={classes.centeredRow}>
              <AppInput
                huge
                className={classes.passwordInput}
                type={isPasswordShowing ? 'text' : 'password'}
                placeholder={translate('register.password').toString()}
                onChange={ev => setLocalPassword(ev.target.value)}
                value={localPassword}
                disabled={!form.editing}
                prefix={<Lock className={classes.icon} />}
                suffix={
                  isPasswordShowing ? (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <See className={classes.icon} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <Unsee className={classes.icon} />
                    </div>
                  )
                }
              />
              <Button
                disabled={!form.editing}
                type="ghost"
                onClick={() => {
                  setChangePassword(true);
                }}
              >
                <Translate id="profile.change" />
              </Button>
            </div>
            {form.editing && !validator.isEmpty(newPassword.value) ? (
              <AnimatedInfo
                isVisible={
                  newPassword.isVisible ? newPassword.isVisible : false
                }
                infos={newPassword.infos}
              />
            ) : null}
            <Divider className={classes.divider} />
            {/* B-DAY */}
            <Text weight="semibold">
              <Translate id="profile.dateOfBirth" />
            </Text>
            <BirthDateSelector
              onDateChange={changeDate}
              isInvalid={true}
              reason={''}
              initialValue={localVisitorBirthDate}
              disable={!form.editing}
            />
            <AnimatedError
              isVisible={birthDateError.error}
              reason={birthDateError.reason}
            />
            <Divider className={classes.divider} />
            {/* PHONE NUMBER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.phoneNumber" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localVisitorPhoneNumber}
              onChange={ev => {
                setLocalVisitorPhoneNumber(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={phoneNumberError.error}
              reason={phoneNumberError.reason}
            />
            <Divider className={classes.divider} />
            {/* GENDER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.gender" />
            </Text>
            <Select
              className={classes.select}
              defaultValue={undefined}
              disabled={!form.editing}
              onChange={v => setLocalVisitorGender(v.toString())}
              value={localVisitorGender || 'Selecionar um genero'}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  key={gender}
                  huge
                  value={gender}
                  label={translate(`genders.${gender}`)}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={GenderError.error}
              reason={GenderError.reason}
            />
            <Divider className={classes.divider} />
            {/* NIF */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.nif" />
            </Text>
            <Input
              disabled={!form.editing}
              className={classes.disableTextColor}
              value={localVisitorNIF}
              onChange={ev => {
                setLocalVisitorNIF(ev.target.value);
              }}
            ></Input>
            <Divider className={classes.divider} />
            <Text weight="semibold" noMargin>
              Acessibilidades
            </Text>
            <Checkbox
              disabled={!form.editing}
              key={'showOnly'}
              onChange={selectShowOnly}
              defaultChecked={localVisitorShowOnly}
            >
              Mostrar apenas
            </Checkbox>
            <Divider className={classes.divider} />

            <Checkbox.Group
              disabled={!form.editing}
              options={accessibilitiesOptions}
              defaultValue={localAccessibilities}
              onChange={selectAccessibilities}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(VisitorInformation);
