import Axios from 'axios';
import { API_URL } from '../../consts';

export interface SystemConfigurationDto {
  fee: number;
  cut: number;
  timeValue: number;
  timePercentage: number;
  languageNumber: number;
  languagePercentage: number;
  minLocalBusinessValue: number;
  maxLocalBusinessValue: number;
  minLocalBusinessPercentage: number;
  maxLocalBusinessPercentage: number;
  minReviewValue: number;
  mid1ReviewValue: number;
  mid2ReviewValue: number;
  maxReviewValue: number;
  minReviewPercentage: number;
  mid1ReviewPercentage: number;
  mid2ReviewPercentage: number;
  maxReviewPercentage: number;
  minCompletedValue: number;
  midCompletedValue: number;
  maxCompletedValue: number;
  minCompletedPercentage: number;
  midCompletedPercentage: number;
  maxCompletedPercentage: number;
  seniorDiscount: number;
  childrenDiscount: number;
}

export const postSystemConfiguration = (dto: SystemConfigurationDto) => {
  Axios.patch(`${API_URL}/administrator/system-configuration`, dto).catch(
    err => {
      return Promise.reject(err);
    }
  );
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
