import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../components/App/Modal';
import { useVideoUrl } from '../../../hooks/customization/useVideoUrl';
import { RootState } from '../../../store/index';
import { setVideoUrl } from '../../../store/UserConfiguration/actions';

const useStyles = makeStyles({
  container: {
    padding: 24,
  },
});
interface VideoPlayerModalProps {
  currentLocation?: any;
}
export default function VideoPlayerModal(
  videoPlayerModalProps: VideoPlayerModalProps
) {
  const currentVideoUrl = useSelector(
    (state: RootState) => state.userConfiguration.videoUrl
  );
  const hasAcceptedCookies = useSelector(
    (state: RootState) => state.userConfiguration.acceptedCookies
  );

  // pathname: '/terms-of-service
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');
  useVideoUrl(setUrl);

  useEffect(() => {
    if (url) {
      if (currentVideoUrl !== url) {
        setShowModal(true);
      }
    }
  }, [currentVideoUrl, url]);
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Modal
      stickToBottom={false}
      visible={showModal}
      closable={true}
      contentContainerClassName={classes.container}
      fullScreenOnMobile={true}
      widthModal={'50%'}
      variant={'close'}
      onRequestClose={() => {
        dispatch(setVideoUrl(url));
        setShowModal(false);
      }}
      key={'videoModal'}
      zIndex={1000}
      disableNaturalPadding={false}
    >
      <ReactPlayer
        width="100%"
        height="480px"
        playing={hasAcceptedCookies ? true : false}
        url={url}
      />
    </Modal>
  );
}
