import { makeStyles } from '@material-ui/styles';
import Typography from 'antd/es/typography';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import classNames from 'classnames';
import React from 'react';

import { getTheme, useIsMobile } from '../../utils';

type TextVariant = 'default' | 'note' | 'link' | 'faded' | undefined;
type Weight = 'bold' | 'semibold';

interface StyleProps {
  variant?: TextVariant;
  color?: string;
  size?: number;
  noMargin?: boolean;
  inline?: boolean;
  weight?: Weight;
  isMobile: boolean;
  disableAutoscale?: boolean;
}

const useStyles = makeStyles({
  paragraph: ({
    variant,
    color,
    size,
    noMargin,
    inline,
    weight,
    isMobile,
    disableAutoscale,
  }: StyleProps) => {
    let finalColor = getTheme().defaultTextPrimaryColor;
    let fontSize = isMobile || disableAutoscale ? 14 : 16;
    let fontWeight: string | number = 'normal';

    switch (variant) {
      case 'link':
        finalColor = getTheme().primary;
        fontWeight = 'normal';
        break;
      case 'faded':
        finalColor = getTheme().neutral1;
        break;
      case 'note':
        fontSize = fontSize - 4;
        fontWeight = 'normal';
        break;
      default:
    }

    if (color) finalColor = color;
    if (size) fontSize = size + (isMobile || disableAutoscale ? 0 : 2);
    switch (weight) {
      case 'bold':
        fontWeight = 'bold';
        break;
      case 'semibold':
        fontWeight = 600;
        break;
      default:
        break;
    }

    return {
      fontWeight: (`${fontWeight} !important` as unknown) as number,
      fontSize: `${fontSize}px !important`,
      color: finalColor,
      margin: noMargin ? '0px !important' : undefined,
      display: inline ? 'inline' : undefined,
    };
  },
});

interface TextProps extends ParagraphProps {
  variant?: TextVariant;
  color?: string;
  size?: number;
  noMargin?: boolean;
  inline?: boolean;
  weight?: Weight;
  disableAutoscale?: boolean;
}

const Text: React.FC<TextProps> = ({
  className,
  variant,
  children,
  noMargin,
  color,
  size,
  inline,
  weight,
  disableAutoscale,
  ...rest
}) => {
  const isMobile = useIsMobile();

  const classes = useStyles({
    variant,
    color,
    size,
    noMargin,
    inline,
    weight,
    isMobile,
    disableAutoscale,
  });
  return (
    <Typography.Paragraph
      {...rest}
      className={classNames(classes.paragraph, className)}
    >
      {children}
    </Typography.Paragraph>
  );
};

export default React.memo(Text);
