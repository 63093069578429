import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { ReactComponent as PlusBlue } from '../../../shared_assets/icons/plus_blue.svg';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Option, Select } from '../Select';
import GuideLanguageEntry from './GuideLanguageEntry';

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
    '& > .segment': {
      display: 'flex',
      '& > *:first-child': {
        marginRight: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
});

interface GuideLanguageSelectionProps extends LocalizeContextProps {
  value?: string[];
  inputLanguages: string[];
  onChange?: (newLanguages: string[]) => void;
  className?: string;
}

const GuideLanguageSelection: React.FC<GuideLanguageSelectionProps> = ({
  value,
  inputLanguages: languages,
  onChange,
  translate,
  className,
}) => {
  const classes = useStyles();

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    value || []
  );

  const [editing, setEditing] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);

  const [languageToReplace, setLanguageToReplace] = useState<
    string | undefined
  >();
  const [languageToAdd, setLanguageToAdd] = useState<string | undefined>();

  useEffect(() => {
    if (!value) return;

    setSelectedLanguages(value);
  }, [value]);

  useEffect(() => {
    if (!onChange) return;

    onChange(selectedLanguages);
  }, [selectedLanguages, onChange]);

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setAdding(false);
                setLanguageToAdd(undefined);
              }}
            >
              <Translate id="button.cancel" />
            </Button>

            <Button
              disabled={!languageToAdd}
              type="primary"
              onClick={() => {
                if (languageToAdd) {
                  setSelectedLanguages(prevLanguages => [
                    ...prevLanguages,
                    languageToAdd,
                  ]);
                  setLanguageToAdd(undefined);
                  setAdding(false);
                }
              }}
            >
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={adding}
        closable={false}
      >
        <Select
          placeholder={<Translate id="button.addLanguage" />}
          className={classes.select}
          value={languageToAdd}
          getPopupContainer={() => document.body}
          onChange={ev => setLanguageToAdd(ev as string)}
          huge
        >
          {languages
            .filter(lang => !selectedLanguages.includes(lang))
            .map(lang => (
              <Option
                key={lang}
                value={lang}
                label={translate(`languages.${lang}`).toString()}
              >
                <Translate id={`languages.${lang}`} />
              </Option>
            ))}
        </Select>
      </Modal>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        footer={
          <div className={classes.footer}>
            <div className="segment">
              <Button
                onClick={() => {
                  setLanguageToAdd(undefined);
                  setLanguageToReplace(undefined);
                  setEditing(false);
                }}
              >
                <Translate id="button.cancel" />
              </Button>

              <Button
                disabled={!languageToAdd || languageToReplace !== languageToAdd}
                danger
                onClick={() => {
                  if (languageToAdd) {
                    setSelectedLanguages(prevLanguages =>
                      prevLanguages.filter(lang => lang !== languageToAdd)
                    );
                    setLanguageToAdd(undefined);
                    setEditing(false);
                  }
                }}
              >
                <Translate id="button.remove" />
              </Button>
            </div>
            <Button
              disabled={!languageToAdd}
              type="primary"
              onClick={() => {
                if (languageToAdd) {
                  setSelectedLanguages(prevLanguages => [
                    ...prevLanguages.filter(lang => lang !== languageToReplace),
                    languageToAdd,
                  ]);
                  setLanguageToAdd(undefined);
                  setLanguageToReplace(undefined);
                  setEditing(false);
                }
              }}
            >
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={editing}
        closable={false}
      >
        <Select
          className={classes.select}
          value={languageToAdd}
          getPopupContainer={() => document.body}
          onChange={ev => setLanguageToAdd(ev as string)}
          huge
        >
          {languages
            .filter(
              lang =>
                lang === languageToReplace || !selectedLanguages.includes(lang)
            )
            .map(lang => (
              <Option
                key={lang}
                value={lang}
                label={translate(`languages.${lang}`).toString()}
              >
                <Translate id={`languages.${lang}`} />
              </Option>
            ))}
        </Select>
      </Modal>
      <div className={className}>
        {selectedLanguages.map((language, i) => (
          <GuideLanguageEntry
            key={language}
            onRequestLanguageChange={() => {
              setLanguageToReplace(language);
              setLanguageToAdd(language);
              setEditing(true);
            }}
            code={language}
          />
        ))}
        <Button
          onClick={() => setAdding(true)}
          prefix={<PlusBlue />}
          type="link"
        >
          <Translate id="button.addLanguage" />
        </Button>
      </div>
    </>
  );
};

export default React.memo(withLocalize(GuideLanguageSelection));
