import { useMemo, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import validator from 'validator';
import { store } from '../../store/index';
import {
  FormEntry,
  FormGuideEntry,
  Info,
  SettableFormGuideEntry,
} from '../../types/form.interface';

export type RegisterFormEntries =
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'password';

interface SettableFormEntry extends FormEntry {
  set: (val: string) => void;
}

interface RegisterForm extends Record<RegisterFormEntries, SettableFormEntry> {
  password: SettableFormGuideEntry;
}

export const useRegisterForm = (): RegisterForm => {
  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const [email, setEmail] = useState<FormEntry>({
    value: '',
    isInvalid: true,
  });
  const [firstName, setFirstName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
  });
  const [lastName, setLastName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
  });
  const [password, setPassword] = useState<FormGuideEntry>({
    value: '',
    isInvalid: true,
    isVisible: false,
  });

  /*const resetForm = () => {
    setEmail({ value: '', isInvalid: true });
    setFirstName({ value: '', isInvalid: true });
    setLastName({ value: '', isInvalid: true });
    setPhoneNumber({ value: '', isInvalid: true });
    setPassword({ value: '', isInvalid: true, isVisible: false });
  };*/

  return {
    email: {
      ...email,
      set: (value: string) => {
        const isInvalid = !validator.isEmail(value);
        let reason: string | undefined = undefined;
        if (isInvalid) {
          reason = translate('register.badEmail').toString();
        }

        setEmail({ value, isInvalid, reason });
      },
    },
    firstName: {
      ...firstName,
      set: (value: string) => {
        //const isInvalid = !validator.isLength(value, { min: 3 });
        const isInvalid = value.length < 2 || value.length > 50;
        let reason: string | undefined = undefined;
        if (isInvalid) {
          reason = translate('register.badName').toString();
        }

        setFirstName({ value, isInvalid, reason });
      },
    },
    lastName: {
      ...lastName,
      set: (value: string) => {
        const isInvalid = value.length < 2 || value.length > 50;
        let reason: string | undefined = undefined;
        if (isInvalid) {
          reason = translate('register.badName').toString();
        }

        setLastName({ value, isInvalid, reason });
      },
    },
    password: {
      ...password,
      set: (value: string) => {
        const formatNumSymbol = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        const formatStrength =
          /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
        const hasRightLength = value.length >= 8 && value.length <= 20;
        const emailSplit = email.value.includes('@') && email.value.split('@');
        const isStrength = value.match(formatStrength) ? true : false;
        const isContainNameEmail: boolean =
          (value.includes(firstName.value) && firstName.value !== '') ||
          (value.includes(lastName.value) && lastName.value !== '') ||
          (emailSplit && value.includes(emailSplit[0]) && email.value !== '') ||
          value === ''; //TODO: falta fazer validar o email
        const isContainNumSymbol =
          formatNumSymbol.test(value) && value.match(/\d/) ? true : false;
        const isInvalid: boolean = !(
          hasRightLength &&
          !isContainNameEmail &&
          isContainNumSymbol &&
          isStrength
        );
        let infos: Info[] = [];

        if (isInvalid) {
          infos = [
            {
              infoType: 'error',
              phrase: [
                translate('register.passwordStrengthInfo').toString(),
                translate('register.weakPassword').toString(),
              ],
            },
            {
              infoType: !isContainNameEmail ? 'success' : 'default',
              phrase: [translate('register.passNoContainNameMail').toString()],
            },
            {
              infoType: hasRightLength ? 'success' : 'default',
              phrase: [translate('register.rightLengthPassword').toString()],
            },
            {
              infoType: isContainNumSymbol ? 'success' : 'default',
              phrase: [translate('register.passwordRestrictions').toString()],
            },
          ];
        } else {
          infos = [
            {
              infoType: 'success',
              phrase: [
                translate('register.passwordStrengthInfo').toString(),
                translate('register.strongPassword').toString(),
              ],
            },
          ];
        }
        setPassword({ value, isInvalid, isVisible: true, infos });
      },
    },
  };
};
