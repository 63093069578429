import Axios from 'axios';
import { API_URL } from '../../consts';

interface EditAdvertiserDto {
  name?: string;
  phoneNumber?: string;
  url?: string;
  address?: string;
  email?: string;
  id: string;
}

export interface EditAdvertiserResponse {
  name: string;
  phoneNumber: string;
  url: string;
  address: string;
  email: string;
  id: string;
}
interface ChangePasswordDto {
  newPassword: string;
  oldPassword: string;
}
export const editAdvertiser = (dto: EditAdvertiserDto, image?: File | null) => {
  const formData = new FormData();
  formData.append(
    'editAdOwnerDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (image) {
    formData.append('logo-image', image);
  }

  return Axios({
    method: 'put',
    url: `${API_URL}/publicity/edit-ad-owner/${dto.id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};

export const changePassword = (id: string, dto: ChangePasswordDto) =>
  Axios.patch(`${API_URL}/authentication/profile/${id}/reset-password`, dto);
