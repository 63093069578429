import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { hideNotifications } from '../../../hooks/notifications/hideNotifications';
import { markNotificationAsRead } from '../../../hooks/notifications/markNotificationAsRead';
import { ReactComponent as CloseBlue } from '../../../shared_assets/icons/close_blue.svg';
import {
  GenericNotification,
  NotificationType,
} from '../../../types/notification.interface';
import { castShadow } from '../../../utils';
import NotificationApprovedProfile from './Notifications/NotificationApprovedProfile';
import NotificationBookingCancel from './Notifications/NotificationBookingCancel';
import NotificationComment from './Notifications/NotificationComment';
import NotificationCompleteProfile from './Notifications/NotificationCompleteProfile';
import NotificationDraftRoute from './Notifications/NotificationDraftRoute';
import NotificationEvaluateRoute from './Notifications/NotificationEvaluateRoute';
import NotificationNewBooking from './Notifications/NotificationNewBooking';
import NotificationRouteApproved from './Notifications/NotificationRouteApproved';
import NotificationRouteRejected from './Notifications/NotificationRouteRejected';
import NotificationRouteToStart from './Notifications/NotificationRouteToStart';
import NotificationScheduleCancellation from './Notifications/NotificationScheduleCancellation';

export const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0 1rem',
    '& .ant-divider': {
      margin: '1rem 0',
    },
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& *': {
      marginRight: '0.5rem',
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  marginLeft: {
    marginLeft: '0.5rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.5rem',
    flexShrink: 0,
  },
  notificationContainer: {
    borderRadius: 10,
    padding: '1rem',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    margin: '0.5rem 0',
  },
  castShadow: {
    boxShadow: castShadow,
  },
  close: {
    height: 14,
    width: 14,
    marginLeft: '2rem',
    flexShrink: 0,
  },
  normalNotification: {
    alignSelf: 'start',
    marginLeft: 'unset',
  },
});

interface NotificationProps {
  notification: GenericNotification;
  simpleVersion?: boolean;
}
interface Classes {
  page: string;
  container: string;
  rowDirection: string;
  marginLeft: string;
  buttonContainer: string;
  uppercase: string;
  inlineBtn: string;
  icon: string;
  close: string;
  notificationContainer: string;
  castShadow: string;
  contentWrapper: string;
  normalNotification: string;
}

interface NotificationComponentProps extends NotificationProps {
  onHide: (hiddenId: string) => void;
  onRead: (hiddenId: string) => void;
}

export interface NotificationsProps extends NotificationProps {
  classes: Classes;
}
const NotificationComponent = ({
  notification,
  simpleVersion,
  onRead,
  onHide,
}: NotificationComponentProps) => {
  const classes = (useStyles() as unknown) as Classes;
  let notificationFragment;
  switch (notification.type) {
    case NotificationType.COMPLETE_PROFILE:
      notificationFragment = (
        <NotificationCompleteProfile
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.GUIDE_APPROVED:
      notificationFragment = (
        <NotificationApprovedProfile
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.EVALUATE_ROUTE:
      notificationFragment = (
        <NotificationEvaluateRoute
          onHide={onHide}
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.SCHEDULE_CANCELLATION:
      notificationFragment = (
        <NotificationScheduleCancellation
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.BOOKING_CANCEL:
      notificationFragment = (
        <NotificationBookingCancel
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.COMMENT:
      notificationFragment = (
        <NotificationComment
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.DRAFT_ROUTE:
      notificationFragment = (
        <NotificationDraftRoute
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.NEW_BOOKING:
      notificationFragment = (
        <NotificationNewBooking
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.ROUTE_APPROVED:
      notificationFragment = (
        <NotificationRouteApproved
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.ROUTE_REJECTED:
      notificationFragment = (
        <NotificationRouteRejected
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    case NotificationType.ROUTE_TO_START:
      notificationFragment = (
        <NotificationRouteToStart
          simpleVersion={simpleVersion}
          notification={notification}
          classes={classes}
        />
      );
      break;
    default:
      notificationFragment = null;
  }

  const hide = useCallback(async () => {
    try {
      await hideNotifications([notification.notificationId]);
      onHide(notification.notificationId);
    } catch (err) {}
  }, [notification, onHide]);

  const read = useCallback(async () => {
    try {
      await markNotificationAsRead(notification.notificationId);
      onRead(notification.notificationId);
    } catch (err) {}
  }, [onRead, notification]);

  return (
    <div
      className={classNames(
        classes.notificationContainer,
        !simpleVersion ? classes.castShadow : ''
      )}
    >
      <div className={classes.contentWrapper} onClick={read}>
        {notificationFragment}
      </div>
      <div onClick={hide}>
        <CloseBlue
          className={classNames(
            classes.close,
            !simpleVersion ? classes.normalNotification : ''
          )}
        />
      </div>
    </div>
  );
};

export default NotificationComponent;
