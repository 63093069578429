import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AreaCard, { AreaCardProps } from './AreaCard';

interface AreaCardListProps {
  data: AreaCardProps[];
  className?: string;
  wrap?: boolean;
  hyperLink?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginTop: '1em',
    '& > * ': {
      margin: '0 1rem',
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  wrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyItems: 'center',
    rowGap: '3rem',
    columnGap: '4rem',
    alignSelf: 'center',
  },
});

export default function AreaCardList({
  data,
  className,
  wrap,
  hyperLink,
}: AreaCardListProps) {
  const classes = useStyles();

  const list = (
    <div
      className={classNames(wrap ? classes.wrap : classes.container, className)}
    >
      {data.map((card, i) => (
        <AreaCard key={i.toString()} {...card} hyperLink={hyperLink} />
      ))}
    </div>
  );

  return !wrap ? (
    <PerfectScrollbar>{list}</PerfectScrollbar>
  ) : (
    <div style={{ overflowY: 'auto' }}>{list}</div>
  );
}
