import {
  ACCEPT_COOKIES,
  LOGIN,
  LOGOUT,
  SET_ACTIVE_LANGUAGE,
  SET_ONGOING_ROUTE,
  SET_VIDEO_URL,
  UserConfigurationActions,
  UserConfigurationState,
} from './types';
const INITIAL_STATE: UserConfigurationState = {
  activeLanguage: 'pt_PT',
  jwt: null,
  videoUrl: null,
  acceptedCookies: false,
  ongoingRouteId: null,
};

const UserConfigurationReducer = (
  state: UserConfigurationState = INITIAL_STATE,
  action: UserConfigurationActions
): UserConfigurationState => {
  switch (action.type) {
    case SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguage: action.payload,
      };
    case LOGIN:
      return {
        ...state,
        jwt: action.payload.token,
      };
    case LOGOUT:
      return {
        ...state,
        jwt: null,
      };
    case ACCEPT_COOKIES:
      return {
        ...state,
        acceptedCookies: true,
      };
    case SET_VIDEO_URL:
      return {
        ...state,
        videoUrl: action.payload.videoUrl,
      };
    case SET_ONGOING_ROUTE:
      return {
        ...state,
        ongoingRouteId: action.payload.id,
      };
    default:
      return state;
  }
};

export default UserConfigurationReducer;
