import Axios from 'axios';
import { API_URL } from '../../consts';
import { FaqEntry } from './useFaqAdmin';

interface FaqsDto {
  faqs: FaqEntry[];
}

export const postPostFaqs = (dto: FaqsDto) => {
  Axios.post(
    `${API_URL}/content-management/guide-landing-page/faqs`,
    dto
  ).catch(err => {
    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
