import { makeStyles } from '@material-ui/styles';
import Alert from 'antd/es/alert';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { ReactComponent as Pediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { headerHeight } from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    textAlign: 'center',
    padding: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 1rem',
    flex: 1,
  },
  marginBetween: {
    margin: '1rem 0',
  },
  img: {
    width: '50%',
    flex: 1,
  },
  logo: {
    maxHeight: headerHeight,
  },
  marginTop: {
    marginTop: '1rem',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  smallLogo: {
    maxHeight: 32,
  },
});

const AwaitingApproval = () => {
  const classes = useStyles();
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.container}>
        <Alert
          className={classNames(classes.marginBetween)}
          message=""
          description={<Translate id="login.awaitingApproval" />}
          type="info"
          showIcon
        />

        <Pediguia className={classes.img} />
      </div>

      <CopyrightFooter />
    </motion.div>
  );
};

export default AwaitingApproval;
