import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AreaCardProps } from '../../components/App/AreaCardList/AreaCard';
import { RoutePreviewInterface } from '../../components/App/RoutePreviewList/RoutePreview';
import { StepProps } from '../../components/App/StepList/StepList';
import { API_URL } from '../../consts';
import { LanguageType } from '../../types/language-type.enum';

export interface VisitorLandingTranslation {
  firstTitle: string;
  intro: string;
  experiencesTitle: string;
  experiences: RoutePreviewInterface[];
  featuredRoutesTitle: string;
  // featuredRoutePreviews: RoutePreviewInterface[];
  interestTitle: string;
  interests: AreaCardProps[];
  joinCommunityTitle: string;
  joinCommunityText: string;
  callToActionUrl: string;
  searchTitle: string;
  searchSubtitle: string;
  searchPlaceholder: string;
  howToStartTitle: string;
  howToStartSubtitle: string;
  steps: StepProps[];
}
export interface AreaCardInterface {
  id: string;
  title: string;
  description?: string;
}
interface VisitorLandingPageInterface {
  firstTitle: string;
  intro: string;
  experiencesTitle: string;
  featuredRoutesTitle: string;
  interestTitle: string;
  joinCommunityTitle: string;
  joinCommunityText: string;
  callToActionUrl: string;
  language: LanguageType;
  steps: {
    title: string;
    description: string;
    position: number;
  }[];
  howToStartTitle: string;
  howToStartSubtitle: string;

  experiences: RoutePreviewInterface[];

  interests: AreaCardInterface[];
  searchTitle: string;
  searchSubtitle: string;
  searchPlaceholder: string;
}
type Hook = (
  language: string,
  setVisitorLandingTranslation: (
    val: SetStateAction<VisitorLandingTranslation | null>
  ) => void
) => [boolean, boolean];

const requestLandingPageInformation = (language: string) =>
  Axios.get<VisitorLandingPageInterface>(
    `${API_URL}/content-management/visitor-landing-page/${language}`
  );

export const useVisitorLandingTranslations: Hook = (
  language,
  setVisitorLandingTranslation
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestLandingPageInformation(language);
        const landingPageInformation = list as VisitorLandingPageInterface;
        const translation: VisitorLandingTranslation = {
          firstTitle: landingPageInformation.firstTitle,
          intro: landingPageInformation.intro,
          experiencesTitle: landingPageInformation.experiencesTitle,
          experiences: landingPageInformation.experiences.map(
            (area, index) => ({
              id: area.id,
              place: area.place,
              name: area.name,
              price: area.price,
              rating: area.rating,
              numberOfRatings: area.numberOfRatings,
              image: getRouteImage(area.id),
            })
          ),
          featuredRoutesTitle: landingPageInformation.featuredRoutesTitle,
          steps: landingPageInformation.steps.map(s => ({
            ...s,
            number: s.position + 1,
          })),
          // featuredRoutePreviews: Array.from(Array(3), (_, i) => ({
          //   id: i.toString(),
          //   place: 'Lourinhã',
          //   name: 'pasei pelo parque de qualquer cena',
          //   price: 30,
          //   rating: 4.87,
          //   numberOfRatings: 120,
          //   image: getRandomImage(),
          // })),
          interestTitle: landingPageInformation.interestTitle,
          interests: landingPageInformation.interests.map(i => {
            return {
              title: i.title,
              image: getSubjectImageUrl(i.id),
              id: i.id,
            };
          }),
          joinCommunityTitle: landingPageInformation.joinCommunityTitle,
          joinCommunityText: landingPageInformation.joinCommunityText,
          callToActionUrl: landingPageInformation.callToActionUrl,
          searchPlaceholder: landingPageInformation.searchPlaceholder,
          searchSubtitle: landingPageInformation.searchSubtitle,
          searchTitle: landingPageInformation.searchTitle,
          howToStartSubtitle: landingPageInformation.howToStartSubtitle,
          howToStartTitle: landingPageInformation.howToStartTitle,
        };

        setVisitorLandingTranslation(translation);
        setIsError(false);
      } catch (err) {
        setVisitorLandingTranslation(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setVisitorLandingTranslation, language]);

  return [isLoading, isError];
};
export const getHeaderImageUrl = () =>
  `${API_URL}/content-management/visitor-landing-page/header-image?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
export const getSubjectImageUrl = (id: string) =>
  `${API_URL}/subject/${id}/image?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
const getRouteImage = (id: string) =>
  `${API_URL}/route/${id}/location/photo?_=${moment().unix()}`;
