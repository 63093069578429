import Axios from 'axios';
import { API_URL } from '../../consts';
import {
  AdEventDescriptionInterface,
  AdEventTitleInterface,
} from './useAdEvent';

interface AddAdEventDto {
  adOwnerId: string;
  startAd: Date;
  endAd: Date;
  titles: AdEventTitleInterface[];
  descriptions: AdEventDescriptionInterface[];
  location: string;
  latitude: number;
  longitude: number;
  radius: number;
}

export interface AddAdEventResponse {
  id: string;
  startAd: Date;
  endAd: Date;
  titles: AdEventTitleInterface[];
  descriptions: AdEventDescriptionInterface[];
  longitude: number;
  latitude: number;
  location: string;
  active: boolean;
  radius: number;
}

export const addAdEvent = (dto: AddAdEventDto, image: File) => {
  const formData = new FormData();
  formData.append(
    'createAdEventDto',
    JSON.stringify({
      ...dto,
    })
  );
  formData.append('event-image', image);
  return Axios({
    method: 'post',
    url: `${API_URL}/publicity/create-ad-event`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};
