import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import React from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Modal } from '../../components/App/Modal';
import { Text } from '../../components/Typography';
import { setActiveLanguage as setActiveReduxLanguage } from '../../store/UserConfiguration/actions';
import { getTheme, headerHeight } from '../../utils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    height: headerHeight,
    minHeight: headerHeight,
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '0 1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingTop: 24,
  },
  marginBetween: {
    margin: '1rem 0',
  },
  languageContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(125px, 1fr))`,
    gridAutoRows: '1fr',
    gap: '1rem',
  },
  roundModal: {
    '& .ant-modal-content': {
      borderRadius: 10,
    },
  },
  languageItem: () => ({
    display: 'flex',
    flex: 1,
    borderRadius: '3px',
    flexDirection: 'column',
    padding: '0.5rem',
    border: `2px solid ${getTheme().primary}`,
    '&.active': {
      cursor: 'default',
      backgroundColor: getTheme().primary,
    },
    cursor: 'pointer',
  }),
});

interface ChangeLanguageModalProps extends LocalizeContextProps {
  visible: boolean;
  onClose: () => void;
}

function ChangeLanguageModal({
  languages,
  setActiveLanguage,
  visible,
  onClose,
}: ChangeLanguageModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Modal
      visible={visible}
      footer={null}
      onRequestClose={() => {
        onClose();
      }}
      variant="close"
      headerTitle={<Translate id="language" />}
      className={classes.roundModal}
    >
      <div className={classes.container}>
        <div className={classes.languageContainer}>
          {languages.map(l => (
            <div
              onClick={() => {
                dispatch(setActiveReduxLanguage(l.code));
                setActiveLanguage(l.code);
                switch (l.code) {
                  case 'en_US':
                    moment.locale(l.code);
                    break;
                  case 'fr_FR':
                    moment.locale(l.code);
                    break;
                  case 'es_ES':
                    moment.locale(l.code);
                    break;
                  default:
                    moment.locale(l.code);
                    break;
                }
              }}
              key={l.code}
              className={classNames(
                classes.languageItem,
                l.active ? 'active' : ''
              )}
            >
              <Text color={l.active ? 'white' : undefined} noMargin>
                {l.name}
              </Text>
              <Text color={l.active ? 'white' : undefined} noMargin>
                {l.code}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default withLocalize(ChangeLanguageModal);
