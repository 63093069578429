import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { mobileThreshhold } from '../../../utils';
import AreaCard, { PointDescriptionProps } from './PointDescription';

interface PointDescriptionListProps {
  data: PointDescriptionProps[];
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    overflowY: 'auto',
    marginTop: '1em',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      flexDirection: 'column',
    },
  },
});

function PointDescriptionList({ data }: PointDescriptionListProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {data.map((card, i) => (
        <AreaCard key={i.toString()} {...card} />
      ))}
    </div>
  );
}

export default React.memo(PointDescriptionList);
