import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import { LinkSection } from '../../../components/App/LinkSection';
import { Loader } from '../../../components/App/Loader';
import { Modal } from '../../../components/App/Modal';
import { RouteFilterComponent } from '../../../components/App/RouteFilterComponent';
import { RoutePreviewList } from '../../../components/App/RoutePreviewList';
import { Heading, Text } from '../../../components/Typography';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { getLocationPhotoUrl } from '../../../hooks/routes/location/useLocationPhoto';
import {
  PartialRouteInterface,
  useSimilarRoutes,
} from '../../../hooks/routes/UseSimilarRoutes';
import {
  RouteSubjectWithSingleTranslation,
  useSubjects,
} from '../../../hooks/subjects/useSubjects';
import { useVisitor } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as FilterIcon } from '../../../shared_assets/icons/filter.svg';
import { RootState } from '../../../store';
import { FilterRoute } from '../../../types/filter-route.dto';
import { Title } from '../../../types/title.interface';
import { Visitor } from '../../../types/visitor.interface';
import {
  complexTranslate,
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';

const useStyles = makeStyles({
  page: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      flexShrink: 0,
    },
  },
  body: {
    ...mobilePadding,
    flex: 1,
    flexGrow: 1,
    paddingBottom: '2rem',
  },
  introContainer: {
    display: 'flex',
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: '1rem',
  },
  icon: {
    height: '1em',
    width: '1em',
    float: 'right',
  },
  listContainer: {
    display: 'flex',
  },
  filterContainer: {
    flex: 0.2,
    height: '100%',
    boxShadow: '0px 0px 9px 4px rgba(0,0,0,0.1)',
    marginRight: '3rem',
    borderRadius: 5,
  },
  gridContainer: {
    flex: 1,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    gridContainer: {
      flex: 0.8,
    },
  },
});

interface RouteState {
  displayText?: string;
  filter?: FilterRoute;
}
const RouteSearch: React.FC<LocalizeContextProps> = ({ translate }) => {
  const classes = useStyles();
  const location = useLocation<RouteState>();
  const [filterModalShowing, setFilterModalShowing] = useState<boolean>(false);
  const [routes, setRoutes] = useState<PartialRouteInterface[]>([]);
  const filterParams =
    location.state && location.state.filter ? location.state.filter || {} : {};

  const [filter, setFilter] = useState<FilterRoute>(filterParams);

  const [routeAreLoading, routesHaveError] = useSimilarRoutes(
    filter || {},
    setRoutes
  );
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [subjects, setSubjects] = useState<RouteSubjectWithSingleTranslation[]>(
    []
  );
  const [subjectsAreLoading, subjectsHasError] = useSubjects(
    setSubjects,

    activeLanguage,
    true
  );

  const [jwt] = UseJWT();
  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const [visitorIsLoading] = useVisitor(jwt?.visitorId, setVisitor);

  const [placeSearchText, setPlaceSearchText] = useState<string>('');

  useEffect(() => {
    if (!visitor || !visitor.showOnly || filter.accessibilitiesIds) return;
    setFilter(prevFilter => ({
      ...prevFilter,
      accessibilitiesIds: visitor.accessibilities,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitor]);

  const generateFilterBreadcrumb = useCallback(() => {
    let string = [translate('search.breadcrumbPrefix')];

    if (filter.subjectsIds) {
      string = string.concat(
        filter.subjectsIds.map((s, i) => (
          <Text inline variant="link">{`${
            subjects.find(sub => s === sub.id)?.name
          }${i !== (filter.subjectsIds?.length || 0) - 1 ? ' - ' : ''}`}</Text>
        ))
      );
    } else return null;

    return string;
  }, [filter, translate, subjects]);

  const generateMainFilter = useCallback(() => {
    if (placeSearchText) return placeSearchText;

    switch (Object.keys(filter)[0]) {
      case 'titles':
        return complexTranslate(translate('search.searchTitle').toString(), {
          '{TERM}': filter.titles?.[0],
        });
      case 'subjectsIds':
        return subjects.find(s => s.id === filter.subjectsIds?.[0])?.name;
      default:
        return translate('search.filter');
    }
  }, [filter, translate, subjects, placeSearchText]);

  useEffect(() => {
    //setFilter(filterParams);
    setPlaceSearchText(
      location.state && location.state.displayText
        ? location.state.displayText || ''
        : ''
    );
  }, [location.state]);
  const getRouteTitle = useCallback(
    (titles: Title[]) => {
      const translation = titles.find(t => t.language === activeLanguage);
      return translation ? translation.title : titles[0].title;
    },
    [activeLanguage]
  );
  const isMobile = useIsMobile();

  if (subjectsAreLoading || visitorIsLoading) return <Loader />;

  if (subjectsHasError || routesHaveError) return <Error />;

  return (
    <>
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        className={classes.page}
        variants={GenericPageTransition}
      >
        <div className={classes.body}>
          <div className={classes.introContainer}>
            <div className={classes.headingContainer}>
              {<Text>{generateFilterBreadcrumb()}</Text>}
              <Heading noMargin level={2}>
                {generateMainFilter()}
                {isMobile && (
                  <FilterIcon
                    onClick={() => setFilterModalShowing(true)}
                    className={classes.icon}
                  />
                )}
              </Heading>
              <Text variant="faded">
                {complexTranslate(translate('search.nExperiences').toString(), {
                  '{COUNT}': routes.length,
                })}
              </Text>
            </div>
          </div>

          <div className={classes.listContainer}>
            {isMobile ? (
              <Modal
                footer={null}
                variant="close"
                visible={filterModalShowing}
                onRequestClose={() => setFilterModalShowing(false)}
              >
                <RouteFilterComponent
                  filter={filter}
                  setFilter={setFilter}
                  setPlaceSearchText={setPlaceSearchText}
                  placeSearchText={placeSearchText}
                  language={activeLanguage}
                />
              </Modal>
            ) : (
              <div className={classes.filterContainer}>
                <RouteFilterComponent
                  allOpen
                  filter={filter}
                  setFilter={setFilter}
                  setPlaceSearchText={setPlaceSearchText}
                  placeSearchText={placeSearchText}
                  language={activeLanguage}
                />
              </div>
            )}
            {!routeAreLoading && (
              <div className={classes.gridContainer}>
                <RoutePreviewList
                  wrap
                  data={routes.map(r => ({
                    id: r.id,
                    image: getLocationPhotoUrl(
                      r.locations.map(l => l.photos.map(p => p.id)).flat()[0]
                    ),
                    name: getRouteTitle(r.titles),
                    numberOfRatings: r.numberOfRatings,
                    rating: r.averageRating,
                    place: r.location,
                    price: r.adultPrice === 0 ? r.adultPrice : r.minPrice,
                  }))}
                />
              </div>
            )}
          </div>
        </div>
        {!isMobile && <LinkSection />}
        <CopyrightFooter />
      </motion.div>
    </>
  );
};

export default withLocalize(RouteSearch);
