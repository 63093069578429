import { makeStyles } from '@material-ui/styles';
import message from 'antd/es/message';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { Button } from '../../../../../../components/App/Button';
import { Input } from '../../../../../../components/App/Input';
import { Modal } from '../../../../../../components/App/Modal';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { editVisitorStep } from '../../../../../../hooks/landing/steps/editVisitorStep';
import { insertVisitorStep } from '../../../../../../hooks/landing/steps/insertVisitorStep';
import { GetVisitorStepsResponse } from '../../../../../../hooks/landing/steps/useSteps';
import { ErrorCode } from '../../../../../../types/error.codes.enum';

interface StepModalProps extends LocalizeContextProps {
  visible: boolean;
  languageGrouping?: GetVisitorStepsResponse;
  onAdd?: (v: SetStateAction<GetVisitorStepsResponse>) => void;
  onEdit?: (v: SetStateAction<GetVisitorStepsResponse>) => void;
  onRequestClose: () => void;
}

const useStyles = makeStyles({
  footer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: 20,
  },
});

const StepModal = ({
  languageGrouping,
  visible,
  onAdd,
  onEdit,
  onRequestClose,
  translate,
  languages,
}: StepModalProps) => {
  const [stepInfo, setStepInfo] = useState<
    {
      title: string;
      description: string;
      language: string;
      id?: string;
      position?: number;
    }[]
  >([]);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (!languageGrouping || languageGrouping.length === 0) {
      setStepInfo(
        languages
          .map(l => l.code)
          .map(l => {
            return {
              title: '',
              description: '',
              language: l,
            };
          })
      );
    } else {
      setStepInfo(
        languages
          .map(l => l.code)
          .map(l => {
            const translation = languageGrouping.find(lp => lp.language === l);
            if (translation) {
              return translation;
            }
            return {
              title: '',
              description: '',
              language: l,
              id: undefined,
              position: languageGrouping[0].position,
            };
          })
      );
    }
  }, [languageGrouping, languages]);

  const action = useCallback(async () => {
    try {
      setSubmitting(true);
      if (!languageGrouping) {
        const { data } = await insertVisitorStep({ steps: stepInfo });

        if (onAdd) onAdd(data);
      } else {
        const { data } = await editVisitorStep({
          steps: stepInfo,
        });

        if (onEdit) onEdit(data);
      }
    } catch (err) {
      message.error(
        `${translate(
          `error.API_ERROR.${ErrorCode.LANDING_PAGE_NEED_TRANSLATION}`
        )} ${translate(`languages.${err.response.data.message}`)}`
      );
    } finally {
      setSubmitting(false);
    }
  }, [languageGrouping, onAdd, onEdit, stepInfo, translate]);

  const error = useMemo(() => {
    if (stepInfo.some(sI => !sI.description.trim() || !sI.title.trim())) {
      return translate('adminstration.visitorSteps.textMissing').toString();
    }
  }, [stepInfo, translate]);
  return (
    <Modal
      contentContainerClassName={classes.content}
      footer={
        <div className={classes.footer}>
          <Button disabled={submitting} onClick={onRequestClose}>
            <Translate id="button.cancel" />
          </Button>
          <Button disabled={submitting || !!error} onClick={action}>
            <Translate id="button.save" />
          </Button>
        </div>
      }
      visible={visible}
    >
      <Tabs>
        {stepInfo.map((sI, index) => (
          <TabPane
            key={index}
            tab={<Translate id={`languages.${sI.language}`} />}
          >
            <Input
              value={sI.title}
              onChange={e => {
                e.persist();
                setStepInfo(prev =>
                  prev.map(nSI =>
                    nSI === sI ? { ...nSI, title: e.target.value } : nSI
                  )
                );
              }}
            />
            <AnimatedError />
            <Input
              value={sI.description}
              onChange={e => {
                e.persist();
                setStepInfo(prev =>
                  prev.map(nSI =>
                    nSI === sI ? { ...nSI, description: e.target.value } : nSI
                  )
                );
              }}
            />
          </TabPane>
        ))}
      </Tabs>
      <AnimatedError isVisible={!!error} reason={error} />
    </Modal>
  );
};

export default withLocalize(StepModal);
