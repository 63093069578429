import { makeStyles } from '@material-ui/styles';
import { Space, Table, Tooltip } from 'antd';
import Button from 'antd/es/button';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Badge2 from '../../../../../components/App/Badge2/Badge2';
import { Checkbox } from '../../../../../components/App/Checkbox';
import { Input } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { useListGuides } from '../../../../../hooks/administration/useListGuides';
import { ReactComponent as Canceled } from '../../../../../shared_assets/icons/canceled.svg';
import { ReactComponent as Complete } from '../../../../../shared_assets/icons/complete.svg';
import { GuideStateType } from '../../../../../types/guide-state-type.enum';
import { ListGuidesAdminInterface } from '../../../../../types/list-guide.interface';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  filterZone: {
    '& > *:nth-child(2)': { flexGrow: 1, marginLeft: '1rem' },
    display: 'flex',
  },
});

interface ParamsInterface {
  id: string;
}

const ListIntermediateGuides = ({ translate }: LocalizeContextProps) => {
  const params = useParams<ParamsInterface>();
  const history = useHistory();
  const classes = useStyles();
  const [guides, setListGuides] = useState<ListGuidesAdminInterface[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showOnlyToApprove, setShowOnlyToApprove] = useState<boolean>(false);

  const dto = useMemo(
    () => ({
      search,
      showOnlyToApprove,
      intermediateId: params.id,
    }),
    [search, showOnlyToApprove, params]
  );

  const [fetchingGuides] = useListGuides(setListGuides, dto);

  const createGuideCallback = useCallback(() => {
    history.push(`/admin/area/create-guide/${params.id}`);
  }, [history, params]);
  const columns = useMemo(() => {
    return [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => {
          switch (text) {
            case GuideStateType.APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'success'}
                  text={translate('adminstration.guideApproved').toString()}
                />
              );
            case GuideStateType.NOT_APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideNotApproved').toString()}
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  type={'warning'}
                  text={translate(
                    'adminstration.guideWaitingApproval'
                  ).toString()}
                />
              );
          }
        },

        width: '20%',
      },
      {
        title: <Translate id="adminstration.name" />,
        dataIndex: 'name',
        key: 'name',
      },

      {
        title: <Translate id="adminstration.gender" />,
        dataIndex: 'gender',
        key: 'gender',
      },

      {
        title: <Translate id="adminstration.email" />,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: <Translate id="adminstration.phoneNumber" />,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
      },
      {
        title: <Translate id="adminstration.companyName" />,
        dataIndex: 'companyName',
        key: 'companyName',
      },
      {
        title: <Translate id="adminstration.nif" />,
        dataIndex: 'nif',
        key: 'nif',
      },
      {
        title: <Translate id="adminstration.magnifinanceStatus" />,
        dataIndex: 'partner',
        key: 'nif',
        render: (value: any) => {
          if (!value) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartner" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else if (!value.magnifinanceToken) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartnerToken" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={<Translate id="adminstration.magnifinanceOk" />}>
                <Complete style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          }
        },
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <Link to={`/admin/area/guide/${record.id}`}>
              <Translate id="adminstration.showInformation" />
            </Link>
          </Space>
        ),
      },
    ];
  }, [translate]);
  return (
    <>
      <div className={classes.divAddButton}>
        <div className={classes.filterZone}>
          <Input
            value={search}
            placeholder={translate('adminstration.search').toString()}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <Checkbox
            checked={showOnlyToApprove}
            onChange={e => setShowOnlyToApprove(e.target.checked)}
          >
            <Translate id="adminstration.showOnlyNotApproved" />
          </Checkbox>
        </div>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            createGuideCallback();
          }}
        >
          <Translate id="adminstration.createGuide" />
        </Button>
      </div>

      {fetchingGuides ? (
        <Loader />
      ) : (
        <Table dataSource={guides} columns={columns} />
      )}
    </>
  );
};

export default withLocalize(ListIntermediateGuides);
