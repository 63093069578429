import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HelpModal } from '../../../components/App/HelpModal';
import { RootState } from '../../../store';
import { getTheme, mobilePadding, mobileThreshhold } from '../../../utils';
import { Button } from '../../App/Button';
import { Heading } from '../../Typography';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...mobilePadding,
    justifySelf: 'flex-end',
    flex: 0,
    marginBottom: '2rem',
    '& > div': {
      margin: '1.5rem 0',
      '&:last-child': {
        marginBottom: 0,
      },
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  logoRow: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    maxHeight: 100,
    masWidth: 150,
  },
  noMargin: { margin: 0, lineHeight: 'unset ! important' },
  marginBetween: {
    margin: '1.5rem 0',
  },
  title: {
    fontWeight: '600 !important' as unknown as number,
  },
  links: () => ({
    '& *': {
      color: `${getTheme().primary} !important`,
      textTransform: 'initial',
    },
  }),
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      '& > div': {
        margin: 0,
      },
    },
  },
});

function LinkSection({ translate }: LocalizeContextProps) {
  const [openHelpModalFlag, setOpenHelpModalFlag] = useState(false);
  const lastAppZone = useSelector((state: RootState) => state.app.lastAppZone);
  const openHelpModal = useCallback(() => {
    setOpenHelpModalFlag(!openHelpModalFlag);
  }, [openHelpModalFlag]);
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div>
          <Heading level={5} className={classes.title}>
            {translate('landing.uniquepaths').toString()}
          </Heading>
          <div className={classes.links}>
            <Link to="/about">
              <Heading level={5}>
                {translate('landing.whoWeAre').toString()}
              </Heading>
            </Link>
            <Link
              to={{ pathname: `/${lastAppZone}/landing`, hash: 'how-it-works' }}
            >
              <Heading level={5}>
                {translate('landing.howItWorks').toString()}
              </Heading>
            </Link>
            <Link to="/where-we-are">
              <Heading level={5}>
                {translate('landing.whereAreWe').toString()}
              </Heading>
            </Link>
            <Link to="/contacts">
              <Heading level={5}>
                {translate('landing.contacts').toString()}
              </Heading>
            </Link>
          </div>
        </div>
        <div>
          <Heading level={5} className={classes.title}>
            {translate('landing.support').toString()}
          </Heading>
          <div className={classes.links}>
            {lastAppZone === 'guide' ? (
              <>
                <Button key="help" type="link" onlyText onClick={openHelpModal}>
                  <Heading level={5}>
                    <Translate id="landing.help" />
                  </Heading>
                </Button>
                <HelpModal
                  visible={openHelpModalFlag}
                  onRequestClose={() => {
                    setOpenHelpModalFlag(false);
                  }}
                />
              </>
            ) : null}
            {lastAppZone === 'visitor' ? (
              <>
                <Link to="/guide">
                  <Heading level={5}>
                    {translate('landing.beAGuideInUniquePaths').toString()}
                  </Heading>
                </Link>
                <Link to="/terms-of-service#visitor">
                  <Heading level={5}>
                    {translate('landing.responsibilitiesAsAVisitor').toString()}
                  </Heading>
                </Link>
              </>
            ) : lastAppZone === 'guide' ? (
              <>
                <Link to="/visitor">
                  <Heading level={5}>
                    {translate('landing.beAVisitorInUniquePaths').toString()}
                  </Heading>
                </Link>
                <Link to="/terms-of-service#guide">
                  <Heading level={5}>
                    {translate('landing.responsibilitiesAsAGuide').toString()}
                  </Heading>
                </Link>
                <Link to="/company">
                  <Heading level={5}>
                    {translate('landing.companyRedirect').toString()}
                  </Heading>
                </Link>
              </>
            ) : null}
          </div>
        </div>
        <div>
          <Heading level={5} className={classes.title}>
            {translate('landing.financedBy').toString()}
          </Heading>
          <img
            src={require('./assets/turismo_portugal.svg')}
            alt="logo"
            className={classes.logo}
          />
        </div>
        <div>
          <Heading level={5} className={classes.title}>
            {translate('landing.thisIsARegisteredTrademark').toString()}
          </Heading>
          <img
            src={require('./assets/municipia.svg')}
            alt="logo"
            className={classes.logo}
          />
        </div>
      </div>
    </>
  );
}

export default withLocalize(LinkSection);
