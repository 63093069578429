import { message, Table } from 'antd';
import Button from 'antd/es/button';
import { TableRowSelection } from 'antd/es/table/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Error } from '../../../../../components/App/Error';
import { Loader } from '../../../../../components/App/Loader';
import { useSubjectsAdmin } from '../../../../../hooks/administration/useSubjectsAdmin';
import { editHighlightedSubjects } from '../../../../../hooks/subjects/editHighlightedSubjects';
import { RootState } from '../../../../../store';
import { RouteSubject } from '../../../../../types/route-subject.interface';

const SubjectHighlights = ({ translate }: LocalizeContextProps) => {
  const [subjects, setSubjects] = useState<RouteSubject[]>([]);

  const [isLoading, isError] = useSubjectsAdmin(setSubjects);

  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const subjectsForDisplay = useMemo(
    () =>
      subjects.map(s => ({
        name:
          s.translations?.find(t => t.language === activeLanguage)
            ?.translation || '',
        key: s.id.toString(),
      })),
    [subjects, activeLanguage]
  );

  const columns = useMemo(
    () => [
      {
        title: 'Nome',
        dataIndex: 'name',
      },
    ],
    []
  );
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const rowSelection: TableRowSelection<{
    name: string;
    key: string;
  }> = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        if (selectedRowKeys.length <= 6) {
          setSelectedKeys(selectedRowKeys);
        }
      },

      type: 'checkbox',
      selectedRowKeys: selectedKeys,
      hideSelectAll: true,
      getCheckboxProps: record => {
        if (selectedKeys.length >= 6) {
          if (!selectedKeys.includes(record.key)) {
            return {
              disabled: true,
            };
          }
        }

        return {};
      },
    }),
    [selectedKeys]
  );

  useEffect(() => {
    setSelectedKeys(subjects.filter(s => s.highlighted).map(s => s.id));
  }, [subjects]);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const updateHighlightedSubjects = useCallback(async () => {
    try {
      setSubmitting(true);

      await editHighlightedSubjects({
        ids: selectedKeys.map(k => k.toString()),
      });
      message.success(
        translate('adminstration.subjectManagement.highlightSuccess').toString()
      );
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [selectedKeys, translate]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={subjectsForDisplay}
        rowSelection={rowSelection}
      />
      <AnimatedError
        isVisible={selectedKeys.length !== 6}
        reason={
          <Translate id="adminstration.subjectManagement.highlightError" />
        }
      />
      <Button disabled={submitting} onClick={updateHighlightedSubjects}>
        <Translate id="button.save" />
      </Button>
    </>
  );
};

export default withLocalize(SubjectHighlights);
