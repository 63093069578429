import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

export type AdvertiserResponse = {
  name: string;
  phoneNumber: string;
  url: string;
  address: string;
  email: string;
  id: string;
}[];

const fetchAdvertisers = () =>
  Axios.get<AdvertiserResponse>(`${API_URL}/publicity`);

type Hook = (
  setter: (v: SetStateAction<AdvertiserResponse>) => void
) => [boolean, boolean];

export const useAdvertiser: Hook = setAdvertiser => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await fetchAdvertisers();
        setAdvertiser(data);
      } catch (err) {
        setAdvertiser([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setAdvertiser]);

  return [isLoading, isError];
};
export const getAdvertiserImageUrl = (id: string) =>
  `${API_URL}/publicity/${id}/ad-owner-image?_=${moment().unix()}`;
