import { makeStyles } from '@material-ui/styles';
import Empty from 'antd/es/empty';
import React from 'react';
import { Translate } from 'react-localize-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIsMobile } from '../../../utils';
import RoutePreview, { RoutePreviewInterface } from './RoutePreview';

export interface RoutePreviewListProps {
  data: RoutePreviewInterface[];
  wrap?: boolean;
}

const useStyles = makeStyles({
  routePreviewContainer: {
    display: 'flex',
    padding: '10px 10px',
    marginTop: '1em',
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
    '& > *': {
      maxWidth: 180,
      minWidth: 180,
    },
  },
  wrappingContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(160px, 1fr))`,
    gridAutoRows: '1fr',
    rowGap: '1rem',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  scroller: {
    overflowX: 'auto',
  },
});

function RoutePreviewList({ data, wrap }: RoutePreviewListProps) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const list = (
    <div
      className={
        wrap
          ? classes.wrappingContainer
          : data.length > 0
          ? classes.routePreviewContainer
          : ''
      }
    >
      {data.length > 0 ? (
        data.map((preview, i) => (
          <RoutePreview key={i.toString()} {...preview} />
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<Translate id="routeDetails.dontExistRoutes" />}
        />
      )}
    </div>
  );

  if (!data) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Translate id="routeDetails.dontExistRoutes" />}
      />
    );
  }

  return !isMobile ? (
    <PerfectScrollbar>{list}</PerfectScrollbar>
  ) : (
    <div className={classes.scroller}>{list}</div>
  );
}

export default React.memo(RoutePreviewList);
