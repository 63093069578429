import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { ScheduleDateBookings } from '../../types/ScheduleDateBookings.interface';

type Hook = (
  repetitionId: string | undefined,
  setScheduleBookings: (
    val: SetStateAction<ScheduleDateBookings | null>
  ) => void
) => [boolean, boolean];

const requestRouteBookings = (repetitionId: string) =>
  Axios.get<ScheduleDateBookings>(
    `${API_URL}/schedule/repetition/${repetitionId}/bookings`
  );

export const useGuideScheduleBookings: Hook = (
  repetitionId,
  setScheduleBookings
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!repetitionId) {
        setIsError(true);
        setScheduleBookings(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: scheduleBookings } = await requestRouteBookings(
          repetitionId
        );
        setScheduleBookings(scheduleBookings);
      } catch (err) {
        setScheduleBookings(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [repetitionId, setScheduleBookings]);

  return [isLoading, isError];
};
