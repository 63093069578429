import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouteDetails } from '.';
import { RouteSearch } from './RouteSearch';

interface RouteRouterProps {
  openLogin: () => void;
}

export default function RouteRouter({ openLogin }: RouteRouterProps) {
  return (
    <Switch>
      <Route path="/route/search" exact component={RouteSearch} />
      <Route
        path="/route/:routeId"
        exact
        render={() => <RouteDetails openLogin={openLogin} />}
      />
      <Redirect to="/visitor/landing" />
    </Switch>
  );
}
