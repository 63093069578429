import Axios from 'axios';

import { API_URL } from '../../consts';

interface CreateThemeDto {
  primary: string;

  secondary: string;

  hostname: string;

  logo: File;

  logoWhite: File;
}

export const createTheme = (dto: CreateThemeDto) => {
  const data = new FormData();

  data.append('createThemeDto', JSON.stringify(dto));

  data.append('logo', dto.logo);
  data.append('whiteLogo', dto.logoWhite);

  return Axios.post(`${API_URL}/content-management/theme`, data);
};
