import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../components/App/Button';
import { CopyrightFooter } from '../../../../../components/App/CopyrightFooter';
import { Heading, Text } from '../../../../../components/Typography';
import { useCut } from '../../../../../hooks/visitor/booking/cancelBooking';
import { ReactComponent as Complete } from '../../../../../shared_assets/icons/complete.svg';
import {
  complexTranslate,
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  titleContainer: {
    display: 'flex',
    padding: '1rem 0',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    ...mobilePadding,
  },
  icon: {
    height: '2.5rem',
    width: '2.5rem',
    flexShrink: 0,
  },
  button: {
    alignSelf: 'flex-start',
    marginTop: '2rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      ...desktopPadding,
    },
    titleContainer: {
      justifyContent: 'flex-start',
    },
    icon: {
      marginLeft: '1rem',
    },
  },
});

const BookingCancelConfirmed = ({ translate }: LocalizeContextProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [percent, setPercent] = useState<number | null>(null);
  useCut(setPercent);

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Heading level={2} noMargin>
            <Translate id="booking.cancellationConfirmed" />
          </Heading>
          <Complete className={classes.icon} />
        </div>
        <Text variant="faded">
          {complexTranslate(
            translate('booking.cancellationConfirmedText').toString(),
            {
              '{PERCENT}': percent,
            }
          )}
        </Text>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            history.goBack();
          }}
          className={classes.button}
        >
          <Translate id="button.backToBookingsPage" />
        </Button>
      </div>
      <CopyrightFooter />
    </motion.div>
  );
};

export default withLocalize(BookingCancelConfirmed);
