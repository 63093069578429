import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CopyrightFooter } from '../../components/App/CopyrightFooter';
import { LinkSection } from '../../components/App/LinkSection';
import { Loader } from '../../components/App/Loader';
import { MemberCard } from '../../components/App/MemberCard';
import { Heading } from '../../components/Typography';
import {
  AboutTranslation,
  useAboutTranslations,
} from '../../hooks/About/useAboutTranslations';
import { getHeaderImageUrl } from '../../hooks/administration/useAbout';
import {
  getMemberImage,
  MemberInterface,
} from '../../hooks/administration/useListAdminMembers';
import { useMembers } from '../../hooks/Member/useMember';
import { RootState } from '../../store';
import {
  desktopPadding,
  getTheme,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
} from '../../utils';
import { GenericPageTransition } from '../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  introZone: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 0 2vh 2vh',
    flexGrow: 1,
    backgroundImage: `url(${getHeaderImageUrl()})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '65vh',
    '& img, & picture, & svg': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
    '& > .text-zone': {
      marginTop: headerHeight,
      backgroundColor: getTheme().shadowBackground,
      padding: '1rem',
      '& *': {
        color: 'white !important',
      },
    },
  },

  bottomDivider: {
    margin: '1rem 0 0 0',
  },

  extraPadding: {
    paddingTop: '3rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      ...desktopPadding,
      alignItems: 'center',
    },
    body: {
      maxWidth: '50%',
    },
    extraPadding: {
      margin: '0 25%',
    },
    stepsContainer: {
      display: 'flex',
    },
    introZone: {
      '& > .text-zone': {
        alignSelf: 'center',
        borderRadius: 16,
      },
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
});

interface RouteState {
  redirectTo?: string;
}

const ValidRedirections = ['/guide/landing', '/visitor/landing'];

interface AboutProps extends LocalizeContextProps {}

const WhereWeAre = () => {
  const classes = useStyles();
  const location = useLocation<RouteState | undefined>();
  const [memberList, setListMembers] = useState<MemberInterface[]>([]);
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [translations, setTranslations] = useState<AboutTranslation | null>(
    null
  );
  const [fetchingTranslations, errorFetchingTranslations] =
    useAboutTranslations(activeLanguage, setTranslations);
  const [fetchingMembers, errorFetchingMembers] = useMembers(setListMembers);
  if (
    !location.state ||
    !location.state.redirectTo ||
    !ValidRedirections.includes(location.state.redirectTo)
  ) {
    //This cannot be in the state since it will come from an outside source
    //history.goBack();
  }
  if (fetchingTranslations || !translations || fetchingMembers)
    return <Loader />;
  if (errorFetchingTranslations || errorFetchingMembers) return <Loader />;
  return (
    <motion.div
      className={classes.page}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <div className={classes.introZone}></div>
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.title}>
            <Heading level={2}>
              <Translate id="whereWeAre.title" />
            </Heading>
          </div>

          <Divider></Divider>

          {memberList.map(ml => (
            <>
              <MemberCard
                name={ml.name}
                key={ml.id}
                url={ml.url}
                uniquePathUrl={ml.uniquepathUrl}
                image={getMemberImage(ml.id)}
              ></MemberCard>
              <Divider></Divider>
            </>
          ))}
        </div>
      </div>

      <Divider />
      <LinkSection />
      <Divider className={classes.bottomDivider} />
      <div style={{ flex: 1 }}></div>
      <CopyrightFooter />
    </motion.div>
  );
};

export default withLocalize(WhereWeAre);
