import { makeStyles } from '@material-ui/styles';
import { Space, Table } from 'antd';
import Divider from 'antd/es/divider';
import React, { useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Button } from '../../../../../../components/App/Button';
import { Modal } from '../../../../../../components/App/Modal';
import { deleteVisitorStep } from '../../../../../../hooks/landing/steps/deleteVisitorStep';
import {
  GetVisitorStepsResponse,
  useVisitorSteps,
} from '../../../../../../hooks/landing/steps/useSteps';
import { RootState } from '../../../../../../store';
import StepModal from './StepModal';

const useStyles = makeStyles({
  footer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: 20,
  },
});

const StepList = () => {
  const [visitorSteps, setVisitorSteps] = useState<GetVisitorStepsResponse>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading] = useVisitorSteps(setVisitorSteps);
  const [editing, setEditing] = useState<number | undefined>();
  const [removing, setRemoving] = useState<number | undefined>();
  const [adding, setAdding] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const classes = useStyles();

  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );

  const group = useMemo(() => {
    const positions: number[] = [];

    visitorSteps.forEach(step => {
      if (!positions.includes(step.position)) {
        positions.push(step.position);
      }
    });

    return positions.sort().map(p => {
      return (
        visitorSteps.find(
          step => step.language === activeLanguage && step.position === p
        ) || {
          description: '',
          language: activeLanguage,
          position: p,
          title: '',
          id: '',
        }
      );
    });
  }, [activeLanguage, visitorSteps]);
  const onDelete = useCallback(async () => {
    try {
      setSubmitting(true);
      if (removing) {
        const { data } = await deleteVisitorStep({ position: removing });
        setVisitorSteps(data);
        setRemoving(undefined);
      }
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [removing]);

  const columns = useMemo(
    () => [
      {
        title: <Translate id="adminstration.visitorSteps.position" />,
        dataIndex: 'position',
        key: 'position',
      },
      {
        title: <Translate id="adminstration.visitorSteps.stepTitle" />,
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: <Translate id="adminstration.visitorSteps.stepDescription" />,
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <StepModal
              onRequestClose={() => setEditing(undefined)}
              visible={editing === record.position}
              languageGrouping={visitorSteps.filter(
                step => step.position === editing
              )}
              onEdit={v => {
                setVisitorSteps(v);
                setEditing(undefined);
              }}
            />
            <Button
              type="link"
              onClick={() => {
                setEditing(record.position);
              }}
            >
              <Translate id="adminstration.visitorSteps.edit" />
            </Button>
            <Divider type="vertical" />
            <Modal
              onRequestClose={() => setRemoving(undefined)}
              contentContainerClassName={classes.content}
              footer={
                <div className={classes.footer}>
                  <Button
                    type="primary"
                    disabled={submitting}
                    onClick={onDelete}
                  >
                    <Translate id="button.save" />
                  </Button>
                  <Button
                    disabled={submitting}
                    type="primary"
                    onClick={() => setRemoving(undefined)}
                  >
                    <Translate id="button.cancel" />
                  </Button>
                </div>
              }
              visible={removing === record.position}
            >
              <Translate id="adminstration.visitorSteps.removeConfirmation" />
            </Modal>
            <Button
              type="link"
              onClick={() => {
                setRemoving(record.position);
              }}
            >
              <Translate id="adminstration.visitorSteps.remove" />
            </Button>
          </Space>
        ),
      },
    ],
    [
      classes.content,
      classes.footer,
      editing,
      onDelete,
      removing,
      submitting,
      visitorSteps,
    ]
  );
  return (
    <>
      <Button
        onClick={() => {
          setAdding(true);
        }}
      >
        <Translate id="adminstration.visitorSteps.create" />
      </Button>
      <StepModal
        onRequestClose={() => setAdding(false)}
        visible={adding}
        onAdd={v => {
          setVisitorSteps(v);
          setAdding(false);
        }}
      />
      <Table columns={columns} dataSource={group} />
    </>
  );
};

export default React.memo(StepList);
