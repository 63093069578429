import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';

export interface TestimonyEntryInterface {
  testimonyId: string;
  visitorId: string;
  visitorName: string;
  routeLocation: string;
  dateCreation: Date;
  description: string;
  rating: number;
}

type Hook = (
  setListGuides: (val: SetStateAction<TestimonyEntryInterface[]>) => void
) => [boolean, boolean];

const requestAdmin = () =>
  Axios.get<TestimonyEntryInterface[]>(`${API_URL}/review`);

export const useTestimoniesList: Hook = setListTestimony => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestAdmin();
        setListTestimony(list);
        setIsError(false);
      } catch (err) {
        setListTestimony([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setListTestimony]);

  return [isLoading, isError];
};
