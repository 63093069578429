import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { GenderType } from '../../types/gender-type.enum';
export interface IntermediateInterface {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  dateOfBirth: Date;
  phoneNumber: string;
  location: string;
  nif: string;
  address: string;
  iban: string;
  nationality: string;
  postalCode: string;
  gender: GenderType;
  email: string;
}

type Hook = (
  id: string | undefined,
  setIntermediate: (val: SetStateAction<IntermediateInterface | null>) => void
) => [boolean, boolean];

const requestIntermediate = (id: string) =>
  Axios.get<IntermediateInterface>(`${API_URL}/intermediate/${id}`);

export const useIntermediate: Hook = (id, setIntermediate) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!id) {
        setIsError(true);
        setIntermediate(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: guide } = await requestIntermediate(id);
        setIntermediate(guide);
        setIsError(false);
      } catch (err) {
        setIntermediate(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, dispatch, setIntermediate]);

  return [isLoading, isError];
};
