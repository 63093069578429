import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import validator from 'validator';
import { store } from '../../../store/index';
import { FormEntry, SettableFormEntry } from '../../../types/form.interface';
import { Guide } from '../../../types/guide.interface';

type PublicProfileProperties = 'firstName' | 'lastName';

export type PublicProfileForm = Record<
  PublicProfileProperties,
  SettableFormEntry
>;

export const usePublicProfileForm = (
  guide?: Guide | null
): PublicProfileForm => {
  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const [firstName, setFirstName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.firstNameMandatory').toString(),
  });

  const [lastName, setLastName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('onboarding.lastNameMandatory').toString(),
  });

  const setValidatedFirstName = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.firstNameMandatory').toString();
      }

      setFirstName({ value, isInvalid, reason });
    },
    [setFirstName, translate]
  );

  const setValidatedLastName = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.lastNameMandatory').toString();
      }

      setLastName({ value, isInvalid, reason });
    },
    [setLastName, translate]
  );

  useEffect(() => {
    if (!guide) return;
    setValidatedFirstName(guide.firstName);
    setValidatedLastName(guide.lastName);
  }, [guide, setValidatedFirstName, setValidatedLastName]);

  return {
    firstName: {
      ...firstName,
      set: setValidatedFirstName,
    },
    lastName: {
      ...lastName,
      set: setValidatedLastName,
    },
  };
};
