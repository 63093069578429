import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { Button as LinkButton } from '../../../../../../components/App/Button';
import { FallbackImage } from '../../../../../../components/App/Image';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { Text } from '../../../../../../components/Typography';
import {
  AboutInterface,
  getHeaderImageUrl,
  getImage1Url,
  getImage2Url,
  useAboutLandingPage,
} from '../../../../../../hooks/administration/useAbout';
import { postAbout } from '../../../../../../hooks/administration/usePostAbout';
import { UseJWT } from '../../../../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../../../../hooks/useFilePreview';
import { ReactComponent as Pediguia } from '../../../../../../shared_assets/images/pediguia_default.svg';
import { complexTranslate, maxAllowedImageSize } from '../../../../../../utils';
import { GenericPageTransition } from '../../../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  divider: {
    margin: '1rem 0',
  },
  titleDiv: {
    display: 'flex',
    margin: '1rem',
    justifyContent: 'center',
  },
  avatarArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '1rem',
    flex: 1,
  },
  buttonArea: {
    margin: '1rem',
  },
  avatarPreview: {
    maxHeight: '340px',
    maxWidth: '100%',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    justifyContent: 'center',
  },
  button: {
    marginLeft: '1rem',
  },
  tabContent: {
    overflow: 'auto',
  },
  imgDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  imgDivContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

interface AboutLandingPageProps extends LocalizeContextProps {}
const AboutLandingPage = ({ languages, translate }: AboutLandingPageProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();
  //HEADER IMAGE
  const imageInputRefHeader = useRef<HTMLInputElement>(null);
  const [imageHeader, setImageHeader] = useState<File | null>(null);
  const previewSourceHeader = useMemo(() => [imageHeader], [imageHeader]);
  const imagePreviewHeader = useFilePreview(previewSourceHeader);

  // SECOND IMAGE

  const imageInputRefInformation1 = useRef<HTMLInputElement>(null);
  const [imageInformation1, setImageInformation1] = useState<File | null>(null);
  const previewSourceInformation1 = useMemo(
    () => [imageInformation1],
    [imageInformation1]
  );
  const imagePreviewInformation1 = useFilePreview(previewSourceInformation1);

  // THIRD IMAGE
  const imageInputRefInformation2 = useRef<HTMLInputElement>(null);
  const [imageInformation2, setImageInformation2] = useState<File | null>(null);
  const previewSourceInformation2 = useMemo(
    () => [imageInformation2],
    [imageInformation2]
  );
  const imagePreviewInformation2 = useFilePreview(previewSourceInformation2);

  const [landingPage, setLandingPage] = useState<AboutInterface[]>([]);
  const [landingPageLocal, setLandingPageLocal] = useState<AboutInterface[]>(
    []
  );

  useAboutLandingPage(setLandingPage);

  useEffect(() => {
    setLandingPageLocal(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = landingPage.find(lp => lp.language === l);
          if (translation) {
            return translation;
          }
          return {
            firstTitle: '',
            intro: '',
            whoWeAreTitle: '',
            whoWeAreText: '',
            whyUniquePathsTitle: '',
            argument0Title: '',
            argument0Text: '',
            argument1Title: '',
            argument1Text: '',
            argument2Title: '',
            argument2Text: '',
            information0Title: '',
            information0Text: '',
            information0RedirectUrl: '',
            information1Title: '',
            information1Text: '',
            information1RedirectUrl: '',
            footerTitle: '',
            footerText: '',
            footerButtonText: '',
            footerButtonRedirect: '',
            language: l,
          };
        })
    );
  }, [landingPage, languages]);
  const submit = useCallback(async () => {
    if (
      landingPageLocal.some(
        s =>
          //validator.isEmpty(s.firstTitle) ||
          validator.isEmpty(s.language) ||
          //validator.isEmpty(s.intro) ||
          validator.isEmpty(s.whoWeAreTitle) ||
          validator.isEmpty(s.whoWeAreText) ||
          validator.isEmpty(s.whyUniquePathsTitle) ||
          validator.isEmpty(s.argument0Title) ||
          validator.isEmpty(s.argument0Text) ||
          validator.isEmpty(s.argument1Title) ||
          validator.isEmpty(s.argument1Text) ||
          validator.isEmpty(s.argument2Title) ||
          validator.isEmpty(s.argument2Text) ||
          validator.isEmpty(s.information0Title) ||
          validator.isEmpty(s.information0Text) ||
          validator.isEmpty(s.information0RedirectUrl) ||
          validator.isEmpty(s.information1Title) ||
          validator.isEmpty(s.information1Text) ||
          validator.isEmpty(s.information1RedirectUrl) ||
          validator.isEmpty(s.footerTitle) ||
          validator.isEmpty(s.footerText) ||
          validator.isEmpty(s.footerButtonText) ||
          validator.isEmpty(s.footerButtonRedirect)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    await postAbout(
      {
        about: landingPageLocal.map(fs => {
          return {
            firstTitle: fs.firstTitle,
            intro: fs.intro,
            whoWeAreTitle: fs.whoWeAreTitle,
            whoWeAreText: fs.whoWeAreText,
            argument0Title: fs.argument0Title,
            argument0Text: fs.argument0Text,
            argument1Title: fs.argument1Title,
            argument1Text: fs.argument1Text,
            argument2Title: fs.argument2Title,
            argument2Text: fs.argument2Text,
            information0Title: fs.information0Title,
            information0Text: fs.information0Text,
            information0RedirectUrl: fs.information0RedirectUrl,
            information1Title: fs.information1Title,
            information1Text: fs.information1Text,
            information1RedirectUrl: fs.information1RedirectUrl,
            footerTitle: fs.footerTitle,
            footerText: fs.footerText,
            footerButtonText: fs.footerButtonText,
            footerButtonRedirect: fs.footerButtonRedirect,
            whyUniquePathsTitle: fs.whyUniquePathsTitle,
            language: fs.language,
          };
        }),
      },
      imageHeader,
      imageInformation1,
      imageInformation2
    );
    message.success(
      translate('adminstration.guideSelection.success').toString()
    );
  }, [
    landingPageLocal,
    imageHeader,
    imageInformation1,
    imageInformation2,
    translate,
  ]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.imgDivContainer}>
        {/* IMAGE HEADER */}
        <div className={classes.imgDiv}>
          <div className={classes.titleDiv}>
            <Text noMargin weight="semibold">
              <Translate id="adminstration.aboutPage.headerImage" />
            </Text>
          </div>
          <div className={classes.avatarArea}>
            <FallbackImage
              className={classes.avatarPreview}
              src={imagePreviewHeader?.[0] || getHeaderImageUrl()}
              fallbackComponent={<Pediguia className={classes.avatarPreview} />}
            />
          </div>
          <div className={classes.changeAvatarButton}>
            <Translate id="adminstration.changeSubjectImage" />
            <input
              className={classes.button}
              type="file"
              accept="image/*"
              ref={imageInputRefHeader}
              onChange={ev => {
                if (
                  ev.currentTarget.files &&
                  ev.currentTarget.files.length > 0 &&
                  ev.currentTarget.files[0].size < maxAllowedImageSize
                ) {
                  setImageHeader(ev.currentTarget.files[0]);
                } else {
                  message.info(
                    complexTranslate(
                      translate('error.imageFileSize').toString(),
                      {
                        '{linkTinyPNG}': (
                          <a key={'tinypnglink'} href="https://tinypng.com/">
                            <LinkButton
                              key="1"
                              type="link"
                              className={classNames(
                                classes.noMargin,
                                classes.inlineBtn
                              )}
                              onlyText
                            >
                              {translate('error.linkTinyPNG').toString()}
                            </LinkButton>
                          </a>
                        ),
                      }
                    )
                  );
                }
              }}
            />
          </div>
        </div>
        {/* IMAGE 1 */}
        <div className={classes.imgDiv}>
          <div className={classes.titleDiv}>
            <Text noMargin weight="semibold">
              <Translate id="adminstration.aboutPage.image1" />
            </Text>
          </div>
          <div className={classes.avatarArea}>
            <FallbackImage
              className={classes.avatarPreview}
              src={imagePreviewInformation1?.[0] || getImage1Url()}
              fallbackComponent={<Pediguia className={classes.avatarPreview} />}
            />
          </div>
          <div className={classes.changeAvatarButton}>
            <Translate id="adminstration.changeSubjectImage" />
            <input
              className={classes.button}
              type="file"
              accept="image/*"
              ref={imageInputRefInformation1}
              onChange={ev => {
                if (
                  ev.currentTarget.files &&
                  ev.currentTarget.files.length > 0 &&
                  ev.currentTarget.files[0].size < maxAllowedImageSize
                ) {
                  setImageInformation1(ev.currentTarget.files[0]);
                } else {
                  message.info(
                    complexTranslate(
                      translate('error.imageFileSize').toString(),
                      {
                        '{linkTinyPNG}': (
                          <a key={'tinypnglink'} href="https://tinypng.com/">
                            <LinkButton
                              key="1"
                              type="link"
                              className={classNames(
                                classes.noMargin,
                                classes.inlineBtn
                              )}
                              onlyText
                            >
                              {translate('error.linkTinyPNG').toString()}
                            </LinkButton>
                          </a>
                        ),
                      }
                    )
                  );
                }
              }}
            />
          </div>
        </div>
        {/* IMAGE 2 */}
        <div className={classes.imgDiv}>
          <div className={classes.titleDiv}>
            <Text noMargin weight="semibold">
              <Translate id="adminstration.aboutPage.image2" />
            </Text>
          </div>
          <div className={classes.avatarArea}>
            <FallbackImage
              className={classes.avatarPreview}
              src={imagePreviewInformation2?.[0] || getImage2Url()}
              fallbackComponent={<Pediguia className={classes.avatarPreview} />}
            />
          </div>
          <div className={classes.changeAvatarButton}>
            <Translate id="adminstration.changeSubjectImage" />
            <input
              className={classes.button}
              type="file"
              accept="image/*"
              ref={imageInputRefInformation2}
              onChange={ev => {
                if (
                  ev.currentTarget.files &&
                  ev.currentTarget.files.length > 0 &&
                  ev.currentTarget.files[0].size < maxAllowedImageSize
                ) {
                  setImageInformation2(ev.currentTarget.files[0]);
                } else {
                  message.info(
                    complexTranslate(
                      translate('error.imageFileSize').toString(),
                      {
                        '{linkTinyPNG}': (
                          <a key={'tinypnglink'} href="https://tinypng.com/">
                            <LinkButton
                              key="1"
                              type="link"
                              className={classNames(
                                classes.noMargin,
                                classes.inlineBtn
                              )}
                              onlyText
                            >
                              {translate('error.linkTinyPNG').toString()}
                            </LinkButton>
                          </a>
                        ),
                      }
                    )
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <Tabs type="card" className={classes.tabContent}>
        {landingPageLocal.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.firstTitle" />
              </Text>
              <Input
                value={state.firstTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          firstTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>

              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.intro" />
              </Text>
              <Input
                value={state.intro}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          intro: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>

              {/* *******************ANCHOR experiencesTitle *********************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.whoWeAreTitle" />
              </Text>
              <Input
                value={state.whoWeAreTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          whoWeAreTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.whoWeAreTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ***************ANCHOR featuredRoutesTitle*********************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.whoWeAreText" />
              </Text>
              <Input
                value={state.whoWeAreText}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          whoWeAreText: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.whoWeAreText)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* *************ANCHOR interestTitle **************************** */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.whyUniquePathsTitle" />
              </Text>
              <Input
                value={state.whyUniquePathsTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          whyUniquePathsTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.whyUniquePathsTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* *************ANCHOR interestTitle **************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.argument0Title" />
              </Text>
              <Input
                value={state.argument0Title}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          argument0Title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.argument0Title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* *************ANCHOR interestTitle **************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.argument0Text" />
              </Text>
              <Input
                value={state.argument0Text}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          argument0Text: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.argument0Text)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* *************ANCHOR joinCommunityTitle ************************* */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.argument1Title" />
              </Text>
              <Input
                value={state.argument1Title}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          argument1Title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.argument1Title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* *****************ANCHOR call to action text************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.argument1Text" />
              </Text>
              <Input
                value={state.argument1Text}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          argument1Text: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.argument1Text)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.argument2Title" />
              </Text>
              <Input
                value={state.argument2Title}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          argument2Title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.argument2Title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.argument2Text" />
              </Text>
              <Input
                value={state.argument2Text}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          argument2Text: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.argument2Text)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.information0Title" />
              </Text>
              <Input
                value={state.information0Title}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          information0Title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.information0Title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.information0Text" />
              </Text>
              <Input
                value={state.information0Text}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          information0Text: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.information0Text)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.information0RedirectUrl" />
              </Text>
              <Input
                value={state.information0RedirectUrl}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          information0RedirectUrl: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.information0RedirectUrl)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.information1Title" />
              </Text>
              <Input
                value={state.information1Title}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          information1Title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.information1Title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.information1Text" />
              </Text>
              <Input
                value={state.information1Text}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          information1Text: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.information1Text)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.information1RedirectUrl" />
              </Text>
              <Input
                value={state.information1RedirectUrl}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          information1RedirectUrl: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.information1RedirectUrl)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.footerTitle" />
              </Text>
              <Input
                value={state.footerTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          footerTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.footerTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.footerText" />
              </Text>
              <Input
                value={state.footerText}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          footerText: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.footerText)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.footerButtonText" />
              </Text>
              <Input
                value={state.footerButtonText}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          footerButtonText: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.footerButtonText)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.footerButtonRedirect" />
              </Text>
              <Input
                value={state.footerButtonRedirect}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          footerButtonRedirect: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.footerButtonRedirect)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
            </TabPane>
          );
        })}
      </Tabs>
      <Divider className={classes.divider} />
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </motion.div>
  );
};

export default withLocalize(AboutLandingPage);
