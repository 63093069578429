import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../../consts';
import { BookingState } from '../../../types/booking-state.enum';
import { ScheduleRepetitionStateType } from '../../../types/schedule-repetition-state-type.enum';

type Hook = (
  visitorId: string | undefined,
  setBookings: (val: SetStateAction<VisitorBookingInterface[]>) => void,
  filter?: VisitorBookingFilter
) => [boolean, boolean];

export interface VisitorBookingInterface {
  id: string;
  groupSize: number;
  price: number;
  state: BookingState;
  routeId: string;
  routeTitle: string;
  locationPhoto: string;
  repetitionDate: Date;
  reviewId: string | null;
}

interface VisitorBookingFilter {
  bookingStates: BookingState[];
  repetitionState: ScheduleRepetitionStateType[];
}

export const requestVisitorBookings = (
  visitorId: string,
  dto?: VisitorBookingFilter
) =>
  Axios.get<VisitorBookingInterface[]>(
    `${API_URL}/booking/visitor/${visitorId}`,
    { params: dto }
  );

export const useVisitorBookings: Hook = (visitorId, setBookings, filter) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!visitorId) {
        setIsError(true);
        setBookings([]);
        setIsLoading(false);

        return;
      }

      try {
        const { data } = await requestVisitorBookings(visitorId, filter);
        setBookings(data);
        setIsError(false);
      } catch (err) {
        setBookings([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [visitorId, setBookings, filter]);

  return [isLoading, isError];
};
