import Axios from 'axios';
import { API_URL } from '../../../consts';
import { ErrorCode } from '../../../types/error.codes.enum';
import message from 'antd/es/message';
import { getTranslate } from 'react-localize-redux';
import { store } from '../../../store/index';
import { Review } from '../../../types/review.interface';

interface CreateReviewDto {
  bookingId: string;
  rating: number;
  description?: string;
}

export const submitReview = (dto: CreateReviewDto) =>
  Axios.post<Review>(`${API_URL}/review`, dto).catch(err => {
    const translate = getTranslate(store.getState().localize);

    if (err.response.data.error === ErrorCode.BOOKING_ALREADY_REVIEWED) {
      message.error(translate('reviews.alreadyReviewedRoute'));
    } else if (err.response.status === 403) {
      message.error(translate('reviews.routeNotFinished'));
    }
    return Promise.reject(err);
  });
