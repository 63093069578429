import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { ReactComponent as Edit } from '../../../shared_assets/icons/edit.svg';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';

const useStyles = makeStyles({
  container: {
    height: 40,
    borderRadius: 5,
    border: `2px ${getTheme().neutral4} solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0',
  },
});

interface SkillEntryProps {
  disabled?: boolean;
  skill: string;
  onRequestSkillChange: () => void;
  className?: 'string';
}

const SkillEntry: React.FC<SkillEntryProps> = ({
  disabled,
  skill,
  onRequestSkillChange,
  className,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, className)}>
      <Text noMargin inline>
        {skill}
      </Text>
      {!disabled ? (
        <Button
          type="link"
          onlyText
          onClick={onRequestSkillChange}
          prefix={<Edit />}
        >
          <Translate id="button.edit" />
        </Button>
      ) : null}
    </div>
  );
};

export default SkillEntry;
