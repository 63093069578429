import { SetStateAction } from "react";
import validator from "validator";
import { getTranslate } from "react-localize-redux";
import {
  VisitorForm,
  VisitorFormProperty
} from "../../types/visitor.interface";
import { store } from "../../store/index";

export const setVisitorFormValue = (
  setForm: (prevForm: SetStateAction<VisitorForm | null>) => void
) => (field: VisitorFormProperty, value: string) => {
  setForm(prevForm => {
    let valid = false;
    let sanitizedValue = value.trim();

    //TODO: Ensure all VisitorFormProperties are handled
    //TODO: Turn these into simpler, cleaner hooks, see useLoginForm.ts
    switch (field) {
      case "firstName":
        valid = !validator.isEmpty(sanitizedValue);
        break;
      case "lastName":
        valid =
          validator.isNumeric(sanitizedValue) && sanitizedValue.length === 9;
        break;
      default:
        valid = false;
    }

    if (prevForm)
      return {
        ...prevForm,
        [field]: {
          value: value,
          sanitizedValue: sanitizedValue,
          isValid: valid
        }
      };
    else return null;
  });
};

type VisitorError = VisitorFormProperty | "noAvatar";

export const getVisitorErrorMessagesFromField = (field: VisitorError) => {
  const translate = getTranslate(store.getState().localize);

  //TODO: Removed now unused translations but some are missing see that all VisitorError's are handled in switch
  switch (field) {
    case "noAvatar":
      return translate("administration.guides.errors.mandatoryAvatar");
    default:
      return "Not such field in errors";
  }
};
