import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_URL } from '../../../consts';

export type GetVisitorStepsResponse = {
  id: string;
  title: string;
  position: number;
  description: string;
  language: string;
}[];

type Hook = (
  setSteps: (val: SetStateAction<GetVisitorStepsResponse>) => void
) => [boolean, boolean];

const requestVisitorSteps = () =>
  Axios.get<GetVisitorStepsResponse>(
    `${API_URL}/content-management/visitor-landing-page/steps`
  );

export const useVisitorSteps: Hook = setSteps => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: info } = await requestVisitorSteps();

        setSteps(info);
        setIsError(false);
      } catch (err) {
        setSteps([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setSteps]);

  return [isLoading, isError];
};
