import Axios from 'axios';
import { API_URL } from '../../consts';

export interface VisitorLandingPageDto {
  firstTitle: string;
  intro: string;
  experiencesTitle: string;
  featuredRoutesTitle: string;
  interestTitle: string;
  joinCommunityTitle: string;
  joinCommunityText: string;
  callToActionUrl: string;
  language: string;
  searchTitle: string;
  searchSubtitle: string;
  searchPlaceholder: string;
  howToStartTitle: string;
  howToStartSubtitle: string;
}
export interface LandingPageDto {
  landing: VisitorLandingPageDto[];
}
export const postVisitorLandingPage = (
  dto: LandingPageDto,
  image?: File | null
) => {
  const formData = new FormData();
  formData.append(
    'createVisitorLandingPageDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (image) {
    formData.append('headerImage', image);
  }
  return Axios({
    method: 'post',
    url: `${API_URL}/content-management/visitor-landing-page`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
