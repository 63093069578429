import Axios from 'axios';
import { API_URL } from '../../consts';
import moment from 'moment';
import { useState, useEffect, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
enum AboutPageImageType {
  HEADER = 'HEADER',
  IMAGE1 = 'IMAGE1',
  IMAGE2 = 'IMAGE2',
}

export interface AboutInterface {
  firstTitle: string;
  intro: string;
  whoWeAreTitle: string;
  whoWeAreText: string;
  whyUniquePathsTitle: string;
  argument0Title: string;
  argument0Text: string;
  argument1Title: string;
  argument1Text: string;
  argument2Title: string;
  argument2Text: string;
  information0Title: string;
  information0Text: string;
  information0RedirectUrl: string;
  information1Title: string;
  information1Text: string;
  information1RedirectUrl: string;
  footerTitle: string;
  footerText: string;
  footerButtonText: string;
  footerButtonRedirect: string;
  language: string;
}

type Hook = (
  setAboutLandingPage: (val: SetStateAction<AboutInterface[]>) => void
) => [boolean, boolean];

const requestLandingPageInformation = () =>
  Axios.get<AboutInterface[]>(`${API_URL}/content-management/about-page`);

export const useAboutLandingPage: Hook = setAboutLandingPage => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestLandingPageInformation();
        setAboutLandingPage(list);
        setIsError(false);
      } catch (err) {
        setAboutLandingPage([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setAboutLandingPage]);

  return [isLoading, isError];
};
export const getHeaderImageUrl = () =>
  `${API_URL}/content-management/about-page/image/${
    AboutPageImageType.HEADER
  }?_=${moment().unix()}`;
export const getImage1Url = () =>
  `${API_URL}/content-management/about-page/image/${
    AboutPageImageType.IMAGE1
  }?_=${moment().unix()}`;
export const getImage2Url = () =>
  `${API_URL}/content-management/about-page/image/${
    AboutPageImageType.IMAGE2
  }?_=${moment().unix()}`;
