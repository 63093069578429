import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { Button } from '../../../../components/App/Button';
import { FallbackImage } from '../../../../components/App/Image';
import { Modal } from '../../../../components/App/Modal';
import { Heading, Text } from '../../../../components/Typography';
import { getRouteLocationImageUrl } from '../../../../hooks/routes/UseRoute';
import { getTheme } from '../../../../utils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 1rem',
  },
  bookingRouteContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    borderRadius: '5px',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  imageContainer: {
    flex: '1',
    marginRight: '1rem',
  },
  bookingRouteInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: '3',
    '& > *': {
      margin: '0',
    },
  },
  numberingContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 0',
  },
  circle: () => ({
    display: 'flex',
    position: 'relative',
    borderRadius: '50%',
    width: '1rem',
    height: '1rem',
    border: `1px solid ${getTheme().primary}`,
    margin: '0.2rem 1rem 0.3rem 0',
  }),
  circleText: () => ({
    position: 'absolute',
    alignSelf: 'center',
    textAlign: 'center',
    width: '100%',
    height: 'auto',
    color: `${getTheme().primary} !important`,
  }),
  marginBetween: {
    margin: '1.5rem 0',
  },
  bottomDivider: {
    margin: '0 0 1rem 0',
  },
  button: {
    width: '100%',
  },
  paddingModal: {
    padding: 24,
  },
});

export interface RouteAboutToStartProps extends LocalizeContextProps {
  routeTitle: string;
  routeDuration: number;
  routeImageId: string;
  scheduleDate: moment.Moment;
  isVisible: boolean;
  onOpenStartRouteModal: () => void;
  onClose: () => void;
}

const RouteAboutToStart = ({
  translate,
  routeTitle,
  routeDuration,
  routeImageId,
  scheduleDate,
  isVisible,
  onOpenStartRouteModal,
  onClose,
}: RouteAboutToStartProps) => {
  const classes = useStyles();

  return (
    <Modal
      visible={isVisible}
      stickToBottom
      disableNaturalPadding
      contentContainerClassName={classes.paddingModal}
      onRequestClose={() => {
        onClose();
      }}
      footer={null}
    >
      <div>
        <Heading level={4}>
          <Translate id="startRoute.routeAboutToStart" />
        </Heading>
        <div
          className={classNames(
            classes.bookingRouteContainer,
            classes.marginBetween
          )}
        >
          <div className={classes.imageContainer}>
            <FallbackImage
              className={classes.image}
              fallback={require('../../../../shared_assets/images/route-default.png')}
              src={getRouteLocationImageUrl(routeImageId)}
              //src={scheduleBookings.image}
              alt="place"
            />
          </div>
          <div className={classes.bookingRouteInfo}>
            <Heading level={5} ellipsis={{ rows: 2 }} noMargin>
              {routeTitle}
            </Heading>
            <Text color={getTheme().neutral2} noMargin>
              {`${moment(scheduleDate).format(
                'D MMM YYYY, [das] LT'
              )} às ${moment(scheduleDate)
                .add(routeDuration, 'hours')
                .format('LT')}`}
            </Text>
          </div>
        </div>
        <div className={classes.numberingContainer}>
          <div className={classes.circle}>
            <Text variant="note" className={classes.circleText} noMargin>
              1
            </Text>
          </div>
          <Text noMargin style={{ flex: 1 }}>
            {translate('startRoute.titleCheckPresence').toString()}
          </Text>
        </div>
        <div className={classes.numberingContainer}>
          <div className={classes.circle}>
            <Text variant="note" className={classes.circleText} noMargin>
              2
            </Text>
          </div>
          <Text noMargin style={{ flex: 1 }}>
            {translate('startRoute.titleStartRoute').toString()}
          </Text>
        </div>
        <Divider className={classes.bottomDivider} />

        <Button
          type="primary"
          className={classes.button}
          onClick={onOpenStartRouteModal}
        >
          <Translate id="button.seeVisitors" />
        </Button>
      </div>
    </Modal>
  );
};

export default withLocalize(RouteAboutToStart);
