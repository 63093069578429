import message from 'antd/es/message';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../../../../components/App/Loader';
import { RouteForm } from '../../../../components/App/RouteForm';
import { RouteFormResult } from '../../../../components/App/RouteForm/RouteForm';
import { RouteIntro } from '../../../../components/App/RouteIntro';
import { SubmitNewRoute } from '../../../../hooks/routes/SubmitNewRoute';
import { RouteStateType } from '../../../../types/route-state.enum';

interface RouteState {
  showIntro: boolean;
}

function CreateNewRoute() {
  const location = useLocation<RouteState>();
  const history = useHistory();
  const [submittingRoute, setSubmittingRoute] = useState<boolean>(false);
  const [showIntro, setShowIntro] = useState(
    location.state?.showIntro || false
  );

  const submitNewRoute = useCallback(
    (status: RouteStateType) => (form: RouteFormResult) => {
      setSubmittingRoute(true);
      SubmitNewRoute({
        accessibilityIds: form.accessibilityIds,
        activities: form.activities,
        agreeTerms:
          form.agreeGpsSharing &&
          form.agreePhotoOwnership &&
          form.agreeTosAndPp,
        difficulty: form.selectedDifficulty,
        duration: form.selectedDuration,
        extraItems: form.extraItems,
        groupAge: form.selectedAgeGroup,
        groupSize: form.maxVisitors,
        guideId: form.guideId,
        location: form.routeLocation,
        locations: form.pointLocations.map((pL, i) => ({
          ...pL,
          photos: pL.photos.filter(p => p instanceof File) as File[],
          order: i,
        })),
        languages: form.selectedLanguages.map(lang => ({ language: lang })),
        routeExperience: form.routeExperienceType,
        subjectIds: form.selectedSubjects,
        titles: form.routeTitles,
        descriptions: form.routeDescriptions,
        state: status,
        latitude: form.routeLatitude,
        longitude: form.routeLongitude,
        routeLength: form.routeLength,
      })
        .then(res => {
          setSubmittingRoute(false);
          if (status === RouteStateType.DRAFT) history.goBack();
          else history.replace('/guide/my-routes/create/success');
        })
        .catch(err => {
          setSubmittingRoute(false);

          message.error(err.toString());
        });
    },
    [history]
  );
  if (submittingRoute) {
    return <Loader />;
  }
  return (
    <>
      {showIntro ? (
        <RouteIntro onFinish={() => setShowIntro(false)} />
      ) : (
        <RouteForm
          onSave={submitNewRoute(RouteStateType.DRAFT)}
          onFinish={submitNewRoute(RouteStateType.PENDING_APPROVAL)}
        />
      )}
    </>
  );
}

export default CreateNewRoute;
