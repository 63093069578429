import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AnimatedAlert } from '../../../components/Administration/AnimatedAlert/AnimatedAlert';
import { AreaCardList } from '../../../components/App/AreaCardList';
import { Breaker } from '../../../components/App/Breaker';
import { Button } from '../../../components/App/Button';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { CreateRouteStickyFooter } from '../../../components/App/CreateRouteStickyFooter';
import { Error } from '../../../components/App/Error';
import { Faq } from '../../../components/App/Faq';
import { GuideTestimonyList } from '../../../components/App/GuideTestimonyList';
import { HelpModal } from '../../../components/App/HelpModal';
import { LinkSection } from '../../../components/App/LinkSection';
import { Loader } from '../../../components/App/Loader';
import { LookForList } from '../../../components/App/LookForList';
import { RoutePreviewList } from '../../../components/App/RoutePreviewList';
import { StepList } from '../../../components/App/StepList';
import { VideoPlayerModal } from '../../../components/App/VideoPlayerModal';
import { Heading, Text } from '../../../components/Typography';
import { useEmail } from '../../../hooks/authentication/confirmEmail';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useGuide } from '../../../hooks/guide/UseGuide';
import {
  getHeaderImageUrl,
  GuideLandingTranslation,
  useGuideLandingTranslations,
} from '../../../hooks/landing/useGuideLandingTranslations';
import { ReactComponent as ArrowBlueDown } from '../../../shared_assets/icons/arrow_blue_down.svg';
import { ReactComponent as Pedirota } from '../../../shared_assets/images/pedirota.svg';
import { RootState } from '../../../store';
import { GuideStateType } from '../../../types/guide-state-type.enum';
import { Guide } from '../../../types/guide.interface';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';

const useStyles = makeStyles({
  standOutBackground: {
    backgroundColor: getTheme().neutral6,
  },
  dot: () => ({
    height: 8,
    width: 8,
    margin: '0 8px',
    borderRadius: '100%',
    borderWidth: 2,
    backgroundColor: 'transparent',
    display: 'inline-block',
    borderColor: 'white',
    borderStyle: 'solid',
    cursor: 'pointer',
    '&.active': {
      backgroundColor: getTheme().primary,
      borderColor: getTheme().primary,
    },
  }),
  carouselArrow: {
    height: '42px !important',
    width: '42px !important',
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
    pointerEvents: 'all !important' as 'all',
    objectFit: 'unset !important' as 'unset',
    cursor: 'pointer',
    '&:nth-child(3)': {
      right: 0,
    },
  },
  carousel: {
    '& .slide': {
      background: 'transparent',
    },
  },
  page: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'column',
    '& > *': {
      flexShrink: 0,
    },
  },
  introZone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    position: 'relative',
    backgroundImage: `url(${getHeaderImageUrl()})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '0 0 2vh 2vh',
    '& *': {
      color: 'white !important',
    },
    '& > .carousel-zone': {
      '& > *:not(button)': {
        filter: 'drop-shadow(2px 2px 8px black)',
      },
    },
    flexGrow: 1,
    overflow: 'hidden',
  },
  introSpacer: {
    minHeight: '100vh',
  },
  textZone: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: headerHeight,
    paddingTop: '1rem !important',
    paddingBottom: '1rem !important',
    '& > *:not(button)': {
      filter: 'drop-shadow(2px 2px 8px black)',
    },
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: '100vw',
  },
  mainButton: () => ({
    alignSelf: 'center',
    textTransform: 'uppercase',
    height: '64px !important',

    '& > *': {
      fontWeight: 'bold !important',
      color: getTheme().primary + '!important',
    },
    '& > svg': {
      height: '30px !important',
    },
  }),
  uppercaseButton: {
    textTransform: 'uppercase',
  },
  faqsButton: {
    justifyContent: 'initial',
    padding: 0,
    marginBottom: '1rem',
  },
  scrollable: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  body: {
    ...mobilePadding,
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  thinCallout: {
    fontWeight: 'lighter',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  fadedDescription: {
    textAlign: 'center',
  },
  fillWidth: {
    minWidth: '100%',
    padding: '2rem 0',
  },
  stickyFooterPadder: {
    marginBottom: 64,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  marginBetween: {
    margin: '1rem 0',
  },
  marginBetweenLink: {
    margin: '2rem 0',
  },
  marginBetweenShowRoutes: {
    margin: '0 0 1rem 0 ',
  },
  marginTitle: {
    margin: '2rem 0 1rem 0 !important',
  },
  marginTitle2: {
    margin: '2rem 0 0 0 !important',
  },
  bold: {
    fontWeight: '600 !important' as unknown as number,
  },
  divider: {
    margin: '1rem 0',
  },
  textCenter: {
    textAlign: 'center',
  },
  paddingExtra: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    paddingExtra: {
      ...desktopPadding,
    },
    divider: {
      margin: '24px 0',
    },
    textZone: {
      margin: '2rem',
      position: 'relative',
      maxWidth: '50vw',
      alignSelf: 'center',
      textAlign: 'center',
      '& > *': { filter: 'unset !important' },
    },
    introZone: {
      alignItems: 'center',
      minHeight: '40vh',
      '& > .carousel-zone': {
        borderRadius: 16,
      },
    },
    stickyFooterPadder: {
      marginBottom: 0,
    },
    btnSeeRoutes: {
      alignSelf: 'center',
      padding: '1.5rem 6rem',
    },
    mainButton: () => ({
      alignSelf: 'flex-start',
    }),
  },
  experiencesDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface RouteState {
  alert?: 'resetEmailSent' | 'passwordChanged' | 'confirmEmail';
  resetEmail?: string;
}

interface GuideLandingProps extends LocalizeContextProps {
  openLogin: () => void;
}
const getToken = (location: any): string => {
  if (location.search) {
    const queryString = location.search.split('?')[1];
    const token = queryString.split('=')[1];
    return token;
  } else {
    return '';
  }
};
function GuideLanding({ translate, openLogin }: GuideLandingProps) {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [translations, setTranslations] =
    useState<GuideLandingTranslation | null>(null);
  const [loadingTranslation, errorLoadingTranslation] =
    useGuideLandingTranslations(activeLanguage, setTranslations);
  const location = useLocation<RouteState | undefined>();
  const [jwt] = UseJWT();
  const history = useHistory();
  const [guide, setGuide] = useState<Guide | null>(null);
  const [isLoading, hasError] = useGuide(jwt?.guideId, setGuide);
  const token = getToken(location);
  const { lastAppZone } = useSelector((state: RootState) => state.app);
  const [openHelpModalFlag, setOpenHelpModalFlag] = useState(false);
  const [isConfirming, errorConfirming] = useEmail(token, lastAppZone);
  const guideIsApproved = useSelector(
    (state: RootState) => state.app.lastGuideState === GuideStateType.APPROVED
  );
  if (token !== '') {
    location.state = { alert: 'confirmEmail' };
  }

  const renderAlert = useCallback(
    (errorConfirming: boolean) => {
      switch (location.state?.alert) {
        case 'passwordChanged':
          return (
            <AnimatedAlert
              visible
              message={translate('landing.passwordChanged')
                .toString()
                .replace('{FIRST_NAME}', guide?.firstName || '')
                .replace('{LAST_NAME}', guide?.lastName || '')}
              type="success"
              showIcon
            />
          );
        case 'resetEmailSent':
          return (
            <AnimatedAlert
              visible
              message={translate('landing.resetPassword').toString()}
              description={translate('landing.resetEmailSent')
                .toString()
                .replace('{EMAIL}', location.state.resetEmail || '')}
              type="info"
              showIcon
            />
          );

        default:
          return null;
      }
    },
    [location, translate, guide]
  );
  const openHelpModal = useCallback(() => {
    setOpenHelpModalFlag(!openHelpModalFlag);
  }, [openHelpModalFlag]);
  const createRoute = useCallback(() => {
    if (jwt?.guideId) {
      if (guide && guide?.numberOfRoutes > 0) {
        history.push('/guide/schedules');
      } else {
        history.push('/guide/my-routes');
      }
    } else {
      openLogin();
    }
  }, [jwt, guide, history, openLogin]);

  const loadingPage =
    isLoading || isConfirming || loadingTranslation || !translations;

  const goToHash = useCallback(() => {
    if (loadingPage) {
      return;
    }

    const id = location.hash.split('#').filter(s => !!s)[0];

    if (id) document.getElementById(id)?.scrollIntoView();
    else
      document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash, loadingPage]);

  useLayoutEffect(goToHash, [goToHash]);

  if (loadingPage || !translations) return <Loader />;

  if (hasError && jwt?.guideId) return <Error />;
  if (errorLoadingTranslation) return <Error />;
  return (
    <motion.div
      className={classNames(classes.page, classes.stickyFooterPadder)}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <VideoPlayerModal />
      {renderAlert(errorConfirming)}
      {!isMobile && (
        <div className={classes.textZone}>
          <Heading level={2} className={classes.mainTitle}>
            {translations.firstTitle}
          </Heading>
          {/* <Text className={classes.thinCallout}>
            {translations.thinCallout}
          </Text> */}
          <Text className={classes.fadedDescription}>{translations.intro}</Text>
        </div>
      )}
      <div
        className={classNames(classes.introZone, classes.standOutBackground)}
      >
        {isMobile && (
          <div className={classes.introSpacer}>
            <div
              className={classNames(
                classes.textZone,
                classes.body,
                classes.shadowBackground
              )}
            >
              <Heading level={2} className={classes.mainTitle}>
                {translations.firstTitle}
              </Heading>
              <Text className={classes.thinCallout}>
                {translations.thinCallout}
              </Text>
              <Text className={classes.fadedDescription}>
                {translations.intro}
              </Text>
              {guideIsApproved ? (
                <Button
                  className={classes.mainButton}
                  prefix={<Pedirota />}
                  size="large"
                  onClick={createRoute}
                >
                  {guide && guide.numberOfRoutes > 0 ? (
                    <Translate id="landing.guide.accessMyCalendar" />
                  ) : (
                    <Translate id="landing.guide.organizeRoute" />
                  )}
                </Button>
              ) : null}
            </div>
          </div>
        )}
        <div className={classNames('carousel-zone', classes.body)}>
          <Heading disableAutoscale level={2}>
            {translations.whatIsTitle}
          </Heading>
          {/* <Carousel
            showStatus={false}
            className={classes.carousel}
            showThumbs={false}
            infiniteLoop
            renderIndicator={(onClick, isSelected) => (
              <div
                onClick={onClick}
                className={classNames(
                  classes.dot,
                  isSelected ? 'active' : undefined
                )}
              />
            )}
            renderArrowNext={clickHandler => (
              <FallbackImage
                src={require('../../../shared_assets/icons/next.svg')}
                alt="next"
                onClick={clickHandler}
                className={classes.carouselArrow}
              />
            )}
            renderArrowPrev={clickHandler => (
              <FallbackImage
                src={require('../../../shared_assets/icons/previous.svg')}
                alt="next"
                onClick={clickHandler}
                className={classes.carouselArrow}
              />
            )}
          >
          
          </Carousel> */}
          {translations.carouselTexts.map((text: string, i: number) => (
            <div key={i.toString()}>
              <Heading disableAutoscale level={4}>
                {text}
              </Heading>
            </div>
          ))}
          {!isMobile ? (
            guideIsApproved ? (
              <Button
                className={classes.mainButton}
                style={{ marginTop: '1rem' }}
                onClick={createRoute}
                type="default"
                size="large"
                prefix={<Pedirota />}
              >
                {guide && guide.numberOfRoutes > 0 ? (
                  <Translate id="landing.guide.accessMyCalendar" />
                ) : (
                  <Translate id="landing.guide.organizeRoute" />
                )}
              </Button>
            ) : null
          ) : null}
        </div>
      </div>

      <div className={classNames(classes.body)}>
        <Heading level={3} className={classes.marginTitle2}>
          {translations.areaTitle}
        </Heading>

        <div className={classes.marginBetweenShowRoutes}>
          {/* <Heading level={3} noMargin>
            {translations.otherExperiencesTitle}
          </Heading> */}
          <div className={classes.experiencesDiv}>
            <RoutePreviewList data={translations.areas} />
          </div>
        </div>
        {/* <CarouselLanding
          type="area"
          data={translations.areas}
          className={classes.marginBetween}
        /> */}
        <Heading level={3} className={classNames(classes.marginTitle)}>
          {translations.subjectTitle}
        </Heading>
        {/* <InterestAreaList data={translations.subjects} /> */}
        <AreaCardList
          wrap={!isMobile}
          data={translations.subjects}
          className={classes.marginBetween}
        />
        <Heading level={3} className={classes.marginTitle}>
          {translations.lookForTitle}
        </Heading>
        <LookForList
          data={translations.lookFors}
          className={classes.marginBetween}
        />
      </div>
      <div className={!isMobile ? classes.body : ''}>
        <div
          className={classNames(
            classes.standOutBackground,
            classes.paddingExtra,
            isMobile ? classes.body : ''
          )}
        >
          <Heading level={2}>{translations.joinTitle}</Heading>
          <Text>{translations.joinText}</Text>
          <GuideTestimonyList data={translations.testimonies} />
          <Breaker />
          <Text>
            {complexTranslate(translations.toKnowMoreText, {
              '{HELP_LINK}': (
                <Button
                  key="help"
                  type="link"
                  onlyText
                  onClick={openHelpModal}
                  className={classes.inlineBtn}
                  style={{ textTransform: 'lowercase' }}
                >
                  <Translate id="landing.help" />
                </Button>
              ),
            })}
          </Text>
          <Text>
            {complexTranslate(translate('landing.guide.companyAd').toString(), {
              '{companyArea}': (
                <Link color="blue" to="/company" type="link" key="companyLink">
                  <Text noMargin color="rgb(0, 156, 222)" inline>
                    <Translate id="landing.companyArea" />
                  </Text>
                </Link>
              ),
            })}
          </Text>
        </div>
      </div>
      <HelpModal
        visible={openHelpModalFlag}
        onRequestClose={() => {
          setOpenHelpModalFlag(false);
        }}
      />
      <div className={classNames(classes.body, classes.marginBetween)}>
        <Heading level={3}>{translations.weHelpToGrowTitle}</Heading>
        <Text>{translations.weHelpToGrowText1}</Text>
        <Text>{translations.weHelpToGrowText2}</Text>
        <div id="how-it-works" className={classes.marginBetween}>
          <Heading level={3}>{translations.howToStartTitle}</Heading>
          <Text>{translations.howToStartText}</Text>
          <StepList data={translations.steps} />
        </div>
      </div>
      <div className={!isMobile ? classes.body : ''}>
        <div
          className={classNames(
            isMobile ? classes.body : '',
            classes.paddingExtra,
            classes.standOutBackground
          )}
        >
          <Heading level={5} className={classes.bold}>
            {translations.enticeText}
          </Heading>
          {guideIsApproved && (
            <Button
              className={classNames(classes.marginBetween)}
              type="primary"
              size="large"
              onClick={createRoute}
            >
              {guide && guide.numberOfRoutes > 0 ? (
                <Translate id="landing.guide.accessMyCalendar" />
              ) : (
                <Translate id="landing.guide.organizeRoute" />
              )}
            </Button>
          )}
        </div>
      </div>

      <div className={classNames(classes.body)}>
        {translations.faqs.length > 0 ? (
          <Heading level={3} className={classes.marginTitle}>
            {translations.faqTitle}
          </Heading>
        ) : null}

        {translations.faqs.map((faq, i) => (
          <div key={i.toString()}>
            <Faq {...faq} />
            <Divider className={classes.divider} />
          </div>
        ))}
        {translations.faqs.length > 0 ? (
          <Button
            type="link"
            className={classes.faqsButton}
            suffix={
              <ArrowBlueDown
                style={{
                  width: '0.5rem',
                  height: '0.5rem',
                  marginLeft: '0.3rem',
                }}
              />
            }
          >
            <Translate id="button.showMore" />
          </Button>
        ) : null}

        <div className={classes.marginBetween}>
          <Heading level={3} noMargin>
            {translations.otherExperiencesTitle}
          </Heading>
          <RoutePreviewList data={translations.routePreviews} />
        </div>

        <Link to="/route/search" className={classes.marginBetweenLink}>
          <Button
            className={classNames(
              classes.btnSeeRoutes,
              classes.uppercaseButton
            )}
            type="primary"
          >
            <Translate id="landing.guide.seeMoreRoutes" />
          </Button>
        </Link>
      </div>
      <Divider />
      <LinkSection />
      <CopyrightFooter showSocialLinks />
      {isMobile && (
        <CreateRouteStickyFooter guide={guide} onCreate={createRoute} />
      )}
    </motion.div>
  );
}

export default withLocalize(GuideLanding);
