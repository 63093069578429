import React from 'react';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { getTheme } from '../../../utils';
import { Button } from '../Button';

const BackButton = () => {
  const history = useHistory();

  return (
    <Button type="ghost" onClick={history.goBack} color={getTheme().primary}>
      <Translate id="button.goBack" />
    </Button>
  );
};

export default React.memo(BackButton);
