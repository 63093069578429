import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { Route } from '../../types/route.interface';

type Hook = (
  guideId: string | undefined,
  setRoutes: (val: SetStateAction<Route[]>) => void
) => [boolean, boolean];

export const requestGuideRoutes = (guideId: string) =>
  Axios.get(`${API_URL}/route/guide/${guideId}`);

export const useGuideRoutes: Hook = (guideId, setRoutes) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!guideId) {
        setIsError(true);
        setRoutes([]);
        setIsLoading(false);
        return;
      }

      try {
        const { data: route } = await requestGuideRoutes(guideId);
        setRoutes(route as Route[]);
      } catch (err) {
        setRoutes([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [guideId, setRoutes]);

  return [isLoading, isError];
};
