import { makeStyles } from '@material-ui/styles';
import Progress from 'antd/es/progress';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { Error } from '../../../components/App/Error';
import {
  AccessibilityWithSingleTranslation,
  useAccessibilities,
} from '../../../hooks/accessibilities/useAccessibilities';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useGuide } from '../../../hooks/guide/UseGuide';
import {
  RouteSubjectWithSingleTranslation,
  useSubjects,
} from '../../../hooks/subjects/useSubjects';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { RootState } from '../../../store';
import { GroupAgeType } from '../../../types/group-age-type.enum';
import { Guide } from '../../../types/guide.interface';
import { RouteActivity } from '../../../types/route-activity.interface';
import { DifficultyType } from '../../../types/route-difficulty.enum';
import { RouteExperienceType } from '../../../types/route-experience.enum';
import { RouteExtraItem } from '../../../types/route-extra-item.interface';
import { Route } from '../../../types/route.interface';
import {
  desktopPadding,
  getTheme,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { Heading } from '../../Typography';
import { EditRouteCollapsible } from '../EditRouteCollapsible';
import EditRouteCollapsibleContent from '../EditRouteCollapsible/EditRouteCollapsibleContent';
import { HorizontalSteps } from '../HorizontalSteps';
import Loader from '../Loader/Loader';
import AgreementStep from './AgreementStep';
import Competences from './Competences';
import CreateNewRouteStickyFooter from './CreateNewRouteStickyFooter';
import DescriptionAndSubjectForm from './DescriptionAndSubjectForm';
import { Location } from './LocationModal';
import PointsForm from './PointsForm';
import RouteCreationHeader from './RouteCreationHeader';
import RouteDetails from './RouteDetails';
import TitleStep from './TitleStep';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  stickyFooterPadder: {
    marginBottom: 64,
  },
  paddedProgress: {
    ...mobilePadding,
    paddingTop: '1rem',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    marginTop: '1rem',
    paddingBottom: '1rem',
  },
  desktopSaveButton: {
    marginBottom: '1rem',
  },
  headerMargin: {
    marginBottom: '3rem !important',
  },
  directionDefiner: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {},
    stickyFooterPadder: {
      marginBottom: 0,
    },
    stepContainer: {
      marginTop: 0,
    },
    paddedProgress: {
      ...desktopPadding,
    },
    directionDefiner: {
      flexDirection: 'row',
      '& > *:first-child': {
        marginRight: '2rem',
        flex: 0,
        minWidth: 300,
      },
      '& > *:nth-child(2)': {
        flex: 1,
      },
    },
    body: {
      ...desktopPadding,
    },
  },
});

export interface RouteDescription {
  language: string;
  description: string;
}
export interface RouteTitle {
  language: string;
  title: string;
}
interface RouteFormProps extends LocalizeContextProps {
  route?: Route;
  onSave?: (route: RouteFormResult) => void;
  onFinish: (route: RouteFormResult) => void;
}

export interface RouteFormResult {
  selectedLanguages: string[];
  accessibilityIds: string[];
  routeDescriptions: RouteDescription[];
  routeExperienceType: RouteExperienceType;
  routeLocation: string;
  pointLocations: Location[];
  selectedSubjects: string[];
  activities: RouteActivity[];
  extraItems: RouteExtraItem[];
  selectedDifficulty: DifficultyType;
  agreeGpsSharing: boolean;
  agreePhotoOwnership: boolean;
  agreeTosAndPp: boolean;
  selectedAgeGroup: GroupAgeType;
  selectedDuration: number;
  maxVisitors: number;
  routeTitles: RouteTitle[];
  guideId: string;
  routeLatitude: number;
  routeLongitude: number;
  routeLength: number;
}

function RouteForm({ translate, onSave, onFinish, route }: RouteFormProps) {
  const classes = useStyles();
  const [majorStep, setMajorStep] = useState<number>(0);
  const [minorStep, setMinorStep] = useState<number>(0);
  const [jwt] = UseJWT();
  const [guide, setGuide] = useState<Guide | null>(null);
  const [isLoading, hasError] = useGuide(jwt?.guideId, setGuide);
  const [canMoveForward, setCanMoveForward] = useState<boolean>(false);
  //TODO: ver porque isso esta definido com PT ?????
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [routeDescriptions, setRouteDescriptions] = useState<
    RouteDescription[]
  >([]);
  const [routeTitles, setRouteTitles] = useState<RouteTitle[]>([]);
  const [routeExperienceType, setRouteExperienceType] =
    useState<RouteExperienceType>(RouteExperienceType.LESS_THEN_ONE_YEAR);
  const [routeLocation, setRouteLocation] = useState<string>('');
  const [pointLocations, setPointLocations] = useState<Location[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [subjects, setSubjects] = useState<RouteSubjectWithSingleTranslation[]>(
    []
  );
  const [activities, setActivities] = useState<RouteActivity[]>([]);
  const [extraItems, setExtraItems] = useState<RouteExtraItem[]>([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState<DifficultyType>(
    DifficultyType.EASY
  );
  const [agreeGpsSharing, setAgreeGpsSharing] = useState<boolean>(false);
  const [routeLongitude, setRouteLongitude] = useState<number>(0);

  const [routeLatitude, setRouteLatitude] = useState<number>(0);
  const [routeLength, setRouteLength] = useState<number>(0);
  const [agreePhotoOwnership, setAgreePhotoOwnership] =
    useState<boolean>(false);
  const [agreeTosAndPp, setAgreeTosAndPp] = useState<boolean>(false);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<GroupAgeType>(
    GroupAgeType.ANY
  );
  const [selectedDuration, setSelectedDuration] = useState<number>(60);
  const [maxVisitors, setMaxVisitors] = useState<number>(8);
  const [accessibilityIds, setAccessibilityIds] = useState<string[]>([]);

  const [progress, setProgress] = useState<number>(0);
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [subjectsAreLoading, subjectsHaveError] = useSubjects(
    setSubjects,
    activeLanguage
  );
  const [accessibilities, setAccessibilities] = useState<
    AccessibilityWithSingleTranslation[]
  >([]);
  const [accessibilitiesAreLoading, accessibilitiesHaveError] =
    useAccessibilities(setAccessibilities);

  //when step changes update progress
  useEffect(() => {
    switch (majorStep) {
      case 0:
        setProgress(0);
        break;
      case 1:
        switch (minorStep) {
          case 0:
            setProgress(20);
            break;
          case 1:
            setProgress(40);
            break;
          case 2:
            setProgress(60);
            break;
          case 3:
            setProgress(80);
            break;
          default:
            break;
        }
        break;
      case 2:
        setProgress(90);
        break;
      default:
        break;
    }
  }, [minorStep, majorStep]);

  //if and when an external route is provided update state to match external route, this means we can feed it a route to edit
  useEffect(() => {
    if (!route) return;

    setActivities(route.activities);
    setExtraItems(route.extraItems);
    setAgreeGpsSharing(false);
    setAgreePhotoOwnership(false);
    setAgreeTosAndPp(false);
    setAccessibilityIds(route.accessibilities.map(acc => acc.id));
    setMaxVisitors(route.groupSize);
    setRouteDescriptions(route.descriptions);
    setRouteExperienceType(route.routeExperience);
    setRouteLocation(route.location);
    setPointLocations(
      route.locations
        .sort((a, b) => a.order - b.order)
        .map((l, i, sortedLocations) => ({
          ...l,
          type:
            i === 0
              ? 'start'
              : i === sortedLocations.length - 1
              ? 'end'
              : 'middle',
        }))
    );
    setSelectedAgeGroup(route.groupAge);
    setSelectedDifficulty(route.difficulty);
    setSelectedDuration(route.duration);
    setSelectedLanguages(route.languages);
    setSelectedSubjects(route.subjects.map(s => s.id));
    setRouteTitles(route.titles);
    setRouteLength(route.routeLength);
  }, [route]);

  //when selectedLanguages change double check descriptions
  useEffect(() => {
    if (selectedLanguages.length === 0) {
      return;
    }
    setPointLocations(prevLocations =>
      prevLocations.map(prevLocation => {
        const newLocation: Location = prevLocation;

        //remove extra languages
        newLocation.descriptions = newLocation.descriptions.filter(desc =>
          selectedLanguages.includes(desc.language)
        );
        newLocation.titles = newLocation.titles.filter(title =>
          selectedLanguages.includes(title.language)
        );
        //add missing languages
        selectedLanguages.forEach(language => {
          if (
            !newLocation.descriptions.some(desc => desc.language === language)
          )
            newLocation.descriptions.push({ language, description: '' });

          if (!newLocation.titles.some(title => title.language === language))
            newLocation.titles.push({ language, title: '' });
        });

        return { ...newLocation };
      })
    );

    setRouteDescriptions(prevDescriptions => {
      let newDescriptions: RouteDescription[] = prevDescriptions;

      //remove extra languages
      newDescriptions = newDescriptions.filter(desc =>
        selectedLanguages.includes(desc.language)
      );

      //add missing languages
      selectedLanguages.forEach(language => {
        if (!newDescriptions.some(desc => desc.language === language))
          newDescriptions.push({ language, description: '' });
      });

      return [...newDescriptions];
    });
    setRouteTitles(prevTitles => {
      let newTitles: RouteTitle[] = prevTitles;
      //remove extra languages
      newTitles = newTitles.filter(title =>
        selectedLanguages.includes(title.language)
      );
      //add missing languages
      selectedLanguages.forEach(language => {
        if (!newTitles.some(title => title.language === language))
          newTitles.push({ language, title: '' });
      });

      return [...newTitles];
    });
  }, [selectedLanguages]);

  //when state changes decide if it can move to the next step
  useEffect(() => {
    switch (majorStep) {
      case 0:
        switch (minorStep) {
          case 0:
            setCanMoveForward(selectedLanguages.length > 0);
            break;
          default:
            setCanMoveForward(false);
        }
        break;
      case 1:
        switch (minorStep) {
          case 1:
            setCanMoveForward(
              pointLocations.some(p => p.type === 'start') &&
                pointLocations.some(p => p.type === 'end') &&
                !pointLocations.some(p =>
                  p.descriptions.some(d => validator.isEmpty(d.description))
                ) &&
                !validator.isEmpty(routeLocation)
            );
            break;
          case 2:
            setCanMoveForward(
              selectedSubjects.length > 0 &&
                !routeDescriptions.some(rD => validator.isEmpty(rD.description))
            );
            break;
          case 3:
            setCanMoveForward(
              !extraItems.some(item =>
                item.descriptions.some(desc =>
                  validator.isEmpty(desc.description)
                )
              ) &&
                !activities.some(activity =>
                  activity.descriptions.some(desc =>
                    validator.isEmpty(desc.description)
                  )
                )
            );
            break;
          case 0:
            setCanMoveForward(
              !routeTitles.some(rT => validator.isEmpty(rT.title))
            );
            break;
          default:
            setCanMoveForward(false);
        }
        break;
      case 2:
        switch (minorStep) {
          case 0:
            setCanMoveForward(
              agreeGpsSharing && agreePhotoOwnership && agreeTosAndPp
            );
            break;
          default:
            setCanMoveForward(false);
        }
        break;
      default:
        setCanMoveForward(false);
    }
  }, [
    selectedLanguages,
    majorStep,
    minorStep,
    pointLocations,
    routeLocation,
    selectedSubjects,
    routeDescriptions,
    extraItems,
    activities,
    agreeGpsSharing,
    agreePhotoOwnership,
    agreeTosAndPp,
    routeTitles,
  ]);

  //when step changes scroll top
  useEffect(() => {
    document.getElementById('root')?.scrollTo({ behavior: 'smooth', top: 0 });
  }, [minorStep, majorStep]);

  const renderStep = useCallback(() => {
    if (!guide || !guide.languages || subjectsAreLoading) return <Loader />;

    switch (majorStep) {
      case 0:
        switch (minorStep) {
          default:
            return (
              <Competences
                availableLanguages={guide.languages.map(l => l.language)}
                setSelectedLanguages={setSelectedLanguages}
                selectedLanguages={selectedLanguages}
                setRouteExperienceType={setRouteExperienceType}
                routeExperienceType={routeExperienceType}
              />
            );
        }
      case 1:
        switch (minorStep) {
          case 1:
            return (
              <PointsForm
                setPointLocations={setPointLocations}
                availableLanguages={selectedLanguages}
                location={routeLocation}
                setLocation={setRouteLocation}
                pointLocations={pointLocations}
                setRouteLatitude={setRouteLatitude}
                setRouteLongitude={setRouteLongitude}
                setRouteLength={setRouteLength}
                routeLength={routeLength}
              />
            );
          case 2:
            return (
              <DescriptionAndSubjectForm
                routeDescriptions={routeDescriptions}
                setRouteDescriptions={setRouteDescriptions}
                selectedSubjects={selectedSubjects}
                setSelectedSubjects={setSelectedSubjects}
                remoteSubjects={subjects}
              />
            );
          case 3:
            return (
              <RouteDetails
                activities={activities}
                setActivities={setActivities}
                selectedDifficulty={selectedDifficulty}
                setSelectedDifficulty={setSelectedDifficulty}
                ageGroup={selectedAgeGroup}
                setAgeGroup={setSelectedAgeGroup}
                duration={selectedDuration}
                setDuration={setSelectedDuration}
                extraItems={extraItems}
                setExtraItems={setExtraItems}
                maxVisitors={maxVisitors}
                setMaxVisitors={setMaxVisitors}
                selectedLanguages={selectedLanguages}
                setAccessibilityIds={setAccessibilityIds}
                accessibilityIds={accessibilityIds}
                accessibilities={accessibilities}
              />
            );
          case 0:
            return (
              <TitleStep titles={routeTitles} setRouteTitles={setRouteTitles} />
            );
          default:
            return null;
        }
      case 2:
        return (
          <AgreementStep
            agreeGpsSharing={agreeGpsSharing}
            agreePhotoOwnership={agreePhotoOwnership}
            agreeTosAndPp={agreeTosAndPp}
            setAgreeGpsSharing={setAgreeGpsSharing}
            setAgreePhotoOwnership={setAgreePhotoOwnership}
            setAgreeTosAndPp={setAgreeTosAndPp}
          />
        );
      default:
        return null;
    }
  }, [
    guide,
    subjectsAreLoading,
    majorStep,
    minorStep,
    agreeGpsSharing,
    agreePhotoOwnership,
    agreeTosAndPp,
    selectedLanguages,
    routeExperienceType,
    routeLocation,
    pointLocations,
    routeLength,
    routeDescriptions,
    selectedSubjects,
    subjects,
    activities,
    selectedDifficulty,
    selectedAgeGroup,
    selectedDuration,
    extraItems,
    maxVisitors,
    accessibilityIds,
    accessibilities,
    routeTitles,
  ]);

  const isMobile = useIsMobile();

  if (
    !guide ||
    !jwt ||
    isLoading ||
    subjectsAreLoading ||
    accessibilitiesAreLoading
  )
    return <Loader />;

  if (hasError || subjectsHaveError || accessibilitiesHaveError)
    return <Error />;

  const generateResult = (): RouteFormResult => ({
    activities,
    agreeGpsSharing,
    agreePhotoOwnership,
    agreeTosAndPp,
    extraItems,
    accessibilityIds,
    maxVisitors,
    pointLocations,
    routeDescriptions,
    routeExperienceType,
    routeLocation,
    selectedAgeGroup,
    selectedDifficulty,
    selectedDuration,
    selectedLanguages,
    selectedSubjects,
    routeTitles,
    routeLatitude,
    routeLongitude,
    guideId: jwt.guideId,
    routeLength,
  });

  return (
    <motion.div
      className={classNames(classes.page, classes.stickyFooterPadder)}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <RouteCreationHeader
        white={!!route}
        onSave={
          onSave &&
          (() => {
            onSave(generateResult());
          })
        }
      />
      {!route && (
        <>
          <HorizontalSteps
            steps={[
              { title: translate('routes.create1stStepTitle').toString() },
              { title: translate('routes.create2ndStepTitle').toString() },
              { title: translate('routes.create3rdStepTitle').toString() },
            ]}
            activeStep={majorStep}
          />
          <Progress
            className={classes.paddedProgress}
            percent={progress}
            trailColor={getTheme().standout}
            strokeColor={getTheme().primary}
          />
        </>
      )}
      <div className={classes.body}>
        {!isMobile && route && (
          <Heading className={classes.headerMargin} level={2}>
            <Translate id="routes.editRouteTitle" />
          </Heading>
        )}
        {route ? (
          <div className={classes.directionDefiner}>
            {!isMobile ? (
              <EditRouteCollapsibleContent
                setMajorStep={setMajorStep}
                setMinorStep={setMinorStep}
                activeMajorStep={majorStep}
                activeMinorStep={minorStep}
              />
            ) : (
              <>
                <EditRouteCollapsible
                  setMajorStep={setMajorStep}
                  setMinorStep={setMinorStep}
                  activeMajorStep={majorStep}
                  activeMinorStep={minorStep}
                />
              </>
            )}
            <div className={classes.stepContainer}>{renderStep()}</div>
          </div>
        ) : (
          <div className={classes.stepContainer}>{renderStep()}</div>
        )}
        <CreateNewRouteStickyFooter
          showFinish={majorStep === 2 && minorStep === 0}
          onFinish={() => {
            onFinish(generateResult());
          }}
          showBackBtn={!(minorStep === 0 && majorStep === 0)}
          canMoveForward={canMoveForward}
          moveBack={() => {
            if (minorStep === 0) {
              if (majorStep === 2) {
                setMinorStep(3);
              }
              setMajorStep(prevMajorStep => --prevMajorStep);
            } else {
              setMinorStep(prevMinorStep => --prevMinorStep);
            }
          }}
          moveForward={() => {
            if (majorStep === 0) {
              setMinorStep(0);
              setMajorStep(1);
            } else if (majorStep === 1) {
              if (minorStep < 3) {
                setMinorStep(prevStep => ++prevStep);
              } else {
                setMinorStep(0);
                setMajorStep(2);
              }
            }
          }}
        />
      </div>
    </motion.div>
  );
}

export default withLocalize(RouteForm);
