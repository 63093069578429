export const API_URL = 'https://api.uniquepaths.eu';

export const GOOGLE_API_BASE_URL = 'https://maps.googleapis.com/maps/api';
export const GOOGLE_LIBRARIES = ['places'];
export const GOOGLE_API_KEY = 'AIzaSyDjttLTIKZD1uItQESO_p8FX2dB0RCgOC4';


export const FACEBOOK_ID = '828404931279730';
export const FACEBOOK_SCOPES = 'public_profile, email';
export const FACEBOOK_FIELDS = 'name,email,picture';

export const GOOGLE_CLIENT_ID =
  '844089429407-d3tjphci9u9v1n45f5puur7vi9fjmht5.apps.googleusercontent.com';
export const GOOGLE_SCOPES =
  'email profile';

