export interface UserConfigurationState {
  activeLanguage: string;
  //TODO: user??
  jwt: string | null;
  acceptedCookies: boolean;
  videoUrl: string | null;
  ongoingRouteId: string | null;
}

export const SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const SET_VIDEO_URL = 'SET_VIDEO_URL';
export const SET_ONGOING_ROUTE = 'SET_ONGOING_ROUTE';

export interface SetActiveLanguageAction {
  type: typeof SET_ACTIVE_LANGUAGE;
  payload: string;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface AcceptCookiesAction {
  type: typeof ACCEPT_COOKIES;
}

export interface SetVideoUrl {
  type: typeof SET_VIDEO_URL;
  payload: {
    videoUrl: string | null;
  };
}

export interface LoginAction {
  type: typeof LOGIN;
  payload: {
    token: string;
  };
}

export interface SetOngoingRouteAction {
  type: typeof SET_ONGOING_ROUTE;
  payload: {
    id: string | null;
  };
}

export type UserConfigurationActions =
  | SetActiveLanguageAction
  | LoginAction
  | LogoutAction
  | AcceptCookiesAction
  | SetOngoingRouteAction
  | SetVideoUrl;
