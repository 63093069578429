import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { Route } from '../../types/route.interface';
import moment from 'moment';

type Hook = (
  routeId: string | undefined,
  setRoute: (val: SetStateAction<Route | null>) => void,
  publicRoute?: boolean
) => [boolean, boolean];

const requestRoute = (routeId: string, publicRoute: boolean) =>
  Axios.get(`${API_URL}/route/${routeId}`);

export const useRouteAdmin: Hook = (routeId, setRoute, publicRoute) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!routeId) {
        setIsError(true);
        setRoute(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: route } = await requestRoute(routeId, !!publicRoute);
        setRoute(route as Route);
        setIsError(false);
      } catch (err) {
        setRoute(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [routeId, publicRoute, setRoute]);

  return [isLoading, isError];
};

export const getRouteLocationImageUrl = (id: string) =>
  `${API_URL}/route/location/photo/${id}?_=${moment().unix()}`;
