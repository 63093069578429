import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';

import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Loader } from '../../../components/App/Loader';
import { Heading, Text } from '../../../components/Typography';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useGuideReviews } from '../../../hooks/guide/review/UseGuideReviews';
import { getVisitorImageUrl } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as CommentsIcon } from '../../../shared_assets/icons/comments.svg';
import { Review } from '../../../types/review.interface';
import { desktopPadding, mobilePadding, mobileThreshhold, useIsMobile } from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import CommentCard from './CommentCard';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  fill: {
    flex: 1,
    flexGrow: 1,
  },
  body: {
    ...mobilePadding,
    display: 'flex',
    flexDirection: 'column',
  },
  emptyImage: {
    width: '100%',
    objectFit: 'cover',
  },
  finalContainer: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  desktopPrefix: {
    marginRight: '1rem',
    width: 40,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: { ...desktopPadding },
    finalContainer: {
      padding: '0 56px',
    },
    emptyImage: {
      width: '50%',
      alignSelf: 'center',
      borderRadius: 10,
      marginTop: '3rem',
    },
  },
});
const Comments = () => {
  const classes = useStyles();
  const [jwt] = UseJWT();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewsAreLoading] = useGuideReviews(jwt?.guideId, setReviews);

  const isMobile = useIsMobile();

  if (reviewsAreLoading) return <Loader />;

  return (
    <motion.div
      className={classes.page}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <div
        className={classNames(
          classes.body,
          reviews.length > 0 ? classes.fill : undefined
        )}
      >
        <Heading level={2}>
          {!isMobile && <CommentsIcon className={classes.desktopPrefix} />}
          <Translate id="reviews.reviewsTitle" />
        </Heading>

        {reviews.length > 0 ? (
          <div className={classNames(classes.finalContainer)}>
            <Text size={16}>
              <Translate id="reviews.reviewsSubtitle" />
            </Text>
            {reviews.length > 0 &&
              reviews.map(r => (
                <CommentCard
                  key={r.id}
                  rating={r.rating}
                  date={moment(r.dateOfCreation)}
                  description={r.description}
                  routeTitle={r.routeTitle}
                  visitorImage={getVisitorImageUrl(r.visitorId)}
                  visitorName={r.visitorName}
                />
              ))}
          </div>
        ) : (
          <Text size={16}>
            <Translate id="reviews.emptySubtitle" />
          </Text>
        )}
      </div>
      {reviews.length === 0 && (
        <>
          <img
            src={require('../../../shared_assets/images/sem_comentarios.png')}
            alt="empty"
            className={classes.emptyImage}
          />
          <div className={classes.fill} />
        </>
      )}

      <CopyrightFooter />
    </motion.div>
  );
};

export default Comments;
