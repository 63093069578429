import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { ReactComponent as DefaultPediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Button } from '../Button';
import { FallbackImage } from '../Image';

const useListStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr auto 1fr auto',
    gap: '1rem',
    flexDirection: 'column',
    '& > *:nth-child(2)': {
      gridRow: 3,
    },
    '& > *:nth-child(3)': {
      gridRow: 2,
    },
  },
  testimony: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    height: '50vw',
    objectFit: 'cover',
    flex: 1,
    borderRadius: '10px',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr auto',
      '& > *:nth-child(2)': {
        gridRow: 'unset',
      },
      '& > *:nth-child(3)': {
        gridRow: 'unset',
      },
    },
    image: {
      height: 'calc(80vw / 2.5 - 1rem)',
    },
  },
});

export interface CallToActionProps {
  title: string;
  text: string;
  image: string;
  id: string;
  link: string;
  buttonText: string;
}

export interface CallToActionListProps {
  data: CallToActionProps[];
}

export function CallToActionList({ data }: CallToActionListProps) {
  const classes = useListStyles();
  return (
    <div className={classes.container}>
      {data.map(callToAction => (
        <FallbackImage
          fallbackComponent={<DefaultPediguia className={classes.image} />}
          className={classes.image}
          src={callToAction.image}
          alt="place"
        />
      ))}
      {data.map(callToAction => (
        <div className={classes.testimony}>
          <Heading level={4}>{callToAction.title}</Heading>
          <Text>{callToAction.text}</Text>
          <a href={callToAction.link}>
            <Button type="primary">{callToAction.buttonText}</Button>
          </a>
        </div>
      ))}
    </div>
  );
}
