import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { getGuideImageUrl, useGuide } from '../../../hooks/guide/UseGuide';
import { getVisitorImageUrl, useVisitor } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as CompleteWhite } from '../../../shared_assets/icons/complete white.svg';
import { ReactComponent as Pediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { ReactComponent as Visitante } from '../../../shared_assets/images/visitante_default.svg';
import { Guide } from '../../../types/guide.interface';
import { Visitor } from '../../../types/visitor.interface';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { FallbackAvatar } from '../Avatar';
import { Error } from '../Error';
import { FallbackImage } from '../Image';
import Loader from '../Loader/Loader';
import { Modal } from '../Modal';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  userContainer: () => ({
    display: 'flex',
    border: `2px solid ${getTheme().primary}`,
    borderRadius: 5,
    alignItems: 'center',
    padding: '1rem',
    marginBottom: '1rem',
    '&.active': {
      backgroundColor: getTheme().primary,
      pointerEvents: 'none',
    },
  }),
  middlePortion: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '0 1rem',
  },
  checked: {
    height: '2rem',
  },
});

interface ChangeAccountTypeModalProps {
  visible: boolean;
  onRequestClose: () => void;
}

const ChangeAccountTypeModal = ({
  visible,
  onRequestClose,
}: ChangeAccountTypeModalProps) => {
  const [jwt] = UseJWT();

  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const [guide, setGuide] = useState<Guide | null>(null);

  const [visitorIsLoading, visitorHasError] = useVisitor(
    jwt?.visitorId,
    setVisitor
  );
  const [guideIsLoading, guideHasError] = useGuide(jwt?.guideId, setGuide);

  const guideMatch = useRouteMatch({ path: '/guide', exact: false });

  const classes = useStyles();

  return (
    <Modal
      variant="close"
      footer={null}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      {guideIsLoading || visitorIsLoading ? (
        <Loader />
      ) : (guideHasError && jwt?.guideId) ||
        (visitorHasError && jwt?.visitorId) ? (
        <Error />
      ) : (
        <>
          <Link
            to="/guide"
            onClick={onRequestClose}
            className={classNames(
              classes.userContainer,
              guideMatch ? 'active' : undefined
            )}
          >
            <FallbackAvatar
              size="large"
              src={
                guide ? (
                  <FallbackImage
                    fallbackComponent={<Pediguia />}
                    src={getGuideImageUrl(guide.id)}
                    alt="pediguia"
                  />
                ) : (
                  <Pediguia />
                )
              }
            />
            <div className={classes.middlePortion}>
              <Text color={guideMatch ? 'white' : undefined} noMargin>
                {guide ? (
                  `${guide.firstName} ${guide.lastName}`
                ) : (
                  <Translate id="unauthenticated" />
                )}
              </Text>
              <Text color={guideMatch ? 'white' : undefined} noMargin>
                <Translate id="guide" />
              </Text>
            </div>
            {guideMatch && <CompleteWhite />}
          </Link>
          <Link
            onClick={onRequestClose}
            to="/visitor"
            className={classNames(
              classes.userContainer,
              !guideMatch ? 'active' : undefined
            )}
          >
            <FallbackAvatar
              size="large"
              src={
                visitor ? (
                  <FallbackImage
                    fallbackComponent={<Visitante />}
                    src={getVisitorImageUrl(visitor.id)}
                    alt="visitante"
                  />
                ) : (
                  <Visitante />
                )
              }
            />

            <div className={classes.middlePortion}>
              <Text color={!guideMatch ? 'white' : undefined} noMargin>
                {visitor ? (
                  `${visitor.firstName} ${visitor.lastName}`
                ) : (
                  <Translate id="unauthenticated" />
                )}
              </Text>
              <Text color={!guideMatch ? 'white' : undefined} noMargin>
                <Translate id="visitor" />
              </Text>
            </div>
            {!guideMatch && <CompleteWhite className={classes.checked} />}
          </Link>
        </>
      )}
    </Modal>
  );
};

export default React.memo(ChangeAccountTypeModal);
