import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import validator from 'validator';
import isTaxID from 'validator/lib/isTaxID';
import { store } from '../../../store/index';
import { FormEntry, SettableFormEntry } from '../../../types/form.interface';
import { CodeISOCountries } from '../../../types/iso-code-countries.enum';
import { Visitor } from '../../../types/visitor.interface';
type ProfileFormProperties =
  | 'firstName'
  | 'lastName'
  | 'birthDay'
  | 'birthMonth'
  | 'birthYear'
  | 'gender'
  | 'phoneNumber'
  | 'nif'
  | 'zip'
  | 'address'
  | 'location'
  | 'nationality';

export type PersonalProfileForm = Record<
  ProfileFormProperties,
  SettableFormEntry
>;

export const useProfileForm = (
  visitor?: Visitor | null
): PersonalProfileForm => {
  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const [birthDay, setBirthDay] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badBirthDate').toString(),
  });

  const [gender, setGender] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.genderMandatory').toString(),
  });

  const [birthMonth, setBirthMonth] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badBirthDate').toString(),
  });

  const [birthYear, setBirthYear] = useState<FormEntry>({
    value: '',
    isInvalid: false,
  });

  const [phoneNumber, setPhoneNumber] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badPhoneNumber').toString(),
  });

  const [firstName, setFirstName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.firstNameMandatory').toString(),
  });

  const [lastName, setLastName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.lastNameMandatory').toString(),
  });

  const [nif, setNif] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badNif').toString(),
  });

  const [location, setLocation] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badLocation').toString(),
  });

  const [zip, setZip] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badZip1NoLength').toString(),
  });

  const [nationality, setNationality] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.nationalityMandatory').toString(),
  });

  const [address, setAddress] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badAddress').toString(),
  });

  const setValidatedNationality = useCallback(
    (value: string) => {
      const isInvalid = false; //validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.nationalityMandatory').toString();
      }

      setNationality({ value, isInvalid, reason });
    },
    [setNationality, translate]
  );

  const setValidatedAddress = useCallback(
    (value: string) => {
      const isInvalid = false;
      validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badAddress').toString();
      }

      setAddress({ value, isInvalid, reason });
    },
    [setAddress, translate]
  );

  const setValidatedZip = useCallback(
    (value: string) => {
      let isInvalid = false;
      let reason: string | undefined = undefined;

      const zipSegments = value.split('-');
      const isFirstSegmentEmpty = validator.isEmpty(zipSegments[0]);
      const isSecondSegmentEmpty = validator.isEmpty(zipSegments[1]);

      if (!isSecondSegmentEmpty || !isFirstSegmentEmpty) {
        if (
          (!isFirstSegmentEmpty || !isSecondSegmentEmpty) &&
          zipSegments[0].length !== 4
        ) {
          reason = translate('profile.badZip1NoLength').toString();
          isInvalid = true;
        } else if (
          (!isFirstSegmentEmpty || !isSecondSegmentEmpty) &&
          zipSegments[1].length !== 3
        ) {
          reason = translate('profile.badZip2NoLength').toString();
          isInvalid = true;
        }
      }

      setZip({ isInvalid, reason, value });
    },
    [translate, setZip]
  );

  const setValidatedBirthDay = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value) && !validator.isNumeric(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badBirthDate').toString();
      }

      setBirthDay({ value, isInvalid, reason });
    },
    [setBirthDay, translate]
  );

  const setValidatedBirthMonth = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value) && !validator.isNumeric(value);

      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badBirthDate').toString();
      }

      setBirthMonth({ value, isInvalid, reason });
    },
    [setBirthMonth, translate]
  );

  const setValidatedGender = useCallback(
    (value: string) => {
      const isInvalid = false; //validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.genderMandatory').toString();
      }

      setGender({ value, isInvalid, reason });
    },
    [setGender, translate]
  );

  const setValidatedFirstName = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.firstNameMandatory').toString();
      }

      setFirstName({ value, isInvalid, reason });
    },
    [setFirstName, translate]
  );

  const setValidatedLastName = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.genderMandatory').toString();
      }

      setLastName({ value, isInvalid, reason });
    },
    [setLastName, translate]
  );

  const setValidatedBirthYear = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value) && !validator.isNumeric(value);

      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badBirthDate').toString();
      }

      setBirthYear({ value, isInvalid, reason });
    },
    [setBirthYear, translate]
  );

  const setValidatedPhoneNumber = useCallback(
    (value: string) => {
      const isInvalid = !!value && !validator.isMobilePhone(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badPhoneNumber').toString();
      }

      setPhoneNumber({ value, isInvalid, reason });
    },
    [setPhoneNumber, translate]
  );

  const setValidatedLocation = useCallback(
    (value: string) => {
      const isInvalid = false; //validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.mandatoryLocation').toString();
      }

      setLocation({ value, isInvalid, reason });
    },
    [setLocation, translate]
  );

  const setValidatedNif = useCallback(
    (value: string) => {
      let isInvalid = false;
      let reason: string | undefined = undefined;

      if (value !== '') {
        const countryCode = value.substring(0, 2);
        const nif = value.substring(2, value.length);

        switch (true) {
          case Object.keys(CodeISOCountries).includes(countryCode) &&
            nif !== '':
            if (countryCode === 'PT' && !isTaxID(nif, 'pt-PT')) {
              isInvalid = true;
              reason = translate('profile.badNif').toString();
            }
            break;
          case Object.keys(CodeISOCountries).includes(countryCode) &&
            nif === '':
            isInvalid = true;
            reason = translate('profile.noNIFInserted').toString();
            break;
        }
      }
      setNif({ value, isInvalid, reason });
    },
    [translate]
  );

  useEffect(() => {
    if (!visitor) return;

    setValidatedBirthYear(visitor.dateOfBirth?.getFullYear().toString() || '');
    setValidatedBirthMonth(visitor.dateOfBirth?.getMonth().toString() || '');
    setValidatedBirthDay(visitor.dateOfBirth?.getDay().toString() || '');
    setValidatedPhoneNumber(visitor.phoneNumber?.toString() || '');
    setValidatedLastName(visitor.lastName);
    setValidatedFirstName(visitor.firstName);

    setValidatedAddress(visitor.address || '');
    setValidatedGender(visitor.gender || '');
    setValidatedLocation(visitor.location || '');
    setValidatedNationality(visitor.nationality || '');
    setValidatedNif(visitor.nif?.toString() || '');
    setValidatedZip(visitor.postalCode || '-');
  }, [
    visitor,
    setValidatedBirthMonth,
    setValidatedBirthYear,
    setValidatedGender,
    setValidatedBirthDay,
    setValidatedPhoneNumber,
    setValidatedLastName,
    setValidatedFirstName,
    setValidatedLocation,
    setValidatedNationality,
    setValidatedNif,
    setValidatedZip,
    setValidatedAddress,
  ]);

  return {
    firstName: {
      ...firstName,
      set: setValidatedFirstName,
    },
    lastName: {
      ...lastName,
      set: setValidatedLastName,
    },
    gender: {
      ...gender,
      set: setValidatedGender,
    },
    phoneNumber: {
      ...phoneNumber,
      set: setValidatedPhoneNumber,
    },
    birthDay: {
      ...birthDay,
      set: setValidatedBirthDay,
    },
    birthMonth: {
      ...birthMonth,
      set: setValidatedBirthMonth,
    },
    birthYear: {
      ...birthYear,
      set: setValidatedBirthYear,
    },
    nationality: {
      ...nationality,
      set: setValidatedNationality,
    },
    address: {
      ...address,
      set: setValidatedAddress,
    },
    location: {
      ...location,
      set: setValidatedLocation,
    },
    nif: {
      ...nif,
      set: setValidatedNif,
    },
    zip: {
      ...zip,
      set: setValidatedZip,
    },
  };
};
