import Axios from 'axios';
import { API_URL } from '../../consts';
import { TestimoniesTitleInterface } from './useTestimony';

export interface TestimonyDto {
  testimoniesIds: string[];
  testimoniesTitles: TestimoniesTitleInterface[];
}

export const postTestimonyAboutPage = (dto: TestimonyDto) => {
  return Axios.post(`${API_URL}/content-management/about-page/testimony`, dto)
    .catch(err => {
      return Promise.reject(err.response.data);
    })
    .then(success => {
      return Promise.resolve(success);
    });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
