import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

export interface TestimoniesTitleInterface {
  title: string;
  language: string;
}

type Hook = (
  setTestimoniesTitle: (
    val: SetStateAction<TestimoniesTitleInterface[]>
  ) => void
) => [boolean, boolean];

export const requestRoutes = () =>
  Axios.get(`${API_URL}/content-management/about-page/testimonies-titles`);

export const useTestimonyTitles: Hook = setTestimoniesTitle => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: testimoniesTitles } = await requestRoutes();
        setTestimoniesTitle(testimoniesTitles as TestimoniesTitleInterface[]);
      } catch (err) {
        setTestimoniesTitle([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setTestimoniesTitle]);

  return [isLoading, isError];
};
