import message from 'antd/es/message';
import Axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { API_URL } from '../../consts';
import { RootState } from '../../store';
import { VisitorFormProperty } from '../../types/visitor.interface';
import { SignUpDto } from '../guide/CreateGuide';
import { getVisitorErrorMessagesFromField } from './VisitorUtils';

type registerResult = ThunkAction<Promise<void>, RootState, undefined, Action>;

export interface VisitorSignUpProfileDto {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  agreeTerms: boolean;
  agreeMarketing: boolean;
}

export interface SignUpProfileDto extends SignUpDto {
  signUpProfile: VisitorSignUpProfileDto;
}

export const attemptSignUp =
  (signUpDto: SignUpProfileDto): registerResult =>
  (dispatch, getState) => {
    return Axios.post(`${API_URL}/authentication/sign-up`, signUpDto)
      .then(() => {})
      .catch(err => {
        if (
          err &&
          err.response &&
          err.response.data &&
          Array.isArray(err.response.data.message)
        ) {
          err.response.data.message.forEach(
            (t: { property: VisitorFormProperty }) => {
              message.error(getVisitorErrorMessagesFromField(t.property));
            }
          );
        }

        return Promise.reject(err);
      });
  };
