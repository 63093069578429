import Axios from 'axios';
import { API_URL } from '../../consts';

export interface EditScheduleRepetitionDto {
  newDate: Date;
  newReserveLimit: number;
  giveAway: boolean;
}

export const editScheduleRepetition = (
  id: string,
  dto: EditScheduleRepetitionDto
) => Axios.put(`${API_URL}/schedule/repetition/${id}`, dto);
