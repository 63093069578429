import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton } from '../../../components/App/BackButton';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import { Loader } from '../../../components/App/Loader';
import { PublicProfile as ProfileComponent } from '../../../components/App/PublicProfile';
import { useGuideReviews } from '../../../hooks/guide/review/UseGuideReviews';
import { useGuidePublicRoutes } from '../../../hooks/guide/UseGuidePublicRoutes';
import { usePublicGuide } from '../../../hooks/guide/UsePublicGuide';
import { PartialRouteInterface } from '../../../hooks/routes/UseSimilarRoutes';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { GuidePublicProfile } from '../../../types/guide-public-profile.interface';
import { Review } from '../../../types/review.interface';
import { desktopPadding, headerHeight, mobilePadding, mobileThreshhold, useIsMobile } from '../../../utils';

// import { Header } from '../../../components/App/Header';
const useStyles = makeStyles({
  page: {
    ...mobilePadding,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    maxHeight: headerHeight,
    height: headerHeight,
    display: 'flex',
    alignItems: 'center',
    '& > img, & > svg': {
      height: '25%',
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  backZone: {
    flex: 0.2,
    minWidth: 125,
  },
  fill: {
    flex: 1,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
      flexDirection: 'row',
      flex: 0.8,
    },
    body: {
      flexDirection: 'row',
    },
  },
});

function PublicProfile() {
  const params = useParams<{ id: string }>();
  const [guideProfile, setGuideProfile] = useState<GuidePublicProfile | null>(
    null
  );
  const classes = useStyles();
  const [isLoading, hasError] = usePublicGuide(params.id, setGuideProfile);
  const [routes, setRoutes] = useState<PartialRouteInterface[]>([]);
  const [areRoutesLoading, routeHaveError] = useGuidePublicRoutes(
    params.id,
    setRoutes
  );
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewsAreLoading, reviewsHaveError] = useGuideReviews(
    params.id,
    setReviews
  );
  const history = useHistory();
  const isMobile = useIsMobile();

  if (isLoading || areRoutesLoading || reviewsAreLoading) {
    return <Loader />;
  }

  if (hasError || routeHaveError || reviewsHaveError || !guideProfile)
    return <Error />;

  return (
    <>
      <div className={classes.page}>
        {isMobile && (
          <div className={classes.header}>
            <div onClick={history.goBack}>
              <ArrowBack />
            </div>
          </div>
        )}
        {!isMobile && (
          <div className={classes.backZone}>
            <BackButton />
          </div>
        )}
        <ProfileComponent
          reviews={reviews}
          guide={guideProfile}
          guideRoutes={routes}
        />
      </div>
      <div className={classes.fill} />
      <CopyrightFooter />
    </>
  );
}

export default PublicProfile;
