import { makeStyles } from '@material-ui/styles';
import { Divider, Space, Table } from 'antd';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { Loader } from '../../../../../../components/App/Loader';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { Text } from '../../../../../../components/Typography';
import { useGuideSelection } from '../../../../../../hooks/administration/useGuideSelection';
import { useJoinUs } from '../../../../../../hooks/administration/useJoinUs';
import {
  JoinUsInterface,
  postGuideSelection,
} from '../../../../../../hooks/administration/usePostGuideSelection';
import { useSelectedGuides } from '../../../../../../hooks/administration/useSelectedGuides';
import { ErrorCode } from '../../../../../../types/error.codes.enum';
import { ListGuidesAdminInterface } from '../../../../../../types/list-guide.interface';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  divider: {
    margin: '1rem 0',
  },
});

const columns = [
  {
    title: <Translate id="adminstration.guideSelection.guideName" />,
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: <Translate id="adminstration.guideSelection.guideGender" />,
    dataIndex: 'gender',
    key: 'gender',
  },

  {
    title: <Translate id="adminstration.guideSelection.guideEmail" />,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <Translate id="adminstration.guideSelection.phoneNumber" />,
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: <Translate id="adminstration.guideSelection.companyName" />,
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: <Translate id="adminstration.nif" />,
    dataIndex: 'nif',
    key: 'nif',
  },
  {
    title: <Translate id="adminstration.actions" />,
    key: 'action',
    render: (text: any, record: any) => (
      <Space size="middle">
        <Link to={`/admin/area/guide/${record.key}`}>
          <Translate id="adminstration.showInformation" />
        </Link>
        <Divider type="vertical" />

        <Link to={`/admin/area/guide/${record.key}/list/routes`}>
          <Translate id="adminstration.showRoutes" />
        </Link>
      </Space>
    ),
  },
];

interface GuideSelectionPageProps extends LocalizeContextProps {}
const GuideSelection = ({ languages, translate }: GuideSelectionPageProps) => {
  const classes = useStyles();
  const [guides, setListGuides] = useState<ListGuidesAdminInterface[]>([]);
  const [fetchingGuides] = useGuideSelection(setListGuides);
  const [selectedGuides, setSelectedGuides] = useState<string[]>([]);
  const [joinUsTexts, setJoinUsTexts] = useState<JoinUsInterface[]>([]);
  const [joinUsTextsLocal, setJoinUsTextsLocal] = useState<JoinUsInterface[]>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchingTexts, errorFetchingTexts] = useJoinUs(setJoinUsTexts);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchingSelectedRoutes, hasFetchingError] =
    useSelectedGuides(setSelectedGuides);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [languagesLocalLanguages, setLanguagesLocalLanguages] = useState(
    languages.map(l => l.code)
  );
  useEffect(() => {
    setJoinUsTextsLocal(
      languages
        .map(la => la.code)
        .map(l => {
          const landingPageTranslation = joinUsTexts.find(
            lp => lp.language === l
          );
          if (landingPageTranslation) {
            return landingPageTranslation;
          }
          return {
            joinTitle: '',
            joinText: '',
            toKnowMoreText: '',
            toKnowMoreRedirectUrl: '',
            language: l,
          };
        })
    );
  }, [joinUsTexts, languages]);
  const tableData = guides.map(g => {
    return {
      key: g.id,
      name: g.name,
      nif: g.nif,
      companyName: g.companyName,
      email: g.email,
      gender: g.gender,
      state: g.state,
      phoneNumber: g.phoneNumber,
    };
  });
  const submit = useCallback(async () => {
    if (
      joinUsTextsLocal.some(
        s =>
          validator.isEmpty(s.joinText) ||
          validator.isEmpty(s.joinTitle) ||
          validator.isEmpty(s.toKnowMoreRedirectUrl) ||
          validator.isEmpty(s.toKnowMoreText)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    try {
      await postGuideSelection({
        guideIds: selectedGuides,
        joinUsTexts: joinUsTextsLocal,
      });
      message.success(
        translate('adminstration.guideSelection.success').toString()
      );
    } catch (error) {
      message.error(
        `${translate(
          `error.API_ERROR.${ErrorCode.LANDING_PAGE_NEED_TRANSLATION}`
        )} ${translate(`languages.${error.message}`)}`
      );
    }
  }, [joinUsTextsLocal, selectedGuides, translate]);
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (selectedRowKeys.length <= 2) {
        setSelectedGuides(selectedRowKeys);
      }
    },
    selectedRowKeys: selectedGuides,
    hideSelectAll: true,
    columnTitle: 'Selecionar',
  };

  if (fetchingGuides) return <Loader />;

  return (
    <>
      {/* *****************ANCHOR call to action text************************ */}
      <Text noMargin weight="semibold">
        <Translate id="adminstration.guideSelection.pageTitle" />
      </Text>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={tableData}
        columns={columns}
      />
      <Divider className={classes.divider} />
      <Tabs type="card">
        {joinUsTextsLocal.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideSelection.joinTitle" />
              </Text>

              <Input
                value={state.joinTitle}
                onChange={ev => {
                  ev.persist();
                  setJoinUsTextsLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          joinTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.joinTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideSelection.joinText" />
              </Text>
              <Input
                value={state.joinText}
                onChange={ev => {
                  ev.persist();
                  setJoinUsTextsLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          joinText: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.joinText)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideSelection.toKnowMoreText" />
              </Text>
              <Input
                value={state.toKnowMoreText}
                onChange={ev => {
                  ev.persist();
                  setJoinUsTextsLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          toKnowMoreText: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.toKnowMoreText)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />

              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideSelection.toKnowMoreRedirectUrl" />
              </Text>
              <Input
                value={state.toKnowMoreRedirectUrl}
                onChange={ev => {
                  ev.persist();
                  setJoinUsTextsLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          toKnowMoreRedirectUrl: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.toKnowMoreRedirectUrl)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </>
  );
};
export default withLocalize(GuideSelection);
