import { makeStyles } from '@material-ui/styles';
import Calendar from 'antd/es/calendar';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/App/Button';
import { Error } from '../../../components/App/Error';
import { Loader } from '../../../components/App/Loader';
import { Modal } from '../../../components/App/Modal';
import { Heading, Text } from '../../../components/Typography';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useRouteSchedulesPublic } from '../../../hooks/schedule/UseRouteSchedulesPublic';
import { ReactComponent as Back } from '../../../shared_assets/icons/arrow_back.svg';
import { ReactComponent as BackGrey } from '../../../shared_assets/icons/back_grey.svg';
import { ReactComponent as Forward } from '../../../shared_assets/icons/forward_black.svg';
import { ReactComponent as ForwardGrey } from '../../../shared_assets/icons/forward_grey.svg';
import { Schedule } from '../../../types/schedule-new';
import { getTheme, mobileThreshhold, useIsMobile } from '../../../utils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  dateContainer: () => ({
    display: 'flex',
    marginTop: '2rem',
    '& .ant-picker-panel': {
      borderTop: 'none',
    },
    '& .ant-picker-content th': {
      color: getTheme().primary,
      fontWeight: 'bold',
      padding: '0.5rem 0 1rem 0',
    },
    '& .ant-picker-cell-disabled': {
      visibility: 'hidden',
    },
  }),
  dayContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateHeader: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    '& > h1': {
      fontWeight: `${600} !important` as unknown as number,
    },
    '& > div': {
      margin: '0 0.5rem',
      '&:last-child': {
        marginRight: 0,
      },
      '&:first-child': {
        marginLeft: 0,
        marginRight: '2rem',
      },
    },
  },
  disabled: () => ({
    color: getTheme().neutral4,
  }),
  grey: () => ({
    color: getTheme().neutral2,
  }),
  bold: () => ({
    fontWeight: 'bold',
    color: getTheme().defaultTextPrimaryColor,
  }),
  icon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '1.5rem',
  },
  dateCell: ({ width }: { width: number | undefined }) => ({
    '&.selected': {
      backgroundColor: getTheme().primary,
      color: 'white',
    },
    borderRadius: '50%',
    padding: 5,
    width,
  }),
  schedulesContainer: {
    // marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      '& > .ant-typography': {
        marginBottom: '1.5rem',
      },
      '& > div': {
        marginBottom: '3rem',
        '& > div ': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      },
    },
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      '& .ant-divider': {
        margin: '0 1rem',
        height: 'unset',
      },
      flexDirection: 'row',
      '& > :first-child': {
        flex: 1,
        //marginRight: '3rem',
      },
      '& > :last-child': {
        flex: 1,
      },
    },
    schedulesContainer: {
      marginTop: '2rem',
    },
  },
});

function getCurrDayAndMonth(date: moment.Moment) {
  var today = date.format('LL');
  return today
    .replace(date.format('YYYY'), '') // remove year
    .replace(/\s\s+/g, ' ') // remove double spaces, if any
    .trim() // remove spaces from the start and the end
    .replace(/[рг]\./, '') // remove year letter from RU/UK locales
    .replace(/de$/, '') // remove year prefix from PT
    .replace(/b\.$/, '') // remove year prefix from SE
    .trim() // remove spaces from the start and the end
    .replace(/,$/g, ''); // remove comma from the end
}

interface RouteDatesModalProps extends LocalizeContextProps {
  monthLimit: number;
  routeId: string;
  price: number;
  duration: number;
  isVisible: boolean;
  onClose: () => void;
  openLogin: () => void;
}

function RouteDatesModal({
  translate,
  monthLimit,
  routeId,
  price,
  duration,
  isVisible,
  onClose,
  openLogin,
}: RouteDatesModalProps) {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [jwt] = UseJWT();
  const [cellHeight, setCellHeight] = useState<number | undefined>(undefined);
  const classes = useStyles({ width: cellHeight });
  const [schedules, setSchedules] = useState<Schedule[]>([]);

  const [isLoading, hasError] = useRouteSchedulesPublic(routeId, setSchedules);
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(0);
  const divRef = useRef<HTMLDivElement | null>(null);

  const schedulesRoute = useMemo(
    () =>
      schedules
        .map(schedule =>
          schedule.repetitions.map(repetitionSchedule => ({
            id: repetitionSchedule.id,
            date: moment(repetitionSchedule.date),
            price:
              repetitionSchedule.adultPrice === 0
                ? translate('routes.giveAway')
                : repetitionSchedule.adultPrice,
          }))
        )
        .flat(),
    [schedules, translate]
  );

  const schedulesSelected = useMemo(
    () =>
      selectedDates
        .map(date => {
          return {
            date,
            schedules: schedulesRoute
              .filter(schedule => date.isSame(schedule.date, 'day'))
              .sort((a, b) => a.date.diff(b.date)),
          };
        })
        .sort((a, b) => a.date.diff(b.date)),
    [selectedDates, schedulesRoute]
  );

  const resizeCell = useCallback(() => {
    if (!isVisible) {
      return;
    }
    const tempCellHeight = divRef.current?.children[0].clientHeight;

    if (tempCellHeight !== cellHeight) setCellHeight(tempCellHeight);
  }, [isVisible, cellHeight /*, divRef.current*/]);

  function prevMonth(
    value: moment.Moment,
    onChange: (date: moment.Moment) => void
  ) {
    if (currentCalendarMonth > 0) {
      const newValue = value.clone();
      newValue.subtract(1, 'month');
      const tmp = [...selectedDates];
      onChange(newValue);
      setSelectedDates(tmp);
      setCurrentCalendarMonth(prevState => prevState - 1);
    }
  }

  function nextMonth(
    value: moment.Moment,
    onChange: (date: moment.Moment) => void
  ) {
    const newValue = value.clone();
    newValue.add(1, 'month');
    const tmp = [...selectedDates];
    onChange(newValue);
    setSelectedDates(tmp);
    setCurrentCalendarMonth(prevState => prevState + 1);
  }

  function doesDateHaveSchedules(dateImLookingFor: moment.Moment) {
    return schedulesRoute.some(schedule => {
      return dateImLookingFor.isSame(schedule.date.toString(), 'day');
    });
  }

  function isDateSelected(dateImLookingFor: moment.Moment) {
    return selectedDates.some(date => {
      return dateImLookingFor.isSame(date.toString(), 'day');
    });
  }

  function onSelect(date: moment.Moment) {
    const hasSchedules =
      doesDateHaveSchedules(date) && date.isSameOrAfter(moment(), 'day');

    if (hasSchedules) {
      if (!isDateSelected(date) && selectedDates.length !== 2) {
        setSelectedDates(prevState => [...prevState, date]);
      } else if (isDateSelected(date)) {
        setSelectedDates(prevState =>
          prevState.filter(
            (filterDate, i) => !filterDate.isSame(date.toString(), 'day')
          )
        );
      }
    }
  }

  function dateCellRender(date: moment.Moment) {
    const hasSchedules = doesDateHaveSchedules(date);
    const isSelected = isDateSelected(date);
    const actualDate = moment();
    return (
      <div
        ref={ref => {
          if (
            date.isBetween(
              moment().add(currentCalendarMonth, 'month').startOf('month'),
              moment().add(currentCalendarMonth, 'month').endOf('month')
            ) &&
            !divRef.current
          ) {
            divRef.current = ref;
            resizeCell();
          }
        }}
      >
        <div className={classes.dayContainer}>
          <span
            className={classNames(
              classes.dateCell,
              date.isBefore(actualDate, 'day')
                ? classes.disabled
                : isSelected
                ? 'selected'
                : hasSchedules
                ? classes.bold
                : classes.grey
            )}
          >
            {date.format('D')}
          </span>
        </div>
      </div>
    );
  }

  if (isLoading) return <Loader />;

  if (hasError) return <Error />;

  return (
    <Modal
      widthModal={!isMobile ? '50%' : undefined}
      visible={isVisible}
      variant="close"
      onRequestClose={() => {
        setSelectedDates([]);
        setCurrentCalendarMonth(0);
        onClose();
      }}
      headerTitle={
        <div style={{ padding: '1rem 0' }}>
          <Heading level={4}>
            <Translate id={'routeDate.title'} />
          </Heading>
          <Text variant="faded" weight="semibold" noMargin>
            <Translate id={'routeDate.subtitle'} />
          </Text>
        </div>
      }
      footer={null}
    >
      <div className={classes.container}>
        <div className={classes.dateContainer}>
          {isVisible && (
            <Calendar
              dateFullCellRender={dateCellRender}
              validRange={[
                moment().add(currentCalendarMonth, 'month').startOf('month'),
                moment().add(currentCalendarMonth, 'month').endOf('month'),
              ]}
              // disabledDate={(current) => {
              //   return (
              //     current <
              //       moment()
              //         .add(currentCalendarMonth, 'month')
              //         .startOf('month') ||
              //     current >
              //       moment().add(currentCalendarMonth, 'month').endOf('month')
              //   );
              // }}
              onSelect={date => onSelect(date)}
              fullscreen={false}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                return (
                  <div className={classes.dateHeader}>
                    <Heading level={5}>
                      {moment()
                        .add(currentCalendarMonth, 'month')
                        .format('MMMM YYYY')}
                    </Heading>
                    <div>
                      {currentCalendarMonth === 0 ? (
                        <BackGrey className={classes.icon} />
                      ) : (
                        <Back
                          onClick={() => {
                            prevMonth(value, onChange);
                          }}
                          className={classes.icon}
                        />
                      )}
                      {currentCalendarMonth >= monthLimit ? (
                        <ForwardGrey className={classes.icon} />
                      ) : (
                        <Forward
                          onClick={() => {
                            nextMonth(value, onChange);
                          }}
                          className={classes.icon}
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            />
          )}
        </div>

        <Divider type="vertical" />
        <div className={classes.schedulesContainer}>
          {schedulesSelected.map((scheduleSelected, i) => (
            <div key={i.toString()}>
              <Text weight="semibold">{`${moment(scheduleSelected.date).format(
                'dddd'
              )}, ${getCurrDayAndMonth(scheduleSelected.date)}`}</Text>

              <div>
                {scheduleSelected.schedules.map((selected, index) => (
                  <Fragment key={index.toString()}>
                    <div>
                      <div>
                        <Text weight="bold" noMargin>
                          {`${moment(selected.date).format(
                            'HH:mm[h]'
                          )}-${moment(selected.date)
                            .add(duration, 'minutes')
                            .format('HH:mm[h]')}`}
                        </Text>
                        <Text
                          variant="note"
                          color={getTheme().neutral1}
                          noMargin
                        >
                          {selected.price === translate('routes.giveAway')
                            ? translate('routes.giveAway')
                            : translate('routeDate.valuePerPerson')
                                .toString()
                                .replace(
                                  '{value}',
                                  selected.price
                                    ? selected.price.toString()
                                    : price.toString()
                                )}
                        </Text>
                      </div>
                      {/* {jwt?.visitorId && (
                        <RouteBooking
                          routeId={routeId}
                          title={title}
                          photoId={photoId}
                          price={price}
                          dateId={selected.id}
                          date={selected.date}
                          isVisible={isShowingModal}
                          onClose={() => setIsShowingModal(false)}
                        />
                      )} */}
                      <Button
                        type="ghost"
                        onClick={() => {
                          if (!jwt?.visitorId) {
                            openLogin();
                            onClose();
                          } else {
                            history.push(
                              `/booking/${routeId}/${selected.id}/`,
                              { date: selected.date.toJSON() }
                            );
                          }
                        }}
                      >
                        <Translate id="choose" />
                      </Button>
                    </div>
                    {index !== scheduleSelected.schedules.length - 1 && (
                      <Divider style={{ margin: '1rem 0' }} />
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(withLocalize(RouteDatesModal));
