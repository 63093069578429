/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import Axios from 'axios';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect, useParams } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../components/App/AnimatedError';
import { Error } from '../../../../components/App/Error';
import { TextArea } from '../../../../components/App/Input';
import { LanguageSelection } from '../../../../components/App/LanguageSelection';
import { Loader } from '../../../../components/App/Loader';
import { Modal } from '../../../../components/App/Modal';
import { ProfileStrip } from '../../../../components/App/ProfileStrip';
import { SkillSelection } from '../../../../components/App/SkillSelection';
import { TabPane, Tabs } from '../../../../components/App/Tabs';
import { Text } from '../../../../components/Typography';
import { API_URL } from '../../../../consts';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { useCompanyGuide } from '../../../../hooks/company/useCompanyGuide';
import { getGuideImageUrl } from '../../../../hooks/guide/UseGuide';
import { GuideStateType } from '../../../../types/guide-state-type.enum';
import { GuideDescriptionDto } from '../../../../types/guide.dtos';
import { Guide } from '../../../../types/guide.interface';
import { makeEnumArray, SkillType } from '../../../../types/skill-type.enum';
import {
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
} from '../../../../utils';
import { GenericPageTransition } from '../../../PageUtils';

// ANCHOR: IMPORTS
interface FormState {
  editing: boolean;
  guideId: string;
}
interface ErrorInterface {
  error: boolean;
  reason: string;
}
interface EvaluateDto {
  state: GuideStateType;
  description: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
const useStyles = makeStyles({
  fill: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerDivider: {
    margin: 0,
    marginTop: 1,
  },
  statsContainer: {
    display: 'flex',
  },
  statItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > p:last-child': {
      fontWeight: 'bold',
    },
  },
  noMargin: {
    margin: 0,
  },
  divider: {
    margin: '1rem 0',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      flexDirection: 'row',
    },
    visitorSection: {
      flex: 0.4,
      boxShadow: '0px 0px 9px 4px rgba(0,0,0,0.1)',
      padding: '1rem',
      marginRight: '4rem',
      height: '100%',
      borderRadius: 10,
      maxWidth: 360,
    },
    infoSection: {
      flex: 0.8,
    },
  },
  evaluateApproveButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
    marginLeft: '50px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
interface ParamsInterface {
  id: string;
}

interface InformationProps extends LocalizeContextProps {}
const CompanyGuideInformation = ({ translate }: InformationProps) => {
  const classes = useStyles();

  const [guideProfile, setGuideProfile] = useState<Guide | null>(null);

  const [jwt] = UseJWT();
  const params = useParams<ParamsInterface | undefined>();

  const [isLoading, hasError] = useCompanyGuide(params?.id, setGuideProfile);

  const [reason, setReason] = useState('');

  const [evaluateGuide, setEvaluateGuide] = useState<boolean>(false);
  const [descriptions, setDescriptions] = useState<GuideDescriptionDto[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  useEffect(() => {
    if (!guideProfile) return;

    setDescriptions(guideProfile.descriptions || []);
    setSelectedLanguages((guideProfile.languages || []).map(l => l.language));
    setSelectedSkills(
      (guideProfile.skills || [])
        .map((sk: any) => sk.skillType)
        .filter((ss: any) => makeEnumArray(SkillType).includes(ss))
    );
    //EVALUATE
    setEvaluateGuide(false);
    setReason('');
  }, [guideProfile, translate]);

  const evalError = useMemo((): ErrorInterface => {
    if (validator.isEmpty(reason)) {
      return {
        error: true,
        reason: 'Reason should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [reason]);
  //****************************************************************** */
  const approveGuideCallBack = useCallback(async () => {
    if (!guideProfile) return;

    try {
      await Axios.patch(
        `${API_URL}/company/evaluation/guide/${guideProfile.id}`,
        {
          state: GuideStateType.APPROVED,
          description: 'APPROVED',
        }
      );

      try {
        await Axios.post(`${API_URL}/partner/${guideProfile.id}`);
      } catch (error) {}

      setReason('');
      setEvaluateGuide(false);
      message.success(translate('adminstration.eval').toString());
    } catch (err) {
      console.log('err', err);
    }
  }, [guideProfile, translate]);

  const noApproveGuideCallBack = useCallback(async () => {
    if (!guideProfile) return;
    if (evalError.error) return;
    try {
      await Axios.patch(
        `${API_URL}/company/evaluation/guide/${guideProfile.id}`,
        {
          state: GuideStateType.NOT_APPROVED,
          description: reason,
        }
      );

      setReason('');
      setEvaluateGuide(false);
      message.success(translate('adminstration.eval').toString());
    } catch (err) {}
  }, [guideProfile, evalError.error, reason, translate]);

  if (!guideProfile || isLoading) {
    return <Loader />;
  }
  if (!jwt) {
    return <Redirect to="/company/landing" />;
  }

  if (hasError) return <Error />;
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.body}>
        <div className={classes.visitorSection}>
          <ProfileStrip
            imageSource={getGuideImageUrl(guideProfile.id)}
            name={`${guideProfile.firstName} ${guideProfile.lastName}`}
            memberSince={guideProfile.dateOfCreation}
            type="guide"
          />
          <Divider className={classes.divider} />
        </div>
        <div className={classes.infoSection}>
          <Text noMargin weight="semibold">
            <Translate id="profile.name" />
          </Text>
          <Text variant="faded" noMargin>
            {`${guideProfile.firstName} ${guideProfile.lastName}`}
          </Text>
          <Divider className={classes.divider} />
          <Text noMargin weight="semibold">
            <Translate id="profile.email" />
          </Text>
          <Text variant="faded" noMargin>
            {guideProfile.email}
          </Text>
          <Divider className={classes.divider} />
          <Text noMargin weight="semibold">
            <Translate id="profile.birthDate" />
          </Text>
          <Text variant="faded" noMargin>
            {moment(guideProfile.dateOfBirth).format('L')}
          </Text>
          <Divider className={classes.divider} />
          <Text noMargin weight="semibold">
            <Translate id="profile.phoneNumber" />
          </Text>
          <Text noMargin variant="faded">
            {guideProfile.phoneNumber || <Translate id="notDefined" />}
          </Text>
          <Divider className={classes.divider} />
          <Text noMargin weight="semibold">
            <Translate id="profile.gender" />
          </Text>
          <Text noMargin variant="faded">
            {guideProfile.gender ? (
              <Translate id={`genders.${guideProfile.gender}`} />
            ) : (
              <Translate id="notDefined" />
            )}
          </Text>
          <Divider className={classes.divider} />

          <Text noMargin weight="semibold">
            <Translate id="profile.nationality" />
          </Text>
          <Text noMargin variant="faded">
            {guideProfile.nationality ? (
              <Translate id={`nationalities.${guideProfile.nationality}`} />
            ) : (
              <Translate id="notDefined" />
            )}
          </Text>
          <Divider className={classes.divider} />

          <Text noMargin weight="semibold">
            <Translate id="profile.address" />
          </Text>
          <Text noMargin variant="faded">
            {`${guideProfile.address || ''} ${guideProfile.postalCode || ''} ${
              guideProfile.location || ''
            }`.trim() || <Translate id="notDefined" />}
          </Text>
          <Divider className={classes.divider} />
          <Text weight="semibold">
            <Translate id="profile.spokenLanguages" />
          </Text>
          <LanguageSelection disabled={true} value={selectedLanguages} />
          <Divider className={classes.divider} />

          <Text weight="semibold">
            <Translate id="profile.skills" />
          </Text>
          <SkillSelection
            disabled={true}
            value={selectedSkills}
            onChange={setSelectedSkills}
          />
          <Divider className={classes.divider} />
          <Text weight="semibold">
            <Translate id="profile.profileDescription" />
          </Text>
          <Tabs>
            {descriptions.map(iteratedLanguage => (
              <TabPane
                key={iteratedLanguage.language}
                tab={translate(`languages.${iteratedLanguage.language}`)}
              >
                <TextArea
                  disabled={true}
                  value={iteratedLanguage.description}
                  rows={10}
                />
              </TabPane>
            ))}
          </Tabs>
          <Divider />

          <div className={classes.evaluateButton}>
            {/* //ANCHOR MODAL */}
            <Modal
              fullScreenOnMobile={false}
              contentContainerClassName={classes.modalContent}
              headerTitle={<Translate id="button.addSkill" />}
              footer={
                <div className={classes.footer}>
                  <Button
                    onClick={() => {
                      setReason('');
                      setEvaluateGuide(false);
                    }}
                  >
                    <Translate id="button.cancel" />
                  </Button>
                  <div className={classes.addRemoveBtn}>
                    <Button
                      type="primary"
                      onClick={() => {
                        noApproveGuideCallBack();
                      }}
                    >
                      <Translate id="button.save" />
                    </Button>
                  </div>
                </div>
              }
              visible={evaluateGuide}
              closable={false}
            >
              <Text weight="semibold">
                <Translate id="adminstration.reason" />
              </Text>
              <TextArea
                value={reason}
                rows={10}
                onChange={ev => {
                  ev.persist();
                  setReason(ev.target.value);
                }}
              />
              <AnimatedError
                isVisible={evalError.error}
                reason={evalError.reason}
              />
            </Modal>
            <Button
              type="primary"
              shape="round"
              className={classes.cancelButton}
              size={'large'}
              onClick={ev => {
                setEvaluateGuide(true);
              }}
            >
              <Translate id="adminstration.notApprove" />
            </Button>
            <Button
              type="primary"
              shape="round"
              className={classes.evaluateApproveButton}
              size={'large'}
              onClick={ev => {
                approveGuideCallBack();
              }}
            >
              <Translate id="adminstration.approve" />
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(CompanyGuideInformation);
