import Axios from 'axios';

import { API_URL } from '../../../consts';
import { GetVisitorStepsResponse } from './useSteps';

type DeleteVisitorStepsDto = { position: number };

export const deleteVisitorStep = (dto: DeleteVisitorStepsDto) =>
  Axios.delete<GetVisitorStepsResponse>(
    `${API_URL}/content-management/visitor-landing-page/steps`,
    { data: dto }
  );
