import message from 'antd/es/message';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { API_URL } from '../../consts';
import { store } from '../../store';
import { LastAppZone } from '../../store/App/types';
import { login } from '../../store/UserConfiguration/actions';

type Hook = (
  token: string | undefined,
  appZone: LastAppZone
) => [boolean, boolean];

const attemptConfirmEmail = (token: string, appZone: LastAppZone) =>
  Axios.patch<any>(
    `${API_URL}/authentication/confirm-email/${token}/${appZone}`
  );

export const useEmail: Hook = (token, appZone) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!token || token === '') {
        setIsError(true);
        setIsLoading(false);
        return;
      }

      const translate = getTranslate(store.getState().localize);
      try {
        const response = await attemptConfirmEmail(token, appZone);

        Axios.defaults.headers['Authorization'] =
          'Bearer ' + response.data.accessToken;

        if (appZone === 'guide') {
          history.replace('/guide');
        } else if (appZone === 'visitor') {
          history.replace('/visitor');
        } else if (appZone === 'company') {
          history.replace('/company/landing');
        } else {
          history.replace('/');
        }
        if (appZone !== 'company') {
          dispatch(login(response.data.accessToken));
        }
        message.success(translate('landing.emailConfirmed').toString());
        setIsError(false);
      } catch (err) {
        if (err.response.data.message === 'Email Already confirmed') {
          message.error(translate('landing.emailAlreadyConfirmed').toString());
        } else {
          message.error(translate('landing.emailNotConfirmed').toString());
        }
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [token, dispatch, appZone, history]);

  return [isLoading, isError];
};
