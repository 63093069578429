import { makeStyles } from '@material-ui/styles';
import ANTDInput, { InputProps as ANTDInputProps } from 'antd/es/input';
import classNames from 'classnames';
import React, { useState } from 'react';
import tinycolor from 'tinycolor2';
import { ReactComponent as MinusBlack } from '../../../shared_assets/icons/minus black.svg';
import { ReactComponent as MinusBlue } from '../../../shared_assets/icons/minus blue.svg';
import { ReactComponent as PlusBlack } from '../../../shared_assets/icons/plus_black.svg';
import { ReactComponent as PlusBlue } from '../../../shared_assets/icons/plus_blue.svg';
import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  hugeInput: {
    height: 40,
    borderRadius: 5,
  },
  input: () => ({
    borderColor: getTheme().neutral4,
    borderWidth: 2,
    '&.ant-input-affix-wrapper:hover, &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused':
      {
        borderColor: getTheme().primary + ' !important',
        borderRightWidth: '2px !important',
        boxShadow: `0 0 0 2px ${tinycolor(getTheme().primary)
          .setAlpha(0.2)
          .toRgbString()}`,
      },
    '& > .ant-input-prefix ': {
      '& > *': {
        height: '100%',
      },
      height: 14,
      marginRight: '1em',
    },
    '& > .ant-input-suffix ': {
      '& > *': {
        height: '100%',
      },
      height: 14,
      marginLeft: '1em',
    },
    '& > .ant-input': {
      textAlign: 'center',
      padding: 0,
      fontSize: 14,
    },
    fontSize: 14,
    alignItems: 'center',
  }),
});

interface InputProps extends Omit<ANTDInputProps, 'icon' | 'onChange'> {
  huge?: boolean;
  onChange: (val: number) => void;
  positive?: boolean;
  value?: number;
}

function Input({
  className,
  huge,
  positive,
  size,
  value,
  onChange,
  placeholder,
  ...props
}: InputProps) {
  const classes = useStyles();

  const [focused, setFocused] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <span
      onFocus={() => setFocused(true)}
      onMouseEnter={() => setHovered(true)}
      onBlur={() => setFocused(false)}
      onMouseLeave={() => setHovered(false)}
    >
      <ANTDInput
        {...props}
        size={huge ? 'large' : size}
        className={
          huge
            ? classNames(classes.hugeInput, classes.input, className)
            : classNames(className, classes.input)
        }
        type="number"
        placeholder={placeholder || '0'}
        value={value}
        min={0}
        onChange={ev => onChange(parseInt(ev.target.value))}
        prefix={
          <div
            onClick={() => {
              if (positive) {
                if ((value || 0) - 1 >= 0) onChange((value || 0) - 1);
              } else {
                onChange((value || 0) - 1);
              }
            }}
          >
            {focused || hovered ? <MinusBlue /> : <MinusBlack />}
          </div>
        }
        suffix={
          <div
            onClick={() => {
              onChange((value || 0) + 1);
            }}
          >
            {focused || hovered ? <PlusBlue /> : <PlusBlack />}
          </div>
        }
      />
    </span>
  );
}

export default React.memo(Input);
