import Axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { EmailRegisteredDto } from '../../types/email-registered.dto';

const fetchEmailRegistered = (dto: EmailRegisteredDto) =>
  Axios.post<boolean>(`${API_URL}/authentication/email-registered`, dto);

export const useEmailRegistered = (dto: EmailRegisteredDto) => {
  const [isRegistered, setIsRegistered] = useState<boolean | 'loading'>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsRegistered('loading');
        const { data: registered } = await fetchEmailRegistered(dto);
        setIsRegistered(registered);
      } catch (err) {
        setIsRegistered('loading');
      }
    };
    fetchData();
  }, [dto]);

  return isRegistered;
};
