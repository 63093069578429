import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { CompanyRegisterForm } from '../../../components/Administration/CompanyRegisterForm';
import { FallbackImage } from '../../../components/App/Image';
import { LogoUrl } from '../../../hooks/customization/getLogo';
import { RoleType } from '../../../types/role-type.enum';
const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  img: {
    maxWidth: '30%',
    objectFit: 'contain',
  },
  h1: {
    color: '#009CDE',
  },
  userArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundColor: '#ADD8E6',
    paddingTop: '4rem',
    height: '100vh',
    width: '100vw',
  },
});

interface CompanyLandingProps extends LocalizeContextProps {
  type: RoleType;
}

function CompanySignUp({ translate, type }: CompanyLandingProps) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.title}>
          <FallbackImage
            src={LogoUrl}
            fallback={require('../../../components/App/Header/assets/logo_cor.png')}
            className={classes.img}
            alt=""
          />
          <h1 className={classes.h1}>EMPRESAS</h1>
        </div>

        <div className={classes.userArea}>
          <CompanyRegisterForm editable={true} />
        </div>
      </div>
    </>
  );
}

export default withLocalize(CompanySignUp);
