import { makeStyles } from '@material-ui/styles';
import Badge from 'antd/es/badge';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/App/Button';
import { MakeReviewModal } from '../../../../components/App/MakeReviewModal';
import { Heading, Text } from '../../../../components/Typography';
import { getLocationPhotoUrl } from '../../../../hooks/routes/location/useLocationPhoto';
import { VisitorBookingInterface } from '../../../../hooks/visitor/booking/useVisitorBookings';
import { BookingState } from '../../../../types/booking-state.enum';
import { complexTranslate, getTheme } from '../../../../utils';

interface StyleProps {
  imageUrl: string | null;
  badgeColor: string;
  textColor: string;
}

const useStyles = makeStyles({
  container: ({ imageUrl }: StyleProps) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    padding: '1rem',
    backgroundPosition: 'center',
    margin: '1rem 0',
    borderRadius: 10,
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  }),
  badge: ({ badgeColor, textColor }: StyleProps) => ({
    marginBottom: '1rem',
    '& > sup': {
      backgroundColor: badgeColor,
      color: textColor,
    },
  }),
  buttonContainer: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: '1rem',
    },
  },
});

interface BookingHistoryEntryProps
  extends VisitorBookingInterface,
    LocalizeContextProps {
  onReview: (bookingId: string, reviewId: string) => void;
}

const BookingHistoryEntry: React.FC<BookingHistoryEntryProps> = ({
  routeTitle,
  groupSize,
  locationPhoto,
  state,
  id,
  translate,
  reviewId,
  routeId,
  onReview,
}) => {
  const [stateColor, setStateColor] = useState({
    backgroundColor: getTheme().warningBase,
    textColor: getTheme().defaultTextPrimaryColor,
  });

  const [reviewShowing, setReviewShowing] = useState<boolean>(false);

  const closeReview = useCallback(() => {
    setReviewShowing(false);
  }, []);

  const openReview = useCallback(() => {
    setReviewShowing(true);
  }, []);

  const reviewMade = useCallback(
    (reviewId: string) => {
      onReview(id, reviewId);
      closeReview();
    },
    [closeReview, id, onReview]
  );

  useEffect(() => {
    if (state === BookingState.PAYED)
      setStateColor({
        backgroundColor: getTheme().successBase,
        textColor: getTheme().defaultTextPrimaryColor,
      });
    else if (state === BookingState.CANCELED)
      setStateColor({
        backgroundColor: getTheme().errorBase,
        textColor: getTheme().defaultTextPrimaryColor,
      });
    else {
      setStateColor({
        backgroundColor: getTheme().warningBase,
        textColor: getTheme().defaultTextPrimaryColor,
      });
    }
  }, [state]);

  const classes = useStyles({
    badgeColor: stateColor.backgroundColor,
    textColor: stateColor.textColor,
    imageUrl: locationPhoto
      ? getLocationPhotoUrl(locationPhoto)
      : require('../../../../shared_assets/images/route-default.png'),
  });

  return (
    <div className={classes.container}>
      <Badge
        className={classes.badge}
        count={
          state === BookingState.PAYED
            ? translate('adminstration.scheduleStateFinished').toString()
            : translate(`bookingState.${state}`).toString()
        }
      />
      <Heading level={6} color="white">
        {routeTitle}
      </Heading>
      <Text variant="note" color="white">
        {complexTranslate(
          translate('replaceValue.reservedForVisitors').toString(),
          {
            '{value}': groupSize,
          }
        )}
      </Text>
      <div className={classes.buttonContainer}>
        <Link
          to={{
            pathname: `/visitor/bookings/${id}`,
            state: { allowCancellation: false },
          }}
        >
          <Button onlyText type="link">
            <Text noMargin variant="note" weight="bold" color="white">
              <Translate id="button.showMore" />
            </Text>
          </Button>
        </Link>
        {!reviewId && (
          <>
            <Button onlyText type="link" onClick={openReview}>
              <Text noMargin variant="note" weight="bold" color="white">
                <Translate id="button.evaluateRoute" />
              </Text>
            </Button>
            <MakeReviewModal
              bookingId={id}
              routeId={routeId}
              onDismiss={closeReview}
              onFinish={reviewMade}
              visible={reviewShowing}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withLocalize(BookingHistoryEntry);
