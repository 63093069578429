import { useCallback, useState, useMemo, useEffect } from 'react';
import validator from 'validator';
import {
  FormEntry,
  SettableFormEntry,
  Info,
  SettableFormGuideEntry,
} from '../../../types/form.interface';
import { getTranslate } from 'react-localize-redux';
import { store } from '../../../store/index';
import { FormGuideEntry } from '../../../types/form.interface';

type ChangePasswordFormProperties =
  | 'oldPassword'
  | 'newPassword'
  | 'confirmNewPassword';

export interface ChangePasswordForm
  extends Record<ChangePasswordFormProperties, SettableFormEntry> {
  newPassword: SettableFormGuideEntry;
}

export const useChangePasswordForm = (
  firstName: string,
  lastName: string,
  email: string
): ChangePasswordForm => {
  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const [oldPassword, setOldPassword] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const setValidatedOldPassword = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.oldPasswordMandatory').toString();
      }

      setOldPassword({ value, isInvalid, reason });
    },
    [setOldPassword, translate]
  );

  const [newPassword, setNewPassword] = useState<FormGuideEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const setValidatedNewPassword = useCallback(
    (value: string) => {
      //const formatNumSymbol = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const formatNumSymbol = new RegExp(
        '[ `!@#$%^&*()_+-=[]{};\':"\\|,.<>/?~]'
      );
      const formatStrength = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      const hasRightLength = value.length >= 8 && value.length <= 20;
      const emailSplit = email.includes('@') && email.split('@');
      const isStrength = value.match(formatStrength) ? true : false;
      const isContainNameEmail: boolean =
        (value.includes(firstName) && firstName !== '') ||
        (value.includes(lastName) && lastName !== '') ||
        (emailSplit && value.includes(emailSplit[0]) && email !== '') ||
        value === ''; //TODO: falta fazer validar o email
      const isContainNumSymbol =
        formatNumSymbol.test(value) && value.match(/\d/) ? true : false;
      const isInvalid: boolean = !(
        hasRightLength &&
        !isContainNameEmail &&
        isContainNumSymbol &&
        isStrength
      );
      let infos: Info[] = [];

      if (isInvalid) {
        infos = [
          {
            infoType: 'error',
            phrase: [
              translate('register.passwordStrengthInfo').toString(),
              translate('register.weakPassword').toString(),
            ],
          },
          {
            infoType: !isContainNameEmail ? 'success' : 'default',
            phrase: [translate('register.passNoContainNameMail').toString()],
          },
          {
            infoType: hasRightLength ? 'success' : 'default',
            phrase: [translate('register.rightLengthPassword').toString()],
          },
          {
            infoType: isContainNumSymbol ? 'success' : 'default',
            phrase: [translate('register.passwordRestrictions').toString()],
          },
        ];
      } else {
        infos = [
          {
            infoType: 'success',
            phrase: [
              translate('register.passwordStrengthInfo').toString(),
              translate('register.strongPassword').toString(),
            ],
          },
        ];
      }
      setNewPassword({ value, isInvalid, isVisible: true, infos });
    },
    [setNewPassword, translate, email, firstName, lastName]
  );

  const [confirmNewPassword, setConfirmNewPassword] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const setValidatedConfirmNewPassword = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value) || value !== newPassword.value;
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badConfirmNewPassword').toString();
      }

      setConfirmNewPassword({ value, isInvalid, reason });
    },
    [setConfirmNewPassword, translate, newPassword]
  );

  useEffect(() => {
    setValidatedConfirmNewPassword(confirmNewPassword.value);
  }, [
    newPassword.value,
    confirmNewPassword.value,
    setValidatedConfirmNewPassword,
  ]);

  return {
    oldPassword: {
      ...oldPassword,
      set: setValidatedOldPassword,
    },
    newPassword: {
      ...newPassword,
      set: setValidatedNewPassword,
    },
    confirmNewPassword: {
      ...confirmNewPassword,
      set: setValidatedConfirmNewPassword,
    },
  };
};
