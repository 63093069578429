import { useState, useMemo } from 'react';
import { FormEntry } from '../../types/form.interface';
import validator from 'validator';
import { store } from '../../store/index';
import { getTranslate } from 'react-localize-redux';

export const useLoginForm = () => {
  const [email, setEmail] = useState<FormEntry>({
    value: '',
    isInvalid: true,
  });
  const [password, setPassword] = useState<FormEntry>({
    value: '',
    isInvalid: true,
  });

  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const resetForm = () => {
    setEmail({ value: '', isInvalid: true });
    setPassword({ value: '', isInvalid: true });
  };

  return {
    email: {
      ...email,
      set: (value: string) => {
        const isInvalid = !validator.isEmail(value);
        let reason: string | undefined = undefined;
        if (isInvalid) {
          reason = translate('login.badEmail').toString();
        }

        setEmail({ value, isInvalid, reason });
      },
    },
    password: {
      ...password,
      set: (value: string) => {
        const isInvalid = value.length < 8;
        let reason: string | undefined = undefined;

        if (isInvalid) {
          reason = translate('login.badPassword').toString();
        }

        setPassword({ value, isInvalid, reason });
      },
    },
    resetForm,
  };
};
