import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import Popover from 'antd/es/popover';
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../../components/App/Loader';
import { deleteRoute } from '../../../hooks/routes/deleteRoute';
import { hideRoute } from '../../../hooks/routes/hideRoute';
import { showRoute } from '../../../hooks/routes/showRoute';
import {
  ClosestRepetition,
  useRouteClosestRepetition,
} from '../../../hooks/routes/useRouteClosestsRepetition';
import { ReactComponent as CalendarIcon } from '../../../shared_assets/icons/calendar.svg';
import { ReactComponent as DeleteIcon } from '../../../shared_assets/icons/delete.svg';
import { ReactComponent as DotsIcon } from '../../../shared_assets/icons/dots.svg';
import { ReactComponent as EditIcon } from '../../../shared_assets/icons/edit.svg';
import { ReactComponent as PauseIcon } from '../../../shared_assets/icons/pause.svg';
import { ReactComponent as ReserveIcon } from '../../../shared_assets/icons/reserva.svg';
import { ReactComponent as SeeIcon } from '../../../shared_assets/icons/see.svg';
import { ReactComponent as YellowWarningIcon } from '../../../shared_assets/icons/warning_yellow.svg';
import { RouteStateType } from '../../../types/route-state.enum';
import { castShadow, getTheme, mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Badge } from '../Badge';
import { Button } from '../Button';

interface StyleProps {
  image: string;
}
const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyItems: 'center',
    maxWidth: '100%',
    minWidth: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-end',
    justifyItems: 'center',
    alignItems: 'center',
    '& .ant-popover-inner': {
      borderRadius: '10px',
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyItems: 'center',
    padding: '0.5rem 0',
  },
  footer: {
    marginBottom: 'unset',
    padding: '0 2rem',
  },
  noMargin: {
    margin: '0',
  },

  moreBtn: {
    padding: '0 0.5rem',
    height: 'fit-content',
    '& img, & svg': {
      width: '1rem',
      height: '1rem',
      margin: 'unset !important',
    },
  },
  moreContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyItems: 'center',
    '& .ant-divider': {
      margin: '0.5rem 0',
    },
    '& img, & svg': {
      marginRight: '0.5rem',
      width: '1rem',
      height: '1rem',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  imageContainer: ({ image }: StyleProps) => ({
    flex: 1,
    marginRight: '1rem',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '5px',
  }),
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    '& > .ant-divider': {
      margin: '0.7rem 0 0 0',
    },
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: '0.5rem',
    },
  },
  alertContainer: {
    display: 'flex',
    backgroundColor: getTheme().neutral5,
    padding: '1rem',
    borderRadius: '0 0 5px 5px',
    textAlign: 'center',
  },
  alertIcon: {
    display: 'flex',
    '& img, & svg': {
      margin: '0.15rem 1rem 0.2rem 0',
      width: '1rem',
      height: '1rem',
    },
  },
  alertInfo: {
    display: 'flex',
    textAlign: 'justify',
    flexWrap: 'wrap',
    '& > :first-child': {
      marginRight: '1rem !important',
    },
  },
  routeTitle: {
    cursor: 'pointer',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      borderRadius: '5px',
      boxShadow: castShadow,
    },
    body: {
      padding: '1rem',
    },
    footer: {
      padding: '0 1rem',
    },
    imageContainer: {
      maxWidth: '13vw',
    },
  },
});

export interface GuideRouteProps
  extends LocalizeContextProps,
    GuideRouteInterface {
  onDelete: (deletedId: string) => void;
  onShow: (shownId: string) => void;
  onHide: (hiddenId: string) => void;
}

export interface GuideRouteInterface {
  id: string;
  guideId: string;
  status: string;
  title: string;
  image: string;
  numBookings?: number;
  numSchedules: number;
}

function GuideRoute({
  id,
  guideId,
  status,
  title,

  numSchedules,
  image,
  numBookings,
  translate,
  onDelete,
  onHide,
  onShow,
}: GuideRouteProps) {
  const classes = useStyles({
    image: image || require('../../../shared_assets/images/route-default.png'),
  });

  let statusType: 'default' | 'success' | 'error' | 'warning' | undefined =
    'default';
  let statusText = translate(`routeState.${status}`).toString();
  let statusDescription = translate('routeList.draft').toString();
  let alert = '';

  const content = (
    <div className={classes.moreContainer}>
      {status === RouteStateType.APPROVED ? (
        <>
          <Link to={`/route/${id}`}>
            <Text noMargin>
              <SeeIcon />
              {translate('routeList.see').toString()}
            </Text>
          </Link>
          <Divider />
          <Link
            to={{
              pathname: '/guide/schedules',
              state: { preSelectedRouteId: id },
            }}
          >
            <Text noMargin>
              <CalendarIcon />
              {translate('routeList.schedule').toString()}
            </Text>
          </Link>
          <Divider />
        </>
      ) : null}
      {status !== RouteStateType.DUPLICATED ? (
        <>
          <Link to={`/guide/my-routes/edit/${id}`}>
            <Text noMargin>
              <EditIcon />
              {translate('routeList.edit').toString()}
            </Text>
          </Link>
          <Divider />

          {status !== RouteStateType.DRAFT &&
            (status === RouteStateType.HIDDEN ? (
              <Text noMargin>
                <span
                  onClick={() => {
                    showRoute(id).then(() => onShow(id));
                  }}
                >
                  <PauseIcon />
                  {translate('routeList.show').toString()}
                </span>
              </Text>
            ) : (
              <Text noMargin>
                <span
                  onClick={() => {
                    hideRoute(id).then(() => onHide(id));
                  }}
                >
                  <PauseIcon />
                  {translate('routeList.hide').toString()}
                </span>
              </Text>
            ))}
          <Divider />
        </>
      ) : null}
      <Text noMargin>
        <span
          onClick={() => {
            deleteRoute(id)
              .then(() => onDelete(id))
              .catch(error => {
                if (error.response.status === 409) {
                  message.error(
                    translate(`error.API_ERROR.${error.response.data.error}`)
                  );
                }
              });
          }}
        >
          <DeleteIcon />
          {translate('routeList.clear').toString()}
        </span>
      </Text>
    </div>
  );

  if (status === RouteStateType.APPROVED && numSchedules > 0) {
    statusType = 'success';
    statusText = translate('routeList.available').toString();
    statusDescription = translate('routeList.routeFooter').toString();
  } else if (status === RouteStateType.APPROVED) {
    statusType = 'success';
    statusDescription = translate(
      'routeList.approvedWithoutSchedule'
    ).toString();
    alert = translate('routeList.alert').toString();
  }
  if (status === RouteStateType.PENDING_APPROVAL) {
    statusType = 'warning';
    statusDescription = '';
  }

  const history = useHistory();

  const [repetition, setRepetition] = useState<ClosestRepetition | undefined>();

  const [isLoading] = useRouteClosestRepetition(id, setRepetition);
  if (isLoading) return <Loader />;

  return (
    <div className={classes.page} key={id.toString()}>
      <div className={classes.body}>
        <div
          className={classNames(classes.imageContainer, classes.routeTitle)}
          onClick={() => {
            if (
              status === RouteStateType.DUPLICATED ||
              status === RouteStateType.APPROVED
            ) {
              history.push(`/route/${id}?private=true`);
            }
          }}
        />
        <div className={classes.infoContainer}>
          <div className={classes.header}>
            <Popover placement="bottomRight" content={content} trigger="click">
              <Button className={classes.moreBtn} color={getTheme().neutral4}>
                <DotsIcon />
              </Button>
            </Popover>
          </div>
          {status === RouteStateType.DUPLICATED ||
          status === RouteStateType.APPROVED ? (
            <Link to={`/route/${id}?private=true`}>
              <Heading
                ellipsis={{ rows: 3 }}
                level={4}
                className={classes.routeTitle}
              >
                {title.trim() || (
                  <Translate id="routeList.noRouteNameDefault" />
                )}
              </Heading>
            </Link>
          ) : (
            <Text size={18} weight={'bold'} ellipsis={{ rows: 1 }}>
              {title.trim() || <Translate id="routeList.noRouteNameDefault" />}
            </Text>
          )}

          <div className={classes.statusContainer}>
            <Badge type={statusType} small>
              {statusText}
            </Badge>
            {repetition ? (
              <Badge
                onClick={() => {
                  history.push(`/guide/schedules/?repetition=${repetition.id}`);
                }}
                type="none"
                small
                className={classes.routeTitle}
                prefix={<ReserveIcon />}
              >
                {repetition.nPeople === 1
                  ? translate('routeList.oneReservation').toString()
                  : translate('replaceValue.reservationValue')
                      .toString()
                      .replace('{value}', repetition.nPeople.toString())}
                {` ${moment(repetition.date).format('LLL')}`}
              </Badge>
            ) : null}
          </div>
          <Divider />
        </div>
      </div>

      <Text
        variant="note"
        color={getTheme().neutral2}
        className={classes.footer}
      >
        {statusDescription}
      </Text>
      {alert !== '' && (
        <div className={classes.alertContainer}>
          <div className={classes.alertIcon}>
            <YellowWarningIcon />
          </div>
          <div className={classes.alertInfo}>
            <Text noMargin>{alert}</Text>
            <Link
              to={{
                pathname: '/guide/schedules',
                state: { preSelectedRouteId: id },
              }}
            >
              <Text color={getTheme().warningBase} noMargin>
                {translate('routeList.alertDate').toString()}
              </Text>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default withLocalize(GuideRoute);
