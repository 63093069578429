import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { GenderType } from '../../types/gender-type.enum';

export interface IntermediateListInterface {
  id: string;
  name: string;
  companyName: string;
  dateOfBirth: Date;
  phoneNumber: string;
  location: string;
  nif: string;
  address: string;
  iban: string;
  nationality: string;
  postalCode: string;
  gender: GenderType;
}

type Hook = (
  setListIntermediates: (
    val: SetStateAction<IntermediateListInterface[]>
  ) => void,
  info: { search?: string; showOnlyToApprove?: boolean },
  fetch: boolean
) => [boolean, boolean];

const requestAdmin = (cancel: CancelToken | undefined, search: string) =>
  Axios.get<IntermediateListInterface[]>(`${API_URL}/intermediate`, {
    params: {
      search,
    },
    cancelToken: cancel,
  });

export const useIntermediates: Hook = (
  setListIntermediates,
  { search },
  fetch
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (fetch) {
          const { data: list } = await requestAdmin(
            cancel.current?.token,
            search || ''
          );
          setListIntermediates(list);
        }

        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setListIntermediates([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [dispatch, setListIntermediates, search, fetch]);

  return [isLoading, isError];
};

export const getIntermediates = () =>
  Axios.get<IntermediateListInterface[]>(`${API_URL}/intermediate`);
