import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AreaCardProps } from '../../components/App/AreaCardList/AreaCard';
import { FaqProps } from '../../components/App/Faq/Faq';
import { GuideTestimonyProps } from '../../components/App/GuideTestimonyList/GuideTestimonyList';
import { LookForProps } from '../../components/App/LookForList/LookForList';
import { RoutePreviewInterface } from '../../components/App/RoutePreviewList/RoutePreview';
import { StepProps } from '../../components/App/StepList/StepList';
import { API_URL } from '../../consts';

interface SubjectsInterface {
  id: string;
  title: string;
}
interface FaqInterface {
  question: string;
  answer: string;
}
export interface GuideLandingTranslationResponse {
  firstTitle: string;
  thinCallout: string;
  intro: string;
  whatIsTitle: string;
  carouselTexts: string[];
  areaTitle: string;
  areas: RoutePreviewInterface[];
  subjectTitle: string;
  subjects: SubjectsInterface[];
  lookForTitle: string;
  lookFors: LookForProps[];
  joinTitle: string;
  joinText: string;
  testimonies: GuideTestimonyProps[];
  toKnowMoreText: string;
  toKnowMoreRedirectUrl: string;
  weHelpToGrowTitle: string;
  weHelpToGrowText1: string;
  weHelpToGrowText2: string;
  howToStartTitle: string;
  howToStartText: string;
  stepTitle: string;
  stepText: string;
  steps: StepProps[];
  enticeText: string;
  faqTitle: string;
  faqs: FaqInterface[];
  otherExperiencesTitle: string;
  routePreviews: RoutePreviewInterface[];
}

export interface GuideLandingTranslation {
  firstTitle: string;
  thinCallout: string;
  intro: string;
  whatIsTitle: string;
  carouselTexts: string[];
  areaTitle: string;
  areas: RoutePreviewInterface[];
  subjectTitle: string;
  subjects: AreaCardProps[];
  lookForTitle: string;
  lookFors: LookForProps[];
  joinTitle: string;
  joinText: string;
  testimonies: GuideTestimonyProps[];
  toKnowMoreText: string;
  toKnowMoreRedirectUrl: string;
  weHelpToGrowTitle: string;
  weHelpToGrowText1: string;
  weHelpToGrowText2: string;
  howToStartTitle: string;
  howToStartText: string;
  stepTitle: string;
  stepText: string;
  steps: StepProps[];
  enticeText: string;
  faqTitle: string;
  faqs: FaqProps[];
  otherExperiencesTitle: string;
  routePreviews: RoutePreviewInterface[];
}

type Hook = (
  language: string,
  setLandingPageTranslation: (
    val: SetStateAction<GuideLandingTranslation | null>
  ) => void
) => [boolean, boolean];

const requestLandingPageTranslation = (language: string) =>
  Axios.get<GuideLandingTranslationResponse>(
    `${API_URL}/content-management/guide-landing-page/${language}`
  );

export const useGuideLandingTranslations: Hook = (
  language,
  setLandingPageTranslation
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: info } = await requestLandingPageTranslation(language);
        const translation = info as GuideLandingTranslationResponse;

        setLandingPageTranslation({
          firstTitle: translation.firstTitle,
          thinCallout: translation.thinCallout,
          intro: translation.intro,
          whatIsTitle: translation.whatIsTitle,
          carouselTexts: translation.carouselTexts,
          areaTitle: translation.areaTitle,
          areas: translation.areas.map((area, index) => ({
            id: area.id,
            place: area.place,
            name: area.name,
            price: area.price,
            rating: area.rating,
            numberOfRatings: area.numberOfRatings,
            image: getRouteImage(area.id),
          })),
          subjectTitle: translation.subjectTitle,
          subjects: translation.subjects.map(s => ({
            title: s.title,
            image: getSubjectImageUrl(s.id),
            id: s.id,
          })),
          lookForTitle: translation.lookForTitle,
          lookFors: translation.lookFors.map(lf => {
            return {
              title: lf.title,
              description: lf.description,
              image: getLooForImageUrl(lf.position),
              position: lf.position,
            };
          }),

          joinTitle: translation.joinTitle,
          joinText: translation.joinText,
          testimonies: translation.testimonies.map(t => {
            return {
              id: t.id,
              name: t.name,
              image: getGuideAvatar(t.id),
              description: t.description,
            };
          }),

          toKnowMoreText: translation.toKnowMoreText,
          toKnowMoreRedirectUrl: translation.toKnowMoreRedirectUrl,
          weHelpToGrowTitle: translation.weHelpToGrowTitle,
          weHelpToGrowText1: translation.weHelpToGrowText1,
          weHelpToGrowText2: translation.weHelpToGrowText2,
          howToStartTitle: translation.howToStartTitle,
          howToStartText: translation.howToStartText,
          stepTitle: translation.stepTitle,
          stepText: translation.stepText,
          steps: translation.steps,
          enticeText: translation.enticeText,
          faqTitle: translation.faqTitle,
          faqs: translation.faqs.map(f => {
            return {
              question: f.question,
              answer: f.answer,
              link: '',
            };
          }),
          otherExperiencesTitle: translation.otherExperiencesTitle,
          routePreviews: translation.routePreviews.map(rp => ({
            id: rp.id,
            place: rp.place,
            name: rp.name,
            price: rp.price,
            rating: rp.rating,
            numberOfRatings: rp.numberOfRatings,
            image: getRouteImage(rp.id),
          })),
        });
        setIsError(false);
      } catch (err) {
        setLandingPageTranslation(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setLandingPageTranslation, language]);

  return [isLoading, isError];
};
export const getHeaderImageUrl = () =>
  `${API_URL}/content-management/guide-landing-page/header-image?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
export const getSubjectImageUrl = (id: string) =>
  `${API_URL}/subject/${id}/image?_=${moment().unix()}`; //TODO: POR O URL CORRECTo

const getRouteImage = (id: string) =>
  `${API_URL}/route/${id}/location/photo?_=${moment().unix()}`;
export const getLooForImageUrl = (order: number) =>
  `${API_URL}/content-management/guide-landing-page/look-for-image/${order}?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
export const getGuideAvatar = (guideId: string) =>
  `${API_URL}/guide/${guideId}/avatar?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
