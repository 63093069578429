import React, { useEffect } from 'react';
import { useParams } from 'react-router';

interface RouteParams {
  status?: string;
}

interface ReactNativeWindow extends Window {
  ReactNativeWebView: {
    postMessage: (v: string) => void;
  };
}

const MobilePayment = () => {
  const params = useParams<RouteParams>();

  useEffect(() => {
    if (params.status === 'ok') {
      ((window as unknown) as ReactNativeWindow).ReactNativeWebView.postMessage(
        'payment-success'
      );
    } else {
      ((window as unknown) as ReactNativeWindow).ReactNativeWebView.postMessage(
        'payment-error'
      );
    }
  }, [params.status]);

  return (
    <h3>
      {params.status === 'ok'
        ? 'Pagamento bem sucedido, a redirecionar...'
        : 'Ocorreu um erro, a redirecionar...'}
    </h3>
  );
};

export default React.memo(MobilePayment);
