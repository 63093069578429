import { makeStyles } from '@material-ui/styles';
import Badge from 'antd/es/badge';
import Popover from 'antd/es/popover';
import React, { SetStateAction, useCallback, useMemo, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { getGuideImageUrl } from '../../../../hooks/guide/UseGuide';
import { NotificationOwnerType, useFixedNotifications } from '../../../../hooks/notifications/useNotifications';
import { getVisitorImageUrl } from '../../../../hooks/visitor/UseVisitor';
import { ReactComponent as Notifications } from '../../../../shared_assets/icons/notifications.svg';
import { ReactComponent as Pediguia } from '../../../../shared_assets/images/pediguia_default.svg';
import { ReactComponent as Visitante } from '../../../../shared_assets/images/visitante_default.svg';
import { RootState } from '../../../../store';
import { GenericNotification } from '../../../../types/notification.interface';
import { Heading } from '../../../Typography';
import { FallbackAvatar } from '../../Avatar';
import { Button } from '../../Button';
import { Error } from '../../Error';
import { FallbackImage } from '../../Image';
import Loader from '../../Loader/Loader';
import { Notification } from '../../Notification';
import NotificationBoxEmpty from './NotificationBoxEmpty';

const useStyles = makeStyles({
  container: {
    zIndex: 1200,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  icon: {
    fontSize: '2rem',
    marginRight: '1rem',
  },
  avatar: {
    margin: '0 0.5rem',
    '& .ant-badge-dot': {
      width: '10px',
      height: '10px',
      top: '5px',
      right: '5px',
    },
  },
});

interface NotificationCollapsibleProps extends LocalizeContextProps {
  setDrawerOpen: (val: SetStateAction<boolean>) => void;
  collapsibleOpen: boolean;
  setCollapsibleOpen: (val: SetStateAction<boolean>) => void;
}

function NotificationCollapsible({
  setDrawerOpen,
  collapsibleOpen,
  setCollapsibleOpen,
}: NotificationCollapsibleProps) {
  const [jwt] = UseJWT();
  const history = useHistory();
  const classes = useStyles();
  const { lastAppZone } = useSelector((state: RootState) => state.app);

  const [notifications, setNotifications] = useState<GenericNotification[]>([]);

  const removeNotifications = useCallback((id: string) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notif => notif.notificationId !== id)
    );
  }, []);

  const markNotificationAsRead = useCallback((id: string) => {
    setNotifications(prevNotifications =>
      prevNotifications.map(not =>
        not.notificationId === id ? { ...not, seen: true } : not
      )
    );
  }, []);

  const info = useMemo(
    () => ({
      id:
        lastAppZone === 'guide' && jwt?.guideId
          ? jwt.guideId
          : lastAppZone === 'visitor' && jwt?.visitorId
          ? jwt.visitorId
          : undefined,
      type:
        lastAppZone === 'visitor'
          ? NotificationOwnerType.VISITOR
          : NotificationOwnerType.GUIDE,
      size: 3,
    }),
    [jwt, lastAppZone]
  );

  const [isLoading, isError] = useFixedNotifications(info, setNotifications);

  const unseenNotifications = useSelector((state: RootState) =>
    lastAppZone === 'guide'
      ? state.app.guideUnseenNotifications
      : state.app.visitorUnseenNotifications
  );

  if (isLoading && notifications.length === 0) return <Loader />;

  if (isError) return <Error />;

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      onVisibleChange={setCollapsibleOpen}
      content={
        <div className={classes.container}>
          <div className={classes.header}>
            <Notifications className={classes.icon} />
            <Heading level={3} noMargin color="white">
              <Translate id="notifications.title" />
            </Heading>
          </div>
          {notifications.length > 0 ? (
            notifications.map(not => (
              <Notification
                onRead={markNotificationAsRead}
                onHide={removeNotifications}
                notification={not}
                key={not.notificationId}
                simpleVersion
              />
            ))
          ) : (
            <NotificationBoxEmpty />
          )}
          <Button
            color="white"
            onlyText
            type="link"
            onClick={() => {
              setCollapsibleOpen(prev => !prev);
              history.push(
                lastAppZone === 'guide'
                  ? '/guide/my-notifications'
                  : '/visitor/my-notifications'
              );
            }}
          >
            <Translate id="notifications.seeMoreNotifications" />
          </Button>
        </div>
      }
      visible={collapsibleOpen}
    >
      <div
        className={classes.avatar}
        onClick={() => {
          setDrawerOpen(false);
        }}
      >
        {unseenNotifications > 0 ? (
          <Badge dot>
            {jwt && lastAppZone === 'guide' && jwt.guideId ? (
              <FallbackAvatar
                src={
                  <FallbackImage
                    src={getGuideImageUrl(jwt.guideId)}
                    fallbackComponent={<Pediguia />}
                  />
                }
              />
            ) : jwt && jwt.visitorId ? (
              <FallbackAvatar
                src={
                  <FallbackImage
                    src={getVisitorImageUrl(jwt.visitorId)}
                    fallbackComponent={<Visitante />}
                  />
                }
              />
            ) : (
              ''
            )}
          </Badge>
        ) : (
          <>
            {jwt && lastAppZone === 'guide' && jwt.guideId ? (
              <FallbackAvatar
                src={
                  <FallbackImage
                    src={getGuideImageUrl(jwt.guideId)}
                    fallbackComponent={<Pediguia />}
                  />
                }
              />
            ) : jwt && jwt.visitorId ? (
              <FallbackAvatar
                src={
                  <FallbackImage
                    src={getVisitorImageUrl(jwt.visitorId)}
                    fallbackComponent={<Visitante />}
                  />
                }
              />
            ) : null}
          </>
        )}
      </div>
    </Popover>
  );
}

export default withLocalize(NotificationCollapsible);
