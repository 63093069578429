import { makeStyles } from '@material-ui/styles';
import { OverlayView } from '@react-google-maps/api';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePosition } from 'use-position';
import { GoogleMap } from '../../../components/App/GoogleMap';
import { ReactComponent as MapPointer } from '../../../shared_assets/icons/map pointer.svg';
import { RouteLocation } from '../../../types/route-location.interface';
import LocationMarker from './LocationMarker';

interface LocationsMapProps {
  locations: RouteLocation[];
}

const useStyles = makeStyles({
  map: {
    height: 300,
  },
  currentPosition: {
    height: '2rem',
    width: '2rem',
  },
});

const LocationsMap = ({ locations }: LocationsMapProps) => {
  const classes = useStyles();

  const [selectedPoint, setselectedPoint] = useState<number | undefined>();

  const center = useMemo(() => {
    return {
      lat: locations[0] ? locations[0].latitude : 38.7223,
      lng: locations[0] ? locations[0].longitude : 9.1393,
    };
  }, [locations]);

  const { latitude, longitude } = usePosition(false);

  const fitInitial = useRef<boolean>(false);
  const map = useRef<null | google.maps.Map<Element>>(null);

  const fitToLocations = useCallback(() => {
    if (!map.current) return;
    var bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < locations.length; i++) {
      bounds.extend({
        lat: locations[i].latitude,
        lng: locations[i].longitude,
      });
    }

    map.current.fitBounds(bounds);
  }, [locations]);

  useEffect(fitToLocations, [fitToLocations]);

  return (
    <GoogleMap
      onLoad={ref => {
        map.current = ref;
      }}
      onIdle={() => {
        if (!fitInitial.current) {
          fitToLocations();
          fitInitial.current = true;
        }
      }}
      mapContainerClassName={classes.map}
      zoom={16}
      center={center}
    >
      {locations.map((location, i) => (
        <LocationMarker
          selected={i === selectedPoint}
          location={location}
          index={i}
          key={location.id}
          onSelect={setselectedPoint}
        />
      ))}
      {latitude && longitude && (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={{ lat: latitude, lng: longitude }}
        >
          <MapPointer className={classes.currentPosition} />
        </OverlayView>
      )}
    </GoogleMap>
  );
};

export default React.memo(LocationsMap);
