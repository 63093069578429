import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/App/Button';
import { Text } from '../../../components/Typography';
import { BookingsPaymentInterface } from '../../../hooks/guide/useGuidePayments';
import { RootState } from '../../../store';
import { Title } from '../../../types/title.interface';
import { complexTranslate } from '../../../utils';
import PaymentModal from './PaymentModal';

const useStyles = makeStyles({
  container: {
    borderRadius: 5,
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.1)',
    padding: '0.5rem',
    marginBottom: '1rem',
  },
  middleContainer: {
    display: 'flex',
  },
  price: {
    flex: 0,
  },
  textContainer: {
    flex: 1,
  },
});

interface PaymentEntryProps extends LocalizeContextProps {
  date: Date;
  routeTitles: Title[];
  amount: number;
  id: string;
  invoiceDocument: boolean;
  invoiceDocumentId: number;
  bookings: BookingsPaymentInterface[];
}

const PaymentEntry: React.FC<PaymentEntryProps> = ({
  date,
  routeTitles,
  amount,
  translate,
  id,
  invoiceDocument,
  invoiceDocumentId,
  bookings,
}) => {
  const classes = useStyles();
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const routeTitle = useMemo(() => {
    const translation = routeTitles.find(t => t.language === activeLanguage);
    return translation ? translation.title : routeTitles[0].title;
  }, [routeTitles, activeLanguage]);

  return (
    <div className={classes.container}>
      <PaymentModal
        bookings={bookings}
        visible={detailModalVisible}
        amount={amount}
        date={date}
        id={id}
        available={invoiceDocument}
        documentId={invoiceDocumentId}
        onRequestClose={() => setDetailModalVisible(false)}
      />
      <Text noMargin variant="faded" size={12}>
        {moment(date).format('L')}
      </Text>
      <div className={classes.middleContainer}>
        <div className={classes.textContainer}>
          <Text size={12}>{routeTitle}</Text>
          <Button
            type="link"
            onlyText
            onClick={() => setDetailModalVisible(true)}
          >
            <Text noMargin variant="link" size={12}>
              <Translate id="button.seeDetails" />
            </Text>
          </Button>
        </div>
        <Text size={12} weight="bold" className={classes.price} inline>
          {complexTranslate(translate('paymentHistory.amount').toString(), {
            '{VALUE}': amount.toFixed(2),
          })}
        </Text>
      </div>
    </div>
  );
};

export default withLocalize(PaymentEntry);
