import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { AnimatedError } from '../../components/App/AnimatedError';
import { Button } from '../../components/App/Button';
import { Checkbox } from '../../components/App/Checkbox';
import { InformationPopup } from '../../components/App/InformationPopup';
import { Input } from '../../components/App/Input';
import { Text } from '../../components/Typography';
import {
  FACEBOOK_FIELDS,
  FACEBOOK_ID,
  FACEBOOK_SCOPES,
  GOOGLE_CLIENT_ID,
  GOOGLE_SCOPES,
} from '../../consts';
import { ReactComponent as Email } from '../../shared_assets/icons/email.svg';
import { ReactComponent as Facebook } from '../../shared_assets/icons/facebook.svg';
import { ReactComponent as Google } from '../../shared_assets/icons/google.svg';
import { ReactComponent as Lock } from '../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../shared_assets/icons/unsee.svg';
import { RootState } from '../../store';
import {
  attemptLogin,
  authenticateFacebook,
  authenticateGoogle,
} from '../../store/UserConfiguration/actions';
import { ErrorCode } from '../../types/error.codes.enum';
import { RoleType } from '../../types/role-type.enum';
import { complexTranslate } from '../../utils';
import { LoginType } from './LoginForgotPasswordModal';
import { useLoginForm } from './useLoginForm';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 24,
  },
  dividerBottom: {
    margin: '1rem 0 0.5rem 0',
  },
  formBottom: {
    display: 'flex',
    padding: 24,
    paddingTop: 0,
    justifyContent: 'space-between',
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});
interface LoginProps extends LocalizeContextProps {
  classes: {
    popupMargin: string;
    icon: string;
    marginBetween: string;
    formBottom: string;
  };
  loginType: LoginType;
  onForgotPassword: () => void;
  onRequestRegistration: () => void;
  onRequestClose: () => void;
}

const Login: React.FC<LoginProps> = ({
  classes,
  loginType,
  translate,
  onForgotPassword,
  onRequestRegistration,
  onRequestClose,
}) => {
  const innerClasses = useStyles();
  const form = useLoginForm();
  const [thereWasALoginError, setThereWasALoginError] =
    useState<boolean>(false);
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const lastAppZone = useSelector((state: RootState) => state.app.lastAppZone);
  const [errorMessage, setErrorMessage] = useState('login.badLoginMessage');
  const [errorDescription, setErrorDescription] = useState(
    'login.badLoginDescription'
  );
  const googleOnClick = useRef<() => void>(() => {});
  const fbOnClick = useRef<() => void>(() => {});

  // const [lastClicked, setLastClicked] = useState<'fb' | 'google'>('fb');
  // const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  // const [TOSModalShowing, setTOSModalShowing] = useState(false);

  // const onAccept = useCallback(() => {
  //   // setHasAgreedToTerms(true);
  //   // setTOSModalShowing(false);
  //   if (lastClicked === 'fb') {
  //     fbOnClick.current();
  //   } else if (lastClicked === 'google') {
  //     googleOnClick.current();
  //   }
  // }, [lastClicked]);

  // const onDeny = useCallback(() => {
  //   // setTOSModalShowing(false);
  //   setHasAgreedToTerms(false);
  // }, []);

  // const openTOSModal = useCallback(
  //   (type: 'fb' | 'google') => () => {
  //     setLastClicked(type);
  //     setTOSModalShowing(true);
  //   },
  //   []
  // );

  const signIn = useCallback(async () => {
    if (
      Object.values(form).some(formEntry =>
        typeof formEntry === 'function' ? false : formEntry.isInvalid
      )
    ) {
      setThereWasALoginError(true);
      return;
    }

    setIsLoggingIn(true);

    try {
      await dispatch(
        attemptLogin({
          email: form.email.value,
          password: form.password.value,
          //type: redirect,
          type: loginType === 'GUIDE' ? RoleType.GUIDE : RoleType.VISITOR,
        })
      );

      setThereWasALoginError(false);

      onRequestClose();
    } catch (err) {
      if (
        err.response.data.error === ErrorCode.EMAIL_PROFILE_ALREADY_REGISTERED
      ) {
        setErrorDescription('login.badLoginMessage');
        setErrorMessage('');
      }
      if (err.response.data.message === 'Guide is not approved') {
        history.replace('/guide/awaiting-approval');
      }

      setThereWasALoginError(true);
    } finally {
      setIsLoggingIn(false);
    }

    setIsLoggingIn(false);
  }, [dispatch, history, form, loginType, onRequestClose]);

  const loginWithGoogle = useCallback(
    async response => {
      try {
        setIsLoggingIn(true);
        await authenticateGoogle(loginType)(response);
        setThereWasALoginError(false);

        onRequestClose();
      } catch (err) {
        if (err.response.data.message === 'Guide is not approved') {
          history.replace('/guide/awaiting-approval');
        }

        setThereWasALoginError(true);
      } finally {
        setIsLoggingIn(false);
      }
    },
    [loginType, history, onRequestClose]
  );

  const loginWithFacebook = useCallback(
    async response => {
      try {
        setIsLoggingIn(true);
        await authenticateFacebook(loginType)(response);
        setThereWasALoginError(false);

        onRequestClose();
      } catch (err) {
        if (err.response.data.message === 'Guide is not approved') {
          history.replace('/guide/awaiting-approval');
        }

        setThereWasALoginError(true);
      } finally {
        setIsLoggingIn(false);
      }
    },
    [loginType, history, onRequestClose]
  );
  return (
    <>
      {/* <TOSConfirmationModal
        visible={TOSModalShowing}
        onAccept={onAccept}
        onCancel={onDeny}
      /> */}
      <InformationPopup
        visible={thereWasALoginError}
        type="error"
        message={translate(errorMessage).toString()}
        description={translate(errorDescription).toString()}
        className={classes.popupMargin}
      />
      <Input
        huge
        type="email"
        placeholder={translate('login.email').toString()}
        onChange={event => {
          form.email.set(event.currentTarget.value);
        }}
        value={form.email.value}
        prefix={<Email className={classes.icon} />}
      />
      <AnimatedError
        isVisible={form.email.isInvalid}
        reason={form.email.reason}
      />
      <Input
        huge
        type={isPasswordShowing ? 'text' : 'password'}
        placeholder={translate('login.password').toString()}
        onChange={event => {
          form.password.set(event.currentTarget.value);
        }}
        value={form.password.value}
        prefix={<Lock className={classes.icon} />}
        suffix={
          isPasswordShowing ? (
            <div
              onClick={() => {
                setIsPasswordShowing(prevState => !prevState);
              }}
            >
              <See className={classes.icon} />
            </div>
          ) : (
            <div
              onClick={() => {
                setIsPasswordShowing(prevState => !prevState);
              }}
            >
              <Unsee className={classes.icon} />
            </div>
          )
        }
      />
      {/* <AnimatedError
        isVisible={form.password.isInvalid}
        reason={form.password.reason}
      /> */}
      <div>
        <Checkbox>
          <Text inline noMargin>
            <Translate id="login.rememberMe" />
          </Text>
        </Checkbox>
      </div>
      <Button
        className={classes.marginBetween}
        type="primary"
        size="large"
        onClick={signIn}
        disabled={isLoggingIn}
      >
        {isLoggingIn ? <BeatLoader /> : <Translate id="login.signIn" />}
      </Button>
      <Button type="link" onlyText onClick={onForgotPassword}>
        <Translate id="login.forgotPasswordQuestion" />
      </Button>
      <Divider className={classNames(classes.marginBetween)}>
        <Translate id="login.or" />
      </Divider>

      <FacebookLogin
        appId={FACEBOOK_ID}
        fields={FACEBOOK_FIELDS}
        scope={FACEBOOK_SCOPES}
        callback={loginWithFacebook}
        render={(props: any) => {
          fbOnClick.current = props.onClick as () => void;
          // RUSH FIX      onClick={hasAgreedToTerms ? props.onClick : openTOSModal('fb')}
          return (
            <Button
              {...props}
              onClick={props.onClick}
              type="ghost"
              size="large"
              prefix={<Facebook />}
            >
              <Translate id="login.signInWithFacebook" />
            </Button>
          );
        }}
      />
      {/* RUSH FIX   hasAgreedToTerms ? props.onClick : openTOSModal('google') |||||| hasAgreedToTerms ? props.onClick : openTOSModal('fb') */}
      <GoogleLogin
        render={props => {
          googleOnClick.current = props.onClick as () => void;

          return (
            <Button
              {...props}
              prefix={<Google />}
              onClick={props.onClick}
              className={classes.marginBetween}
              size="large"
              type="ghost"
            >
              <Translate id="login.signInWithGoogle" />
            </Button>
          );
        }}
        clientId={GOOGLE_CLIENT_ID}
        // clientId="844089429407-d3tjphci9u9v1n45f5puur7vi9fjmht5.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={loginWithGoogle}
        onFailure={err => {
          console.error(err);
        }}
        cookiePolicy="single_host_origin"
        scope={GOOGLE_SCOPES}
      />
      <Text>
        {complexTranslate(
          translate('register.serviceTermsInfoModal').toString(),
          {
            '{TOS}': (
              <Link to={`/terms-of-service/#${lastAppZone}`} target="_blank">
                <Button
                  key={'1'}
                  type="link"
                  className={innerClasses.inlineBtn}
                  onlyText
                >
                  <Translate id="register.serviceTerms" />
                </Button>
              </Link>
            ),
            '{PP}': (
              <Link to="/terms-of-service#privacy" target="_blank">
                <Button
                  key={'2'}
                  type="link"
                  onlyText
                  className={innerClasses.inlineBtn}
                >
                  <Translate id="register.privacyPolicy" />
                </Button>
              </Link>
            ),
            '{TSP}': (
              <Link to="/terms-of-service#market" target="_blank">
                <Button
                  key={'3'}
                  type="link"
                  onlyText
                  className={innerClasses.inlineBtn}
                >
                  <Translate id="register.paymentServiceTerms" />
                </Button>
              </Link>
            ),
          }
        )}
      </Text>
      <div className={classNames(classes.formBottom, classes.marginBetween)}>
        <Text noMargin>
          <Translate id="login.noAccountQuestion" />
        </Text>
        <Button type="link" onlyText onClick={onRequestRegistration}>
          <Translate id="login.signUp" />
        </Button>
      </div>
    </>
  );
};

export default withLocalize(React.memo(Login));
