import { makeStyles } from '@material-ui/styles';
import ANTDSlider, { SliderRangeProps as ANTDSliderProps } from 'antd/es/slider';
import classNames from 'classnames';
import React from 'react';

import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  slider: () => ({
    '& > *': {
      borderColor: getTheme().primary,
    },
    '& .ant-slider-track, & .ant-slider-handle': {
      backgroundColor: getTheme().primary,
    },
  }),
});

const Slider = ({ className, ...rest }: ANTDSliderProps) => {
  const classes = useStyles();
  return (
    <ANTDSlider className={classNames(className, classes.slider)} {...rest} />
  );
};

export default Slider;
