import { makeStyles } from '@material-ui/styles';
import Collapse from 'antd/es/collapse';
import { motion } from 'framer-motion';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { CopyrightFooter } from '../../../../components/App/CopyrightFooter';
import { Error } from '../../../../components/App/Error';
import { Loader } from '../../../../components/App/Loader';
import { MakeReviewModal } from '../../../../components/App/MakeReviewModal';
import { Heading, Text } from '../../../../components/Typography';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import {
  useVisitorBookings,
  VisitorBookingInterface,
} from '../../../../hooks/visitor/booking/useVisitorBookings';
import { ReactComponent as Bill } from '../../../../shared_assets/icons/bill.svg';
import { ReactComponent as Pedirota } from '../../../../shared_assets/icons/pedirota.svg';
import { BookingState } from '../../../../types/booking-state.enum';
import { ScheduleRepetitionStateType } from '../../../../types/schedule-repetition-state-type.enum';
import {
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../../utils';
import { GenericPageTransition } from '../../../PageUtils';
import BookingHistoryEntry from './BookingHistoryEntry';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& .ant-collapse': {
      backgroundColor: 'transparent',
    },
  },
  body: {
    ...mobilePadding,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  desktopPrefix: {
    width: 40,
    marginRight: '1rem',
  },
  emptyContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  emptyIcon: {
    width: '50%',
    marginBottom: '1rem',
  },
  collapse: {},
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    collapse: {
      padding: '0 44px',
    },
  },
});
interface QuerystringInterface {
  routeId: string;
  bookingId: string;
}
const getId = (): QuerystringInterface | undefined => {
  const queryString = window.location.href.split('?')[1];

  if (!queryString) {
    return undefined;
  }

  const routeId = queryString.split('=')[2];
  const bookingId = queryString.split('=')[1].split('&')[0];

  if (!routeId || !bookingId) {
    return undefined;
  }
  return {
    routeId,
    bookingId,
  };
};
const BookingHistory = () => {
  const [jwt] = UseJWT();
  const [bookings, setBookings] = useState<VisitorBookingInterface[]>([]);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [bookingIdParam, setBookingIdParam] = useState<
    QuerystringInterface | undefined
  >(undefined);

  const filter = useMemo(
    () => ({
      bookingStates: [
        BookingState.CANCELED,
        BookingState.REFOUND,
        BookingState.PAYED,
      ],
      repetitionState: [
        ScheduleRepetitionStateType.CANCELED,
        ScheduleRepetitionStateType.FINISHED,
      ],
    }),
    []
  );
  useEffect(() => {
    const bookingIdParam = getId();

    if (!bookingIdParam) {
      return;
    }
    setIsShowingModal(true);
    setBookingIdParam(bookingIdParam);
  }, []);

  const [bookingsLoading, bookingsError] = useVisitorBookings(
    jwt?.visitorId,
    setBookings,
    filter
  );

  const onReview = useCallback((bookingId: string, reviewId: string) => {
    setBookings(prevBookings =>
      prevBookings.map(b => ({
        ...b,
        reviewId: b.id === bookingId ? reviewId : b.reviewId,
      }))
    );
  }, []);

  const classes = useStyles();

  const uniqueDates = useMemo(() => {
    const uniqueDates: Moment[] = [];

    bookings.forEach(b => {
      const momentDate = moment(b.repetitionDate);
      if (!uniqueDates.some(date => date.isSame(momentDate, 'day')))
        uniqueDates.push(momentDate);
    });

    return uniqueDates;
  }, [bookings]);

  const isMobile = useIsMobile();

  if (bookingsLoading) return <Loader />;
  if (bookingsError) return <Error />;
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {bookingIdParam ? (
        <MakeReviewModal
          visible={isShowingModal}
          routeId={bookingIdParam.routeId}
          bookingId={bookingIdParam.bookingId}
          onDismiss={() => {
            setIsShowingModal(false);
          }}
          onFinish={() => {
            setIsShowingModal(false);
            setBookingIdParam(undefined);
          }}
        />
      ) : null}
      <div className={classes.body}>
        <Heading level={2}>
          {!isMobile && <Bill className={classes.desktopPrefix} />}
          <Translate id="booking.history" />
        </Heading>
        {bookings.length > 0 ? (
          <Collapse className={classes.collapse} bordered={false}>
            {uniqueDates.map(date => (
              <Collapse.Panel
                key={date.format('L')}
                header={<Text noMargin>{date.format('LL')}</Text>}
              >
                {bookings
                  .filter(b => moment(b.repetitionDate).isSame(date, 'day'))
                  .map(b => (
                    <BookingHistoryEntry
                      onReview={onReview}
                      key={b.id}
                      {...b}
                    />
                  ))}
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <div className={classes.emptyContainer}>
            <Pedirota className={classes.emptyIcon} />
            <Text variant="faded">
              <Translate id="booking.noHistory" />
            </Text>
          </div>
        )}
      </div>
      <CopyrightFooter />
    </motion.div>
  );
};

export default BookingHistory;
