import { makeStyles } from '@material-ui/styles';
import Empty from 'antd/es/empty';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import { getTheme } from '../../../../utils';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    backgroundColor: getTheme().neutral6,
    padding: '0.5rem 1rem',
    margin: '0.5rem 0 ',
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  empty: {
    padding: '0 7rem',
  },
});

interface NotificationBoxProps extends LocalizeContextProps {
  onLinkClick?: () => void;
  onClose?: () => void;
}

const NotificationBox: React.FC<NotificationBoxProps> = ({ translate }) => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Empty
        className={classes.empty}
        description={translate(
          'notifications.dontExistNotifications'
        ).toString()}
      />
    </div>
  );
};

export default withLocalize(NotificationBox);
