import { makeStyles } from '@material-ui/styles';
import { Divider, Table } from 'antd';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { FallbackAvatar } from '../../../../../../components/App/Avatar';
import { FallbackImage } from '../../../../../../components/App/Image';
import { Loader } from '../../../../../../components/App/Loader';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { Text } from '../../../../../../components/Typography';
import {
  TestimonyEntryInterface,
  useTestimoniesList,
} from '../../../../../../hooks/administration/useTestimoniesEntries';
import {
  TestimoniesTitleInterface,
  useTestimonyTitles,
} from '../../../../../../hooks/administration/useTestimony';
import { postTestimonyAboutPage } from '../../../../../../hooks/administration/useTestimonyAboutPage';
import { getVisitorImageUrl } from '../../../../../../hooks/visitor/UseVisitor';
import { ReactComponent as Visitante } from '../../../../../../shared_assets/images/visitante_default.svg';
import { ErrorCode } from '../../../../../../types/error.codes.enum';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  divider: {
    margin: '1rem 0',
  },
});

const columns = [
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    render: (text: any, record: any) => {
      return (
        <FallbackAvatar
          src={
            <FallbackImage
              src={getVisitorImageUrl(record.visitorId)}
              fallbackComponent={<Visitante />}
            />
          }
        />
      );
    },
  },
  {
    title: <Translate id="adminstration.aboutPage.visitorName" />,
    dataIndex: 'visitorName',
    key: 'visitorName',
  },

  {
    title: <Translate id="adminstration.aboutPage.location" />,
    dataIndex: 'location',
    key: 'location',
  },

  {
    title: <Translate id="adminstration.aboutPage.review" />,
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: <Translate id="adminstration.aboutPage.rating" />,
    dataIndex: 'rating',
    key: 'rating',
  },
];
interface SelectTestimonyPageProps extends LocalizeContextProps {}
const SelectTestimony = ({
  languages,
  translate,
}: SelectTestimonyPageProps) => {
  const classes = useStyles();
  const [testimonies, setListTestimonies] = useState<TestimonyEntryInterface[]>(
    []
  );
  const [fetchingGuides] = useTestimoniesList(setListTestimonies);
  const [testimoniesSelected, setSelectedTestimonies] = useState<string[]>([]);
  const [testimoniesTitles, setTestimonyTitles] = useState<
    TestimoniesTitleInterface[]
  >([]);
  //USE EFECT PARA  @Get('/about-page/testimonies-titles')
  useTestimonyTitles(setTestimonyTitles);
  const [testimoniesTitlesLocal, setTestimonyTitlesLocal] = useState<
    TestimoniesTitleInterface[]
  >([]);
  useEffect(() => {
    setTestimonyTitlesLocal(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = testimoniesTitles.find(lp => lp.language === l);
          if (translation) {
            return translation;
          }
          return {
            title: '',
            language: l,
          };
        })
    );
  }, [languages, testimoniesTitles]);
  const tableData = testimonies.map(g => {
    return {
      key: g.testimonyId,
      visitorName: g.visitorName,
      location: g.routeLocation,
      description: g.description,
      date: moment(g.dateCreation).format('LL, LTS'),
      rating: g.rating,
      visitorId: g.visitorId,
    };
  });

  const submit = useCallback(async () => {
    if (testimoniesTitlesLocal.some(s => validator.isEmpty(s.title))) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    try {
      await postTestimonyAboutPage({
        testimoniesIds: testimoniesSelected,
        testimoniesTitles: testimoniesTitlesLocal,
      });
      message.success(
        translate('adminstration.guideSelection.success').toString()
      );
    } catch (error) {
      message.error(
        `${translate(
          `error.API_ERROR.${ErrorCode.LANDING_PAGE_NEED_TRANSLATION}`
        )} ${translate(`languages.${error.response.data.message}`)}`
      );
    }
  }, [testimoniesTitlesLocal, translate, testimoniesSelected]);
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (selectedRowKeys.length <= 2) {
        setSelectedTestimonies(selectedRowKeys);
      }
    },
    selectedRowKeys: testimoniesSelected,
    hideSelectAll: true,
    columnTitle: 'Selecinar',
  };

  if (fetchingGuides) return <Loader />;

  return (
    <>
      {/* *****************ANCHOR call to action text************************ */}

      <Tabs type="card">
        {testimoniesTitlesLocal.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.aboutPage.testimonyTitle" />
              </Text>
              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setTestimonyTitlesLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>

      <Text noMargin weight="semibold">
        <Translate id="adminstration.aboutPage.testimonySelection" />
      </Text>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={tableData}
        columns={columns}
      />
      <Divider className={classes.divider} />
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </>
  );
};
export default withLocalize(SelectTestimony);
