import message from 'antd/es/message';
import Axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../consts';
import { GenderType } from '../../types/gender-type.enum';
import {
  GuideDescriptionDto,
  GuideSkillDto,
  LanguageCompetenceDto,
} from '../../types/guide.dtos';
import { GuideFormProperty } from '../../types/guide.interface';
import { RoleType } from '../../types/role-type.enum';
import { getGuideErrorMessagesFromField } from '../guide/GuideUtils';
export interface CreateGuideAdminDto {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  phoneNumber: string;
  agreeTerms: boolean;
  agreeMarketing: boolean;
  companyName: string;
  nif: string;
  nationality: string;
  gender: GenderType;
  address: string;
  location: string;
  postalCode: string;
  iban: string;
  skills: GuideSkillDto[];
  languages: LanguageCompetenceDto[];
  descriptions: GuideDescriptionDto[];
  email: string;
  password: string;
  type: RoleType;
  intermediateId?: string;
}

export const createGuideAdmin = async (
  dto: Partial<CreateGuideAdminDto>,
  avatar?: File | null,
  documents?: File[]
) => {
  const formData = new FormData();

  formData.append(
    'createGuideDto',
    JSON.stringify({
      ...dto,
      dateOfBirth: moment(dto.dateOfBirth).utc().toISOString(),
    })
  );
  if (avatar) {
    formData.append('avatar', avatar);
  }
  if (documents) {
    documents.forEach((doc, i) => {
      formData.append('document-' + i, doc);
    });
  }

  try {
    return Axios({
      method: 'post',
      url: `${API_URL}/administrator/create-guide`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      err.response.data.message.forEach(
        (t: { property: GuideFormProperty }) => {
          message.error(getGuideErrorMessagesFromField(t.property));
        }
      );
    }
    return Promise.reject(err);
  }
};
