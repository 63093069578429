import message from 'antd/es/message';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RouteFormAdmin } from '../../../../../components/App/RouteForm';
import { RouteFormResult } from '../../../../../components/App/RouteForm/RouteForm';
import { RouteIntro } from '../../../../../components/App/RouteIntro';
import { SubmitNewRoute } from '../../../../../hooks/routes/SubmitNewRoute';
import { RouteStateType } from '../../../../../types/route-state.enum';

interface RouteState {
  showIntro: boolean;
}
interface RouteParams {
  id: string;
}
function CreateNewRouteAdmin() {
  const location = useLocation<RouteState>();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [showIntro, setShowIntro] = useState(
    location.state?.showIntro || false
  );

  const submitNewRoute = useCallback(
    (status: RouteStateType) => (form: RouteFormResult) => {
      SubmitNewRoute({
        accessibilityIds: form.accessibilityIds,
        activities: form.activities,
        agreeTerms:
          form.agreeGpsSharing &&
          form.agreePhotoOwnership &&
          form.agreeTosAndPp,
        difficulty: form.selectedDifficulty,
        duration: form.selectedDuration,
        extraItems: form.extraItems,
        groupAge: form.selectedAgeGroup,
        groupSize: form.maxVisitors,
        guideId: form.guideId,
        location: form.routeLocation,
        locations: form.pointLocations.map((pL, i) => ({
          ...pL,
          photos: pL.photos.filter(p => p instanceof File) as File[],
          order: i,
        })),
        languages: form.selectedLanguages.map(lang => ({ language: lang })),
        routeExperience: form.routeExperienceType,
        subjectIds: form.selectedSubjects,
        titles: form.routeTitles,
        descriptions: form.routeDescriptions,
        state: status,
        latitude: form.routeLatitude,
        longitude: form.routeLongitude,
        routeLength: form.routeLength,
      })
        .then(res => {
          history.replace(`/admin/area/guide/${params.id}/list/routes`);
        })
        .catch(err => {
          message.error(err.toString());
        });
    },
    [history, params.id]
  );

  return (
    <>
      {showIntro ? (
        <RouteIntro onFinish={() => setShowIntro(false)} />
      ) : (
        <RouteFormAdmin
          guideId={params.id}
          onSave={submitNewRoute(RouteStateType.DRAFT)}
          onFinish={submitNewRoute(RouteStateType.APPROVED)}
        />
      )}
    </>
  );
}

export default CreateNewRouteAdmin;
