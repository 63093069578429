import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion, Variants } from 'framer-motion';
import React from 'react';

import { ReactComponent as CorrectIcon } from '../../../shared_assets/icons/correct.svg';
import { ReactComponent as InvalidIcon } from '../../../shared_assets/icons/invalid.svg';
import { ReactComponent as InvalidRedIcon } from '../../../shared_assets/icons/invalid_red.svg';
import { Info } from '../../../types/form.interface';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';

//import { Paragraph } from '../Paragraph';

export interface AnimatedInfoProps {
  infos?: Info[];
  isVisible: boolean;
}

const variants: Variants = {
  enter: {
    height: 'unset',
  },
  exit: {
    height: 0,
  },
};

const useStyles = makeStyles({
  transformSettings: {
    overflow: 'hidden',
    margin: '0.5rem 0.5rem',
  },
  warning: {
    color: getTheme().errorBase,
  },
  myP: {
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerSpan: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  icon: {
    width: '0.7rem',
    height: '0.7rem',
    minWidth: '0.7rem',
    minHeight: '0.7rem',
    marginRight: '0.5rem',
    alignSelf: 'start',
    marginTop: '0.3rem',
  },
  error: {
    backgroundColor: getTheme().errorBase,
  },
  success: {
    backgroundColor: getTheme().successBase,
  },
  info: {
    backgroundColor: getTheme().infoBase,
  },
});

export default function AnimatedInfo({ infos, isVisible }: AnimatedInfoProps) {
  const classes = useStyles();

  const iconChooser = (type: string) => {
    switch (type) {
      case 'error':
        return <InvalidRedIcon className={classes.icon} />;
      case 'success':
        return <CorrectIcon className={classes.icon} />;
      case 'default':
        return <InvalidIcon className={classes.icon} />;
      default:
        return <></>;
    }
  };

  return (
    <motion.div
      animate={isVisible ? 'enter' : 'exit'}
      className={classes.transformSettings}
      variants={variants}
    >
      {infos &&
        infos.map(({ infoType, iconSource, phrase }, id) => (
          <Text
            key={id}
            color={
              infoType === 'success'
                ? getTheme().successBase
                : infoType === 'error'
                ? getTheme().errorBase
                : getTheme().neutral2
            }
            className={classes.myP}
            noMargin
          >
            {iconSource ? (
              <img src={iconSource} alt="icon" className={classes.icon} />
            ) : (
              iconChooser(infoType || 'default')
            )}
            {phrase.map((str, i) => (
              <span
                key={i}
                className={classNames(
                  // i === 1 && !isCorrect ? classes.warning : '',
                  classes.innerSpan
                )}
              >
                {str}
              </span>
            ))}
          </Text>
        ))}
    </motion.div>
  );
}
