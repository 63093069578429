import { makeStyles } from '@material-ui/styles';
import { Divider, Space, Table } from 'antd';
import React, { useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import Badge2 from '../../../../../components/App/Badge2/Badge2';
import { Checkbox } from '../../../../../components/App/Checkbox';
import { Error } from '../../../../../components/App/Error';
import { Input } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { useAllPediroutes } from '../../../../../hooks/administration/useAllPediroutes';
import { RouteStateType } from '../../../../../types/route-state.enum';
import { Route } from '../../../../../types/route.interface';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  filterZone: {
    '& > *:nth-child(2)': { flexGrow: 1, marginLeft: '1rem' },
    display: 'flex',
  },
});

const ListAllGuideRoute = ({ translate }: LocalizeContextProps) => {
  const classes = useStyles();
  const [routes, setRoutes] = useState<Route[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showOnlyToApprove, setShowOnlyToApprove] = useState<boolean>(false);

  const dto = useMemo(
    () => ({
      search,
      showOnlyToApprove,
    }),
    [search, showOnlyToApprove]
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => {
          switch (text) {
            case RouteStateType.APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'success'}
                  text={translate('adminstration.guideApproved').toString()}
                />
              );
            case RouteStateType.NOT_APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideNotApproved').toString()}
                />
              );
            case RouteStateType.DRAFT:
              return (
                <Badge2
                  key={record.key}
                  type={'primary'}
                  text={translate('adminstration.draft').toString()}
                />
              );
            case RouteStateType.DUPLICATED:
              return (
                <Badge2
                  key={record.key}
                  type={'primary'}
                  text={translate('adminstration.duplicated').toString()}
                />
              );
            case RouteStateType.HIDDEN:
              return (
                <Badge2
                  key={record.key}
                  type={'primary'}
                  text={translate('adminstration.hidden').toString()}
                />
              );
            case RouteStateType.REMOVED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.removed').toString()}
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  type={'warning'}
                  text={translate(
                    'adminstration.guideWaitingApproval'
                  ).toString()}
                />
              );
          }
        },
        filters: [
          {
            text: <Translate id="adminstration.guideApproved" />,
            value: RouteStateType.APPROVED,
          },
          {
            text: <Translate id="adminstration.guideNotApproved" />,
            value: RouteStateType.NOT_APPROVED,
          },
          {
            text: <Translate id="adminstration.guideWaitingApproval" />,
            value: RouteStateType.PENDING_APPROVAL,
          },
          {
            text: <Translate id="adminstration.draft" />,
            value: RouteStateType.DRAFT,
          },
          {
            text: <Translate id="adminstration.hidden" />,
            value: RouteStateType.HIDDEN,
          },
          {
            text: <Translate id="adminstration.duplicated" />,
            value: RouteStateType.DUPLICATED,
          },
          {
            text: <Translate id="adminstration.removed" />,
            value: RouteStateType.REMOVED,
          },
        ],
        width: '15%',
      },
      {
        title: <Translate id="adminstration.name" />,
        dataIndex: 'title',
        key: 'title',
      },

      {
        title: <Translate id="adminstration.location" />,
        dataIndex: 'location',
        key: 'location',
      },

      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            {record.state !== 'DRAFT' && (
              <>
                <Link
                  to={`/admin/area/guide/${record.guideId}/route/${record.key}`}
                >
                  <Translate id="adminstration.showRoute" />
                </Link>
                <Divider type="vertical" />
              </>
            )}
            {record.state !== RouteStateType.DUPLICATED &&
            record.state !== RouteStateType.REMOVED ? (
              <>
                <Link
                  to={`/admin/area/guide/${record.guideId}/edit/route/${record.key}`}
                >
                  <Translate id="adminstration.editRoute" />
                </Link>
                <Divider type="vertical" />
              </>
            ) : null}
            {record.state === RouteStateType.APPROVED ? (
              <Link
                to={`/admin/area/guide/${record.guideId}/route/${record.key}/list/schedules`}
              >
                <Translate id="adminstration.scheduleRoute" />
              </Link>
            ) : null}
          </Space>
        ),
      },
    ];
  }, [translate]);

  const [fetchingRoutes, hasError] = useAllPediroutes(setRoutes, dto);
  const [filter, setFilter] = useState<string[] | null>(null);
  const tableData = useMemo(() => {
    if (!filter) {
      return routes.map(r => {
        return {
          key: r.id,
          title: r.titles[0] ? r.titles[0].title : '',
          location: r.location,
          state: r.state,
          guideId: r.guideId,
        };
      });
    } else {
      return filter
        .map(f => {
          return routes
            .filter(g => g.state === f)
            .map(filtered => {
              return {
                key: filtered.id,
                title: filtered.titles[0] ? filtered.titles[0].title : '',
                location: filtered.location,
                state: filtered.state,
                guideId: filtered.guideId,
              };
            });
        })
        .flat(1);
    }
  }, [filter, routes]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter(filters.state);
  };

  if (hasError) return <Error />;
  return (
    <>
      <div className={classes.divAddButton}>
        <div className={classes.filterZone}>
          <Input
            value={search}
            placeholder={translate('adminstration.search').toString()}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <Checkbox
            checked={showOnlyToApprove}
            onChange={e => setShowOnlyToApprove(e.target.checked)}
          >
            <Translate id="adminstration.showOnlyNotApproved" />
          </Checkbox>
        </div>
      </div>
      {fetchingRoutes ? (
        <Loader />
      ) : (
        <Table
          dataSource={tableData}
          columns={columns}
          onChange={handleTableChange}
        />
      )}
    </>
  );
};

export default withLocalize(ListAllGuideRoute);
