import message from 'antd/es/message';
import Axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../consts';
import { GenderType } from '../../types/gender-type.enum';
import { GuideFormProperty } from '../../types/guide.interface';
import { RoleType } from '../../types/role-type.enum';
import { getGuideErrorMessagesFromField } from '../guide/GuideUtils';
export interface CreateVisitorAdminDto {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  phoneNumber: string;
  agreeTerms: boolean;
  agreeMarketing: boolean;
  nif: string;
  nationality: string;
  gender: GenderType;
  address: string;
  location: string;
  postalCode: string;
  email: string;
  password: string;
  type: RoleType;
  showOnly: boolean;
  accessibilities: string[];
}

export const postVisitorAdmin = async (
  dto: CreateVisitorAdminDto,
  avatar?: File | null
) => {
  const formData = new FormData();

  formData.append(
    'createVisitorDto',
    JSON.stringify({
      ...dto,
      dateOfBirth: moment(dto.dateOfBirth).utc().toISOString(),
    })
  );
  if (avatar) {
    formData.append('avatar', avatar);
  }

  try {
    return Axios({
      method: 'post',
      url: `${API_URL}/administrator/create-visitor`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      err.response.data.message.forEach(
        (t: { property: GuideFormProperty }) => {
          message.error(getGuideErrorMessagesFromField(t.property));
        }
      );
    }
    return Promise.reject(err);
  }
};
