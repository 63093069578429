/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Space, Table } from 'antd';
import Button from 'antd/es/button';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import Badge2 from '../../../../../../components/App/Badge2/Badge2';
import { Loader } from '../../../../../../components/App/Loader';
import { postSelectedRoutes } from '../../../../../../hooks/administration/usePostSelectedRoutes';
import { useRoutesAdmin } from '../../../../../../hooks/administration/useRoutesAdmin';
import { useSelectedRoutes } from '../../../../../../hooks/administration/useSelectedRoutes';
import { RouteStateType } from '../../../../../../types/route-state.enum';
import { Route } from '../../../../../../types/route.interface';

const RouteSelectionGuideLandingPageList = ({
  translate,
}: LocalizeContextProps) => {
  const [routes, setRoutes] = useState<Route[]>([]);
  const [selectedRoutes, setSelectedRoutes] = useState<string[]>([]);
  const [fetchingSelectedRoutes, hasFetchingError] =
    useSelectedRoutes(setSelectedRoutes);

  const [fetchingRoutes, hasError] = useRoutesAdmin(setRoutes);
  const tableData = routes.map(r => {
    return {
      key: r.id,
      title: r.titles[0] ? r.titles[0].title : '',
      location: r.location,
      state: r.state,
      guideId: r.guideId,
    };
  });
  const columns = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (text: any, record: any) => {
        switch (text) {
          case RouteStateType.APPROVED:
            return (
              <Badge2
                key={record.key}
                type={'success'}
                text={translate('adminstration.guideApproved').toString()}
              />
            );
          case RouteStateType.NOT_APPROVED:
            return (
              <Badge2
                key={record.key}
                type={'error'}
                text={translate('adminstration.guideNotApproved').toString()}
              />
            );
          default:
            return (
              <Badge2
                key={record.key}
                type={'warning'}
                text={translate(
                  'adminstration.guideWaitingApproval'
                ).toString()}
              />
            );
        }
      },
    },
    {
      title: <Translate id="adminstration.routeSelection.title" />,
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: <Translate id="adminstration.routeSelection.location" />,
      dataIndex: 'location',
      key: 'location',
    },

    {
      title: <Translate id="adminstration.actions" />,
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={`/admin/area/guide/${record.guideId}/route/${record.key}`}>
            <Translate id="adminstration.showRoute" />
          </Link>
          <Divider type="vertical" />
        </Space>
      ),
    },
  ];
  const submit = useCallback(() => {
    postSelectedRoutes({ ids: selectedRoutes });
  }, [selectedRoutes]);
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (selectedRowKeys.length <= 3) {
        setSelectedRoutes(selectedRowKeys);
      }
    },
    selectedRowKeys: selectedRoutes,
    hideSelectAll: true,
    columnTitle: 'Selecinar',
  };

  if (fetchingRoutes || fetchingSelectedRoutes) return <Loader />;
  //if (hasError) return <Error />;
  return (
    <>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={tableData}
        columns={columns}
      />
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </>
  );
};

export default withLocalize(RouteSelectionGuideLandingPageList);
