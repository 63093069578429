import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import Axios from 'axios';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { Input as AppInput } from '../../../../../components/App/Input';
import { Modal } from '../../../../../components/App/Modal';
import { Heading, Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import {
  IntermediatePartialInterface,
  useIntermediateChangePasswordInfo,
} from '../../../../../hooks/administration/useIntermediateChangePasswordInfo';
import { ReactComponent as Lock } from '../../../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../../../shared_assets/icons/unsee.svg';
import {
  desktopPadding,
  getTheme,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
const useStyles = makeStyles({
  passwordInput: {
    maxWidth: '300px',
    marginRight: '1rem',
  },

  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: '25%',
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    flex: '1',
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },
  disableTextColor: {
    color: 'black !important',
  },
  divisorAvatar: {
    paddingBottom: '10px',
  },
  headerArea: {
    display: 'flex',
  },
  buttonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0.5',
  },
  avatarArea: {
    display: 'flex',
    height: '100px',
  },
  avatarPreview: {
    height: '100px',
    width: '100px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  select: {
    minWidth: '300px',
    color: 'black !important',
  },
  spacer: {
    margin: '0 1rem',
  },
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // ANCHOR: CSS
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  invisible: {
    display: 'none',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
});
interface ParamsInterface {
  id: string;
}
interface ChangePasswordProps extends LocalizeContextProps {}
const ChangePassword = ({ translate }: ChangePasswordProps) => {
  const classes = useStyles();

  const params = useParams<ParamsInterface | undefined>();
  const [
    intermediateInformation,
    setIntermediateInformation,
  ] = useState<IntermediatePartialInterface | null>(null);
  const [localIntermediateEmail, setLocalIntermediateEmail] = useState<string>(
    ''
  );
  const [localIntermediateName, setLocalIntermediateName] = useState<string>(
    ''
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetching] = useIntermediateChangePasswordInfo(
    params?.id,
    setIntermediateInformation
  );
  const [localPassword, setLocalPassword] = useState('');
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });

  const [changePassword, setChangePassword] = useState(false);

  // ANCHOR: VARIABLES
  useEffect(() => {
    if (!intermediateInformation) return;
    setLocalIntermediateEmail(intermediateInformation.email);
    setLocalIntermediateName(
      `${intermediateInformation.firstName} ${intermediateInformation.lastName}`
    );
    setChangePassword(false);
  }, [intermediateInformation]);

  const requestChangePassword = useCallback(async () => {
    if (newPassword.isInvalid) return;
    try {
      await Axios.patch(
        `${API_URL}/authentication/profile/${params?.id}/reset-password`,
        { newPassword: newPassword.value, oldPassword: 'none' }
      );
      //close modal
      message.success(
        translate('resetPassword.resetPasswordSuccess').toString()
      );
    } catch (err) {}
  }, [newPassword.isInvalid, newPassword.value, params, translate]);

  useMemo(() => {
    const formatStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const hasRightLength =
      localPassword.length >= 8 && localPassword.length <= 20;
    const emailSplit =
      localIntermediateEmail.includes('@') && localIntermediateEmail.split('@');
    const isStrength = localPassword.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (emailSplit &&
        localPassword.includes(emailSplit[0]) &&
        localIntermediateEmail !== '') ||
      localPassword === ''; //TODO: falta fazer validar o email

    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [translate('register.passNoContainNameMail').toString()],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isStrength ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewPassword({
      value: localPassword,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [localPassword, localIntermediateEmail, translate]);

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.headerArea}>
          <Heading className={classes.title} level={2}>
            <Translate id="adminstration.profileOf" />{' '}
            {`${localIntermediateName}`}
          </Heading>
        </div>

        <div className={classes.bodyContainer}>
          <Text weight="semibold" noMargin>
            <Translate id="profile.password" />
          </Text>
          <Modal
            fullScreenOnMobile={false}
            contentContainerClassName={classes.modalContent}
            headerTitle={<Translate id="button.addSkill" />}
            footer={
              <div className={classes.footer}>
                <Button
                  onClick={() => {
                    setChangePassword(false);
                  }}
                >
                  <Translate id="button.cancel" />
                </Button>
                <div className={classes.addRemoveBtn}>
                  <Button
                    type="primary"
                    onClick={() => {
                      requestChangePassword();
                      setChangePassword(false);
                    }}
                  >
                    <Translate id="button.save" />
                  </Button>
                </div>
              </div>
            }
            visible={changePassword}
            closable={false}
          >
            <Text color={'red'} noMargin weight="semibold">
              <Translate id="adminstration.passwordWarning" />
            </Text>
          </Modal>
          <div className={classes.centeredRow}>
            <AppInput
              huge
              className={classes.passwordInput}
              type={isPasswordShowing ? 'text' : 'password'}
              placeholder={translate('register.password').toString()}
              onChange={ev => setLocalPassword(ev.target.value)}
              value={localPassword}
              prefix={<Lock className={classes.icon} />}
              suffix={
                isPasswordShowing ? (
                  <div
                    onClick={() => {
                      setIsPasswordShowing(prevState => !prevState);
                    }}
                  >
                    <See className={classes.icon} />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setIsPasswordShowing(prevState => !prevState);
                    }}
                  >
                    <Unsee className={classes.icon} />
                  </div>
                )
              }
            />
            <Button
              type="ghost"
              onClick={() => {
                setChangePassword(true);
              }}
            >
              <Translate id="profile.change" />
            </Button>
          </div>
          <AnimatedInfo
            isVisible={newPassword.isVisible ? newPassword.isVisible : false}
            infos={newPassword.infos}
          />
          <Divider className={classes.divider} />
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(ChangePassword);
