import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { Schedule } from '../../types/schedule-new';
import { RefreshToken } from '../refresh/useRefreshToken';

type Hook = (
  guideId: string | undefined,
  setSchedules: (val: SetStateAction<Schedule[]>) => void,
  refresh?: RefreshToken | null
) => [boolean, boolean];

export const requestGuideSchedules = (guideId: string) =>
  Axios.get<Schedule[]>(`${API_URL}/schedule/guide/${guideId}`);

export const useGuideSchedules: Hook = (guideId, setSchedules, refresh) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!guideId) {
        setIsError(true);
        setSchedules([]);
        setIsLoading(false);
        return;
      }

      try {
        const { data: schedules } = await requestGuideSchedules(guideId);
        setSchedules(schedules);
      } catch (err) {
        setSchedules([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [guideId, refresh, setSchedules]);

  return [isLoading, isError];
};
