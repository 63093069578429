import Axios from 'axios';
import { API_URL } from '../../consts';

interface TranslationDto {
  language: string;
  description: string;
}

export interface EditAccessibilityDto {
  translations: TranslationDto[];
}

export const putAccessibility = (id: string, dto: EditAccessibilityDto) =>
  Axios.put(`${API_URL}/accessibility/${id}`, dto).catch(err => {
    return Promise.reject(err);
  });

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
