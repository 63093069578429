import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MobilePayment from './Payment/MobilePayment';

export default function MobileRouter() {
  return (
    <Switch>
      <Route path="/mobile/payment/:status" component={MobilePayment} />
    </Switch>
  );
}
