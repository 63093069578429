import { makeStyles } from '@material-ui/styles';
import { Space } from 'antd';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import React, { useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import Loader from 'react-spinners/BeatLoader';
import { Button } from '../../../../../components/App/Button';
import { Modal } from '../../../../../components/App/Modal';
import { AddAdvertiserResponse } from '../../../../../hooks/advertiser/addAdvertiser';
import { EditAdvertiserResponse } from '../../../../../hooks/advertiser/editAdvertiser';
import { removeAdvertiser } from '../../../../../hooks/advertiser/removeAdvertiser';
import {
  AdvertiserResponse,
  useAdvertiser,
} from '../../../../../hooks/advertiser/useAdvertiser';
import CreateAdvertiserModal from './CreateAdvertiserModal';

const useStyles = makeStyles({
  addDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },
  header: {
    paddingTop: 20,
  },
});

const ListAdvertisers = () => {
  const history = useHistory();

  const [advertisers, setAdvertisers] = useState<AdvertiserResponse>([]);
  const classes = useStyles();
  const [isLoading] = useAdvertiser(setAdvertisers);

  const [adding, setAdding] = useState<boolean>(false);
  const [editing, setEditing] = useState<string | undefined>();
  const [removing, setRemoving] = useState<string | undefined>();

  const closeAdding = useCallback(() => {
    setAdding(false);
  }, []);
  const onAdd = useCallback((newHelp: AddAdvertiserResponse) => {
    setAdvertisers(prevHelp => [...prevHelp, newHelp]);
    setAdding(false);
  }, []);

  const onEdit = useCallback((newHelp: EditAdvertiserResponse) => {
    setAdvertisers(prevHelp =>
      prevHelp.map(h => (h.id === newHelp.id ? newHelp : h))
    );
    setEditing(undefined);
  }, []);
  const listAds = useCallback(
    (advertiserId: string) => {
      history.push(`/admin/area/advertiser/list-ads/${advertiserId}`);
    },
    [history]
  );
  const columns = useMemo(
    () => [
      {
        title: <Translate id="adminstration.adArea.name" />,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: <Translate id="adminstration.adArea.email" />,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: <Translate id="adminstration.adArea.address" />,
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: <Translate id="adminstration.adArea.phoneNumber" />,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
      },
      {
        title: <Translate id="adminstration.adArea.url" />,
        dataIndex: 'url',
        key: 'url',
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <CreateAdvertiserModal
              advertiserContent={record}
              onEdit={onEdit}
              visible={record.id === editing}
              onRequestClose={() => {
                setEditing(undefined);
              }}
            />
            <Button
              type="link"
              onClick={() => {
                setEditing(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.edit" />
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                listAds(record.id);
              }}
            >
              <Translate id="adminstration.adArea.seeAds" />
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                setRemoving(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.remove" />
            </Button>
          </Space>
        ),
      },
    ],
    [editing, listAds, onEdit]
  );

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onDelete = useCallback(async () => {
    try {
      setSubmitting(true);
      if (removing) await removeAdvertiser(removing);
      setRemoving(undefined);
      setAdvertisers(prev => prev.filter(h => h.id !== removing));
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [removing]);

  return (
    <div>
      <Modal
        visible={!!removing}
        onRequestClose={() => {
          setRemoving(undefined);
        }}
        footer={
          <div className={classes.footer}>
            <Button
              disabled={submitting}
              onClick={() => setRemoving(undefined)}
            >
              <Translate id="button.cancel" />
            </Button>
            <Button disabled={submitting} onClick={onDelete}>
              <Translate id="button.remove" />
            </Button>
          </div>
        }
        contentContainerClassName={classes.header}
      >
        <Translate id="adminstration.helpZone.removeConfirmation" />
      </Modal>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.addDiv}>
            <Button
              type="primary"
              onClick={() => {
                setAdding(true);
              }}
            >
              <Translate id="adminstration.helpZone.add" />
            </Button>
          </div>
          <CreateAdvertiserModal
            onAdd={onAdd}
            onRequestClose={closeAdding}
            visible={adding}
          />
          <Table columns={columns} dataSource={advertisers} />
        </>
      )}
    </div>
  );
};

export default React.memo(ListAdvertisers);
