import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { mobileThreshhold } from '../../../utils';

const useStyles = makeStyles({
  areaCard: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60vw',
    minWidth: '60vw',
    margin: '0 0.75rem',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  title: ({ titleCentered }: { titleCentered?: boolean }) => ({
    fontWeight: 'bold',
    textAlign: titleCentered ? 'center' : undefined,
  }),
  positionText: ({ titleCentered }: { titleCentered?: boolean }) => ({
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    textAlign: titleCentered ? 'center' : undefined,
  }),
  description: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    areaCard: {
      maxWidth: 'unset',
      minWidth: 'unset',
      margin: '0.7rem 0',
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
});

export interface PointDescriptionProps {
  title: string;
  position: string;
  locationTitle?: string;
  description?: string;
  localBusiness?: string;
}

export default function PointDescription({
  title,
  description,
  locationTitle,
  localBusiness,
}: PointDescriptionProps) {
  const classes = useStyles({ titleCentered: description === undefined });
  return (
    <div className={classes.areaCard}>
      <p className={classes.title}>{locationTitle}</p>
      <p className={classes.description}>{title}</p>
      <p className={classes.description}>{description}</p>
      {localBusiness ? (
        <p className={classes.description}>{localBusiness}</p>
      ) : null}
    </div>
  );
}
