import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
// ANCHOR: IMPORTS
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { Text } from '../../../../../../components/Typography';
import { postWhatWeGive } from '../../../../../../hooks/administration/usePostWhatWeGive';
import {
  ManageWhatWeGiveInterface,
  StepInterface,
  useWhatWeGive,
  WhatWeGiveInterface,
} from '../../../../../../hooks/administration/useWhatWeGive';
import { UseJWT } from '../../../../../../hooks/authentication/UseJWT';
import { ErrorCode } from '../../../../../../types/error.codes.enum';
import { GenericPageTransition } from '../../../../../PageUtils';

interface ErrorInterface {
  error: boolean;
  reason: string;
}

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  divider: {
    margin: '1rem 0',
  },
  titleDiv: {
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  avatarArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarPreview: {
    maxHeight: '25rem',
    marginBottom: '1rem',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  button: {
    marginLeft: '1rem',
  },
});

interface WhatWeGiveProps extends LocalizeContextProps {}
const WhatWeGive = ({ languages, translate }: WhatWeGiveProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();

  const [whatWeGive, setWhatWeGive] = useState<WhatWeGiveInterface | null>(
    null
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, errorLoading] = useWhatWeGive(setWhatWeGive);

  const [position0, setPosition0] = useState<StepInterface[]>([]);
  const [position1, setPosition1] = useState<StepInterface[]>([]);
  const [position2, setPosition2] = useState<StepInterface[]>([]);
  const [position3, setPosition3] = useState<StepInterface[]>([]);

  const [whatWeCanGive, setWhatWeCanGive] = useState<
    ManageWhatWeGiveInterface[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    setWhatWeCanGive(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = whatWeGive?.whatWeGiveTexts.find(
            lp => lp.language === l
          );
          if (translation) {
            return translation;
          }
          return {
            firstTitle: '',
            text1: '',
            text2: '',
            secondTitle: '',
            text3: '',
            text4: '',
            language: l,
          };
        })
    );

    setPosition0(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = whatWeGive?.position0.find(
            lp => lp.language === l
          );
          if (translation) {
            return translation;
          }
          return {
            title: '',
            description: '',
            position: 0,
            language: l,
          };
        })
    );

    setPosition1(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = whatWeGive?.position1.find(
            lp => lp.language === l
          );
          if (translation) {
            return translation;
          }
          return {
            title: '',
            description: '',
            position: 1,
            language: l,
          };
        })
    );
    setPosition2(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = whatWeGive?.position2.find(
            lp => lp.language === l
          );
          if (translation) {
            return translation;
          }
          return {
            title: '',
            description: '',
            position: 2,
            language: l,
          };
        })
    );

    setPosition3(
      languages
        .map(l => l.code)
        .map(l => {
          const translation = whatWeGive?.position3.find(
            lp => lp.language === l
          );
          if (translation) {
            return translation;
          }
          return {
            title: '',
            description: '',
            position: 3,
            language: l,
          };
        })
    );
  }, [languages, whatWeGive]);
  const submit = useCallback(async () => {
    if (
      position1.some(
        p =>
          validator.isEmpty(p.title) ||
          validator.isEmpty(p.language) ||
          validator.isEmpty(p.description)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    if (
      position2.some(
        p =>
          validator.isEmpty(p.title) ||
          validator.isEmpty(p.language) ||
          validator.isEmpty(p.description)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    if (
      position3.some(
        p =>
          validator.isEmpty(p.title) ||
          validator.isEmpty(p.language) ||
          validator.isEmpty(p.description)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    try {
      await postWhatWeGive({
        whatWeGive: whatWeCanGive,
        steps: position0.concat(position1, position2, position3),
      });
      message.success(
        translate('adminstration.guideSelection.success').toString()
      );
    } catch (error) {
      message.error(
        `${translate(
          `error.API_ERROR.${ErrorCode.LANDING_PAGE_NEED_TRANSLATION}`
        )} ${translate(`languages.${error.message}`)}`
      );
    }
  }, [position1, position2, position3, whatWeCanGive, position0, translate]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {/* SECÇÂO 1********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.whatWeGive.pageTitle" />
        </Text>
      </div>

      <Divider className={classes.divider} />
      <Tabs type="card">
        {whatWeCanGive.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.firstTitle" />
              </Text>

              <Input
                value={state.firstTitle}
                onChange={ev => {
                  ev.persist();
                  setWhatWeCanGive(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          firstTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.firstTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.text1" />
              </Text>
              <Input
                value={state.text1}
                onChange={ev => {
                  ev.persist();
                  setWhatWeCanGive(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          text1: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.text1)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.text2" />
              </Text>
              <Input
                value={state.text2}
                onChange={ev => {
                  ev.persist();
                  setWhatWeCanGive(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          text2: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.text2)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.secondTitle" />
              </Text>
              <Input
                value={state.secondTitle}
                onChange={ev => {
                  ev.persist();
                  setWhatWeCanGive(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          secondTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.secondTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.text3" />
              </Text>
              <Input
                value={state.text3}
                onChange={ev => {
                  ev.persist();
                  setWhatWeCanGive(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          text3: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.text3)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.text4" />
              </Text>
              <Input
                value={state.text4}
                onChange={ev => {
                  ev.persist();
                  setWhatWeCanGive(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          text4: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.text4)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>

      {/* SECÇÂO 1********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.whatWeGive.position1" />
        </Text>
      </div>

      <Divider className={classes.divider} />
      <Tabs type="card">
        {position0.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.title" />
              </Text>

              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setPosition0(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.description" />
              </Text>
              <Input
                value={state.description}
                onChange={ev => {
                  ev.persist();
                  setPosition0(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          description: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.description)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>
      {/* SECÇÂO 2********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.whatWeGive.position2" />
        </Text>
      </div>

      <Divider className={classes.divider} />
      <Tabs type="card">
        {position1.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.title" />
              </Text>

              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setPosition1(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.description" />
              </Text>
              <Input
                value={state.description}
                onChange={ev => {
                  ev.persist();
                  setPosition1(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          description: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.description)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>

      {/* SECÇÂO 2********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.whatWeGive.position3" />
        </Text>
      </div>
      <Divider className={classes.divider} />
      <Tabs type="card">
        {position2.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.title" />
              </Text>

              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setPosition2(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.description" />
              </Text>
              <Input
                value={state.description}
                onChange={ev => {
                  ev.persist();
                  setPosition2(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          description: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.description)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>
      {/* SECÇÂO 3********************************************************** */}
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.whatWeGive.position4" />
        </Text>
      </div>
      <Divider className={classes.divider} />
      <Tabs type="card">
        {position3.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.title" />
              </Text>

              <Input
                value={state.title}
                onChange={ev => {
                  ev.persist();
                  setPosition3(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          title: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.title)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.whatWeGive.description" />
              </Text>
              <Input
                value={state.description}
                onChange={ev => {
                  ev.persist();
                  setPosition3(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          description: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.description)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
            </TabPane>
          );
        })}
      </Tabs>

      <Divider className={classes.divider} />
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </motion.div>
  );
};

export default withLocalize(WhatWeGive);
