import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../../consts';

type Hook = (
  bookingId: string,
  setRefundInformation: (
    val: SetStateAction<RefundInformationInterface | null>
  ) => void
) => [boolean, boolean];

export interface RefundInformationInterface {
  refunded: boolean;
  cut: number;
  value: number;
}

export const requestVisitorBookings = (bookingId: string) =>
  Axios.get<RefundInformationInterface>(
    `${API_URL}/booking/${bookingId}/refund-information`
  );

export const useRefundInformation: Hook = (bookingId, setRefundInformation) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!bookingId) {
        setIsError(true);
        setRefundInformation(null);
        setIsLoading(false);

        return;
      }

      try {
        const { data: info } = await requestVisitorBookings(bookingId);
        setRefundInformation(info as RefundInformationInterface);
        setIsError(false);
      } catch (err) {
        setRefundInformation(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [bookingId, setRefundInformation]);

  return [isLoading, isError];
};
