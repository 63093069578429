import Axios, { CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';

export interface CompanyOnboardingInterface {
  id: string;
  name: string;
  nif: string;
}

const requestCompaniesList = () =>
  Axios.get<CompanyOnboardingInterface[]>(`${API_URL}/company/onboarding`);

type Hook = (
  setCompaniesList: (val: SetStateAction<CompanyOnboardingInterface[]>) => void
) => [boolean, boolean];

export const useCompanyOnboarding: Hook = setCompaniesList => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestCompaniesList();

        setCompaniesList(list);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setCompaniesList([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [dispatch, setCompaniesList]);

  return [isLoading, isError];
};
