import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

export enum HelpType {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ADDRESS = 'ADDRESS',
}

export type HelpResponse = {
  type: HelpType;
  content: string;
  id: string;
}[];

const fetchHelp = () =>
  Axios.get<HelpResponse>(`${API_URL}/content-management/help`);

type Hook = (
  setter: (v: SetStateAction<HelpResponse>) => void
) => [boolean, boolean];

export const useHelp: Hook = setHelp => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await fetchHelp();
        setHelp(data);
      } catch (err) {
        setHelp([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setHelp]);

  return [isLoading, isError];
};
