import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../components/App/AnimatedError';
import { Error } from '../../../../components/App/Error';
import { Loader } from '../../../../components/App/Loader';
import { Text } from '../../../../components/Typography';
import {
  SystemConfigurationResponse,
  useCurrentAnnualFee,
} from '../../../../hooks/administration/UseCurrentAnnualFee';
import { postSystemConfiguration } from '../../../../hooks/administration/usePostSystemConfiguration';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { GenericPageTransition } from '../../../PageUtils';

interface ErrorInterface {
  error: boolean;
  reason: string;
}

const useStyles = makeStyles({
  divider: {
    margin: '1rem 0',
  },

  divSubmitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  tittles: {
    marginBottom: '2rem !important',
  },
  groupInformation: {
    border: 2,
    borderStyle: 'solid',
    padding: '2rem',
    marginBottom: '2rem',
  },
  textWrapper: { display: 'flex', justifyContent: 'space-around' },
  spacer: {
    margin: '0 1rem',
  },
});

interface SystemConfigurationProps extends LocalizeContextProps {}
const SystemConfiguration = ({ translate }: SystemConfigurationProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();

  const [
    systemConfiguration,
    setSystemConfiguration,
  ] = useState<SystemConfigurationResponse | null>(null);
  const [
    fetchingSystemConfiguration,
    errorFetchingSystemConfiguration,
  ] = useCurrentAnnualFee(setSystemConfiguration);
  const [localAnnualFee, setLocalAnnualFee] = useState<string>('');
  const [localUniqueCut, setLocalUniqueCut] = useState<string>('');
  //ANCHOR: STATES

  const [localTimeValue, setLocalTimeValue] = useState<string>('0');
  const [localTimePercentage, setLocalTimePercentage] = useState<string>('0');
  const [languageNumber, setLanguageNumber] = useState<string>('0');
  const [languagePercentage, setLanguagePercentage] = useState<string>('0');
  const [minLBValue, setMinLBValue] = useState<string>('0');
  const [maxLBValue, setMaxLBValue] = useState<string>('0');
  const [minLBPercentage, setMinLBPercentage] = useState<string>('0');
  const [maxLBPercentage, setMaxLBPercentage] = useState<string>('0');
  const [minReviewValue, setMinReviewValue] = useState<string>('0');
  const [mid1ReviewValue, setMid1ReviewValue] = useState<string>('0');
  const [mid2ReviewValue, setMid2ReviewValue] = useState<string>('0');
  const [maxReviewValue, setMaxReviewValue] = useState<string>('0');
  const [minReviewPercentage, setMinReviewPercentage] = useState<string>('0');
  const [mid1ReviewPercentage, setMid1ReviewPercentage] = useState<string>('0');
  const [mid2ReviewPercentage, setMid2ReviewPercentage] = useState<string>('0');
  const [maxReviewPercentage, setMaxReviewPercentage] = useState<string>('0');
  const [minCompletedValue, setMinCompletedValue] = useState<string>('0');
  const [midCompletedValue, setMidCompletedValue] = useState<string>('0');
  const [maxCompletedValue, setMaxCompletedValue] = useState<string>('0');
  const [minCompletedPercentage, setMinCompletedPercentage] = useState<string>(
    '0'
  );
  const [midCompletedPercentage, setMidCompletedPercentage] = useState<string>(
    '0'
  );
  const [maxCompletedPercentage, setMaxCompletedPercentage] = useState<string>(
    '0'
  );
  const [childrenDiscount, setChildrenDiscount] = useState<string>('0');
  const [seniorDiscount, setSeniorDiscount] = useState<string>('0');

  useEffect(() => {
    if (!systemConfiguration) return;
    setLocalAnnualFee(systemConfiguration.annualFee.toString());
    setLocalUniqueCut(systemConfiguration.uniqueCut.toString());
    setLocalTimeValue(systemConfiguration.timeValue.toString());
    setLocalTimePercentage(systemConfiguration.timePercentage.toString());
    setLanguageNumber(systemConfiguration.languageNumber.toString());
    setLanguagePercentage(systemConfiguration.languagePercentage.toString());
    setMinLBValue(systemConfiguration.minLocalBusinessValue.toString());
    setMaxLBValue(systemConfiguration.maxLocalBusinessValue.toString());
    setMinLBPercentage(
      systemConfiguration.minLocalBusinessPercentage.toString()
    );
    setMaxLBPercentage(
      systemConfiguration.maxLocalBusinessPercentage.toString()
    );
    setMinReviewValue(systemConfiguration.minReviewValue.toString());
    setMid1ReviewValue(systemConfiguration.mid1ReviewValue.toString());
    setMid2ReviewValue(systemConfiguration.mid2ReviewValue.toString());
    setMaxReviewValue(systemConfiguration.maxReviewValue.toString());
    setMinReviewPercentage(systemConfiguration.minReviewPercentage.toString());
    setMid1ReviewPercentage(
      systemConfiguration.mid1ReviewPercentage.toString()
    );
    setMid2ReviewPercentage(
      systemConfiguration.mid2ReviewPercentage.toString()
    );

    setMaxReviewPercentage(systemConfiguration.maxReviewPercentage.toString());
    setMinCompletedValue(systemConfiguration.minCompletedValue.toString());
    setMidCompletedValue(systemConfiguration.midCompletedValue.toString());
    setMaxCompletedValue(systemConfiguration.maxCompletedValue.toString());
    setMinCompletedPercentage(
      systemConfiguration.minCompletedPercentage.toString()
    );
    setMidCompletedPercentage(
      systemConfiguration.midCompletedPercentage.toString()
    );
    setMaxCompletedPercentage(
      systemConfiguration.maxCompletedPercentage.toString()
    );
    setChildrenDiscount(systemConfiguration.childrenDiscount.toString());
    setSeniorDiscount(systemConfiguration.seniorDiscount.toString());
  }, [systemConfiguration]);
  //ANCHOR: ERROR

  const uniqueCutError = React.useMemo((): ErrorInterface => {
    if (
      validator.isEmpty(localUniqueCut) ||
      !validator.isNumeric(localUniqueCut)
    ) {
      return {
        error: true,
        reason: 'Refund Percentage must be filled',
      };
    }

    return { error: false, reason: '' };
  }, [localUniqueCut]);
  const annualFeeError = React.useMemo((): ErrorInterface => {
    if (
      validator.isEmpty(localAnnualFee) ||
      !validator.isNumeric(localAnnualFee)
    ) {
      return {
        error: true,
        reason: 'Annual Fee must be filled',
      };
    }

    return { error: false, reason: '' };
  }, [localAnnualFee]);
  const timePercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(localTimePercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [localTimePercentage]);
  const timeValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(localTimeValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [localTimeValue]);

  const childrenDiscountError = React.useMemo((): ErrorInterface => {
    if (parseFloat(childrenDiscount) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [childrenDiscount]);
  const seniorDiscountError = React.useMemo((): ErrorInterface => {
    if (parseFloat(seniorDiscount) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [seniorDiscount]);
  const languagePercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(languagePercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [languagePercentage]);
  const minLBValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(minLBValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [minLBValue]);

  const maxLBValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(maxLBValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }
    if (parseFloat(maxLBValue) <= parseFloat(minLBValue)) {
      return {
        error: true,
        reason:
          'Value of Intervalo máximo should be greater then the Value of Intervalo mínimo ',
      };
    }
    return { error: false, reason: '' };
  }, [maxLBValue, minLBValue]);

  const minLBPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(minLBPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [minLBPercentage]);

  const maxLBPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(maxLBPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [maxLBPercentage]);

  const languageNumberError = React.useMemo((): ErrorInterface => {
    if (parseFloat(languageNumber) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [languageNumber]);

  const minReviewValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(minReviewValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [minReviewValue]);

  const mid1ReviewValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(mid1ReviewValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }
    if (parseFloat(mid1ReviewValue) <= parseFloat(minReviewValue)) {
      return {
        error: true,
        reason:
          'Value of Intervalo intermédio 1 should be greater then the Value of Intervalo mínimo',
      };
    }
    return { error: false, reason: '' };
  }, [mid1ReviewValue, minReviewValue]);

  const mid2ReviewValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(mid2ReviewValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }
    if (
      parseFloat(mid2ReviewValue) <= parseFloat(minReviewValue) ||
      parseFloat(mid2ReviewValue) <= parseFloat(mid1ReviewValue)
    ) {
      return {
        error: true,
        reason:
          'Value of Intervalo intermédio 2 should be greater then the Value of Intervalo mínimo and Intervalo intermédio 1',
      };
    }
    return { error: false, reason: '' };
  }, [mid1ReviewValue, mid2ReviewValue, minReviewValue]);

  const maxReviewValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(maxReviewValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }
    if (
      parseFloat(maxReviewValue) <= parseFloat(minReviewValue) ||
      parseFloat(maxReviewValue) <= parseFloat(mid1ReviewValue) ||
      parseFloat(maxReviewValue) <= parseFloat(mid2ReviewValue)
    ) {
      return {
        error: true,
        reason:
          'Value of Intervalo máximo should be greater then the Value of Intervalo mínimo and Intervalo intermédio 1 and Intervalo intermédio 2',
      };
    }
    return { error: false, reason: '' };
  }, [maxReviewValue, mid1ReviewValue, mid2ReviewValue, minReviewValue]);

  const minReviewPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(minReviewPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [minReviewPercentage]);

  const mid1ReviewPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(mid1ReviewPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [mid1ReviewPercentage]);

  const mid2ReviewPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(mid2ReviewPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [mid2ReviewPercentage]);

  const maxReviewPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(maxReviewPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [maxReviewPercentage]);

  //ERROR

  const minCompletedValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(minCompletedValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [minCompletedValue]);

  const midCompletedValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(midCompletedValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }
    if (parseFloat(midCompletedValue) <= parseFloat(minCompletedValue)) {
      return {
        error: true,
        reason:
          'Value of Intervalo intermédio should be greater then Intervalo mínimo ',
      };
    }
    return { error: false, reason: '' };
  }, [midCompletedValue, minCompletedValue]);

  const maxCompletedValueError = React.useMemo((): ErrorInterface => {
    if (parseFloat(maxCompletedValue) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }
    if (
      parseFloat(maxCompletedValue) <= parseFloat(midCompletedValue) ||
      parseFloat(maxCompletedValue) <= parseFloat(minCompletedValue)
    ) {
      return {
        error: true,
        reason:
          'Value of Intervalo máximo should be greater then Intervalo mínimo and Intervalo intermédio',
      };
    }
    return { error: false, reason: '' };
  }, [maxCompletedValue, midCompletedValue, minCompletedValue]);

  const minCompletedPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(minCompletedPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [minCompletedPercentage]);

  const midCompletedPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(midCompletedPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [midCompletedPercentage]);

  const maxCompletedPercentageError = React.useMemo((): ErrorInterface => {
    if (parseFloat(maxCompletedPercentage) < 0) {
      return {
        error: true,
        reason: 'Value should be greater or equal to 0',
      };
    }

    return { error: false, reason: '' };
  }, [maxCompletedPercentage]);

  //ANCHOR: SUBMIT
  const submitForm = useCallback(() => {
    if (
      uniqueCutError.error ||
      annualFeeError.error ||
      timePercentageError.error ||
      timeValueError.error ||
      languageNumberError.error ||
      languagePercentageError.error ||
      minLBValueError.error ||
      maxLBValueError.error ||
      minLBPercentageError.error ||
      maxLBPercentageError.error ||
      minReviewValueError.error ||
      mid1ReviewValueError.error ||
      mid2ReviewValueError.error ||
      maxReviewValueError.error ||
      minReviewPercentageError.error ||
      mid1ReviewPercentageError.error ||
      mid2ReviewPercentageError.error ||
      maxReviewPercentageError.error ||
      maxCompletedPercentageError.error ||
      midCompletedPercentageError.error ||
      minCompletedPercentageError.error ||
      maxCompletedValueError.error ||
      midCompletedValueError.error ||
      minCompletedValueError.error ||
      seniorDiscountError.error ||
      childrenDiscountError.error
    ) {
      return;
    }
    try {
      postSystemConfiguration({
        cut: parseFloat(localUniqueCut),
        fee: parseFloat(localAnnualFee),
        timeValue: parseFloat(localTimeValue),
        timePercentage: parseFloat(localTimePercentage),
        languageNumber: parseFloat(languageNumber),
        languagePercentage: parseFloat(languagePercentage),
        minLocalBusinessValue: parseFloat(minLBValue),
        maxLocalBusinessValue: parseFloat(maxLBValue),
        minLocalBusinessPercentage: parseFloat(minLBPercentage),
        maxLocalBusinessPercentage: parseFloat(maxLBPercentage),
        minReviewValue: parseFloat(minReviewValue),
        mid1ReviewValue: parseFloat(mid1ReviewValue),
        mid2ReviewValue: parseFloat(mid2ReviewValue),

        maxReviewValue: parseFloat(maxReviewValue),
        minReviewPercentage: parseFloat(minReviewPercentage),
        mid1ReviewPercentage: parseFloat(mid1ReviewPercentage),
        mid2ReviewPercentage: parseFloat(mid2ReviewPercentage),

        maxReviewPercentage: parseFloat(maxReviewPercentage),
        minCompletedValue: parseFloat(minCompletedValue),
        midCompletedValue: parseFloat(midCompletedValue),
        maxCompletedValue: parseFloat(maxCompletedValue),
        minCompletedPercentage: parseFloat(minCompletedPercentage),
        midCompletedPercentage: parseFloat(midCompletedPercentage),
        maxCompletedPercentage: parseFloat(maxCompletedPercentage),
        childrenDiscount: parseFloat(childrenDiscount),
        seniorDiscount: parseFloat(seniorDiscount),
      });
      message.success(
        translate('adminstration.systemConfiguration.patchSuccess').toString()
      );
    } catch (err) {
      message.error(
        translate('adminstration.systemConfiguration.patchError').toString()
      );
    }
  }, [
    uniqueCutError.error,
    annualFeeError.error,
    timePercentageError.error,
    timeValueError.error,
    languageNumberError.error,
    languagePercentageError.error,
    minLBValueError.error,
    maxLBValueError.error,
    minLBPercentageError.error,
    maxLBPercentageError.error,
    minReviewValueError.error,
    mid1ReviewValueError.error,
    mid2ReviewValueError.error,
    maxReviewValueError.error,
    minReviewPercentageError.error,
    mid1ReviewPercentageError.error,
    mid2ReviewPercentageError.error,
    maxReviewPercentageError.error,
    maxCompletedPercentageError.error,
    midCompletedPercentageError.error,
    minCompletedPercentageError.error,
    maxCompletedValueError.error,
    midCompletedValueError.error,
    minCompletedValueError.error,
    seniorDiscountError.error,
    childrenDiscountError.error,
    localUniqueCut,
    localAnnualFee,
    localTimeValue,
    localTimePercentage,
    languageNumber,
    languagePercentage,
    minLBValue,
    maxLBValue,
    minLBPercentage,
    maxLBPercentage,
    minReviewValue,
    mid1ReviewValue,
    mid2ReviewValue,
    maxReviewValue,
    minReviewPercentage,
    mid1ReviewPercentage,
    mid2ReviewPercentage,
    maxReviewPercentage,
    minCompletedValue,
    midCompletedValue,
    maxCompletedValue,
    minCompletedPercentage,
    midCompletedPercentage,
    maxCompletedPercentage,
    childrenDiscount,
    seniorDiscount,
    translate,
  ]);

  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }
  if (fetchingSystemConfiguration) {
    return <Loader />;
  }

  if (errorFetchingSystemConfiguration) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Text weight="semibold" noMargin>
        <Translate id="adminstration.systemConfiguration.annualFee" />
      </Text>
      <Input
        type="number"
        step="0.01"
        onChange={ev => {
          setLocalAnnualFee(ev.currentTarget.value);
        }}
        value={localAnnualFee}
      />
      <AnimatedError
        isVisible={annualFeeError.error}
        reason={annualFeeError.reason}
      />
      <Divider className={classes.divider} />
      <Text weight="semibold" noMargin>
        <Translate id="adminstration.systemConfiguration.uniqueCut" />
      </Text>
      <Input
        type="number"
        step="0.01"
        onChange={ev => {
          setLocalUniqueCut(ev.currentTarget.value);
        }}
        value={localUniqueCut}
      />
      <AnimatedError
        isVisible={uniqueCutError.error}
        reason={uniqueCutError.reason}
      />
      <Divider className={classes.divider} />

      {/* DISCOUNTS */}
      <div className={classes.textWrapper}>
        <Text weight="semibold" noMargin className={classes.row}>
          <Translate id="adminstration.systemConfiguration.childrenDiscount" />
        </Text>
        <Text weight="semibold" noMargin className={classes.row}>
          <Translate id="adminstration.systemConfiguration.seniorDiscount" />
        </Text>
      </div>
      <div className={classes.row}>
        <Input
          type="number"
          step="0.01"
          placeholder="0"
          onChange={ev => {
            setChildrenDiscount(ev.currentTarget.value);
          }}
          value={childrenDiscount}
        />

        <span className={classes.spacer}>-</span>

        <Input
          placeholder="XXX"
          type="number"
          step="0.01"
          onChange={ev => {
            setSeniorDiscount(ev.currentTarget.value);
          }}
          value={seniorDiscount}
        />
      </div>
      <AnimatedError
        isVisible={childrenDiscountError.error}
        reason={childrenDiscountError.reason}
      />
      <AnimatedError
        isVisible={seniorDiscountError.error}
        reason={seniorDiscountError.reason}
      />

      {/* MIN AFTER 30 */}
      <div className={classes.textWrapper}>
        <Text weight="semibold" noMargin className={classes.row}>
          <Translate id="adminstration.systemConfiguration.timeValue" />
        </Text>
        <Text weight="semibold" noMargin className={classes.row}>
          <Translate id="adminstration.systemConfiguration.timePercentage" />
        </Text>
      </div>
      <div className={classes.row}>
        <Input
          type="number"
          step="0.01"
          placeholder="0"
          onChange={ev => {
            setLocalTimeValue(ev.currentTarget.value);
          }}
          value={localTimeValue}
        />

        <span className={classes.spacer}>-</span>

        <Input
          placeholder="XXX"
          type="number"
          step="0.01"
          onChange={ev => {
            setLocalTimePercentage(ev.currentTarget.value);
          }}
          value={localTimePercentage}
        />
      </div>
      <AnimatedError
        isVisible={timeValueError.error}
        reason={timeValueError.reason}
      />
      <AnimatedError
        isVisible={timePercentageError.error}
        reason={timePercentageError.reason}
      />

      {/* LANGUAGE */}
      <div className={classes.textWrapper}>
        <Text weight="semibold" noMargin className={classes.row}>
          <Translate id="adminstration.systemConfiguration.languageNumber" />
        </Text>
        <Text weight="semibold" noMargin className={classes.row}>
          <Translate id="adminstration.systemConfiguration.languagePercentage" />
        </Text>
      </div>
      <div className={classes.row}>
        <Input
          type="number"
          step="0.01"
          placeholder="0"
          onChange={ev => {
            setLanguageNumber(ev.currentTarget.value);
          }}
          value={languageNumber}
        />

        <span className={classes.spacer}>-</span>

        <Input
          placeholder="XXX"
          type="number"
          step="0.01"
          onChange={ev => {
            setLanguagePercentage(ev.currentTarget.value);
          }}
          value={languagePercentage}
        />
      </div>
      <AnimatedError
        isVisible={languageNumberError.error}
        reason={languageNumberError.reason}
      />
      <AnimatedError
        isVisible={languagePercentageError.error}
        reason={languagePercentageError.reason}
      />
      {/* LOCAL BUSINESS  */}
      <div className={classes.groupInformation}>
        <Text weight="semibold" noMargin className={classes.tittles}>
          <Translate id="adminstration.systemConfiguration.localBusinessTitle" />
        </Text>
        <div className={classes.textWrapper}>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.minLocalBusinessValue" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.maxLocalBusinessValue" />
          </Text>
        </div>
        {/* VALUE */}
        <div className={classes.row}>
          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMinLBValue(ev.currentTarget.value);
            }}
            value={minLBValue}
          />

          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMaxLBValue(ev.currentTarget.value);
            }}
            value={maxLBValue}
          />
        </div>

        {/* PERCENTAGE */}

        <div className={classes.textWrapper}>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.minLocalBusinessPercentage" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.maxLocalBusinessPercentage" />
          </Text>
        </div>
        <div className={classes.row}>
          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMinLBPercentage(ev.currentTarget.value);
            }}
            value={minLBPercentage}
          />

          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMaxLBPercentage(ev.currentTarget.value);
            }}
            value={maxLBPercentage}
          />
        </div>
        <AnimatedError
          isVisible={minLBValueError.error}
          reason={minLBValueError.reason}
        />
        <AnimatedError
          isVisible={maxLBValueError.error}
          reason={maxLBValueError.reason}
        />
        <AnimatedError
          isVisible={minLBPercentageError.error}
          reason={minLBPercentageError.reason}
        />
        <AnimatedError
          isVisible={maxLBPercentageError.error}
          reason={maxLBPercentageError.reason}
        />
      </div>
      {/* COMPLETED PEDIROUTES */}
      <div className={classes.groupInformation}>
        <Text weight="semibold" noMargin className={classes.tittles}>
          <Translate id="adminstration.systemConfiguration.pedirouteTitle" />
        </Text>
        <div className={classes.textWrapper}>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.minCompletedValue" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.midCompletedValue" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.maxCompletedValue" />
          </Text>
        </div>
        {/* VALUE */}
        <div className={classes.row}>
          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMinCompletedValue(ev.currentTarget.value);
            }}
            value={minCompletedValue}
          />

          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMidCompletedValue(ev.currentTarget.value);
            }}
            value={midCompletedValue}
          />
          <span className={classes.spacer}>-</span>

          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMaxCompletedValue(ev.currentTarget.value);
            }}
            value={maxCompletedValue}
          />
        </div>

        {/* PERCENTAGE */}

        <div className={classes.textWrapper}>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.minCompletedPercentage" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.midCompletedPercentage" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.maxCompletedPercentage" />
          </Text>
        </div>
        <div className={classes.row}>
          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMinCompletedPercentage(ev.currentTarget.value);
            }}
            value={minCompletedPercentage}
          />

          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMidCompletedPercentage(ev.currentTarget.value);
            }}
            value={midCompletedPercentage}
          />
          <span className={classes.spacer}>-</span>

          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMaxCompletedPercentage(ev.currentTarget.value);
            }}
            value={maxCompletedPercentage}
          />
        </div>

        <AnimatedError
          isVisible={minCompletedValueError.error}
          reason={minCompletedValueError.reason}
        />
        <AnimatedError
          isVisible={midCompletedValueError.error}
          reason={midCompletedValueError.reason}
        />
        <AnimatedError
          isVisible={maxCompletedValueError.error}
          reason={maxCompletedValueError.reason}
        />
        <AnimatedError
          isVisible={minCompletedPercentageError.error}
          reason={minCompletedPercentageError.reason}
        />
        <AnimatedError
          isVisible={midCompletedPercentageError.error}
          reason={midCompletedPercentageError.reason}
        />
        <AnimatedError
          isVisible={maxCompletedPercentageError.error}
          reason={maxCompletedPercentageError.reason}
        />
      </div>
      {/* REVIEW */}
      <div className={classes.groupInformation}>
        <Text weight="semibold" noMargin className={classes.tittles}>
          <Translate id="adminstration.systemConfiguration.reviewTittle" />
        </Text>
        <div className={classes.textWrapper}>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.minReviewValue" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.mid1ReviewValue" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.mid2ReviewValue" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.maxReviewValue" />
          </Text>
        </div>
        {/* VALUE */}
        <div className={classes.row}>
          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMinReviewValue(ev.currentTarget.value);
            }}
            value={minReviewValue}
          />

          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMid1ReviewValue(ev.currentTarget.value);
            }}
            value={mid1ReviewValue}
          />
          <span className={classes.spacer}>-</span>

          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMid2ReviewValue(ev.currentTarget.value);
            }}
            value={mid2ReviewValue}
          />
          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMaxReviewValue(ev.currentTarget.value);
            }}
            value={maxReviewValue}
          />
        </div>

        {/* PERCENTAGE */}

        <div className={classes.textWrapper}>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.minReviewPercentage" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.mid1ReviewPercentage" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.mid2ReviewPercentage" />
          </Text>
          <Text weight="semibold" noMargin className={classes.row}>
            <Translate id="adminstration.systemConfiguration.maxReviewPercentage" />
          </Text>
        </div>
        <div className={classes.row}>
          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMinReviewPercentage(ev.currentTarget.value);
            }}
            value={minReviewPercentage}
          />

          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMid1ReviewPercentage(ev.currentTarget.value);
            }}
            value={mid1ReviewPercentage}
          />
          <span className={classes.spacer}>-</span>

          <Input
            type="number"
            step="0.01"
            placeholder="0"
            onChange={ev => {
              setMid2ReviewPercentage(ev.currentTarget.value);
            }}
            value={mid2ReviewPercentage}
          />
          <span className={classes.spacer}>-</span>

          <Input
            placeholder="XXX"
            type="number"
            step="0.01"
            onChange={ev => {
              setMaxReviewPercentage(ev.currentTarget.value);
            }}
            value={maxReviewPercentage}
          />
        </div>

        <AnimatedError
          isVisible={minReviewValueError.error}
          reason={minReviewValueError.reason}
        />
        <AnimatedError
          isVisible={mid1ReviewValueError.error}
          reason={mid1ReviewValueError.reason}
        />
        <AnimatedError
          isVisible={mid2ReviewValueError.error}
          reason={mid2ReviewValueError.reason}
        />
        <AnimatedError
          isVisible={maxReviewValueError.error}
          reason={maxReviewValueError.reason}
        />
        <AnimatedError
          isVisible={minReviewPercentageError.error}
          reason={minReviewPercentageError.reason}
        />
        <AnimatedError
          isVisible={mid1ReviewPercentageError.error}
          reason={mid1ReviewPercentageError.reason}
        />
        <AnimatedError
          isVisible={mid2ReviewPercentageError.error}
          reason={mid2ReviewPercentageError.reason}
        />
        <AnimatedError
          isVisible={maxReviewPercentageError.error}
          reason={maxReviewPercentageError.reason}
        />
      </div>

      {/* *************** */}
      <div className={classes.divSubmitButton}>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            submitForm();
          }}
        >
          <Translate id="adminstration.submitButton" />
        </Button>
      </div>
    </motion.div>
  );
};

export default withLocalize(SystemConfiguration);
