import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import RouteAboutToStart from '../../../../pages/Guide/Schedules/RouteAboutToStart/RouteAboutToStart';
import { ReactComponent as Time } from '../../../../shared_assets/icons/time.svg';
import { RootState } from '../../../../store';
import { RouteToStartNotification } from '../../../../types/notification.interface';
import { Heading, Text } from '../../../Typography';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { NotificationsProps } from '../Notification';

interface NotificationRouteToStartProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: RouteToStartNotification;
}

const NotificationRouteToStart: React.FC<NotificationRouteToStartProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
}) => {
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const { seen, type, routeTitles, repetitionId } = notification;
  const [isShowingAboutToStart, setIsShowingAboutToStart] = useState(false);
  const routeTitle = useMemo(() => {
    const translation = routeTitles.find(t => t.language === activeLanguage);
    return translation ? translation.title : routeTitles[0].title;
  }, [routeTitles, activeLanguage]);
  return (
    <>
      {!simpleVersion && (
        <div className={classes.rowDirection}>
          <Time className={classes.icon} />
          <Heading level={5} noMargin>
            {translate(`notificationType.${type}`).toString()}
          </Heading>
          {!seen && (
            <Badge
              className={classNames(classes.marginLeft, classes.uppercase)}
              type="primary"
              small
            >
              {translate('notifications.new').toString()}
            </Badge>
          )}
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        {!simpleVersion && <Text noMargin>{routeTitle}</Text>}
        <Text variant={!simpleVersion ? 'faded' : 'default'} noMargin>
          {translate('notifications.routeToStart').toString()}
        </Text>
      </div>
      {Number(notification.repetitionGroupSize) > 0 ? (
        <div className={classes.buttonContainer}>
          <Button
            type="link"
            onlyText
            onClick={() => {
              setIsShowingAboutToStart(true);
            }}
          >
            <Text variant="link" noMargin>
              {translate('button.start').toString()}
            </Text>
          </Button>
          {isShowingAboutToStart && (
            <RouteAboutToStart
              setIsVisible={setIsShowingAboutToStart}
              repetitionId={repetitionId}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default withLocalize(NotificationRouteToStart);
