import { makeStyles } from '@material-ui/styles';
import Radio from 'antd/es/radio';
import React, { useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import validator from 'validator';

import { AnimatedError } from '../../../../components/App/AnimatedError';
import { Button } from '../../../../components/App/Button';
import { TextArea } from '../../../../components/App/Input';
import { Modal } from '../../../../components/App/Modal';
import { Text } from '../../../../components/Typography';
import { ReactComponent as WarningWhite } from '../../../../shared_assets/icons/warning_white.svg';
import { getTheme } from '../../../../utils';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      marginBottom: 0,
    },
  },
  divider: {
    margin: 0,
  },
  group: {
    margin: '1rem 0',
    '& .ant-radio-group': {
      display: 'flex',
      flexDirection: 'column',
      '& > * ': {
        height: 30,
      },
    },
  },
  body: {
    padding: '0.5rem 24px',
    paddingBottom: 24,
  },
  warningContainer: {
    backgroundColor: getTheme().errorBase,
    padding: '0.5rem 24px',
    display: 'flex',
    '& > img, & > svg': {
      height: '1rem',
      width: '1rem',
      marginRight: '0.5rem',
    },
  },
});

interface CancelScheduleModalProps extends LocalizeContextProps {
  visible: boolean;
  hasBookings: boolean;
  onConfirmDelete: (type: CancelType, description: string) => void;
  onClose: () => void;
}

export type CancelType = 'future' | 'current';

function CancelScheduleModal({
  visible,
  onClose,
  hasBookings,
  onConfirmDelete,
  translate,
}: CancelScheduleModalProps) {
  const classes = useStyles();
  const [type, setType] = useState<CancelType>('current');
  const [description, setDescription] = useState<string>('');
  return (
    <Modal
      fullScreenOnMobile={false}
      visible={visible}
      footer={null}
      closable={false}
      disableNaturalPadding
      title={<Translate id="schedules.cancelScheduleQuestion" />}
    >
      {hasBookings && (
        <div className={classes.warningContainer}>
          <WarningWhite />
          <Text size={12} color="white" noMargin inline>
            <Translate id="schedules.cancelWarning" />
          </Text>
        </div>
      )}
      <div className={classes.body}>
        <Text noMargin>
          <Translate id="schedules.cancelConfirmQuestion" />
        </Text>
        <Text variant="note">
          <Translate id="schedules.cancelConfirmText" />
        </Text>
        <div className={classes.group}>
          <Radio.Group
            size="large"
            value={type}
            onChange={newValue => setType(newValue.target.value as CancelType)}
          >
            <Radio value="current">
              <Text inline noMargin>
                <Translate id="schedules.onlyCurrent" />
              </Text>
            </Radio>
            <Radio value="future">
              <Text inline noMargin>
                <Translate id="schedules.cancelFuture" />
              </Text>
            </Radio>
          </Radio.Group>
          <TextArea
            placeholder={translate('schedules.cancelReason').toString()}
            value={description}
            onChange={ev => setDescription(ev.target.value)}
          />
          <AnimatedError
            reason={<Translate id="schedules.cancelReasonMandatory" />}
            isVisible={validator.isEmpty(description)}
          />
        </div>
        <div className={classes.header}>
          <Button type="ghost" onClick={onClose}>
            <Translate id="schedules.stopCanceling" />
          </Button>
          <Button
            type="primary"
            disabled={validator.isEmpty(description)}
            onClick={() => {
              onConfirmDelete(type, description);
            }}
          >
            <Translate id="schedules.confirmCanceling" />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default withLocalize(CancelScheduleModal);
