import Axios from 'axios';
import { API_URL } from '../../consts';
import { ScheduleType } from '../../types/schedule-type.enum';

interface CreateRouteScheduleAdminDto {
  guideId: string;
  routeId: string;
  start: string;
  repetitionType: ScheduleType;
  reserveLimit: number;
  giveAway: boolean;
}

export const createRouteScheduleAdmin = (dto: CreateRouteScheduleAdminDto) =>
  Axios.post(`${API_URL}/schedule/admin`, dto);
