import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Form from './Form/Form';
import List from './List/List';

const ThemeRouter = () => {
  return (
    <Switch>
      <Route path="/admin/area/theme/list" component={List} />
      <Route path="/admin/area/theme/create" component={Form} />
      <Route path="/admin/area/theme/:id" component={Form} exact />
      <Redirect to="/admin/area/theme/list" />
    </Switch>
  );
};

export default ThemeRouter;
