import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  breaker: {
    width: '100%',
    flex: 1,
    display: 'flex',
    height: 1,
    margin: '1rem 0'
  },
  padder: {
    flex: 0.05
  },
  content: {
    flex: 0.9,
    // flex: 1,
    backgroundColor: 'grey',
    borderRadius: 3,
    height: 'inherit'
  }
});

export default function Breaker() {
  const classes = useStyles();

  return (
    <div className={classes.breaker}>
      <div className={classes.padder} />
      <div className={classes.content} />
      <div className={classes.padder} />
    </div>
  );
}
