import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { AnimatedAlert } from '../../../components/Administration/AnimatedAlert';
import { AnimatedError } from '../../../components/App/AnimatedError';
import AnimatedInfo from '../../../components/App/AnimatedInfo/AnimatedInfo';
import { BackButton } from '../../../components/App/BackButton';
import { Button } from '../../../components/App/Button';
import { Error } from '../../../components/App/Error';
import { Input } from '../../../components/App/Input';
import { Loader } from '../../../components/App/Loader';
import { Heading, Text } from '../../../components/Typography';
import { changePassword } from '../../../hooks/authentication/changePassword';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useVisitor } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { Visitor } from '../../../types/visitor.interface';
import {
  desktopPadding,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import { useChangePasswordForm } from './useChangePasswordForm';

const useStyles = makeStyles({
  fill: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem',
  },
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    height: '25%',
  },
  headerDivider: {
    marginTop: 0,
  },
  introIcon: {
    marginRight: '1rem',
  },
  introRow: {
    display: 'flex',
    marginBottom: '1rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noMargin: {
    margin: 0,
  },
  bottomContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    '& > *': {
      width: '100%',
    },
  },
  backZone: {
    flex: 0.2,
    '& > *:nth-child(2)': {
      marginTop: '1rem',
    },
  },
  infoSection: {
    flex: 1,
  },
  title: { marginBottom: '2rem !important' },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    infoSection: {
      flex: 0.8,
    },
    page: {
      flexDirection: 'row',
    },
  },
});

function ChangePassword({ translate }: LocalizeContextProps) {
  const classes = useStyles();
  const [jwt] = UseJWT();
  const history = useHistory();
  const [guide, setGuide] = useState<Visitor | null>(null);
  const [isLoading, hasError] = useVisitor(jwt?.visitorId, setGuide);
  const form = useChangePasswordForm(
    guide?.firstName || '',
    guide?.lastName || '',
    guide?.email || ''
  );
  const [changeError, setChangeError] = useState<{
    message: string;
    description: string;
  } | null>(null);
  const changePasswordCallback = useCallback(async () => {
    if (!jwt) return;

    if (Object.values(form).some(formEntry => formEntry.isInvalid)) {
      Object.values(form).forEach(formEntry => formEntry.set(formEntry.value));
      window.scrollTo(0, 0);
      return;
    }
    window.scrollTo(0, 0);

    try {
      await changePassword(jwt.id, {
        newPassword: form.newPassword.value,
        oldPassword: form.oldPassword.value,
      });
      setChangeError(null);
      message.success(
        translate('profile.passwordChangedSuccessfully').toString()
      );
      history.replace('/guide/profile/personal');
    } catch (err) {
      setChangeError({
        message: translate('profile.changePasswordFailedTitle').toString(),
        description: translate(
          'profile.changePasswordFailedDescription'
        ).toString(),
      });
    }
  }, [form, jwt, translate, setChangeError, history]);

  const isMobile = useIsMobile();

  if (!jwt) {
    return <Redirect to="/visitor" />;
  }

  if (!guide || isLoading) {
    return <Loader />;
  }

  if (hasError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.fill}
      variants={GenericPageTransition}
    >
      {isMobile && (
        <>
          <div className={classNames(classes.body, classes.header)}>
            <ArrowBack onClick={history.goBack} className={classes.backIcon} />
          </div>
          <Divider className={classes.headerDivider} />
        </>
      )}
      <div className={classNames(classes.body, classes.page)}>
        {!isMobile && (
          <div className={classes.backZone}>
            <Button type="primary" onClick={changePasswordCallback}>
              <Translate id="profile.change" />
            </Button>
            <BackButton />
          </div>
        )}
        <div className={classes.infoSection}>
          <AnimatedAlert
            visible={!!changeError}
            message={changeError?.message}
            description={changeError?.description}
            type="error"
            showIcon
          />
          <Heading level={2} className={classes.title}>
            <Translate id="profile.changePassword" />
          </Heading>
          <Text weight="semibold">
            <Translate id="profile.oldPassword" />
          </Text>
          <Input
            huge
            type="password"
            value={form.oldPassword.value}
            onChange={ev => {
              form.oldPassword.set(ev.currentTarget.value);
            }}
          />
          <AnimatedError
            reason={form.oldPassword.reason}
            isVisible={form.oldPassword.isInvalid}
          />
          <Text weight="semibold">
            <Translate id="profile.newPassword" />
          </Text>
          <Input
            type="password"
            huge
            value={form.newPassword.value}
            onChange={ev => {
              form.newPassword.set(ev.currentTarget.value);
            }}
          />
          <AnimatedInfo
            isVisible={
              form.newPassword.isVisible ? form.newPassword.isVisible : false
            }
            infos={form.newPassword.infos}
          />
          <Text weight="semibold">
            <Translate id="profile.confirmNewPassword" />
          </Text>
          <Input
            type="password"
            huge
            value={form.confirmNewPassword.value}
            onChange={ev => {
              form.confirmNewPassword.set(ev.currentTarget.value);
            }}
          />
          <AnimatedError
            reason={form.confirmNewPassword.reason}
            isVisible={form.confirmNewPassword.isInvalid}
          />
          {isMobile && (
            <div className={classes.bottomContainer}>
              <Button
                size="large"
                type="primary"
                onClick={changePasswordCallback}
              >
                <Translate id="profile.change" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default withLocalize(ChangePassword);
