import Axios from 'axios';
import { API_URL } from '../../consts';
import { GenderType } from '../../types/gender-type.enum';
import { RoleType } from '../../types/role-type.enum';
export interface CreateIntermediateDto {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  companyName: string;
  nif: string;
  nationality: string;
  gender: GenderType;
  address: string;
  location: string;
  postalCode: string;
  iban: string;
  email: string;
  password: string;
  type: RoleType;
}

export const createIntermediate = async (dto: CreateIntermediateDto) => {
  try {
    return Axios({
      method: 'post',
      url: `${API_URL}/administrator/create-intermediate`,
      data: dto,
    });
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
    }
    return Promise.reject(err);
  }
};
