import { makeStyles } from '@material-ui/styles';
import Empty from 'antd/es/empty';
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { ReactComponent as DefaultRota } from '../../../shared_assets/icons/pedirota.svg';
import { RouteStateType } from '../../../types/route-state.enum';
import { mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Option, Select } from '../Select';
import GuideRoute, { GuideRouteInterface } from './GuideRoute';

export interface GuideRouteListProps extends LocalizeContextProps {
  data: GuideRouteInterface[];
  onDelete: (deletedId: string) => void;
  onHide: (hiddenId: string) => void;
  onShow: (shownId: string) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '2rem 0 ',
  },
  previewImage: {
    minHeight: '100%',
    minWidth: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  emptyContainer: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  selectContainer: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  routesContainer: {
    '& > div': {
      margin: '0.75rem 0',
      // '&:last-child': {
      //   marginBottom: '0',
      // },
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  emptyImage: {
    width: '50%',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      margin: 'unset',
      marginBottom: '2rem',
      marginLeft: '6rem',
    },
    previewImage: {
      margin: '0 25%',
      marginTop: '2rem',
      borderRadius: '15px',
      width: '50%',
      minWidth: 'unset',
    },
    emptyImage: {
      borderRadius: '15px',
    },
    selectContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      '& > :last-child': {
        marginLeft: '1rem',
      },
      '& .ant-select-selector': {
        width: '20rem !important',
      },
    },
  },
});

function GuideRouteList({
  data,
  translate,
  onDelete,
  onShow,
  onHide,
}: GuideRouteListProps) {
  const classes = useStyles();
  const [filter, setFilter] = useState<string>(
    translate('routeList.all').toString()
  );
  const [filterRoutes, setFilterRoutes] = useState<GuideRouteInterface[]>([]);

  useEffect(() => {
    let routes = [];
    routes = data.filter((preview, i) => {
      return (
        filter === translate('routeList.all').toString() ||
        (filter === translate('routeList.available').toString() &&
          preview.numBookings &&
          preview.numBookings > 0) ||
        preview.status === filter
      );
    });
    setFilterRoutes(routes ? routes : []);
  }, [filter, data, translate]);

  return (
    <div className={classes.container}>
      {data.length > 0 ? (
        <Fragment>
          <div className={classNames(classes.selectContainer)}>
            <Heading level={5}>
              {<Translate id="routeList.seeRoutes" />}
            </Heading>
            <Select
              huge
              value={filter}
              onChange={value => {
                setFilter(value.toString());
              }}
              placeholder={<Translate id="routeList.selectPlaceholder" />}
            >
              <Option
                value={translate('routeList.all').toString()}
                label={translate('routeList.all').toString()}
              >
                <Text className={classes.capitalize} noMargin>
                  {<Translate id="routeList.all" />}
                </Text>
              </Option>
              <Option
                value={translate('routeList.available').toString()}
                label={translate('routeList.available').toString()}
              >
                <Text className={classes.capitalize} noMargin>
                  {<Translate id="routeList.available" />}
                </Text>
              </Option>
              {Object.values(RouteStateType)
                .filter(value => value !== RouteStateType.REMOVED)
                .map((state, i) => (
                  <Option
                    label={translate(
                      `routeState.${state.toString()}`
                    ).toString()}
                    key={i}
                    value={state.toString()}
                  >
                    <Text className={classes.capitalize} noMargin>
                      {translate(`routeState.${state.toString()}`)
                        .toString()
                        .toLowerCase()}
                    </Text>
                  </Option>
                ))}
            </Select>
          </div>
          <div className={classes.routesContainer}>
            {filterRoutes.length > 0 ? (
              filterRoutes.map((preview, i) => (
                <GuideRoute
                  onHide={onHide}
                  onShow={onShow}
                  onDelete={onDelete}
                  key={i.toString()}
                  {...preview}
                />
              ))
            ) : (
              <Empty
                image={<DefaultRota />}
                description={<Translate id="routeList.noRoutesFilter" />}
              />
            )}
          </div>
        </Fragment>
      ) : (
        <div className={classes.emptyContainer}>
          <Heading level={5}>
            {<Translate id="routeList.dontHaveRoutes" />}
          </Heading>
          <img
            className={classes.emptyImage}
            src={require('./assets/no_routes.jpg')}
            alt="place"
          />
        </div>
      )}
    </div>
  );
}

export default withLocalize(GuideRouteList);
