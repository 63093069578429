import React from 'react';

export default function EnglishTOS() {
  return (
    <>
      <h1 id="visitor">A - General Terms and Conditions of Use for Visitors</h1>
      <p>
        Municípia – Empresa de Cartografia e Sistemas de Informação, S.A.,
        headquartered at Taguspark, Edifício Ciência II, 3ºB, 2740-120 Porto
        Salvo and single registration and tax identification number 504475606
        (hereinafter, “Municípia”), implemented at the website
        www.uniquepaths.eu (hereinafter “Site”) a virtual market (hereinafter,
        “MarketPlace”) that allows Visitors of legal age (hereinafter,
        “Visitors”), after registering on the Site, to use this platform to
        contact guides, also registered on the Site (hereinafter, “PediGuides”),
        with the aim of providing them with a Guide service, at the time chosen
        by them, in places and routes (hereinafter, “PediRoutes”) developed by
        PediGuides at a fixed price (hereinafter, “Service”).
      </p>
      <p>
        To use the Service, the Visitor must agree to be bound by these General
        Conditions of Use for Visitors (hereinafter, “Visitor GTCUs”) without
        restrictions or reservations. This acceptance is substantiated by a
        validation click at the time of purchase.
      </p>
      <p>
        Agreements for the service provision entered into via the Service
        between Visitors and PediGuides are governed by the General Terms and
        Conditions of Use of the MarketPlace Service (hereinafter, “GTCUS”),
        which must also be accepted by the Visitor at the time of each booking,
        and complete these Visitor GTCUs. As a MarketPlace supplier, Municípia
        does not control, offer or manage any PediRoutes published by
        PediGuides.
      </p>
      <p>
        Municípia invites you to take knowledge of the GTCUS by clicking on
        <a href="https://uniquepaths.eu/terms-of-service#market">
          https://uniquepaths.eu/terms-of-service#market
        </a>
        .
      </p>
      <h2>Clause 1 – Purpose</h2>
      <p>
        The purpose of these Visitors GTCU is to define the conditions under
        which Municípia makes available to Visitors, within the scope of the
        Service, technological tools used to book the tourist guide services
        associated with PediRoutes and PediGuides.
      </p>
      <h2>Clause 2 – Description of the Service</h2>
      <p>
        The Service is made up of a set of tools that allow Visitors to register
        on the Site, book PediRoutes, pay the price of such PediRoutes, confirm
        the completion of PediRoutes.
      </p>
      <p>
        The Service also includes an assessment system that allows the Visitor
        to assess the quality of their relationship with the PediGuides.
      </p>
      <p>
        Operations carried out using the Service within the scope of the booking
        of PediRoutes are executed directly between the Visitor and PediGuide.
        Municípia is under no circumstances a provider of services associated
        with PediRoutes proposed by PediGuides through the Service.
      </p>
      <p>
        However, with the constant concern to improve the quality of its
        services and to allow a better selection of its partners, Municípia
        invites you to transmit all comments and suggestions that you wish to
        bring to its attention regarding the quality of operations carried out
        through the Service.
      </p>
      <p>
        For this purpose, you can contact us at the following email address:
        info@uniquepaths.eu.
      </p>
      <p>
        In case of breach of these General Terms and Conditions of Use for
        Visitors, Municípia reserves the right to temporarily or permanently
        suspend access to the Service for the Visitor in question.
      </p>
      <p>Each Visitor undertakes to use the Service in good faith.</p>
      <h2>Clause 3ª – Access to the Service</h2>
      <p>
        Access to the Service by Visitors is reserved for strictly personal use.
        The Visitor represents that they act as a consumer (as defined by the
        Consumer Protection Law – Law no. 24/96, of July 31st) in a private
        capacity when using the Service.
      </p>
      <p>
        In addition to accepting these Visitor GTCUs, access to the Service is
        subject to opening of an account on the Site. For this purpose, the
        Visitor must provide the data that allow their identification. When
        opening this account, the Visitor undertakes to provide only accurate
        information, any changes to which must be made in the account. To use
        the Service, the Visitor must use the email address and password created
        when opening their account. The Visitor undertakes to keep them secret
        and not disclose them to any third party. In fact, the Visitor shall be
        solely liable for access to the Service through their email and
        password, unless they prove fraudulent use not attributable to them.
      </p>
      <p>
        In case of loss, diversion or fraudulent use of their email and/or
        password, the Visitor undertakes to immediately notify the Municípia
        customer service.
      </p>
      <h2>Clause 4ª – Price of the Service</h2>
      <p>
        Opening an account and using the Service are free of charge (with the
        exception of any connection costs that depend on your digital
        communications operator), with no obligation to purchase on the Site.
        Only reservation of PediRoutes to PediGuides is charged, under the
        conditions provided for in the TGCUS.
      </p>
      <h2>Clause 5ª – Personal Data</h2>
      <p>
        Information and data relating to the Visitor are processed by Municípia
        and, unless otherwise stated, are critical to allow management of their
        account and their access to the Service.
      </p>
      <p>
        The personal data provided shall only be used for the following
        purposes:
      </p>
      <ol type="a">
        <li>
          Execution and management of a purchase and sale or service provision
          agreement;
        </li>
        <li>Processing bookings;</li>
        <li>Communication with customers and clarification of doubts;</li>
        <li>Processing information requests;</li>
        <li>
          Processing Complaints, Regulator Procedures, Pre-litigation Procedures
          or exercise of rights in legal proceedings;
        </li>
        <li>Statistical analysis activities;</li>
        <li>
          Checking, maintaining and developing systems and statistical analysis;
        </li>
        <li>
          Direct marketing communications (if you have consented to the
          processing of your personal data for this purpose);
        </li>
        <li>Prevention and fight against fraud;</li>
        <li>Request for comments on PediRoutes or services purchased;</li>
        <li>Carrying out satisfaction surveys.</li>
      </ol>
      <p>
        For these data collection and processing purposes, Municípia may, with
        the consent of the relevant data subjects, gather personal data relating
        to identification (name, nationality and email), payment methods (credit
        card, American Express, PayPal or others), geographic location and
        opinions expressed in the assessment of PediRoutes.
      </p>
      <p>
        The information needed to provide the service (PediRoute) shall be
        transmitted to PediGuide for this sole purpose.
      </p>
      <p>
        Pursuant to the legislation in force on the Protection of Personal Data,
        the Visitor may, at any time, exercise their rights of access,
        rectification, deletion, limitation, objection and portability, upon
        request by any of the following means:
      </p>
      <ul>
        <li>
          Post office: Av Dr Cavaco Silva, Edifício Ciência II, 11, 3º B,
          Taguspark, 2740-120 Porto Salvo – Portugal
        </li>
        <li>Email: info@uniquepaths.eu </li>
        <li>
          Telephone: +351214228200 office hours from 9:30 am to 12:30 pm and
          from 2 pm to 6 pm every weekday.
        </li>
      </ul>
      <p>
        The Visitor’s personal data shall only be kept for the period required
        to achieve the purpose that led to its collection.
      </p>
      <p>
        The Site was also designed to be particularly attentive to the needs of
        our customers. This is why we use cookies in particular. Cookies are
        intended to mark your visit to the Site. Cookies are used by Municípia
        only to improve the customised service intended for you. For more
        information about cookies visit http://www.allaboutcookies.org, where
        you can find information on how to manage your settings for the
        different browser providers. You can also refer to chapter E hereof.
      </p>
      <p>
        Notwithstanding the fact that Municípia collects and procedures data
        safely and prevents their loss or manipulation, using the most perfected
        techniques for this purpose, we inform you that collection in an open
        network allows circulation of personal data without security conditions,
        at the risk of being seen and used by unauthorised third parties.
      </p>
      <p>
        Under the legal terms, the data subject has the right to file a
        complaint regarding the protection of personal data with the competent
        supervisory authority, the National Data Protection Commission (CNPD).
      </p>
      <h2>Clause 6ª – Intellectual Property</h2>
      <p>
        All texts, comments, works, illustrations, works and images reproduced
        or represented on the Site are protected under copyright as well as
        intellectual property, for the entire duration of the protection of such
        rights and for the entire world. Accordingly, and pursuant to the
        provisions of the Code of Copyright and Related Rights, only private use
        is authorised, without prejudice to different and possibly more
        stringent provisions of said Code. Any total or partial reproduction,
        representation, change or adaptation of the Site and/or all or part of
        the elements on the Site or incorporated therein is absolutely
        prohibited.
      </p>
      <p>
        Municípia shall not be liable for any breach of Copyright and Related
        Rights related to PediRoutes sold on MarketPlace, under the terms of the
        applicable legislation.
      </p>
      <h2>Clause 7ª – Liability</h2>
      <p>
        The liability of Municípia towards the Visitor can only be assailed for
        facts directly attributable to it that cause damages to the latter
        directly related to those facts. Municípia’s liability cannot be
        assailed for indirect damages. Likewise, Municípia cannot be held liable
        for defective use of the Service by the Visitor or for any error on
        their part, nor for facts attributable to a third party to the Service.
      </p>
      <p>
        Furthermore, pursuant to the provisions of articles 11 and following of
        Decree-Law no. 7/2004, of Jan. 7th, amended by Decree-Law no. 62/2009,
        of Mar. 10th and Law no. 46/ 2012, of Aug. 29th, which transposed
        Directive no. 2000/31/EC of the European Parliament and of the Council,
        of June 8th, 2000, on certain legal aspects of information society
        services, in particular, to the Portuguese legal system. e-commerce, in
        the domestic market, Municípia is exempt from liability for content made
        available on the Site by Visitors or PediGuides, in particular with
        regard to the description of PediRoutes or the assessments that Visitors
        make of PediGuides, except when it does not remove or prevent access to
        such information upon becoming aware of its illegality, pursuant to the
        aforementioned legislation.
      </p>
      <p>
        In this sense, the Visitor shall be fully liable for the agreements
        entered into through the Service, exonerating Municípia and PediGuide
        from any liability in cases where they hire PediRoutes whose features or
        requirements they do not meet, for example if they are younger/older
        than advised by PediGuide in the PediRoute description. Municípia is
        also not liable for minors left alone and/or unaccompanied during the
        performance of PediRoutes.
      </p>
      <p>
        Finally, Municípia declines any responsibility with regard to
        reservations entered into by Visitors through its Service with
        PediGuides, which it is totally alien to. Any complaint regarding the
        comments contained in the description of PediRoutes or the provision of
        the service associated with PediRoutes shall be forwarded to the
        relevant PediGuide, who shall take full responsibility in this regard.
      </p>
      <p>
        The Visitor is solely responsible for their use of the Service, namely
        for the assessments they make on the PediGuides Site, and undertakes to
        guarantee Municípia, on first demand, indemnity and compensation for any
        damage, loss, loss of profits that Municípia may sustain if held liable
        by a third party due to any operation inherent to this use of the
        Service by the Visitor.
      </p>
      <h2>Clause 8ª – Partial nullity</h2>
      <p>
        In the event that one or more provisions of these Visitor GCTUs are
        deemed invalid or declared as such by application of the law or a final
        ruling issued by a competent court, the remaining provisions shall
        remain valid and effective.
      </p>
      <h2>Clause 9ª – Jurisdiction and applicable law</h2>
      <p>
        These Visitor GTCUs, as well as the agreements for the service provision
        entered into under them, are subject to Portuguese legislation. Any
        dispute regarding their interpretation and/or execution is subject to
        the jurisdiction of Portuguese courts.
      </p>
      <h1 id="guide">B - General Terms and Conditions of Use for PediGuides</h1>
      <p>
        Municípia – Empresa de Cartografia e Sistemas de Informação, S.A.,
        headquartered at Taguspark, Edifício Ciência II, 3ºB, 2740-120 Porto
        Salvo and single registration and tax identification number 504 475 606
        (hereinafter “Municípia”), makes available at the website
        www.uniquepaths.eu (hereinafter “Site”), a virtual market (hereinafter
        “MarketPlace”) which allows Guides (hereinafter “PediGuides”), after
        registration, to contact private Visitors (hereinafter “Visitors”), also
        registered on the Site, with the aim of providing them with a Guide
        service in the places and routes developed by the former (hereinafter
        “PediRoutes”) at fixed prices (hereinafter the “Service”). PediGuides
        can either be legal persons (“LP PediGuides”) or natural persons (“NP
        PediGuides”).
      </p>
      <p>
        To use the Service, the PediGuide accepts these PediGuide General Terms
        and Conditions of Use (hereinafter, “PediGuide GTCUs”) without
        restrictions or reservations. This acceptance is substantiated by a
        validation click when registering as a PediGuide.
      </p>
      <p>
        Service agreements entered into via the Service between Visitors and
        PediGuides are governed by the General Terms and Conditions of Use of
        the MarketPlace Service (hereinafter, “GTCUS”), which must also be
        accepted by the PediGuide at the time of registration. The GTCUS
        complete these PediGuide GCTUS. As a MarketPlace supplier, Municípia
        does not control, offer or manage any PediRoutes published by
        PediGuides.
      </p>
      <p>
        Municípia invites you to take knowledge of the GTCUS by clicking on
        <a href="https://uniquepaths.eu/terms-of-service#market">
          https://uniquepaths.eu/terms-of-service#market
        </a>
        .
      </p>
      <p>
        Municípia reserves the right to change these PediGuide GTCUs. Any change
        shall take effect within 15 (fifteen) days of the date of notice thereof
        to the PediGuide. Municípia shall set a longer notice period when
        necessary to allow PediGuides to make technical or commercial
        adaptations to comply with the changes.
      </p>
      <p>
        The PediGuide has the right to terminate the agreement with Municípia
        before the expiry of the notice period. This termination of the
        agreement takes effect within 15 (fifteen) days of receipt of the notice
        of the change of these PediGuide GTCUs.
      </p>
      <p>
        The PediGuide may, by written declaration or express consent, waive the
        aforementioned notice period at any time after receiving notice of the
        change to these PediGuide GTCUs.
      </p>
      <p>
        During the notice period, submission of new PediRoutes on MarketPlace
        shall be deemed an affirmative measure of waiver of the notice period,
        except in cases where said period exceeds 15 (fifteen) days because the
        changes to these PediGuide GTCUs require the PediGuide to make
        significant technical and commercial adaptations of their services. In
        these cases, waiver of the notice period is not deemed automatic if the
        PediGuide submits new PediRoutes.
      </p>
      <p>There shall be no prior notice in the following cases:</p>
      <ol type="a">
        <li>
          The changes are caused by a legal or regulatory obligation that
          prevents Municípia from observing the aforementioned notice period;
        </li>
        <li>
          The changes are critical to deal with an unforeseen and imminent
          danger related to the defence of online intermediation services, their
          consumers or other professional users against fraud, malware,
          unsolicited commercial communications (spam), data breaches or other
          cybersecurity risks.
        </li>
      </ol>
      <p>
        The changes shall not apply to operations in progress at the time of
        their entry into force.
      </p>
      <h2>Clause 1ª – Purpose</h2>
      <p>
        The purpose of these PediGuide GTCUs is to define the conditions under
        which Municípia makes available to PediGuides, within the scope of the
        Service, technological tools that allow them to make PediRoutes
        available to Visitors.
      </p>
      <h2>Clause 2ª – Description of the Service</h2>
      <p>
        The Service consists of a set of tools that allow PediGuides to display
        and create their PediRoutes, indicating their starting and ending point,
        points of interest to visit, activities included, and to reference and
        describe such PediRoutes, receive bookings placed by Visitors, charge
        the price of the PediRoutes booked, manage the PediRoutes post-booking
        service and any contact needs.
      </p>
      <p>
        Operations carried out through the Service within the scope of the
        booking of PediRoutes are executed directly between the Visitor and the
        PediGuide. Municípia is under no circumstances a provider of services
        associated with PediRoutes proposed by PediGuides through the Service.
      </p>
      <p>
        However, with the constant concern to improve the quality of its
        services and to allow a better selection of its partners, Municípia
        invites you to transmit all comments and suggestions that you wish to
        bring to its attention regarding the quality of operations carried out
        through the Service.
      </p>
      <h2>Clause 3ª – Access to the Service</h2>
      <p>
        In addition to accepting these PediGuide GTCUs, access to the Service is
        subject to registration at www.uniquepaths.eu. For this purpose, the
        PediGuide must provide the data that allow identifying them. When
        opening this account, the PediGuide undertakes to provide accurate
        information only and to immediately inform Municípia of any changes to
        such information. Any changes must be made directly by the PediGuide in
        the relevant account.
      </p>
      <p>
        To use the Service, the PediGuide must use their email address and the
        password created when opening their account. The PediGuide undertakes to
        keep them secret and not disclose them to any third party. Indeed, the
        PediGuide shall be solely liable for accessing the Service using their
        email address and password, unless they prove fraudulent use for which
        they are not responsible.
      </p>
      <p>
        In case of loss, diversion or fraudulent use of their email address
        and/or password, the PediGuide undertakes to immediately notify
        Municípia via email at info@uniquepaths.eu.
      </p>
      <h2>
        Clause 4ª – Obligations related to operations carried out through the
        service
      </h2>
      <h3>A - General Obligations</h3>
      <p>
        The PediGuide undertakes to comply with the applicable legislation
        regarding the exercise of a commercial activity (namely registration,
        accounting, contributory and tax obligations) and must identify
        themselves correctly to Visitors.
      </p>
      <p>
        The PediGuide expressly undertakes to identify themselves as acting as a
        service provider, since it provides services (PediRoutes) through the
        Service on a regular basis and for profit.
      </p>
      <p>
        The PediGuide is responsible for understanding and complying with all
        legislation, rules and other regulations that apply to the provision of
        the PediRoutes that they make available on the Site, including
        legislation on the obligation to provide information in Portuguese (see
        excerpts of the legislation in the Annex), obtaining the necessary
        licenses, authorisations or registrations before making their PediRoute
        available and ensuring that they do not breach any agreement they may
        have with third parties.
      </p>
      <p>
        The PediGuide expressly undertakes not to promote their products or
        services directly or indirectly, in any way (namely by inserting
        hypertext links, any message in the description of PediRoutes, or by
        using the URL address of its website in their username or on the
        PediGuide page), as well as the products or services of any third party.
      </p>
      <p>
        The PediGuide undertakes to make every effort to fulfil their
        obligations in the best way possible by providing a quality service to
        Visitors.
      </p>
      <p>
        To this end, the PediGuide undertakes in particular to reply to email
        messages from the Customer Service and from Municípia within 1 (one)
        business of receipt.
      </p>
      <p>
        The PediGuide is responsible for their own acts and omissions and for
        the acts and omissions of anyone who they allows to participate in the
        provision of services.
      </p>
      <h3>
        B - Natural Person PediGuides (“NP PediGuides”) and Legal Person
        PediGuides (“LP PediGuides”)
      </h3>
      <p>
        For an LP PediGuide to register with MarketPlace, they must indicate,
        from the moment of registration, at least one NP PediGuide that shall be
        associated with them (and perform the Service on behalf of the LP
        PediGuide).
      </p>
      <p>
        The association between an LP PediGuide and an NP PediGuide shall be
        included in the private profile of the PediGuides (both the LP PediGuide
        and the NP PediGuide).
      </p>
      <p>
        The association between an LP PediGuide and an NP PediGuide shall take
        place upon request of either of them, carried out with the MarketPlace
        platform, and acceptance by the other.
      </p>
      <p>
        Both the LP PediGuide and the NP PediGuide may terminate the existing
        association between them at any time, with no need to invoke a reason
        and no need for acceptance by the other, simply by making such a request
        by email to the MarketPlace platform.
      </p>
      <p>
        In the event that an association between an LP PediGuide and an NP
        PediGuide ends, if there is a Service already hired to be performed by
        the NP PediGuide, but not yet provided, the booking made by the Visitor
        must be fulfilled by the NP PediGuide.
      </p>
      <h3>C - Obligations regarding the offer of PediRoutes on the Site</h3>
      <p>
        The PediGuide is solely liable for making available the PediRoutes
        offered in the Service.
      </p>
      <p>
        Regarding the description associated with the PediRoute offers that they
        propose on the Site, the PediGuide undertakes to act in good faith. The
        PediGuide is solely liable for the accuracy of the indications contained
        in the aforementioned description and undertakes to ensure that they do
        not create a risk of misleading potential Visitors, with regard to the
        features of the service. The PediGuide conveys to Visitors all
        information that allows them to learn about the essential
        characteristics of the PediRoute.
      </p>
      <p>
        The PediGuide is liable for establishing rules and requirements for
        their PediRoute. When publishing a PediRoute, the PediGuide must, where
        applicable, fully instruct and inform Visitors about:
      </p>
      <dl>
        <dd>Any inherent or possible risks related to the PediRoute;</dd>
        <dd>
          Any requirements for participation, such as minimum age, related
          skills, physical condition; and
        </dd>
        <dd>
          Any other information that may be required to safely take part in the
          PediRoute.
        </dd>
      </dl>
      <p>
        Municípia reserves the right to decide, at its sole discretion, whether
        a PediRoute shall be withdrawn from MarketPlace.
      </p>
      <p>
        PediGuides must honour their bookings, except in cases where they have
        to be cancelled for reasons of force majeure, safety concerns or
        dangerous weather conditions, and these reasons must be duly justified
        when the booking is cancelled by the PediGuide.
      </p>
      <p>
        As a general rule, the PediGuide shall only cancel bookings for the
        abovementioned reasons 2 (two) times each calendar year, in which case
        their user account shall be automatically suspended. Cancellation and
        presentation of its justification is done by sending an email to
        info@uniquepaths.eu.
      </p>
      <p>
        In view of the reasons invoked by PediGuide, following this suspension,
        Municípia reserves the right to decide, at its sole discretion, whether
        the user account can be reactivated or shall remain suspended
        temporarily or permanently, in which case these conditions shall be
        suspended or terminated without prior notice and without compensation.
      </p>
      <h3>D - Obligations relating to the prices of PediRoutes</h3>
      <p>
        The price for the PediRoutes service is defined by an algorithm, for
        example as a function of distance or the assessment of the PediGuide.
      </p>
      <p>
        This price must be indicated on the Site with all taxes, fees and
        expenses included (namely VAT, among other taxes or charges), and the
        PediGuide may not charge any additional fees or charges outside the
        MarketPlace.
      </p>
      <h3>E - Obligations relating to the service provision</h3>
      <p>
        Service agreements associated with PediRoutes proposed by PediGuide on
        the Site are entered into between PediGuide and the Visitor under the
        condition that PediRoute is available. The PediGuide undertakes to
        propose only available PediRoutes on the Site and immediately remove any
        proposal related to PediRoutes that are no longer available.
      </p>
      <p>
        The PediGuide is informed by email, in their account and/or by text
        message, by Municípia, when PediRoute they have placed online is booked
        by a Visitor.
      </p>
      <p>
        With the frequency adequate to the number of PediRoutes available at the
        MarketPlace, the PediGuide must review the PediRoutes they have
        published, keeping them properly updated (availability, possible times,
        among others).
      </p>
      <p>
        Similarly, the PediGuide undertakes to regularly consult with adequate
        frequency the number of PediRoutes made available at the MarketPlace,
        their text or email messages received at the address and telephone
        number provided to Municípia at the time of opening their account and,
        subsequently, if this information changes, to take knowledge of the list
        of PediRoutes booked by Visitors.
      </p>
      <p>
        In case of predicted absence or unavailability to perform PediRoutes,
        the PediGuide must remove the schedules defined for their PediRoutes. In
        appointments where bookings already exist, this is not applicable, and
        the service must be provided under the terms defined.
      </p>
      <ol type="i">
        <li>
          The PediGuide must indicate in the description associated with each
          PediRoute that they propose on the Site the modalities of service
          provision (days, times, meeting points, activities to be performed,
          end point and difficulty);
        </li>
        <li>
          Municípia transmits to PediGuide, at the latest within 30 minutes
          after the Visitor’s booking, the information relating to the Visitor
          that allows them to proceed with the provision of the service booked;
        </li>
        <li>
          The PediGuide undertakes to provide any service booked within the
          period defined by them.
        </li>
      </ol>
      <h3>F - Obligations regarding claims related to PediRoutes</h3>
      <p>
        In general, the PediGuide (both NP PediGuide and LP PediGuide)
        undertakes, in good faith, to make every effort to fulfil their
        obligations, providing a quality service to Visitors.
      </p>
      <p>
        Accordingly, any Visitor has the possibility to submit in their account,
        within 7 (seven) days of the service provision, a complaint regarding
        the PediRoutes booked, applying the following criteria:
      </p>
      <ul>
        <li>
          Service not provided: the PediRoute was not performed by the
          PediGuide;
        </li>
        <li>
          Non-conforming service: The PediRoute provided does not correspond to
          the service booked.
        </li>
      </ul>
      <p>
        In case complaint by a Visitor regarding a PediRoute, including when the
        PediGuide is informed of such complaint through an email message from
        Municípia, the PediGuide takes responsibility for settling the dispute
        with the Visitor. Disputes are directly settled between the Visitor and
        the PediGuide (both NP PediGuides and, in case of association, LP
        PediGuides). The PediGuide shall make every effort to settle the dispute
        with the Visitor amicably.
      </p>
      <p>
        However, in the event that PediGuide has not settled the dispute with
        the Visitor within 8 (eight) business days of receipt of the complaint,
        Municípia reserves the right, after notice by the PediGuide to that
        effect, to refund the booking to the Visitor. The same amount shall
        subsequently be deducted from the amounts payable by Municípia to the
        PediGuide for PediRoutes sold by the PediGuide through the Service.
      </p>
      <p>
        If the Visitor is right, the PediGuide shall reimburse the Visitor at no
        additional cost.
      </p>
      <p>
        In the event of regular exercise of the right of free termination by the
        Visitor, Municípia, in its capacity as a suitable third party, shall
        reimburse the Visitor for the amount of the service booked by the
        Visitor, crediting their bank card for this purpose. This amount shall
        be subsequently deducted from the amounts payable by Municípia to the
        PediGuide related to PediRoute bookings. However, if the PediGuide
        account is indebted, the refund of the booking must be made directly by
        the PediGuide to the Visitor.
      </p>
      <h3>G - Disclaimer</h3>
      <p>
        The PediGuide, whether an NP PediGuide or an LP PediGuide, exonerates
        Municípia of any liability, complaint, denunciation, etc. that may be
        directed against Municípia arising from the announcement and/or offer of
        PediRoutes by the PediGuide. As a result, Municípia shall not be
        responsible nor liable, for whatever reason, for the PediRoutes, prices,
        performance, etc. of the PediGuide. If a penalty is applied to Municípia
        for these reasons, the PediGuide undertakes to bear it and reimburse
        Municípia for all amounts spent by it for this matter.
      </p>
      <h2 id="guide-financial">Clause 5ª – Financial provisions</h2>
      <p>
        If the Service is hired by the Visitor to an NP PediGuide not associated
        with an LP PediGuide, the MarketPlace platform shall automatically
        invoice the PediRoute on behalf of the NP PediGuide or the entity
        indicated by it, making this invoice available to the Visitor.
      </p>
      <p>
        If the Service is hired by the Visitor to an NP PediGuide associated
        with an LP PediGuide, or if the Service is hired directly to an LP
        PediGuide, the MarketPlace platform shall invoice the PediRoute on
        behalf of the LP PediGuide, making this invoice available to the
        Visitor.
      </p>
      <p>
        The PediGuide shall be liable for identifying themselves in the
        application made available by uniquePATHS, for the purpose of exclusive
        invoicing, with Name, Tax Identification Number, Tax Address and IBAN,
        whether a natural or a legal person.
      </p>
      <p>
        For each PediRoute booking received by a Visitor, Municípia shall charge
        the PediGuide a fee equivalent to 30% of the corresponding amount plus
        VAT.
      </p>
      <p>
        Municípia shall pay the PediGuide the total amount of the booking price,
        deducting the fee, after confirmation by the Visitor of the provision of
        the service or after the deadlines for submitting or settling complaints
        pursuant to the preceding clause have elapsed.
      </p>
      <p>
        Payment shall be made by bank transfer to the bank account whose details
        have been advised by PediGuide, after said confirmation, and the
        services performed in the reference month shall be paid by the 10th of
        the month following the service provision, except in cases where
        complaints are pending.
      </p>
      <p>
        In case of association between an NP PediGuide and an LP PediGuide,
        payment shall be made by Municípia to the LP PediGuide, even if the NP
        PediGuide has provided their own IBAN when identifying themselves in the
        application.
      </p>
      <p>
        In the absence of confirmation of the service provision by the Visitor
        and in the absence of a complaint by the Visitor brought to the
        attention of PediGuide, the service is presumed to have been provided
        and the transfer takes place within the period defined in the preceding
        paragraph. In the event of a complaint by the Visitor, once the
        aforementioned period of 21 (twenty-one) days has elapsed and the
        transfer is made to PediGuide, Municípia, after assessing the Visitor’s
        complaint and if it is justified, may reimburse the Visitor with the
        value of the PediRoute booked. In this case, the amount refunded by
        Municípia to the Visitor shall be debited to PediGuide, which must pay
        it, by offsetting the current account between the PediGuide and
        Municípia or through any other means agreed between Municípia and the
        PediGuide.
      </p>
      <h2>Clause 6ª – Assessment of PediGuides</h2>
      <p>
        Municípia makes available to Visitors the means that allow them to
        assess the performance of PediGuides upon confirmation of the provision
        of the PediRoute booked, thus allowing Visitors to select the PediRoutes
        of the PediGuides that best meet the conditions of use of the Service.
      </p>
      <p>The assessment is carried out by applying the following criteria:</p>
      <ul>
        <li>Global assessment by Visitors, by assigning stars from 1 to 5</li>
      </ul>
      <p>
        Municípia reserves the right to suspend or terminate access to the
        Service by the PediGuide pursuant to Article 7 of these PediGuide GTCUs.
      </p>
      <h3>A - Internal procedure for handling complaints</h3>
      <p>
        Municípia has implemented an internal procedure for handling complaints
        that it makes available to PediGuide through the email address:
        info@uniquepaths.eu. The PediGuide may submit their complaints to
        Municípia, namely in the event of alleged breach by Municípia of any of
        the obligations established in Regulation (EU) 2019/1150 of the European
        Parliament and of the Council of June 20th, 2019 regarding the promotion
        of fairness and transparency for professional users of online
        intermediation services, this regulation, which directly affects them,
        in relation to technological issues directly related to the provision of
        services of access to MarketPlace, or in relation to measures taken by
        Municípia, or its practices, directly linked to the provision of
        MarketPlace access services.
      </p>
      <p>
        Municípia, an entity certified by the ISO 9001 standard, with a
        complaints handling system, undertakes to properly review the complaints
        submitted and ensure their follow-up, to deal with the issues submitted
        appropriately, to deal with the complaints quickly and effectively,
        taking into account the importance and complexity of the issues raised,
        and reporting the results of the internal procedure for handling
        complaints to the PediGuide complainant, in a customised manner.
      </p>
      <h2>
        Clause 7ª – Restriction, Suspension or Termination of the relationship
        with PediGuides
      </h2>
      <p>
        These PediGuide GTCUs are valid for an indefinite period. Each party
        may, therefore, terminate this agreement at any time, without
        justification, with a notice period of 2 (two) months.
      </p>
      <p>
        If the PediGuide does not comply with any of their obligations, or any
        of the assessment criteria below, Municípia may restrict, suspend or
        terminate the Service provision under the following terms:
      </p>
      <p>
        If Municípia decides to restrict or suspend access to the MarketPlace to
        a particular PediGuide, it shall notify the latter of the grounds for
        its decision on the date on which the restriction or suspension shall
        take effect.
      </p>
      <p>
        Municípia may suspend access to the MarketPlace, namely and without
        limitation, if the average score of the global assessment by the
        Visitors is lower than 4.0, within a scale from 0 to 5.
      </p>
      <p>
        If Municípia decides to cease providing its MarketPlace access services
        to a specific PediGuide, it shall notify the latter by email, notice in
        their account and/or text message, of the reasons for its decision, with
        30 (thirty) days’ notice from the date on which the termination shall
        take effect.
      </p>
      <p>
        Municípia may cease providing its MarketPlace access services, with the
        consequent deactivation of the PediGuide account, namely and without
        limitation, if the average score of the global assessment by the
        Visitors is less than 4.0 or due to cancellation of bookings as
        described in Clause 4.
      </p>
      <p>
        In case of restriction, suspension or termination of access to the
        MarketPlace for an NP PediGuide associated with an LP PediGuide, the LP
        PediGuide shall be informed by Municípia of this fact. If the
        restriction, suspension or termination of access to the MarketPlace is
        for an LP PediGuide, the NP PediGuides associated with them shall also
        be informed by Municípia.
      </p>
      <p>
        In case of restriction, suspension or termination, the PediGuide has the
        right to clarify the facts and circumstances that motivated the decision
        of Municípia within the scope of the internal procedure for handling
        complaints mentioned in the preceding clause.
      </p>
      <p>
        In case of serious and/or repeated breach by the PediGuide of their
        obligations, namely and without limitation: fraud, offensive comments,
        counterfeiting, breach of third-party rights, Municípia may terminate
        the Service without prior notice and without the PediGuide being
        entitled to any compensation/indemnity, and may also retain the current
        account balance to face claims that may arise in the future. In this
        case, PediGuide shall be notified by Municípia of the grounds for its
        decision to terminate the Service by registered mail with acknowledgment
        of receipt.
      </p>
      <p>
        If the breach by the PediGuide constitutes an illegal activity,
        Municípia shall immediately inform the competent authorities.
      </p>
      <h2>Clause 8ª - Personal Data</h2>
      <p>
        Information and personal data relating to the PediGuide or any natural
        person representing PediGuides are processed by Municípia; unless
        otherwise stated, they are critical to ensure the management of the
        PediGuide account and their access to the Service. This information and
        personal data are also kept for security purposes, to ensure compliance
        with the legal obligations incumbent, if applicable, on Municípia, and
        to allow Municípia to improve and customise the services offered to
        users of the Site, and the information sent to the PediGuide. In this
        context, the PediGuide may receive information regarding the Service by
        email from Municípia.
      </p>
      <p>
        For these data collection and processing purposes, Municípia may, with
        the consent of the relevant data subjects, gather personal data from
        them relating to identification (name, tax identification number,
        nationality and email), comfort languages, means of receiving payment
        (IBAN, Name, TIN and company address, if applicable), geographic
        location and skills as a guide (history, biology, geography,
        “storyteller”, etc.).
      </p>
      <p>
        Pursuant to the legislation in force on the Protection of Personal Data,
        the PediGuide may at any time exercise their rights of access,
        rectification, deletion, limitation, objection and portability, and to
        this end, send an email to privacidade@municípia.pt with their surname,
        name, email address.
      </p>
      <p>
        The PediGuide’s personal data shall only be kept for the period required
        to achieve the purpose for which it was collected.
      </p>
      <p>
        The PediGuide, as a personal data subject, has the right to file a
        complaint regarding the protection of personal data with the competent
        supervisory authority, the National Data Protection Commission (CNPD).
      </p>
      <p>
        Municípia uses cookies to mark the passage of the PediGuide on the Site.
        Cookies are only used by Municípia with the aim of improving the
        customised service intended for them. For more information about cookies
        see Chapter E of this document.
      </p>
      <p>
        With regard to the data relating to the PediRoute bookings of the
        PediGuide collected within the scope of the Service, Municípia shall
        inform the Visitors that these are intended for Municípia and that the
        information needed for delivery of PediRoutes shall be reported, solely
        for this purpose, to the PediGuide.
      </p>
      <p>
        The PediGuide irrevocably undertakes to use the Visitor’s personal data
        that shall be reported to them by Municípia only for the purpose of
        providing the service and to allow them to comply with the emerging
        legal obligations. The PediGuide undertakes not to keep any copy of said
        data once they have provided the service(s) booked. Any other use,
        rental, sale, resale or copy by the PediGuide is prohibited. In this
        context, the PediGuide may receive information regarding the Service by
        email from Municípia. The PediGuide undertakes to comply with the
        applicable legal and regulatory norms regarding the protection of
        personal data when processing the personal data that shall be reported
        to them by Municípia.
      </p>
      <p>
        The PediGuide undertakes to apply at least the following technical,
        organisational and security measures:
      </p>
      <ul>
        <li>
          Protect their computers, mobile phones and devices with:
          <ul>
            <li>
              Secure passwords, with enough characters and composed of upper-
              and lower-case letters, digits and special characters, which they
              must change at least quarterly;
            </li>
            <li>Updated operating systems, applications and antivirus;</li>
            <li>
              Protect access to email with secure passwords, which they must
              change at least quarterly;
            </li>
            <li>
              Encrypt the external storage devices they use (pen drives, hard
              drives, etc.);
            </li>
            <li>Carry out any document destruction securely.</li>
          </ul>
        </li>
      </ul>
      <h2>Clause 9ª – Intellectual Property</h2>
      <p>
        All texts, comments, works, illustrations, works and images reproduced
        or represented on the Site are protected under copyright and
        intellectual property, for the entire duration of the protection of such
        rights and for the entire world. Accordingly, and pursuant to the
        provisions of the Code of Copyright and Related Rights, only private use
        is authorised, without prejudice to different and possibly more
        stringent provisions contained in said Code. Any total or partial
        reproduction or representation of the Site and/or all or part of the
        elements on the Site is absolutely prohibited.
      </p>
      <p>
        Municípia shall not be liable for any breach of Copyright and Related
        Rights of PediRoutes sold on the MarketPlace, under the terms of the
        applicable legislation.
      </p>
      <h2>Clause 10ª – Liability</h2>
      <p>
        The PediGuide guarantees and shall indemnify Municípia on first demand
        for any damage sustained by Municípia and from any liability action that
        may be brought against Municípia for breach by the PediGuide of any
        third-party right, including by a Visitor, when the loss arises from the
        provision of the service associated with PediRoutes, the use made by the
        PediGuide of the Service or any other fact attributable to them.
      </p>
      <p>
        The liability of Municípia to the PediGuide can only be assailed for
        facts directly attributable to it and is, in any circumstance, limited
        to the amount of the bookings made during the previous month. Municípia
        cannot be held liable for indirect damages, namely for profits lost
        resulting from sale of PediRoutes in case of unavailability of the
        Service. The liability of Municípia cannot be assailed in case of misuse
        of the Service by PediGuide.
      </p>
      <p>
        Municípia declines any responsibility for agreements entered into
        between PediGuides and Visitors through its Service. Any complaint
        regarding PediRoutes (description, delivery, conditions of use, etc.)
        brought to the attention of Municípia shall be transmitted to the
        relevant PediGuide, who shall take full responsibility. Municípia is
        also not liable for minors left alone and/or unaccompanied during the
        performance of PediRoutes.
      </p>
      <p>
        When taking responsibility for an NP PediGuide associated with an LP
        PediGuide, both the NP PediGuide and the LP PediGuide shall take
        responsibility to Municípia.
      </p>
      <h2>Clause 11ª – Partial nullity</h2>
      <p>
        In the event that one or more provisions of these PediGuide GTCUs are
        deemed invalid or declared as such by application of the law or a final
        ruling issued by a competent court, the remaining provisions shall
        remain valid and effective.
      </p>
      <h2>Clause 12ª – Jurisdiction and applicable law</h2>
      <p>These PediGuide GTCUs are subject to Portuguese legislation.</p>
      <p>
        In case of dispute between Municípia and the PediGuide arising from the
        Service provision, including any complaints that cannot be settled
        through the internal procedure for handling complaints mentioned in
        Clause 6 above, either party may appeal to mediation with a view to an
        out-of-court settlement of the dispute.
      </p>
      <p>
        Recourse to mediation is optional in nature and no attempt to reach an
        agreement through mediation for the purpose of settling disputes,
        pursuant to this article, shall affect the rights of Municípia and
        PediGuide to bring a lawsuit at any time before, during or after the
        mediation procedure.
      </p>
      <p>
        Any dispute regarding its interpretation and/or its execution is subject
        to the jurisdiction of Portuguese courts. In the event of a dispute
        between Municípia and a PediGuide, the courts of the District of Western
        Lisbon shall have jurisdiction, notwithstanding the possible existence
        of several defendants or the intervention of third parties, including in
        the context of injunctions.
      </p>
      <h1 id="market">
        C - General Terms and Conditions of Use of the MarketPlace Service
      </h1>
      <p>
        These General Terms and Conditions of Use of the MarketPlace Service
        (hereinafter the “GTCUS”) apply in addition, with respect to Visitors,
        to the Visitor GTCUs and, with regard to PediGuides, both Natural Person
        PediGuides (“PediGuides” PS”) and Legal Person PediGuides (“LP
        PediGuides”), to the PediGuide GTCUs, in which the capitalised terms
        used in these GTCG are defined.
      </p>
      <p>
        The GTCUS apply to all PediRoute bookings made through the Service
        between PediGuide and the Visitor. The GTC governs the relationship
        between PediGuides and Visitors, with the exception of the relationship
        between Visitors and Municípia or between PediGuides and Municípia.
        Relations between Visitors and Municípia are governed by the Visitor
        GTCUs. The relations between PediGuides and Municípia are governed by
        the PediGuide GTCUs.
      </p>
      <p>
        Municípia is not the service provider of PediRoutes booked through the
        Service provided by the platform; only the PediGuide, whose name is in
        the description of each PediRoute, is the Visitor’s counterparty for the
        purchase of said PediRoutes.
      </p>
      <h2 id="cancel-policy">
        Clause 1ª – Entering into the service agreement between the PediGuide
        and the Visitor
      </h2>
      <p>These GTCUS are subject to Portuguese legislation.</p>
      <ol>
        <li>
          PediRoutes are offered on the Site with a description that allows the
          Visitor to know their essential features and price.
        </li>
        <li>The Visitor selects the PediRoute(s) that they wish to book.</li>
        <li>
          The Visitor confirms their choice of PediRoute(s) and acknowledges and
          accepts these GTCUS by one validation click.
        </li>
        <li>The Visitor pays for the PediRoute(s).</li>
        <li>
          The Visitor receives an email message confirming acceptance of their
          booking
        </li>
        <li>
          The PediGuide is informed by Municípia that one or more PediRoutes
          that they have placed online have been booked, thus the PediGuide
          takes the definitive commitment to provide the service within the
          stipulated period, without prejudice to any applicable legal
          exceptions.
        </li>
        <li>
          Booking cancellation:
          <ol type="1">
            <li>
              In the event of cancellation of the booking by the Visitor under
              the terms and conditions of free termination under clause 3 below,
              the agreement between the Visitor and the PediGuide is terminated
              and each party is relieved of their obligations. If the booking is
              cancelled after the deadline mentioned in clause 3 below, there
              shall be no refund of the price paid.
            </li>
            <li>
              In case of cancellation of the booking by the PediGuide, before
              the date and time defined for completing the PediRoute, the
              agreement between the Visitor and the PediGuide is terminated, and
              the price of the booking is automatically returned to the Visitor,
              the PediGuide being liable for the other consequences of early
              termination of the agreement.
            </li>
          </ol>
        </li>
        <li>
          The Visitor must confirm:
          <ul>
            <li>The provision of the service(s);</li>
            <li>
              Compliance thereof (compliance means that the PediRoute
              corresponds to what was booked);
            </li>
            <li>
              Conditions for the service provision (corresponding to the
              description on the Site at the time of booking). In the absence of
              confirmation, the PediRoute is presumed to have been provided
              within 7 (seven) days of the date of the relevant service
              provision.
            </li>
          </ul>
        </li>
        <li>
          The Visitor is invited to assess the performance of the PediGuide
          pursuant to clause 5.
        </li>
      </ol>
      <h2>Clause 2ª – Price and payment</h2>
      <p>
        The PediRoute booking value is determined by Municípia, with the price
        indicated in euros with all taxes and fees included in the description
        form.
      </p>
      <p>
        Payment for purchases made through the Service is to Municípia, which
        receives the corresponding amount, in the name and on behalf of the
        PediGuide.
      </p>
      <h2 id="refund-policy">Clause 3ª – Right of free termination</h2>
      <p>
        Pursuant to current legislation, within the scope of a booking made for
        a PediGuide, the Visitor has a period of 14 (fourteen) days from
        entering into the agreement (booking) to exercise, with said PediGuide,
        their right to free termination without payment of damages and no need
        to state the reason.
      </p>
      <p>
        If, meanwhile, the service(s) have been fully provided, with the express
        consent of the Visitor, registered at the time of the PediRoute, the
        Visitor cannot freely terminate the agreement(s).
      </p>
      <p>
        In case of exercise of the right of free termination within the
        aforementioned period, only the price of the PediRoute(s) not carried
        out shall be refunded.
      </p>
      <p>
        The Visitor exercises their right of free termination directly with the
        PediGuide in question through the tool made available within the scope
        of the Service.
      </p>
      <p>
        The reimbursement of PediRoutes is made by Municípia to the Visitor
        within the shortest possible period and within 30 (thirty) days of the
        date of exercise of the right of free termination.
      </p>
      <h2>Clause 4ª – Litigation and complaints</h2>
      <p>
        The Visitor has the possibility to submit, within 7 (seven) days of the
        service provision, any claim related to the PediRoutes booked, by
        applying the following criteria:
      </p>
      <ul>
        <li>
          Service not provided: the service was not provided to the Visitor.
        </li>
        <li>
          Non-conforming service: the service provided did not correspond to the
          one booked.
        </li>
      </ul>
      <p>
        Disputes are settled directly between the Visitor and the PediGuide
        (whether an LP PediGuide or an NP PediGuide).
      </p>
      <p>
        The Visitor and the PediGuide shall use their best efforts to settle the
        dispute amicably.
      </p>
      <p>
        Depending on the case, the dispute may give rise to the provision of the
        service booked or reimbursement.
      </p>
      <h2>Clause 5ª – PediGuide Assessment</h2>
      <p>
        Municípia makes available to Visitors means that allow them to assess
        the performance the PediGuides upon confirmation of the completion of
        the PediRoutes booked, thus allowing Visitors to select the PediRoutes
        by the PediGuides that best meet the conditions of use of the Service.
      </p>
      <p>
        The assessment is carried out by the Visitors by applying assessment
        criteria and assigning star(s). To this extent, Municípia does not carry
        out any inspection of the assessment made by Visitors, merely storing it
        on the Site. Municípia may, however, decide to remove, without prior
        notice, any assessment whose content has been flagged to it as being of
        an unlawful nature. The assessments by the Visitor as well as their
        pseudonym are visible to any visitor to the Site.
      </p>
      <p>
        Municípia reserves the right to temporarily or definitively interrupt
        access to the Service for PediGuides whose assessment is less than 4
        (four), suspending or terminating the Service as provided for in the
        PediGuide GTCUs.
      </p>
      <h2>Clause 6ª - Personal Data</h2>
      <p>
        In addition to the provisions of Clause 5 of the Visitor GTCUs and the
        provisions of Clause 8 of the PediGuide GTCUs, the Visitor and the
        PediGuide are informed that the data collected within the scope of the
        Service when placing the bookings are processed by Municípia for
        purposes of processing said bookings.
      </p>
      <p>
        The information and data of Visitors regarding the delivery are reported
        by Municípia to PediGuides with the sole purpose of allowing them to
        provide the PediRoute(s) booked. Under no circumstances may they be used
        for other purposes.
      </p>
      <p>
        Each PediGuide only has access to the personal data provided by Visitors
        who booked their PediRoutes with Municípia at the time of booking.
      </p>
      <p>
        The PediGuides undertake to ensure the security of the personal data
        that they keep for the purpose of processing and following up on
        bookings.
      </p>
      <p>
        In the event of a problem arising from the processing of these data, the
        Visitor and PediGuide may go directly to Municípia under the conditions
        defined, as the case may be, in the Visitor GTCUs or the PediGuide
        GTCUs.
      </p>
      <h2>Clause 7ª – Security</h2>
      <p>
        The Site is subject to a security system: Municípia has adopted the SSL
        encryption process but also reinforced the set of interference and
        encryption procedures to more effectively protect all sensitive data
        relating to the payment methods used on the Site.
      </p>
      <h2>Clause 8ª – Partial nullity</h2>
      <p>
        In the event that one or more provisions of these GTC are deemed invalid
        or declared as such by application of the law or a final ruling issued
        by a competent court, the remaining provisions shall remain valid and
        effective.
      </p>
      <h2>Clause 9ª – Jurisdiction and applicable law</h2>
      <p>
        These general conditions are subject to Portuguese law. Any dispute
        relating to their interpretation and/or execution is a matter for
        Portuguese courts.
      </p>
      <h1 id="legal">D – Relevant legal provisions</h1>
      <p>
        <b>Decree-Law no. 7/2004, of January 7th</b>
      </p>
      <p>
        Decree-Law no. 7/2004, of January 7th (hereinafter “DL no. 7/2004”), is
        intended to transpose Directive no. 2000/31/EC, of ​​the European
        Parliament and of the Council, of June 8th, 2000, governing electronic
        commerce, leaving only some matters open, such as: tax matters,
        competition discipline, the regime for processing personal data and the
        protection of privacy, legal sponsorship, games of fortune and notarial
        or equivalent activity.
      </p>
      <p>
        Pursuant to article 13 of Decree-Law no. 7/2004, the intermediary
        service provider has the obligation to inform the competent authorities
        immediately upon becoming aware of illegal activities that develop
        through the services they provide; satisfy requests for identification
        of service recipients with whom they have storage agreements; promptly
        comply with orders aimed at preventing or ending an infringement, namely
        as far as removing or preventing access to information; and providing
        lists of owners of sites they host, when requested.
      </p>
      <p>
        According to article 14 of this Decree-Law no. 7/2004, if the
        intermediary service provider is only in charge of the transmission of
        information on the network, or of providing access to a communications
        network, having no intervention in the content of the messages
        transmitted, they are exempt from all liability for the information
        transmitted.
      </p>
      <p>
        However, under the terms of article 15, the common liability regime
        shall apply if the intermediary service provider does not act according
        to the usual rules of the industry, such as: updating information and
        the use of technology, using it to obtain data on the use of
        information; or even, in cases where the provider learns that the
        information has been removed from the original source or access has been
        prevented or a court or administrative entity with jurisdiction over the
        provider who is the source of the information ordered such removal or
        prevention of access with immediate feasibility and the provider does
        not immediately withdraw or prevent access.
      </p>
      <p>
        Nevertheless, in cases of main storage, article 16 determines that the
        intermediary provider is only liable, under common terms, for the
        information it stores if it has knowledge of activity or information
        whose unlawfulness is manifest and fails to immediately remove or
        prevent access to that information. The rules of common liability also
        apply where the recipient of the service acts subordinate to the
        provider or is controlled by them.
      </p>
      <p>
        Decree-Law no. 7/2004 intends to determine the liability scheme of
        intermediary service providers. More precisely, it aims to establish the
        conditions of exemption of liability of these providers in view of the
        possible illegality of the messages they make available.
      </p>
      <p>
        <b>
          Article 1 of Decree-Law no. 238/86, of August 19th, amended by
          Decree-Law no. 42/88, of February 6th
        </b>
      </p>
      <p>
        Information on the nature, features and guarantees of goods or services
        offered to the public in the national market, whether contained in
        labels, packaging, prospectuses, catalogues, instruction books for use
        or other informational means, whether provided at sales points or
        published by any advertising medium, must be provided in Portuguese.
      </p>
      <p>
        <b>
          Article 2 of Decree-Law no. 238/86, of August 19th, amended by
          Decree-Law no. 42/88, of February 6th
        </b>
      </p>
      <p>
        If the written information is in a foreign language or languages ​​when
        selling goods or services on the national market, its full translation
        into Portuguese is mandatory, and, depending on the case, the translated
        text must be affixed to the labels or packaging or added to the
        information means mentioned in the preceding article.
      </p>
      <p>
        <b>
          Article 3 of Decree-Law no. 238/86, of August 19th, amended by
          Decree-Law no. 42/88, of February 6th
        </b>
      </p>
      <p>
        Without prejudice to containing a version in a foreign language or
        languages, agreements that have per object the sale of goods or
        PediRoutes or service provision in the domestic market, as well as the
        issuance of invoices or receipts, must be written in Portuguese.
      </p>
      <p>
        Article 4 of Decree-Law no. 238/86, of August 19th, amended by
        Decree-Law no. 42/88, of February 6th
      </p>
      <p>
        1 – The obligations set out in this law bind, in the domestic market,
        the manufacturer, packager, service provider and all other agents that
        carry out wholesale or retail trade activities.
      </p>
      <p>
        2 – The inspection of compliance with the obligation to inform in
        Portuguese shall be carried out when the good or service is placed
        within reach of the consumer, without prejudice to the responsibility of
        the other economic agents mentioned in the preceding paragraph.
      </p>
      <h1 id="privacy">E - Privacy Policy</h1> 
      <p>
        <b>1. IDENTIFICATION OF THE CONTROLLER</b>
      </p>
      <ul>
        <li>
          Company: Municípia – Empresa de Cartografia e Sistemas de Informação,
          SA (hereinafter “Municípia”)
        </li>
        <li>
          Headquarters: Taguspark, Edifício Ciência II, 3ºB, 2740-120 Porto
          Salvo
        </li>
        <li>Legal person number: 504 475 606</li> 
      </ul>
      <p>
        <b>2. INFORMATION AND CONSENT</b>
      </p>
      <p>
        The Personal Data Protection Act (hereinafter “LPDP”) and the General
        Data Protection Regulation (Regulation (EU) 2016/679 of the European
        Parliament and of the Council of April 27th, 2016, hereinafter “GDPR”)
        ensure the protection of natural persons with regard to the processing
        of personal data and the free movement of such data.
      </p>
      <p>
        Under the law, “personal data” means any information, of any nature and
        regardless of its support, including sound and image, relating to an
        identified or identifiable natural person, so the protection does not
        cover the data of legal persons.
      </p>
      <p>
        By accepting this Privacy Policy, the user provides their informed,
        express, free and unambiguous consent for the personal data provided
        through the website www.uniquepaths.eu to be included in a file under
        the responsibility of Municípia, which shall be processed in accordance
        with the LPDP and the GDPR in compliance with the appropriate technical
        and organisational security measures.
      </p>
      <p>
        Municípia maintains a database with the registration of its customers.
        The data contained in this database are solely the data provided by them
        at the time of registration, collected and processed automatically,
        under the terms approved by Municípia, the entity responsible for the
        corresponding file.
      </p>
      <p>
        Under no circumstances shall information be requested on philosophical
        or political beliefs, party or union affiliation, religious faith,
        private life and racial or ethnic origin, as well as data relating to
        health and sexual life, including genetic data.
      </p>
      <p>
        Under no circumstances shall we carry out any of the following
        activities with the personal data provided to us through this website:
      </p>
      <ul>
        <li>
          Assign to other people or other entities, without prior consent of the
          data subject;
        </li>
        <li>
          Transfer outside the European Economic Area (EEA) without prior
          consent of the data subject.
        </li>
         
      </ul>
      <p>
        <b>3. PURPOSES OF PERSONAL DATA PROCESSING</b>
      </p>
      <p>
        The personal data that we process through this page shall only be used
        for the following purposes:
      </p>
      <ul>
        <li>
          Execution and management of a purchase and sale or service provision
          agreement;
        </li>
        <li>Processing bookings;</li>
        <li>Communication with customers and clarification of doubts;</li>
        <li>Processing of information requests;</li>
        <li>
          Processing Complaints, Regulator Procedures, Pre-litigation Procedures
          or exercise of rights in legal proceedings;
        </li>
        <li>Statistical analysis activities;</li>
        <li>
          Checking, maintaining and developing systems and statistical analysis;
        </li>
        <li>
          Direct marketing communications (if you have consented to the
          processing of your personal data for this purpose);
        </li>
        <li>Prevention and fight against fraud;</li>
        <li>Request for comments on PediRoutes or services purchased;</li>
        <li>Carrying out satisfaction surveys.</li>
      </ul>
      <p>
        Municípia guarantees the confidentiality of all data provided by its
        customers. Notwithstanding the fact that Municípia collects and
        procedures data safely and prevents their loss or manipulation, using
        the most perfected techniques for this purpose, we inform you that
        collection in an open network allows circulation of personal data
        without security conditions, at the risk of being seen and used by
        unauthorised third parties.
      </p>
      <p>
        On the other hand, the user consents to accessing information regarding
        the service hired with Municípia to be able to offer additional services
        to the hired party.
      </p>
      <p>
        At the time of collection of personal data, except in the fields where
        indicated otherwise, the user may voluntarily provide the personal data,
        lack of response entailing no decrease in the quality or quantity of the
        corresponding services (unless otherwise indicated). However, lack of
        response to the data deemed mandatory entails the impossibility of
        accessing the service for which the data was requested.
      </p>
      <p>
        If you do not agree with the above conditions, Municípia cannot enter
        into an agreement with the user through the Site.
      </p>
      <p>
        <b>4. ASSIGNMENT OF PERSONAL DATA</b>
      </p>
      <p>
        To achieve the purpose of this Site, Municípia shall transfer your
        personal data to other entities, which shall process them, for the
        following purposes:
      </p>
      <ul>
        <li>Payment management and processing activities;</li>
        <li>Provision of services hired.</li>
      </ul>
      <p>
        The entities to which Municípia shall transfer your personal data to
        process them under the terms above shall have the following nature:
      </p>
      <ul>
        <li>PediGuides;</li>
        <li>Third parties related to the provision of the services hired;</li>
        <li>Payment and invoicing management and processing entities.</li> 
      </ul>
      <p>
        <b>5. SECURITY MEASURES</b>
      </p>
      <p>
        Municípia represents that it has implemented and shall continue to
        implement the technical and organisational security measures needed to
        guarantee the security of the personal data provided to it, to prevent
        their change, loss, processing and/or unauthorised access, considering
        the current state of technology, the nature of the data stored and the
        risks that they are exposed to.
      </p>
      <p>
        Municípia guarantees the confidentiality of all data provided by its
        customers, whether in registration or in the process of
        purchasing/booking services. The collection and processing of data is
        carried out securely to prevent loss or manipulation. All data shall be
        entered into a Secure Server (SSL 128 bits) which encrypts/encodes them
        (transforms them into code). You can verify that your browser is secure
        if the lock symbol appears or if the address starts with https instead
        of http.
      </p>
      <p>
        Personal data are treated with the level of protection legally required
        to ensure their security and prevent their change, loss, processing or
        unauthorised access, considering the state of technology, the user being
        aware and accepting that the measures of Internet security are not
        unbreakable.
      </p>
      <p>Municípia, whenever accessing any personal data, undertakes to:</p>
      <ul>
        <li>
          Store them through legally required security technical and
          organisational measures that guarantee their security, thus preventing
          change, loss, treatment or unauthorised access, in accordance with the
          state of technology at any time, the nature of the data and the
          possible risks that they are exposed to;
        </li>
        <li>Use the data exclusively for the purposes previously defined;</li>
        <li>
          Make sure that the data are processed only by workers whose
          intervention is needed for the service provision, and they are bound
          by the duty of secrecy and confidentiality. If there the information
          may be revealed to third parties, they must be obliged to keep due
          confidentiality in accordance with the provisions of this document.
        </li>
      </ul>
      <p>
        <b>6. COMMERCIAL AND PROMOTIONAL COMMUNICATIONS</b>
      </p>
      <p>
        One of the purposes for which we process personal data provided by users
        is to send electronic communications with information relating to
        commercial and promotional communications.
      </p>
      <p>
        Whenever we make a communication of this type, it shall be addressed
        exclusively to users who have expressly authorised them in advance.
      </p>
      <p>
        According to the provisions of Decree-Law no. 7/2004, of January 7th, if
        the user wishes to stop receiving commercial or promotional
        communications from Municípia, they may express objection to the service
        by sending an email to info@uniquepaths.eu.
      </p>
       
      <p>
        <b>7. EXERCISE OF RIGHTS</b>
      </p>
      <p>
        In accordance with the provisions of the LDPD and the GDPR, the user may
        exercise at any time their rights of access, rectification, deletion,
        limitation, objection and portability, upon request by any of the
        following means:
      </p>
      <ul>
        <li>
          Post office: Av Dr Cavaco Silva, Edifício Ciência II, 11, 3º B,
          Taguspark, 2740-120 Porto Salvo – Portugal
        </li>
        <li>Email: info@uniquepaths.eu</li>
        <li>
          Telephone: +351214228200 office hours from 9:30 am to 12:30 pm and
          from 2 pm to 6 pm every weekday.
        </li>
      </ul>
      <p>
        If the user wishes, at any time, to no longer be part of the Municípia
        database, they may exercise this right using these contacts.
      </p>
       
      <p>
        <b>8. CONTROLLING AUTHORITY</b>
      </p>
      <p>
        Under legal terms, the data subject has the right to file a complaint
        regarding the protection of personal data with the competent supervisory
        authority, the National Data Protection Commission (CNPD).
      </p>
      <p>
        <b>9. COOKIES POLICY </b>
      </p>
      <p>
        Cookies are small pieces of data that are stored by a user’s browser on
        their hard drive for a period of time.
      </p>
      <p>
        Cookies may record information that a user accesses on a web page to
        simplify subsequent user interactions or to use the information to
        expedite user transactions on related web pages.
      </p>
      <p>
        Cookies make it easier for the user to move between webpages and to
        complete transactions on the Internet.
      </p>
      <p>
        We use “cookies” to make the Site and Application easier to use and to
        make our advertising better.
      </p>
      <p>
        The Site and Application also use “cookies” to store and sometimes track
        information to make your online experience easier and more customised.
      </p>
      <p>
        Most major web browsers are set to initially accept cookies, but you can
        modify your computer’s preferences to issue an alert when you “download”
        a cookie or to disable the ability of third parties to do so. If the
        user chooses to reject all cookies, some areas of the Site and
        Application may not function correctly.
      </p>
      <p>
        If you have any questions about the cookies used on the website or
        application, please contact us via email at info@uniquepaths.eu
      </p>
    </>
  );
}
