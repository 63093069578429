import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { ReactComponent as Comments } from '../../../../shared_assets/icons/comments.svg';
import { RootState } from '../../../../store';
import { EvaluateRouteNotification } from '../../../../types/notification.interface';
import { complexTranslate } from '../../../../utils';
import { Heading, Text } from '../../../Typography';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { MakeReviewModal } from '../../MakeReviewModal';
import { NotificationsProps } from '../Notification';

interface NotificationEvaluateRouteProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: EvaluateRouteNotification;
  onHide: (id: string) => void;
}

const NotificationEvaluateRoute: React.FC<NotificationEvaluateRouteProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
  onHide,
}) => {
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [isShowingModal, setIsShowingModal] = useState(false);
  const { seen, type, routeTitles, bookingId, routeId } = notification;
  const routeTitle = useMemo(() => {
    const translation = routeTitles.find(t => t.language === activeLanguage);
    return translation ? translation.title : routeTitles[0].title;
  }, [routeTitles, activeLanguage]);
  return (
    <>
      {!simpleVersion && (
        <div className={classes.rowDirection}>
          <Comments className={classes.icon} />
          <Heading level={5} noMargin>
            {translate(`notificationType.${type}`).toString()}
          </Heading>
          {!seen && (
            <Badge
              className={classNames(classes.marginLeft, classes.uppercase)}
              type="primary"
              small
            >
              {translate('notifications.new').toString()}
            </Badge>
          )}
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        <Text variant={!simpleVersion ? 'faded' : 'default'} noMargin>
          {complexTranslate(
            translate('notifications.evaluateRoute').toString(),
            {
              '{routeTitle}': (
                <Text className={classes.inlineBtn} noMargin>
                  {routeTitle}
                </Text>
              ),
            }
          )}
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        <MakeReviewModal
          visible={isShowingModal}
          routeId={routeId}
          bookingId={bookingId}
          onDismiss={() => {
            setIsShowingModal(false);
          }}
          onFinish={() => {
            setIsShowingModal(false);
            onHide(notification.notificationId);
          }}
        />
        <Button
          type="link"
          onlyText
          onClick={() => {
            setIsShowingModal(true);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.evaluateRoute').toString()}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default withLocalize(NotificationEvaluateRoute);
