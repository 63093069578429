import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

import { getTheme } from '../../../utils';
import { Heading, Text } from '../../Typography';

const useListStyles = makeStyles({
  benefitContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const useBenefitStyles = makeStyles({
  benefit: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: () => ({
    fontWeight: ('600 !important' as unknown) as number,
    color: `${getTheme().primary} !important`,
    marginBottom: '1.5rem !important',
  }),
  description: {
    marginBottom: '1.5rem !important',
  },
});

export interface BenefitProps {
  title: string;
  description: string;
}

export interface BenefitListProps {
  data: BenefitProps[];
  className?: string;
}

export function BenefitList({ data, className }: BenefitListProps) {
  const classes = useListStyles();

  return (
    <div className={classNames(classes.benefitContainer, className)}>
      {data.map((benefit, i) => (
        <Benefit key={i.toString()} {...benefit} />
      ))}
    </div>
  );
}

export function Benefit({ title, description }: BenefitProps) {
  const classes = useBenefitStyles();
  return (
    <div className={classes.benefit}>
      <Heading level={4} className={classes.title}>
        {title}
      </Heading>
      <Text variant="faded" className={classes.description}>
        {description}
      </Text>
    </div>
  );
}
