/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { ReactComponent as PlusBlue } from '../../../shared_assets/icons/plus_blue.svg';
import { SkillType } from '../../../types/skill-type.enum';
import { mobileThreshhold } from '../../../utils';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Select } from '../Select';
import Option from '../Select/Option';
import SkillEntry from './SkillEntry';

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  addRemoveBtn: {
    display: 'flex',
    '& > :last-child': {
      marginLeft: '0.5rem',
    },
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
});

interface SkillSelectionProps extends LocalizeContextProps {
  disabled?: boolean;
  value?: string[];
  onChange?: (newSkill: string[]) => void;
}

const SkillSelection: React.FC<SkillSelectionProps> = ({
  disabled,
  value,
  onChange,
  translate,
}) => {
  const classes = useStyles();
  const [currentSkill, setCurrentSkill] = useState<string>('');
  const [selectedSkills, setSelectedSkills] = useState<string[]>(value || []);

  const [editing, setEditing] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);

  const [skillToReplace, setSkillToReplace] = useState<string | undefined>();
  const [skillToAdd, setSkillToAdd] = useState<string | undefined>();

  useEffect(() => {
    if (!value) return;

    setSelectedSkills(value);
  }, [value]);
  useEffect(() => {
    if (skillToAdd) {
      setCurrentSkill(skillToAdd);
    }
  }, [skillToAdd]);
  useEffect(() => {
    if (!onChange) return;

    onChange(selectedSkills);
  }, [selectedSkills, onChange]);

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setAdding(false);
                setSkillToAdd(undefined);
              }}
            >
              <Translate id="button.cancel" />
            </Button>

            <Button
              disabled={!skillToAdd}
              type="primary"
              onClick={() => {
                if (skillToAdd) {
                  setSelectedSkills(prevSkills => [...prevSkills, skillToAdd]);
                  setSkillToAdd(undefined);
                  setAdding(false);
                }
              }}
            >
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={adding}
        closable={false}
      >
        <Select
          className={classes.select}
          value={skillToAdd}
          onChange={ev => {
            setSkillToAdd(ev as string);
          }}
          placeholder={<Translate id="button.addSkill" />}
          getPopupContainer={() => document.body}
          huge
        >
          {Object.keys(SkillType)
            .filter(skill => !selectedSkills.includes(skill))
            .map((skill, index) => (
              <Option key={`${skill}_${index}_something`} value={skill}>
                {translate(`profile.skillList.${skill}`)}
              </Option>
            ))}
        </Select>
      </Modal>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setSkillToAdd(undefined);
                setSkillToReplace(undefined);
                setEditing(false);
              }}
            >
              <Translate id="button.cancel" />
            </Button>
            <div className={classes.addRemoveBtn}>
              <Button
                disabled={!skillToAdd || skillToReplace !== skillToAdd}
                danger
                onClick={() => {
                  if (skillToAdd) {
                    setSelectedSkills(prevSkills =>
                      prevSkills.filter(lang => lang !== skillToReplace)
                    );
                    setSkillToAdd(undefined);
                    setEditing(false);
                  }
                }}
              >
                <Translate id="button.remove" />
              </Button>
              <Button
                disabled={!skillToAdd}
                type="primary"
                onClick={() => {
                  if (skillToAdd) {
                    setSelectedSkills(prevSkills => [
                      ...prevSkills.filter(lang => lang !== skillToReplace),
                      skillToAdd,
                    ]);
                    setSkillToAdd(undefined);
                    setSkillToReplace(undefined);
                    setEditing(false);
                  }
                }}
              >
                <Translate id="button.save" />
              </Button>
            </div>
          </div>
        }
        visible={editing}
        closable={false}
      >
        <Select
          className={classes.select}
          value={skillToAdd}
          onChange={ev => setSkillToAdd(ev as string)}
          getPopupContainer={() => document.body}
          huge
        >
          {Object.keys(SkillType)
            .filter(
              skill =>
                skill === skillToReplace || !selectedSkills.includes(skill)
            )
            .map((skill, index) => (
              <Option key={`${skill}_${index}_add`} value={skill}>
                {translate(`profile.skillList.${skill}`)}
              </Option>
            ))}
        </Select>
      </Modal>
      <div>
        {selectedSkills.map((skill, i) => (
          <SkillEntry
            disabled={disabled}
            key={skill}
            onRequestSkillChange={() => {
              setSkillToReplace(skill);
              setSkillToAdd(skill);
              setEditing(true);
            }}
            skill={translate(`profile.skillList.${skill}`).toString()}
          />
        ))}
        {!disabled ? (
          <Button
            onClick={() => setAdding(true)}
            prefix={<PlusBlue />}
            type="link"
          >
            <Translate id="button.addSkills" />
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(withLocalize(SkillSelection));
