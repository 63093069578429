import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import { Translate } from 'react-localize-redux';
import { Button } from '../../../../components/App/Button';
import { SkillSelection } from '../../../../components/App/SkillSelection';
import { Heading, Text } from '../../../../components/Typography';
import { ReactComponent as BackWhite } from '../../../../shared_assets/icons/back_white.svg';
import { ReactComponent as Next } from '../../../../shared_assets/icons/next.svg';
import {
  castShadow,
  desktopPadding,
  getTheme,
  mobilePadding,
  mobileThreshhold,
} from '../../../../utils';
import { SlideFromRightTransition } from '../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    padding: '1rem 0',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    margin: '1rem 0',
    '& .ant-btn': {
      padding: '1rem',
    },
  },
  invisible: {
    display: 'none',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  icon: {
    marginRight: '1em',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },
    container: {
      padding: '2rem',
      boxShadow: castShadow,
      borderRadius: '10px',
    },
  },
});

interface SkillStepProps {
  setSelectedSkills: (v: SetStateAction<string[]>) => void;
  selectedSkills: string[];
  onRequestPreviousStep: () => void;
  onRequestNextStep: () => void;
}

function SkillStep({
  setSelectedSkills,
  selectedSkills,
  onRequestPreviousStep,
  onRequestNextStep,
}: SkillStepProps) {
  const classes = useStyles();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={SlideFromRightTransition}
    >
      <div className={classes.body}>
        <div className={classes.container}>
          <Heading level={4}>
            <Translate id="onboarding.skills" />
          </Heading>
          <Heading level={5}>
            <Translate id="onboarding.knownSkills" />
          </Heading>
          <Text variant="faded">
            <Translate id="onboarding.selectKnownSkills" />
          </Text>
          <SkillSelection
            onChange={setSelectedSkills}
            value={selectedSkills}
            //skills={remoteSkills}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          size="large"
          onClick={onRequestPreviousStep}
          prefix={<BackWhite />}
        >
          <Translate id="button.previous" />
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={onRequestNextStep}
          suffix={<Next />}
        >
          <Translate id="button.next" />
        </Button>
      </div>
    </motion.div>
  );
}

export default React.memo(SkillStep);
