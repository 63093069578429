import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';

import { Guide } from '../../../types/guide.interface';
import { Button } from '../Button';

interface StyleProps {
  shouldShow: boolean;
}

const useStyles = makeStyles({
  footer: ({ shouldShow }: StyleProps) => ({
    zIndex: 999,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1rem',
    height: 64,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    alignItems: 'center',
    //     -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.75);
    // -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.75);
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
    transform: `translateY(${shouldShow ? '0%' : '100%'})`,
    transition: 'transform 200ms ease-in-out',
  }),
  text: {
    margin: 0,
  },
  button: {
    textTransform: 'uppercase',
  },
});

interface CreateRouteStickyFooter {
  onCreate: () => void;
  guide: Guide | null;
}

export default function CreateRouteStickyFooter({
  onCreate,
  guide,
}: CreateRouteStickyFooter) {
  const [shouldShow, setShouldShow] = useState(false);

  const onScroll = useCallback(() => {
    if ((document.getElementById('root')?.scrollTop || 0) > 0) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  }, []);

  useEffect(() => {
    document.getElementById('root')?.addEventListener('scroll', onScroll);

    return () => {
      document.getElementById('root')?.removeEventListener('scroll', onScroll);
    };
  });

  const classes = useStyles({ shouldShow });

  return (
    <div className={classes.footer}>
      <h3 className={classes.text}>
        <Translate id="landing.guide.organizeRoute" />
      </h3>
      <Button type="primary" className={classes.button} onClick={onCreate}>
        {guide && guide.numberOfRoutes > 0 ? (
          <Translate id="landing.guide.accessMyCalendar" />
        ) : (
          <Translate id="landing.start" />
        )}
      </Button>
    </div>
  );
}
