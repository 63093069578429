import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { BookingSection } from '../../../components/App/BookingSection';
import { Button } from '../../../components/App/Button';
import { Modal } from '../../../components/App/Modal';
import { Heading, Text } from '../../../components/Typography';
import { BookingsPaymentInterface } from '../../../hooks/guide/useGuidePayments';
import { getInvoiceUrl } from '../../../hooks/visitor/useVisitorInvoices';
import { ReactComponent as Download } from '../../../shared_assets/icons/download.svg';
import { BookingState } from '../../../types/booking-state.enum';
import { complexTranslate } from '../../../utils';

const useStyles = makeStyles({
  image: {
    width: '100%',
    marginBottom: '1rem',
    borderRadius: 10,
  },
  downloadIcon: {
    marginLeft: '1rem',
  },
  disabledIcon: {
    '& > *': {
      fill: 'grey !important',
    },
  },
});

interface PaymentModalProps extends LocalizeContextProps {
  visible: boolean;
  amount: number;
  id: string;
  date: Date;
  available: boolean;
  documentId: number;
  bookings: BookingsPaymentInterface[];
  onRequestClose: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  id,
  amount,
  date,
  visible,
  translate,
  onRequestClose,
  available,
  documentId,
  bookings,
}) => {
  const classes = useStyles();

  const downloadFile = useCallback(async () => {
    window.open(await getInvoiceUrl(documentId.toString()));
  }, [documentId]);

  return (
    <Modal
      visible={visible}
      variant="close"
      footer={null}
      headerTitle={<Translate id="paymentHistory.paymentDetails" />}
      onRequestClose={onRequestClose}
    >
      <img
        className={classes.image}
        src={require('../../../shared_assets/images/payment.png')}
        alt="payment"
      />
      <Heading level={4}>
        {complexTranslate(
          translate('paymentHistory.receivedPayment').toString(),
          { '{AMOUNT}': amount.toFixed(2) }
        )}
      </Heading>
      <Text variant="faded" size={12}>
        {complexTranslate(translate('paymentHistory.performedAt').toString(), {
          '{DATE}': moment(date).format('LLL'),
        })}
      </Text>
      <Text weight="semibold">
        <Translate id="schedules.bookings" />
      </Text>
      <BookingSection
        canceled={false}
        maxVisitors={bookings[0].maxVisitors}
        bookings={
          bookings
            .filter(b => b.bookingState !== BookingState.CANCELED)
            .map(b => ({ ...b, groupSize: b.groupSize })) || []
        }
      />
      <Divider />
      <BookingSection
        canceled={true}
        bookings={
          bookings
            .filter(b => b.bookingState === BookingState.CANCELED)
            .map(b => ({ ...b, groupSize: b.groupSize })) || []
        }
      />
      {available === true ? (
        <Button type="link" onlyText onClick={downloadFile}>
          <Translate id="invoices.download" />
          <Download className={classes.downloadIcon} />
        </Button>
      ) : (
        <Button type="link" onlyText disabled>
          <Translate id="invoices.notAvailable" />
          <Download
            className={classNames(classes.downloadIcon, classes.disabledIcon)}
          />
        </Button>
      )}
      <Divider />
      <Text variant="faded" size={12}>
        {complexTranslate(translate('paymentHistory.transaction').toString(), {
          '{ID}': id,
        })}
      </Text>
    </Modal>
  );
};

export default withLocalize(PaymentModal);
