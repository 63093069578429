import {
  AppActions,
  AppState,
  SET_GUIDE_UNSEEN_NOTIFICATIONS,
  SET_LAST_APP_ZONE,
  SET_LAST_GUIDE_STATE,
  SET_MARKER_COUNT,
  SET_REACHED_BOTTOM,
  SET_THEME,
  SET_VISITOR_UNSEEN_NOTIFICATIONS,
} from './types';

const INITIAL_STATE: AppState = {
  reachedBottom: false,
  guideUnseenNotifications: 0,
  visitorUnseenNotifications: 0,
  lastGuideState: undefined,
  lastAppZone: 'visitor',
  theme: undefined,
  count: null,
};

const AppReducer = (
  state: AppState = INITIAL_STATE,
  action: AppActions
): AppState => {
  switch (action.type) {
    case SET_REACHED_BOTTOM:
      return {
        ...state,
        reachedBottom: action.payload,
      };
    case SET_GUIDE_UNSEEN_NOTIFICATIONS:
      return {
        ...state,
        guideUnseenNotifications: action.payload,
      };
    case SET_VISITOR_UNSEEN_NOTIFICATIONS:
      return {
        ...state,
        visitorUnseenNotifications: action.payload,
      };
    case SET_LAST_GUIDE_STATE:
      return {
        ...state,
        lastGuideState: action.payload,
      };
    case SET_LAST_APP_ZONE:
      return {
        ...state,
        lastAppZone: action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_MARKER_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};

export default AppReducer;
