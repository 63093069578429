import { makeStyles } from '@material-ui/styles';
import { DatePicker, Table } from 'antd';
import Button from 'antd/es/button';
import Axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../components/App/Loader';
import { API_URL } from '../../../../../hooks/../consts';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  divDatePicker: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem',
  },
  divSubmitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3rem',
  },
});

const columns = [
  {
    title: <Translate id="adminstration.paymentList.guideName" />,
    dataIndex: 'guideName',
    key: 'guideName',
  },

  {
    title: <Translate id="adminstration.paymentList.nif" />,
    dataIndex: 'nif',
    key: 'nif',
  },
  {
    title: <Translate id="adminstration.paymentList.iban" />,
    dataIndex: 'iban',
    key: 'iban',
  },
  {
    title: <Translate id="adminstration.paymentList.totalSum" />,
    dataIndex: 'totalSum',
    key: 'totalSum',
  },
];
interface TableDataInterface {
  key: string;
  guideName: string;
  nif: string;
  iban: string;
  totalSum: string;
}
interface ListPaymentsInterface {
  guideName: string;
  id: string;
  nif: string;
  iban: string;
  totalSum: number;
}
interface ParamsInterface {
  id: string;
}
const PaymentList = () => {
  const classes = useStyles();
  const [paymentList, setPaymentList] = useState<ListPaymentsInterface[]>([]);
  const [fetchingList] = useState<boolean>(false);

  const params = useParams<ParamsInterface | undefined>();
  const [selectedMonth, setSelectedMonth] = useState(moment());
  useEffect(() => {
    async function fetchList(
      params: ParamsInterface | undefined
    ): Promise<ListPaymentsInterface[]> {
      const { data: list } = await Axios.get<ListPaymentsInterface[]>(
        `${API_URL}/administrator/payment-list/${moment(selectedMonth).format(
          'MM'
        )}/${moment(selectedMonth).format('YYYY')}${
          params?.id ? `/intermediate/${params.id}` : ''
        }`
      );
      setPaymentList(list);
      return list;
    }
    fetchList(params);
  }, [params, selectedMonth]);
  const tableData: TableDataInterface[] = useMemo(() => {
    if (!paymentList) return [];
    return paymentList.map(g => {
      return {
        key: g.id,
        guideName: g.guideName,
        nif: g.nif,
        iban: g.iban,
        totalSum: `${g.totalSum} €`,
      };
    });
  }, [paymentList]);
  const dataPicker = useCallback(async (momentDate: any) => {
    setSelectedMonth(moment(momentDate));
  }, []);

  if (fetchingList) return <Loader />;

  return (
    <>
      <div className={classes.divDatePicker}>
        <DatePicker
          defaultValue={selectedMonth}
          onChange={dataPicker}
          picker="month"
        />
      </div>

      <Table dataSource={tableData} columns={columns} />

      {paymentList.length > 0 ? (
        <div className={classes.divSubmitButton}>
          <Button
            type="primary"
            size={'large'}
            href={`${API_URL}/administrator/export/payment-list/${moment(
              selectedMonth
            ).format('MM')}/${moment(selectedMonth).format('YYYY')}${
              params?.id ? `/intermediate/${params.id}` : ''
            }`}
          >
            <Translate id="adminstration.paymentList.export" />
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default PaymentList;
