import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { GuideStateType } from '../../types/guide-state-type.enum';

export type Company = {
  id: string;
  name: string;
  representativeName: string;
  nif: number;
  location: string;
  email: string;
  phoneNumber: string;
  postalCode: string;
  address: string;
  state: GuideStateType;
  documentName: string;
  ivaExemptionId: string;
  retentionId: string;
  iban: string;
};
const fetchCompany = (id: string) =>
  Axios.get<Company>(`${API_URL}/company/${id}`);

type Hook = (
  id: string | undefined,
  setter: (v: SetStateAction<Company | undefined>) => void
) => [boolean, boolean];

export const useCompany: Hook = (id, setCompany) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      setIsError(true);
      setCompany(undefined);
      setIsLoading(false);
      return;
    }
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await fetchCompany(id);
        setCompany(data);
      } catch (err) {
        setCompany(undefined);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setCompany, id]);

  return [isLoading, isError];
};
