import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { RouteSubjectWithSingleTranslation } from '../../../hooks/subjects/useSubjects';
import { useCurrentSize } from '../../../hooks/useCurrentSize';
import SubjectItem from './SubjectItem';

const useStyles = makeStyles({
  list: ({ vw }: { vw: number }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(${
      10 * vw < 100 ? 100 : 10 * vw
    }px, 1fr))`,
    gridAutoRows: '1fr',
    gap: '3rem',
    '&::before': {
      content: "''",
      width: 0,
      paddingBottom: '100%',
      gridRow: '1 / 1',
      gridColumn: '1 / 1',
    },
    '& > *:first-child': {
      gridRow: '1 / 1',
      gridColumn: '1 / 1',
    },
  }),
});

interface SelectableSubjectListProps {
  onChange: (newSubjects: string[]) => void;
  value: string[];
  subjects: RouteSubjectWithSingleTranslation[];
}

function SelectableSubjectList({
  subjects,
  onChange,
  value,
}: SelectableSubjectListProps) {
  const { width } = useCurrentSize();
  const classes = useStyles({ vw: width / 100 });
  return (
    <div className={classes.list}>
      {subjects.map(subject => (
        <SubjectItem
          selected={value.includes(subject.id)}
          key={subject.id}
          onClick={() => {
            let newValue = [...value];

            if (value.includes(subject.id))
              newValue = newValue.filter(sub => subject.id !== sub);
            else newValue = [...newValue, subject.id];

            onChange(newValue);
          }}
          image={subject.image}
          name={subject.name}
        />
      ))}
    </div>
  );
}

export default SelectableSubjectList;
