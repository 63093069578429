import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

type Hook = (
  setSystemConfiguration: (
    v: SetStateAction<SystemConfigurationResponse | null>
  ) => void
) => [boolean, boolean];

export interface SystemConfigurationResponse {
  id: string;
  annualFee: number;
  year: number;
  uniqueCut: number;
  timeValue: number;
  timePercentage: number;
  languageNumber: number;
  languagePercentage: number;
  minLocalBusinessValue: number;
  maxLocalBusinessValue: number;
  minLocalBusinessPercentage: number;
  maxLocalBusinessPercentage: number;
  minReviewValue: number;
  mid1ReviewValue: number;
  mid2ReviewValue: number;
  maxReviewValue: number;
  minReviewPercentage: number;
  mid1ReviewPercentage: number;
  mid2ReviewPercentage: number;
  maxReviewPercentage: number;
  minCompletedValue: number;
  midCompletedValue: number;
  maxCompletedValue: number;
  minCompletedPercentage: number;
  midCompletedPercentage: number;
  maxCompletedPercentage: number;
  seniorDiscount: number;
  childrenDiscount: number;
}

export const getDefaultCurrentFeeResponse = (): SystemConfigurationResponse => ({
  year: moment().year(),
  annualFee: 0,
  id: '',
  uniqueCut: 0,
  timeValue: 0,
  timePercentage: 0,
  languageNumber: 0,
  languagePercentage: 0,
  minLocalBusinessValue: 0,
  maxLocalBusinessValue: 0,
  minLocalBusinessPercentage: 0,
  maxLocalBusinessPercentage: 0,
  minReviewValue: 0,
  mid1ReviewValue: 0,
  mid2ReviewValue: 0,
  maxReviewValue: 0,
  minReviewPercentage: 0,
  mid1ReviewPercentage: 0,
  mid2ReviewPercentage: 0,
  maxReviewPercentage: 0,
  minCompletedValue: 0,
  midCompletedValue: 0,
  maxCompletedValue: 0,
  minCompletedPercentage: 0,
  midCompletedPercentage: 0,
  maxCompletedPercentage: 0,
  seniorDiscount: 0,
  childrenDiscount: 0,
});

const requestCurrentFee = () =>
  Axios.get(`${API_URL}/administrator/system-configurations/latest`);

export const useCurrentAnnualFee: Hook = setSystemConfiguration => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await requestCurrentFee();
        setSystemConfiguration(data);
        setIsError(false);
      } catch (err) {
        if (err.response.status === 404) {
          setSystemConfiguration(getDefaultCurrentFeeResponse());
          setIsError(false);
        } else {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [setSystemConfiguration]);

  return [isLoading, isError];
};
