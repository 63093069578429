import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Popover from 'antd/es/popover';
import classNames from 'classnames';
import { motion, Variants } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { LogoUrl, WhiteLogoUrl } from '../../../hooks/customization/getLogo';
import { ChangeLanguageModal } from '../../../pages/ChangeLanguage';
import { LoginModal } from '../../../pages/Login';
import { RegisterModal } from '../../../pages/Registration';
import { ReactComponent as ArrowDownWhite } from '../../../shared_assets/icons/arrow down white.svg';
import { ReactComponent as ArrowDown } from '../../../shared_assets/icons/arrow down.svg';
import { RootState } from '../../../store';
import { GuideStateType } from '../../../types/guide-state-type.enum';
import {
  desktopPadding,
  getTheme,
  headerHeight,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import ChangeAccountTypeModal from '../ChangeAccountTypeModal/ChangeAccountTypeModal';
import FallbackImage from '../Image/FallbackImage';
import { RouteSearch } from '../RouteSearch';
import AvatarNotificationCollapsible from './AvatarNotificationCollapsible/AvatarNotificationCollapsible';
import DesktopAuthentication from './DesktopAuthentication';
import DesktopLinkSection from './DesktopLinkSection';
import Drawer from './Drawer';

interface StyleProps {
  contrastColor: string;
  baseColor: string;
}

const useStyles = makeStyles({
  drawerHolder: {
    position: 'relative',
  },
  floating: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  dynamicContainer: ({ contrastColor, baseColor }: StyleProps) => ({
    color: contrastColor,
    backgroundColor: baseColor,
  }),
  container: () => ({
    display: 'flex',
    flex: 1,
    transition: 'background-color 300ms',
    flexGrow: 1,
    flexDirection: 'row',
    height: headerHeight,
    maxHeight: headerHeight,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    zIndex: 1000,
    position: 'relative',
    padding: '8px 1rem',
    '& .ant-popover': {
      '&.drawer-popover': {
        '& .ant-popover-arrow': {
          visibility: 'hidden',
        },
      },
      maxWidth: '50vw',
      maxHeight: `calc(100vh - ${headerHeight}px)`,
      '&:not(.drawer-popover):not(.search-popover) .ant-popover-arrow': {
        borderLeftColor: getTheme().primary,
        backgroundColor: getTheme().primary,
        borderTopColor: getTheme().primary,
      },
      '&:not(.drawer-popover):not(.search-popover) .ant-popover-inner': {
        backgroundColor: getTheme().primary,
      },
      '&.drawer-popover .ant-popover-inner-content': {
        paddingBottom: 0,
      },
    },
  }),
  logo: {
    flex: 0,
    maxHeight: '100%',
  },
  icon: {
    width: 16,
    height: 28,
    padding: '0.2rem',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  link: {
    display: 'flex',
    height: '100%',
  },
  dropIcon: {
    transformOrigin: 'center center',
    marginLeft: '0.5rem',
    display: 'flex',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  desktopDivider: {
    margin: 0,
  },
  padder: {},
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    padder: {
      ...desktopPadding,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  [`@media (max-width: ${mobileThreshhold}px)`]: {
    container: () => ({
      '& .ant-popover': {
        maxWidth: '100vw !important',
        width: '100vw !important',
        '& .ant-popover-inner': {
          boxShadow: 'none',
        },
        '& .ant-popover-arrow': {
          right: `calc(1rem + 32px + 16px - 4px) !important`,
        },
      },
    }),
  },
});

const iconVariants: Variants = {
  drawerClosed: {
    transform: 'rotate(0deg)',
  },
  drawerOpen: {
    transform: 'rotate(180deg)',
  },
  exit: {},
};

interface HeaderProps {
  onDrawerStateChange?: (isOpen: boolean) => void;
}

const locations = [
  { path: '/about', hideHeader: false, floating: true },
  { path: '/guide/profile/public', hideHeader: true },
  { path: '/guide/profile/public/edit', hideHeader: true },
  { path: '/guide/profile/personal', hideHeader: true },
  { path: '/guide/profile/personal/edit', hideHeader: true },
  { path: '/guide/profile/personal', hideHeader: true },
  { path: '/guide/profile/personal/change-password', hideHeader: true },
  { path: '/guide/my-routes/create', hideHeader: true, hideOnDesktop: true },
  { path: '/guide/my-routes/create/success', hideHeader: true },
  { path: '/guide/my-routes/edit/:id', hideHeader: true, hideOnDesktop: true },
  { path: '/guide/my-routes', hideHeader: false },
  { path: '/guide/profile/menu', hideHeader: false },
  { path: '/guide/my-notifications', hideHeader: false },
  { path: '/guide/schedules', hideHeader: false },
  { path: '/guide/awaiting-approval', hideHeader: false },
  { path: '/guide/my-routes', hideHeader: false },
  { path: '/guide/payment-history', hideHeader: false },
  { path: '/guide/my-comments', hideHeader: false },
  { path: '/guide/about', hideHeader: false, floating: true },
  { path: '/guide/landing', hideHeader: false, floating: true },
  { path: '/guide/onboarding', hideHeader: true, hideOnDesktop: true },
  { path: '/guide/onboarding/intro', hideHeader: true, hideOnDesktop: true },
  {
    path: '/guide/onboarding/languages',
    hideHeader: true,
    hideOnDesktop: true,
  },
  { path: '/guide/onboarding/skills', hideHeader: true, hideOnDesktop: true },
  { path: '/guide/onboarding/address', hideHeader: true, hideOnDesktop: true },
  {
    path: '/guide/onboarding/financial',
    hideHeader: true,
    hideOnDesktop: true,
  },
  { path: '/guide/onboarding/personal', hideHeader: true, hideOnDesktop: true },
  { path: '/guide/onboarding/bio', hideHeader: true, hideOnDesktop: true },
  { path: '/guide/onboarding/finish', hideHeader: true, hideOnDesktop: true },
  { path: '/guide/:id', hideHeader: true },
  { path: '/visitor/landing', hideHeader: false, floating: true },
  { path: '/visitor/about', hideHeader: false, floating: true },
  { path: '/visitor/my-notifications', hideHeader: false },
  { path: '/visitor/bookings', hideHeader: false },
  { path: '/visitor/bookings/history', hideHeader: false },
  { path: '/visitor/bookings/:id', hideHeader: true },
  { path: '/visitor/invoices', hideHeader: false },
  { path: '/visitor/profile', hideHeader: false },
  { path: '/visitor/profile/edit', hideHeader: true },
  { path: '/visitor/profile/change-password', hideHeader: true },
  { path: '/route/search', hideHeader: false },
  { path: '/route/:id', hideHeader: true },
];

function Header({ onDrawerStateChange }: HeaderProps, ref: any) {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [collapsibleOpen, setCollapsibleOpen] = useState<boolean>(false);
  const [showRegistrationModal, setShowRegistrationModal] =
    useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);
  const [changingAccountType, setChangingAccountType] =
    useState<boolean>(false);
  useImperativeHandle(
    ref,
    () => ({
      openLogin: () => {
        setShowLoginModal(true);
      },
    }),
    []
  );

  const openRegistration = useCallback(() => {
    setShowRegistrationModal(true);
    setShowLoginModal(false);
  }, []);

  const openLogin = useCallback(() => {
    setShowLoginModal(true);
    setShowRegistrationModal(false);
  }, []);

  const closeRegisterModal = useCallback(() => {
    setShowRegistrationModal(false);
  }, []);

  const closeLoginModal = useCallback(() => {
    setShowLoginModal(false);
  }, []);

  const adminMatch = useRouteMatch('/admin');
  const companyMatch = useRouteMatch('/company');

  const [jwt] = UseJWT();

  useEffect(() => {
    if (!onDrawerStateChange) return;
    onDrawerStateChange(drawerOpen);
  }, [drawerOpen, onDrawerStateChange]);

  const location = useLocation();
  const isMobile = useIsMobile();

  const [hide, floating] = useMemo(() => {
    if (adminMatch || companyMatch) {
      return [true, false];
    }
    const locationInfo = locations.find(({ path }) =>
      matchPath(location.pathname, { path, exact: true })
    );

    if (!isMobile) return [locationInfo?.hideOnDesktop, false];
    else return [locationInfo?.hideHeader, locationInfo?.floating];
  }, [adminMatch, companyMatch, isMobile, location.pathname]);

  const baseColor = useMemo(
    () => (!floating || drawerOpen ? 'white' : 'transparent'),
    [floating, drawerOpen]
  );
  const contrastColor = useMemo(
    () => (floating ? 'white' : 'black'),
    [floating]
  );

  const classes = useStyles({
    contrastColor,
    baseColor,
  });

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
    setCollapsibleOpen(false);
  }, []);

  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );

  const guideIsApproved = useSelector(
    (state: RootState) => state.app.lastGuideState === GuideStateType.APPROVED
  );

  const { lastAppZone } = useSelector((state: RootState) => state.app);

  const closeChangeAcccountModal = useCallback(() => {
    setChangingAccountType(false);
  }, []);

  const loginModal = (
    <LoginModal
      isVisible={showLoginModal}
      loginType={lastAppZone === 'guide' ? 'GUIDE' : 'VISITOR'}
      openRequestRegister={openRegistration}
      onRequestClose={closeLoginModal}
    />
  );
  const registerModal = (
    <RegisterModal
      isVisible={showRegistrationModal}
      registerType={lastAppZone === 'guide' ? 'GUIDE' : 'VISITOR'}
      onRequestOpenLogin={openLogin}
      onRegistrationSuccess={closeRegisterModal}
      onRequestClose={closeRegisterModal}
    />
  );
  if (hide)
    return (
      <>
        {loginModal} {registerModal}{' '}
      </>
    );

  return (
    <div className={(floating && classes.floating) || undefined}>
      <ChangeLanguageModal
        visible={showLanguageModal}
        onClose={() => {
          setShowLanguageModal(false);
        }}
      />
      <ChangeAccountTypeModal
        visible={changingAccountType}
        onRequestClose={closeChangeAcccountModal}
      />
      {registerModal}
      {loginModal}
      <div className={classes.padder}>
        <div
          className={classNames(classes.dynamicContainer, classes.container)}
        >
          <Link className={classes.link} onClick={closeDrawer} to="/">
            <FallbackImage
              src={
                contrastColor === 'white' && !drawerOpen
                  ? WhiteLogoUrl
                  : LogoUrl
              }
              className={classes.logo}
              alt="logo"
              fallback={
                contrastColor === 'white' && !drawerOpen
                  ? require('./assets/logo_branco.png')
                  : require('./assets/logo_cor.png')
              }
            />
          </Link>
          <div className={classes.rightContainer}>
            {lastAppZone === 'visitor' && (
              <RouteSearch
                onSearchStateChange={closeDrawer}
                showBlack={drawerOpen || !floating}
              />
            )}
            {!isMobile && (
              <DesktopLinkSection
                setShowLanguageModal={setShowLanguageModal}
                reduxActiveLanguage={activeLanguage}
              />
            )}
            {!isMobile &&
              (!jwt ||
                (!jwt.guideId && lastAppZone === 'guide') ||
                (!jwt.visitorId && lastAppZone === 'visitor')) && (
                <DesktopAuthentication
                  openLogin={openLogin}
                  openSignUp={openRegistration}
                />
              )}
            {jwt &&
              ((jwt.guideId && lastAppZone === 'guide') ||
                (jwt.visitorId && lastAppZone === 'visitor')) && (
                <AvatarNotificationCollapsible
                  setDrawerOpen={setDrawerOpen}
                  collapsibleOpen={collapsibleOpen}
                  setCollapsibleOpen={setCollapsibleOpen}
                />
              )}
            {(isMobile ||
              (jwt &&
                ((jwt.guideId && lastAppZone === 'guide') ||
                  (jwt.visitorId && lastAppZone === 'visitor')))) && (
              <Popover
                overlayClassName="drawer-popover"
                trigger="click"
                placement="bottomRight"
                visible={drawerOpen}
                onVisibleChange={setDrawerOpen}
                content={
                  <Drawer
                    guideIsApproved={guideIsApproved}
                    setChangingAccountType={setChangingAccountType}
                    activeLanguage={activeLanguage}
                    setShowRegisterModal={setShowRegistrationModal}
                    setShowLoginModal={setShowLoginModal}
                    setShowLanguageModal={setShowLanguageModal}
                    setOpenStatus={setDrawerOpen}
                    offset={
                      48 +
                      12 +
                      10 /* this is not magic it's the top of the popover + the outer padding of the inner-content + padding for the arrow*/
                    }
                  />
                }
              >
                <div className={classes.dropIcon}>
                  <motion.div
                    variants={iconVariants}
                    animate={drawerOpen ? 'drawerOpen' : 'drawerClosed'}
                  >
                    {drawerOpen || !floating ? (
                      <ArrowDown
                        onClick={() => {
                          setDrawerOpen(prevState => !prevState);
                          setCollapsibleOpen(false);
                        }}
                        className={classes.icon}
                      />
                    ) : (
                      <ArrowDownWhite
                        onClick={() => {
                          setDrawerOpen(prevState => !prevState);
                          setCollapsibleOpen(false);
                        }}
                        className={classes.icon}
                      />
                    )}
                  </motion.div>
                </div>
              </Popover>
            )}
          </div>
        </div>
        {!isMobile && <Divider className={classes.desktopDivider} />}
      </div>
    </div>
  );
}

export default React.memo(React.forwardRef(Header));
