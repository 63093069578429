import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import {
  getTheme,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { Text } from '../../Typography';

const hourHeight = 48;

const useStyles = makeStyles({
  scrollContainer: {
    overflowY: 'auto',
  },
  page: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'hidden',
    paddingTop: '1rem',
    ...mobilePadding,
  },
  verticalDivider: {
    marginLeft: '4rem',
    width: 1,
    flex: 1,
    height: '100%',
    zIndex: 2,
    position: 'absolute',
    backgroundColor: getTheme().neutral3,
  },
  horizontalDivider: {
    width: '100%',
    height: 1,
    backgroundColor: getTheme().neutral3,
  },
  hourText: {
    width: '4rem',
    marginBottom: 0,
    textAlign: 'center',
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 0,
  },
  hourContainer: {
    display: 'flex',
    minHeight: hourHeight,
    flex: 1,
    flexDirection: 'column',
  },
  dateContainer: () => ({
    width: '3.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  hours: {
    position: 'relative',
  },
  event: () => ({
    position: 'absolute',
    padding: '0.2rem 1rem',
    right: 0,
    backgroundColor: getTheme().primary,
    borderRadius: 5,
    left: '4rem',
  }),
  dateText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  eventTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  routeName: {
    marginBottom: '0.2rem !important',
  },
  bookingsContainer: {
    borderRadius: 5,
    backgroundColor: 'white',
    padding: 2,
    transform: 'translateY(2px)',
  },
  eventCount: {
    marginLeft: '1rem !important',
  },
  nowDivider: () => ({
    margin: 0,
    backgroundColor: getTheme().primary,
    position: 'absolute',
    marginLeft: '4rem',
    width: 'calc(100% - 4rem)',
    minWidth: 'unset',
    height: 1,
    '& > *': {
      width: 10,
      height: 10,
      position: 'absolute',
      left: -4,
      top: -5,
      borderRadius: '100%',
      backgroundColor: getTheme().primary,
      zIndex: 2,
    },
  }),
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    topRow: {
      display: 'flex',
      alignItems: 'center',
    },
    dateContainer: () => ({
      marginLeft: '5rem',
      width: 'unset',
      '& > *:nth-child(2)': {
        borderRadius: '100%',
        backgroundColor: getTheme().primary,
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white !important',
      },
    }),
  },
});

type minutes = number;

interface DayOrganizerProps {
  date: Moment;
  scrollToHour?: number;
  events: {
    start: string;
    duration: minutes;
    title: string;
    eventOnClick: () => void;
    occupation: number;
    reserveLimit: number;
  }[];
}

function DayOrganizer({ date, events, scrollToHour }: DayOrganizerProps) {
  const classes = useStyles();
  const hourContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [hourOffset, setHourOffset] = useState<number>(0);
  const isMobile = useIsMobile();
  useEffect(() => {
    setHourOffset(
      (hourContainerRef.current &&
        hourContainerRef.current.clientHeight / 2 - 1) ||
        0
    );
  }, [setHourOffset]);

  useEffect(() => {
    scrollContainerRef.current?.scrollTo({
      top: (scrollToHour && scrollToHour * hourHeight) || 0,
      behavior: 'smooth',
    });
  });

  return (
    <div className={classes.page}>
      <div className={classes.verticalDivider} />
      <div className={classes.topRow}>
        <div className={classes.dateContainer}>
          <Text noMargin className={classes.dateText} weight="semibold">
            {isMobile ? date.format('ddd') : date.format('dddd')}
          </Text>
          <Text noMargin className={classes.dateText} weight="semibold">
            {date.format('DD')}
          </Text>
        </div>
        {!isMobile && events.length === 0 && (
          <Text className={classes.eventCount} variant="faded" noMargin>
            <Translate id="schedules.noRoutesPlanned" />
          </Text>
        )}
      </div>
      <div
        className={classNames(classes.scrollContainer, classes.hours)}
        ref={scrollContainerRef}
      >
        <div
          className={classes.nowDivider}
          style={{
            top:
              moment().diff(moment().startOf('day'), 'hours', true) *
                hourHeight +
              hourOffset,
          }}
        >
          <div />
        </div>
        {events.map((ev, i) => (
          <div
            key={i.toString()}
            onClick={ev?.eventOnClick}
            className={classes.event}
            style={{
              height: (ev.duration / 60) * hourHeight + 1,
              top:
                moment({
                  hour: parseInt(ev.start.split(':')[0]),
                  minutes: parseInt(ev.start.split(':')[1]),
                }).diff(moment().startOf('day'), 'hours', true) *
                  hourHeight +
                hourOffset,
            }}
          >
            <div className={classes.eventTitle}>
              <Text
                size={12}
                color="white"
                weight="semibold"
                ellipsis={{ rows: 1 }}
                className={classes.routeName}
              >
                {ev.title}
              </Text>
              <div className={classes.bookingsContainer}>
                <Text noMargin color={getTheme().primary} variant="note">
                  {`${ev.occupation}/${ev.reserveLimit}`}
                </Text>
              </div>
            </div>
            <Text size={12} noMargin color="white" ellipsis={{ rows: 1 }}>
              {/* {`${moment({
              hour: ev.start,
            }).format('LT')} - ${moment({
              hour: ev.start,
            })
              .add({
                minutes: ev.duration,
              })
              .format('LT')}`} */}
            </Text>
          </div>
        ))}
        {Array.from(new Array(24), (_, i) => (
          <div
            className={classes.hourContainer}
            ref={scrollContainerRef}
            key={i.toString()}
          >
            <div className={classes.topContainer} ref={hourContainerRef}>
              <Text
                variant="faded"
                weight="semibold"
                noMargin
                className={classes.hourText}
              >
                {moment({ hour: i }).format('LT')}
              </Text>
              <div className={classes.horizontalDivider} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DayOrganizer;
