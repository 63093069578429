import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { AccessibilityAdminInterface } from '../../types/accebilityAdmin.interface';

type Hook = (
  setSubjects: (val: SetStateAction<AccessibilityAdminInterface[] | []>) => void
) => [boolean, boolean];

const requestAccessibilities = () =>
  Axios.get<AccessibilityAdminInterface[]>(`${API_URL}/accessibility`);

export const useAccessibilitiesAdmin: Hook = setAccessibilities => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: accessibilities } = await requestAccessibilities();

        setAccessibilities(accessibilities);
        setIsError(false);
      } catch (err) {
        setAccessibilities([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setAccessibilities]);

  return [isLoading, isError];
};
