import React, { useState, useEffect } from 'react';
import { Select, Option } from '../Select';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

import { makeStyles } from '@material-ui/styles';
import AnimatedError from '../AnimatedError/AnimatedError';
import { useDay, GetMonths, GetYears } from './getDate';
import moment from 'moment';

const useStyles = makeStyles({
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      flex: 1,
      margin: '0 0.5rem',
      '&:last-child': {
        marginRight: 0,
      },
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
});

interface BirthDateSelector extends LocalizeContextProps {
  onDateChange: (newDate: { year: string; month: string; day: string }) => void;
  isInvalid: boolean;
  reason?: string;
  initialValue?: Date;
  disable?: boolean;
}

function BirthDateSelector({
  translate,
  isInvalid,
  reason,
  onDateChange,
  initialValue,
  disable,
}: BirthDateSelector) {
  const classes = useStyles();

  const [currentDay, setCurrentDay] = useState<string | undefined>(undefined);
  const [currentMonth, setCurrentMonth] = useState<string | undefined>(
    undefined
  );
  const [currentYear, setCurrentYear] = useState<string | undefined>(undefined);

  const days = useDay(currentMonth, currentYear);
  const months: string[] = GetMonths();
  const years: number[] = GetYears();

  useEffect(() => {
    //Ao mudar o estado informar que a data mudou
    if (!currentDay && !currentMonth && !currentYear) return;

    onDateChange({
      year: currentYear || '',
      month: currentMonth || '',
      day: currentDay || '',
    });
  }, [currentDay, currentMonth, currentYear, onDateChange]);

  useEffect(() => {
    //permitir que o formulário defina uma data exteriormente
    if (!initialValue) return;

    const m = moment.utc(initialValue);

    setCurrentYear(m.year().toString());
    setCurrentMonth(m.month().toString());
    setCurrentDay(m.date().toString());
  }, [initialValue]);

  useEffect(() => {
    //Se o array de dias não inclui o dia escolhido reset para 1

    if (!currentDay) return;

    if (!days.includes(parseInt(currentDay || '1'))) {
      setCurrentDay('1');
    }
  }, [days, currentDay]);

  return (
    <>
      <div className={classes.selectContainer}>
        <Select
          placeholder={translate('register.day').toString()}
          onChange={v => setCurrentDay(v.toString())}
          value={currentDay}
          huge
          disabled={disable}
        >
          {days.map(value => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>

        <Select
          placeholder={translate('register.month').toString()}
          onChange={v => setCurrentMonth(v.toString())}
          value={currentMonth}
          huge
          disabled={disable}
        >
          {months.map((value, index) => (
            <Option key={value} value={index.toString()}>
              {value}
            </Option>
          ))}
        </Select>

        <Select
          placeholder={translate('register.year').toString()}
          onChange={v => setCurrentYear(v.toString())}
          value={currentYear}
          disabled={disable}
          huge
        >
          {years.reverse().map(value => (
            <Option key={value + 1} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
      <AnimatedError isVisible={isInvalid} reason={reason} />
    </>
  );
}

export default React.memo(withLocalize(BirthDateSelector));
