import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { Button as LinkButton } from '../../../../../../components/App/Button';
import { Error } from '../../../../../../components/App/Error';
import { FallbackImage } from '../../../../../../components/App/Image';
import { Loader } from '../../../../../../components/App/Loader';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { Text } from '../../../../../../components/Typography';
import {
  getHeaderImageUrl,
  GuideLandingPageInterface,
  useGuideLandingPage,
} from '../../../../../../hooks/administration/useGuideLandingPage';
import { postGuideLandingPage } from '../../../../../../hooks/administration/usePostGuideLandingPage';
import { UseJWT } from '../../../../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../../../../hooks/useFilePreview';
import { ReactComponent as Pediguia } from '../../../../../../shared_assets/images/pediguia_default.svg';
import { complexTranslate, maxAllowedImageSize } from '../../../../../../utils';
import { GenericPageTransition } from '../../../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  divider: {
    margin: '1rem 0',
  },
  titleDiv: {
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  avatarArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarPreview: {
    maxHeight: '25rem',
    marginBottom: '1rem',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  button: {
    marginLeft: '1rem',
  },
  tabContent: {
    overflow: 'auto',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

interface GuideLandingPageProps extends LocalizeContextProps {}
const GuideLandingPage = ({ languages, translate }: GuideLandingPageProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();

  const imageInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const previewSource = useMemo(() => [image], [image]);
  const imagePreview = useFilePreview(previewSource);
  const [landingPage, setLandingPage] = useState<GuideLandingPageInterface[]>(
    []
  );
  const [landingPageLocal, setLandingPageLocal] = useState<
    GuideLandingPageInterface[]
  >([]);
  const [fetchingLandingPageInfo, errorFetchingLandingPage] =
    useGuideLandingPage(setLandingPage);

  useEffect(() => {
    setLandingPageLocal(
      languages
        .map(la => la.code)
        .map(l => {
          const translation = landingPage.find(lp => lp.language === l);
          if (translation) {
            return translation;
          }
          return {
            firstTitle: '',
            thinCallout: '',
            intro: '',
            whatIsTitle: '',
            carouselText: '',
            areaTitle: '',
            subjectTitle: '',
            otherExperiencesTitle: '',
            lookForTitle: '',
            faqTitle: '',
            language: l,
          };
        })
    );
  }, [landingPage, languages]);
  const submit = useCallback(async () => {
    if (
      landingPageLocal.some(
        s =>
          validator.isEmpty(s.language) ||
          validator.isEmpty(s.carouselText) ||
          validator.isEmpty(s.subjectTitle) ||
          validator.isEmpty(s.whatIsTitle) ||
          validator.isEmpty(s.otherExperiencesTitle) ||
          validator.isEmpty(s.lookForTitle) ||
          validator.isEmpty(s.faqTitle)
      )
    ) {
      message.error(
        translate('administration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    await postGuideLandingPage(
      {
        landing: landingPageLocal.map(fs => {
          return {
            areaTitle: fs.areaTitle,
            carouselText: fs.carouselText,
            firstTitle: fs.firstTitle,
            intro: fs.intro,
            subjectTitle: fs.subjectTitle,
            thinCallout: fs.thinCallout,
            whatIsTitle: fs.whatIsTitle,
            lookForTitle: fs.lookForTitle,
            faqTitle: fs.faqTitle,
            otherExperiencesTitle: fs.otherExperiencesTitle,
            language: fs.language,
          };
        }),
      },
      image
    );
    message.success(
      translate('adminstration.landingPage.updateSuccessful').toString()
    );
  }, [landingPageLocal, image, translate]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }
  if (fetchingLandingPageInfo) {
    return <Loader />;
  }
  if (errorFetchingLandingPage) {
    return <Error />;
  }
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.guideLandingPage.title" />
        </Text>
      </div>
      <div className={classes.avatarArea}>
        <FallbackImage
          className={classes.avatarPreview}
          src={imagePreview?.[0] || getHeaderImageUrl()}
          fallbackComponent={<Pediguia className={classes.avatarPreview} />}
        />

        <div className={classes.changeAvatarButton}>
          <Translate id="adminstration.changeSubjectImage" />
          <input
            className={classes.button}
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <LinkButton
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </LinkButton>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <Tabs type="card" className={classes.tabContent}>
        {landingPageLocal.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.firstTitle" />
              </Text>
              <Input
                value={state.firstTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          firstTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              {/* <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.firstTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div> */}
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.intro" />
              </Text>
              <Input
                value={state.intro}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          intro: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              {/* <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.intro)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div> */}
              <Divider className={classes.divider} />
              {/* *******************ANCHOR experiencesTitle *********************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.thinCallout" />
              </Text>
              <Input
                value={state.thinCallout}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          thinCallout: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              {/* <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.thinCallout)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div> */}
              <Divider className={classes.divider} />
              {/* ***************ANCHOR featuredRoutesTitle*********************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.whatIsTitle" />
              </Text>
              <Input
                value={state.whatIsTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          whatIsTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.whatIsTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *************ANCHOR interestTitle **************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.carouselTexts" />
              </Text>
              <Input
                value={state.carouselText}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          carouselText: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.carouselText)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *************ANCHOR joinCommunityTitle ************************* */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.areaTitle" />
              </Text>
              <Input
                value={state.areaTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          areaTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.areaTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *****************ANCHOR call to action text************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.subjectTitle" />
              </Text>
              <Input
                value={state.subjectTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          subjectTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.subjectTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>

              {/* ************************************************** */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.lookForTitle" />
              </Text>
              <Input
                value={state.lookForTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          lookForTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.lookForTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.faqTitle" />
              </Text>
              <Input
                value={state.faqTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          faqTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.faqTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>

              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.guideLandingPage.otherExperiencesTitle" />
              </Text>
              <Input
                value={state.otherExperiencesTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          otherExperiencesTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.otherExperiencesTitle)}
                  reason={
                    <Translate id="adminstration.guideLandingPage.mandatoryField" />
                  }
                />
              </div>
            </TabPane>
          );
        })}
      </Tabs>
      <Divider className={classes.divider} />
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </motion.div>
  );
};

export default withLocalize(GuideLandingPage);
