import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import validator from 'validator';
import isTaxID from 'validator/lib/isTaxID';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { AnimatedInfo } from '../../../../../components/App/AnimatedInfo';
import { FallbackAvatar } from '../../../../../components/App/Avatar';
import { BirthDateSelector } from '../../../../../components/App/BirthDateSelector';
import {
  Button as AppButton,
  Button as LinkButton,
} from '../../../../../components/App/Button';
import { FallbackImage } from '../../../../../components/App/Image';
import {
  Input as AppInput,
  TextArea,
} from '../../../../../components/App/Input';
import { LanguageSelection } from '../../../../../components/App/LanguageSelection';
import { Loader } from '../../../../../components/App/Loader';
import { Option, Select } from '../../../../../components/App/Select';
import { SkillSelection } from '../../../../../components/App/SkillSelection';
import { TabPane, Tabs } from '../../../../../components/App/Tabs';
import { Heading, Text } from '../../../../../components/Typography';
import {
  createGuideAdmin,
  CreateGuideAdminDto,
} from '../../../../../hooks/administration/createGuideAdmin';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { useNifExist } from '../../../../../hooks/guide/useNifExist';
import { useSkills } from '../../../../../hooks/guide/UseSkills';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as Bill } from '../../../../../shared_assets/icons/bill.svg';
import { ReactComponent as Lock } from '../../../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../../../shared_assets/icons/unsee.svg';
import { ReactComponent as Upload } from '../../../../../shared_assets/icons/upload.svg';
import { ReactComponent as Pediguia } from '../../../../../shared_assets/images/pediguia_default.svg';
import { RootState } from '../../../../../store';
import { GenderType } from '../../../../../types/gender-type.enum';
import { GuideDescriptionDto } from '../../../../../types/guide.dtos';
import { Nationality } from '../../../../../types/nationality.enum';
import { RoleType } from '../../../../../types/role-type.enum';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  headerHeight,
  maxAllowedFileSize,
  maxAllowedImageSize,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

// ANCHOR: IMPORTS
interface ErrorInterface {
  error: boolean;
  reason: string;
}

const useStyles = makeStyles({
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: '25%',
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    flex: '1',
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },
  disableTextColor: {
    color: 'black !important',
  },
  divisorAvatar: {
    paddingBottom: '10px',
  },
  headerArea: {
    display: 'flex',
  },
  buttonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0.5',
  },
  avatarArea: {
    display: 'flex',
    height: '100px',
  },
  avatarPreview: {
    height: '100px',
    width: '100px',
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  select: {
    minWidth: '300px',
    color: 'black !important',
  },
  spacer: {
    margin: '0 1rem',
  },
  divSubmitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // ANCHOR: CSS
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  invisible: {
    display: 'none',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
});
interface SortedNationalities {
  key: string;
  translation: string;
}
interface Info {
  infoType?: 'success' | 'default' | 'error';
  iconSource?: string;
  phrase: string[];
}
export interface PasswordEntry {
  value: string;
  isInvalid: boolean;
  reason?: string;
  infos?: Info[];
  isVisible?: boolean;
}
interface ParamsInterface {
  id: string;
}
interface CreateGuideProps extends LocalizeContextProps {}
const CreateGuide = ({ translate }: CreateGuideProps) => {
  const classes = useStyles();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const params = useParams<ParamsInterface>();
  const [jwt] = UseJWT();

  let certificatesInputRef: HTMLInputElement | null = null;

  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const previewSource = useMemo(() => [avatar], [avatar]);
  const avatarPreview = useFilePreview(previewSource);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [localGuideFirstName, setLocalGuideFirstName] = useState('');
  const [localGuideLastName, setLocalGuideLastName] = useState('');
  const [localGuideNationality, setLocalGuideNationality] = useState('');
  const [localGuideAddress, setLocalGuideAddress] = useState('');
  const [localGuideLocation, setLocalGuideLocation] = useState('');
  const [localGuideFirstSection, setLocalGuideFirstSection] = useState('');
  const [localGuideLastSection, setLocalGuideLastSection] = useState('');
  const [localGuideBirthDate, setLocalBirthDate] = useState<Date>();
  const [localGuideEmail, setLocalGuideEmail] = useState('');
  const [localGuidePhoneNumber, setLocalGuidePhoneNumber] = useState('');
  const [localGuideGender, setLocalGuideGender] = useState('');
  const [localGuideCompanyName, setLocalGuideCompanyName] = useState('');
  const [localPassword, setLocalPassword] = useState('');
  const [localGuideIBAN, setLocalGuideIBAN] = useState('');
  const [localGuideNIF, setLocalGuideNIF] = useState('');
  const [certificates, setCertificates] = useState<File[]>([]);
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<PasswordEntry>({
    value: '',
    isInvalid: true,
  });
  const [descriptions, setDescriptions] = useState<GuideDescriptionDto[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  useSkills(activeLanguage);
  const sortedNationalities = useMemo(
    () =>
      Object.values(Nationality)
        .map(nat => {
          return {
            key: nat,
            translation: translate(`nationalities.${nat}`).toString(),
          };
        })
        .sort((a, b) => a.translation.localeCompare(b.translation)),
    [translate]
  );

  // ANCHOR: VARIABLES
  useEffect(() => {
    setLocalGuideFirstName('');
    setLocalGuideLastName('');
    setLocalGuideNationality(Object.values(Nationality)[0]);
    setLocalGuideAddress('');
    setLocalGuideLocation('');

    setLocalGuideFirstSection('');
    setLocalGuideLastSection('');
    setLocalBirthDate(moment().toDate());
    setLocalGuideEmail('');
    setLocalGuidePhoneNumber('');
    setLocalGuideGender(GenderType.OTHER);
    setLocalGuideCompanyName('');
    setLocalPassword('');
    setLocalGuideNIF('');
    setLocalGuideIBAN('');
    setCertificates([]);
    setDescriptions([]);
    setSelectedLanguages([]);
    setSelectedSkills([]);
    //EVALUATE
  }, []);
  //Quando mudam linguas cria descrições novas mas mantem as existentes
  useEffect(() => {
    setDescriptions(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, description: '' })),
    ]);
  }, [selectedLanguages]);
  const changeDate = useCallback(newDate => {
    setLocalBirthDate(newDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //** ERROR CATCH */
  const firstNameError = React.useMemo((): ErrorInterface => {
    if (localGuideFirstName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideFirstName]);
  const selectedLanguagesError = React.useMemo((): ErrorInterface => {
    if (selectedLanguages.length === 0) {
      return {
        error: true,
        reason: 'A language must be selected',
      };
    }
    return { error: false, reason: '' };
  }, [selectedLanguages]);

  const lastNameError = React.useMemo((): ErrorInterface => {
    if (localGuideLastName.length < 2) {
      return {
        error: true,
        reason: 'Name should be greater or equal to 2 characters',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideLastName]);
  const zipCode1Error = React.useMemo((): ErrorInterface => {
    if (localGuideFirstSection.length !== 4) {
      return {
        error: true,
        reason: 'ZipCode first section should have 4 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideFirstSection]);
  const zipCode2Error = React.useMemo((): ErrorInterface => {
    if (localGuideLastSection.length !== 3) {
      return {
        error: true,
        reason: 'ZipCode second section  should have 3 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideLastSection]);
  const addressError = React.useMemo((): ErrorInterface => {
    if (localGuideAddress === '') {
      return {
        error: true,
        reason: 'Address should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideAddress]);
  const locationError = React.useMemo((): ErrorInterface => {
    if (localGuideLocation === '') {
      return {
        error: true,
        reason: 'Location should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideLocation]);
  const companyNameError = React.useMemo((): ErrorInterface => {
    if (localGuideCompanyName === '') {
      return {
        error: true,
        reason: 'Company name should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideCompanyName]);

  const isRegisteredDto = useMemo(
    () => ({
      nif: localGuideNIF,
    }),
    [localGuideNIF]
  );

  const isRegistered = useNifExist(isRegisteredDto);
  const nifError = useMemo((): ErrorInterface => {
    if (isRegistered === true) {
      return {
        error: true,
        reason: 'NIF is already registered',
      };
    }
    if (!isTaxID(localGuideNIF, 'pt-PT')) {
      return {
        error: true,
        reason: 'NIF is invalid',
      };
    }
    return { error: false, reason: '' };
  }, [isRegistered, localGuideNIF]);
  const emailError = React.useMemo((): ErrorInterface => {
    if (!validator.isEmail(localGuideEmail)) {
      return {
        error: true,
        reason: 'Invalid email',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideEmail]);
  const birthDateError = React.useMemo((): ErrorInterface => {
    var guideAge = moment().diff(localGuideBirthDate, 'years', false);
    if (guideAge < 18) {
      return {
        error: true,
        reason: 'Guide is under age',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideBirthDate]);
  const phoneNumberError = React.useMemo((): ErrorInterface => {
    if (!validator.isMobilePhone(localGuidePhoneNumber)) {
      return {
        error: true,
        reason: 'Phone number must have 9 digits',
      };
    }
    return { error: false, reason: '' };
  }, [localGuidePhoneNumber]);
  const IBANError = React.useMemo((): ErrorInterface => {
    if (!validator.isIBAN(localGuideIBAN)) {
      return {
        error: true,
        reason: 'Bad phone number inserted',
      };
    }
    return { error: false, reason: '' };
  }, [localGuideIBAN]);

  const submitForm = useCallback(async () => {
    if (!jwt) return;
    if (localGuideBirthDate === undefined) return;
    if (
      firstNameError.error ||
      lastNameError.error ||
      zipCode1Error.error ||
      zipCode2Error.error ||
      addressError.error ||
      locationError.error ||
      emailError.error ||
      birthDateError.error ||
      phoneNumberError.error ||
      IBANError.error ||
      selectedLanguagesError.error ||
      newPassword.isInvalid ||
      nifError.error ||
      companyNameError
    ) {
      return;
    }

    try {
      setIsSubmitting(true);
      const createDto: CreateGuideAdminDto = {
        firstName: localGuideFirstName,
        lastName: localGuideLastName,
        nif: localGuideNIF,
        companyName: localGuideCompanyName,
        descriptions: descriptions,
        languages: selectedLanguages.map(l => ({ language: l, rating: 0 })),
        skills: selectedSkills.map(sk => ({ skillType: sk, rating: 0 })),
        address: localGuideAddress,
        postalCode: `${localGuideFirstSection}-${localGuideLastSection}`,
        location: localGuideLocation,
        phoneNumber: localGuidePhoneNumber,
        gender: localGuideGender as GenderType,
        iban: localGuideIBAN,
        nationality: localGuideNationality,
        dateOfBirth: localGuideBirthDate,
        agreeMarketing: true,
        agreeTerms: true,
        email: localGuideEmail,
        password: newPassword.value,
        type: RoleType.GUIDE,
        intermediateId: params.id,
      };
      await createGuideAdmin(createDto, avatar, certificates);

      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    jwt,
    localGuideBirthDate,
    firstNameError.error,
    lastNameError.error,
    zipCode1Error.error,
    zipCode2Error.error,
    addressError.error,
    locationError.error,
    emailError.error,
    birthDateError.error,
    phoneNumberError.error,
    IBANError.error,
    selectedLanguagesError.error,
    newPassword.isInvalid,
    newPassword.value,
    nifError.error,
    companyNameError,
    localGuideFirstName,
    localGuideLastName,
    localGuideNIF,
    localGuideCompanyName,
    descriptions,
    selectedLanguages,
    selectedSkills,
    localGuideAddress,
    localGuideFirstSection,
    localGuideLastSection,
    localGuideLocation,
    localGuidePhoneNumber,
    localGuideGender,
    localGuideIBAN,
    localGuideNationality,
    localGuideEmail,
    params.id,
    avatar,
    certificates,
    translate,
  ]);
  useMemo(() => {
    const formatStrength =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const hasRightLength =
      localPassword.length >= 8 && localPassword.length <= 20;
    const emailSplit =
      localGuideEmail.includes('@') && localGuideEmail.split('@');
    const isStrength = localPassword.match(formatStrength) ? true : false;
    const isContainNameEmail: boolean =
      (emailSplit &&
        localPassword.includes(emailSplit[0]) &&
        localGuideEmail !== '') ||
      localPassword === ''; //TODO: falta fazer validar o email

    const isInvalid: boolean = !(
      hasRightLength &&
      !isContainNameEmail &&
      isStrength
    );
    let infos: Info[] = [];

    if (isInvalid) {
      infos = [
        {
          infoType: 'error',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.weakPassword').toString(),
          ],
        },
        {
          infoType: !isContainNameEmail ? 'success' : 'default',
          phrase: [translate('register.passNoContainNameMail').toString()],
        },
        {
          infoType: hasRightLength ? 'success' : 'default',
          phrase: [translate('register.rightLengthPassword').toString()],
        },
        {
          infoType: isStrength ? 'success' : 'default',
          phrase: [translate('register.passwordRestrictions').toString()],
        },
      ];
    } else {
      infos = [
        {
          infoType: 'success',
          phrase: [
            translate('register.passwordStrengthInfo').toString(),
            translate('register.strongPassword').toString(),
          ],
        },
      ];
    }
    setNewPassword({
      value: localPassword,
      isInvalid,
      isVisible: true,
      infos,
    });
  }, [localPassword, localGuideEmail, translate]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.headerArea}>
          <Heading className={classes.title} level={2}>
            <Translate id="adminstration.createGuide" />
          </Heading>
        </div>

        <div className={classes.avatarArea}>
          <FallbackAvatar
            className={classes.avatarPreview}
            src={
              <FallbackImage
                src={avatarPreview?.[0] || undefined}
                fallback={require('../../../../../shared_assets/images/pediguia_default.svg')}
                fallbackComponent={<Pediguia />}
              />
            }
          />
          <div className={classes.changeAvatarButton}>
            <Translate id="profile.changePhoto" />
            <input
              type="file"
              accept="image/*"
              ref={avatarInputRef}
              onChange={ev => {
                if (
                  ev.currentTarget.files &&
                  ev.currentTarget.files.length > 0 &&
                  ev.currentTarget.files[0].size < maxAllowedImageSize
                ) {
                  setAvatar(ev.currentTarget.files[0]);
                } else {
                  message.info(
                    complexTranslate(
                      translate('error.imageFileSize').toString(),
                      {
                        '{linkTinyPNG}': (
                          <a key={'tinypnglink'} href="https://tinypng.com/">
                            <LinkButton
                              key="1"
                              type="link"
                              className={classNames(
                                classes.noMargin,
                                classes.inlineBtn
                              )}
                              onlyText
                            >
                              {translate('error.linkTinyPNG').toString()}
                            </LinkButton>
                          </a>
                        ),
                      }
                    )
                  );
                }
              }}
            />
          </div>
        </div>
        <div className={classes.divisorAvatar} />
        <div className={classes.bodyContainer}>
          <div className={classes.infoZone}>
            {/* NAME */}
            <Text noMargin weight="semibold">
              <Translate id="profile.firstName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuideFirstName}
              onChange={ev => {
                setLocalGuideFirstName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={firstNameError.error}
              reason={firstNameError.reason}
            />
            <Text noMargin weight="semibold">
              <Translate id="profile.lastName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuideLastName}
              onChange={ev => {
                setLocalGuideLastName(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={lastNameError.error}
              reason={lastNameError.reason}
            />
            <Divider className={classes.divider} />
            {/* NACIONALIDADE */}
            <Text weight="semibold">
              <Translate id="profile.nationality" />
            </Text>
            <Select
              className={classes.select}
              onChange={v => setLocalGuideNationality(v.toString())}
              huge
              value={localGuideNationality || undefined}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>
            <Divider className={classes.divider} />
            {/* ADDRESS INFO */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.residence" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuideAddress}
              onChange={ev => {
                setLocalGuideAddress(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={addressError.error}
              reason={addressError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.location" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuideLocation}
              onChange={ev => {
                setLocalGuideLocation(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={locationError.error}
              reason={locationError.reason}
            />
            {/* SPACE ADD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.postalCode" />
            </Text>
            <div className={classes.row}>
              <Input
                className={classes.disableTextColor}
                type="number"
                placeholder="XXXX"
                onChange={ev => {
                  setLocalGuideFirstSection(ev.currentTarget.value);
                }}
                value={localGuideFirstSection}
              />

              <span className={classes.spacer}>-</span>
              <Input
                className={classes.disableTextColor}
                placeholder="XXX"
                type="number"
                onChange={ev => {
                  setLocalGuideLastSection(ev.currentTarget.value);
                }}
                value={localGuideLastSection}
              />
            </div>
            <AnimatedError
              isVisible={zipCode1Error.error}
              reason={zipCode1Error.reason}
            />
            <AnimatedError
              isVisible={zipCode2Error.error}
              reason={zipCode2Error.reason}
            />
            <Divider className={classes.divider} />
            {/* EMAIL */}
            <Text noMargin weight="semibold">
              <Translate id="profile.email" />
            </Text>
            <Input
              type={'email'}
              className={classes.disableTextColor}
              value={localGuideEmail}
              onChange={ev => {
                setLocalGuideEmail(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={emailError.error}
              reason={emailError.reason}
            />
            {/* PASSWORD */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.password" />
            </Text>
            <div className={classes.centeredRow}>
              <AppInput
                huge
                type={isPasswordShowing ? 'text' : 'password'}
                placeholder={translate('register.password').toString()}
                onChange={ev => setLocalPassword(ev.target.value)}
                value={localPassword}
                prefix={<Lock className={classes.icon} />}
                suffix={
                  isPasswordShowing ? (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <See className={classes.icon} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setIsPasswordShowing(prevState => !prevState);
                      }}
                    >
                      <Unsee className={classes.icon} />
                    </div>
                  )
                }
              />
            </div>
            <AnimatedInfo
              isVisible={newPassword.isVisible ? newPassword.isVisible : false}
              infos={newPassword.infos}
            />
            <Divider className={classes.divider} />
            {/* B-DAY */}
            <Text weight="semibold">
              <Translate id="profile.dateOfBirth" />
            </Text>
            <BirthDateSelector
              onDateChange={changeDate}
              isInvalid={true}
              reason={''}
              initialValue={localGuideBirthDate}
            />
            <AnimatedError
              isVisible={birthDateError.error}
              reason={birthDateError.reason}
            />
            <Divider className={classes.divider} />
            {/* PHONE NUMBER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.phoneNumber" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuidePhoneNumber}
              onChange={ev => {
                setLocalGuidePhoneNumber(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={phoneNumberError.error}
              reason={phoneNumberError.reason}
            />
            <Divider className={classes.divider} />
            {/* GENDER */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.gender" />
            </Text>
            <Select
              className={classes.select}
              defaultValue={undefined}
              onChange={v => setLocalGuideGender(v.toString())}
              value={localGuideGender || undefined}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  key={gender}
                  huge
                  value={gender}
                  label={translate(`genders.${gender}`).toString()}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <Divider className={classes.divider} />
            {/* COMPANY NAME */}
            <Text weight="semibold" noMargin>
              <Translate id="adminstration.companyName" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuideCompanyName}
              onChange={ev => {
                setLocalGuideCompanyName(ev.target.value);
              }}
            ></Input>

            <AnimatedError
              isVisible={companyNameError.error}
              reason={companyNameError.reason}
            />
            {/* IBAN */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.iban" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuideIBAN}
              onChange={ev => {
                setLocalGuideIBAN(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={IBANError.error}
              reason={IBANError.reason}
            />
            {/* NIF */}
            <Text weight="semibold" noMargin>
              <Translate id="profile.nif" />
            </Text>
            <Input
              className={classes.disableTextColor}
              value={localGuideNIF}
              onChange={ev => {
                setLocalGuideNIF(ev.target.value);
              }}
            ></Input>
            <AnimatedError
              isVisible={nifError.error}
              reason={nifError.reason}
            />
            <Divider className={classes.divider} />
            {/* DESCRIPTION */}
            <Text weight="semibold">
              <Translate id="profile.profileDescription" />
            </Text>
            <Tabs>
              {descriptions.map(iteratedLanguage => (
                <TabPane
                  key={iteratedLanguage.language}
                  tab={translate(`languages.${iteratedLanguage.language}`)}
                >
                  <TextArea
                    value={iteratedLanguage.description}
                    rows={10}
                    onChange={ev => {
                      ev.persist();
                      setDescriptions(previousDescriptions =>
                        previousDescriptions.map(oldDescription => {
                          if (
                            oldDescription.language ===
                            iteratedLanguage.language
                          )
                            return {
                              ...oldDescription,
                              description: ev.target.value,
                            };
                          else return oldDescription;
                        })
                      );
                    }}
                  />
                  <div>
                    <AnimatedError
                      isVisible={validator.isEmpty(
                        iteratedLanguage.description
                      )}
                      reason={<Translate id="profile.bioMandatory" />}
                    />
                  </div>
                </TabPane>
              ))}
            </Tabs>
            <Divider className={classes.divider} />
            {/* LANGS */}
            <Text weight="semibold">
              <Translate id="profile.spokenLanguages" />
            </Text>
            <LanguageSelection
              onChange={setSelectedLanguages}
              value={selectedLanguages}
            />
            {/* ANCHOR : ALGUAGE ERROR */}
            <AnimatedError
              isVisible={selectedLanguagesError.error}
              reason={selectedLanguagesError.reason}
            />
            <Divider className={classes.divider} />
            <Text weight="semibold">
              <Translate id="profile.skills" />
            </Text>
            <SkillSelection
              // skills={remoteSkills}
              value={selectedSkills}
              onChange={setSelectedSkills}
            />
            <Divider className={classes.divider} />
            <div>
              <Heading level={5} className={classes.titleMargin}>
                <Translate id="onboarding.certificates" />
              </Heading>
              <input
                multiple
                ref={ref => (certificatesInputRef = ref)}
                onChange={ev => {
                  if (!ev.currentTarget.files) {
                    setCertificates([]);
                    return;
                  }

                  let fileArray: File[] = [];

                  for (let i = 0; i < ev.currentTarget.files.length; i++) {
                    if (
                      ev.currentTarget.files[i] &&
                      ev.currentTarget.files[i].size > maxAllowedFileSize
                    ) {
                      fileArray = [];
                      break;
                    } else {
                      fileArray.push(ev.currentTarget.files[i]);
                    }
                  }
                  setCertificates(fileArray);
                }}
                type="file"
                className={classes.invisible}
              />
              <div className={classes.fileContainer}>
                {certificates.map(c => (
                  <div className={classes.file}>
                    <div className={classes.fileIconContainer}>
                      <Bill />
                    </div>
                    <div className={classes.textContainer}>
                      <Text>{c.name}</Text>
                      <span>{c.size}</span>
                    </div>
                  </div>
                ))}
              </div>
              <AppButton
                className={classes.marginTop}
                type="ghost"
                onClick={() => {
                  if (certificatesInputRef) certificatesInputRef.click();
                }}
                prefix={<Upload className={classes.icon} />}
              >
                <Translate id="onboarding.uploadCertificates" />
              </AppButton>
              <Text
                className={classNames(
                  classes.textAlignInitial,
                  classes.marginTop
                )}
              >
                <Translate id="onboarding.certificateSize" />
              </Text>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.divSubmitButton}>
              <Button
                type="primary"
                shape="round"
                size={'large'}
                onClick={ev => {
                  submitForm();
                }}
              >
                <Translate id="adminstration.submitButton" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(CreateGuide);
