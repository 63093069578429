import decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/index';
import { RoleType } from '../../types/role-type.enum';

export interface JwtToken {
  id: string;
  email: string;
  guideId: string;
  visitorId: string;
  roles: string[];
  actions: string[];
  intermediateId: string;
  adOwnerId: string;
  companyId: string;
  loggedType: RoleType;
}

export const UseJWT = (): [JwtToken | null] => {
  const jwt = useSelector((state: RootState) => state.userConfiguration.jwt);

  try {
    if (jwt) return [decode(jwt)];
    else return [null];
  } catch (err) {
    return [null];
  }
};
