import { makeStyles } from '@material-ui/styles';
import message from 'antd/es/message';
import Axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  withLocalize,
} from 'react-localize-redux';
import { useLocation } from 'react-router-dom';
import { AnimatedAlert } from '../../../components/Administration/AnimatedAlert';
import { CompanyLoginForm } from '../../../components/Administration/CompanyLoginForm';
import { FallbackImage } from '../../../components/App/Image';
import { API_URL } from '../../../consts';
import { LogoUrl } from '../../../hooks/customization/getLogo';
import { store } from '../../../store';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  img: {
    maxWidth: '30%',
    objectFit: 'contain',
  },
  h1: {
    color: '#009CDE',
  },
  userArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundColor: '#ADD8E6',
    paddingTop: '4rem',
    height: '100vh',
    width: '100vw',
  },
});

interface CompanyLandingProps extends LocalizeContextProps {}
interface RouteState {
  signUp: '';
}
enum PrefixType {
  signUp = 'signUp',
  token = 'token',
}
interface QueryStringInterface {
  prefix: PrefixType | undefined;
  value: string | undefined;
}
const getQueryStringValues = (location: any): QueryStringInterface => {
  if (location.search) {
    const queryString = location.search.split('?')[1];
    let value = undefined;
    let prefix = undefined;

    if (queryString.split('=')[0] === PrefixType.signUp) {
      prefix = PrefixType.signUp;
      value = queryString.split('=')[1];
    } else if (queryString.split('=')[0] === PrefixType.token) {
      prefix = PrefixType.token;
      value = queryString.split('=')[1];
    }
    return { prefix, value };
  } else {
    return {
      prefix: undefined,
      value: undefined,
    };
  }
};
function CompanyLanding() {
  const classes = useStyles();
  const location = useLocation<RouteState | undefined>();
  const translate = getTranslate(store.getState().localize);

  const queryString: QueryStringInterface = getQueryStringValues(location);
  const [confirmingEmail, setConfirmingEmail] = useState<boolean>(false);

  useMemo(async () => {
    if (!queryString.prefix || !queryString.value) {
      return;
    }
    if (queryString.prefix === PrefixType.signUp) {
      return;
    }
    if (confirmingEmail === true) return;
    try {
      setConfirmingEmail(true);
      await Axios.patch<any>(
        `${API_URL}/authentication/confirm-email/${queryString.value}/company`
      );
      message.success(
        translate('company.apiResponses.emailConfirmed').toString()
      );
    } catch (err) {
      if (err.response.data.message === 'Email Already confirmed') {
        message.error(
          translate('company.apiResponses.emailAlreadyConfirmed').toString()
        );
      } else {
        message.error(
          translate('company.apiResponses.emailNotConfirmed').toString()
        );
      }
    }
  }, [confirmingEmail, queryString.prefix, queryString.value, translate]);
  useEffect(() => {
    document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      {queryString.prefix === PrefixType.signUp &&
      queryString.value === 'success' ? (
        <div className={classes.body}>
          <AnimatedAlert
            visible
            message={translate('company.apiResponses.createSuccess')}
            type="success"
            showIcon
          />
        </div>
      ) : null}
      <div className={classes.mainContainer}>
        <div className={classes.title}>
          <FallbackImage
            src={LogoUrl}
            fallback={require('../../../components/App/Header/assets/logo_cor.png')}
            className={classes.img}
            alt=""
          />
          <h1 className={classes.h1}>EMPRESAS</h1>
        </div>

        <div className={classes.userArea}>
          <CompanyLoginForm />
        </div>
      </div>
    </>
  );
}

export default withLocalize(CompanyLanding);
