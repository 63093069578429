import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Empty from 'antd/es/empty';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { Link, useParams } from 'react-router-dom';
import { Badge } from '../../../components/App/Badge';
import { Button } from '../../../components/App/Button';
import { Error } from '../../../components/App/Error';
import { FallbackImage } from '../../../components/App/Image';
import { Loader } from '../../../components/App/Loader';
import { PointDescriptionList } from '../../../components/App/PointDescriptionList';
import { RoutePreviewList } from '../../../components/App/RoutePreviewList';
import { Option, Select } from '../../../components/App/Select';
import { ShareHeader } from '../../../components/App/Share';
import { VisitorComment } from '../../../components/App/VisitorComment';
import { Heading, Text } from '../../../components/Typography';
import { getGuideImageUrl } from '../../../hooks/guide/UseGuide';
import { useGuidePublicRoutes } from '../../../hooks/guide/UseGuidePublicRoutes';
import { usePublicGuide } from '../../../hooks/guide/UsePublicGuide';
import { useRouteReview } from '../../../hooks/routes/review/UseRouteReviews';
import {
  getRouteLocationImageUrl,
  useRoute,
} from '../../../hooks/routes/UseRoute';
import {
  PartialRouteInterface,
  useSimilarRoutes,
} from '../../../hooks/routes/UseSimilarRoutes';
import { useRouteSchedulesPublic } from '../../../hooks/schedule/UseRouteSchedulesPublic';
import { ReactComponent as Age } from '../../../shared_assets/icons/age.svg';
import { ReactComponent as Difficulty } from '../../../shared_assets/icons/difficulty.svg';
import { ReactComponent as Group } from '../../../shared_assets/icons/group.svg';
import { ReactComponent as Language } from '../../../shared_assets/icons/language.svg';
import { ReactComponent as RouteIcon } from '../../../shared_assets/icons/route.svg';
import { ReactComponent as StarWhite } from '../../../shared_assets/icons/star white.svg';
import { ReactComponent as StarYellow } from '../../../shared_assets/icons/star_yellow.svg';
import { ReactComponent as Time } from '../../../shared_assets/icons/time.svg';
import { ReactComponent as Wheelchair } from '../../../shared_assets/icons/wheelchair.svg';
import { ReactComponent as Pediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { RootState } from '../../../store';
import { FilterRoute } from '../../../types/filter-route.dto';
import { GuidePublicProfile } from '../../../types/guide-public-profile.interface';
import { Review } from '../../../types/review.interface';
import { RouteStateType } from '../../../types/route-state.enum';
import { Route } from '../../../types/route.interface';
import { Schedule } from '../../../types/schedule-new';
import { Title } from '../../../types/title.interface';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  joinWithAnd,
  mobilePadding,
  mobileThreshhold,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import { RouteDatesModal } from '../Dates';
import DatePreview from './DatePreview';
import LocationsMap from './LocationsMap';

//TODO: ROUTE PRICE
const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: 76,
  },
  headerMenu: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  headerBody: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '30rem',
  },
  carousel: () => ({
    '& .slide': {
      pointerEvents: 'none',
    },
    '& .control-dots': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: 'unset !important',
      justifyContent: 'center',
      right: 0,
      '& > *': {
        height: 8,
        width: 8,
        margin: '8px 1rem',
        borderRadius: '100%',
        borderWidth: 2,
        backgroundColor: 'transparent',
        display: 'inline-block',
        borderColor: 'white',
        borderStyle: 'solid',
        cursor: 'pointer',
        '&.active': {
          backgroundColor: getTheme().primary,
          borderColor: getTheme().primary,
        },
      },
    },
  }),
  carouselImage: {
    height: '30rem',
    objectFit: 'cover',
    objectPosition: 'center',
    pointerEvents: 'all !important' as 'all',
  },
  headerTextBody: {
    position: 'absolute',
    bottom: 0,
    padding: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    ...mobilePadding,
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
  },
  selectContainer: () => ({
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-select-selector': {
      backgroundColor: `${getTheme().primary} !important`,
      border: `1px solid ${getTheme().primary} !important`,
      color: 'white',
    },
    '& .ant-select-arrow': {
      color: 'white',
    },
  }),
  personalInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  remainingInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  profilePicture: {
    maxWidth: '12rem',
    maxHeight: '12rem',
    borderRadius: '5px',
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    height: 76,
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
    backgroundColor: '#f1f5f7',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 999,
    padding: '1rem',
  },
  marginBetween: {
    marginTop: '1.5rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
  statsContainer: {
    marginTop: '1rem',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(150px, 1fr))`,
    gridAutoRows: '1fr',
    columnGap: '1rem',
  },
  statItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    marginBottom: '0.5rem',
  },
  dateButton: {
    // marginTop: '1rem',
  },
  '& .ant-picker td': {
    pointerEvents: 'none',
  },
  bold: {
    fontWeight: `${600} !important` as unknown as number,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  marginFooter: {
    margin: '2rem 0 4rem 0',
  },

  starRating: {
    margin: '0 2px',
    height: 10,
    width: 10,
  },
  blue: () => ({
    color: `${getTheme().primary} !important`,
    marginLeft: '1rem',
  }),
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      ...desktopPadding,
    },

    headerTextBody: {
      ...desktopPadding,
    },
    selectContainer: {
      alignSelf: 'center',
      width: '30rem',
    },
    personalInformation: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      '& > :first-child': {
        flex: 0.3,
        marginRight: '2rem',
      },
      '& > :last-child': {
        flex: 0.7,
      },
    },
    remainingInformation: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      '& > :first-child': {
        flex: 0.4,
        marginRight: '2rem',
      },
      '& > :last-child': {
        flex: 0.6,
      },
    },
    map: {
      height: 400,
    },
    marginBetween: {
      marginTop: '3rem',
    },
    statsContainer: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
});
interface RouteParams {
  routeId: string;
}

interface RouteDetailsProps extends LocalizeContextProps {
  openLogin: () => void;
}
const getIsPublic = (): boolean => {
  const queryString = window.location.search.substring(1);
  return !(qs.parse(queryString)?.private === 'true');
};

function RouteDetails({ translate, openLogin }: RouteDetailsProps) {
  const { routeId } = useParams<RouteParams>();
  const isPublic: boolean = getIsPublic();
  const classes = useStyles();
  const monthLimit = 5;
  const pageActiveLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [activeLanguage, setActiveLanguage] = useState<string | undefined>(
    undefined
  );
  const [route, setRoute] = useState<Route | null>(null);
  const [guide, setGuide] = useState<GuidePublicProfile | null>(null);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [isLoadingRoute, hasErrorRoute] = useRoute(routeId, setRoute, isPublic);
  const [isLoadingGuide, hasErrorGuide] = usePublicGuide(
    route?.guideId,
    setGuide
  );
  const [isLoadingSchedules, hasErrorSchedules] = useRouteSchedulesPublic(
    routeId,
    setSchedules
  );
  const [isLoadingReviews, hasErrorReviews] = useRouteReview(
    routeId,
    setReviews
  );

  const [guideRoutes, setGuideRoutes] = useState<PartialRouteInterface[]>([]);
  const [isLoadingGuideRoutes, hasErrorGuideRoutes] = useGuidePublicRoutes(
    route?.guideId,
    setGuideRoutes
  );

  const [similarRoutes, setSimilarRoutes] = useState<PartialRouteInterface[]>(
    []
  );
  const [filterRoute, setFilterRoute] = useState<FilterRoute | null>(null);
  const [isLoadingSimilarRoutes, hasErrorSimilarRoutes] = useSimilarRoutes(
    filterRoute,
    setSimilarRoutes
  );

  const getRouteTitle = useCallback(
    (titles: Title[]) => {
      const translation = titles.find(t => t.language === activeLanguage);
      return translation ? translation.title : titles[0].title;
    },
    [activeLanguage]
  );

  const [isShowingDateModal, setIsShowingDateModal] = useState(false);
  useEffect(() => {
    if (!route) return;

    setActiveLanguage(route.languages[0]);
  }, [route]);
  const photosLocations = useMemo(
    () =>
      route
        ? route.locations
            .filter(location => {
              return location.photos.length > 0;
            })
            .map(location => {
              return location.photos.map(photo => photo.id);
            })
            .flat()
        : [],
    [route]
  );

  const schedulesRoute = useMemo(
    () =>
      schedules
        .map(schedule =>
          schedule.repetitions.map(
            repetitionSchedule => repetitionSchedule.date
          )
        )
        .flat(),
    [schedules]
  );
  const allSimilarRoutes = useMemo(() => {
    return similarRoutes
      .filter(route => route.id !== routeId)
      .map(route => {
        return {
          id: route.id,
          place: route.location,
          name: getRouteTitle(route.titles),
          price: route.minPrice,
          rating: route.averageRating,
          numberOfRatings: route.numberOfRatings,
          image: getRouteLocationImageUrl(
            route.locations.map(l => l.photos.map(p => p.id)).flat()[0]
          ),
        };
      });
  }, [similarRoutes, routeId, getRouteTitle]);

  const allOtherGuideRoutes = useMemo(() => {
    return guideRoutes
      .filter(route => route.id !== routeId)
      .map(route => {
        return {
          id: route.id,
          place: route.location,
          name: getRouteTitle(route.titles),
          price: route.minPrice,
          rating: route.averageRating,
          numberOfRatings: route.numberOfRatings,
          image: getRouteLocationImageUrl(
            route.locations.map(l => l.photos.map(p => p.id)).flat()[0]
          ),
        };
      });
  }, [getRouteTitle, guideRoutes, routeId]);

  useEffect(() => {
    if (!route) return;

    setFilterRoute({
      locations: [route.location],
      subjectsIds: route.subjects.map(subject => subject.id),
    });
  }, [route]);

  const activities = useMemo(
    () =>
      !route
        ? []
        : route.activities.map(act => {
            return {
              name:
                act.descriptions.find(desc => desc.language === activeLanguage)
                  ?.description || act.descriptions[0].description,
              price: act.price,
            };
          }),
    [route, activeLanguage]
  );

  const items = useMemo(
    () =>
      !route
        ? []
        : route.extraItems.map(item => {
            return {
              name:
                item.descriptions.find(desc => desc.language === activeLanguage)
                  ?.description || item.descriptions[0].description,
            };
          }),
    [route, activeLanguage]
  );

  const pointDescriptions = useMemo(
    () =>
      !route
        ? []
        : route.locations
            .sort((a, b) => a.order - b.order)
            .map((location, id) => {
              let position = 'middle';
              if (id === 0) {
                position = 'start';
              } else if (id === route.locations.length - 1) {
                position = 'end';
              }
              return {
                title: location.address,
                description: location.descriptions.find(
                  descriptionLanguage =>
                    descriptionLanguage.language === activeLanguage
                )?.description,
                locationTitle: location.titles.find(
                  titleLanguage => titleLanguage.language === activeLanguage
                )?.title,
                position: position,
              };
            }),
    [route, activeLanguage]
  );
  const closeDatesModal = useCallback(() => setIsShowingDateModal(false), []);

  if (
    isLoadingRoute ||
    isLoadingGuide ||
    isLoadingSchedules ||
    isLoadingReviews
  ) {
    return <Loader />;
  }

  if (
    hasErrorRoute ||
    !route ||
    hasErrorGuide ||
    !guide ||
    hasErrorSchedules ||
    !schedules ||
    hasErrorReviews ||
    !reviews
  ) {
    return <Error title={translate('error.basic').toString()} />;
  }
  return (
    <>
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        className={classes.page}
        variants={GenericPageTransition}
      >
        <div className={classNames(classes.headerBody)}>
          <div className={classes.headerMenu}>
            <ShareHeader
              title={route.titles[0] ? route.titles[0].title : ''}
              routeId={routeId}
            />
          </div>

          {photosLocations.length > 0 ? (
            <Carousel
              className={classes.carousel}
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              autoPlay={true}
              renderIndicator={(onClick, isSelected) => (
                <div
                  onClick={onClick}
                  className={isSelected ? 'active' : undefined}
                />
              )}
              infiniteLoop
              axis="vertical"
            >
              {photosLocations.map((photoId, i) => (
                <FallbackImage
                  key={photoId}
                  src={getRouteLocationImageUrl(photoId)}
                  className={classes.carouselImage}
                  fallback={require('../../../shared_assets/images/route-default.png')}
                />
              ))}
            </Carousel>
          ) : (
            <FallbackImage
              src={require('../../../shared_assets/images/route-default.png')}
              className={classes.carouselImage}
            />
          )}
          <div className={classes.headerTextBody}>
            <Heading color="white" level={6} className={classes.uppercase}>
              {route.location}
            </Heading>
            <Heading
              color={'#FFFFFF'}
              ellipsis={{ rows: 4 }}
              noMargin
              level={3}
            >
              {getRouteTitle(route.titles)}
            </Heading>
            <Text color="white" className={classes.alignItems}>
              <span className={classes.bold}>{route.averageRating}</span>
              <StarWhite className={classes.starRating} />
              <span>({route.numberOfRatings})</span>
            </Text>
            {route.subjects.map((subject, i) => (
              <span key={i} style={{ marginRight: '0.3rem' }}>
                <Badge key={i}>
                  {subject.translations?.find(
                    t => t.language === pageActiveLanguage
                  )?.translation || 'TRANSLATION NOT FOUND'}
                </Badge>
              </span>
            ))}
          </div>
        </div>
        <div className={classes.container}>
          {route.languages.length > 1 && (
            <div className={classes.selectContainer}>
              <Select
                huge
                value={activeLanguage}
                onChange={value => {
                  setActiveLanguage(value.toString());
                }}
                placeholder={translate('routeDetails.seeOtherLanguage')
                  .toString()
                  .toUpperCase()}
              >
                {route.languages.map((language, i) => (
                  <Option
                    key={i}
                    value={language.toString()}
                    label={translate(
                      `languages.${language.toString()}`
                    ).toString()}
                  >
                    {/* <Text color={'#FFFFFF'} noMargin> */}
                    <Translate id={`languages.${language.toString()}`} />
                    {/* </Text> */}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          <div className={classes.personalInformation}>
            <div className={classes.marginBetween}>
              <Heading level={2}>
                <Translate id="routeDetails.yourGuide" />
              </Heading>
              <FallbackImage
                className={classes.profilePicture}
                fallbackComponent={
                  <Pediguia className={classes.profilePicture} />
                }
                alt="guide_image"
                src={getGuideImageUrl(guide.id)}
              />
              <Heading
                level={4}
                className={classNames(
                  classes.capitalize,
                  classes.marginTop,
                  classes.bold
                )}
              >
                {`${guide.firstName} ${guide.lastName}`}
              </Heading>
              <Text variant="faded">
                {guide.descriptions && guide.descriptions.length > 0
                  ? guide.descriptions.find(
                      descriptionLanguage =>
                        descriptionLanguage.language === activeLanguage
                    )?.description
                  : ''}
              </Text>
              <Link to={`/guide/${guide.id}`}>
                <Button type="link" size="large" onlyText>
                  <Translate id="routeDetails.seeProfile" />
                </Button>
              </Link>
            </div>
            <div>
              <div className={classes.marginBetween}>
                <Heading level={2}>
                  <Translate id="routeDetails.willDo" />
                </Heading>
                <Text variant="faded" noMargin>
                  {
                    route.descriptions.find(
                      descriptionLanguage =>
                        descriptionLanguage.language === activeLanguage
                    )?.description
                  }
                </Text>
              </div>
              <div className={classNames(classes.statsContainer)}>
                <div className={classNames(classes.statItem)}>
                  <Time className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.duration" />
                  </Text>
                  <Text variant="faded">
                    {translate('routeDetails.durationInfo')
                      .toString()
                      .replace('{number}', (route.duration / 60).toString())}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Group className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.visitors" />
                  </Text>
                  <Text variant="faded">
                    {translate('routeDetails.visitorsInfo')
                      .toString()
                      .replace('{number}', route.groupSize.toString())}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Language className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.language" />
                  </Text>
                  <Text variant="faded">
                    {joinWithAnd(
                      route.languages.map(language =>
                        translate(`languages.${language}`).toString()
                      ),
                      translate('and').toString()
                    )}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Difficulty className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.difficulty" />
                  </Text>
                  <Text variant="faded">
                    {translate(`difficulties.${route.difficulty}`).toString()}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <RouteIcon className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.routeExtension" />
                  </Text>
                  <Text variant="faded">
                    {translate('routeDetails.routeExtensionInfo')
                      .toString()
                      .replace(
                        '{number}',
                        route.routeLength.toFixed(2).toString()
                      )}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Age className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.groupAge" />
                  </Text>
                  <Text variant="faded">
                    {translate(`groupAgeType.${route.groupAge}`).toString()}
                  </Text>
                </div>
                {route.accessibilities.length > 0 && (
                  <div className={classNames(classes.statItem)}>
                    <Wheelchair className={classes.icon} />
                    <Text weight="bold">
                      <Translate id="routeDetails.accessibilityConditions" />
                    </Text>
                    <Text variant="faded" noMargin>
                      {joinWithAnd(
                        route.accessibilities.map(
                          acc =>
                            acc.translations?.find(
                              rAcc => rAcc.language === pageActiveLanguage
                            )?.translation || 'NO TRANSLATION FOUND'
                        ),
                        translate('and').toString()
                      )}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.marginBetween}>
            <Heading level={2}>
              <Translate id="routeDetails.experienceLocation" />
            </Heading>
            <div>
              <LocationsMap locations={route.locations} />
            </div>
          </div>
          <div
            className={classNames(
              classes.marginBetween,
              classes.remainingInformation
            )}
          >
            <div>
              <Heading level={2}>
                <Translate id="routeDetails.placeStart" />
              </Heading>
            </div>
            <div>
              <PointDescriptionList
                data={pointDescriptions.filter(pd => pd.position === 'start')}
              />
            </div>
          </div>
          {pointDescriptions.some(pd => pd.position === 'middle') && (
            <div
              className={classNames(
                classes.marginBetween,
                classes.remainingInformation
              )}
            >
              <div>
                <Heading level={2}>
                  <Translate id="routeDetails.placesVisit" />
                </Heading>
              </div>
              <div>
                <PointDescriptionList
                  data={pointDescriptions.filter(
                    pd => pd.position === 'middle'
                  )}
                />
              </div>
            </div>
          )}

          <div
            className={classNames(
              classes.marginBetween,
              classes.remainingInformation
            )}
          >
            <div>
              <Heading level={2}>
                <Translate id="routeDetails.placeEnd" />
              </Heading>
            </div>
            <div>
              <PointDescriptionList
                data={pointDescriptions.filter(pd => pd.position === 'end')}
              />
            </div>
          </div>

          {items.length > 0 && (
            <div
              className={classNames(
                classes.marginBetween,
                classes.remainingInformation
              )}
            >
              <div>
                <Heading level={2}>
                  <Translate id="routeDetails.extraItems" />
                </Heading>
              </div>
              <div>
                {items.map((item, i) => (
                  <Heading key={i.toString()} style={{ marginTop: '1rem' }}>
                    {item.name}
                  </Heading>
                ))}
              </div>
            </div>
          )}
          {activities.length > 0 && (
            <div
              className={classNames(
                classes.marginBetween,
                classes.remainingInformation
              )}
            >
              <div>
                <Heading level={2}>
                  <Translate id="routeDetails.extraActivities" />
                </Heading>
              </div>
              <div>
                {activities.map((act, i) => (
                  <Heading
                    key={i.toString()}
                    style={{ marginTop: '1rem' }}
                  >{`${act.name} (${act.price}€)`}</Heading>
                ))}
              </div>
            </div>
          )}
          {isPublic && (
            <div
              className={classNames(
                classes.marginBetween,
                classes.remainingInformation
              )}
            >
              <div>
                <Heading level={2}>
                  <Translate id="routeDetails.availableDates" />
                </Heading>
              </div>
              <div>
                <RouteDatesModal
                  monthLimit={monthLimit}
                  routeId={routeId}
                  price={route.adultPrice}
                  duration={route.duration}
                  isVisible={isShowingDateModal}
                  onClose={closeDatesModal}
                  openLogin={openLogin}
                />
                <DatePreview
                  schedules={schedulesRoute}
                  monthLimit={monthLimit}
                />
                {route.state === RouteStateType.APPROVED ? (
                  <Button
                    type="primary"
                    className={classNames(classes.dateButton)}
                    onClick={() => setIsShowingDateModal(true)}
                  >
                    <Translate id="routeDetails.showDates" />
                  </Button>
                ) : null}
              </div>
            </div>
          )}
          <div
            className={classNames(
              classes.marginBetween,
              classes.remainingInformation
            )}
          >
            <div>
              <Heading level={2}>
                <Translate id="routeDetails.comments" />
              </Heading>
            </div>
            <div>
              {reviews.length > 0 ? (
                <>
                  {reviews.map((review, i) => (
                    <div
                      style={{ margin: '1.5rem 0 0.7rem 0' }}
                      key={review.id}
                    >
                      <VisitorComment
                        visitorId={review.visitorId}
                        commentText={review.description}
                        visitorName={review.visitorName}
                        date={review.dateOfCreation}
                        rating={review.rating}
                      />
                      {
                        //Last comment has no divider
                        i !== reviews.length - 1 && <Divider />
                      }
                    </div>
                  ))}
                  <Button type="link" size="large" onlyText>
                    <Text variant="link" weight="bold" noMargin>
                      <Translate id="routeDetails.showMore" />
                    </Text>
                  </Button>
                </>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <Translate id="routeDetails.dontExistComments" />
                  }
                />
              )}
            </div>
          </div>
          <div className={classNames(classes.marginBetween)}>
            <Heading level={2}>
              <Translate id="routeDetails.otherRoutes" />
              <span className={classes.blue}>
                {`(${allOtherGuideRoutes.length})`}
              </span>
            </Heading>
            {isLoadingGuideRoutes ? (
              <Loader />
            ) : hasErrorGuideRoutes ? (
              <Error />
            ) : (
              <RoutePreviewList data={allOtherGuideRoutes} />
            )}
          </div>
          <div className={classNames(classes.marginBetween)}>
            <Heading level={2}>
              <Translate id="routeDetails.similarExperience" />
              <span className={classes.blue}>
                {`(${allSimilarRoutes.length})`}
              </span>
            </Heading>
            {isLoadingSimilarRoutes ? (
              <Loader />
            ) : hasErrorSimilarRoutes ? (
              <Error />
            ) : (
              <RoutePreviewList data={allSimilarRoutes} />
            )}
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <div>
            <Text weight="bold" noMargin>
              {complexTranslate(
                translate('routeDetails.valuePerPerson').toString(),
                {
                  '{value}': route.minPrice,
                }
              )}
            </Text>
            <Text
              variant="note"
              noMargin
              className={classNames(classes.alignItems)}
            >
              <span className={classes.bold}>{route.averageRating}</span>
              <StarYellow className={classes.starRating} />
              <span>{`(${route.numberOfRatings})`}</span>
            </Text>
          </div>
          {isPublic && route.state === RouteStateType.APPROVED && (
            <Button type="primary" onClick={() => setIsShowingDateModal(true)}>
              <Translate id="routeDetails.showDates" />
            </Button>
          )}
        </div>
      </motion.div>
    </>
  );
}

export default withLocalize(RouteDetails);
