import Axios from 'axios';

import { API_URL } from '../../consts';
import { theme } from '../../utils';
import { ThemeListResponse } from './useThemes';

export interface CustomizationTheme {
  primary: string;
  secondary: string;
}

export interface FullTheme {
  standout: string;
  strongBlue: string;
  defaultTextPrimaryColor: string;
  primary: string;
  secondary: string;
  neutral1: string;
  neutral2: string;
  neutral3: string;
  neutral4: string;
  neutral5: string;
  neutral6: string;
  infoBase: string;
  successBase: string;
  warningBase: string;
  errorBase: string;
  /////////
  shadowBackground: string;
}

export const fetchTheme = async (): Promise<CustomizationTheme> => {
  // return new Promise(resolve => {
  //   resolve({
  //     primary: 'darkgreen',
  //     secondary: 'chocolate',
  //   });
  // });
  try {
    const { data } = await Axios.get<ThemeListResponse[0]>(
      `${API_URL}/content-management/theme`
    );

    return data;
  } catch (err) {
    return theme;
  }
};
