import { Variants } from "framer-motion";

export const GenericPageTransition: Variants = {
  exit: { opacity: 0, type: "tween" },
  enter: { opacity: 1, type: "tween" }
};

export const SlideFromRightTransition: Variants = {
  exit: { translateX: "100%", type: "tween" },
  enter: { translateX: 0, type: "tween" }
};
