import React from 'react';
import ReactDOM from 'react-dom';
import { FullTheme } from './hooks/customization/fetchTheme';
import { useCurrentSize } from './hooks/useCurrentSize';
import { store } from './store';

export const measureElement = (element: any) => {
  const DOMNode = ReactDOM.findDOMNode(element);
  if (!DOMNode) {
    return { width: 0, height: 0 };
  }
  return {
    width: (DOMNode as any).offsetWidth,
    height: (DOMNode as any).offsetHeight,
  };
};

export const PlACE_BOUNDS = {
  west: -31.3,
  south: 36.838268541,
  east: -6.3890876937,
  north: 42.280468655,
};

export const theme = {
  mainColor: '#FFFF00',
  standout: '#f1f5f7',
  strongBlue: '#34466c',
  ////////77
  defaultTextPrimaryColor: '#000000',
  primary: '#009CDE',
  secondary: '#FFCD00',
  neutral1: '#8A8A8A',
  neutral2: '#A0A0A0',
  neutral3: '#D6D6D6',
  neutral4: '#EFEFEF',
  neutral5: '#F6F6F6',
  neutral6: '#F9F9F9',
  infoBase: '#006C9A',
  successBase: '#00A584',
  warningBase: '#E4DD00',
  errorBase: '#DE4100',
  /////////
  shadowBackground: 'rgba(0,0,0,0.5)',
};
export const maxAllowedImageSize = 900 * 1024 * 1024; // 3MB
export const maxAllowedFileSize = 900 * 1024 * 1024; // 5MB

export const getTheme = () =>
  ({ ...theme, ...store.getState().app.theme } as FullTheme);

export const complexTranslate = (
  text: string,
  replacements: Record<string, React.ReactNode>
) => {
  const args = Object.keys(replacements);
  let splitString: (string | React.ReactNode)[] = [text];

  while (
    // Enquanto houverem segmentos da string que sejam string e incluam argumentos há trabalho a fazer
    splitString.some(
      segment =>
        typeof segment === 'string' && args.some(arg => segment.includes(arg))
    )
  ) {
    // tranformar a string em questão em sub-segmentos
    splitString = splitString
      // se um segmento for uma string que tenha um argumento transformar essa string nos seus próprios segmentos
      .map(segment => {
        if (typeof segment !== 'string') return segment;

        let newSegment: (string | React.ReactNode)[] = [segment];

        for (const arg of args) {
          if (segment.includes(arg)) {
            //this segment has an arg
            let splitSegment = segment.split(arg) as (
              | string
              | React.ReactNode
            )[];
            let replaceIndex = splitSegment.indexOf('');

            if (replaceIndex !== -1) {
              //found on the edge
              splitSegment[replaceIndex] = replacements[arg];
            } else {
              //wasn't found on the edge, was found in the middle, insert it there
              splitSegment.splice(1, 0, replacements[arg]);
            }

            newSegment = splitSegment;
            break;
          }
        }

        return newSegment;
      })
      // achatar o array se segmentos para os sub-segmentos passarem a ser segmentos individuais
      .flat();
  }

  return splitString;

  /* exemplo

  texto original : 'Eu concordo com os {TOS} e {POP} da UniquePaths'
  argumentos: { '{TOS}': <Botão />, '{POP}': <Botão />}

  while #1

  MAP vai retornar [
    [ 'Eu concordo com os ', REACT_NODE_BOTÃO, ' e {POP} da UniquePaths']
  ]

  FLAT vai passar a [ 'Eu concordo com os ', REACT_NODE_BOTÃO, ' e {POP} da UniquePaths']

  while #2

  MAP vai retornar [
    'Eu concordo com os ',
    REACT_NODE_BOTÃO,
    [
      ' e ',
      REACT_NODE_BOTÃO,
      ' da UniquePaths'
    ]
  ]

  FLAT vai passar a [
    'Eu concordo com os ',
    REACT_NODE_BOTÃO,
    ' e ',
    REACT_NODE_BOTÃO,
    ' da UniquePaths'
  ]

  While não vai passar porque não há strings que contenham argumentos

  Vou retornar;

*/
};

// export const getRandomImage = () => `https://source.unsplash.com/random`;
export const getRandomImage = () =>
  `http://www.fabuloussavers.com/new_wallpaper/scenic_landscape_italy-wallpapers_pp.jpg`;

export const normalizeString = (s: string) =>
  s
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const headerHeight = 56;
export const castShadow = '0px 0px 10px 2px rgba(0,0,0,0.2)';

export const joinWithAnd = (strings: string[], and: string) =>
  strings.reduce((fullString, currentString, i, originalArray) => {
    if (originalArray.length === 1) return currentString;
    else if (i === originalArray.length - 1)
      return (
        fullString.substr(0, fullString.length - 2) + ` ${and} ` + currentString
      );
    else return fullString + currentString + ', ';
  }, '');

export const mobileThreshhold = 960;

export const useIsMobile = () => {
  const { width } = useCurrentSize();

  return width <= mobileThreshhold;
};

export const mobilePadding = {
  padding: '0 5%',
};

export const desktopPadding = {
  //padding: '0 calc(calc(100vw - 960px) / 5 + 64px)',
  padding: '1rem 10%',
};

export const ellipsis = (s: string, length = 32, tail = '...') => {
  if (s.length <= length + tail.length) {
    return s;
  } else {
    return <span title={s}>{s.substr(0, length) + tail}</span>;
  }
};
