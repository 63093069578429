import { makeStyles } from '@material-ui/styles';
import React from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { ReactComponent as CommentsIcon } from '../../../shared_assets/icons/comments.svg';
import { ReactComponent as LanguageIcon } from '../../../shared_assets/icons/language.svg';
import { ReactComponent as MedalIcon } from '../../../shared_assets/icons/medal.svg';
import { ReactComponent as RouteIcon } from '../../../shared_assets/icons/route.svg';
import { joinWithAnd, mobileThreshhold } from '../../../utils';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(150px, 1fr))`,
    gridAutoRows: '1fr',
    marginBottom: '1rem',
  },
  icon: {
    height: '1em',
    width: '1em',
    marginRight: '0.5em',
  },
  statItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    statsContainer: {
      gridTemplateColumns: '1fr',
      marginBottom: 0,
    },
  },
});

interface GuideStatsProps extends LocalizeContextProps {
  guideId: string;
  guideLanguages: string[];
  guideSkills: string[];
  numberOfReviews: number;
  numberOfCompletedRoutes: number;
}

function GuideStats({
  guideLanguages,
  guideSkills,
  translate,
  numberOfReviews,
  numberOfCompletedRoutes,
}: GuideStatsProps) {
  const classes = useStyles();

  return (
    <div className={classes.statsContainer}>
      <div className={classes.statItem}>
        <Text weight="bold" noMargin>
          <CommentsIcon className={classes.icon} />
          <Translate id="profile.comments" />
        </Text>
        <Text variant="faded">
          {numberOfReviews} <Translate id="profile.comments" />
        </Text>
      </div>
      <div className={classes.statItem}>
        <Text weight="bold" noMargin>
          <RouteIcon className={classes.icon} />
          <Translate id="profile.routesPerformed" />
        </Text>
        <Text variant="faded">{numberOfCompletedRoutes}</Text>
      </div>
      <div className={classes.statItem}>
        <Text weight="bold" noMargin>
          <LanguageIcon className={classes.icon} />
          <Translate id="profile.speaks" />
        </Text>
        <Text variant="faded">
          {joinWithAnd(
            guideLanguages.map(lang =>
              translate(`languages.${lang}`).toString()
            ),
            translate('and').toString()
          )}
        </Text>
      </div>
      <div className={classes.statItem}>
        <Text weight="bold" noMargin>
          <MedalIcon className={classes.icon} />
          <Translate id="profile.skills" />
        </Text>
        <Text variant="faded">
          {joinWithAnd(guideSkills, translate('and').toString())}
        </Text>
      </div>
    </div>
  );
}

export default withLocalize(GuideStats);
