import Avatar, { AvatarProps } from 'antd/es/avatar';
import React, { useEffect, useState } from 'react';

interface FallbackAvatarProps extends AvatarProps {
  fallback?: string;
}

const FallbackAvatar = ({ fallback, src, ...rest }: FallbackAvatarProps) => {
  const [mySource, setMySource] = useState(src);

  useEffect(() => {
    setMySource(src || fallback);
  }, [src, fallback]);

  return (
    <Avatar
      {...rest}
      src={mySource}
      onError={() => {
        if (fallback) setMySource(fallback);
        return true;
      }}
    />
  );
};

export default FallbackAvatar;
