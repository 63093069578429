import { makeStyles } from '@material-ui/styles';
import Collapse from 'antd/es/collapse';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { Loader } from '../../../../../components/App/Loader';
import { Heading, Text } from '../../../../../components/Typography';
import {
  getInvoiceUrl,
  useVisitorInvoices,
} from '../../../../../hooks/visitor/useVisitorInvoices';
import {
  ReactComponent as Bill,
  ReactComponent as Pedirota,
} from '../../../../../shared_assets/icons/bill.svg';
import { ReactComponent as Download } from '../../../../../shared_assets/icons/download.svg';
import { InvoiceInterface } from '../../../../../types/invoice.interface';
import {
  complexTranslate,
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    '& .ant-collapse': {
      backgroundColor: 'transparent',
    },
  },
  body: {
    ...mobilePadding,
    flex: 1,
    flexGrow: 1,
  },
  bodyContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  invoiceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  emptyImage: {
    width: '50%',
    marginBottom: '1rem',
  },
  downloadIcon: {
    marginLeft: '1rem',
  },
  downloadButton: {
    display: 'inline-flex',
    height: '1.5rem',
  },
  desktopPrefix: {
    marginRight: '1rem',
    width: 40,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    collapse: {
      padding: '0 54px',
    },
  },
});
interface ParamsInterface {
  id: string;
}
const Invoices = () => {
  const classes = useStyles();

  const [invoices, setInvoices] = useState<InvoiceInterface[]>([]);
  const params = useParams<ParamsInterface>();

  const [loading, error] = useVisitorInvoices(setInvoices, params.id);

  const uniqueYears = useMemo(() => {
    const unique: number[] = [];

    invoices.forEach(inv => {
      const year = moment(inv.dateOfCreation).get('year');

      if (!unique.includes(year)) unique.push(year);
    });

    return unique;
  }, [invoices]);

  const isMobile = useIsMobile();

  if (loading) return <Loader />;

  if (error) return <Error />;

  return (
    <motion.div
      className={classNames(classes.page)}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <div className={classes.body}>
        <Heading level={2}>
          {!isMobile && <Bill className={classes.desktopPrefix} />}
          <Translate id="invoices.invoices" />
        </Heading>
        {invoices.length === 0 ? (
          <div className={classes.bodyContent}>
            <Pedirota className={classes.emptyImage} />
            <Text variant="faded">
              <Translate id="invoices.emptyInvoices" />
            </Text>
          </div>
        ) : (
          <Collapse
            className={classes.collapse}
            expandIconPosition="right"
            bordered={false}
          >
            {uniqueYears.length === 1
              ? invoices.map(inv => (
                  <Collapse.Panel
                    key={inv.invoiceDocumentId}
                    header={
                      <Text disableAutoscale noMargin>
                        {inv.invoiceDocumentId === -1
                          ? 'Factura ainda não criada'
                          : `${inv.invoiceDocumentId} - ${moment(
                              inv.dateOfCreation
                            ).format('LL')}`}
                      </Text>
                    }
                  >
                    <InvoiceContent
                      date={inv.dateOfCreation}
                      amount={inv.amount.toString()}
                      link={inv.invoiceDocumentId.toString()}
                    />
                  </Collapse.Panel>
                ))
              : uniqueYears.map(year => (
                  <Collapse.Panel
                    key={year}
                    header={
                      <Text disableAutoscale weight="bold" noMargin>
                        {year}
                      </Text>
                    }
                  >
                    <Collapse expandIconPosition="right" bordered={false}>
                      {invoices
                        .filter(
                          inv => moment(inv.dateOfCreation).get('year') === year
                        )
                        .map(inv => (
                          <Collapse.Panel
                            key={inv.invoiceDocumentId}
                            header={
                              <Text disableAutoscale noMargin>
                                {inv.invoiceDocumentId}
                              </Text>
                            }
                          >
                            <InvoiceContent
                              date={inv.dateOfCreation}
                              amount={inv.amount.toString()}
                              link={inv.invoiceDocumentId.toString()}
                            />
                          </Collapse.Panel>
                        ))}
                    </Collapse>
                  </Collapse.Panel>
                ))}
          </Collapse>
        )}
      </div>
    </motion.div>
  );
};

interface InvoiceContentProps extends LocalizeContextProps {
  date: Date;
  amount: string;
  link: string;
}

const InvoiceContent = withLocalize(
  ({ date, amount, link, translate }: InvoiceContentProps) => {
    const classes = useStyles();
    const downloadFile = useCallback(async () => {
      window.open(await getInvoiceUrl(link));
    }, [link]);
    return (
      <div>
        <div className={classes.invoiceHeader}>
          <Text disableAutoscale variant="faded">
            {moment(date).format('LL')}
          </Text>
          <Text disableAutoscale variant="faded">
            {complexTranslate(translate('invoices.price').toString(), {
              '{VALUE}': amount,
            })}
          </Text>
        </div>
        {link !== '-1' ? (
          <Button
            type="link"
            onlyText
            onClick={downloadFile}
            className={classes.downloadButton}
          >
            <Translate id="invoices.download" />
            <Download className={classes.downloadIcon} />
          </Button>
        ) : null}
      </div>
    );
  }
);

export default Invoices;
