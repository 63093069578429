import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { API_URL } from '../../consts';
import { PartialRouteInterface } from './UseSimilarRoutes';

type Hook = (
  setRoutes: (val: SetStateAction<PartialRouteInterface[]>) => void,
  latitude?: number,
  longitude?: number
) => [boolean, boolean];

const requestClosestRoutes = (
  dto: { latitude: number; longitude: number } | null,
  cancelToken?: CancelToken
) =>
  Axios.get<PartialRouteInterface[]>(`${API_URL}/route/closest`, {
    params: dto,
    cancelToken: cancelToken,
  });

export const useClosestRoutes: Hook = (setRoutes, latitude, longitude) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: route } = await requestClosestRoutes(
          latitude && longitude ? { latitude, longitude } : null,
          cancel.current?.token
        );
        setRoutes(route);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        setRoutes([]);
        if (Axios.isCancel(err)) {
          setIsError(false);
        } else {
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [latitude, longitude, setRoutes]);

  return [isLoading, isError];
};
