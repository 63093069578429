import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

const fetchVideoUrl = () =>
  Axios.get<string>(`${API_URL}/content-management/video-url`);

type Hook = (setter: (v: SetStateAction<string>) => void) => [boolean, boolean];

export const useVideoUrl: Hook = setVideoUrl => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await fetchVideoUrl();
        setVideoUrl(data);
      } catch (err) {
        setVideoUrl('');
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setVideoUrl]);

  return [isLoading, isError];
};
