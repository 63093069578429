import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import AnimatedError from '../../../../components/App/AnimatedError/AnimatedError';
import { Button } from '../../../../components/App/Button';
import { TextArea } from '../../../../components/App/Input';
import { TabPane, Tabs } from '../../../../components/App/Tabs';
import { Heading, Text } from '../../../../components/Typography';
import { ReactComponent as BackWhite } from '../../../../shared_assets/icons/back_white.svg';
import { ReactComponent as Next } from '../../../../shared_assets/icons/next.svg';
import { GuideDescriptionDto } from '../../../../types/guide.dtos';
import {
  castShadow,
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
} from '../../../../utils';
import { SlideFromRightTransition } from '../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    padding: '1rem 0',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'initial',
    marginTop: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'flex-end',
    margin: '1rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },
    container: {
      padding: '2rem 2rem 3rem 2rem',
      boxShadow: castShadow,
      borderRadius: '10px',
    },
  },
});

interface BioStepProps extends LocalizeContextProps {
  selectedLanguages: string[];
  descriptions: GuideDescriptionDto[];
  setDescriptions: (v: SetStateAction<GuideDescriptionDto[]>) => void;
  onRequestPreviousStep: () => void;
  onRequestNextStep: () => void;
}

function BioStep({
  selectedLanguages,
  translate,
  descriptions,
  setDescriptions,
  onRequestPreviousStep,
  onRequestNextStep,
}: BioStepProps) {
  const classes = useStyles();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={SlideFromRightTransition}
    >
      <div className={classes.body}>
        <div className={classes.container}>
          <Heading level={4}>
            <Translate id="onboarding.bio" />
          </Heading>
          <Text variant="faded">
            <Translate id="onboarding.bioInfo" />
          </Text>
          <div className={classes.inputsContainer}>
            <Tabs>
              {descriptions.map(iteratedLanguage => (
                <TabPane
                  key={iteratedLanguage.language}
                  tab={translate(`languages.${iteratedLanguage.language}`)}
                >
                  <TextArea
                    value={iteratedLanguage.description}
                    rows={10}
                    onChange={ev => {
                      ev.persist();
                      setDescriptions(previousDescriptions =>
                        previousDescriptions.map(oldDescription => {
                          if (
                            oldDescription.language ===
                            iteratedLanguage.language
                          )
                            return {
                              ...oldDescription,
                              description: ev.target.value,
                            };
                          else return oldDescription;
                        })
                      );
                    }}
                  />
                  <AnimatedError
                    reason={translate(
                      'onboarding.singleBioMandatory'
                    ).toString()}
                    isVisible={validator.isEmpty(iteratedLanguage.description)}
                  />
                </TabPane>
              ))}
            </Tabs>
            <AnimatedError
              isVisible={selectedLanguages.length === 0}
              reason={translate('onboarding.mandatoryLanguages').toString()}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          size="large"
          onClick={onRequestPreviousStep}
          prefix={<BackWhite />}
        >
          <Translate id="button.previous" />
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={
            selectedLanguages.length === 0 ||
            descriptions.some(desc => desc.description.length === 0)
          }
          onClick={onRequestNextStep}
          suffix={<Next />}
        >
          <Translate id="button.completed" />
        </Button>
      </div>
    </motion.div>
  );
}

export default React.memo(withLocalize(BioStep));
