import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import React from 'react';

import { ReactComponent as CorrectWhite } from '../../../shared_assets/icons/correct_white.svg';
import { desktopPadding, getTheme, mobilePadding, mobileThreshhold } from '../../../utils';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  container: () => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: getTheme().primary,
    ...mobilePadding,
    paddingTop: '1rem',
  }),
  graphicsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  stepBox: {
    width: '2.5rem',
    minWidth: '2.5rem',
    height: '2.5rem',
    minHeight: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:first-child': {
      marginLeft: '1.5rem',
    },
    '&:last-child': {
      marginRight: '1.5rem',
    },
  },
  step: () => ({
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.17)',
    color: 'rgba(255,255,255,0.25)',
    borderRadius: '100%',
    '&.active': {
      backgroundColor: 'white',
      color: getTheme().primary,
    },
    '&.done': {
      backgroundColor: 'rgba(255,255,255,0.17)',
      color: 'white',
      '& img, & svg': {
        minHeight: '1rem',
        minWidth: '1rem',
        maxHeight: '1rem',
        maxWidth: '1rem',
      },
    },
  }),
  divider: {
    margin: 0,
    minWidth: 'unset',
    width: 'unset',
    flex: 1,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      minWidth: '5.5rem',
      maxWidth: '5.5rem',
      wordBreak: 'break-word',
      textAlign: 'center',
    },
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: () => ({
      ...desktopPadding,
    }),
    stepBox: {
      '&:first-child': {
        marginLeft: '2.5rem',
      },
      '&:last-child': {
        marginRight: '2.5rem',
      },
    },
    textContainer: {
      '& > *': {
        minWidth: '7.5rem',
        maxWidth: '7.5rem',
      },
    },
  },
});

interface HorizontalStepsProps {
  steps: {
    title: string;
  }[];
  activeStep: number;
}

function HorizontalSteps({ steps, activeStep }: HorizontalStepsProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.graphicsContainer}>
        {steps.map((s, i) => (
          <React.Fragment key={i.toString()}>
            <div className={classes.stepBox}>
              <div
                className={classNames(
                  classes.step,
                  activeStep === i ? 'active' : i < activeStep ? 'done' : ''
                )}
              >
                {i < activeStep ? <CorrectWhite /> : i + 1}
              </div>
            </div>
            {i + 1 < steps.length && <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </div>
      <div className={classes.textContainer}>
        {steps.map(s => (
          <Text inline color="white">
            {s.title}
          </Text>
        ))}
      </div>
    </div>
  );

  // return (
  //   <div className={classes.container}>
  //     <div className={classes.absoluteDividerContainer}>
  //       {Array.from(Array(steps.length - 1), (_, i) => {
  //         return (
  //           <React.Fragment key={i.toString()}>
  //             <Divider className={classNames(classes.divider)} />
  //           </React.Fragment>
  //         );
  //       })}
  //     </div>
  //     {steps.map((step, i) => (
  //       <div className={classes.stepContainer} key={step.title}>
  //         <div
  //           className={classNames(
  //             classes.step,
  //             activeStep === i ? 'active' : i < activeStep ? 'done' : ''
  //           )}
  //         >
  //           {i < activeStep ? (
  //             <FallbackImage
  //               src={require('../../../shared_assets/icons/correct_white.svg')}
  //               alt="correct"
  //             />
  //           ) : (
  //             i + 1
  //           )}
  //         </div>
  //         <Text
  //           className={activeStep >= i ? classes.activeTitle : classes.title}
  //         >
  //           {step.title}
  //         </Text>
  //       </div>
  //     ))}
  //   </div>
  // );
}

export default HorizontalSteps;
