import Axios from 'axios';
import { API_URL } from '../../consts';

export interface ManageAboutDto {
  firstTitle: string;
  intro: string;
  whoWeAreTitle: string;
  whoWeAreText: string;
  argument0Title: string;
  argument0Text: string;
  argument1Title: string;
  argument1Text: string;
  argument2Title: string;
  argument2Text: string;
  information0Title: string;
  information0Text: string;
  information0RedirectUrl: string;
  information1Title: string;
  information1Text: string;
  information1RedirectUrl: string;
  footerTitle: string;
  footerText: string;
  footerButtonText: string;
  footerButtonRedirect: string;
  whyUniquePathsTitle: string;
  language: string;
}
export interface LandingPageDto {
  about: ManageAboutDto[];
}
export const postAbout = (
  dto: LandingPageDto,
  imageHeader?: File | null,
  image1?: File | null,
  image2?: File | null
) => {
  const formData = new FormData();
  formData.append(
    'manageAboutDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (imageHeader) {
    formData.append('headerImage', imageHeader);
  }
  if (image1) {
    formData.append('image1', image1);
  }
  if (image2) {
    formData.append('image2', image2);
  }
  return Axios({
    method: 'post',
    url: `${API_URL}/content-management/about-page`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
