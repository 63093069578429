import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import validator from 'validator';
import { ReactComponent as PlusBlue } from '../../../shared_assets/icons/plus_blue.svg';
import { RouteActivity } from '../../../types/route-activity.interface';
import { Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { Button } from '../Button';
import { TextArea } from '../Input';
import { InputNumber } from '../InputNumber';
import { Modal } from '../Modal';
import { TabPane, Tabs } from '../Tabs';
import ActivityEntry from './ActivityEntry';

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
    '& > .segment': {
      display: 'flex',
      '& > *:first-child': {
        marginRight: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
});

const generateEmptyActivity = (languages: string[]): RouteActivity => ({
  price: 0,
  descriptions: languages.map(l => ({
    language: l,
    description: '',
  })),
});

interface RouteActivitiesProps {
  activities?: RouteActivity[];
  languages: string[];
  onChange?: (newActivities: RouteActivity[]) => void;
  className?: string;
}

const RouteActivities: React.FC<RouteActivitiesProps> = ({
  activities,
  languages,
  onChange,
  className,
}) => {
  const classes = useStyles();

  const [selectedActivities, setSelectedActivities] = useState<RouteActivity[]>(
    activities || []
  );

  const [editing, setEditing] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);

  const [activityToReplace, setActivityToReplace] = useState<RouteActivity>();
  const [activityToAdd, setActivityToAdd] = useState<RouteActivity>(
    generateEmptyActivity(languages)
  );

  useEffect(() => {
    if (!activities) return;

    setSelectedActivities(activities);
  }, [activities]);

  useEffect(() => {
    if (!onChange) return;

    onChange(selectedActivities);
  }, [selectedActivities, onChange]);

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setAdding(false);
                setActivityToAdd(generateEmptyActivity(languages));
              }}
            >
              <Translate id="button.cancel" />
            </Button>

            <Button
              disabled={activityToAdd.descriptions.some(desc =>
                validator.isEmpty(desc.description)
              )}
              type="primary"
              onClick={() => {
                if (activityToAdd) {
                  setSelectedActivities(prevActivities => [
                    ...prevActivities,
                    activityToAdd,
                  ]);
                  setActivityToAdd(generateEmptyActivity(languages));
                  setAdding(false);
                }
              }}
            >
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={adding}
        closable={false}
      >
        <Text weight="bold">
          <Translate id="routes.price" />
        </Text>
        <InputNumber
          huge
          type="number"
          value={activityToAdd?.price}
          onChange={value =>
            setActivityToAdd(prevActivity => ({
              ...prevActivity,
              price: value || 0,
            }))
          }
        />
        <AnimatedError
          reason={<Translate id="routes.extraItemPriceMandatory" />}
          isVisible={!activityToAdd.price}
        />
        <Tabs>
          {activityToAdd.descriptions.map(description => (
            <TabPane
              key={description.language}
              tab={<Translate id={`languages.${description.language}`} />}
            >
              <TextArea
                value={description.description}
                onChange={e => {
                  e.persist();
                  setActivityToAdd(prevItem => ({
                    ...prevItem,
                    descriptions: prevItem.descriptions.map(desc =>
                      desc.language === description.language
                        ? { ...desc, description: e.target.value }
                        : desc
                    ),
                  }));
                }}
              />
            </TabPane>
          ))}
        </Tabs>
        <AnimatedError
          reason={<Translate id="routes.extraItemDescriptionMandatory" />}
          isVisible={activityToAdd.descriptions.some(desc =>
            validator.isEmpty(desc.description)
          )}
        />
      </Modal>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        footer={
          <div className={classes.footer}>
            <div className="segment">
              <Button
                onClick={() => {
                  setActivityToAdd(generateEmptyActivity(languages));
                  setActivityToReplace(undefined);
                  setEditing(false);
                }}
              >
                <Translate id="button.cancel" />
              </Button>
              <Button
                disabled={!activityToAdd}
                danger
                onClick={() => {
                  if (activityToAdd) {
                    setSelectedActivities(prevActivities =>
                      prevActivities.filter(
                        activity => activity !== activityToAdd
                      )
                    );

                    setActivityToAdd(generateEmptyActivity(languages));
                    setEditing(false);
                  }
                }}
              >
                <Translate id="button.remove" />
              </Button>
            </div>
            <Button
              disabled={activityToAdd.descriptions.some(desc =>
                validator.isEmpty(desc.description)
              )}
              type="primary"
              onClick={() => {
                if (activityToAdd) {
                  setSelectedActivities(prevActivities => [
                    ...prevActivities.filter(
                      activity => activity !== activityToReplace
                    ),
                    activityToAdd,
                  ]);

                  setActivityToAdd(generateEmptyActivity(languages));
                  setActivityToReplace(undefined);
                  setEditing(false);
                }
              }}
            >
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={editing}
        closable={false}
      >
        <Text weight="bold">
          <Translate id="routes.price" />
        </Text>
        <InputNumber
          huge
          type="number"
          value={activityToAdd?.price}
          onChange={value =>
            setActivityToAdd(prevActivity => ({
              ...prevActivity,
              price: value || 0,
            }))
          }
        />
        <AnimatedError
          reason={<Translate id="routes.extraItemPriceMandatory" />}
          isVisible={!activityToAdd.price}
        />
        <Tabs>
          {activityToAdd.descriptions.map(description => (
            <TabPane
              key={description.language}
              tab={<Translate id={`languages.${description.language}`} />}
            >
              <TextArea
                value={description.description}
                onChange={e => {
                  e.persist();
                  setActivityToAdd(prevItem => ({
                    ...prevItem,
                    descriptions: prevItem.descriptions.map(desc =>
                      desc.language === description.language
                        ? { ...desc, description: e.target.value }
                        : desc
                    ),
                  }));
                }}
              />
            </TabPane>
          ))}
        </Tabs>
        <AnimatedError
          reason={<Translate id="routes.extraItemDescriptionMandatory" />}
          isVisible={activityToAdd.descriptions.some(desc =>
            validator.isEmpty(desc.description)
          )}
        />
      </Modal>
      <div className={className}>
        {selectedActivities.map((activity, i) => (
          <ActivityEntry
            key={i}
            onRequestActivityChange={() => {
              setActivityToReplace(activity);
              setActivityToAdd(activity);
              setEditing(true);
            }}
            activity={activity}
          />
        ))}
        <Button
          onClick={() => setAdding(true)}
          prefix={<PlusBlue />}
          type="link"
        >
          <Translate id="button.addActivity" />
        </Button>
      </div>
    </>
  );
};

export default React.memo(RouteActivities);
