import { useCallback, useState, useMemo, useEffect } from 'react';
import validator from 'validator';
import {
  SettableFormEntry,
  Info,
  SettableFormGuideEntry,
} from '../../../types/form.interface';
import { getTranslate } from 'react-localize-redux';
import { store } from '../../../store/index';
import { FormGuideEntry } from '../../../types/form.interface';

type ResetPasswordFormProperties = 'newPassword' | 'confirmNewPassword';

export interface ResetPasswordForm
  extends Record<ResetPasswordFormProperties, SettableFormEntry> {
  newPassword: SettableFormGuideEntry;
  confirmNewPassword: SettableFormGuideEntry;
}

export const useResetPasswordForm = (): ResetPasswordForm => {
  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const [newPassword, setNewPassword] = useState<FormGuideEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const setValidatedNewPassword = useCallback(
    (value: string) => {
      const formatNumSymbol = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      const formatStrength = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      const hasRightLength = value.length >= 8 && value.length <= 20;
      const isStrength = value.match(formatStrength) ? true : false;
      const isContainNumSymbol =
        formatNumSymbol.test(value) && value.match(/\d/) ? true : false;
      const isInvalid: boolean =
        !hasRightLength || !isContainNumSymbol || !isStrength ? true : false;

      let infos: Info[] = [];

      if (isInvalid) {
        infos = [
          {
            infoType: 'error',
            phrase: [
              translate('register.passwordStrengthInfo').toString(),
              translate('register.weakPassword').toString(),
            ],
          },
        ];
      } else {
        infos = [
          {
            infoType: 'success',
            phrase: [
              translate('register.passwordStrengthInfo').toString(),
              translate('register.strongPassword').toString(),
            ],
          },
        ];
      }
      setNewPassword({ value, isInvalid, isVisible: true, infos });
    },
    [setNewPassword, translate]
  );

  const [confirmNewPassword, setConfirmNewPassword] = useState<FormGuideEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const setValidatedConfirmNewPassword = useCallback(
    (value: string) => {
      setConfirmNewPassword(prevState => ({ ...prevState, value }));
    },
    [setConfirmNewPassword]
  );

  useEffect(() => {
    if (
      validator.isEmpty(newPassword.value) ||
      validator.isEmpty(confirmNewPassword.value)
    ) {
      return;
    } else {
      if (validator.isEmpty(confirmNewPassword.value)) {
        setConfirmNewPassword(prevState => ({
          ...prevState,
          isInvalid: true,
          infos: [
            {
              infoType: 'success',
              phrase: [
                translate('resetPassword.passwordStrengthInfo').toString(),
              ],
            },
          ],
        }));
      } else {
        if (newPassword.value !== confirmNewPassword.value)
          setConfirmNewPassword(prevState => ({
            ...prevState,
            isInvalid: true,
            infos: [
              {
                infoType: 'error',
                phrase: [
                  '',
                  translate('resetPassword.badConfirmNewPassword').toString(),
                ],
              },
            ],
          }));
        else {
          setConfirmNewPassword(prevState => ({
            ...prevState,
            isInvalid: false,
            infos: [
              {
                infoType: 'success',
                phrase: [
                  translate('resetPassword.goodConfirmNewPassword').toString(),
                ],
              },
            ],
          }));
        }
      }
    }
  }, [
    newPassword.value,
    confirmNewPassword.value,
    setValidatedConfirmNewPassword,
    translate,
  ]);

  return {
    newPassword: {
      ...newPassword,
      set: setValidatedNewPassword,
    },
    confirmNewPassword: {
      ...confirmNewPassword,
      set: setValidatedConfirmNewPassword,
    },
  };
};
