import Axios from 'axios';
import { API_URL } from '../../consts';
import { RoleType } from '../../types/role-type.enum';

interface ResetPasswordChangeDto {
  email: string;
  token: string;
  newPassword: string;
}

interface ResetPasswordRequestDto {
  email: string;
  type: RoleType;
}
interface AccessToken {
  accessToken: string;
}
export const resetPassword = (dto: ResetPasswordChangeDto) => {
  return Axios.put<AccessToken>(
    `${API_URL}/authentication/reset-password`,
    dto
  );
};

export const resetPasswordEmail = (dto: ResetPasswordRequestDto) => {
  return Axios.post(`${API_URL}/authentication/reset-password`, dto);
};
