import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';

import { API_URL } from '../../consts';
import { ScheduleRepetitionStateType } from '../../types/schedule-repetition-state-type.enum';

type Hook = (
  routeId: string | undefined,
  setRep: (val: SetStateAction<ClosestRepetition | undefined>) => void
) => [boolean, boolean];

export interface ClosestRepetition {
  id: string;
  date: Date;
  state: ScheduleRepetitionStateType;
  language: string;
  reserveLimit: number;
  scheduleId: string;
  commentary: string | null;
  price: number;
  nPeople: number;
}

const requestRouteClosestRepetition = (routeId: string) =>
  Axios.get<ClosestRepetition>(
    `${API_URL}/route/${routeId}/closest-repetition`
  );

export const useRouteClosestRepetition: Hook = (routeId, setRep) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!routeId) {
        setIsError(true);
        setRep(undefined);
        setIsLoading(false);
        return;
      }

      try {
        const { data } = await requestRouteClosestRepetition(routeId);
        setRep(data);
        setIsError(false);
      } catch (err) {
        setRep(undefined);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [routeId, setRep]);

  return [isLoading, isError];
};
