import { makeStyles } from '@material-ui/styles';
import Axios from 'axios';
import { motion } from 'framer-motion';
import qs from 'qs';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AnimatedInfo } from '../../../components/App/AnimatedInfo';
import { Button } from '../../../components/App/Button';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { InformationPopup } from '../../../components/App/InformationPopup';
import { Input } from '../../../components/App/Input';
import { Heading, Text } from '../../../components/Typography';
import { resetPassword } from '../../../hooks/authentication/resetPassword';
import { ReactComponent as Lock } from '../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../shared_assets/icons/unsee.svg';
import { login } from '../../../store/UserConfiguration/actions';
import { castShadow, mobilePadding, mobileThreshhold } from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import { useResetPasswordForm } from './useResetPasswordForm';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    padding: '',
  },
  body: {
    ...mobilePadding,
  },
  container: {},
  noMargin: {
    margin: 0,
  },
  bottomContainer: {
    marginTop: '1.2rem',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  btn: {
    padding: '0 2rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      padding: '3rem 30%', //TODO: meter margem do utils
      //...desktopPadding,
    },
    container: {
      padding: '2rem',
      boxShadow: castShadow,
    },
    bold: {
      fontWeight: ('600 !important' as unknown) as number,
    },
    marginBot: {
      marginBottom: '1rem !important',
    },
    btn: {
      padding: '0 2rem',
    },
  },
});

// interface RouteParams {
//   type: 'visitor' | 'guide';
// }

//const ValidRedirections = ['/guide/landing', '/visitor/landing'];

function ResetPassword({ translate }: LocalizeContextProps) {
  const classes = useStyles();
  // const { type } = useParams<RouteParams>();
  const history = useHistory();
  const form = useResetPasswordForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isNewPasswordShowing, setIsNewPasswordShowing] = useState<boolean>(
    false
  );
  const [
    isConfirmPasswordShowing,
    setIsConfirmPasswordShowing,
  ] = useState<boolean>(false);
  const [changeError, setChangeError] = useState<{
    message: string;
    description: string;
  } | null>(null);

  const query = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location]
  );

  const resetPasswordCallback = useCallback(async () => {
    if (Object.values(form).some(formEntry => formEntry.isInvalid)) {
      Object.values(form).forEach(formEntry => formEntry.set(formEntry.value));
      window.scrollTo(0, 0);
      return;
    }
    window.scrollTo(0, 0);

    try {
      const { data: jwt } = await resetPassword({
        email: (query.email as string) ?? '',
        token: (query.token as string) ?? '',
        newPassword: form.newPassword.value,
      });
      setChangeError(null);

      const area: string = (query.token as string).split('-')[0];

      history.push(`/${area}/landing`, {
        alert: 'passwordChanged',
      });
      Axios.defaults.headers['Authorization'] = 'Bearer ' + jwt.accessToken;
      dispatch(login(jwt.accessToken));
    } catch (err) {
      if (err && err.response && err.response.data) {
        setChangeError({
          message: translate(
            'resetPassword.resetPasswordFailedTitle'
          ).toString(),
          description: translate(
            'resetPassword.resetPasswordFailedDescription'
          ).toString(),
        });
      } else {
        setChangeError({
          message: translate('error.connectionFailed').toString(),
          description: '',
        });
      }
    }
  }, [form, translate, setChangeError, history, dispatch, query]);

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.body}>
        <div className={classes.container}>
          <InformationPopup
            visible={!!changeError}
            message={changeError?.message}
            description={changeError?.description}
            type="error"
          />
          <Heading level={3}>
            <Translate id="resetPassword.resetPasswordTitle" />
          </Heading>
          <Text className={classes.marginBot}>
            <Translate id="resetPassword.passwordInfo" />
          </Text>

          <Text weight="bold">
            <Translate id="resetPassword.newPassword" />
          </Text>
          <Input
            huge
            placeholder={translate('resetPassword.password').toString()}
            type={isNewPasswordShowing ? 'text' : 'password'}
            value={form.newPassword.value}
            onChange={ev => {
              form.newPassword.set(ev.currentTarget.value);
            }}
            prefix={<Lock className={classes.icon} />}
            suffix={
              isNewPasswordShowing ? (
                <div
                  onClick={() => {
                    setIsNewPasswordShowing(prevState => !prevState);
                  }}
                >
                  <See className={classes.icon} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsNewPasswordShowing(prevState => !prevState);
                  }}
                >
                  <Unsee className={classes.icon} />
                </div>
              )
            }
          />
          <AnimatedInfo
            isVisible={
              form.newPassword.isVisible ? form.newPassword.isVisible : false
            }
            infos={form.newPassword.infos}
          />

          <Text weight="bold">
            <Translate id="resetPassword.confirmNewPassword" />
          </Text>
          <Input
            huge
            placeholder={translate('resetPassword.password').toString()}
            type={isConfirmPasswordShowing ? 'text' : 'password'}
            value={form.confirmNewPassword.value}
            onChange={ev => {
              form.confirmNewPassword.set(ev.currentTarget.value);
            }}
            prefix={<Lock className={classes.icon} />}
            suffix={
              isConfirmPasswordShowing ? (
                <div
                  onClick={() => {
                    setIsConfirmPasswordShowing(prevState => !prevState);
                  }}
                >
                  <See className={classes.icon} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsConfirmPasswordShowing(prevState => !prevState);
                  }}
                >
                  <Unsee className={classes.icon} />
                </div>
              )
            }
          />
          <AnimatedInfo
            isVisible={form.confirmNewPassword.isInvalid}
            infos={form.confirmNewPassword.infos}
          />
          <div className={classes.bottomContainer}>
            <Button
              type="primary"
              onClick={resetPasswordCallback}
              className={classes.btn}
            >
              <Translate id="resetPassword.send" />
            </Button>
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
      <CopyrightFooter />
    </motion.div>
  );
}

export default withLocalize(ResetPassword);
