import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
export interface IntermediatePartialInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

type Hook = (
  id: string | undefined,
  setIntermediate: (
    val: SetStateAction<IntermediatePartialInterface | null>
  ) => void
) => [boolean, boolean];

const requestIntermediate = (id: string) =>
  Axios.get<IntermediatePartialInterface>(
    `${API_URL}/intermediate/${id}/partial-info`
  );

export const useIntermediateChangePasswordInfo: Hook = (
  id,
  setIntermediate
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!id) {
        setIsError(true);
        setIntermediate(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: guide } = await requestIntermediate(id);
        setIntermediate(guide);
        setIsError(false);
      } catch (err) {
        setIntermediate(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, dispatch, setIntermediate]);

  return [isLoading, isError];
};
