import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';

import { getLocationPhotoUrl } from '../../../hooks/routes/location/useLocationPhoto';
import { getSubjectImageUrl } from '../../../hooks/subjects/useSubjects';
import { ReactComponent as Difficulty } from '../../../shared_assets/icons/difficulty.svg';
import { ReactComponent as Filter } from '../../../shared_assets/icons/filter.svg';
import { ReactComponent as Language } from '../../../shared_assets/icons/language.svg';
import { ReactComponent as Routes } from '../../../shared_assets/icons/routes.svg';
import { Text } from '../../Typography';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: ' 0.5rem 0',
  },
  icon: {
    marginRight: '1rem',
    height: 20,
    width: 20,
    fontSize: 'inherit',
  },
});

interface GenericResultItemProps {
  type: 'location' | 'filter';
  text: string;
  onClick?: () => void;
}

interface ResultItemPropsWithId {
  type: 'route' | 'subject';
  text: string;
  id: string;
  onClick?: () => void;
}

type ResultItemProps = GenericResultItemProps | ResultItemPropsWithId;

const ResultItem: React.FC<ResultItemProps> = props => {
  const classes = useStyles();
  const { text, onClick } = props;

  const renderIcon = useCallback(() => {
    switch (props.type) {
      case 'location':
        return <Language className={classes.icon} />;
      case 'subject':
        return (
          <FallbackImage
            className={classes.icon}
            src={getSubjectImageUrl(props.id)}
            fallbackComponent={<Difficulty className={classes.icon} />}
          />
        );
      case 'route':
        return (
          <FallbackImage
            src={getLocationPhotoUrl(props.id)}
            fallbackComponent={<Routes className={classes.icon} />}
            className={classes.icon}
          />
        );
      default:
        return <Filter className={classes.icon} />;
    }
  }, [props, classes.icon]);

  return (
    <div className={classes.container} onClick={onClick}>
      {renderIcon()}
      <Text inline noMargin>
        {text}
      </Text>
    </div>
  );
};

export default React.memo(ResultItem);
