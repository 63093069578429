import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import ANTDModal, { ModalProps as ANTDModalProps } from 'antd/es/modal';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import tinycolor from 'tinycolor2';
import { useCurrentSize } from '../../../hooks/useCurrentSize';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { ReactComponent as CloseBlack } from '../../../shared_assets/icons/close black.svg';
import {
  getTheme,
  headerHeight,
  measureElement,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { Heading } from '../../Typography';

interface StyleProps {
  height: number;
}

const useStyles = makeStyles({
  myModal: {},
  header: {
    minHeight: headerHeight,
    display: 'flex',
    alignItems: 'center',
    '& img, & svg': {
      height: 20,
    },
  },
  noScrollModal: {
    maxHeight: '80vh',
    paddingBottom: 0,
    overflow: 'hidden',
    '& > .ant-modal-content': {
      maxHeight: 'inherit',
      '& > .ant-modal-body': {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'inherit',
        padding: 0,
      },
    },
  },
  zIndexChange: {
    '& div < div < div:nth-child(3) < scrolling-container': {
      zIndex: 900,
    },
  },
  bodyContainer: {
    overflow: 'auto',
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      flexShrink: 0,
    },
  },
  naturalPadding: {
    padding: '0 24px',
    '&:last-child': {
      marginBottom: 24,
    },
  },
  headerDivider: {
    margin: 0,
  },
  stickToBottom: {
    minHeight: 'unset !important',
  },
  round: { borderRadius: 10 },
  [`@media (max-width: ${mobileThreshhold}px)`]: {
    myModal: {
      maxWidth: '100vw !important',
      width: '100vw !important',
      margin: 0,
      top: 0,
      padding: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      '& .ant-modal-content, & .ant-modal-body': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      },
    },
    noScrollModal: ({ height }: StyleProps) => ({
      maxHeight: height,
    }),
  },
  closeVariant: {
    justifyContent: 'space-between',
    '& > img, & > svg': {
      height: '1rem',
      width: '1rem',
    },
  },
});

export interface ModalProps extends ANTDModalProps {
  variant?: 'back' | 'close';
  onRequestClose?: () => void;
  fullScreenOnMobile?: boolean;
  contentContainerClassName?: string;
  headerContainerClassName?: string;
  headerTitle?: React.ReactNode | string;
  disableNaturalPadding?: boolean;
  footer?: React.ReactNode;
  glueDown?: boolean;
  stickToBottom?: boolean;
  widthModal?: number | string;
  key?: string;
  zIndex?: number;
}

const Modal: React.FC<ModalProps> = ({
  children,
  className,
  variant,
  onRequestClose,
  mask,
  fullScreenOnMobile,
  headerTitle,
  headerContainerClassName,
  disableNaturalPadding,
  contentContainerClassName,
  stickToBottom,
  widthModal,
  footer,
  getContainer,
  zIndex,
  key,
  ...rest
}) => {
  const { width, height } = useCurrentSize();
  const isMobile = useIsMobile();
  const [modalPosition, setModalPosition] = useState<number | undefined>(
    undefined
  );

  const classes = useStyles({ height, position: modalPosition });

  const renderHeader = useCallback(() => {
    switch (variant) {
      case 'back':
        return (
          <div
            className={classNames(
              classes.header,
              headerContainerClassName,
              disableNaturalPadding ? undefined : classes.naturalPadding
            )}
          >
            <div onClick={onRequestClose}>
              <ArrowBack />
            </div>
            {headerTitle && (
              <Heading noMargin level={4}>
                {headerTitle}
              </Heading>
            )}
          </div>
        );
      case 'close':
        return (
          <div
            className={classNames(
              classes.header,
              classes.closeVariant,
              headerContainerClassName,
              disableNaturalPadding ? undefined : classes.naturalPadding
            )}
          >
            {headerTitle && (
              <Heading noMargin level={4}>
                {headerTitle}
              </Heading>
            )}
            <div onClick={onRequestClose}>
              <CloseBlack />
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [
    variant,
    classes.header,
    onRequestClose,
    headerContainerClassName,
    disableNaturalPadding,
    classes.naturalPadding,
    headerTitle,
    classes.closeVariant,
  ]);

  const modalRef = useRef<HTMLDivElement | null>(null);

  const maskStyle = useMemo(
    () => ({
      backgroundColor: tinycolor(getTheme().primary)
        .setAlpha(0.6)
        .toRgbString(),
    }),
    []
  );

  const setRef = useCallback(
    ref => {
      modalRef.current = ref;

      if (!stickToBottom) {
        setModalPosition(undefined);
        return;
      }

      if (modalRef.current) {
        setModalPosition(
          height - measureElement(modalRef.current).height - (isMobile ? 0 : 80)
        );
      }
    },
    [setModalPosition, height, isMobile, stickToBottom]
  );

  const content = useMemo(
    () => (
      <div
        className={classNames(
          'modal-scroller',
          classes.bodyContainer,
          contentContainerClassName,
          disableNaturalPadding ? undefined : classes.naturalPadding
        )}
        ref={setRef}
      >
        {children}
      </div>
    ),
    [
      classes.bodyContainer,
      classes.naturalPadding,
      contentContainerClassName,
      children,
      disableNaturalPadding,
      setRef,
    ]
  );

  useEffect(() => {
    if (!stickToBottom) {
      setModalPosition(undefined);
      return;
    }

    if (modalRef.current) {
      setModalPosition(
        height - measureElement(modalRef.current).height - (isMobile ? 0 : 80)
      );
    }
  }, [setModalPosition, height, isMobile, stickToBottom]);

  const modalStyle = useMemo(
    () => ({
      top: modalPosition,
    }),
    [modalPosition]
  );

  const container = useMemo(() => {
    return (
      getContainer ||
      (() => {
        return document.getElementById('scrolling-container') as HTMLElement;
      })
    );
  }, [getContainer]);

  return (
    <ANTDModal
      maskStyle={maskStyle}
      centered={!isMobile && !stickToBottom}
      {...rest}
      key={key}
      destroyOnClose
      getContainer={container}
      style={modalStyle}
      footer={null}
      onCancel={onRequestClose}
      className={classNames(
        fullScreenOnMobile === false ? undefined : classes.myModal,
        stickToBottom ? classes.stickToBottom : undefined,
        classes.noScrollModal,
        fullScreenOnMobile === false || !isMobile ? classes.round : undefined,
        className
      )}
      mask={!isMobile || stickToBottom}
      closable={false}
      width={isMobile ? width : widthModal ? widthModal : 520}
      zIndex={zIndex ? zIndex : 1000}
    >
      {variant || headerTitle ? (
        <>
          {renderHeader()} <Divider className={classes.headerDivider} />
        </>
      ) : null}
      {content}
      {footer || null}
    </ANTDModal>
  );
};

export default Modal;
