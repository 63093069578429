import Axios from 'axios';
import { API_URL } from '../../consts';
import { RequestFromTypes } from '../../types/request-from.enum';

interface CancelScheduleRepetitionDto {
  cancelAll?: boolean;
  description: string;
  requestFrom: RequestFromTypes;
}

export const cancelScheduleRepetition = (
  repetitionId: string,
  dto: CancelScheduleRepetitionDto
) =>
  Axios.patch(`${API_URL}/schedule/cancel-schedule-date/${repetitionId}`, dto);
