import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import stringSimilarity from 'string-similarity';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import {
  PartialRouteInterface,
  useSimilarRoutes,
} from '../../../hooks/routes/UseSimilarRoutes';
import {
  RouteSubjectWithSingleTranslation,
  useSubjects,
} from '../../../hooks/subjects/useSubjects';
import { usePlacesSearch } from '../../../hooks/usePlacesSearch';
import { useVisitor } from '../../../hooks/visitor/UseVisitor';
import { RootState } from '../../../store';
import { FilterRoute } from '../../../types/filter-route.dto';
import { Visitor } from '../../../types/visitor.interface';
import { normalizeString } from '../../../utils';

export interface RouteResult extends PartialRouteInterface {
  type: 'route';
}

export type ResultType =
  | {
      type: 'location';
      location: {
        description: string;
        location: { lat: number; lng: number };
      };
    }
  | { type: 'subject'; id: string; name: string }
  | RouteResult
  | { type: 'filter' };

export const useSearchResults = (
  onSearchStateChange?: (v: boolean) => void
) => {
  const [search, setSearch] = useState<string>('');
  const [subjects, setSubjects] = useState<RouteSubjectWithSingleTranslation[]>(
    []
  );
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );

  const [routes, setRoutes] = useState<PartialRouteInterface[]>([]);
  const [filterDto, setFilterDto] = useState<FilterRoute>({ titles: [search] });
  const [routesAreLoading, routesHaveError] = useSimilarRoutes(
    filterDto,
    setRoutes
  );

  const [jwt] = UseJWT();
  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const [visitorisLoading] = useVisitor(jwt?.visitorId, setVisitor);

  const [searching, setSearching] = useState<boolean>(false);

  const recent = useSelector((state: RootState) => state.recentSearches.list);

  useEffect(() => {
    if (onSearchStateChange) onSearchStateChange(searching);
  }, [searching, onSearchStateChange]);

  useEffect(() => {
    if (!visitor || !visitor.showOnly) return;
    setFilterDto(prevFilter => ({
      ...prevFilter,
      accessibilitiesIds: visitor.accessibilities,
    }));
  }, [visitor]);

  useEffect(() => {
    setFilterDto(prevFilter => {
      if (search) return { ...prevFilter, titles: [search] };
      else {
        delete prevFilter.titles;
        return { ...prevFilter };
      }
    });
  }, [search]);

  const [locations] = usePlacesSearch(search);

  const [subjectsAreLoading, subjectsHaveError] = useSubjects(
    setSubjects,
    activeLanguage,
    true
  );

  const results = useMemo(() => {
    return [
      ...subjects
        .filter(s => normalizeString(s.name).includes(normalizeString(search)))
        .map(s => ({ ...s, type: 'subject' })),
      ...locations.predictions
        .filter(l =>
          normalizeString(l.description).includes(normalizeString(search))
        )
        .map(l => ({ location: l, type: 'location' })),
      ...routes.map(r => ({ ...r, type: 'route' })),
    ]
      .sort((a, b) => {
        // @ts-ignore
        // prettier-ignore
        const aText= a.type === "location" ? a.location.description : a.type === 'route' ? a.titles[0].title : a.name;
        // @ts-ignore
        // prettier-ignore
        const bText= b.type === "location" ? b.location.description : b.type === 'route' ? b.titles[0].title : b.name;

        const aSimilarity = stringSimilarity.compareTwoStrings(
          normalizeString(aText),
          normalizeString(search)
        );
        const bSimilarity = stringSimilarity.compareTwoStrings(
          normalizeString(bText),
          normalizeString(search)
        );

        return bSimilarity - aSimilarity;
      })
      .slice(0, 10) as ResultType[];
  }, [locations, routes, subjects, search]);

  return {
    filterDto,
    error: routesHaveError || routesHaveError || subjectsHaveError,
    loading: routesAreLoading || subjectsAreLoading || visitorisLoading,
    results,
    recent,
    search,
    setSearch,
    setSearching,
    searching,
  };
};
