import Axios from 'axios';
import clonedeep from 'lodash.clonedeep';
import { API_URL } from '../../consts';
import { GroupAgeType } from '../../types/group-age-type.enum';
import { DifficultyType } from '../../types/route-difficulty.enum';
import { RouteExperienceType } from '../../types/route-experience.enum';
import { RouteLocationPhotos } from '../../types/route-location-photo.interface';
import { RouteStateType } from '../../types/route-state.enum';
import { Route } from '../../types/route.interface';

interface DescriptionDto {
  language: string;
  description: string;
}

export interface RouteActivityDto {
  price: number;
  descriptions?: DescriptionDto[];
}

export interface RouteExtraItemDto {
  descriptions?: DescriptionDto[];
}

interface RouteLocationDto {
  order: number;
  latitude: number;
  longitude: number;
  address: string;
  descriptions: DescriptionDto[];
  photos: (File | RouteLocationPhotos)[];
  id?: string;
  removePhotos?: string[];
}

interface RouteLanguageDto {
  language: string;
}
interface TitleDto {
  language: string;
  title: string;
}
interface EditRouteDto {
  titles: TitleDto[];
  location: string;
  duration: number;
  languages: RouteLanguageDto[];
  difficulty: DifficultyType;
  accessibilityIds: string[];
  groupSize: number;
  routeExperience: RouteExperienceType;
  groupAge: GroupAgeType;
  agreeTerms: boolean;
  state: RouteStateType;
  subjectIds: string[];
  locations: RouteLocationDto[];
  activities: RouteActivityDto[];
  extraItems: RouteExtraItemDto[];
  descriptions?: DescriptionDto[];
  removeLocations?: string[];
  latitude: number;
  longitude: number;
  routeLength: number;
}

const requestEditRoute = (id: string, formData: FormData) =>
  Axios.put(`${API_URL}/route/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const EditRoute = (dto: EditRouteDto, oldRoute: Route) => {
  const clonedDto = clonedeep(dto) as EditRouteDto; // cloning so we don't mutate the sent dto
  const data = new FormData();

  oldRoute.locations.forEach(oldLoc => {
    if (!clonedDto.locations.some(newLoc => newLoc.id === oldLoc.id))
      clonedDto.removeLocations = [
        ...(clonedDto.removeLocations || []),
        oldLoc.id,
      ];
  });

  clonedDto.locations.forEach((newLoc, i) => {
    const oldLoc = oldRoute.locations.find(oldLoc => oldLoc.id === newLoc.id);
    if (oldLoc) {
      clonedDto.locations[i].removePhotos = oldLoc.photos
        .filter(
          oldPhoto =>
            !newLoc.photos.some(
              newPhoto =>
                !(newPhoto instanceof File) && oldPhoto.id === newPhoto.id
            )
        )
        .map(p => p.id);
    }
  });

  clonedDto.locations.forEach((loc, i) => {
    loc.photos.forEach(photo => {
      if (photo instanceof File) data.append(`location-${i}`, photo);
    });
    //clean json data
    delete loc.photos;
  });

  if (!clonedDto.removeLocations) {
    clonedDto.removeLocations = [];
  }

  clonedDto.locations = clonedDto.locations.map(loc => ({
    ...loc,
    removePhotos: loc.removePhotos || [],
  }));

  data.append('editRouteDto', JSON.stringify(clonedDto));
  return requestEditRoute(oldRoute.id, data);
};
