import Axios from 'axios';
import { API_URL } from '../../consts';
import { MemberType } from './useListAdminMembers';

interface EditMemberDto {
  name: string;
  url: string;
  id: string;
  type: MemberType;
  uniquepathUrl?: string;
}

export const editMember = (dto: EditMemberDto, image?: File | null) => {
  const formData = new FormData();
  formData.append(
    'editMemberDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (image) {
    formData.append('image', image);
  }

  return Axios({
    method: 'put',
    url: `${API_URL}/member/${dto.id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};
