import moment from 'moment';
import { RepetitionWithRoute } from './schedule-repetition';
import { Title } from './title.interface';

export enum NotificationType {
  SCHEDULE_CANCELLATION = 'SCHEDULE_CANCELLATION',
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  EVALUATE_ROUTE = 'EVALUATE_ROUTE',
  COMMENT = 'COMMENT',
  BOOKING_CANCEL = 'BOOKING_CANCEL',
  DRAFT_ROUTE = 'DRAFT_ROUTE',
  NEW_BOOKING = 'NEW_BOOKING',
  ROUTE_TO_START = 'ROUTE_TO_START',
  ROUTE_APPROVED = 'ROUTE_APPROVED',
  ROUTE_REJECTED = 'ROUTE_REJECTED',
  GUIDE_APPROVED = 'GUIDE_APPROVED',
}

export type GenericNotification =
  | BookingCancelNotification
  | CommentNotification
  | CompleteProfileNotification
  | DraftRouteNotification
  | EvaluateRouteNotification
  | NewBookingNotification
  | RouteApprovedNotification
  | RouteRejectedNotification
  | RouteToStartNotification
  | ScheduleCanceledNotification
  | ApprovedProfileNotification;

interface Notification {
  seen: boolean;
  notificationId: string;
  type: NotificationType;
}

export interface BookingCancelNotification extends Notification {
  type: NotificationType.BOOKING_CANCEL;
  bookingGroupSize: number;
  scheduleRepetitionWithRoute: RepetitionWithRoute;
}

export interface CommentNotification extends Notification {
  type: NotificationType.COMMENT;
  routeTitles: Title[];
  visitorName: string;
  commentDate: moment.Moment;
  commentId: string;
  visitorId: string;
}

export interface CompleteProfileNotification extends Notification {
  type: NotificationType.COMPLETE_PROFILE;
}
export interface ApprovedProfileNotification extends Notification {
  type: NotificationType.GUIDE_APPROVED;
}
export interface DraftRouteNotification extends Notification {
  type: NotificationType.DRAFT_ROUTE;
  routeTitles: Title[];
  routeId: string;
}

export interface EvaluateRouteNotification extends Notification {
  type: NotificationType.EVALUATE_ROUTE;
  routeTitles: Title[];
  routeId: string;
  bookingId: string;
}

export interface NewBookingNotification extends Notification {
  type: NotificationType.NEW_BOOKING;
  bookingGroupSize: number;
  scheduleRepetitionWithRoute: RepetitionWithRoute;
}

export interface RouteApprovedNotification extends Notification {
  type: NotificationType.ROUTE_APPROVED;
  routeTitles: Title[];
  routeId: string;
}

export interface RouteRejectedNotification extends Notification {
  type: NotificationType.ROUTE_REJECTED;
  routeTitles: Title[];
  routeId: string;
}

export interface RouteToStartNotification extends Notification {
  type: NotificationType.ROUTE_TO_START;
  routeTitles: Title[];
  repetitionGroupSize: number;
  repetitionId: string;
}

export interface ScheduleCanceledNotification extends Notification {
  type: NotificationType.SCHEDULE_CANCELLATION;
  routeTitles: Title[];
  scheduleDate: moment.Moment;
  routeId: string;
}
