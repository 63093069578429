import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  LocalizedElement,
  LocalizedElementMap,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { Company, useCompany } from '../../../hooks/company/useCompany';
import { useLoginForm } from '../../../pages/Login/useLoginForm';
import { ReactComponent as Email } from '../../../shared_assets/icons/email.svg';
import { ReactComponent as Lock } from '../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../shared_assets/icons/unsee.svg';
import { attemptLogin } from '../../../store/UserConfiguration/actions';
import { ErrorCode } from '../../../types/error.codes.enum';
import { RoleType } from '../../../types/role-type.enum';
import { AnimatedError } from '../../App/AnimatedError';
import { Button } from '../../App/Button';
import { Checkbox } from '../../App/Checkbox';
import { Error } from '../../App/Error';
import { InformationPopup } from '../../App/InformationPopup';
import { Input } from '../../App/Input';
import { Loader } from '../../App/Loader';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  dot: {
    maxHeight: '50%',
    maxWidth: '50%',
    borderRadius: '100%',
    borderWidth: 2,
    backgroundColor: 'transparent',

    borderColor: 'black',
    borderStyle: 'solid',
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderWidth: 2,
    flex: 1,
    alignSelf: 'center',
    borderColor: '#009CDE',
    borderRadius: '32px',
    borderStyle: 'solid',
    padding: '0 1rem 0 1rem',
    minWidth: '30%',
    maxHeight: '18rem',
  },
  popupMargin: {
    marginBottom: '1.5rem',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  marginBetween: {
    margin: '1rem 0',
  },
  formBottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  div: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  img: {
    maxWidth: '30%',
    objectFit: 'contain',
  },
  h1: {
    color: '#009CDE',
  },
});

interface CompanyLoginProps extends LocalizeContextProps {}

function CompanyLoginForm({ translate }: CompanyLoginProps) {
  const form = useLoginForm();
  const history = useHistory();
  const classes = useStyles();
  const [jwt] = UseJWT();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [isLoading, hasError] = useCompany(
    jwt?.roles.includes(RoleType.COMPANY) ? jwt?.companyId : undefined,
    setCompany
  );
  const [thereWasALoginError, setThereWasALoginError] =
    useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<
    LocalizedElement | LocalizedElementMap | undefined
  >(undefined);
  const [loginErrorDescription, setLoginErrorDescription] = useState<
    LocalizedElement | LocalizedElementMap | undefined
  >(undefined);
  const [feedbackType, setFeedbackType] = useState<
    'error' | 'success' | 'info'
  >('error');
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const signIn = useCallback(async () => {
    if (
      Object.values(form).some(formEntry =>
        typeof formEntry === 'function' ? false : formEntry.isInvalid
      )
    ) {
      setThereWasALoginError(true);
      return;
    }

    setIsLoggingIn(true);

    try {
      await dispatch(
        attemptLogin({
          email: form.email.value,
          password: form.password.value,
          type: RoleType.COMPANY,
        })
      );
      setThereWasALoginError(false);
      history.replace(`/company/area/list/guides`);
    } catch (err) {
      // type: 'error' | 'success' | 'info';

      switch (true) {
        case err.response.data.error === ErrorCode.EMAIL_NOT_CONFIRMED:
          setLoginErrorMessage(translate('login.badLoginMessage'));
          setLoginErrorDescription(
            translate(`error.API_ERROR.${ErrorCode.EMAIL_NOT_CONFIRMED}`)
          );
          setFeedbackType('info');
          break;
        case err.response.data.error === ErrorCode.NOT_APPROVED:
          setLoginErrorMessage(translate('login.badLoginMessage'));
          setLoginErrorDescription(
            translate(`error.API_ERROR.${ErrorCode.NOT_APPROVED}`)
          );
          setFeedbackType('info');
          break;

        default:
          setLoginErrorMessage(translate('login.badLoginMessage'));
          setLoginErrorDescription(translate('login.badLoginDescription'));
          setFeedbackType('error');
          break;
      }
      setThereWasALoginError(true);
    } finally {
      setIsLoggingIn(false);
    }

    setIsLoggingIn(false);
  }, [form, dispatch, history, translate]);
  const onRequestRegistration = useCallback(() => {
    history.replace(`/company/sign-up`);
  }, [history]);
  if (isLoading) return <Loader />;

  if (hasError && jwt?.roles.includes(RoleType.COMPANY)) return <Error />;

  return (
    <>
      <div className={classes.mainContainer}>
        <InformationPopup
          visible={thereWasALoginError}
          type={feedbackType}
          message={loginErrorMessage?.toString()}
          description={loginErrorDescription?.toString()}
          className={classes.popupMargin}
        />
        <Input
          huge
          type="email"
          placeholder={translate('login.email').toString()}
          onChange={event => {
            form.email.set(event.currentTarget.value);
          }}
          value={form.email.value}
          prefix={<Email className={classes.icon} />}
        />
        <AnimatedError
          isVisible={form.email.isInvalid}
          reason={form.email.reason}
        />
        <Input
          huge
          type={isPasswordShowing ? 'text' : 'password'}
          placeholder={translate('login.password').toString()}
          onChange={event => {
            form.password.set(event.currentTarget.value);
          }}
          value={form.password.value}
          prefix={<Lock className={classes.icon} />}
          suffix={
            isPasswordShowing ? (
              <div
                onClick={() => {
                  setIsPasswordShowing(prevState => !prevState);
                }}
              >
                <See className={classes.icon} />
              </div>
            ) : (
              <div
                onClick={() => {
                  setIsPasswordShowing(prevState => !prevState);
                }}
              >
                <Unsee className={classes.icon} />
              </div>
            )
          }
        />
        <AnimatedError
          isVisible={form.password.isInvalid}
          reason={form.password.reason}
        />
        <div>
          <Checkbox>
            <Text inline noMargin>
              <Translate id="login.rememberMe" />
            </Text>
          </Checkbox>
        </div>
        <Button
          className={classes.marginBetween}
          type="primary"
          size="large"
          onClick={signIn}
          disabled={isLoggingIn}
        >
          {isLoggingIn ? <BeatLoader /> : <Translate id="login.signIn" />}
        </Button>
        <Button type="link" onlyText onClick={onRequestRegistration}>
          <Translate id="login.signUp" />
        </Button>
      </div>
      <div className={classes.div}></div>
    </>
  );
}

export default withLocalize(CompanyLoginForm);
