import { makeStyles } from '@material-ui/styles';
import { message, Space } from 'antd';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../components/App/AnimatedError';
import { Button } from '../../../../components/App/Button';
import { FallbackImage } from '../../../../components/App/Image';
import { Input } from '../../../../components/App/Input';
import { Modal } from '../../../../components/App/Modal';
import { Option, Select } from '../../../../components/App/Select';
import { Text } from '../../../../components/Typography';
import { addMember } from '../../../../hooks/administration/addMember';
import { editMember } from '../../../../hooks/administration/editMember';
import {
  getMemberImage,
  MemberInterface,
  MemberType,
} from '../../../../hooks/administration/useListAdminMembers';
import { useFilePreview } from '../../../../hooks/useFilePreview';
import { ReactComponent as Pediguia } from '../../../../shared_assets/images/pediguia_default.svg';
import { complexTranslate, maxAllowedImageSize } from '../../../../utils';

const useStyles = makeStyles({
  footer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: 20,
  },
  imageSize: {
    width: '10rem',
  },
  select: {
    width: '100%',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

interface CreateMemberModalProps extends LocalizeContextProps {
  visible: boolean;
  onRequestClose: () => void;
  memberContent?: MemberInterface;
  onAdd?: (v: MemberInterface) => void;
  onEdit?: (v: MemberInterface) => void;
}

const CreateMemberModal = ({
  visible,
  memberContent,
  onRequestClose,
  onAdd,
  onEdit,
  translate,
}: CreateMemberModalProps) => {
  const [name, setName] = useState<string>('');
  const [uniquePathUrl, setUniquePathUrl] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [type, setType] = useState<MemberType>(MemberType.PREMIUM);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const previewSource = useMemo(() => [image], [image]);
  const imagePreview = useFilePreview(previewSource);
  const classes = useStyles();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const typeOptions = useMemo(() => {
    return Object.keys(MemberType).map(r => {
      return (
        <Option key={r} value={r}>
          <Text>{r}</Text>
        </Option>
      );
    });
  }, []);
  useEffect(() => {
    if (memberContent) {
      setName(memberContent.name);
      setUniquePathUrl(memberContent.uniquepathUrl);
      setUrl(memberContent.url);
      setType(memberContent.type);
    }
  }, [memberContent, visible]);

  const action = useCallback(async () => {
    try {
      setSubmitting(true);
      if (memberContent) {
        //edit

        const { data } = await editMember(
          {
            name,
            uniquepathUrl: uniquePathUrl,
            url,
            id: memberContent.id,
            type,
          },
          image
        );
        if (onEdit) onEdit(data);
      } else {
        //add
        if (image === null) return;
        //FAZER ERROR
        const { data } = await addMember(
          {
            name,
            uniquepathUrl: uniquePathUrl,
            url,
            type,
          },
          image
        );

        if (onAdd) onAdd(data);
      }
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [memberContent, name, uniquePathUrl, url, image, onEdit, type, onAdd]);

  const errorImage = useMemo(() => {
    if (image === null && onAdd) {
      return translate('adminstration.adEventArea.mandatoryImage');
    }
  }, [image, onAdd, translate]);
  const errorUrl = useMemo(() => {
    if (validator.isEmpty(url)) {
      return translate('adminstration.adArea.nameError');
    }
  }, [translate, url]);
  const errorName = useMemo(() => {
    if (validator.isEmpty(name)) {
      return translate('adminstration.adArea.nameError');
    }
  }, [name, translate]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      footer={
        <div className={classes.footer}>
          <Button
            type="primary"
            disabled={!!errorName || !!errorUrl || submitting}
            onClick={action}
          >
            <Translate id="button.save" />
          </Button>
          <Button disabled={submitting} type="primary" onClick={onRequestClose}>
            <Translate id="button.cancel" />
          </Button>
        </div>
      }
      visible={visible}
    >
      <div className={classes.content}>
        <Space
          direction="vertical"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
          size="large"
        >
          {memberContent !== undefined ? (
            <FallbackImage
              className={classes.imageSize}
              fallbackComponent={<Pediguia />}
              src={imagePreview?.[0] || getMemberImage(memberContent.id)}
            />
          ) : (
            <FallbackImage
              className={classes.imageSize}
              src={imagePreview?.[0] || undefined}
              fallback={require('../../../../shared_assets/images/pediguia_default.svg')}
            />
          )}

          <Translate id="profile.changePhoto" />
          <input
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <Button
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </Button>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
          <AnimatedError isVisible={!!errorImage} reason={errorImage} />
        </Space>
        <Text noMargin weight="semibold">
          <Translate id="administration.member.name" />
        </Text>
        <Input
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <AnimatedError isVisible={!!errorName} reason={errorName} />
        <Divider />
        <Text noMargin weight="semibold">
          <Translate id="administration.member.url" />
        </Text>
        <Input
          value={url}
          onChange={e => {
            setUrl(e.target.value);
          }}
        />
        <AnimatedError isVisible={!!errorUrl} reason={errorUrl} />

        <Divider />
        <Text noMargin weight="semibold">
          <Translate id="administration.member.uniquepathURL" />
        </Text>
        <Input
          value={uniquePathUrl}
          onChange={e => {
            setUniquePathUrl(e.target.value);
          }}
        />
        <Divider />
        <Text noMargin weight="semibold">
          <Translate id="administration.member.type" />
        </Text>
        <Select
          //disabled={!!onEdit}
          placeholder={translate('onboarding.retentionPlaceholder').toString()}
          className={classes.select}
          value={type}
          onChange={ev => setType(ev as MemberType)}
          huge
          getPopupContainer={() => document.body}
        >
          {typeOptions}
        </Select>
      </div>
    </Modal>
  );
};

export default React.memo(withLocalize(CreateMemberModal));
