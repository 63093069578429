import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';

import { Button } from '../../components/App/Button';
import { Modal } from '../../components/App/Modal';
import { Text } from '../../components/Typography';
import ChooseMethod, { RegistrationMethod } from './ChooseMethod';
import EmailRegistration from './EmailRegistration';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  marginBetween: {
    margin: '1rem 0',
  },
  noMarginTop: {
    marginTop: 'unset',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '1rem',
    '& > h3': {
      margin: '0.5rem 0 0.7rem 0',
    },
  },
  justifiedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  dividerBottom: {
    margin: '1rem 0 0.5rem 0',
  },
  formBottom: {
    display: 'flex',
    paddingBottom: '2rem',
    justifyContent: 'space-between',
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

export type RegisterType = 'VISITOR' | 'GUIDE';

export type ActiveRegistrationMethod = RegistrationMethod | undefined;

interface RegisterProps {
  registerType: RegisterType;
  isVisible: boolean;
  onRequestOpenLogin: () => void;
  onRegistrationSuccess: () => void;
  onRequestClose: () => void;
}

function Register({
  registerType,
  isVisible,
  onRequestOpenLogin,
  onRegistrationSuccess,
  onRequestClose,
}: RegisterProps) {
  const classes = useStyles();
  const [
    activeRegistrationMethod,
    setActiveRegistrationMethod,
  ] = useState<ActiveRegistrationMethod>();

  const onClose = useCallback(() => {
    setActiveRegistrationMethod(undefined);
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal
      visible={isVisible}
      variant="close"
      headerTitle={
        <>
          <Translate id="landing.drawer.signUp" />
          {' - '}
          {registerType === 'GUIDE' ? (
            <Translate id="guide" />
          ) : (
            <Translate id="visitor" />
          )}
        </>
      }
      onRequestClose={onClose}
      footer={null}
    >
      <div className={classes.container}>
        <Divider
          className={classNames(classes.marginBetween, classes.noMarginTop)}
        />
        {activeRegistrationMethod === 'email' ? (
          <EmailRegistration
            onChangeMethod={setActiveRegistrationMethod}
            onRegistrationSuccess={onRegistrationSuccess}
            registerType={registerType}
            classes={classes}
          />
        ) : (
          <ChooseMethod
            classes={classes}
            onChooseMethod={setActiveRegistrationMethod}
            onRequestClose={onClose}
            signUpType={registerType}
          />
        )}

        <Divider className={classNames(classes.marginBetween)} />
        <div
          className={classNames(classes.justifiedContainer, classes.formBottom)}
        >
          <Text noMargin style={{ marginRight: '0.25rem' }}>
            <Translate id="register.signInQuestion" />
          </Text>
          <Button type="link" onlyText onClick={onRequestOpenLogin}>
            <Text variant="link" noMargin>
              <Translate id="register.signIn" />
            </Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(Register);
