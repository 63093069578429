import Axios from 'axios';
import { API_URL } from '../../../consts';
import { GetVisitorStepsResponse } from './useSteps';

interface VisitorStep {
  language: string;
  title: string;
  description: string;
  position?: number | undefined;
  id?: string | undefined;
}

type EditVisitorStepsDto = { steps: VisitorStep[] };

export const editVisitorStep = (dto: EditVisitorStepsDto) =>
  Axios.put<GetVisitorStepsResponse>(
    `${API_URL}/content-management/visitor-landing-page/steps`,
    dto
  );
