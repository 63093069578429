import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import moment from 'moment';
import React from 'react';
import { ReactComponent as Pediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { getTheme } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { FallbackAvatar } from '../Avatar';
import { FallbackImage } from '../Image';

const useListStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const useTestimonyStyles = makeStyles({
  container: () => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: getTheme().primary,
    color: 'white !important',
    padding: '1.5rem 1rem',
    borderRadius: '5px',
    margin: '0.5rem 0',
    '& *': {
      color: 'white !important',
    },
  }),
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    marginRight: '0.5rem',
    width: '2.7rem',
    height: '2.7rem',
    border: '2px solid white',
    flexShrink: 0,
  },
});

export interface TestimonyProps {
  id: string;
  description: string;
  image: string;
  name: string;
  locality: string;
  date: moment.Moment;
}

export interface TestimonyListProps {
  data: TestimonyProps[];
}

export function TestimonyList({ data }: TestimonyListProps) {
  const classes = useListStyles();
  return (
    <div className={classes.container}>
      {data.map(testimony => (
        <Testimony key={testimony.id} {...testimony} />
      ))}
    </div>
  );
}

export function Testimony({
  id,
  description,
  image,
  name,
  locality,
  date,
}: TestimonyProps) {
  const classes = useTestimonyStyles();

  return (
    <div className={classes.container}>
      <Heading level={4}>{`"${description}"`}</Heading>
      <Divider />
      <div className={classes.infoContainer}>
        <FallbackAvatar
          src={<FallbackImage src={image} fallbackComponent={<Pediguia />} />}
          className={classes.image}
        />
        <div>
          <Text weight="bold" noMargin>
            {name}
          </Text>
          <Text noMargin>{`${locality}, ${date.format('D MMM YYYY')}`}</Text>
        </div>
      </div>
    </div>
  );
}
