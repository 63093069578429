import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { GetYears } from '../../../components/App/BirthDateSelector/getDate';
import { Button } from '../../../components/App/Button';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import { Loader } from '../../../components/App/Loader';
import { Option, Select } from '../../../components/App/Select';
import { Heading, Text } from '../../../components/Typography';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import {
  getGuidePaymentsExportUrl,
  useGuidePayments,
} from '../../../hooks/guide/useGuidePayments';
import { useGuideRoutes } from '../../../hooks/guide/UseGuideRoutes';
import { ReactComponent as Payment } from '../../../shared_assets/icons/payment.svg';
import { RootState } from '../../../store';
import { Route } from '../../../types/route.interface';
import { Title } from '../../../types/title.interface';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  mobilePadding,
  mobileThreshhold,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import PaymentEntry from './PaymentEntry';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  subtitle: {
    marginBottom: '1.5rem !important',
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
  },
  filterParent: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  select: {
    flex: 1,
    margin: '0 0.5rem',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 0',
  },
  desktopPrefix: {
    width: 40,
    marginRight: '1rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    contentContainer: {
      padding: '0 56px',
    },
    filterParent: {
      flexDirection: 'row',
      '& > *': {
        flex: 1,
        margin: '0 1rem',
      },
      '& > *:first-child': {
        marginLeft: 0,
      },
      '& > *:last-child': {
        marginRight: 0,
      },
    },
    body: {
      ...desktopPadding,
    },
  },
});

const years = GetYears(1).reverse();

const PaymentHistory: React.FC<LocalizeContextProps> = ({ translate }) => {
  const classes = useStyles();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [jwt] = UseJWT();
  const [routes, setRoutes] = useState<Route[]>([]);
  const [routesAreLoading, routesHaveError] = useGuideRoutes(
    jwt?.guideId,
    setRoutes
  );
  const months = useMemo(() => {
    return moment.months();
  }, []);
  const [fromMonth, setFromMonth] = useState<number>(moment().get('month'));
  const [fromYear, setFromYear] = useState<number>(1);

  const [selectedRoute, setSelectedRoute] = useState<string | undefined>();

  const [toMonth, setToMonth] = useState<number>(
    moment().get('month') + 1 > months.length - 1
      ? 0
      : moment().get('month') + 1
  );
  const [toYear, setToYear] = useState<number>(toMonth === 0 ? 2 : 1);
  const getRouteTitle = useCallback(
    (titles: Title[]) => {
      const translation = titles.find(t => t.language === activeLanguage);
      return translation ? translation.title : titles[0].title;
    },
    [activeLanguage]
  );
  const dto = useMemo(
    () => ({
      from: moment({ year: years[fromYear], month: fromMonth })
        .startOf('month')
        .toDate(),
      to: moment({ year: years[toYear], month: toMonth })
        .startOf('month')
        .toDate(),
      routeId: selectedRoute,
    }),
    [fromMonth, fromYear, selectedRoute, toMonth, toYear]
  );

  const [paymentsAreLoading, paymentsHaveError, payments] =
    useGuidePayments(dto);
  const total = useMemo(() => {
    let counter = 0;

    payments.forEach(pay => {
      counter += pay.amount;
    });

    return counter;
  }, [payments]);

  if (routesAreLoading || paymentsAreLoading) return <Loader />;

  if (routesHaveError || paymentsHaveError) return <Error />;
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.body}>
        <Heading level={2}>
          <Payment className={classes.desktopPrefix} />
          <Translate id="paymentHistory.title" />
        </Heading>

        <div className={classes.contentContainer}>
          <Text className={classes.subtitle} size={16}>
            <Translate id="paymentHistory.subtitle" />
          </Text>
          <Select
            onChange={ev => {
              if (ev === 'all') setSelectedRoute(undefined);
              else setSelectedRoute(ev as string);
            }}
            huge
            value={selectedRoute}
            defaultValue="all"
          >
            <Option
              value="all"
              label={translate(
                'paymentHistory.allRoutesPlaceholder'
              ).toString()}
            >
              <Translate id="paymentHistory.allRoutesPlaceholder" />
            </Option>
            {routes.map(route => (
              <Option
                key={route.id}
                value={route.id}
                label={getRouteTitle(route.titles)}
              >
                {getRouteTitle(route.titles)}
              </Option>
            ))}
          </Select>
          <div className={classes.filterParent}>
            <div>
              <Text weight="semibold">
                <Translate id="paymentHistory.from" />
              </Text>
              <div className={classes.filterContainer}>
                <Select
                  value={fromMonth}
                  onChange={ev => setFromMonth(parseInt(ev as string))}
                  huge
                  className={classes.select}
                >
                  {months.map((month, i) => (
                    <Option value={i} label={month} key={i.toString()}>
                      <Text>{month}</Text>
                    </Option>
                  ))}
                </Select>
                <Select
                  value={fromYear}
                  onChange={ev => setFromYear(parseInt(ev as string))}
                  huge
                  className={classes.select}
                >
                  {years.map((year, i) => (
                    <Option label={year} value={i} key={i.toString()}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div>
              <Text weight="semibold">
                <Translate id="paymentHistory.to" />
              </Text>
              <div className={classes.filterContainer}>
                <Select
                  value={toMonth}
                  onChange={ev => setToMonth(parseInt(ev as string))}
                  huge
                  className={classes.select}
                >
                  {months.map((month, i) => (
                    <Option label={month} value={i} key={i.toString()}>
                      <Text>{month}</Text>
                    </Option>
                  ))}
                </Select>
                <Select
                  value={toYear}
                  onChange={ev => setToYear(parseInt(ev as string))}
                  huge
                  className={classes.select}
                >
                  {years.map((year, i) => (
                    <Option label={year} value={i} key={i.toString()}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className={classes.totalContainer}>
            <Heading inline noMargin level={4}>
              <Translate id="paymentHistory.earned" />
              <Text size={18} inline>
                {complexTranslate(
                  translate('paymentHistory.amount').toString(),
                  {
                    '{VALUE}': total.toFixed(2),
                  }
                )}
              </Text>
            </Heading>
            <Button
              onlyText
              color={payments.length > 0 ? undefined : getTheme().neutral1}
              onClick={() => {
                window.open(getGuidePaymentsExportUrl(dto), '_blank');
              }}
              type="link"
            >
              <Translate id="paymentHistory.exportCSV" />
            </Button>
          </div>
          {payments.length === 0 ? (
            <div>
              <Text weight="bold" noMargin>
                <Translate id="paymentHistory.emptyTitle" />
              </Text>
              <Text>
                <Translate id="paymentHistory.emptySubtitle" />
              </Text>
            </div>
          ) : (
            payments.map((payment, index) => (
              <PaymentEntry key={index} {...payment} />
            ))
          )}
        </div>
      </div>
      <CopyrightFooter />
    </motion.div>
  );
};

export default withLocalize(PaymentHistory);
