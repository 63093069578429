import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect, useHistory } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { AnimatedAlert } from '../../../components/Administration/AnimatedAlert';
import { AnimatedError } from '../../../components/App/AnimatedError';
import { BackButton } from '../../../components/App/BackButton';
import { BirthDateSelector } from '../../../components/App/BirthDateSelector';
import { Button } from '../../../components/App/Button';
import { Error } from '../../../components/App/Error';
import { Input } from '../../../components/App/Input';
import { Loader } from '../../../components/App/Loader';
import { Option, Select } from '../../../components/App/Select';
import { Heading, Text } from '../../../components/Typography';
import {
  FinancialInterface,
  useFinancial,
} from '../../../hooks/administration/useFinancial';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { editGuide } from '../../../hooks/guide/EditGuide';
import { useGuide } from '../../../hooks/guide/UseGuide';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { ReactComponent as Contacts } from '../../../shared_assets/icons/contactos.svg';
import { ReactComponent as Home } from '../../../shared_assets/icons/home.svg';
import { ReactComponent as Info } from '../../../shared_assets/icons/info.svg';
import { ReactComponent as MapMarker } from '../../../shared_assets/icons/map_marker.svg';
import { FinancialType } from '../../../types/financial.type';
import { GenderType } from '../../../types/gender-type.enum';
import { Guide } from '../../../types/guide.interface';
import { Nationality } from '../../../types/nationality.enum';
import {
  complexTranslate,
  desktopPadding,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import CancelGuideProfileModal from './CancelGuideProfileModal';
import { usePersonalProfileForm } from './usePersonalProfileForm';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  warningBody: {
    paddingTop: '1rem',
    display: 'flex',
    padding: '0 25%',
    flex: 1,
  },
  body: {
    ...mobilePadding,
  },
  select: {
    width: '100%',
  },
  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    '& img, & svg': {
      height: 16,
    },
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  headerDivider: {
    marginTop: 0,
  },
  introIcon: {
    marginRight: '1rem',
    height: '1.5rem',
  },
  introRow: {
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center',
  },
  row: { display: 'flex', alignItems: 'center' },
  noMargin: {
    margin: 0,
  },
  spacer: {
    margin: '0 1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    marginTop: '1rem',
    '& > *': {
      marginBottom: '1rem',
    },
  },
  title: {
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.2,
      minWidth: 150,
      '& > *:nth-child(2)': {
        marginTop: '1rem',
      },
    },
    infoZone: {
      flex: 0.8,
    },
  },
});
interface SortedNationalities {
  key: string;
  translation: string;
}
function ProfilePersonalEdit({ translate }: LocalizeContextProps) {
  const classes = useStyles();
  const [guideProfile, setGuideProfile] = useState<Guide | null>(null);
  const [jwt] = UseJWT();
  const [isLoading, hasError] = useGuide(jwt?.guideId, setGuideProfile);
  const history = useHistory();
  const form = usePersonalProfileForm(guideProfile);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [cancelShowing, setCancelShowing] = useState<boolean>(false);
  const [financial, setFinancial] = useState<FinancialInterface | null>(null);
  useFinancial(setFinancial);

  const ivaOptions = useMemo(() => {
    if (!financial) return;

    return financial.ivas.map(i => {
      return (
        <Option key={i.id} value={i.id}>
          {i.relevance === 'MINOR' ? (
            <Text variant="faded">{i.invoiceMention}</Text>
          ) : (
            <Text>{i.invoiceMention}</Text>
          )}
        </Option>
      );
    });
  }, [financial]);
  const retentionsOptions = useMemo(() => {
    if (!financial) return;

    return financial.retentions.map(r => {
      return (
        <Option key={r.id} value={r.id}>
          <Text>{r.invoiceMention}</Text>
        </Option>
      );
    });
  }, [financial]);
  const changeDate = useCallback(newDate => {
    form.birthDay.set(newDate.day);
    form.birthMonth.set(newDate.month);
    form.birthYear.set(newDate.year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sortedNationalities: SortedNationalities[] = Object.values(Nationality)
    .map(nat => {
      return {
        key: nat,
        translation: translate(`nationalities.${nat}`).toString(),
      };
    })
    .sort((a, b) => a.translation.localeCompare(b.translation));
  const closeCancel = useCallback(() => {
    setCancelShowing(false);
  }, []);

  const openCancel = useCallback(() => {
    setCancelShowing(true);
  }, []);
  const editPersonalProfileCallback = useCallback(async () => {
    if (!jwt) return;

    try {
      if (
        Object.values(form).find(entry => {
          if (entry.isInvalid) {
            message.error(entry.reason);
            return true;
          }
          return false;
        })
      ) {
        return;
      }

      const date = moment.utc({
        year: parseInt(form.birthYear.value),
        month: parseInt(form.birthMonth.value),
        day: parseInt(form.birthDay.value),
      });

      if (!date.isValid()) {
        message.error(translate('profile.birthDateMandatory').toString());
        return;
      }

      setIsUpdating(true);

      const { data: updatedGuide } = await editGuide(jwt.guideId, {
        address: form.address.value,
        postalCode: `${form.zipFirstSegment.value}-${form.zipSecondSegment.value}`,
        location: form.location.value,
        phoneNumber: form.phoneNumber.value,
        gender: form.gender.value as GenderType,
        iban: form.iban.value,
        nationality: form.nationality.value,
        dateOfBirth: date.toDate(),
        nif: form.nif.value,
        companyName: form.companyName.value,
        financialType: guideProfile?.financialType,
        ivaExemption: form.ivaExemption.value,
        retention: form.retention.value,
        userEdit: true,
      });

      updatedGuide.dateOfBirth = new Date(updatedGuide.dateOfBirth);

      setGuideProfile(updatedGuide);
      message.success(translate('profile.updatedSuccessfully').toString());
    } catch (err) {
    } finally {
      setIsUpdating(false);
    }
  }, [form, guideProfile, jwt, translate]);

  const isMobile = useIsMobile();

  if (!jwt) {
    return <Redirect to="/guide" />;
  }

  if (!guideProfile || isLoading) {
    return <Loader />;
  }
  if (hasError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {isMobile && (
        <>
          <div className={classes.header}>
            <div onClick={history.goBack}>
              <ArrowBack />
            </div>
          </div>
          <Divider className={classes.headerDivider} />
        </>
      )}
      <div className={classes.warningBody}>
        {guideProfile.financialType !== FinancialType.COMPANY ? (
          <AnimatedAlert
            visible
            message={complexTranslate(
              translate('profile.editWarning').toString(),
              {
                '{changeFinancialLink}': (
                  <a
                    key={'changeFinancialLink'}
                    href="https://bo.magnifinance.com"
                  >
                    <Button
                      key="1"
                      type="link"
                      target="_blank"
                      className={classNames(
                        classes.noMargin,
                        classes.inlineBtn
                      )}
                      onlyText
                    >
                      {translate('profile.changeFinancialLink').toString()}
                    </Button>
                  </a>
                ),
              }
            )}
            type="info"
            showIcon
          />
        ) : (
          <AnimatedAlert
            visible
            message={translate('profile.editWarningGuideIsOwnByCompany')}
            type="info"
            showIcon
          />
        )}
      </div>
      <div className={classNames(classes.body, classes.page)}>
        <Heading className={classes.title} level={2}>
          <Translate id="profile.editPersonalInfo" />
        </Heading>
        <div className={classes.bodyContainer}>
          {!isMobile && (
            <div className={classes.backZone}>
              <Button
                disabled={isUpdating}
                type="primary"
                onClick={
                  editPersonalProfileCallback
                } /*disabled={saveDisabled}*/
              >
                {isUpdating ? <BeatLoader /> : <Translate id="profile.save" />}
              </Button>
              <BackButton />
            </div>
          )}
          <div className={classes.infoZone}>
            <div className={classes.introRow}>
              <Info className={classes.introIcon} />
              <Text noMargin>
                <Translate id="profile.personalInfo" />
              </Text>
            </div>
            <Text weight="semibold">
              <Translate id="profile.address" />
            </Text>
            <Input
              prefix={<Home />}
              placeholder={translate('profile.selectAnAddress').toString()}
              huge
              disabled={isUpdating}
              onChange={ev => {
                form.address.set(ev.currentTarget.value);
              }}
              value={form.address.value}
            />
            <AnimatedError
              isVisible={form.address.isInvalid}
              reason={form.address.reason}
            />
            <Text weight="semibold">
              <Translate id="profile.postalCode" />
            </Text>
            <div className={classes.row}>
              <Input
                prefix={<Home />}
                disabled={isUpdating}
                type="number"
                huge
                placeholder="XXXX"
                onChange={ev => {
                  form.zipFirstSegment.set(ev.currentTarget.value);
                }}
                value={form.zipFirstSegment.value}
              />
              <span className={classes.spacer}>-</span>
              <Input
                prefix={<Home />}
                placeholder="XXX"
                type="number"
                huge
                onChange={ev => {
                  form.zipSecondSegment.set(ev.currentTarget.value);
                }}
                value={form.zipSecondSegment.value}
              />
            </div>
            <AnimatedError
              isVisible={
                form.zipFirstSegment.isInvalid ||
                form.zipSecondSegment.isInvalid
              }
              reason={
                form.zipFirstSegment.reason || form.zipSecondSegment.reason
              }
            />

            <Text weight="semibold">
              <Translate id="profile.location" />
            </Text>
            <Input
              prefix={<MapMarker />}
              placeholder={translate('profile.selectLocation').toString()}
              huge
              disabled={isUpdating}
              onChange={ev => {
                form.location.set(ev.currentTarget.value);
              }}
              value={form.location.value}
            />
            <AnimatedError
              isVisible={form.location.isInvalid}
              reason={form.location.reason}
            />

            <Text weight="semibold">
              <Translate id="profile.dateOfBirth" />
            </Text>
            <BirthDateSelector
              onDateChange={changeDate}
              isInvalid={
                form.birthYear.isInvalid ||
                form.birthMonth.isInvalid ||
                form.birthDay.isInvalid
              }
              reason={
                form.birthYear.reason ||
                form.birthMonth.reason ||
                form.birthDay.reason
              }
              initialValue={guideProfile.dateOfBirth}
            />

            <Text weight="semibold">
              <Translate id="profile.phoneNumber" />
            </Text>
            <Input
              placeholder={translate('profile.phoneNumber').toString()}
              huge
              disabled={isUpdating}
              type="number"
              onChange={ev => {
                form.phoneNumber.set(ev.currentTarget.value);
              }}
              prefix={<Contacts />}
              value={form.phoneNumber.value}
            />
            <AnimatedError
              isVisible={form.phoneNumber.isInvalid}
              reason={form.phoneNumber.reason}
            />

            <Text weight="semibold">
              <Translate id="profile.gender" />
            </Text>

            <Select
              className={classes.select}
              defaultValue={undefined}
              huge
              disabled={isUpdating}
              onChange={v => form.gender.set(v.toString())}
              value={form.gender.value || undefined}
            >
              {Object.keys(GenderType).map(gender => (
                <Option
                  label={translate(`genders.${gender}`).toString()}
                  key={gender}
                  huge
                  value={gender}
                >
                  <Translate id={`genders.${gender}`} />
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={form.gender.isInvalid}
              reason={form.gender.reason}
            />
            <Text weight="semibold">
              <Translate id="profile.nationality" />
            </Text>

            <Select
              className={classes.select}
              onChange={v => form.nationality.set(v.toString())}
              huge
              disabled={isUpdating}
              value={form.nationality.value || undefined}
            >
              {sortedNationalities.map(nat => (
                <Option key={nat.key} value={nat.key}>
                  {nat.translation}
                </Option>
              ))}
            </Select>
            <AnimatedError
              isVisible={form.nationality.isInvalid}
              reason={form.nationality.reason}
            />
            {guideProfile.financialType !== FinancialType.COMPANY ? (
              <>
                <Text weight="semibold">
                  <Translate id="profile.iban" />
                </Text>
                <Input
                  placeholder={translate('profile.iban').toString()}
                  huge
                  disabled={isUpdating}
                  onChange={ev => {
                    form.iban.set(ev.currentTarget.value);
                  }}
                  value={form.iban.value}
                />
                <AnimatedError
                  isVisible={form.iban.isInvalid}
                  reason={form.iban.reason}
                />
                <Text weight="semibold">
                  <Translate id="profile.nif" />
                </Text>
                <Input
                  placeholder={translate('profile.nif').toString()}
                  huge
                  disabled={
                    guideProfile.financialType !== FinancialType.NOT_APPROVED
                  }
                  onChange={ev => {
                    form.nif.set(ev.currentTarget.value);
                  }}
                  value={form.nif.value}
                />
                <AnimatedError
                  isVisible={form.nif.isInvalid}
                  reason={form.nif.reason}
                />
                <Text weight="semibold">
                  <Translate id="profile.companyName" />
                </Text>
                <Input
                  placeholder={translate('profile.companyName').toString()}
                  huge
                  disabled={isUpdating}
                  onChange={ev => {
                    form.companyName.set(ev.currentTarget.value);
                  }}
                  value={form.companyName.value}
                />
                <AnimatedError
                  isVisible={form.companyName.isInvalid}
                  reason={form.companyName.reason}
                />
                <Text noMargin weight="semibold">
                  <Translate id="onboarding.ivaExemption" />
                </Text>

                <Select
                  disabled={isUpdating}
                  placeholder={translate(
                    'onboarding.ivaExemptionPlaceholder'
                  ).toString()}
                  className={classes.select}
                  value={
                    form.ivaExemption.value === null
                      ? undefined
                      : form.ivaExemption.value
                  }
                  onChange={ev => form.ivaExemption.set(ev as string)}
                  huge
                  getPopupContainer={() => document.body}
                >
                  {ivaOptions}
                </Select>
                <Text variant="faded">
                  {form.ivaExemption.value
                    ? financial?.ivas.find(
                        i => i.id === form.ivaExemption.value
                      )?.applicableStandard
                    : ''}
                </Text>
                <AnimatedError
                  isVisible={form.ivaExemption.isInvalid}
                  reason={form.ivaExemption.reason}
                />

                <Text noMargin weight="semibold">
                  <Translate id="onboarding.retention" />
                </Text>

                <Select
                  disabled={isUpdating}
                  placeholder={translate(
                    'onboarding.retentionPlaceholder'
                  ).toString()}
                  className={classes.select}
                  value={
                    form.retention.value === null
                      ? undefined
                      : form.retention.value
                  }
                  onChange={ev => form.retention.set(ev as string)}
                  huge
                  getPopupContainer={() => document.body}
                >
                  {retentionsOptions}
                </Select>
                <Text variant="faded">
                  {form.retention.value
                    ? financial?.retentions.find(
                        r => r.id === form.retention.value
                      )?.applicableStandard
                    : ''}
                </Text>
                <AnimatedError
                  isVisible={form.retention.isInvalid}
                  reason={form.retention.reason}
                />
              </>
            ) : null}
            {!isMobile && (
              <Button type="ghost" danger onClick={openCancel}>
                <Translate id="button.cancelProfile" />
              </Button>
            )}
            {isMobile && (
              <div className={classes.buttonContainer}>
                <Button
                  disabled={isUpdating}
                  type="primary"
                  onClick={editPersonalProfileCallback}
                >
                  {isUpdating ? (
                    <BeatLoader />
                  ) : (
                    <Translate id="profile.save" />
                  )}
                </Button>
                <Button
                  type="link"
                  disabled={isUpdating}
                  onClick={history.goBack}
                >
                  <Translate id="profile.cancel" />
                </Button>
                <Button type="ghost" danger onClick={openCancel}>
                  <Translate id="button.cancelProfile" />
                </Button>
              </div>
            )}

            <CancelGuideProfileModal
              visible={cancelShowing}
              onRequestClose={closeCancel}
              nameToConfirm={
                guideProfile.firstName + ' ' + guideProfile.lastName
              }
              guideId={guideProfile.id}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default withLocalize(ProfilePersonalEdit);
