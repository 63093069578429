import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/App/Button';
import { FallbackImage } from '../../../../components/App/Image';
import { MakeReviewModal } from '../../../../components/App/MakeReviewModal';
import { Text } from '../../../../components/Typography';
import { getLocationPhotoUrl } from '../../../../hooks/routes/location/useLocationPhoto';
import { VisitorBookingInterface } from '../../../../hooks/visitor/booking/useVisitorBookings';
import { BookingState } from '../../../../types/booking-state.enum';
import { getTheme } from '../../../../utils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyItems: 'center',
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '150px',
    paddingBottom: '15px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'cover',
    borderRadius: 6,
  },
  imageContainer: {
    flex: 1,
    overflow: 'hidden',
    alignItems: 'center',
    maxHeight: 100,
    maxWidth: 200,
  },
  bookingRouteInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    '& > *': {
      margin: 0,
    },
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstLetterUpper: {
    textTransform: 'initial',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  badgeMargin: {
    marginBottom: '0.5rem',
  },
  buttonContainer: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: '1rem',
    },
  },
});

export interface DesktopBookingHistoryEntryProps
  extends VisitorBookingInterface {
  onReview: (bookingId: string, reviewId: string) => void;
}

function DesktopBookingHistoryEntry({
  id,
  state,
  routeTitle,
  locationPhoto,
  repetitionDate,
  reviewId,
  routeId,
  onReview,
}: DesktopBookingHistoryEntryProps) {
  const classes = useStyles();
  let stateColor = getTheme().warningBase;

  const [reviewShowing, setReviewShowing] = useState<boolean>(false);

  const closeReview = useCallback(() => {
    setReviewShowing(false);
  }, []);

  const openReview = useCallback(() => {
    setReviewShowing(true);
  }, []);

  const reviewMade = useCallback(
    (reviewId: string) => {
      onReview(id, reviewId);
      closeReview();
    },
    [closeReview, id, onReview]
  );

  if (state === 'PAYED') stateColor = getTheme().successBase;
  else if (state === 'CANCELED') stateColor = getTheme().errorBase;

  return (
    <div className={classes.page} key={id.toString()}>
      <div className={classes.bookingRouteInfo}>
        <Text
          variant="note"
          color={stateColor}
          weight="semibold"
          noMargin
          className={classNames(classes.uppercase)}
        >
          {state === BookingState.PAYED ? (
            <Translate id="adminstration.scheduleStateFinished" />
          ) : (
            <Translate id={`bookingState.${state}`} />
          )}
        </Text>

        <Text ellipsis={{ rows: 2 }} weight="semibold" noMargin>
          {routeTitle}
        </Text>
        <Text variant="note" color={getTheme().neutral1}>
          {moment(repetitionDate).format('l[,] LT')}
        </Text>
        <div className={classes.buttonContainer}>
          <Link
            to={{
              pathname: `/visitor/bookings/${id}`,
              state: { allowCancellation: true },
            }}
          >
            <Button onlyText type="link">
              <Text
                variant="note"
                color={getTheme().primary}
                noMargin
                className={classes.firstLetterUpper}
              >
                <Translate id="button.showMore" />
              </Text>
            </Button>
          </Link>
          {!reviewId && (
            <>
              <Button onlyText type="link" onClick={openReview}>
                <Text
                  variant="note"
                  color={getTheme().primary}
                  noMargin
                  className={classes.firstLetterUpper}
                >
                  <Translate id="button.evaluateRoute" />
                </Text>
              </Button>
              <MakeReviewModal
                bookingId={id}
                routeId={routeId}
                onDismiss={closeReview}
                onFinish={reviewMade}
                visible={reviewShowing}
              />
            </>
          )}
        </div>
      </div>
      <div className={classes.imageContainer}>
        <FallbackImage
          className={classes.image}
          fallback={require('../../../../shared_assets/images/route-default.png')}
          src={getLocationPhotoUrl(locationPhoto)}
          alt="place"
        />
      </div>
    </div>
  );
}

export default React.memo(DesktopBookingHistoryEntry);
