import { makeStyles } from '@material-ui/styles';
import { Menu } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { LogoUrl } from '../../../hooks/customization/getLogo';
import { logout } from '../../../store/UserConfiguration/actions';
import FallbackImage from '../../App/Image/FallbackImage';
const useStyles = makeStyles({
  logo: {
    flex: 0,
    maxHeight: '100%',
  },
});

export const CompanySideNav = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [jwt] = UseJWT();

  const handleGuideClick = useCallback(() => {
    history.push('/company/area/list/guides');
  }, [history]);
  const handleLogoutClick = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  const myMenus = useMemo(() => {
    if (!jwt) return null;
    return (
      <Menu
        key={'menu_1'}
        mode="inline"
        theme="dark"
        defaultSelectedKeys={['1']}
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item key="1" onClick={handleGuideClick}>
          <span>
            <Translate id="company.sideNav.myGuides" />
          </span>
        </Menu.Item>
        <Menu.Item key="2" onClick={handleLogoutClick}>
          <span>
            <Translate id="company.sideNav.logout" />
          </span>
        </Menu.Item>
      </Menu>
    );
  }, [handleGuideClick, handleLogoutClick, jwt]);
  return (
    <div>
      <div
        style={{
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          margin: '16px',
        }}
      >
        <FallbackImage
          className={classes.logo}
          alt="logo"
          src={LogoUrl}
          fallback={require('../../App/Header/assets/logo_cor.png')}
        />
      </div>
      {myMenus}
    </div>
  );
};
export default CompanySideNav;
