import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { RouteSubjectWithSingleTranslation } from '../../../hooks/subjects/useSubjects';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { Heading, Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { TextArea } from '../Input';
import { SelectableSubjectList } from '../SelectableSubjectList';
import { TabPane, Tabs } from '../Tabs';
import { Tips } from '../Tips';
import { RouteDescription } from './RouteForm';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  bold: {
    fontWeight: '600 !important' as unknown as number,
  },
  marginBetween: {
    margin: '1.5rem 0',
  },
});

interface DescriptionAndSubjectFormProps extends LocalizeContextProps {
  routeDescriptions: RouteDescription[];
  setRouteDescriptions: (v: SetStateAction<RouteDescription[]>) => void;
  selectedSubjects: string[];
  setSelectedSubjects: (v: SetStateAction<string[]>) => void;
  remoteSubjects: RouteSubjectWithSingleTranslation[];
}

function DescriptionAndSubjectForm({
  translate,
  routeDescriptions,
  setRouteDescriptions,
  selectedSubjects,
  setSelectedSubjects,
  remoteSubjects,
}: DescriptionAndSubjectFormProps) {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.mainContainer}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Heading disableAutoscale level={2} className={classes.bold}>
        <Translate id="routes.descriptionTitle" />
      </Heading>
      <Heading level={5}>
        <Translate id="routes.descriptionIntroTitle" />
      </Heading>
      <Tips
        header={translate('routes.descriptionTipTitle').toString()}
        positive={{
          title: translate('routes.descriptionTipPositive').toString(),
          tips: [translate('routes.descriptionTipPositive1').toString()],
        }}
        negative={{
          title: translate('routes.descriptionTipNegative').toString(),
          tips: [translate('routes.descriptionTipNegative1').toString()],
        }}
        examples={{
          title: translate('routes.descriptionExampleTitle').toString(),
          content: [
            {
              text: translate('routes.descriptionExample1').toString(),
            },
          ],
        }}
      />
      <div className={classes.marginBetween}>
        <Text weight="semibold">
          <Translate id="routes.descriptionInputLabel" />
        </Text>
        <Tabs>
          {routeDescriptions.map(d => (
            <TabPane
              tab={<Translate id={`languages.${d.language}`} />}
              key={d.language}
            >
              <TextArea
                rows={6}
                value={d.description}
                onChange={ev => {
                  ev.persist();
                  setRouteDescriptions(prevState =>
                    prevState.map(desc => {
                      if (desc.language === d.language)
                        return {
                          ...desc,
                          description: ev.target.value,
                        };
                      else return desc;
                    })
                  );
                }}
                placeholder={translate('routes.whatToDoIntro').toString()}
              />
            </TabPane>
          ))}
        </Tabs>
        <AnimatedError
          isVisible={routeDescriptions.some(desc =>
            validator.isEmpty(desc.description)
          )}
          reason={<Translate id="routes.mandatoryRouteDescription" />}
        />
      </div>
      <Heading level={4} className={classes.bold}>
        <Translate id="routes.subjectTitle" />
      </Heading>
      <Heading level={5}>
        <Translate id="routes.subjectSubtitle" />
      </Heading>
      <Text variant="faded">
        <Translate id="routes.subjectIntro" />
      </Text>
      <SelectableSubjectList
        subjects={remoteSubjects}
        onChange={setSelectedSubjects}
        value={selectedSubjects}
      />
    </motion.div>
  );
}

export default withLocalize(DescriptionAndSubjectForm);
