import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePosition } from 'use-position';
import { AnimatedAlert } from '../../../components/Administration/AnimatedAlert';
import { AreaCardList } from '../../../components/App/AreaCardList';
import { Button } from '../../../components/App/Button';
import CopyrightFooter from '../../../components/App/CopyrightFooter/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import { LinkSection } from '../../../components/App/LinkSection';
import { Loader } from '../../../components/App/Loader';
import { RoutePreviewList } from '../../../components/App/RoutePreviewList';
import { StepList } from '../../../components/App/StepList';
import { VideoPlayerModal } from '../../../components/App/VideoPlayerModal';
import { Heading, Text } from '../../../components/Typography';
import { useEmail } from '../../../hooks/authentication/confirmEmail';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import {
  getHeaderImageUrl,
  useVisitorLandingTranslations,
  VisitorLandingTranslation,
} from '../../../hooks/landing/useVisitorLandingTranslations';
import { getLocationPhotoUrl } from '../../../hooks/routes/location/useLocationPhoto';
import { useClosestRoutes } from '../../../hooks/routes/useClosestRoutes';
import { PartialRouteInterface } from '../../../hooks/routes/UseSimilarRoutes';
import { useAddressFromCoordinates } from '../../../hooks/useAddressFromCoordinates';
import { useVisitor } from '../../../hooks/visitor/UseVisitor';
import { RootState } from '../../../store';
import { Visitor } from '../../../types/visitor.interface';
import {
  complexTranslate,
  desktopPadding,
  getTheme,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import LargeSearch from './LargeSearch';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  textShadow: {
    filter: 'drop-shadow(2px 2px 8px black)',
  },
  standout: {
    backgroundColor: getTheme().neutral6,
    paddingTop: '2rem',
    paddingBottom: '2rem',
    margin: '1rem 0',
  },
  fitWidth: {
    width: '100%',
  },
  capsButton: {
    alignSelf: 'center',
    fontWeight: 'bold',
    padding: '0 3rem',
  },
  introZone: {
    display: 'flex',
    backgroundImage: `url(${getHeaderImageUrl()})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexDirection: 'column',
    borderRadius: '0 0 2vh 2vh',
    height: '100vh',
    overflow: 'hidden',
  },
  textZone: {
    marginTop: headerHeight,
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  headingMargin: {
    margin: '1rem 0',
  },
  divider: { margin: 0, marginBottom: '0.5rem' },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    textZone: {
      marginTop: '2em',
      maxWidth: '50vw',
      alignSelf: 'center',
      textAlign: 'center',
    },
    introZone: {
      maxHeight: '40vh',
      minHeight: '40vh',
      justifyContent: 'center',
    },
    seeMoreBtn: {
      alignSelf: 'center',
    },
  },
  experiencesDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginTitle: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    margin: '2rem 0 0 0 !important',
  },
  bodyForceNoPaddingBottom: {
    paddingBottom: '0 !important',
  },
  marginTitle2: {
    margin: '2rem 0 1rem 0 !important',
  },
  marginBetween: {
    margin: '1rem 0',
  },
});

interface RouteState {
  alert?: 'resetEmailSent' | 'passwordChanged' | 'confirmEmail';
  resetEmail?: string;
}
const getToken = (location: any): string => {
  if (location.search) {
    const queryString = location.search.split('?')[1];
    const token = queryString.split('=')[1];
    return token;
  } else {
    return '';
  }
};

interface VisitorLandingProps extends LocalizeContextProps {
  location: { state: RouteState; hash: string; search: string };
}

function VisitorLanding({ translate, location }: VisitorLandingProps) {
  const classes = useStyles();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [translations, setTranslations] =
    useState<VisitorLandingTranslation | null>(null);
  const [fetchingTranslations, errorFetching] = useVisitorLandingTranslations(
    activeLanguage,
    setTranslations
  );
  const token = getToken(location);
  const [jwt] = UseJWT();
  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const { lastAppZone } = useSelector((state: RootState) => state.app);
  const [isConfirming, errorConfirming] = useEmail(token, lastAppZone);
  const [isLoading, hasError] = useVisitor(jwt?.visitorId, setVisitor);
  const { latitude, longitude } = usePosition(false);
  const isMobile = useIsMobile();
  if (token !== '') {
    location.state = { alert: 'confirmEmail' };
  }
  const [routes, setRoutes] = useState<PartialRouteInterface[]>([]);
  useClosestRoutes(setRoutes, latitude, longitude);
  let renderAlert = useCallback(
    (errorConfirming: boolean) => {
      switch (location.state?.alert) {
        case 'passwordChanged':
          return (
            <div className={classes.body}>
              <AnimatedAlert
                visible
                message={translate('landing.passwordChanged')
                  .toString()
                  .replace('{FIRST_NAME}', visitor?.firstName || '')
                  .replace('{LAST_NAME}', visitor?.lastName || '')}
                type="success"
                showIcon
              />
            </div>
          );
        case 'resetEmailSent':
          return (
            <div className={classes.body}>
              <AnimatedAlert
                visible
                message={translate('landing.resetPassword').toString()}
                description={translate('landing.resetEmailSent')
                  .toString()
                  .replace('{EMAIL}', location.state.resetEmail || '')}
                type="info"
                showIcon
              />
            </div>
          );
        case 'confirmEmail':
          if (errorConfirming) {
            return (
              <AnimatedAlert
                visible
                message={translate('landing.emailConfirmation').toString()}
                description={translate('landing.emailNotConfirmed').toString()}
                type="warning"
                showIcon
              />
            );
          }
          return (
            <AnimatedAlert
              visible
              message={translate('landing.emailConfirmation').toString()}
              description={translate('landing.emailConfirmed').toString()}
              type="success"
              showIcon
            />
          );
        default:
          return null;
      }
    },
    [location, translate, visitor, classes.body]
  );
  renderAlert = useCallback(() => {
    if (location.search === 'd') {
      return (
        <div className={classes.body}>
          <AnimatedAlert
            visible
            message={translate('landing.passwordChanged')
              .toString()
              .replace('{FIRST_NAME}', visitor?.firstName || '')
              .replace('{LAST_NAME}', visitor?.lastName || '')}
            type="success"
            showIcon
          />
        </div>
      );
    } else {
      return null;
    }
  }, [location, translate, visitor, classes.body]);

  const [data] = useAddressFromCoordinates(latitude, longitude);

  const loadingPage =
    isLoading || isConfirming || fetchingTranslations || !translations;

  const goToHash = useCallback(() => {
    if (loadingPage) {
      return;
    }

    const id = location.hash.split('#').filter(s => !!s)[0];

    if (id) {
      document.getElementById(id)?.scrollIntoView();
    } else
      document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash, loadingPage]);

  useLayoutEffect(goToHash, [goToHash]);

  if (loadingPage || !translations) return <Loader />;

  if (hasError && jwt?.visitorId) return <Error />;
  if (errorFetching) return <Error />;
  return (
    <motion.div
      className={classNames(classes.page)}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <VideoPlayerModal />
      {renderAlert(errorConfirming)}
      {!isMobile && (translations.firstTitle || translations.intro) && (
        <div className={classes.textZone}>
          <Heading level={2}>{translations.firstTitle}</Heading>
          <Text variant="faded">{translations.intro}</Text>
        </div>
      )}
      <div className={classes.introZone}>
        {isMobile && (
          <div className={classNames(classes.textZone, classes.body)}>
            <Heading className={classes.textShadow} color="white" level={2}>
              {translations.firstTitle}
            </Heading>
            <Text className={classes.textShadow} color="white">
              {translations.intro}
            </Text>
          </div>
        )}
        <LargeSearch
          placeholder={translations.searchPlaceholder}
          subtitle={translations.searchSubtitle}
          title={translations.searchTitle}
        />
      </div>
      <div
        className={classNames(classes.body, classes.bodyForceNoPaddingBottom)}
      >
        <Heading level={3} className={classes.marginTitle}>
          {translations.experiencesTitle}
        </Heading>
      </div>
      <div className={classes.experiencesDiv}>
        <RoutePreviewList data={translations.experiences} />
      </div>
      <div
        id="how-it-works"
        className={classNames(classes.body, classes.bodyForceNoPaddingBottom)}
      >
        <Heading level={3} className={classes.marginTitle}>
          {translations.howToStartTitle}
        </Heading>
        {translations.howToStartSubtitle && (
          <Text>{translations.howToStartSubtitle}</Text>
        )}
        <div className={classes.marginBetween}>
          <StepList data={translations.steps} />
        </div>
      </div>
      {routes.length > 0 && (
        <div
          className={classNames(classes.body, classes.bodyForceNoPaddingBottom)}
        >
          <Heading level={3} className={classes.marginTitle}>
            {complexTranslate(translations.featuredRoutesTitle, {
              '{LOCATION}': data?.results[0].address_components[0]
                .long_name || (
                <Translate
                  key="no routes on featured"
                  id="landing.visitor.noLocation"
                />
              ),
            })}
          </Heading>
          <RoutePreviewList
            data={routes.map(r => ({
              id: r.id,
              name: r.titles[0] ? r.titles[0].title : '',
              numberOfRatings: r.numberOfRatings,
              place: r.location,
              price: r.adultPrice,
              rating: r.averageRating,
              image: getLocationPhotoUrl(
                r.locations.map(l => l.photos.map(p => p.id)).flat()[0]
              ),
            }))}
            wrap={isMobile}
          />
          <Link to="/route/search">
            <Button
              type="primary"
              size="large"
              className={classNames(classes.headingMargin, classes.seeMoreBtn)}
            >
              <Translate id="landing.visitor.showMore" />
            </Button>
          </Link>
        </div>
      )}
      {/* ANCHOR INTERESSES ! */}
      <div
        className={classNames(classes.body, classes.bodyForceNoPaddingBottom)}
      >
        <Heading level={3} className={classes.marginTitle2}>
          {translations.interestTitle}
        </Heading>
        <AreaCardList
          hyperLink
          data={translations.interests}
          wrap={!isMobile}
          className={classes.marginBetween}
        />
      </div>

      <div className={classNames(classes.body, classes.standout)}>
        <Heading level={2}>{translations.joinCommunityTitle}</Heading>
        <Text>{translations.joinCommunityText}</Text>
        <div>
          <a href={translations.callToActionUrl}>
            <Button className={classes.capsButton} type="primary" size="large">
              <Translate id="landing.visitor.knowMore" />
            </Button>
          </a>
        </div>
      </div>
      <Divider className={classes.divider} />
      <LinkSection />
      <CopyrightFooter showSocialLinks />
    </motion.div>
  );
}

export default withLocalize(VisitorLanding);
