import { makeStyles } from '@material-ui/styles';
import Collapse from 'antd/es/collapse';
import React from 'react';
import { ReactComponent as ThumbsDown } from '../../../shared_assets/icons/thumbs_down.svg';
import { ReactComponent as ThumbsUp } from '../../../shared_assets/icons/thumbs_up.svg';
import { Text } from '../../Typography';
import ImageContent from './ImageContent';
import TextContent from './TextContent';

const useStyles = makeStyles({
  tipIcon: {
    minWidth: '3rem',
    height: '1.3em',
    flex: 0,
  },
  customCollapse: {
    border: '0px !important',
  },
  row: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  tipDescription: {
    color: 'grey',
  },
  tipContentContainer: {
    display: 'flex',
    overflowY: 'auto',
    justifyContent: 'space-around',
  },
});

interface Tip {
  title: string;
  tips: string[];
}

interface TipsProps {
  header: string;
  positive: Tip;
  negative: Tip;
  examples: {
    title: string;
    content: {
      text: string;
      image?: string;
    }[];
  };
}

function Tips({ header, positive, negative, examples }: TipsProps) {
  const classes = useStyles();
  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      defaultActiveKey={['primeiro']}
      className={classes.customCollapse}
    >
      <Collapse.Panel
        className={classes.customCollapse}
        header={
          <Text weight="bold" noMargin>
            {header}
          </Text>
        }
        key="primeiro"
      >
        {positive && (
          <div className={classes.row}>
            <ThumbsUp className={classes.tipIcon} />
            <div className={classes.column}>
              <Text>{positive.title}</Text>
              {positive.tips.map((t, i) => (
                <Text
                  variant="faded"
                  key={i.toString()}
                  className={classes.tipDescription}
                >
                  {t}
                </Text>
              ))}
            </div>
          </div>
        )}
        {negative && (
          <div className={classes.row}>
            <ThumbsDown className={classes.tipIcon} />
            <div className={classes.column}>
              <Text>{negative.title}</Text>
              {negative.tips.map((t, i) => (
                <Text
                  variant="faded"
                  key={i.toString()}
                  className={classes.tipDescription}
                >
                  {t}
                </Text>
              ))}
            </div>
          </div>
        )}
        <Text weight="bold">{examples.title}</Text>
        <div className={classes.tipContentContainer}>
          {examples.content.map((ex, i) =>
            ex.image ? (
              <ImageContent
                key={i.toString()}
                text={ex.text}
                image={ex.image}
              />
            ) : (
              <TextContent key={i.toString()} text={ex.text} />
            )
          )}
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}

export default Tips;
