import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

// export interface RouteSubjectWithSingleTranslation {
//   id: string;
//   name: string;
// }
export interface SubjectTranslationInterface {
  id: string;
  name: string;
}

export interface RouteSubjectWithSingleTranslation {
  id: string;
  name: string;
  image: string;
}

type Hook = (
  setSubjects: (
    val: SetStateAction<RouteSubjectWithSingleTranslation[]>
  ) => void,
  language: string,
  noFetchImage?: boolean
) => [boolean, boolean];

const requestSubjects = (language?: string) =>
  Axios.get<SubjectTranslationInterface[]>(
    `${API_URL}/subject/translation/${language}`
  );

export const useSubjects: Hook = (setSubjects, language, noFetchImage) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!language) {
        setSubjects([]);
        setIsError(true);
      }
      try {
        const { data: subjects } = await requestSubjects(language);

        setSubjects(
          subjects.map(sub => ({
            id: sub.id,
            name: sub.name,
            image: noFetchImage ? '' : getSubjectImageUrl(sub.id),
          }))
        );
      } catch (err) {
        setSubjects([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setSubjects, noFetchImage, language]);

  return [isLoading, isError];
};

export const getSubjectImageUrl = (id: string) =>
  `${API_URL}/subject/${id}/image?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
