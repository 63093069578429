import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getVisitorImageUrl } from '../../../../hooks/visitor/UseVisitor';
import { ReactComponent as Comments } from '../../../../shared_assets/icons/comments.svg';
import { ReactComponent as Visitante } from '../../../../shared_assets/images/visitante_default.svg';
import { RootState } from '../../../../store';
import { CommentNotification } from '../../../../types/notification.interface';
import { complexTranslate } from '../../../../utils';
import { Heading, Text } from '../../../Typography';
import { FallbackAvatar } from '../../Avatar';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { FallbackImage } from '../../Image';
import { NotificationsProps } from '../Notification';

interface NotificationCommentProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: CommentNotification;
}

const NotificationComment: React.FC<NotificationCommentProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
}) => {
  const {
    seen,
    type,
    routeTitles,
    visitorName,
    commentDate,
    visitorId,
  } = notification;
  const history = useHistory();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const routeTitle = useMemo(() => {
    const translation = routeTitles.find(t => t.language === activeLanguage);
    return translation ? translation.title : routeTitles[0].title;
  }, [routeTitles, activeLanguage]);
  return (
    <>
      {!simpleVersion ? (
        <>
          <div className={classes.rowDirection}>
            <Comments className={classes.icon} />
            <Heading level={5} noMargin>
              {translate(`notificationType.${type}`).toString()}
            </Heading>
            {!seen && (
              <Badge
                className={classNames(classes.marginLeft, classes.uppercase)}
                type="primary"
                small
              >
                {translate('notifications.new').toString()}
              </Badge>
            )}
          </div>

          <div style={{ margin: '0.5rem 0' }}>
            <div style={{ display: 'flex' }}>
              <FallbackAvatar
                src={
                  <FallbackImage
                    src={getVisitorImageUrl(visitorId)}
                    fallbackComponent={<Visitante />}
                  />
                }
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  minWidth: '1.5rem',
                  minHeight: '1.5rem',
                  marginRight: '0.5rem',
                }}
              />
              <div>
                <Text style={{ marginBottom: '0.2rem' }}>
                  {complexTranslate(
                    translate('notifications.commentDate').toString(),
                    {
                      '{person}': (
                        <Text
                          key="person"
                          className={classes.inlineBtn}
                          noMargin
                        >
                          {visitorName}
                        </Text>
                      ),
                      '{date}': (
                        <Text
                          key="date"
                          className={classes.inlineBtn}
                          variant="faded"
                          noMargin
                        >
                          {moment(commentDate).calendar()}
                        </Text>
                      ),
                    }
                  )}
                </Text>
                <Text variant="faded" noMargin>
                  {complexTranslate(
                    translate('notifications.commentRoute').toString(),
                    {
                      '{routeTitle}': (
                        <Text
                          key="title"
                          className={classes.inlineBtn}
                          variant="default"
                          noMargin
                        >
                          {routeTitle}
                        </Text>
                      ),
                    }
                  )}
                </Text>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ margin: '0.5rem 0' }}>
          <Text style={{ marginBottom: '0.2rem' }}>
            {complexTranslate(
              translate('notifications.commentNotification').toString(),
              {
                '{date}': (
                  <Text key="date" className={classes.inlineBtn} noMargin>
                    {moment(commentDate).format('DD MMMM YYYY')}
                  </Text>
                ),
              }
            )}
          </Text>
        </div>
      )}
      <div className={classes.buttonContainer}>
        <Button
          type="link"
          onlyText
          onClick={() => {
            history.push(`/guide/my-comments`);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.seeComment').toString()}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default withLocalize(NotificationComment);
