import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { getTheme } from '../../../utils';
import { Button } from '../Button';

const useStyles = makeStyles({
  container: { display: 'flex' },
});

interface DesktopAuthentication {
  openSignUp: () => void;
  openLogin: () => void;
}

const DesktopAuthentication = ({
  openLogin,
  openSignUp,
}: DesktopAuthentication) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button type="link" color={getTheme().neutral1} onClick={openSignUp}>
        <Translate id="landing.drawer.signUp" />
      </Button>
      <Button type="link" color={getTheme().neutral1} onClick={openLogin}>
        <Translate id="landing.drawer.signIn" />
      </Button>
    </div>
  );
};

export default DesktopAuthentication;
