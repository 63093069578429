import Axios from 'axios';
import { API_URL } from '../../consts';
import { ScheduleType } from '../../types/schedule-type.enum';

interface CreateRouteScheduleDto {
  guideId: string;
  routeId: string;
  start: string;
  repetitionType: ScheduleType;
  reserveLimit: number;
}

export const createRouteSchedule = (dto: CreateRouteScheduleDto) =>
  Axios.post(`${API_URL}/schedule`, dto);
