import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

type Hook = (
  setRoutes: (val: SetStateAction<string[]>) => void
) => [boolean, boolean];

export const requestRoutes = () =>
  Axios.get(`${API_URL}/content-management/guide-landing-page/selected-routes`);

export const useSelectedRoutes: Hook = selectedRoutes => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: route } = await requestRoutes();
        selectedRoutes(route as string[]);
      } catch (err) {
        selectedRoutes([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [selectedRoutes]);

  return [isLoading, isError];
};
