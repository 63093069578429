import Axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../consts';
import { store } from '../store';

interface GmapsReturn {
  status: string;
  results: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
    formatted_address: string;
    place_id: string;
    types: string[];
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
  }[];
}

export const fetchAddress = (lat: number, lng: number, type?: string) => {
  const instance = Axios.create();

  delete instance.defaults.headers;

  return instance.get<GmapsReturn>(`${API_URL}/location/address`, {
    params: {
      language: store.getState().userConfiguration.activeLanguage,
      lat: lat,
      lng: lng,
      result_type: type,
    },
  });
};

type Hook = (
  lat?: number,
  lng?: number,
  type?: string
) => [GmapsReturn | undefined, boolean, boolean];

export const useAddressFromCoordinates: Hook = (lat, lng, type) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [data, setData] = useState<GmapsReturn | undefined>();

  useEffect(() => {
    if (!lat || !lng) return;
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchAddress(lat, lng, type || 'political');

        setHasError(false);
        setData(data);
      } catch (err) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [lat, lng, type]);

  return [data, isLoading, hasError];
};
