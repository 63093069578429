import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { MemberInterface } from '../administration/useListAdminMembers';

type Hook = (
  setListMembers: (val: SetStateAction<MemberInterface[]>) => void
) => [boolean, boolean];

const requestMembers = (cancel: CancelToken | undefined) =>
  Axios.get<MemberInterface[]>(`${API_URL}/member/list-members`, {
    cancelToken: cancel,
  });

export const useMembers: Hook = setListMembers => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestMembers(cancel.current?.token);

        setListMembers(list);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setListMembers([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [dispatch, setListMembers]);

  return [isLoading, isError];
};
// export const getMemberImage = (id: string) =>
//   `${API_URL}/member/${id}/image?_=${moment().unix()}`;
