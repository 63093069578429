import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { getTheme, mobileThreshhold } from '../../../utils';
import { Text } from '../../Typography';

interface StyleProps {
  titleCentered?: boolean;
  image: string;
  hyperLink?: boolean;
}

const useStyles = makeStyles({
  areaCard: ({ image, hyperLink }: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    minWidth: 200,
    height: 130,
    cursor: hyperLink ? 'pointer' : undefined,
    borderRadius: 10,
    overflow: 'hidden',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  image: {
    minWidth: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },
  textZone: {
    position: 'absolute',
  },
  title: ({ titleCentered }: StyleProps) => ({
    textAlign: titleCentered ? 'center' : undefined,
    backgroundColor: getTheme().shadowBackground,
    width: '100%',
  }),
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    areaCard: () => ({
      width: '19vw',
      height: '12vw',
    }),
  },
});

export interface AreaCardProps {
  image: string;
  title: string;
  description?: string;
  id: string;
  hyperLink?: boolean;
}

export default function AreaCard({
  image,
  title,
  description,
  id,
  hyperLink,
}: AreaCardProps) {
  const classes = useStyles({
    titleCentered: description === undefined,
    image,
    hyperLink,
  });

  const history = useHistory();

  const onClick = useCallback(() => {
    if (!hyperLink) return;
    history.push({
      pathname: '/route/search',
      state: {
        filter: {
          subjectsIds: [id],
        },
      },
    });
  }, [history, id, hyperLink]);

  return (
    <div className={classes.areaCard} onClick={onClick}>
      <Text color="white" weight="bold" noMargin className={classes.title}>
        {title}
      </Text>
      {description && <Text>{description}</Text>}
    </div>
  );
}
