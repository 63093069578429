import Axios from 'axios';
import { API_URL } from '../../consts';

export interface SelectedRoutesDto {
  ids: string[];
}

export const postSelectedRoutes = (dto: SelectedRoutesDto) => {
  Axios.post(
    `${API_URL}/content-management/guide-landing-page/selected-routes`,
    dto
  ).catch(err => {
    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
