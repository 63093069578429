import Axios from 'axios';
import { API_URL } from '../../consts';
import message from 'antd/es/message';
import { GuideFormProperty } from '../../types/guide.interface';
import { getGuideErrorMessagesFromField } from './GuideUtils';

interface PeoplePresentDto {
  bookingId: string;
  peoplePresent: number;
}

interface StartRepetitionTourDto {
  bookingsPeoplePresent: PeoplePresentDto[];
  commentary: string;
}

export const attemptStartTour = (
  repetitionId: string,
  dto: StartRepetitionTourDto
) => {
  return Axios.patch(`${API_URL}/schedule/${repetitionId}/start-tour`, dto)
    .then(() => {
      return Promise.resolve();
    })
    .catch(err => {
      if (
        err &&
        err.response &&
        err.response.data &&
        Array.isArray(err.response.data.message)
      ) {
        err.response.data.message.forEach(
          (t: { property: GuideFormProperty }) => {
            message.error(getGuideErrorMessagesFromField(t.property));
          }
        );
      }

      return Promise.reject(err);
    });
};
