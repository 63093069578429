import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { RouteSubject } from '../../types/route-subject.interface';

type Hook = (
  setSubjects: (val: SetStateAction<RouteSubject[] | []>) => void
) => [boolean, boolean];

const requestSubjects = () => Axios.get<RouteSubject[]>(`${API_URL}/subject`);

export const useSubjectsAdmin: Hook = setSubjects => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: subjects } = await requestSubjects();

        setSubjects(subjects);
        setIsError(false);
      } catch (err) {
        setSubjects([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setSubjects]);

  return [isLoading, isError];
};
