import { Layout } from 'antd';
import Axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import SideNav from '../../../components/Administration/SideNav/sideNav';
import { API_URL } from '../../../consts';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { setMarkerCount } from '../../../store/App/actions';
import { AdminStats } from '../../../store/App/types';
import { RoleType } from '../../../types/role-type.enum';
import { CreateAccessibility } from './Accessibility/Create';
import { AccessibilityInformation } from './Accessibility/Information';
import { ListAccessibilities } from './Accessibility/List';
import { ListAdmins } from './Admin/List';
import { ListAdEvents } from './advertiser/AdEvent';
import { ListAdvertisers } from './advertiser/List';
import { CompanyInformation } from './Company/Information';
import { ListCompanies } from './Company/List';
import { CreateGuide } from './Guide/Create';
import { GuideInformation } from './Guide/Information';
import AdminGuide from './Guide/List/list';
import { PaymentList } from './Guide/Payment';
import Help from './Help/Help';
import { ChangePassword } from './intermediate/ChangePassword';
import { CreateIntermediate } from './intermediate/Create';
import { ListIntermediateGuides } from './intermediate/Guides';
import { IntermediateInformation } from './intermediate/Information';
import { ListIntermediates } from './intermediate/List';
import { AboutLandingPage } from './LandingPage/About/information';
import selectTestimony from './LandingPage/About/SelectTestimony/selectTestimony';
import { FaqAdmin } from './LandingPage/Guide/FAQ';
import { GuideSelection } from './LandingPage/Guide/GuideSelection';
import { GuideLandingPage } from './LandingPage/Guide/Information';
import lookingFor from './LandingPage/Guide/LookingFor/lookingFor';
import { RouteSelectionGuideLandingPageList } from './LandingPage/Guide/RouteSelection';
import { WhatWeGive } from './LandingPage/Guide/WhatWeGive';
import { VisitorLandingPage } from './LandingPage/Visitor';
import StepList from './LandingPage/Visitor/Steps/StepList';
import { ListMember } from './Member/List';
import { CreateNewRouteAdmin } from './Route/Create';
import { EditRouteInformation } from './Route/Edit';
import { RouteDetailsAdmin } from './Route/Information';
import { GuideRouteList, ListAllGuideRoute } from './Route/List';
import { RouteScheduleList } from './Schedule/List';
import { CreateSubject } from './Subjects/Create';
import { SubjectInformation } from './Subjects/Information';
import { ListSubjects } from './Subjects/List';
import { SubjectHighlights } from './Subjects/SubjectHighlights';
import { SystemConfigurationProps } from './SystemConfiguration';
import ThemeRouter from './Theme/ThemeRouter';
import { ListVisitorBookings } from './Visitor/Bookings/List';
import { CreateVisitor } from './Visitor/Create';
import { VisitorInformation } from './Visitor/Information';
import { Invoices } from './Visitor/Invoices';
import { ListVisitors } from './Visitor/List';

const { Header, Sider, Content } = Layout;
const ApplicationRoutes = () => {
  const [collapse, setCollapse] = useState(false);
  useEffect(() => {
    window.innerWidth <= 760 ? setCollapse(true) : setCollapse(false);
  }, []);

  const [jwt] = UseJWT();

  const dispatch = useDispatch();

  useEffect(() => {
    const updateCounter = async () => {
      const { data } = await Axios.get<AdminStats>(
        `${API_URL}/administrator/stats`
      );
      dispatch(setMarkerCount(data));
    };
    updateCounter();
  });
  const redirect = useMemo(() => {
    if (!jwt) return;
    switch (true) {
      case jwt.loggedType === RoleType.INTERMEDIATE:
        return `/admin/area/intermediate/${jwt.intermediateId}/list/guides`;
      case jwt.loggedType === RoleType.AD_OWNER:
        return `/admin/area/advertiser/list-ads/${jwt.adOwnerId}`;
      default:
        return '/admin/area/list/guides';
    }
  }, [jwt]);

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapse}>
        <SideNav />
      </Sider>
      <Layout>
        <Header
          className="siteLayoutBackground"
          style={{ padding: 0, background: '#001529' }}
        />
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 'calc(100vh - 114px)',
            background: '#fff',
          }}
        >
          <Switch>
            {jwt &&
              jwt?.loggedType === RoleType.ADMINISTRATOR && [
                <Route
                  path="/admin/area/list/members"
                  exact={true}
                  component={ListMember}
                />,
                <Route
                  path="/admin/area/list/guides"
                  exact={true}
                  component={AdminGuide}
                />,
                <Route
                  exact={true}
                  path="/admin/area/list/visitors"
                  component={ListVisitors}
                />,
                <Route
                  exact={true}
                  path="/admin/area/list/subjects"
                  component={ListSubjects}
                />,
                <Route
                  exact={true}
                  path="/admin/area/list/accessibilities"
                  component={ListAccessibilities}
                />,
                <Route
                  path="/admin/area/landing-page/about/information"
                  exact={true}
                  component={AboutLandingPage}
                />,
                <Route
                  path="/admin/area/landing-page/about/select-testimony"
                  exact={true}
                  component={selectTestimony}
                />,

                <Route
                  path="/admin/area/landing-page/guide/information"
                  exact={true}
                  component={GuideLandingPage}
                />,
                <Route
                  path="/admin/area/landing-page/guide/route-selection"
                  exact={true}
                  component={RouteSelectionGuideLandingPageList}
                />,
                <Route
                  path="/admin/area/landing-page/guide/looking-for"
                  exact={true}
                  component={lookingFor}
                />,
                <Route
                  path="/admin/area/landing-page/guide/guide-select"
                  exact={true}
                  component={GuideSelection}
                />,

                <Route
                  path="/admin/area/landing-page/guide/what-we-give"
                  exact={true}
                  component={WhatWeGive}
                />,
                <Route
                  path="/admin/area/landing-page/guide/faq"
                  exact={true}
                  component={FaqAdmin}
                />,

                <Route
                  path="/admin/area/landing-page/visitor"
                  exact={true}
                  component={VisitorLandingPage}
                />,
                <Route
                  path="/admin/area/landing-page/visitor/steps"
                  exact={true}
                  component={StepList}
                />,
                <Route
                  exact={true}
                  path="/admin/area/accessibility/create"
                  key="admin-create-accessibility"
                  component={CreateAccessibility}
                />,
                <Route
                  exact={true}
                  path="/admin/area/accessibility/:accessibilityId"
                  key="admin-accessibility"
                  component={AccessibilityInformation}
                />,

                <Route
                  exact={true}
                  path="/admin/area/subject/create"
                  key="admin-create-subject"
                  component={CreateSubject}
                />,
                <Route
                  path="/admin/area/subject/highlight"
                  exact={true}
                  component={SubjectHighlights}
                />,
                <Route
                  exact={true}
                  path="/admin/area/subject/:subjectId"
                  key="admin-subject"
                  component={SubjectInformation}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:guideId/route/:routeId/list/schedules"
                  key="admin-guide-list-schedules"
                  component={RouteScheduleList}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:guideId/edit/route/:routeId"
                  key="admin-guide-edit-route"
                  component={EditRouteInformation}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:guideId/route/:routeId"
                  key="admin-guide-show-route"
                  component={RouteDetailsAdmin}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:id/create-route"
                  key="admin-create-route"
                  component={CreateNewRouteAdmin}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:id/list/routes"
                  key="admin-guide-routes"
                  component={GuideRouteList}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:id"
                  key="admin-guide"
                  component={GuideInformation}
                />,
                <Route
                  exact={true}
                  path="/admin/area/create-guide/:id?"
                  key="admin-create-guide"
                  component={CreateGuide}
                />,

                <Route
                  exact={true}
                  path="/admin/area/visitor/create"
                  key="admin-visitor-create"
                  component={CreateVisitor}
                />,
                <Route
                  exact={true}
                  path="/admin/area/visitor/:id"
                  key="admin-visitor"
                  component={VisitorInformation}
                />,
                <Route
                  exact={true}
                  path="/admin/area/visitor/:id/bookings"
                  key="admin-visitor-bookings"
                  component={ListVisitorBookings}
                />,
                <Route
                  exact={true}
                  path="/admin/area/visitor/:id/invoices"
                  key="admin-visitor-invoices"
                  component={Invoices}
                />,
                <Route
                  exact={true}
                  path="/admin/area/system-configuration"
                  key="admin-system-configuration"
                  component={SystemConfigurationProps}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide-payment-list"
                  key="admin-guide-payment-list"
                  component={PaymentList}
                />,
                <Route
                  exact={true}
                  path="/admin/area/list-all-routes"
                  key="admin-guide-lis-all-routes"
                  component={ListAllGuideRoute}
                />,
                <Route
                  exact={true}
                  path="/admin/area/list-admins"
                  key="admin-list-admins"
                  component={ListAdmins}
                />,
                <Route
                  exact={true}
                  path="/admin/area/create-intermediate"
                  key="admin-create-intermediate"
                  component={CreateIntermediate}
                />,
                <Route
                  exact={true}
                  path="/admin/area/list-intermediates"
                  key="admin-list-intermediates"
                  component={ListIntermediates}
                />,
                <Route
                  exact={true}
                  path="/admin/area/intermediate/:id/list/guides"
                  key="admin-intermediate-guide-list"
                  component={ListIntermediateGuides}
                />,
                <Route
                  exact={true}
                  path="/admin/area/intermediate/:id"
                  key="admin-intermediate"
                  component={IntermediateInformation}
                />,
                <Route
                  path="/admin/area/theme"
                  key="admin-themes"
                  component={ThemeRouter}
                />,

                <Route
                  path="/admin/area/help"
                  key="admin-help"
                  component={Help}
                />,

                <Route
                  path="/admin/area/publicity/list-advertisers"
                  key="admin-publicity-list-advertisers"
                  component={ListAdvertisers}
                />,
                <Route
                  path="/admin/area/advertiser/list-ads/:id"
                  key="admin-publicity-list-ads"
                  component={ListAdEvents}
                />,
                <Route
                  path="/admin/area/companies/list"
                  key="admin-companies-list"
                  component={ListCompanies}
                />,
                <Route
                  exact={true}
                  path="/admin/area/company/:id"
                  key="admin-company"
                  component={CompanyInformation}
                />,
              ]}
            {jwt &&
              jwt?.loggedType === RoleType.INTERMEDIATE && [
                <Route
                  exact={true}
                  path="/admin/area/guide-payment-list/:id"
                  key="admin-guide-payment-list"
                  component={PaymentList}
                />,
                <Route
                  exact={true}
                  path="/admin/area/intermediate/:id/list/guides"
                  key="admin-intermediate-guide-list"
                  component={ListIntermediateGuides}
                />,
                <Route
                  exact={true}
                  path="/admin/area/create-guide/:id?"
                  key="admin-create-guide"
                  component={CreateGuide}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:id/list/routes"
                  key="admin-guide-routes"
                  component={GuideRouteList}
                />,
                <Route
                  exact={true}
                  path="/admin/area/guide/:id"
                  key="admin-guide"
                  component={GuideInformation}
                />,
                <Route
                  exact={true}
                  path="/admin/area/change-password/:id"
                  key="intermediate-change-password"
                  component={ChangePassword}
                />,
              ]}
            {jwt &&
              jwt?.loggedType === RoleType.AD_OWNER && [
                <Route
                  path="/admin/area/advertiser/list-ads/:id"
                  key="admin-publicity-list-ads"
                  component={ListAdEvents}
                />,
              ]}
            <Redirect to={`${redirect}`} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
export default ApplicationRoutes;
