import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { useDispatch } from 'react-redux';

import { ListGuidesAdminInterface } from '../../types/list-guide.interface';

type Hook = (
  setListGuides: (val: SetStateAction<ListGuidesAdminInterface[]>) => void
) => [boolean, boolean];

const requestAdmin = () =>
  Axios.get<ListGuidesAdminInterface[]>(`${API_URL}/guide/approved`);

export const useGuideSelection: Hook = setListGuides => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestAdmin();

        setListGuides(list);
        setIsError(false);
      } catch (err) {
        setListGuides([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setListGuides]);

  return [isLoading, isError];
};
