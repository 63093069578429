import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Empty from 'antd/es/empty';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { getGuideImageUrl } from '../../../hooks/guide/UseGuide';
import { getLocationPhotoUrl } from '../../../hooks/routes/location/useLocationPhoto';
import { PartialRouteInterface } from '../../../hooks/routes/UseSimilarRoutes';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { RootState } from '../../../store';
import { Description } from '../../../types/description.interface';
import { GroupAgeType } from '../../../types/group-age-type.enum';
import { GuidePublicProfile } from '../../../types/guide-public-profile.interface';
import { Review } from '../../../types/review.interface';
import { RouteActivity } from '../../../types/route-activity.interface';
import { DifficultyType } from '../../../types/route-difficulty.enum';
import { RouteExperienceType } from '../../../types/route-experience.enum';
import { RouteExtraItem } from '../../../types/route-extra-item.interface';
import { RouteLocation } from '../../../types/route-location.interface';
import { RouteSubject } from '../../../types/route-subject.interface';
import { AccessibilityInterface } from '../../../types/route.interface';
import {
  complexTranslate,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { Heading, Text } from '../../Typography';
import { GuideStats } from '../GuideStats';
import { ProfileStrip } from '../ProfileStrip';
import { RoutePreviewList } from '../RoutePreviewList';
import { VisitorComment } from '../VisitorComment';

interface InternalRouteInterface {
  id: string;
  guideId: string;
  title: string;
  languages: string[];
  difficulty: DifficultyType;
  accessibilities: AccessibilityInterface[];
  groupAge: GroupAgeType;
  routeExperience: RouteExperienceType;
  price: number;
  routeLength: number;
  groupSize: number;
  duration: number;
  location: string;
  averageRating: number;
  numberOfRatings: number;
  activities: RouteActivity[];
  extraItems: RouteExtraItem[];
  minPrice: number;
  subjects: RouteSubject[];
  locations: RouteLocation[];
  descriptions: Description[];
}

interface PublicProfileProps extends LocalizeContextProps {
  guide: GuidePublicProfile;
  reviews: Review[];
  guideRoutes: PartialRouteInterface[];
}

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  guideSectionWrapper: {
    flex: 1,
  },
  infoSection: {
    flex: 1,
  },
  seeMoreButton: {
    alignSelf: 'flex-start',
    marginBottom: '1rem',
  },
  reviewContainer: {
    padding: '2rem 0',
  },

  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      flexDirection: 'row',
    },
    guideSection: {
      boxShadow: '0px 0px 9px 4px rgba(0,0,0,0.1)',
      padding: '1rem',
      marginRight: '4rem',
      borderRadius: 10,
      maxWidth: 360,
    },
    guideSectionWrapper: {
      flex: 0.4,
    },
    infoSection: {
      flex: 0.8,
    },
  },
});

const PublicProfile = ({
  guide,
  guideRoutes,
  reviews,
  translate,
}: PublicProfileProps) => {
  const guideName = guide.firstName + ' ' + guide.lastName;
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const showDescription =
    guide.descriptions?.find(desc => desc.language === activeLanguage)
      ?.description || guide.descriptions[0].description;

  const guideLanguages =
    (guide.languages && guide.languages.map(l => l.language)) || [];

  const guideSkills =
    (guide.skills && guide.skills.map(sk => sk.skillType)) || [];
  const fixedRoute: InternalRouteInterface[] = useMemo(() => {
    return guideRoutes.map(r => {
      const translation = r.titles.find(t => t.language === activeLanguage);
      const title = translation
        ? translation.title
        : r.titles[0].title
        ? r.titles[0].title
        : '';
      return {
        id: r.id,
        guideId: r.guideId,
        title: title,
        languages: r.languages,
        difficulty: r.difficulty,
        accessibilities: r.accessibilities,
        groupAge: r.groupAge,
        routeExperience: r.routeExperience,
        price: r.adultPrice,
        routeLength: r.routeLength,
        groupSize: r.groupSize,
        duration: r.duration,
        location: r.location,
        averageRating: r.averageRating,
        numberOfRatings: r.numberOfRatings,
        activities: r.activities,
        extraItems: r.extraItems,
        minPrice: r.minPrice,
        subjects: r.subjects,
        locations: r.locations,
        descriptions: r.descriptions,
      };
    });
  }, [activeLanguage, guideRoutes]);
  const routePreviewData = fixedRoute.map(r => ({
    id: r.id,
    name: r.title,
    numberOfRatings: r.numberOfRatings,
    place: r.location,
    price: r.price,
    rating: r.averageRating,
    image: getLocationPhotoUrl(
      r.locations.map(l => l.photos.map(p => p.id)).flat()[0]
    ),
  }));

  const descriptionZone = useMemo(
    () => (
      <>
        <Heading level={4}>
          <Translate id="profile.about" />
        </Heading>
        <Text variant="faded">{showDescription}</Text>
      </>
    ),
    [showDescription]
  );

  const classes = useStyles();

  const isMobile = useIsMobile();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.guideSectionWrapper}>
        <div className={classes.guideSection}>
          <ProfileStrip
            imageSource={getGuideImageUrl(guide.id)}
            name={guideName}
            type="guide"
            memberSince={new Date(guide.dateOfCreation)}
          />
          <Divider />
          {isMobile && descriptionZone}
          <GuideStats
            guideId={guide.id}
            numberOfCompletedRoutes={guide.numberOfCompletedRoutes}
            numberOfReviews={guide.numberOfReviews}
            guideLanguages={guideLanguages}
            guideSkills={guideSkills}
          />
        </div>
      </div>
      <div className={classes.infoSection}>
        {!isMobile && descriptionZone}
        <Heading level={4} noMargin>
          <Translate id="profile.comments" />
        </Heading>
        <div className={classes.reviewContainer}>
          {reviews.length > 0 ? (
            reviews.map((review, i) => (
              <React.Fragment key={review.id}>
                <VisitorComment
                  visitorId={review.visitorId}
                  commentText={review.description}
                  visitorName={review.visitorName}
                  routeName={review.routeTitle}
                  rating={review.rating}
                  date={review.dateOfCreation}
                />
                {
                  //Last comment has no divider
                  i !== reviews.length - 1 && <Divider />
                }
              </React.Fragment>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<Translate id="routeDetails.dontExistComments" />}
            />
          )}
          {/* 
           <Button
            size="large"
            className={classes.seeMoreButton}
            type="link"
            onlyText
          >
            <Translate id="profile.seeMore" />
          </Button> */}
        </div>
        <Heading level={4}>
          {complexTranslate(translate('profile.guideRoutes').toString(), {
            '{NAME}': guideName,
          })}
        </Heading>
        <RoutePreviewList data={routePreviewData} />
      </div>
    </motion.div>
  );
};

export default withLocalize(PublicProfile);
