import { makeStyles } from '@material-ui/styles';
import { Menu } from 'antd';
import Badge from 'antd/es/badge';
import React, { useCallback, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { LogoUrl } from '../../../hooks/customization/getLogo';
import { RootState } from '../../../store';
import { logout } from '../../../store/UserConfiguration/actions';
import { RoleType } from '../../../types/role-type.enum';
import FallbackImage from '../../App/Image/FallbackImage';

const useStyles = makeStyles({
  logo: {
    flex: 0,
    maxHeight: '100%',
  },
});

const { SubMenu } = Menu;
const SideNav = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { count } = useSelector((state: RootState) => state.app);
  const [jwt] = UseJWT();

  const handleGuideClick = useCallback(() => {
    history.push('/admin/area/list/guides');
  }, [history]);
  const handleAllRoutesClick = useCallback(() => {
    history.push('/admin/area/list-all-routes');
  }, [history]);
  const handlePaymentClick = useCallback(() => {
    history.push('/admin/area/guide-payment-list');
  }, [history]);
  const handlePaymentIntermediateClick = useCallback(() => {
    history.push(`/admin/area/guide-payment-list/${jwt?.intermediateId}`);
  }, [history, jwt]);
  const handleVisitorsClick = useCallback(() => {
    history.push('/admin/area/list/visitors');
  }, [history]);
  const handleSubjectsClick = useCallback(() => {
    history.push('/admin/area/list/subjects');
  }, [history]);
  const handleAccessibilityClick = useCallback(() => {
    history.push('/admin/area/list/accessibilities');
  }, [history]);
  const handleLandingPageGuideInformationClick = useCallback(() => {
    history.push('/admin/area/landing-page/guide/information');
  }, [history]);
  const handleLandingPageGuideRouteSelectionClick = useCallback(() => {
    history.push('/admin/area/landing-page/guide/route-selection');
  }, [history]);
  const handleLandingPageGuideWhatWeLookingForClick = useCallback(() => {
    history.push('/admin/area/landing-page/guide/looking-for');
  }, [history]);
  const handleLandingPageGuideGuideSelectClick = useCallback(() => {
    history.push('/admin/area/landing-page/guide/guide-select');
  }, [history]);
  const handleLandingPageGuideWhatWeGiveClick = useCallback(() => {
    history.push('/admin/area/landing-page/guide/what-we-give');
  }, [history]);
  const handleLandingPageGuideFAQClick = useCallback(() => {
    history.push('/admin/area/landing-page/guide/faq');
  }, [history]);

  const handleLandingPageVisitorClick = useCallback(() => {
    history.push('/admin/area/landing-page/visitor');
  }, [history]);
  const handleVisitorLandingPageStepsClick = useCallback(() => {
    history.push('/admin/area/landing-page/visitor/steps');
  }, [history]);

  const handleLandingPageAboutClick = useCallback(() => {
    history.push('/admin/area/landing-page/about/information');
  }, [history]);
  const handleLandingPageSelectTestimonyClick = useCallback(() => {
    history.push('/admin/area/landing-page/about/select-testimony');
  }, [history]);
  const handleSystemConfigurationClick = useCallback(() => {
    history.push('/admin/area/system-configuration');
  }, [history]);
  const handleListAdvertisersClick = useCallback(() => {
    history.push('/admin/area/publicity/list-advertisers');
  }, [history]);
  const handleListAdminsClick = useCallback(() => {
    history.push('/admin/area/list-admins');
  }, [history]);
  const handleThemesClick = useCallback(() => {
    history.push('/admin/area/theme');
  }, [history]);
  const handleHelpClick = useCallback(() => {
    history.push('/admin/area/help');
  }, [history]);
  const handleIntermediate = useCallback(() => {
    history.push('/admin/area/list-intermediates');
  }, [history]);
  const handleMyGuides = useCallback(() => {
    history.push(`/admin/area/intermediate/${jwt?.intermediateId}/list/guides`);
  }, [history, jwt]);
  const handleChangePassword = useCallback(() => {
    history.push(`/admin/area/change-password/${jwt?.intermediateId}`);
  }, [history, jwt]);
  const handleListMembers = useCallback(() => {
    history.push(`/admin/area/list/members`);
  }, [history]);
  const handleMyAds = useCallback(() => {
    history.push(`/admin/area/advertiser/list-ads/${jwt?.adOwnerId}`);
  }, [history, jwt]);

  const handleCompanyList = useCallback(() => {
    history.push(`/admin/area/companies/list`);
  }, [history]);

  const handleLogoutClick = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const myMenus = useMemo(() => {
    if (!jwt) return null;
    switch (true) {
      case jwt.loggedType === RoleType.AD_OWNER:
        return (
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={['20']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item key="20" onClick={handleMyAds}>
              <span>
                <Translate id="adminstration.sideNav.myAds" />
              </span>
            </Menu.Item>
            <Menu.Item key="21" onClick={handleLogoutClick}>
              <span>
                <Translate id="adminstration.sideNav.logout" />
              </span>
            </Menu.Item>
          </Menu>
        );
      case jwt.loggedType === RoleType.INTERMEDIATE:
        return (
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={['14']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item key="14" onClick={handleMyGuides}>
              <span>
                <Translate id="adminstration.sideNav.myGuides" />
              </span>
            </Menu.Item>
            <Menu.Item key="16" onClick={handleChangePassword}>
              <span>
                <Translate id="adminstration.sideNav.changeMyPass" />
              </span>
            </Menu.Item>
            <Menu.Item key="17" onClick={handlePaymentIntermediateClick}>
              <span>
                <Translate id="adminstration.sideNav.payments" />
              </span>
            </Menu.Item>
            <Menu.Item key="15" onClick={handleLogoutClick}>
              <span>
                <Translate id="adminstration.sideNav.logout" />
              </span>
            </Menu.Item>
          </Menu>
        );

      default:
        return (
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['1.1']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <SubMenu
              key="1"
              title={
                <>
                  <Translate id="adminstration.sideNav.guides" />
                  {count &&
                    count.numberOfGuidesToApprove +
                      count.numberOfRoutesToApprove >
                      0 && (
                      <Badge
                        style={{ marginLeft: '1rem' }}
                        count={
                          count.numberOfGuidesToApprove +
                          count.numberOfRoutesToApprove
                        }
                      />
                    )}
                </>
              }
            >
              <Menu.Item key="1.1" onClick={handleGuideClick}>
                <span>
                  <Translate id="adminstration.sideNav.guide" />
                  {count && count.numberOfGuidesToApprove > 0 && (
                    <Badge
                      style={{ marginLeft: '1rem' }}
                      count={count.numberOfGuidesToApprove}
                    />
                  )}
                </span>
              </Menu.Item>
              <Menu.Item key="1.2" onClick={handleAllRoutesClick}>
                <span>
                  <Translate id="adminstration.sideNav.seeAllPediRoutes" />
                  {count && count.numberOfRoutesToApprove > 0 && (
                    <Badge
                      style={{ marginLeft: '1rem' }}
                      count={count.numberOfRoutesToApprove}
                    />
                  )}
                </span>
              </Menu.Item>

              <Menu.Item key="1.3" onClick={handlePaymentClick}>
                <span>
                  <Translate id="adminstration.sideNav.payments" />
                </span>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="2" onClick={handleVisitorsClick}>
              <span>
                <Translate id="adminstration.sideNav.visitor" />
              </span>
            </Menu.Item>
            <Menu.Item key="13" onClick={handleIntermediate}>
              <span>
                <Translate id="adminstration.sideNav.intermediates" />
              </span>
            </Menu.Item>
            <Menu.Item key="3" onClick={handleSubjectsClick}>
              <span>
                <Translate id="adminstration.sideNav.subjects" />
              </span>
            </Menu.Item>
            <Menu.Item key="4" onClick={handleAccessibilityClick}>
              <span>
                <Translate id="adminstration.sideNav.accessibilities" />
              </span>
            </Menu.Item>
            <SubMenu
              key="5"
              //icon={<NotificationOutlined />}
              title={<Translate id="adminstration.sideNav.guideLandingPage" />}
            >
              <Menu.Item
                key="5.1"
                onClick={handleLandingPageGuideInformationClick}
              >
                <Translate id="adminstration.sideNav.mainTexts" />
              </Menu.Item>
              <Menu.Item
                key="5.2"
                onClick={handleLandingPageGuideRouteSelectionClick}
              >
                <Translate id="adminstration.sideNav.selectPediRoutes" />
              </Menu.Item>
              <Menu.Item
                key="5.3"
                onClick={handleLandingPageGuideWhatWeLookingForClick}
              >
                <Translate id="adminstration.sideNav.whatWeLookFor" />
              </Menu.Item>
              <Menu.Item
                key="5.4"
                onClick={handleLandingPageGuideGuideSelectClick}
              >
                <Translate id="adminstration.sideNav.selectGuides" />
              </Menu.Item>
              <Menu.Item
                key="5.5"
                onClick={handleLandingPageGuideWhatWeGiveClick}
              >
                <Translate id="adminstration.sideNav.whatWeGive" />
              </Menu.Item>
              <Menu.Item key="5.6" onClick={handleLandingPageGuideFAQClick}>
                <Translate id="adminstration.sideNav.faq" />
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="6"
              //icon={<NotificationOutlined />}
              title={
                <Translate id="adminstration.sideNav.visitorLandingPage" />
              }
            >
              <Menu.Item key="6.1" onClick={handleLandingPageVisitorClick}>
                <Translate id="adminstration.sideNav.mainTexts" />
              </Menu.Item>
              <Menu.Item key="6.2" onClick={handleVisitorLandingPageStepsClick}>
                <Translate id="adminstration.sideNav.steps" />
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="7"
              // icon={}
              title={<Translate id="adminstration.sideNav.aboutLandingPage" />}
            >
              <Menu.Item key="7.1" onClick={handleLandingPageAboutClick}>
                <Translate id="adminstration.sideNav.texts" />
              </Menu.Item>
              <Menu.Item
                key="7.2"
                onClick={handleLandingPageSelectTestimonyClick}
              >
                <Translate id="adminstration.sideNav.testimonies" />
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="8" onClick={handleSystemConfigurationClick}>
              <span>
                <Translate id="adminstration.sideNav.systemConfiguration" />
              </span>
            </Menu.Item>
            <Menu.Item key="9" onClick={handleListAdminsClick}>
              <span>
                <Translate id="adminstration.sideNav.listAdmins" />
              </span>
            </Menu.Item>
            <Menu.Item key="10" onClick={handleThemesClick}>
              <span>
                <Translate id="adminstration.sideNav.themes" />
              </span>
            </Menu.Item>
            <Menu.Item key="11" onClick={handleHelpClick}>
              <span>
                <Translate id="adminstration.sideNav.help" />
              </span>
            </Menu.Item>
            <Menu.Item key="18" onClick={handleListAdvertisersClick}>
              <span>
                <Translate id="adminstration.sideNav.publicityArea" />
              </span>
            </Menu.Item>
            <Menu.Item key="22" onClick={handleCompanyList}>
              <span>
                <Translate id="company.adminArea.companies" />
                {count && count.numberOfCompaniesToApprove > 0 && (
                  <Badge
                    style={{ marginLeft: '1rem' }}
                    count={count.numberOfCompaniesToApprove}
                  />
                )}
              </span>
            </Menu.Item>
            <Menu.Item key="23" onClick={handleListMembers}>
              <span>
                <Translate id="administration.member.member" />
              </span>
            </Menu.Item>

            {/* <Divider /> */}
            <Menu.Item key="12" onClick={handleLogoutClick}>
              <span>
                <Translate id="adminstration.sideNav.logout" />
              </span>
            </Menu.Item>
          </Menu>
        );
    }
  }, [
    jwt,
    handleMyAds,
    handleLogoutClick,
    handleMyGuides,
    handleChangePassword,
    handlePaymentIntermediateClick,
    count,
    handleGuideClick,
    handleAllRoutesClick,
    handlePaymentClick,
    handleVisitorsClick,
    handleIntermediate,
    handleSubjectsClick,
    handleAccessibilityClick,
    handleLandingPageGuideInformationClick,
    handleLandingPageGuideRouteSelectionClick,
    handleLandingPageGuideWhatWeLookingForClick,
    handleLandingPageGuideGuideSelectClick,
    handleLandingPageGuideWhatWeGiveClick,
    handleLandingPageGuideFAQClick,
    handleLandingPageVisitorClick,
    handleVisitorLandingPageStepsClick,
    handleLandingPageAboutClick,
    handleLandingPageSelectTestimonyClick,
    handleSystemConfigurationClick,
    handleListAdminsClick,
    handleThemesClick,
    handleHelpClick,
    handleListAdvertisersClick,
    handleCompanyList,
    handleListMembers,
  ]);
  return (
    <div>
      <div
        style={{
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          margin: '16px',
        }}
      >
        <FallbackImage
          className={classes.logo}
          alt="logo"
          src={LogoUrl}
          fallback={require('../../App/Header/assets/logo_cor.png')}
        />
      </div>
      {myMenus}
    </div>
  );
};
export default SideNav;
