import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseWhite } from '../../../shared_assets/icons/close_white.svg';
import { ReactComponent as ShareBlue } from '../../../shared_assets/icons/share_blue.svg';
import { ReactComponent as ShareWhite } from '../../../shared_assets/icons/share_white.svg';
import {
  desktopPadding,
  getTheme,
  headerHeight,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { Badge } from '../Badge';
import ShareModal from './ShareModal';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    height: headerHeight,
    minHeight: headerHeight,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    zIndex: 2,
    position: 'relative',
    padding: '1rem',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      justifyContent: 'flex-end',
      ...desktopPadding,
    },
    badge: {
      '& *': {
        color: `${getTheme().defaultTextPrimaryColor} !important`,
      },
    },
  },
});

interface HeaderProps {
  title: string;
  routeId: string;
}

export default function ShareHeader({ title, routeId }: HeaderProps) {
  const classes = useStyles();
  const [isShowingShareModal, setIsShowingShareModal] = useState(false);
  const history = useHistory();
  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      {isMobile && (
        <div
          onClick={() => {
            if (history.length > 0) history.goBack();
            else history.push('/guide/landing');
          }}
        >
          <CloseWhite className={classes.icon} />
        </div>
      )}
      {isMobile ? (
        <div
          onClick={() => {
            setIsShowingShareModal(true);
          }}
        >
          <ShareWhite className={classes.icon} />
        </div>
      ) : (
        <Badge
          prefix={<ShareBlue className={classes.icon} />}
          onClick={() => {
            setIsShowingShareModal(true);
          }}
          className={classes.badge}
        >
          <Translate id="share.share" />
        </Badge>
      )}
      <ShareModal
        routeId={routeId}
        routeTitle={title}
        title={title}
        isVisible={isShowingShareModal}
        onClose={() => setIsShowingShareModal(false)}
      />
    </div>
  );
}
