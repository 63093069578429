import React from 'react';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import BeatLoader from 'react-spinners/BeatLoader';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function Loader() {
  const classes = useStyles();
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <BeatLoader />
    </motion.div>
  );
}
