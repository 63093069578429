import { Autocomplete } from '@react-google-maps/api';
import React, { useCallback, useRef } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import { ReactComponent as Close } from '../../../shared_assets/icons/close black.svg';
import { ReactComponent as MapMarker } from '../../../shared_assets/icons/map_marker.svg';
import { PlACE_BOUNDS } from '../../../utils';
import { Input } from '../Input';

interface PlaceSearchBoxProps extends LocalizeContextProps {
  onPlaceSelected: (place: ReturnedPlace | undefined) => void;
  value?: string;
  onChange?: (v: string) => void;
  clearable?: boolean;
}

export interface ReturnedPlace {
  formatted_address: string;
  name: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
}

const PlaceSearchBox = ({
  onPlaceSelected,
  onChange,
  translate,
  value,
  clearable,
}: PlaceSearchBoxProps) => {
  const searchBox = useRef<any>();

  const onPlacesChanged = useCallback(() => {
    onPlaceSelected(searchBox.current.getPlace());
  }, [onPlaceSelected]);

  const onLoad = useCallback((ref: any) => {
    searchBox.current = ref;
  }, []);

  const handleInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return;

      onChange(ev.target.value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      onLoad={onLoad}
      //options={{ types: '(regions)' }}
      onPlaceChanged={onPlacesChanged}
      options={{
        bounds: PlACE_BOUNDS,
        componentRestrictions: {
          country: 'pt',
        },
      }}
    >
      <Input
        huge
        onChange={handleInputChange}
        value={value}
        placeholder={translate('routes.searchPlacesPlaceholder').toString()}
        prefix={<MapMarker />}
        suffix={
          clearable &&
          value !== '' && (
            <div
              onClick={() => {
                if (onChange) onChange('');
                onPlaceSelected(undefined);
              }}
            >
              <Close />
            </div>
          )
        }
      />
    </Autocomplete>
  );
};

export default React.memo(withLocalize(PlaceSearchBox));
