import classNames from 'classnames';
import React, { useMemo } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../../shared_assets/icons/edit.svg';
import { RootState } from '../../../../store';
import { DraftRouteNotification } from '../../../../types/notification.interface';
import { complexTranslate } from '../../../../utils';
import { Heading, Text } from '../../../Typography';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { NotificationsProps } from '../Notification';

interface NotificationDraftRouteProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: DraftRouteNotification;
}

const NotificationDraftRoute: React.FC<NotificationDraftRouteProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
}) => {
  const history = useHistory();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const { seen, type, routeTitles, routeId } = notification;
  const routeTitle = useMemo(() => {
    const translation = routeTitles.find(t => t.language === activeLanguage);
    return translation ? translation.title : routeTitles[0].title;
  }, [routeTitles, activeLanguage]);
  return (
    <>
      {!simpleVersion && (
        <div className={classes.rowDirection}>
          <EditIcon className={classes.icon} />
          <Heading level={5} noMargin>
            {translate(`notificationType.${type}`).toString()}
          </Heading>
          {!seen && (
            <Badge
              className={classNames(classes.marginLeft, classes.uppercase)}
              type="primary"
              small
            >
              {translate('notifications.new').toString()}
            </Badge>
          )}
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        <Text variant={!simpleVersion ? 'faded' : 'default'} noMargin>
          {/* {`Tem uma rota` + { routeTitle !== '' ? `,com titulo ${routeTitle},` : ''
          } + `nos rascunhos, não quer acaba-la?`} */}
          {routeTitle !== ''
            ? complexTranslate(
                translate('notifications.draftRouteWithName').toString(),
                {
                  '{routeTitle}': (
                    <Text key="title" className={classes.inlineBtn} noMargin>
                      {routeTitle}
                    </Text>
                  ),
                }
              )
            : translate('notifications.draftRouteWithoutName').toString()}
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="link"
          onlyText
          onClick={() => {
            history.push(`/guide/my-routes/edit/${routeId}`);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.continueRoute').toString()}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default withLocalize(NotificationDraftRoute);
