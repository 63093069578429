import Axios from 'axios';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { API_URL } from '../../consts';
import { LoginType } from '../../pages/Login/LoginForgotPasswordModal';
import { RoleType } from '../../types/role-type.enum';
import { RootState, store } from '../index';
import {
  ACCEPT_COOKIES,
  LOGIN,
  LOGOUT,
  SET_ACTIVE_LANGUAGE,
  SET_ONGOING_ROUTE,
  SET_VIDEO_URL,
  UserConfigurationActions,
} from './types';

export function setActiveLanguage(
  activeLanguageCode: string
): UserConfigurationActions {
  return {
    type: SET_ACTIVE_LANGUAGE,
    payload: activeLanguageCode,
  };
}

export function login(token: string): UserConfigurationActions {
  return {
    type: LOGIN,
    payload: { token },
  };
}

export function logout(): UserConfigurationActions {
  return {
    type: LOGOUT,
  };
}

export function acceptCookies(): UserConfigurationActions {
  return {
    type: ACCEPT_COOKIES,
  };
}

export function setVideoUrl(videoUrl: string | null): UserConfigurationActions {
  return {
    type: SET_VIDEO_URL,
    payload: {
      videoUrl,
    },
  };
}

export function setOngoingRoute(id: string | null): UserConfigurationActions {
  return {
    type: SET_ONGOING_ROUTE,
    payload: {
      id,
    },
  };
}

interface LoginDto {
  email: string;
  password: string;
  type: RoleType;
}

const loginRequest = (loginDto: LoginDto) =>
  Axios.post(`${API_URL}/authentication/sign-in`, loginDto);

type LoginResult = ThunkAction<Promise<void>, RootState, undefined, Action>;

export const attemptLogin =
  (loginDto: LoginDto): LoginResult =>
  async dispatch => {
    try {
      const response = await loginRequest(loginDto);
      Axios.defaults.headers['Authorization'] =
        'Bearer ' + response.data.accessToken;
      dispatch(login(response.data.accessToken));
      return Promise.resolve();
    } catch (err) {
      dispatch(logout());
      Axios.defaults.headers['Authorization'] = undefined;

      return Promise.reject(err);
    }
  };

export const authenticateGoogle =
  (type: LoginType) =>
  async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    try {
      const { data } = await Axios.get<{ accessToken: string }>(
        `${API_URL}/authentication/google`,
        {
          params: { access_token: response, type },
        }
      );

      Axios.defaults.headers['Authorization'] = 'Bearer ' + data.accessToken;
      store.dispatch(login(data.accessToken));
      return Promise.resolve();
    } catch (err) {
      store.dispatch(logout());
      Axios.defaults.headers['Authorization'] = undefined;

      return Promise.reject(err);
    }
  };

export const authenticateFacebook =
  (type: LoginType) => async (response: any) => {
    try {
      const { data } = await Axios.get<{ accessToken: string }>(
        `${API_URL}/authentication/facebook`,
        {
          params: {
            access_token: response.accessToken,
            type: type.toLowerCase(),
          },
        }
      );

      Axios.defaults.headers['Authorization'] = 'Bearer ' + data.accessToken;
      store.dispatch(login(data.accessToken));
      return Promise.resolve();
    } catch (err) {
      store.dispatch(logout());
      Axios.defaults.headers['Authorization'] = undefined;

      return Promise.reject(err);
    }
  };
