import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseGrey } from '../../../shared_assets/icons/close grey.svg';
import { ReactComponent as SearchGrey } from '../../../shared_assets/icons/search grey.svg';
import { ReactComponent as SearchWhite } from '../../../shared_assets/icons/search white.svg';
import { FilterRoute } from '../../../types/filter-route.dto';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';
import { Error } from '../Error';
import { Input } from '../Input';
import Loader from '../Loader/Loader';
import { Modal } from '../Modal';
import ResultList from './ResultList';
import { ResultType } from './useSearchResults';

const useStyles = makeStyles({
  searchHeader: {
    display: 'flex',
    marginBottom: '2rem',
  },
  searchingInput: {
    flex: 1,
  },
  cancelButton: {
    flex: 0,
    marginLeft: '1rem',
    color: getTheme().neutral1,
  },

  modalScrollInside: {
    maxHeight: '100%;',
    minHeight: 'unset !important',
    '& .ant-modal-body, & .ant-modal-content': {
      overflow: 'hidden',
    },
  },
  modalPadding: { padding: 24 },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.5rem',
  },
  icon: {
    height: 16,
    width: 16,
  },
});

interface RouteSearchMobileProps {
  searching: boolean;
  setSearching: (v: boolean) => void;
  error: boolean;
  search: string;
  setSearch: (v: string) => void;
  history: ReturnType<typeof useHistory>;
  translate: TranslateFunction;
  loading: boolean;
  results: ResultType[];
  recent: ResultType[];
  showBlack: boolean;
  filterDto: FilterRoute;
}

const RouteSearchMobile = ({
  searching,
  setSearching,
  error,
  history,
  recent,
  search,
  setSearch,
  translate,
  loading,
  results,
  showBlack,
  filterDto,
}: RouteSearchMobileProps) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        className={classes.modalScrollInside}
        footer={null}
        visible={searching}
        onRequestClose={() => setSearching(false)}
        maskClosable
        mask
        disableNaturalPadding
        contentContainerClassName={classes.modalPadding}
      >
        {error ? (
          <Error />
        ) : (
          <>
            <div className={classes.searchHeader}>
              <Input
                size="large"
                value={search}
                onChange={ev => setSearch(ev.target.value)}
                prefix={<SearchGrey />}
                suffix={search && <CloseGrey />}
                placeholder={translate('search.searchPlaceholder').toString()}
              />
              <Button
                onClick={() => {
                  setSearch('');
                  setSearching(false);
                }}
                className={classes.cancelButton}
                type="link"
                onlyText
              >
                <Text noMargin variant="faded">
                  <Translate id="search.cancel" />
                </Text>
              </Button>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <ResultList
                filterDto={filterDto}
                history={history}
                results={results}
                recent={recent}
                search={search}
                setSearching={setSearching}
                translate={translate}
              />
            )}
          </>
        )}
      </Modal>
      <div className={classes.iconContainer} onClick={() => setSearching(true)}>
        {showBlack ? (
          <SearchGrey className={classes.icon} />
        ) : (
          <SearchWhite className={classes.icon} />
        )}
      </div>
    </>
  );
};

export default RouteSearchMobile;
