import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Radio from 'antd/es/radio';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { GenericPageTransition } from '../../../pages/PageUtils';
import { RouteExperienceType } from '../../../types/route-experience.enum';
import { Heading, Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { GuideLanguageSelection } from '../GuideLanguageSelection';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    '& .ant-radio-group': {
      display: 'flex',
      flexDirection: 'column',
      '& > * ': {
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
    '& .ant-divider': {
      margin: '0.5rem 0 1.5rem 0',
    },
  },
  pickerContainer: {
    marginTop: '1rem',
  },
});

interface LanguageChoiceProps extends LocalizeContextProps {
  setSelectedLanguages: (v: SetStateAction<string[]>) => void;
  selectedLanguages: string[];
  availableLanguages: string[];
  routeExperienceType: RouteExperienceType;
  setRouteExperienceType: (v: RouteExperienceType) => void;
}

function Competences({
  selectedLanguages,
  setSelectedLanguages,
  availableLanguages,
  translate,
  setRouteExperienceType,
  routeExperienceType,
}: LanguageChoiceProps) {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.mainContainer}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Heading disableAutoscale level={2}>
        <Translate id="routes.aboutYou" />
      </Heading>
      <Heading level={5}>
        <Translate id="routes.whatIsTheLanguage" />
      </Heading>
      <Text variant="faded">
        <Translate id="routes.languagePickerInfo" />
      </Text>
      <div className={classes.pickerContainer}>
        <GuideLanguageSelection
          inputLanguages={availableLanguages}
          onChange={setSelectedLanguages}
          value={selectedLanguages}
        />
        <AnimatedError
          isVisible={selectedLanguages.length === 0}
          reason={translate('routes.mandatoryLanguages').toString()}
        />
      </div>
      <Divider />
      <Heading level={5}>
        <Translate id="routes.routeExperienceTypeTitle" />
      </Heading>
      <Radio.Group
        value={routeExperienceType}
        onChange={e =>
          setRouteExperienceType(e.target.value as RouteExperienceType)
        }
      >
        {Object.values(RouteExperienceType).map(type => (
          //
          <Radio value={type} key={type}>
            <Text noMargin>
              <Translate id={`routeExperienceType.${type}`} />
            </Text>
          </Radio>
          //</Text>
        ))}
      </Radio.Group>
    </motion.div>
  );
}

export default withLocalize(Competences);
