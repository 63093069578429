import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../components/App/Modal';
import { RootState } from '../../../store/index';
import { acceptCookies } from '../../../store/UserConfiguration/actions';
import { Button } from '../Button';

const useStyles = makeStyles({
  container: {
    padding: 24,
  },
});

export default function CookieConfirmationModal() {
  const hasAcceptedCookies = useSelector(
    (state: RootState) => state.userConfiguration.acceptedCookies
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Modal
      visible={!hasAcceptedCookies}
      closable={false}
      stickToBottom
      contentContainerClassName={classes.container}
      disableNaturalPadding
      zIndex={1001}
    >
      <p>
        <Translate id="landing.cookieWarning" />
      </p>
      <Button
        type="primary"
        onClick={() => {
          dispatch(acceptCookies());
        }}
      >
        OK
      </Button>
    </Modal>
  );
}
