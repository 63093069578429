import { makeStyles } from '@material-ui/styles';
import Badge from 'antd/es/badge';
import Divider from 'antd/es/divider';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useVisitor } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as Bill } from '../../../shared_assets/icons/bill.svg';
import { ReactComponent as ChangeProfile } from '../../../shared_assets/icons/change_profile.svg';
import { ReactComponent as Contacts } from '../../../shared_assets/icons/contactos.svg';
import { ReactComponent as Logout } from '../../../shared_assets/icons/exit.svg';
import { ReactComponent as Language } from '../../../shared_assets/icons/language.svg';
import { ReactComponent as NotificationBlue } from '../../../shared_assets/icons/notification_blue.svg';
import { ReactComponent as NotificationYellow } from '../../../shared_assets/icons/notification_yellow.svg';
import { ReactComponent as Payment } from '../../../shared_assets/icons/payment.svg';
import { ReactComponent as About } from '../../../shared_assets/icons/quem_somos.svg';
import { ReactComponent as Routes } from '../../../shared_assets/icons/routes.svg';
import { ReactComponent as User } from '../../../shared_assets/icons/user.svg';
import { RootState } from '../../../store';
import { logout } from '../../../store/UserConfiguration/actions';
import { Visitor } from '../../../types/visitor.interface';
import { useIsMobile } from '../../../utils';
import { Text } from '../../Typography';
import { Badge as Chip } from '../Badge';
import Error from '../Error/Error';
import Loader from '../Loader/Loader';
import { AuthenticatedDrawerFragmentProps } from './AuthenticatedGuideDrawerFragment';
import DrawerItem from './DrawerItem';

const useStyles = makeStyles({
  profile: {
    display: 'flex',
    alignItems: 'center',
    '& > .ant-typography': {
      marginRight: '0.5rem !important',
    },
  },
  badge: {
    '& .ant-badge-dot': {
      width: '7px',
      height: '7px',
      top: '5px',
      right: '5px',
    },
  },
  divider: { margin: '0.5rem 0' },
});

function AuthenticatedVisitorDrawerFragment({
  reduxActiveLanguage,
  id: visitorId,
  setOpenStatus,
  setShowLanguageModal,
  setChangingAccountType,
}: AuthenticatedDrawerFragmentProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [visitor, setVisitor] = useState<Visitor | null>(null);
  const [isLoadingVisitor, hasErrorVisitor] = useVisitor(visitorId, setVisitor);

  const unseenNotifications = useSelector(
    (state: RootState) => state.app.visitorUnseenNotifications
  );

  const isMobile = useIsMobile();

  if (isLoadingVisitor) {
    return <Loader />;
  }

  if (hasErrorVisitor || !visitor) {
    return <Error />;
  }

  return (
    <>
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/visitor/profile"
        title={
          <Text
            noMargin
            weight="bold"
          >{`${visitor?.firstName} ${visitor?.lastName}`}</Text>
        }
        subtitle={
          <div className={classes.profile}>
            <Text variant="faded" noMargin>
              <Translate id="landing.drawer.profile" />
            </Text>
            <Chip type="primary">
              <Translate id="landing.drawer.visitor" />{' '}
            </Chip>
          </div>
        }
        icon={<User />}
      />
      <Divider className={classes.divider} />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/visitor/my-notifications"
        title={<Translate id="landing.drawer.notifications" />}
        icon={
          Number(unseenNotifications) === 0 ? (
            <NotificationBlue />
          ) : (
            <Badge dot className={classes.badge}>
              <NotificationYellow />
            </Badge>
          )
        }
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/visitor/bookings"
        title={<Translate id="landing.drawer.myBookings" />}
        icon={<Routes />}
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/visitor/bookings/history"
        title={<Translate id="landing.drawer.history" />}
        icon={<Payment />}
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/visitor/invoices"
        title={<Translate id="landing.drawer.receipts" />}
        icon={<Bill />}
      />
      {isMobile && (
        <>
          <Divider className={classes.divider} />
          <DrawerItem
            setOpenStatus={setOpenStatus}
            closeOnClick
            onClick={() => {
              setShowLanguageModal(true);
            }}
            title={<Translate id="landing.drawer.language" />}
            subtitle={
              <span>
                <Translate id={`languages.${reduxActiveLanguage}`} />
                {` (${reduxActiveLanguage.split('_')[1]})`}
              </span>
            }
            icon={<Language />}
          />
          <Divider className={classes.divider} />
          <DrawerItem
            setOpenStatus={setOpenStatus}
            closeOnClick
            to="/about"
            title={<Translate id="landing.whoWeAre" />}
            icon={<About />}
          />
          <DrawerItem
            setOpenStatus={setOpenStatus}
            closeOnClick
            to="/contacts"
            title={<Translate id="landing.drawer.contacts" />}
            icon={<Contacts />}
          />
        </>
      )}
      <Divider className={classes.divider} />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setChangingAccountType(true);
        }}
        title={<Translate id="landing.drawer.changeAccount" />}
        icon={<ChangeProfile />}
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/visitor"
        onClick={() => {
          dispatch(logout());
        }}
        title={<Translate id="landing.drawer.logout" />}
        icon={<Logout />}
      />
    </>
  );
}

export default AuthenticatedVisitorDrawerFragment;
