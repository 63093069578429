import { makeStyles } from '@material-ui/styles';
import { message } from 'antd';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CopyrightFooter } from '../../../../components/App/CopyrightFooter';
import { Loader } from '../../../../components/App/Loader';
import { VisitorBookingList } from '../../../../components/App/VisitorBookingList';
import { Heading } from '../../../../components/Typography';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import {
  useVisitorBookings,
  VisitorBookingInterface,
} from '../../../../hooks/visitor/booking/useVisitorBookings';
import { ReactComponent as Routes } from '../../../../shared_assets/icons/routes.svg';
import { BookingState } from '../../../../types/booking-state.enum';
import { ScheduleRepetitionStateType } from '../../../../types/schedule-repetition-state-type.enum';
import {
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../../utils';
import { GenericPageTransition } from '../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    marginBottom: '2rem !important',
  },
  container: {
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  desktopPrefix: {
    marginRight: '1rem',
    width: 40,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      ...desktopPadding,
    },
  },
  headerDivider: {
    marginTop: 0,
  },
});
interface SchedulesListProps extends LocalizeContextProps {}
const SchedulesList = ({ translate }: SchedulesListProps) => {
  const cont = useRef(0);
  const classes = useStyles();
  const [jwt] = UseJWT();
  const history = useHistory();
  const loc = useLocation();
  const filter = useMemo(
    () => ({
      bookingStates: [BookingState.ORDER_ISSUED, BookingState.PAYED],
      repetitionState: [ScheduleRepetitionStateType.TO_START],
    }),
    []
  );
  const [bookings, setBookings] = useState<VisitorBookingInterface[]>([]);
  const [bookingsAreLoading] = useVisitorBookings(
    jwt?.visitorId,
    setBookings,
    filter
  );
  const isMobile = useIsMobile();
  useEffect(() => {
    cont.current++;
    if (loc.pathname.split('/')[3] === 'payment-error' && cont.current === 2) {
      message.error(translate('booking.paymentFail').toString());
    }
  }, [loc.pathname, translate]);

  if (!jwt || !jwt.visitorId) {
    history.replace('/visitor');
    return null;
  }

  if (bookingsAreLoading) return <Loader />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.container}>
        <Heading level={2} className={classes.title}>
          {!isMobile && <Routes className={classes.desktopPrefix} />}
          <Translate id="booking.bookings" />
        </Heading>
        <VisitorBookingList data={bookings} />
      </div>
      <CopyrightFooter />
    </motion.div>
  );
};
export default withLocalize(SchedulesList);
