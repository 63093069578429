import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_URL } from '../../consts';
import { ListVisitorsAdminInterface } from '../../types/list-visitors-admin.interface';

type Hook = (
  setListGuides: (val: SetStateAction<ListVisitorsAdminInterface[]>) => void,
  info: {
    search: string;
  }
) => [boolean, boolean];

const requestAdmin = (
  cancelToken: CancelToken | undefined,
  info: {
    search: string;
  }
) =>
  Axios.get<ListVisitorsAdminInterface[]>(`${API_URL}/visitor`, {
    params: info,
    cancelToken,
  });

export const useListVisitors: Hook = (setListVisitors, info) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestAdmin(cancel.current?.token, info);

        setListVisitors(list);
        setIsError(false);
      } catch (err) {
        setListVisitors([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, info, setListVisitors]);

  return [isLoading, isError];
};
