import React from 'react';
import { Text } from '../../Typography';
import { getMajorTranslation, getMinorTranslation } from './utils';

interface CollapseHeaderProps {
  activeMajorStep: number;
  activeMinorStep: number;
  classes: {
    majorContainer: string;
    minorContainer: string;
  };
}

const CollapseHeader = ({
  classes,
  activeMajorStep,
  activeMinorStep,
}: CollapseHeaderProps) => {
  return (
    <div>
      <div className={classes.majorContainer}>
        <div className="step-container">{activeMajorStep + 1}</div>
        <Text noMargin weight="bold">
          {getMajorTranslation(activeMajorStep)}
        </Text>
      </div>
      <div className={classes.minorContainer}>
        <Text noMargin>
          {getMinorTranslation(activeMajorStep, activeMinorStep)}
        </Text>
      </div>
    </div>
  );
};

export default CollapseHeader;
