import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import AnimatedError from '../../../../components/App/AnimatedError/AnimatedError';
import { Button } from '../../../../components/App/Button';
import { Input } from '../../../../components/App/Input';
import { Heading, Text } from '../../../../components/Typography';
import { ReactComponent as BackWhite } from '../../../../shared_assets/icons/back_white.svg';
import { ReactComponent as Next } from '../../../../shared_assets/icons/next.svg';
import { ReactComponent as See } from '../../../../shared_assets/icons/see.svg';
import {
  castShadow,
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
} from '../../../../utils';
import { SlideFromRightTransition } from '../../../PageUtils';
import { OnBoardingForm } from '../useOnboardingForm';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    padding: '1rem 0',
  },
  alignToBottom: {
    alignSelf: 'flex-end',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    margin: '0 1rem',
  },
  inputsContainer: {
    marginTop: '1rem',
    textAlign: 'initial',
    '& .postalCode': {
      '& .first': {
        flex: 1,
      },
      '& .second': {
        flex: 0.5,
      },
      '& :last-child': {
        flex: 1,
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    margin: '1rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },
    container: {
      padding: '2rem 2rem 3rem 2rem',
      boxShadow: castShadow,
      borderRadius: '10px',
    },
  },
});

interface AddressStepProps extends LocalizeContextProps {
  form: OnBoardingForm;
  onRequestPreviousStep: () => void;
  onRequestNextStep: () => void;
}

function AddressStep({
  translate,
  form,
  onRequestPreviousStep,
  onRequestNextStep,
}: AddressStepProps) {
  const classes = useStyles();

  const nextDisabled =
    form.address.isInvalid ||
    form.zipFirstSegment.isInvalid ||
    form.zipSecondSegment.isInvalid ||
    form.location.isInvalid ||
    form.phoneNumber.isInvalid;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={SlideFromRightTransition}
    >
      <div className={classes.body}>
        <div className={classes.container}>
          <Heading level={4}>
            <Translate id="onboarding.address" />
          </Heading>
          <Heading level={5}>
            <Translate id="onboarding.whatAddress" />
          </Heading>
          <Text variant="faded">
            <Translate id="onboarding.addressInfo" />
          </Text>
          <div className={classes.inputsContainer}>
            <Text weight="bold">
              <Translate id="onboarding.address" />
            </Text>
            <Input
              placeholder={translate('onboarding.selectAnAddress').toString()}
              prefix={<See />}
              huge
              onChange={ev => {
                form.address.set(ev.currentTarget.value);
              }}
              value={form.address.value}
            />
            <AnimatedError
              isVisible={form.address.isInvalid}
              reason={form.address.reason}
            />
            <Text weight="bold">
              <Translate id="onboarding.postalCode" />
            </Text>
            <div className={classNames(classes.row, 'postalCode')}>
              <Input
                className={'first'}
                type="number"
                huge
                placeholder="0000"
                onChange={ev => {
                  form.zipFirstSegment.set(ev.currentTarget.value);
                }}
                value={form.zipFirstSegment.value}
              />
              <span className={classes.spacer}>-</span>
              <Input
                className={'second'}
                placeholder="000"
                type="number"
                huge
                onChange={ev => {
                  form.zipSecondSegment.set(ev.currentTarget.value);
                }}
                value={form.zipSecondSegment.value}
              />
              <div>{/*last-child fictitious*/}</div>
            </div>
            <AnimatedError
              isVisible={
                form.zipFirstSegment.isInvalid ||
                form.zipSecondSegment.isInvalid
              }
              reason={
                form.zipFirstSegment.reason || form.zipSecondSegment.reason
              }
            />
            <Text weight="bold">
              <Translate id="onboarding.location" />
            </Text>
            <Input
              placeholder={translate('onboarding.selectLocation').toString()}
              huge
              onChange={ev => {
                form.location.set(ev.currentTarget.value);
              }}
              value={form.location.value}
            />
            <AnimatedError
              isVisible={form.location.isInvalid}
              reason={form.location.reason}
            />
            <Text weight="bold">
              <Translate id="register.phoneNumber" />
            </Text>
            <Input
              placeholder={translate(
                'onboarding.phoneNumberPlaceholder'
              ).toString()}
              huge
              onChange={ev => {
                form.phoneNumber.set(ev.currentTarget.value);
              }}
              value={form.phoneNumber.value}
            />
            <AnimatedError
              isVisible={form.phoneNumber.isInvalid}
              reason={form.phoneNumber.reason}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          size="large"
          onClick={onRequestPreviousStep}
          prefix={<BackWhite />}
        >
          <Translate id="button.previous" />
        </Button>
        <Button
          size="large"
          disabled={nextDisabled}
          type="primary"
          onClick={onRequestNextStep}
          suffix={<Next />}
        >
          <Translate id="button.next" />
        </Button>
      </div>
    </motion.div>
  );
}

export default React.memo(withLocalize(AddressStep));
