/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/styles';
import { Divider } from 'antd';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../../../components/App/AnimatedError';
import { TabPane, Tabs } from '../../../../../../components/App/Tabs';
import { Text } from '../../../../../../components/Typography';
import {
  FaqEntry,
  getUnique,
  useFaqs,
} from '../../../../../../hooks/administration/useFaqAdmin';
import { postPostFaqs } from '../../../../../../hooks/administration/usePostFaqs';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  divider: {
    margin: '1rem 0',
  },
});

interface testeInterface {
  id: string;
  nome: string;
  email: string;
}
// interface FaqLocaInterface {
//   question: string;
//   answer: string;
//   order: number;
//   language: string;
// }
interface FaqPageProps extends LocalizeContextProps {}
const FaqAdmin = ({ languages, translate }: FaqPageProps) => {
  const classes = useStyles();
  const [faqs, setFaqs] = useState<FaqEntry[]>([]);
  const [orders, setOrders] = useState<number[]>([]);
  const [l, o] = useFaqs(setFaqs, setOrders);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [languagesLocalLanguages, setLanguagesLocalLanguages] = useState(
    languages.map(l => l.code)
  );

  useEffect(() => {
    if (faqs.length === 0) {
      setFaqs(
        languagesLocalLanguages.map((l, index) => {
          return {
            question: '',
            answer: '',
            order: 0,
            language: l,
          };
        })
      );
    }
  }, [faqs.length, languagesLocalLanguages]);

  const submit = useCallback(() => {
    if (
      faqs.some(
        p =>
          validator.isEmpty(p.question) ||
          validator.isEmpty(p.language) ||
          validator.isEmpty(p.answer)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    try {
      postPostFaqs({
        faqs: faqs,
      });
      message.success(translate('adminstration.faq.success').toString());
    } catch (error) {
      message.error(translate('adminstration.faq.error').toString());
    }
  }, [faqs, translate]);
  const add = useCallback(() => {
    const final = faqs.concat(
      languagesLocalLanguages.map((l, index) => {
        return {
          question: '',
          answer: '',
          order: Math.max(...faqs.map(f => f.order)) + 1,
          language: l,
        };
      })
    );
    setOrders(getUnique(final.map(f => f.order)));
    setFaqs(final);
  }, [faqs, languagesLocalLanguages]);

  return (
    <>
      {orders.map(order => {
        return (
          <Tabs type="card">
            {faqs
              .map(r => {
                const p1: FaqEntry[] = [];
                if (r.order === order) {
                  p1.push(r);
                }
                return p1;
              })
              .flat(1)
              .map((state, index) => {
                return (
                  <TabPane
                    tab={translate(`languages.${state.language}`)}
                    key={`order_${order}_${state.language}`}
                  >
                    {/* *********************ANCHOR firstTitle ************************ */}

                    <Text
                      key={`text1_${order}_${state.language}`}
                      noMargin
                      weight="semibold"
                    >
                      <Translate id="adminstration.faq.question" />
                    </Text>

                    <Input
                      key={`question_${order}_${state.language}`}
                      value={state.question}
                      onChange={ev => {
                        ev.persist();
                        setFaqs(previousStates =>
                          previousStates.map(oldState => {
                            if (
                              oldState.language === state.language &&
                              oldState.order === state.order
                            )
                              return {
                                ...oldState,
                                question: ev.target.value,
                              };
                            else return oldState;
                          })
                        );
                      }}
                    ></Input>
                    <div>
                      <AnimatedError
                        isVisible={validator.isEmpty(state.question)}
                        reason={
                          <Translate id="adminstration.guideLandingPage.mandatoryField" />
                        }
                      />
                    </div>
                    <Divider className={classes.divider} />
                    {/* *********************ANCHOR INTRO ************************ */}
                    <Text
                      key={`text2_${order}_${state.language}`}
                      noMargin
                      weight="semibold"
                    >
                      <Translate id="adminstration.faq.answer" />
                    </Text>
                    <Input
                      key={`answer_${order}_${state.language}`}
                      value={state.answer}
                      onChange={ev => {
                        ev.persist();
                        setFaqs(previousStates =>
                          previousStates.map(oldState => {
                            if (
                              oldState.language === state.language &&
                              oldState.order === state.order
                            )
                              return {
                                ...oldState,
                                answer: ev.target.value,
                              };
                            else return oldState;
                          })
                        );
                      }}
                    ></Input>
                    <div>
                      <AnimatedError
                        isVisible={validator.isEmpty(state.answer)}
                        reason={
                          <Translate id="adminstration.guideLandingPage.mandatoryField" />
                        }
                      />
                    </div>
                    <Divider className={classes.divider} />
                  </TabPane>
                );
              })}
          </Tabs>
        );
      })}

      <Button
        type="primary"
        onClick={() => {
          add();
        }}
        shape="circle"
      >
        +
      </Button>
      <Divider className={classes.divider} />

      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </>
  );
};
export default withLocalize(FaqAdmin);
