import { makeStyles } from '@material-ui/styles';
import ANTDCalendar from 'antd/es/calendar';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow_back.svg';
import { ReactComponent as ArrowBackGrey } from '../../../shared_assets/icons/back_grey.svg';
import { ReactComponent as ArrowForward } from '../../../shared_assets/icons/forward_black.svg';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';
import DateCell, { DateVariant } from './DateCell';

const useStyles = makeStyles({
  container: () => ({
    '& .ant-picker-cell::before': {
      content: 'none',
    },
    '& .ant-picker-content th': {
      color: getTheme().primary,
      fontWeight: 'bold',
      padding: '0.5rem 0',
    },
  }),
  calendar: {
    flex: 0,
  },
  padding: {
    padding: '0 5%',
  },
  bottomShadow: {
    boxShadow: '0px 9px 8px 0px rgba(0,0,0,0.2)',
  },
  headerContainer: {
    display: 'flex',
    padding: '0.5rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& > div > h4': {
      marginBottom: '0px !important',
    },
    '&  svg': {
      height: '2rem',
      width: '2rem',
    },
  },
  headerIcon: {
    height: '12px !important ',
    width: '12px !important',
    margin: '0 0.5rem',
  },
  headerIconZone: {
    '& > *:first-child': {
      marginRight: 18,
    },
  },
});

interface CalendarProps {
  value: Moment[];
  onChange: (v: Moment[]) => void;
  extraElement?: React.ReactNode;
  events?: Moment[];
  noHeader?: boolean;
  maxSelectedDates?: number;
  replaceWhenAtLimit?: boolean;
  alwaysKeepOneDate?: boolean;
  simple?: boolean;
  disablePreviousMonths?: boolean;
  noShadow?: boolean;
  noPadding?: boolean;
}

interface CalendarRef {
  selectToday: () => void;
}

const Calendar = (
  {
    value,
    onChange,
    extraElement,
    events,
    noHeader,
    maxSelectedDates,
    simple,
    noPadding,
    noShadow,
    replaceWhenAtLimit,
    disablePreviousMonths,
    alwaysKeepOneDate,
  }: CalendarProps,
  ref: any
) => {
  const [monthDiff, setMonthDiff] = useState<number>(0);
  const [selectedDates, setSelectedDates] = useState<Moment[]>([]);

  const classes = useStyles();
  const now = moment();
  const validRange: [Moment, Moment] = useMemo(
    () => [
      moment().utc().startOf('month').add(monthDiff, 'month'),
      moment()
        .utc()
        .startOf('month')
        .add(monthDiff + 1, 'month'),
    ],
    [monthDiff]
  );

  const onChangeRef = useRef<(v: Moment) => void | undefined>();

  useImperativeHandle(ref, () => ({
    selectToday: () => {
      if (onChangeRef.current) onChangeRef.current(moment());
      setSelectedDates([moment()]);
      setMonthDiff(0);
    },
  }));

  useEffect(() => {
    setSelectedDates(value);
  }, [value]);

  const changeDatesAndNotify = useCallback(
    selectedDates => {
      setSelectedDates(selectedDates);
      onChange(selectedDates);
    },
    [onChange]
  );

  const renderCell = useCallback(
    (date: Moment) => {
      let variant: DateVariant = undefined;
      const foundEvents = events?.filter(ev => ev.isSame(date, 'day'));

      if (date.month() === now.month() + monthDiff) {
        if (date.isSame(now, 'day')) {
          variant = 'today';
        }
        if (
          selectedDates.some(selectedDate => selectedDate.isSame(date, 'day'))
        ) {
          variant = 'selected';
        }
      } else {
        variant = 'disabled';
      }

      return (
        <DateCell
          key={date.toString()}
          variant={variant}
          eventCount={foundEvents?.length}
          date={date}
        />
      );
    },
    [events, now, selectedDates, monthDiff]
  );

  const headerRenderer = useMemo(
    () =>
      noHeader
        ? ({ onChange }: { onChange: (date: Moment) => void }) => {
            onChangeRef.current = onChange;
            return null;
          }
        : simple
        ? ({ onChange }: { onChange: (date: Moment) => void }) => {
            onChangeRef.current = onChange;
            return (
              <div className={classes.headerContainer}>
                <Text weight="bold">
                  {moment()
                    .startOf('day')
                    .add(monthDiff, 'month')
                    .format('MMMM YYYY')}
                </Text>
                <div className={classes.headerIconZone}>
                  {disablePreviousMonths && monthDiff === 0 ? (
                    <ArrowBackGrey className={classes.headerIcon} />
                  ) : (
                    <ArrowBack
                      className={classes.headerIcon}
                      onClick={() => {
                        setMonthDiff(prevMonthDiff => --prevMonthDiff);
                        onChange(moment().add(monthDiff - 1, 'month'));
                      }}
                    />
                  )}
                  <ArrowForward
                    className={classes.headerIcon}
                    onClick={() => {
                      onChange(moment().add(monthDiff + 1, 'month'));
                      setMonthDiff(prevMonthDiff => ++prevMonthDiff);
                    }}
                  />
                </div>
              </div>
            );
          }
        : ({ onChange }: { onChange: (date: Moment) => void }) => {
            onChangeRef.current = onChange;

            return (
              <div className={classes.headerContainer}>
                <div>
                  <Button
                    type="ghost"
                    onClick={() => {
                      onChange(now);
                      setMonthDiff(0);
                    }}
                  >
                    <Translate id="schedules.today" />
                  </Button>
                  {disablePreviousMonths && monthDiff === 0 ? (
                    <ArrowBackGrey className={classes.headerIcon} />
                  ) : (
                    <ArrowBack
                      className={classes.headerIcon}
                      onClick={() => {
                        setMonthDiff(prevMonthDiff => --prevMonthDiff);
                        onChange(moment().add(monthDiff - 1, 'month'));
                      }}
                    />
                  )}
                  <ArrowForward
                    className={classes.headerIcon}
                    onClick={() => {
                      onChange(moment().add(monthDiff + 1, 'month'));
                      setMonthDiff(prevMonthDiff => ++prevMonthDiff);
                    }}
                  />
                  <Text noMargin weight="bold">
                    {moment()
                      .startOf('day')
                      .add(monthDiff, 'month')
                      .format('MMMM YYYY')}
                  </Text>
                </div>
                {extraElement}
              </div>
            );
          },
    [
      classes,
      extraElement,
      monthDiff,
      noHeader,
      now,
      simple,
      disablePreviousMonths,
    ]
  );

  return (
    <div className={classes.container}>
      <ANTDCalendar
        className={classNames(
          noPadding ? '' : classes.padding,
          noShadow ? '' : classes.bottomShadow,
          classes.calendar
        )}
        dateFullCellRender={renderCell}
        validRange={validRange}
        onSelect={date => {
          if (
            date.isSame(moment().add(monthDiff - 1, 'month'), 'second') ||
            date.isSame(moment().add(monthDiff + 1, 'month'), 'second')
          ) {
            return;
          }

          let replace = false;

          if (maxSelectedDates) {
            if (
              replaceWhenAtLimit &&
              selectedDates.length === maxSelectedDates
            ) {
              replace = true;
            } else {
              if (selectedDates.length === maxSelectedDates) return;
            }
          }

          const newDates = selectedDates.some(d => d.isSame(date, 'day'))
            ? selectedDates.filter(d => !d.isSame(date, 'day'))
            : [...selectedDates, date];

          if (replace) newDates.splice(0, 1);

          if (alwaysKeepOneDate && newDates.length === 0) {
            return;
          }

          changeDatesAndNotify(newDates);
        }}
        fullscreen={false}
        headerRender={headerRenderer}
      />
    </div>
  );
};

export default React.memo(React.forwardRef(Calendar));
