import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';

import { API_URL } from '../../consts';

export type ThemeListResponse = {
  hostname: string;
  id: string;
  primary: string;
  secondary: string;
}[];

const fetchThemes = () =>
  Axios.get<ThemeListResponse>(`${API_URL}/content-management/themes`);

type Hook = (
  setter: (v: SetStateAction<ThemeListResponse>) => void
) => [boolean, boolean];

export const useThemes: Hook = setThemes => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await fetchThemes();
        setThemes(data);
      } catch (err) {
        setThemes([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setThemes]);

  return [isLoading, isError];
};
