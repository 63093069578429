import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Axios from 'axios';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AnimatedAlert } from '../../../components/Administration/AnimatedAlert';
import { AnimatedError } from '../../../components/App/AnimatedError';
import { Button } from '../../../components/App/Button';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import { FallbackImage } from '../../../components/App/Image';
import { InputNumber } from '../../../components/App/InputNumber';
import { Loader } from '../../../components/App/Loader';
import { Heading, Text } from '../../../components/Typography';
import { API_URL } from '../../../consts';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import {
  getRouteLocationImageUrl,
  useRoute,
} from '../../../hooks/routes/UseRoute';
import { useScheduleRepetition } from '../../../hooks/schedule/useScheduleRepetition';
import { useCut } from '../../../hooks/visitor/booking/cancelBooking';
import { useVisitor } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { ReactComponent as ArrowBackBlue } from '../../../shared_assets/icons/arrow_back_blue.svg';
import { ReactComponent as WarningYellow } from '../../../shared_assets/icons/warning_yellow.svg';
import { LanguageType } from '../../../types/language-type.enum';
import { RequestFromTypes } from '../../../types/request-from.enum';
import { Route } from '../../../types/route.interface';
import { ScheduleRepetition } from '../../../types/schedule-repetition';
import { Visitor } from '../../../types/visitor.interface';
import {
  castShadow,
  complexTranslate,
  desktopPadding,
  getTheme,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    ...mobilePadding,
  },
  flexContainer: {
    margin: '2rem 0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& h1': {
      fontWeight: `${600} !important` as unknown as number,
    },
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
    '& > div': {
      flex: 1,
      margin: '0 0.5rem',
      '&:last-child': {
        marginRight: 0,
      },
      '&:first-child': {
        marginLeft: 0,
      },
    },
    '& input': {
      textAlign: 'center',
    },
  },
  purchaseDetailsContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
  },
  purchaseInformation: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
  },
  purchaseImage: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginLeft: '1.5rem',
    alignSelf: 'start',
    '& > img, & > svg': {
      width: '100%',
      height: '70px',
      maxHeight: '30%',
      objectFit: 'cover',
      borderRadius: '5px',
    },
  },
  paymentMethodsContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  paymentContent: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
  },
  creditDebitCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  validationCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0',
    '& > div': {
      flex: 1,
      margin: '0 0.5rem',
      '&:last-child': {
        marginRight: 0,
      },
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  errorIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
    position: 'unset',
  },
  icon: {
    width: '2rem',
    height: '2rem',
    marginRight: '0.2rem',
    borderRadius: '3px',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginBetween: {
    margin: '1.5rem 0',
  },
  noMargin: {
    margin: '0',
  },
  divider: {
    '& .ant-divider': {
      margin: '1rem 0',
    },
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    flexBack: {
      flex: 0.15,
    },
    flexContainer: {
      flex: 0.85,
      margin: 'unset',
    },
    flexElse: {
      flex: 0.5,
      paddingRight: '2rem',
    },
    flexDetails: {
      flex: 0.5,
      maxWidth: '350px',
      '& > div': {
        padding: '2rem',
        boxShadow: castShadow,
        borderRadius: '5px',
      },
    },
    body: {
      flexDirection: 'row',
      ...desktopPadding,
    },
    container: {
      flexDirection: 'row',
    },
    purchaseDetailsContent: {
      flexDirection: 'column-reverse',
      justifyContent: 'unset',
      alignItems: 'unset',
    },
    purchaseInformation: {
      flex: 1,
      marginTop: '1rem',
    },
    purchaseImage: {
      flex: 1,
      justifyContent: 'unset',
      marginLeft: 'unset',
      alignSelf: 'unset',
      '& > img, & > svg': {
        width: '100%',
        height: '150px',
        maxHeight: '50%',
        objectFit: 'cover',
        borderRadius: '5px',
      },
    },
  },
});

interface BookingParams {
  scheduleId: string;
  routeId: string;
}

interface BookingState {
  date: string;
}

interface RouteBookingProps extends LocalizeContextProps {
  routeId: string;
  title: string;
  dateId: string;
  date: moment.Moment;
  photoId: string;
}
const getQueryString = (): string => {
  const queryString = window.location.search.substring(1);
  const value = queryString.split('=');
  return value[1];
};

function RouteBooking({ translate }: RouteBookingProps) {
  const history = useHistory();
  const [jwt] = UseJWT();
  const classes = useStyles();
  const { scheduleId, routeId } = useParams<BookingParams>();
  const success: string = getQueryString();
  const location = useLocation<BookingState | undefined>();
  const isMobile = useIsMobile();
  const [adults, setAdults] = useState<number>(1);
  const [children, setChildren] = useState<number>(0);
  const [seniors, setSeniors] = useState<number>(0);
  const [adultPrice, setAdultPrice] = useState<number>(0);
  const [childPrice, setChildPrice] = useState<number>(0);
  const [seniorPrice, setSeniorPrice] = useState<number>(0);
  const [cut, setCut] = useState<number | null>(0);
  const [adultBasePrice, setAdultBasePrice] = useState(0);
  const [childBasePrice, setChildBasePrice] = useState(0);
  const [seniorBasePrice, setSeniorBasePrice] = useState(0);
  const [route, setRoute] = useState<Route | null>(null);
  const [scheduleRepetition, setScheduleRepetition] =
    useState<ScheduleRepetition | null>(null);
  const [isLoadingRoute, hasErrorRoute] = useRoute(routeId, setRoute, true);

  const [isLoadingScheduleRepetition, hasErrorScheduleRepetition] =
    useScheduleRepetition(scheduleId, setScheduleRepetition);
  const [thereWasABookingError, setThereWasABookingError] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [visitor, setVisitor] = useState<Visitor | null>(null);

  const [isLoadingVisitor, hasErrorVisitor] = useVisitor(
    jwt?.visitorId,
    setVisitor
  );

  useCut(setCut);
  // useEffect(() => {
  //   if (scheduleRepetition && scheduleRepetition.adultPrice !== null) {
  //     setPrice(scheduleRepetition.price);
  //     setAdultPrice(scheduleRepetition.adultPrice * adults);
  //     setChildPrice(scheduleRepetition.childrenGreater12yearPrice * children);
  //     setSeniorPrice(scheduleRepetition.seniorPrice * seniors);
  //   } else if (route) {
  //     setPrice(route.price);
  //   }
  // }, [route, scheduleRepetition]);
  useEffect(() => {
    if (scheduleRepetition && scheduleRepetition.adultPrice !== null) {
      setAdultBasePrice(Math.round(scheduleRepetition.adultPrice * 100) / 100);
      setChildBasePrice(
        Math.round(scheduleRepetition.childrenGreater12yearPrice * 100) / 100
      );
      setSeniorBasePrice(
        Math.round(scheduleRepetition.seniorsPrice * 100) / 100
      );
    } else if (route) {
      setAdultBasePrice(Math.round(route.adultPrice * 100) / 100);
      setChildBasePrice(
        Math.round(route.childrenGreater12yearPrice * 100) / 100
      );
      setSeniorBasePrice(Math.round(route.seniorsPrice * 100) / 100);
    }
  }, [route, scheduleRepetition]);
  useEffect(() => {
    setAdultPrice(Math.round(adultBasePrice * adults * 100) / 100);
    setChildPrice(Math.round(childBasePrice * children * 100) / 100);
    setSeniorPrice(Math.round(seniorBasePrice * seniors * 100) / 100);
  }, [
    adults,
    children,
    seniors,
    adultBasePrice,
    childBasePrice,
    seniorBasePrice,
  ]);
  const totalValue = useMemo(() => {
    return adultPrice + childPrice + seniorPrice;
  }, [adultPrice, childPrice, seniorPrice]);

  const haveVisitorsOver18 = useMemo(
    () => adults > 0 || seniors > 0,
    [adults, seniors]
  );
  const handleSubmit = useCallback(async () => {
    try {
      const { data: bookingInformation } = await Axios.post(
        `${API_URL}/booking`,
        {
          visitorId: visitor?.id,
          scheduleDateId: scheduleId,
          groupSize: adults + children + seniors,
          language: LanguageType.PT,
          bookingPrice: totalValue,
          adultPrice: adultPrice,
          childrenGreater12yearPrice: childPrice,
          childrenLower12yearPrice: 0,
          seniorsPrice: seniorPrice,
          routeId,
          adults,
          seniors,
          childrenGreater12year: children,
          childrenLower12year: 0,
          requestFrom: RequestFromTypes.WEB,
        }
      );
      setThereWasABookingError(false);
      window.location.href = bookingInformation;
    } catch (err) {
      if (err.response.data.status === 409) {
        setErrorMessage(
          translate(`error.API_ERROR.${err.response.data.error}`).toString()
        );
      } else if (err.response.data.status === 412) {
        setErrorMessage(
          translate(`error.API_ERROR.PRICE_MISS_MATCH`).toString()
        );
        //TODO:COMPOR ISSO
        // const { data: price } = await Axios.get(
        //   `${API_URL}/booking/${scheduleId}/price`
        // );
        // //  setPrice(price);
        // setAdultPrice(price * adults);
        // setChildPrice(price * children);
        // setSeniorPrice(price * seniors);
      } else if (err.response.data.status === 400) {
        setErrorMessage(
          translate(`error.API_ERROR.BAD_DATE_REQUEST`).toString()
        );
      } else if (err && err.response && err.response.data) {
        setErrorMessage(
          translate(`error.API_ERROR.${err.response.data.error}`).toString()
        );
      } else {
        setErrorMessage(translate('error.connectionFailed').toString());
      }
      setThereWasABookingError(true);
    }
  }, [
    adultPrice,
    adults,
    childPrice,
    children,
    routeId,
    scheduleId,
    seniorPrice,
    seniors,
    totalValue,
    translate,
    visitor,
  ]);
  if (isLoadingVisitor || isLoadingRoute || isLoadingScheduleRepetition) {
    return <Loader />;
  }

  if (success && success === 'false' && thereWasABookingError === false) {
    setErrorMessage(translate(`booking.paymentUnsuccessful`).toString());
    setThereWasABookingError(true);
  }

  if (
    hasErrorVisitor ||
    !visitor ||
    hasErrorRoute ||
    !route ||
    !scheduleRepetition ||
    hasErrorScheduleRepetition
  ) {
    return <Error />;
  }

  const renderPurchaseDetails = () => {
    return (
      <div className={classes.flexDetails}>
        <div>
          <Heading level={4}>
            <Translate id={'booking.seePurchaseDetails'} />
          </Heading>
          <div className={classes.purchaseDetailsContent}>
            <div className={classes.purchaseInformation}>
              <Heading level={6} noMargin>
                {route.titles[0] ? route.titles[0].title : ''}
              </Heading>
              <Text variant="note" color={getTheme().neutral1} noMargin>
                {moment(location.state?.date).format('l LT')}
              </Text>
            </div>
            <div className={classes.purchaseImage}>
              <FallbackImage
                fallback={require('../../../shared_assets/images/route-default.png')}
                src={
                  route.locations.some(loc => loc.photos.length > 0)
                    ? getRouteLocationImageUrl(
                        route.locations
                          .map(loc => loc.photos.map(photo => photo.id))
                          .flat()[0]
                      )
                    : require('../../../shared_assets/images/route-default.png')
                }
                alt="place"
              />
            </div>
          </div>
          {adults > 0 || children > 0 || seniors > 0 ? (
            <Divider style={{ margin: '1rem 0' }} />
          ) : (
            ''
          )}
          {adults > 0 && (
            <div className={classes.spaceBetween}>
              <Text weight="semibold" noMargin>
                {complexTranslate(
                  translate('replaceValue.currencyAdult').toString(),
                  {
                    '{value}': adultBasePrice.toString(),
                    '{number}': adults.toString(),
                  }
                )}
              </Text>
              <Text weight="semibold" noMargin>
                {complexTranslate(
                  translate('replaceValue.totalValue').toString(),
                  {
                    '{value}': adultPrice.toFixed(2),
                  }
                )}
              </Text>
            </div>
          )}
          {children > 0 && (
            <div className={classes.spaceBetween}>
              <Text weight="semibold" noMargin>
                {complexTranslate(
                  translate('replaceValue.currencyChild').toString(),
                  {
                    '{value}': childBasePrice.toString(),
                    '{number}': children.toString(),
                  }
                )}
              </Text>
              <Text weight="semibold" noMargin>
                {complexTranslate(
                  translate('replaceValue.totalValue').toString(),
                  {
                    '{value}': childPrice.toFixed(2),
                  }
                )}
              </Text>
            </div>
          )}
          {seniors > 0 && (
            <div className={classes.spaceBetween}>
              <Text weight="semibold" noMargin>
                {complexTranslate(
                  translate('replaceValue.currencySenior').toString(),
                  {
                    '{value}': seniorPrice.toString(),
                    '{number}': seniors.toString(),
                  }
                )}
              </Text>
              <Text weight="semibold" noMargin>
                {complexTranslate(
                  translate('replaceValue.totalValue').toString(),
                  {
                    '{value}': seniorPrice.toFixed(2),
                  }
                )}
              </Text>
            </div>
          )}
          {adults > 0 || children > 0 || seniors > 0 ? (
            <>
              <Divider style={{ margin: '1rem 0' }} />
              <div className={classes.spaceBetween}>
                <Text weight="bold" noMargin>
                  {complexTranslate(
                    translate('replaceValue.totalCurrency').toString(),
                    {
                      '{currency}': translate(
                        'booking.currentCurrency'
                      ).toString(),
                    }
                  )}
                </Text>
                <Text weight="bold" noMargin>
                  {complexTranslate(
                    translate('replaceValue.totalValue').toString(),
                    {
                      '{value}':
                        totalValue === 0
                          ? translate(`routes.giveAway`)
                          : totalValue.toFixed(2),
                    }
                  )}
                </Text>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.body}>
        <div className={classes.flexBack}>
          {isMobile ? (
            <div onClick={() => history.goBack()}>
              <ArrowBack />
            </div>
          ) : (
            <Button
              onClick={() => history.goBack()}
              type="ghost"
              size="large"
              prefix={<ArrowBackBlue />}
            >
              <Translate id="button.goBack" />
            </Button>
          )}
        </div>
        <div className={classes.flexContainer}>
          <AnimatedAlert
            showIcon
            icon={<WarningYellow className={classes.errorIcon} />}
            type="error"
            visible={thereWasABookingError}
            message={errorMessage}
            style={{ marginBottom: '1.5rem' }}
          />
          <Heading level={2} noMargin>
            <Translate id={'booking.title'} />
          </Heading>
          <div className={classes.container}>
            <div className={classes.flexElse}>
              <div style={{ margin: '1.5rem 0' }}>
                <Heading level={4}>
                  <Translate id={'booking.questionWhosGoing'} />
                </Heading>
                <div className={classNames(classes.selectContainer)}>
                  <div>
                    <Text noMargin>
                      <Translate id={'booking.adults'} />
                    </Text>
                    <InputNumber
                      huge
                      positive
                      value={adults}
                      onChange={setAdults}
                    />
                  </div>
                  <div>
                    <Text noMargin>
                      <Translate id={'booking.children'} />
                    </Text>
                    <InputNumber
                      huge
                      positive
                      value={children}
                      onChange={setChildren}
                    />
                  </div>
                  <div>
                    <Text noMargin>
                      <Translate id={'booking.seniors'} />
                    </Text>
                    <InputNumber
                      huge
                      positive
                      value={seniors}
                      onChange={setSeniors}
                    />
                  </div>
                </div>
                <AnimatedError
                  isVisible={!haveVisitorsOver18}
                  reason={
                    !haveVisitorsOver18
                      ? translate('booking.mustBeVisitorOver18').toString()
                      : ''
                  }
                />
              </div>
              {isMobile && renderPurchaseDetails()}

              <div>
                <Heading level={5}>
                  <Translate id={'booking.ages'} />
                </Heading>
                <Text>
                  <Translate id={'booking.explainAdult'} />
                  <br></br>
                  <Translate id={'booking.explainChild'} />
                  <br></br>
                  <Translate id={'booking.explainSenior'} />
                  <br></br>
                  <Translate id={'booking.noPaymentChild'} />
                </Text>
              </div>
              <div>
                <Heading level={5}>
                  <Translate id={'booking.cancellationPolicy'} />
                </Heading>
                {moment().diff(scheduleRepetition.date, 'days') < 14 ? (
                  <Text>
                    {complexTranslate(
                      translate('booking.cancellationInfoLessThen').toString(),
                      {
                        '{CUT}': cut,
                      }
                    )}
                  </Text>
                ) : (
                  <Text>
                    {complexTranslate(
                      translate(
                        'booking.cancellationInfoGratherThen'
                      ).toString(),
                      {
                        '{CUT}': cut,
                      }
                    )}
                  </Text>
                )}
                <Text>
                  <Link
                    to="/terms-of-service#refund-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate id={'knowMore'} />
                  </Link>
                </Text>
              </div>
              <div className={classes.marginBetween}>
                <Text>
                  {complexTranslate(
                    translate('booking.confirmPurchaseInfo').toString(),
                    {
                      '{DWT}': (
                        <Link
                          to="/terms-of-service#visitor"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            <Translate id="booking.disclosureWaiverTerms" />
                          </Button>
                        </Link>
                      ),
                      '{PTS}': (
                        <Link
                          to="/terms-of-service#market"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            key="2"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            <Translate id="booking.paymentTermsService" />
                          </Button>
                        </Link>
                      ),
                      '{CP}': (
                        <Link
                          to="/terms-of-service#cancel-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            key="3"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            <Translate id="booking.cancellationPolicy" />
                          </Button>
                        </Link>
                      ),
                      '{RP}': (
                        <Link
                          to="/terms-of-service#refund-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            key="4"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            <Translate id="booking.RefundPolicy" />
                          </Button>
                        </Link>
                      ),
                    }
                  )}
                </Text>
              </div>
              <Button onClick={handleSubmit} type="primary" size="large">
                <Translate id="booking.confirmAndPay" />
              </Button>
            </div>
            {!isMobile && renderPurchaseDetails()}
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
      <CopyrightFooter showSocialLinks />
    </motion.div>
  );
}

export default withLocalize(RouteBooking);
