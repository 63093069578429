import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

import { getTheme } from '../../../utils';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  badge: {
    borderRadius: '5px',
    textAlign: 'center',
    display: 'inline-flex',
    padding: '0.1rem 0.6rem',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  initial: {
    textTransform: 'initial',
  },
  small: {
    '& *': {
      width: '10px !important',
      height: '10px !important',
      fontSize: '10px !important',
    },
  },
  badgeType: ({
    type,
    small,
  }: {
    type: string;
    small: boolean | undefined;
  }) => {
    let backgroundColor = 'white';
    let color = getTheme().primary;

    let width = '14px !important';
    let height = '14px !important';
    let fontSize = '14px !important';

    if (small) {
      fontSize = '12px !important';
    }

    switch (type) {
      case 'error':
        color = 'white !important';
        backgroundColor = `${getTheme().errorBase} !important`;
        break;
      case 'warning':
        color = `${getTheme().defaultTextPrimaryColor} !important`;
        backgroundColor = `${getTheme().warningBase} !important`;
        break;
      case 'success':
        color = 'white !important';
        backgroundColor = `${getTheme().successBase} !important`;
        break;
      case 'none':
        color = `${getTheme().defaultTextPrimaryColor} !important`;
        backgroundColor = `${getTheme().neutral4} !important`;
        break;
      case 'primary':
        color = `white !important`;
        backgroundColor = `${getTheme().primary} !important`;
        break;
      default:
        break;
    }

    return {
      backgroundColor,
      '& > .text': {
        color,
      },
      '& *': {
        fontSize,
        display: 'flex',
        alignSelf: 'center',
      },
      '& > .prefix > *': {
        width,
        height,
        marginRight: '0.5rem',
      },
      '& > .suffix': {
        width,
        height,
        marginLeft: '0.5rem',
      },
    };
  },
});

interface BadgeInterface {
  type?: 'default' | 'warning' | 'error' | 'success' | 'none' | 'primary';
  className?: string;
  uppercase?: boolean;
  small?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onClick?: () => void;
}

const Badge: React.FC<BadgeInterface> = ({
  type,
  children,
  className,
  uppercase,
  prefix,
  suffix,
  small,
  onClick,
}) => {
  const classes = useStyles({ type: type || 'default', small: small });

  return (
    <div
      className={classNames(
        classes.badge,
        classes.badgeType,
        className
        // uppercase ? classes.uppercase : classes.initial,
      )}
      onClick={onClick}
    >
      {prefix && <div className={'prefix'}>{prefix}</div>}
      <Text className={'text'} noMargin>
        {children}
      </Text>
      {suffix && <span className={'suffix'}>{suffix}</span>}
    </div>
  );
};

export default Badge;
