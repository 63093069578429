import { SetStateAction } from "react";
import validator from "validator";
import { getTranslate } from "react-localize-redux";
import { GuideForm, GuideFormProperty } from "../../types/guide.interface";
import { GuideDescription } from "../../types/guide-description.interface";
import { store } from "../../store/index";

export const setGuideFormValue = (
  setForm: (prevForm: SetStateAction<GuideForm | null>) => void
) => (field: GuideFormProperty, value: string) => {
  setForm(prevForm => {
    let valid = false;
    let sanitizedValue = value.trim();

    //TODO: Ensure all GuideFormProperties are handled
    //TODO: Turn these into simpler, cleaner hooks, see useLoginForm.ts
    switch (field) {
      case "address":
        valid = !validator.isEmpty(sanitizedValue);
        break;
      case "nif":
        valid =
          validator.isNumeric(sanitizedValue) && sanitizedValue.length === 9;
        break;
      case "iban":
        valid = !validator.isEmpty(sanitizedValue); //TODO: Proper mask for IBAN, validator should have this very soon
        break;
      case "location":
        valid = !validator.isEmpty(sanitizedValue);
        break;
      default:
        valid = false;
    }

    if (prevForm)
      return {
        ...prevForm,
        [field]: {
          value: value,
          sanitizedValue: sanitizedValue,
          isValid: valid
        }
      };
    else return null;
  });
};

export const setGuideDescription = (
  setDescriptions: (a: SetStateAction<GuideDescription[]>) => void,
  lang: string
) => (description: GuideDescription) => {
  setDescriptions(previousDescriptions =>
    previousDescriptions.map(desc =>
      desc.language === lang ? description : desc
    )
  );
};

type GuideError =
  | GuideFormProperty
  | "languages"
  | "noAvatar"
  | "username"
  | "password";

export const getGuideErrorMessagesFromField = (field: GuideError) => {
  const translate = getTranslate(store.getState().localize);

  //TODO: Removed now unused translations but some are missing see that all GuideError's are handled in switch
  switch (field) {
    case "address":
      return translate("administration.guides.errors.badAddress");
    case "nif":
      return translate("administration.guides.errors.badNif");
    case "iban":
      return translate("administration.guides.errors.badIban");
    case "location":
      return translate("administration.guides.errors.badLocation");
    case "languages":
      return translate("administration.guides.errors.badLanguages");
    case "noAvatar":
      return translate("administration.guides.errors.mandatoryAvatar");
    case "username":
      return translate("administration.guides.errors.mandatoryAvatar");
    case "password":
      return translate("administration.guides.errors.mandatoryAvatar");
    default:
      return "Not such field in errors";
  }
};
