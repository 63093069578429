import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { ReactComponent as Group } from '../../../shared_assets/icons/group.svg';
import { complexTranslate } from '../../../utils';
import { Text } from '../../Typography';
import BookingEntry from './BookingEntry';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  groupIcon: {
    height: '1.2rem',
    width: '1.2rem',
    marginRight: '0.5rem',
  },
  headerContainer: {
    '& > *': {
      marginBottom: 0,
    },
    marginBottom: '1rem',
  },
});

interface BookingSectionProps extends LocalizeContextProps {
  canceled: boolean;
  maxVisitors?: number;
  bookings: {
    visitorId: string;
    visitorName: string;
    groupSize: number;
  }[];
}

function BookingSection({
  canceled,
  bookings,
  maxVisitors,
  translate,
}: BookingSectionProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Group className={classes.groupIcon} />
      <div>
        <div className={classes.headerContainer}>
          <Text noMargin>
            {canceled ? (
              bookings.length === 1 && bookings[0].groupSize === 1 ? (
                <Translate id="schedules.singleCanceledBooking" />
              ) : (
                complexTranslate(
                  translate('schedules.multipleCanceledBookings').toString(),
                  {
                    '{NUM}': bookings.reduce(
                      (total, b) => (total += b.groupSize),
                      0
                    ),
                  }
                )
              )
            ) : bookings.length === 1 && bookings[0].groupSize === 1 ? (
              <Translate id="schedules.singleActiveBooking" />
            ) : (
              complexTranslate(
                translate('schedules.multipleActiveBookings').toString(),
                {
                  '{NUM}': bookings.reduce(
                    (total, b) => (total += b.groupSize),
                    0
                  ),
                }
              )
            )}
          </Text>

          {maxVisitors && (
            <Text size={12} variant="faded">
              {maxVisitors === 1 ? (
                <Translate id="schedules.singleMaxBookings" />
              ) : (
                complexTranslate(
                  translate('schedules.multipleMaxBookings').toString(),
                  { '{NUM}': maxVisitors }
                )
              )}
            </Text>
          )}
        </div>
        {bookings.map((b, i) => (
          <BookingEntry
            key={i.toString()}
            canceled={canceled}
            groupSize={b.groupSize}
            visitorId={b.visitorId}
            visitorName={b.visitorName}
          />
        ))}
      </div>
    </div>
  );
}

export default withLocalize(BookingSection);
