import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Badge as Chip } from '../../../../components/App/Badge';
import { BookingSection } from '../../../../components/App/BookingSection';
import { Button } from '../../../../components/App/Button';
import { Loader } from '../../../../components/App/Loader';
import { Modal } from '../../../../components/App/Modal';
import { Heading, Text } from '../../../../components/Typography';
import { getRouteLocationImageUrl } from '../../../../hooks/routes/UseRoute';
import { cancelScheduleRepetition } from '../../../../hooks/schedule/cancelSchedule';
import { useRepetitionBookings } from '../../../../hooks/schedule/useRepetitionBookings';
import { ReactComponent as CalendarIcon } from '../../../../shared_assets/icons/calendar.svg';
import { ReactComponent as Cancel } from '../../../../shared_assets/icons/cancel.svg';
import { ReactComponent as Edit } from '../../../../shared_assets/icons/edit.svg';
import { ReactComponent as Repeat } from '../../../../shared_assets/icons/repeat.svg';
import { ReactComponent as RouteIcon } from '../../../../shared_assets/icons/route.svg';
import { ReactComponent as Stub } from '../../../../shared_assets/icons/stub.svg';
import { ReactComponent as Time } from '../../../../shared_assets/icons/time.svg';
import { BookingState } from '../../../../types/booking-state.enum';
import { RequestFromTypes } from '../../../../types/request-from.enum';
import { RepetitionWithRoute } from '../../../../types/schedule-repetition';
import { ScheduleRepetitionStateType } from '../../../../types/schedule-repetition-state-type.enum';
import { ScheduleDateBookings } from '../../../../types/ScheduleDateBookings.interface';
import { getTheme } from '../../../../utils';
import { StartRouteModal } from '../RouteAboutToStart/StartRoute';
import CancelScheduleModal, { CancelType } from './CancelScheduleModal';
import ScheduleModal from './ScheduleModal';

const useStyles = makeStyles({
  routePresentation: ({ routeImage }: { routeImage: string }) => ({
    backgroundImage: `url('${routeImage}')`,
    backgroundSize: 'cover',
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '1rem',
    position: 'relative',
    marginBottom: '1rem',
  }),
  actionRow: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '1rem',
  },
  buttonIcon: {
    marginRight: '0.5rem',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1rem',
      '&:last-child': {
        marginRight: 0,
      },
      '& > *:first-child': {
        marginRight: '0.5rem',
      },
    },
  },
  iconSpacing: {
    marginRight: '0.5rem',
  },
  datepicker: {
    borderColor: getTheme().neutral4 + ' !important',
    borderWidth: 2,
    fontSize: 14,
    borderRadius: 5,
  },
  textIcon: {
    height: '1.2rem',
    width: '1.2rem',
  },
  chip: () => ({
    alignSelf: 'flex-start',
    border: `1px ${getTheme().primary} solid`,
    textTransform: 'uppercase',
    marginBottom: '0.5rem',
  }),
});

interface ScheduleManagementModalProps extends LocalizeContextProps {
  visible: boolean;
  onRequestClose: (requestRefresh?: boolean) => void;
  scheduleRepetition: RepetitionWithRoute;
}
function ScheduleManagementModal({
  scheduleRepetition,
  visible,
  onRequestClose,
  translate,
}: ScheduleManagementModalProps) {
  const [imageId, routeImage] = useMemo(() => {
    const photoId = scheduleRepetition.route.locations
      .map(location => location.photos.map(photo => photo.id))
      .flat()[0];

    return [
      photoId,

      (photoId && getRouteLocationImageUrl(photoId)) ||
        require('../../../../shared_assets/images/route-default.png'),
    ];
  }, [scheduleRepetition]);

  const [bookings, setBookings] = useState<ScheduleDateBookings | null>(null);

  const [bookingsAreLoading] = useRepetitionBookings(
    setBookings,
    scheduleRepetition.id
  );
  const classes = useStyles({ routeImage: routeImage });
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showStartModal, setShowStartModal] = useState<boolean>(false);
  const cancelRepetition = useCallback(
    async (type: CancelType, description: string) => {
      try {
        await cancelScheduleRepetition(scheduleRepetition.id, {
          description,
          cancelAll: type === 'future',
          requestFrom: RequestFromTypes.WEB,
        });

        setShowCancelModal(false);
        onRequestClose(true);
      } catch (err) {
        message.error(translate('errors.basic'));
      }
    },
    [scheduleRepetition, onRequestClose, translate]
  );
  const [editing, setEditing] = useState<boolean>(false);
  return (
    <>
      <ScheduleModal
        onRequestClose={() => {
          setEditing(false);
        }}
        onDoAction={() => {
          // setReferenceRepetition((prevRepetition) => ({
          //   ...prevRepetition,
          //   date: newRepetition.date,
          //   dontAllowBookings: newRepetition.reserveLimit,
          // }));
          onRequestClose(true);
          setEditing(false);
        }}
        visible={editing}
        routes={[scheduleRepetition.route]}
        selectedRouteId={scheduleRepetition.route.id}
        setSelectedRouteId={() => {}}
        selectedDate={moment(scheduleRepetition.date).startOf('day')}
        repetition={scheduleRepetition}
      />
      <CancelScheduleModal
        hasBookings={
          (bookings?.bookings.filter(
            b => b.bookingState !== BookingState.CANCELED
          ).length || 0) > 0
        }
        visible={showCancelModal}
        onClose={() => {
          setShowCancelModal(false);
        }}
        onConfirmDelete={cancelRepetition}
      />
      <Modal
        variant="close"
        visible={visible && !showCancelModal && !editing}
        onRequestClose={onRequestClose}
        headerTitle={<Translate id="schedules.scheduleTitle" />}
      >
        {bookingsAreLoading || !bookings ? (
          <Loader />
        ) : (
          <>
            <StartRouteModal
              onBackInAboutToStartModal={() => setShowStartModal(false)}
              scheduleBookings={bookings}
              repetitionId={scheduleRepetition.id}
              isVisible={showStartModal}
              routeTitle={
                scheduleRepetition.route.titles[0]
                  ? scheduleRepetition.route.titles[0].title
                  : ''
              }
              onClose={() => setShowStartModal(false)}
              routeDuration={scheduleRepetition.route.duration}
              routeImageId={imageId}
            />
            {scheduleRepetition.state ===
              ScheduleRepetitionStateType.TO_START && (
              <div className={classes.actionRow}>
                {bookings?.bookings.length <= 0 ? (
                  <Button
                    size="large"
                    type="link"
                    prefix={<Edit />}
                    onClick={() => setEditing(true)}
                  >
                    <Translate id="schedules.edit" />
                  </Button>
                ) : null}

                {moment(scheduleRepetition.date).diff(moment(), 'days') < 1 &&
                  moment().isBefore(
                    moment(scheduleRepetition.date).add(
                      scheduleRepetition.route.duration,
                      'minutes'
                    )
                  ) && (
                    <Button
                      size="large"
                      type="link"
                      prefix={<RouteIcon />}
                      onClick={() => setShowStartModal(true)}
                    >
                      <Translate id="schedules.startRoute" />
                    </Button>
                  )}
                <Button
                  size="large"
                  type="link"
                  prefix={<Cancel />}
                  onClick={() => setShowCancelModal(true)}
                >
                  <Translate id="schedules.cancel" />
                </Button>
              </div>
            )}
            <div className={classes.routePresentation}>
              {scheduleRepetition.language && (
                <Chip small className={classes.chip}>
                  <Translate id={`languages.${scheduleRepetition.language}`} />
                </Chip>
              )}
              <Text color="white" size={12} weight="bold">
                {scheduleRepetition.route.location}
              </Text>
              <Heading color="white" level={4}>
                {scheduleRepetition.route.titles[0]
                  ? scheduleRepetition.route.titles[0].title
                  : ''}
              </Heading>
            </div>
            <Text weight="semibold">
              <Translate id="schedules.starts" />
            </Text>
            <div className={classes.row}>
              <Text inline>
                <Time className={classes.textIcon} />
                {moment(scheduleRepetition.date).format('HH:mm')}
              </Text>
              <Text inline>
                <CalendarIcon className={classes.textIcon} />
                {moment(scheduleRepetition.date).format('LL')}
              </Text>
            </div>
            <Text weight="semibold">
              <Translate id="schedules.ends" />
            </Text>
            <div className={classes.row}>
              <Text inline>
                <Time className={classes.textIcon} />
                {moment(scheduleRepetition.date)
                  .add({ minutes: scheduleRepetition.route.duration })
                  .format('HH:mm')}
              </Text>
              <Text inline>
                <CalendarIcon className={classes.textIcon} />
                {moment(scheduleRepetition.date)
                  .add({ minutes: scheduleRepetition.route.duration })
                  .format('LL')}
              </Text>
            </div>
            <Text weight="semibold">
              <Translate id="schedules.repeatLabel" />
            </Text>
            <div className={classes.row}>
              <Text>
                <Repeat className={classes.textIcon} />
                <Translate
                  id={`scheduleRepetitionType.${scheduleRepetition.repetitionType}`}
                />
              </Text>
            </div>
            <Text weight="semibold">
              <Translate id="schedules.dontAllowBookings" />
            </Text>
            <div className={classes.row}>
              <Text>
                <Stub className={classes.textIcon} />
                {moment
                  .duration({ minutes: scheduleRepetition.reserveLimit })
                  .humanize()}
              </Text>
            </div>
            <Text weight="semibold">
              <Translate id="schedules.bookings" />
            </Text>
            <BookingSection
              canceled={false}
              maxVisitors={scheduleRepetition.route.groupSize}
              bookings={
                bookings?.bookings
                  .filter(b => b.bookingState !== BookingState.CANCELED)
                  .map(b => ({ ...b, groupSize: b.bookingGroupSize })) || []
              }
            />
            <Divider />
            <BookingSection
              canceled={true}
              bookings={
                bookings?.bookings
                  .filter(b => b.bookingState === BookingState.CANCELED)
                  .map(b => ({ ...b, groupSize: b.bookingGroupSize })) || []
              }
            />
          </>
        )}
      </Modal>
    </>
  );
}

export default withLocalize(ScheduleManagementModal);
