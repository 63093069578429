import { makeStyles } from '@material-ui/styles';
import Popover from 'antd/es/popover';
import classNames from 'classnames';
import React from 'react';
import { TranslateFunction } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseGrey } from '../../../shared_assets/icons/close grey.svg';
import { ReactComponent as SearchGrey } from '../../../shared_assets/icons/search grey.svg';
import { FilterRoute } from '../../../types/filter-route.dto';
import { castShadow } from '../../../utils';
import { Error } from '../Error';
import { Input } from '../Input';
import { Loader } from '../Loader';
import ResultList from './ResultList';
import { ResultType } from './useSearchResults';

interface RouteSearchDesktopProps {
  searching: boolean;
  setSearching: (v: boolean) => void;
  error: boolean;
  search: string;
  setSearch: (v: string) => void;
  history: ReturnType<typeof useHistory>;
  translate: TranslateFunction;
  loading: boolean;
  results: ResultType[];
  recent: ResultType[];
  showBlack: boolean;
  filterDto: FilterRoute;
}

const useStyles = makeStyles({
  input: {
    marginRight: '0.5rem',
  },
  shadow: {
    borderRadius: 5,

    '& .ant-popover-content': {
      borderRadius: 5,
    },
    '& .ant-popover-inner': {
      borderRadius: 5,
    },
    '& .ant-popover-inner-content': {
      borderRadius: 5,
      boxShadow: castShadow,
    },
  },
});

const RouteSearchDesktop = ({
  setSearching,
  error,
  history,
  search,
  setSearch,
  translate,
  loading,
  recent,
  results,
  filterDto,
}: RouteSearchDesktopProps) => {
  const classes = useStyles();

  return (
    <Popover
      onVisibleChange={setSearching}
      trigger={['focus', 'click']}
      placement="bottomLeft"
      overlayClassName={classNames(classes.shadow, 'search-popover')}
      content={
        loading ? (
          <Loader />
        ) : error ? (
          <Error />
        ) : (
          <ResultList
            filterDto={filterDto}
            recent={recent}
            history={history}
            results={results}
            search={search}
            setSearching={setSearching}
            translate={translate}
          />
        )
      }
    >
      <Input
        className={classes.input}
        value={search}
        onChange={ev => setSearch(ev.target.value)}
        prefix={<SearchGrey />}
        suffix={search && <CloseGrey />}
        placeholder={translate('search.searchPlaceholder').toString()}
      />
    </Popover>
  );
};

export default React.memo(RouteSearchDesktop);
