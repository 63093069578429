import { makeStyles } from '@material-ui/styles';
import Typography from 'antd/es/typography';
import { TitleProps } from 'antd/es/typography/Title';
import classNames from 'classnames';
import React from 'react';

import { getTheme, useIsMobile } from '../../utils';

type TitleLevels = 1 | 2 | 3 | 4 | 5 | 6 | undefined;

const useStyles = makeStyles({
  title: ({
    level,
    color,
    noMargin,
    inline,
  }: {
    level?: TitleLevels;
    color?: string;
    noMargin?: boolean;
    inline?: boolean;
  }) => {
    let fontSize = 14;
    let fontWeight = 'normal';

    switch (level) {
      case 1:
        fontSize = 32;
        fontWeight = 'bold';
        break;
      case 2:
        fontSize = 28;
        fontWeight = 'bold';
        break;
      case 3:
        fontSize = 22;
        fontWeight = 'bold';
        break;
      case 4:
        fontSize = 18;
        fontWeight = 'bold';
        break;
      case 5:
        fontSize = 16;
        fontWeight = 'normal';
        break;
      case 6:
        fontSize = 12;
        fontWeight = 'bold';
        break;
      default:
        break;
    }

    return {
      display: inline ? 'inline' : undefined,
      fontSize: `${fontSize}px !important`,
      fontWeight: (`${fontWeight} !important` as unknown) as number,
      verticalAlign: 'middle',
      color: `${color || getTheme().defaultTextPrimaryColor} !important`,
      margin: noMargin ? '0px !important' : undefined,
      'button > &, a > &, h1 > &, h2 > &, h3 > &, h4 > &, h5 > &, h6 > &': {
        // lineHeight: `${fontSize}px !important`,
      },
    };
  },
});

interface HeadingProps extends Omit<TitleProps, 'level'> {
  level?: TitleLevels;
  color?: string;
  noMargin?: boolean;
  inline?: boolean;
  disableAutoscale?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  level,
  className,
  children,
  color,
  noMargin,
  inline,
  disableAutoscale,
  ...rest
}) => {
  const isMobile = useIsMobile();

  const classes = useStyles({
    level: !isMobile && level && !disableAutoscale ? level - 1 : level,
    color,
    noMargin,
    inline,
  });

  const renderContent = () => (
    <Typography.Title
      className={classNames(classes.title, className)}
      {...rest}
    >
      {children}
    </Typography.Title>
  );

  return renderContent();

  // switch (level) {
  //   case 1:
  //     return (
  //         {renderContent()}
  //     );
  //   case 2:
  //     return (
  //         {renderContent()}
  //       </h2>
  //     );
  //   case 3:
  //     return (
  //       <h3 className={classNames(classes.title, className)}>
  //         {renderContent()}
  //       </h3>
  //     );
  //   case 4:
  //     return (
  //       <h4 className={classNames(classes.title, className)}>
  //         {renderContent()}
  //       </h4>
  //     );
  //   case 5:
  //     return (
  //       <h5 className={classNames(classes.title, className)}>
  //         {renderContent()}
  //       </h5>
  //     );
  //   default:
  //   case 6:
  //     return (
  //       <h6 className={classNames(classes.title, className)}>
  //         {renderContent()}
  //       </h6>
  //     );
  // }
};

export default React.memo(Heading);
