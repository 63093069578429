import { useCallback, useMemo, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import validator from 'validator';
import isTaxID from 'validator/lib/isTaxID';
import { store } from '../../../store/index';
import { FinancialType } from '../../../types/financial.type';
import { FormEntry, SettableFormEntry } from '../../../types/form.interface';

type OnBoardingFormProperties =
  | 'address'
  | 'zipFirstSegment'
  | 'zipSecondSegment'
  | 'gender'
  | 'nationality'
  | 'companyName'
  | 'nif'
  | 'iban'
  | 'location'
  | 'phoneNumber'
  | 'ivaExemption'
  | 'retention'
  | 'financialType'
  | 'company';

export type OnBoardingForm = Record<
  OnBoardingFormProperties,
  SettableFormEntry
>;

export const useOnboardingForm = (): OnBoardingForm => {
  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const [address, setAddress] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });
  const [zipFirstSegment, setZipFirstSegment] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const [zipSecondSegment, setZipSecondSegment] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const [gender, setGender] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const [nationality, setNationality] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const [companyName, setCompanyName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('onboarding.companyNameMandatory').toString(),
  });

  const [nif, setNif] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('onboarding.badNif').toString(),
  });

  const [iban, setIban] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const [location, setLocation] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });

  const [phoneNumber, setPhoneNumber] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: '',
  });
  const [ivaExemption, setIvaExemption] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('onboarding.mandatoryIvaExemption').toString(),
  });
  const [retention, setRetention] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('onboarding.mandatoryRetention').toString(),
  });
  const [financialType, setFinancialType] = useState<FormEntry>({
    value: '',
    isInvalid: false,
    reason: '',
  });
  const [company, setCompany] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('onboarding.mandatoryCompany').toString(),
  });
  const setValidatedNationality = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.nationalityMandatory').toString();
      }

      setNationality({ value, isInvalid, reason });
    },
    [setNationality, translate]
  );

  const setValidatedAddress = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.badAddress').toString();
      }

      setAddress({ value, isInvalid, reason });
    },
    [setAddress, translate]
  );

  const setValidatedZipFirstSegment = useCallback(
    (value: string) => {
      const isInvalid = value.length !== 4;
      let reason: string | undefined = undefined;

      if (isInvalid) {
        reason = translate('onboarding.badZip1NoLength').toString();
      }

      setZipFirstSegment({ value, isInvalid, reason });
    },
    [setZipFirstSegment, translate]
  );

  const setValidatedZipSecondFragment = useCallback(
    (value: string) => {
      const isInvalid = value.length !== 3;
      let reason: string | undefined = undefined;

      if (isInvalid) {
        reason = translate('onboarding.badZip2NoLength').toString();
      }

      setZipSecondSegment({ value, isInvalid, reason });
    },
    [setZipSecondSegment, translate]
  );

  const setValidatedGender = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.genderMandatory').toString();
      }

      setGender({ value, isInvalid, reason });
    },
    [setGender, translate]
  );

  const setValidatedCompanyName = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.companyNameMandatory').toString();
      }

      setCompanyName({ value, isInvalid, reason });
    },
    [translate]
  );

  const setValidatedIban = useCallback(
    (value: string) => {
      const isInvalid = !validator.isIBAN(value);
      let reason: string | undefined = undefined;

      if (isInvalid) {
        reason = translate('onboarding.badIban').toString();
      }

      setIban({ value, isInvalid, reason });
    },
    [setIban, translate]
  );

  const setValidatedNif = useCallback(
    (value: string) => {
      const firstDigit = value.substring(0, 1);
      let reason: string | undefined = undefined;

      if (
        firstDigit === '1' ||
        firstDigit === '2' ||
        firstDigit === '3' ||
        firstDigit === '5' ||
        firstDigit === '6' ||
        firstDigit === '7' ||
        firstDigit === '9'
      ) {
        const isInvalid = !isTaxID(value, 'pt-PT');

        if (isInvalid) {
          reason = translate('onboarding.badNif').toString();
        }

        setNif({ value, isInvalid, reason });
      } else {
        reason = translate('onboarding.badNif').toString();
        setNif({ value, isInvalid: true, reason });
      }
    },
    [setNif, translate]
  );

  const setValidatedLocation = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.mandatoryLocation').toString();
      }

      setLocation({ value, isInvalid, reason });
    },
    [setLocation, translate]
  );

  const setValidatedPhoneNumber = useCallback(
    (value: string) => {
      const isInvalid = !validator.isMobilePhone(value, 'pt-PT');
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.badPhoneNumber').toString();
      }

      setPhoneNumber({ value, isInvalid, reason });
    },
    [translate]
  );

  const setValidateFinancialType = useCallback((value: string) => {
    let reason: string | undefined = undefined;

    if (value === FinancialType.PERSONAL.toString()) {
      setCompany({ value: '', isInvalid: false, reason: '' });
    } else {
      setNif({ value: '', isInvalid: false, reason: '' });
      setIban({ value: '', isInvalid: false, reason: '' });
      setIvaExemption({ value: '', isInvalid: false, reason: '' });
      setRetention({ value: '', isInvalid: false, reason: '' });
    }

    setFinancialType({ value, isInvalid: false, reason });
  }, []);

  const setValidateCompany = useCallback(
    (value: string) => {
      let isInvalid = false;

      if (financialType.value === FinancialType.COMPANY.toString()) {
        isInvalid = validator.isEmpty(value);
      }
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.mandatoryCompany').toString();
      }

      setCompany({ value, isInvalid, reason });
    },
    [financialType.value, translate]
  );
  const setValidateIvaExemption = useCallback(
    (value: string) => {
      let isInvalid = false;

      if (financialType.value === FinancialType.PERSONAL.toString()) {
        isInvalid = validator.isEmpty(value);
      }
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.mandatoryIvaExemption').toString();
      }

      setIvaExemption({ value, isInvalid, reason });
    },
    [financialType.value, translate]
  );
  const setValidateRetention = useCallback(
    (value: string) => {
      let isInvalid = false;
      if (financialType.value === FinancialType.PERSONAL.toString()) {
        isInvalid = validator.isEmpty(value);
      }
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('onboarding.mandatoryRetention').toString();
      }

      setRetention({ value, isInvalid, reason });
    },
    [financialType.value, translate]
  );

  return {
    address: {
      ...address,
      set: setValidatedAddress,
    },
    zipFirstSegment: {
      ...zipFirstSegment,
      set: setValidatedZipFirstSegment,
    },
    zipSecondSegment: {
      ...zipSecondSegment,
      set: setValidatedZipSecondFragment,
    },
    gender: {
      ...gender,
      set: setValidatedGender,
    },
    nationality: {
      ...nationality,
      set: setValidatedNationality,
    },
    companyName: {
      ...companyName,
      set: setValidatedCompanyName,
    },
    nif: {
      ...nif,
      set: setValidatedNif,
    },
    iban: {
      ...iban,
      set: setValidatedIban,
    },
    location: {
      ...location,
      set: setValidatedLocation,
    },
    phoneNumber: {
      ...phoneNumber,
      set: setValidatedPhoneNumber,
    },
    retention: {
      ...retention,
      set: setValidateRetention,
    },
    ivaExemption: {
      ...ivaExemption,
      set: setValidateIvaExemption,
    },
    financialType: {
      ...financialType,
      set: setValidateFinancialType,
    },
    company: {
      ...company,
      set: setValidateCompany,
    },
  };
};
