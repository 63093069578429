import React from 'react';

export default function PortugueseTOS() {
  return (
    <>
      <h1 id="visitor">
        A - Termos e Condições Gerais de Utilização Visitantes
      </h1>
      <p>
        A Municípia – Empresa de Cartografia e Sistemas de Informação, S.A., com
        sede no Taguspark, Edifício Ciência II, 3ºB, 2740-120 Porto Salvo e
        número único de matrícula e identificação fiscal 504 475 606 (doravante,
        “Municípia”), implementou no sítio www.uniquepaths.eu (doravante
        “Sítio”), um mercado virtual (doravante, "MarketPlace") que permite a
        Visitantes maiores de idade (doravante, "Visitantes"), após registo no
        Sítio, contactar, por intermédio desta plataforma, guias, igualmente
        registados no Sítio (doravante, "PediGuias"), com o objetivo de que, no
        momento por estes escolhido, lhes seja prestado um serviço de Guia nos
        locais e rotas (doravante, “PediRotas”) desenvolvidas pelos PediGuias a
        um preço fixo (doravante, "Serviço").
      </p>
      <p>
        Para utilizar o Serviço, o Visitante terá de aceitar sujeitar-se às
        presentes Condições Gerais de Utilização Visitantes (doravante, "TCGU
        Visitantes") sem restrições nem reservas. Esta aceitação
        consubstancia-se por um clique de validação no momento da compra.
      </p>
      <p>
        Os contratos de prestação de serviço celebrados por intermédio do
        Serviço entre os Visitantes e os PediGuias são reguladas pelos Termos e
        Condições Gerais de Utilização do Serviço do MarketPlace (doravante,
        "TCGUS"), que devem igualmente ser aceites pelo Visitante no ato de cada
        reserva, e que completam os presentes TCGU Visitantes. Como fornecedora
        do MarketPlace, a Municípia não controla, oferece ou gere quaisquer
        PediRotas publicadas pelos PediGuias.
      </p>
      <p>
        A Municípia convida-o a tomar conhecimento das TCGUS clicando em{' '}
        <a href="https://uniquepaths.eu/terms-of-service#market">
          https://uniquepaths.eu/terms-of-service#market
        </a>
        .
      </p>
      <h2>Cláusula 1ª – Objeto</h2>
      <p>
        Os presentes TCGU Visitantes têm por objeto definir as condições nas
        quais a Municípia põe à disposição dos Visitantes, no âmbito do Serviço,
        ferramentas tecnológicas utilizadas para reservar os serviços de guia
        turístico associados às PediRotas junto dos PediGuias.
      </p>
      <h2>Cláusula 2ª – Descrição do Serviço</h2>
      <p>
        O Serviço é constituído por um conjunto de ferramentas que permite aos
        Visitantes inscreverem-se no Sítio, reservarem as PediRotas, efetuar o
        pagamento do preço das PediRotas, confirmar a realização das PediRotas.
      </p>
      <p>
        O Serviço compreende igualmente um sistema de avaliação que permite ao
        Visitante avaliar a qualidade das suas relações com os PediGuias.
      </p>
      <p>
        As operações realizadas através do Serviço no âmbito da reserva das
        PediRotas são celebradas diretamente entre o Visitante e o PediGuia. A
        Municípia não é, em caso algum, prestadora dos serviços associados às
        PediRotas propostas pelos PediGuias por intermédio do Serviço.{' '}
      </p>
      <p>
        No entanto, com a preocupação constante de melhorar a qualidade dos seus
        serviços e de forma a permitir uma melhor seleção dos seus parceiros, a
        Municípia convida-o a transmitir-lhe todos os comentários e sugestões
        que pretenda levar ao seu conhecimento no que respeita à qualidade das
        operações realizadas por intermédio do Serviço.
      </p>
      <p>
        Para este efeito, pode contactar-nos através do seguinte endereço de
        e-mail: info@uniquepaths.eu .
      </p>
      <p>
        Em caso de incumprimento dos presentes Termos e Condições Gerais de
        Utilização Visitantes, a Municípia reserva-se o direito de suspender
        temporária ou definitivamente o acesso ao Serviço pelo Visitante em
        causa.
      </p>
      <p>Cada Visitante compromete-se a utilizar o Serviço de boa-fé.</p>
      <h2>Cláusula 3ª – Acesso ao Serviço</h2>
      <p>
        O acesso ao Serviço pelos Visitantes é reservado para uma utilização
        estritamente pessoal. O Visitante declara atuar como consumidor (na
        definição dada pela Lei de Defesa do Consumidor – Lei n.º 24/96, de 31
        de julho) a título privado quando utiliza o Serviço.
      </p>
      <p>
        Para além da aceitação dos presentes TCGU Visitantes, o acesso ao
        Serviço fica sujeito à abertura de uma conta no Sítio. Para este efeito,
        o Visitante deve fornecer os dados que permitam a sua identificação. No
        momento da abertura desta conta, o Visitante compromete-se a fornecer
        somente informações exatas, qualquer alteração das mesmas deverá ser
        efetuada na conta. Para utilizar o Serviço, o Visitante deve utilizar o
        seu endereço de e-mail e a palavra-passe criados no momento da abertura
        da sua conta. O Visitante compromete-se a conservá-los secretamente e a
        não divulgar os mesmos a nenhum terceiro. Com efeito, o Visitante será o
        único responsável pelo acesso ao Serviço através do seu e-mail e
        palavra-passe, salvo quando faça prova de uma utilização fraudulenta que
        não lhe seja imputável.
      </p>
      <p>
        Em caso de extravio, de desvio ou de utilização fraudulenta do seu
        e-mail e/ou da sua palavra-passe, o Visitante compromete-se a avisar de
        imediato o serviço cliente da Municípia.
      </p>
      <h2>Cláusula 4ª – Preço do Serviço</h2>
      <p>
        A abertura de uma conta e a utilização do Serviço são gratuitas (com
        exceção dos custos eventuais da ligação que depende do seu operador de
        comunicações eletrónicas), sem obrigação de compra no Sítio. Somente a
        reserva de PediRotas aos PediGuias é onerosa, nas condições previstas
        nas TGCUS.
      </p>
      <h2>Cláusula 5ª – Dados Pessoais</h2>
      <p>
        As informações e os dados relativos ao Visitante são processados pela
        Municípia e, salvo menção em contrário, são indispensáveis para
        assegurar a gestão da sua conta e do seu acesso ao Serviço.
      </p>
      <p>
        Os dados pessoais facultados serão unicamente utilizados para as
        seguintes finalidades:
      </p>
      <ol type="a">
        <li>
          Execução e gestão de contrato de compra e venda ou de prestação de
          serviços;
        </li>
        <li>Processamento de reservas;</li>
        <li>Comunicação com clientes e esclarecimento de dúvidas;</li>
        <li>Processamento de pedidos de informação;</li>
        <li>
          Processamento de reclamações, Processos de Reguladores, Processos
          pré-contencioso ou exercício de direitos em processos judiciais;
        </li>
        <li>Atividades de análise estatística;</li>
        <li>
          Verificar, manter e desenvolver sistemas e análises estatísticas;
        </li>
        <li>
          Comunicações de marketing direto (caso tenha consentido no tratamento
          dos seus dados pessoais para esta finalidade);
        </li>
        <li>Prevenção e combate à fraude;</li>
        <li>Solicitação de comentários a PediRotas ou serviços adquiridos;</li>
        <li>Realização de inquéritos de satisfação.</li>
      </ol>
      <p>
        Em função destas finalidades da recolha e tratamento de dados, a
        Municípia poderá com o consentimento dos respetivos titulares reunir
        dados pessoais destes relativos à identificação (Nome, nacionalidade e
        e-mail), meios de pagamento (cartão de crédito, American Express, Paypal
        ou outros), localização geográfica e opiniões expressadas em sede de
        avaliação das PediRotas.
      </p>
      <p>
        As informações necessárias à prestação do serviço (PediRota) serão
        transmitidas ao PediGuia para esta única finalidade.
      </p>
      <p>
        Nos termos da Legislação em vigor sobre a Proteção de Dados Pessoais, o
        Visitante pode exercer a todo o tempo os seus direitos de acesso,
        retificação, apagamento, limitação, oposição e portabilidade, através de
        solicitação por qualquer dos seguintes meios:{' '}
      </p>
      <ul>
        <li>
          Correio: Av. Prof. Dr. Cavaco Silva, Edifício Ciência II, número 11,
          3.º B, Taguspark, 2740-120 Porto Salvo – Portugal{' '}
        </li>
        <li>E-mail: info@uniquepaths.eu </li>
        <li>
          Telefone: +351214228200 horário de atendimento das 9:30 às 12:30 e das
          14:00 às 18:00 todos os dias úteis.
        </li>
      </ul>
      <p>
        Os dados pessoais do Visitante serão conservados somente durante o prazo
        necessário à concretização da finalidade que motivou a sua recolha.
      </p>
      <p>
        O Sítio foi igualmente concebido para estar particularmente atento às
        necessidades dos nossos clientes. É nomeadamente por este motivo que
        utilizamos cookies. O cookie tem por objetivo assinalar a sua passagem
        pelo Sítio. Os cookies são utilizados pela Municípia somente para
        melhorar o serviço personalizado que lhe é destinado. Para mais
        informações sobre os cookies visite http://www.allaboutcookies.org onde
        poderá encontrar informações sobre como gerir as suas configurações para
        os vários fornecedores de navegadores. Pode ainda consultar o capítulo E
        do presente documento.
      </p>
      <p>
        Não obstante a Municípia proceder à recolha e ao tratamento de dados de
        forma segura e que impede a sua perda ou manipulação, utilizando as
        técnicas mais aperfeiçoadas para o efeito, informamos que a recolha em
        rede aberta permite a circulação dos dados pessoais sem condições de
        segurança, correndo o risco de ser vistos e utilizados por terceiros não
        autorizados.
      </p>
      <p>
        Nos termos legais, o titular dos dados tem o direito de apresentar uma
        reclamação em matéria de proteção de dados pessoais à autoridade de
        controlo competente, a Comissão Nacional de Proteção de Dados (CNPD).
      </p>
      <h2>Cláusula 6ª – Propriedade Intelectual</h2>
      <p>
        Todos os textos, comentários, trabalhos, ilustrações, obras e imagens
        reproduzidas ou representadas no Sítio estão protegidas nos termos do
        direito de autor bem como da propriedade intelectual, por toda a duração
        da proteção de tais direitos e para o mundo inteiro. Nesta conformidade
        e nos termos do disposto no Código dos Direitos de Autor e Direitos
        Conexos, é somente autorizada a utilização privada, sem prejuízo de
        disposições diferentes e porventura mais restritivas do referido Código.
        Qualquer reprodução, representação, alteração ou adaptação total ou
        parcial do Sítio e/ou de todo ou parte dos elementos que se encontrem no
        Sítio ou que nele estejam incorporados é absolutamente proibida.
      </p>
      <p>
        A Municípia não será responsável por qualquer violação de Direitos de
        Autor e Direitos Conexos relativos às PediRotas comercializadas no
        MarketPlace, nos termos da legislação aplicável.
      </p>
      <h2>Cláusula 7ª – Responsabilidade</h2>
      <p>
        A responsabilidade da Municípia para com o Visitante só pode ser
        assacada por factos que lhe sejam diretamente imputáveis e que causem a
        este último um prejuízo diretamente relacionado com esses factos. A
        responsabilidade da Municípia não pode ser assacada por prejuízos
        indiretos. De igual modo, a responsabilidade da Municípia não pode ser
        assacada pela utilização defeituosa do Serviço pelo Visitante ou ainda
        por qualquer erro da sua parte, nem tão pouco por factos imputáveis a
        uma entidade terceira ao Serviço.
      </p>
      <p>
        Acresce que, nos termos do disposto nos artigos 11.º e seguintes do
        Decreto-Lei n.º 7/2004, de 07.01, alterado pelo Decreto-Lei n.º 62/2009,
        de 10.03 e pela Lei n.º 46/2012, de 29.08, que transpôs para a ordem
        jurídica portuguesa a Diretiva n.º 2000/31/CE do Parlamento Europeu e do
        Conselho, de 8 de Junho de 2000, relativa a certos aspetos legais dos
        serviços da sociedade de informação, em especial do comércio eletrónico,
        no mercado interno, a Municípia é isenta de responsabilidade
        relativamente aos conteúdos disponibilizados no Sítio pelos Visitantes
        ou pelos PediGuias, nomeadamente no que concerne à descrição das
        PediRotas ou as apreciações que os Visitantes façam dos PediGuias, salvo
        quando não retire ou impossibilite logo o acesso a essa informação assim
        que tiver conhecimento da respetiva ilicitude, nos termos previstos pela
        legislação supra referida.
      </p>
      <p>
        Neste sentido, o Visitante será totalmente responsável pelos contratos
        celebrados por intermédio do Serviço, exonerando a Municípia e o
        PediGuia de qualquer responsabilidade nos casos em que contrate PediRota
        cujas características ou requisitos não preencha, por exemplo se tiver
        idade inferior/superior à indicada pelo PediGuia na descrição da
        PediRota. A Municípia não se responsabiliza, ainda, por menores deixados
        sozinhos e/ou desacompanhados durante a realização de PediRotas.{' '}
      </p>
      <p>
        Finalmente, a Municípia declina qualquer responsabilidade no que
        respeita às reservas concluídas pelos Visitantes por intermédio do seu
        Serviço com os PediGuias, às quais é totalmente alheia. Qualquer
        reclamação relativa aos comentários constantes do descritivo das
        PediRotas ou à prestação do serviço associado às PediRotas será
        reencaminhada para o PediGuia indicado, o qual assumirá integralmente a
        responsabilidade a esse título.
      </p>
      <p>
        O Visitante é o único responsável pela utilização que faça do Serviço,
        nomeadamente pelas avaliações que faça no Sítio dos PediGuias e
        compromete-se a garantir à primeira solicitação a indemnização e a
        compensação da Municípia por qualquer dano, prejuízo, lucros cessantes
        que a Municípia possa vir a sofrer caso lhe seja imputada
        responsabilidade por terceiro em virtude de qualquer operação inerente a
        esta utilização do Serviço pelo Visitante.
      </p>
      <h2>Cláusula 8ª – Nulidade parcial</h2>
      <p>
        Caso uma ou mais disposições dos presentes TCGU Visitantes sejam
        consideradas inválidas ou declaradas como tal por aplicação da lei ou de
        uma decisão definitiva emanada de um tribunal competente, as restantes
        disposições manter-se-ão válidas e eficazes.
      </p>
      <h2>Cláusula 9ª – Foro e lei aplicável</h2>
      <p>
        Os presentes TCGU Visitantes, bem como os contratos de prestação de
        serviços celebrados ao abrigo das mesmas estão sujeitas à legislação
        portuguesa. Qualquer litígio relativo à sua interpretação e/ou à sua
        execução está sujeito à competência dos tribunais portugueses.
      </p>
      <h1 id="guide">B - Termos e Condições Gerais de Utilização PediGuias</h1>
      <p>
        A Municípia – Empresa de Cartografia e Sistemas de Informação, S.A., com
        sede no Taguspark, Edifício Ciência II, 3ºB, 2740-120 Porto Salvo e
        número único de matrícula e identificação fiscal 504 475 606 (doravante
        “Municípia”), disponibiliza no sítio www.uniquepaths.eu (doravante
        “Sítio”), um mercado virtual (doravante "MarketPlace") que permite aos
        Guias (doravante os "PediGuias"), após registo, entrar por seu
        intermédio em contacto com Visitantes particulares (doravante os
        "Visitantes"), igualmente registados no Sítio, com o intuito de lhes
        prestar um serviço de Guia nos locais e rotas por aqueles desenvolvidas
        (doravante as "PediRotas") a preços fixos (doravante o "Serviço"). Os
        PediGuias tanto podem ser pessoas coletivas (“PediGuias PC”), como
        pessoas singulares (“PediGuias PS”).
      </p>
      <p>
        Para utilizar o Serviço, o PediGuia aceita os presentes Termos e
        Condições Gerais de Utilização PediGuia (doravante, "TCGU PediGuia") sem
        restrições nem reservas. Esta aceitação consubstancia-se por um clique
        de validação no momento de inscrição como PediGuia.
      </p>
      <p>
        Os contratos de prestação de serviço celebrados por intermédio do
        Serviço entre os Visitantes e os PediGuias são reguladas pelos Termos e
        Condições Gerais de Utilização do Serviço do MarketPlace (doravante,
        "TCGUS"), que devem igualmente ser aceites pelo PediGuia no momento do
        registo. As TCGUS completam as presentes TCGU PediGuia. Como fornecedora
        do MarketPlace, a Municípia não controla, oferece ou gere quaisquer
        PediRotas publicadas pelos PediGuias.
      </p>
      <p>
        A Municípia convida-o a tomar conhecimento das TCGUS clicando em
        <a href="https://uniquepaths.eu/terms-of-service#market">
          https://uniquepaths.eu/terms-of-service#market
        </a>
        .
      </p>
      <p>
        A Municípia reserva-se a possibilidade de alterar os presentes TCGU
        PediGuia. Qualquer alteração entrará em vigor no prazo de 15 (quinze)
        dias a contar da data da respetiva notificação ao PediGuia. A Municípia
        fixará um prazo de pré-aviso mais longo quando tal for necessário para
        permitir que os PediGuias façam adaptações técnicas ou comerciais para
        respeitar as alterações.
      </p>
      <p>
        O PediGuia tem o direito de resolver o contrato com a Municípia antes do
        termo do prazo de pré-aviso. Essa resolução do contrato produz efeitos
        no prazo de 15 (quinze) dias a contar da receção da notificação da
        alteração dos presentes TCGU PediGuia.
      </p>
      <p>
        O PediGuia pode, por via de uma declaração escrita ou de um
        consentimento expresso, renunciar ao prazo de pré-aviso supramencionado
        em qualquer momento após a receção da notificação da alteração dos
        presentes TCGU PediGuia.
      </p>
      <p>
        Durante o prazo de pré-aviso, a apresentação de novas PediRotas no
        MarketPlace será considerada uma medida afirmativa de renúncia ao prazo
        de pré-aviso, exceto nos casos em que o referido prazo seja superior a
        15 (quinze) dias em virtude das alterações aos presentes TCGU PediGuia
        exigirem que o PediGuia proceda a adaptações técnicas e comerciais
        significativas dos seus serviços. Nesses casos, a renúncia ao prazo de
        pré-aviso não é considerada automática se o PediGuia apresentar novas
        PediRotas.
      </p>
      <p>Não haverá lugar a pré-aviso nos seguintes casos:</p>
      <ol type="a">
        <li>
          As alterações sejam determinadas por uma obrigação legal ou
          regulamentar de uma forma que impeça a Municípia de respeitar o prazo
          de pré-aviso suprarreferido;
        </li>
        <li>
          As alterações sejam indispensáveis para fazer face a um perigo
          imprevisto e iminente relacionado com a defesa dos serviços de
          intermediação em linha, dos seus consumidores ou de outros
          utilizadores profissionais contra a fraude, os programas informáticos
          maliciosos (malware), as comunicações comerciais não solicitadas
          (spam), as violações de dados ou outros riscos em matéria de
          cibersegurança.
        </li>
      </ol>
      <p>
        As alterações não serão aplicáveis às operações em curso no momento da
        sua entrada em vigor.
      </p>
      <h2>Cláusula 1ª – Objeto</h2>
      <p>
        Os presentes TCGU PediGuia têm como objeto definir as condições em que a
        Municípia põe à disposição dos PediGuias, no âmbito do Serviço,
        ferramentas tecnológicas que permitem a estes pôr as PediRotas à
        disposição dos Visitantes.
      </p>
      <h2>Cláusula 2ª – Descrição do Serviço</h2>
      <p>
        O Serviço é constituído por um conjunto de ferramentas que permite aos
        PediGuias expor e criar as suas PediRotas, indicando o seu ponto de
        início e fim, os pontos de interesse a visitar, atividades incluídas,
        referenciar e descrever essas PediRotas, receber as reservas colocadas
        pelos Visitantes, cobrar o preço das PediRotas reservadas, gerir o
        serviço pós-reserva das PediRotas e eventuais necessidades de contacto.
      </p>
      <p>
        As operações realizadas por intermédio do Serviço no âmbito da reserva
        das PediRotas são celebradas diretamente entre o Visitante e o PediGuia.
        A Municípia não é, em caso algum, prestadora dos serviços associados às
        PediRotas propostas pelos PediGuias por intermédio do Serviço.
      </p>
      <p>
        No entanto, com a preocupação constante de melhorar a qualidade dos seus
        serviços e de forma a permitir uma melhor seleção dos seus parceiros, a
        Municípia convida-o a transmitir-lhe todos os comentários e sugestões
        que pretenda levar ao seu conhecimento no que respeita à qualidade das
        operações realizadas por intermédio do Serviço.
      </p>
      <h2>Cláusula 3ª – Acesso ao Serviço</h2>
      <p>
        Para além da aceitação dos presentes TCGU PediGuia, o acesso ao Serviço
        fica sujeito ao registo em www.uniquepaths.eu. Para este efeito, o
        PediGuia deve fornecer os dados que permitam a sua identificação. No
        momento da abertura desta conta, o PediGuia compromete-se a fornecer
        somente informações exatas e a informar de imediato a Municípia de
        qualquer alteração das mesmas. Qualquer alteração deverá ser efetuada
        diretamente pelo PediGuia na respetiva conta.
      </p>
      <p>
        Para utilizar o Serviço, o PediGuia deve utilizar o seu endereço de
        e-mail e a palavra-passe criados no momento da abertura da sua conta. O
        PediGuia compromete-se a conservá-los secretamente e a não divulgar os
        mesmos a nenhum terceiro. Com efeito, o PediGuia será o único
        responsável pelo acesso ao Serviço graças ao seu endereço de e-mail e
        palavra-passe, salvo quando faça prova de uma utilização fraudulenta que
        não lhe seja imputável.
      </p>
      <p>
        Em caso de extravio, de desvio ou de utilização fraudulenta do seu
        endereço de e-mail e/ou da sua palavra-passe, o PediGuia compromete-se a
        avisar de imediato a Municípia através do email info@uniquepaths.eu.
      </p>
      <h2>
        Cláusula 4ª – Obrigações relativas às operações realizadas por
        intermédio do serviço
      </h2>
      <h3>A - Obrigações Gerais</h3>
      <p>
        O PediGuia compromete-se a cumprir a legislação aplicável em matéria de
        exercício de uma atividade comercial (nomeadamente registo, obrigações
        contabilísticas, contributivas e tributárias) e deverá identificar-se
        corretamente junto dos Visitantes.
      </p>
      <p>
        O PediGuia compromete-se expressamente a identificar-se como agindo na
        qualidade de prestador de serviços, uma vez que presta serviços
        (PediRotas) por intermédio do Serviço de forma regular e com finalidades
        lucrativas.{' '}
      </p>
      <p>
        O PediGuia é responsável por compreender e cumprir toda a legislação,
        regras e demais regulamentos que se apliquem à prestação das PediRotas
        que coloca à disposição no Sítio, incluindo a legislação relativa à
        obrigação de prestação de informações em língua portuguesa (Vide
        excertos da legislação em Anexo), obtendo as licenças, autorizações ou
        registos necessários antes de disponibilizar a sua PediRota e
        assegurando que a mesma não viola qualquer acordo que possa ter com
        terceiros.
      </p>
      <p>
        O PediGuia obriga-se expressamente a não promover direta ou
        indiretamente, por qualquer forma, (nomeadamente pela inserção de links
        de hipertexto, de qualquer mensagem no descritivo das PediRotas, ou
        ainda pela utilização do endereço URL do seu sítio internet no seu nome
        de utilizador ou na página PediGuia), os seus produtos ou serviços, bem
        como os produtos ou serviços de qualquer terceiro.
      </p>
      <p>
        O PediGuia compromete-se a envidar todos os esforços para cumprir da
        melhor forma as suas obrigações prestando um serviço de qualidade aos
        Visitantes.
      </p>
      <p>
        Para tanto, o PediGuia compromete-se nomeadamente a responder às
        mensagens de e-mail do Serviço de Apoio ao Cliente e da Municípia. no
        prazo máximo de 1 (um) dia útil a contar da respetiva receção.
      </p>
      <p>
        O PediGuia é responsável pelos seus próprios atos e omissões e é também
        responsável pelos atos e omissões de qualquer pessoa que permite que
        participe na prestação dos serviços.
      </p>
      <h3>
        B - PediGuias pessoas singulares (“PediGuias PS”) e PediGuias pessoas
        coletivas (“PediGuias PC”)
      </h3>
      <p>
        Para que um PediGuia PC se registe no MarketPlace, deve indicar, desde o
        momento da sua inscrição, pelo menos, um PediGuia PS que lhe estará
        associado (e que realizará o Serviço em nome do PediGuia PC).
      </p>
      <p>
        A associação entre um PediGuia PC e um PediGuia PS constará do perfil
        privado dos PediGuias (tanto dos PediGuias PC como dos PediGuias PS).
      </p>
      <p>
        A associação entre um PediGuia PC e um PediGuia PS ocorrerá mediante
        pedido de qualquer um deles, a realizar junto da plataforma MarketPlace,
        e aceitação por parte do outro.
      </p>
      <p>
        Tanto o PediGuia PC como o PediGuia PS podem terminar a associação
        existente entre ambos, a todo o tempo, sem necessidade de invocação de
        motivo e sem necessidade de aceitação pelo outro, bastando, para o
        efeito, realizar tal solicitação, através do envio de e-mail, à
        plataforma MarketPlace.
      </p>
      <p>
        No caso de ocorrer o término de uma associação entre um PediGuia PC e um
        PediGuia PS, existindo um Serviço já contratado a ser realizado pelo
        PediGuia PS, mas ainda não prestado, a reserva realizada pelo Visitante
        é obrigatoriamente cumprida pelo PediGuia PS.
      </p>
      <h3>C - Obrigações relativas à oferta de PediRotas no Sítio</h3>
      <p>
        O PediGuia é o único responsável pela colocação à disposição das
        PediRotas que propõe no Serviço.
      </p>
      <p>
        Relativamente ao descritivo associado às ofertas de PediRotas que
        proponha no Sítio, o PediGuia compromete-se a atuar de boa-fé. O
        PediGuia é o único responsável pela exatidão das indicações constantes
        do referido descritivo e compromete-se a que estas não criem um risco de
        induzir em erro os Visitantes potenciais, tanto no que concerne às
        características do serviço. O PediGuia comunica aos Visitantes todas as
        informações que lhes permitam tomar conhecimento das características
        essenciais da PediRota.
      </p>
      <p>
        O PediGuia é responsável por estabelecer regras e requisitos para a sua
        PediRota. Quando publica uma PediRota, o PediGuia tem de, quando
        aplicável, instruir e informar totalmente os Visitantes acerca de:
      </p>
      <dl>
        <dd>
          Quaisquer riscos inerentes ou eventuais relacionados com a PediRota;
        </dd>
        <dd>
          Quaisquer requisitos para a participação, como idade mínima,
          habilidades relacionadas, condição física; e{' '}
        </dd>
        <dd>
          Qualquer outra informação que possa ser necessária para participar com
          segurança na PediRota.
        </dd>
      </dl>
      <p>
        A Municípia reserva-se o direito de decidir, a seu exclusivo critério,
        se uma PediRota será retirada do MarketPlace.
      </p>
      <p>
        Os PediGuias devem honrar as suas reservas, salvo os casos em que as
        mesmas tenham de ser canceladas por motivos de força maior, preocupação
        com segurança ou condições climatéricas perigosas, devendo estes motivos
        ser devidamente justificados aquando do cancelamento da reserva pelo
        PediGuia.
      </p>
      <p>
        Como regra geral, o PediGuia só poderá cancelar reservas pelos motivos
        supramencionados 2 (duas) vezes em cada ano civil, caso em que a sua
        conta de utilizador será automaticamente suspensa. O cancelamento, e
        apresentação da respetiva justificação, é feito mediante o envio de um
        e-mail para info@uniquepaths.eu.
      </p>
      <p>
        Face aos motivos invocados pelo PediGuia, na sequência desta suspensão,
        a Municípia reserva-se o direito de decidir, a seu exclusivo critério,
        se a conta de utilizador pode ser reativada ou permanecer suspensa de
        forma temporária ou definitiva, caso em que as presentes condições serão
        suspensas ou resolvidas sem aviso prévio e sem indemnização.
      </p>
      <h3>D - Obrigações relativas aos preços das PediRotas</h3>
      <p>
        O preço de prestação das PediRotas é definido por um algoritmo, por
        exemplo em função da distância ou da avaliação do PediGuia.{' '}
      </p>
      <p>
        Este preço deve ser indicado no Sítio com todos os impostos, taxas e
        despesas incluídos (nomeadamente IVA, entre outros tributos ou
        encargos), não podendo o PediGuia cobrar quaisquer taxas ou encargos
        adicionais fora do MarketPlace.
      </p>
      <h3>E - Obrigações relativas à prestação de serviços</h3>
      <p>
        Os contratos de prestação de serviço associados às PediRotas propostas
        pelo PediGuia no Sítio são celebrados entre o PediGuia e o Visitante sob
        condição resolutiva da PediRota estar disponível. O PediGuia
        compromete-se a propor no Sítio unicamente PediRotas disponíveis e a
        eliminar de imediato qualquer proposta relativa a PediRotas que já não
        estejam disponíveis.
      </p>
      <p>
        O PediGuia é informado por e-mail, na sua conta e/ou por SMS, pela
        Municípia, quando uma PediRota que tenha colocado em linha seja
        reservada por um Visitante.
      </p>
      <p>
        Com a frequência adequada à quantidade de PediRotas disponibilizadas no
        MarketPlace, o PediGuia deve rever as PediRotas que publicou,
        mantendo-as devidamente atualizadas (disponibilidade, horários possíveis
        entre outros).
      </p>
      <p>
        De igual modo, o PediGuia compromete-se a consultar regularmente e com a
        frequência adequada à quantidade de PediRotas disponibilizadas no
        MarketPlace as suas mensagens SMS ou de correio eletrónico recebidas no
        endereço e telefone fornecidos à Municípia no momento da abertura da sua
        conta e, posteriormente, em caso de alteração desta informação, para
        tomar conhecimento da lista das PediRotas reservadas pelos Visitantes.
      </p>
      <p>
        Em caso de previsão de ausência ou de indisponibilidade para a
        realização das PediRotas, o PediGuia deve remover os agendamentos
        definidos para as suas PediRotas. Nos agendamentos em que já existam
        reservas, esta situação não é aplicável, devendo o serviço ser prestado
        nos termos definidos.{' '}
      </p>
      <ol type="i">
        <li>
          O PediGuia deve indicar no descritivo associado a cada PediRota que
          propõe no Sítio as modalidades de prestação do serviço (dias,
          horários, pontos de encontro, atividades a realizar, ponto de fim e
          dificuldade).{' '}
        </li>
        <li>
          A Municípia transmite ao PediGuia, o mais tardar no prazo de 30
          minutos após a reserva pelo Visitante, as informações relativas ao
          Visitante que lhe permitam proceder à prestação do serviço reservado.
        </li>
        <li>
          O PediGuia compromete-se a prestar qualquer serviço reservado no prazo
          por ele definido.
        </li>
      </ol>
      <h3>F - Obrigações relativas às reclamações relativas às PediRotas</h3>
      <p>
        De maneira geral, o PediGuia (tanto o PediGuia PS, como o PediGuia PC)
        compromete-se a envidar, de boa-fé, todos os esforços para cumprir as
        suas obrigações, prestando um serviço de qualidade aos Visitantes.
      </p>
      <p>
        Nesta conformidade, qualquer Visitante tem a possibilidade de apresentar
        na sua conta, no prazo de 7 (sete) dias após a prestação do serviço, uma
        reclamação relativa às PediRotas reservadas, por aplicação dos seguintes
        critérios:
      </p>
      <ul>
        <li>
          Serviço não prestado: A PediRota não foi realizada pelo PediGuia;
        </li>
        <li>
          Serviço não conforme: A PediRota prestada não corresponde ao serviço
          reservado.
        </li>
      </ul>
      <p>
        Em caso de reclamação de um Visitante relativamente a uma PediRota,
        inclusive quando o PediGuia é informado da referida reclamação por
        intermédio de uma mensagem de e-mail da Municípia, o PediGuia assume a
        responsabilidade da resolução do diferendo que o opõe ao Visitante. Os
        litígios são diretamente resolvidos entre o Visitante e o PediGuia
        (tanto o PediGuia PS como, em caso de associação, o PediGuia PC). O
        PediGuia deverá envidar todos os esforços para resolver o litígio que o
        opõe ao Visitante de forma amigável.
      </p>
      <p>
        No entanto, no caso em que o PediGuia não tenha resolvido o litígio que
        o opõe ao Visitante no prazo de 8 (oito) dias úteis a contar da receção
        da reclamação, a Municípia reserva-se o direito, após notificação do
        PediGuia para o efeito, de proceder ao reembolso da reserva ao
        Visitante. Igual montante será subsequentemente deduzido das quantias a
        pagar pela Municípia ao PediGuia relativas às PediRotas vendidas pelo
        PediGuia através do Serviço.
      </p>
      <p>
        Caso assista razão ao Visitante, o PediGuia procederá ao reembolso, sem
        custos adicionais para o Visitante.
      </p>
      <p>
        Em caso de regular exercício do direito de livre resolução pelo
        Visitante, a Municípia, na sua qualidade de terceiro idóneo, procederá
        ao reembolso ao Visitante do montante do serviço reservado pelo
        Visitante creditando para o efeito o seu cartão bancário. Este montante
        será subsequentemente deduzido das quantias devidas pela Municípia ao
        PediGuia relativas às reservas das PediRotas. No entanto, caso a conta
        do PediGuia seja devedora, o reembolso da reserva deverá ser efetuado
        diretamente pelo PediGuia ao Visitante.
      </p>
      <h3>G - Exclusão de responsabilidade</h3>
      <p>
        O PediGuia, quer seja PediGuia PS, quer seja PediGuia PC, exonera a
        Municípia de qualquer responsabilidade, reclamação, denúncia, etc. que
        possa ser dirigida contra a Municípia decorrente do anúncio e/ou
        colocação à disposição das PediRotas pelo PediGuia. Em consequência, a
        Municípia não será responsável, nem responderá, qualquer que seja o
        motivo, pelos PediRotas, preços, atuação, etc. do PediGuia. Caso seja
        aplicada à Municípia uma sanção por estes motivos, o PediGuia obriga-se
        a assumir a mesma e, bem assim, a reembolsar à Municípia todas as
        quantias que tenham sido despendidas por esta neste âmbito.
      </p>
      <h2 id="guide-financial">Cláusula 5ª – Disposições financeiras</h2>
      <p>
        Caso o Serviço seja contratado pelo Visitante a um PediGuia PS não
        associado a um PediGuia PC, a plataforma MarketPlace faturará
        automaticamente em nome do PediGuia PS, ou da entidade por ele indicada,
        a PediRota, disponibilizando essa fatura ao Visitante.
      </p>
      <p>
        Caso o Serviço seja contratado pelo Visitante a um PediGuia PS associado
        a um PediGuia PC, bem como se o Serviço for contratado diretamente a um
        PediGuia PC, a plataforma MarketPlace faturará a PediRota em nome do
        PediGuia PC, disponibilizando essa fatura ao Visitante.
      </p>
      <p>
        Ao PediGuia caberá identificar-se na aplicação disponibilizada pela
        uniquePATHS, para efeitos de faturação exclusiva a partir desta com
        Nome, Número de Identificação Fiscal, Morada fiscal e IBAN, seja ele
        pessoa singular ou coletiva.
      </p>
      <p>
        Por cada reserva de PediRotas recebida por um Visitante, a Municípia
        cobrará ao PediGuia uma comissão equivalente a 30% do montante
        correspondente e IVA acrescido.
      </p>
      <p>
        A Municípia pagará ao PediGuia o montante total do preço da reserva,
        deduzindo a comissão, após confirmação pelo Visitante da prestação do
        serviço ou decorridos os prazos para apresentação ou resolução de
        reclamações referidos em e da cláusula anterior.
      </p>
      <p>
        O pagamento será efetuado por transferência bancária para a conta
        bancária cujas coordenadas tiverem sido indicadas pelo PediGuia, após a
        referida confirmação, sendo os serviços efetuados no mês de referência
        pagos até ao 10 dia do mês seguinte à realização do serviço, salvo os
        casos em que estejam pendentes reclamações.
      </p>
      <p>
        No caso de associação entre um PediGuia PS e um PediGuia PC, o pagamento
        será realizado pela Municípia a favor do PediGuia PC, ainda que o
        PediGuia PS tenha indicado um IBAN próprio no momento da sua
        identificação na aplicação.
      </p>
      <p>
        Na falta de confirmação da prestação do serviço pelo Visitante e na
        ausência de reclamação do Visitante que tenha sido levada ao
        conhecimento do PediGuia, o serviço presume-se prestado e a
        transferência ocorre no prazo definido no parágrafo anterior. Em caso de
        reclamação pelo Visitante, uma vez decorrido o prazo de 21 (vinte e um)
        dias suprarreferido e efetuada a transferência para o PediGuia, a
        MUNICÍPIA, após avaliação da reclamação do Visitante e caso a mesma o
        justifique, poderá reembolsar o Visitante no valor da PediRota
        reservada. Nesse caso, o valor do montante reembolsado pela MUNICÍPIA ao
        Visitante será debitado ao PediGuia, que deverá proceder ao pagamento do
        mesmo, por compensação na conta corrente existente entre o PediGuia e a
        Municípia ou através de qualquer outro meio acordado entre a Municípia e
        o PediGuia.
      </p>
      <h2>Cláusula 6ª – Avaliação dos PediGuias</h2>
      <p>
        A Municípia põe à disposição dos Visitantes meios que lhes permitem
        avaliar o desempenho dos PediGuias no termo da confirmação da prestação
        da PediRota reservada, permitindo deste modo aos Visitantes selecionar
        as PediRotas junto dos PediGuias que cumprem melhor as condições de
        utilização do Serviço.
      </p>
      <p>A avaliação é realizada por aplicação dos seguintes critérios:</p>
      <ul>
        <li>
          Avaliação global por parte dos Visitantes, por atribuição de estrelas
          de 1 a 5
        </li>
      </ul>
      <p>
        A Municípia reserva-se o direito de suspender ou cessar o acesso ao
        Serviço por parte do PediGuia nos termos previstos no artigo 7 dos
        presentes TCGU PediGuia.
      </p>
      <h3>A - Procedimento interno de tratamento das reclamações</h3>
      <p>
        A Municípia implementou um procedimento interno de tratamento de
        reclamações que coloca à disposição do PediGuia através do endereço de
        correio eletrónico: info@uniquepaths.eu. O PediGuia poderá apresentar as
        suas reclamações à Municípia, designadamente em caso de alegado
        incumprimento, por parte da Municípia, de qualquer uma das obrigações
        estabelecidas no Regulamento (EU) 2019/1150 do Parlamento Europeu e do
        Conselho de 20 de Junho de 2019 relativo à promoção da equidade e da
        transparência para os utilizadores profissionais de serviços de
        intermediação em linha, presente regulamento, que o afete diretamente,
        relativamente a questões tecnológicas diretamente relacionadas com a
        prestação de serviços de acesso ao MarketPlace, ou ainda relativamente a
        medidas tomadas pela Municípia, ou respetivas práticas, diretamente
        ligadas à prestação dos serviços de acesso ao MarketPlace.
      </p>
      <p>
        A Municípia, entidade certificada pela norma ISO 9001, dispondo de um
        sistema de tratamento de reclamações, compromete-se a analisar
        devidamente as reclamações apresentadas e a assegurar o acompanhamento
        das mesmas, a fim de tratar as questões apresentadas de forma adequada,
        a tratar as reclamações de forma rápida e eficaz, tendo em conta a
        importância e a complexidade das questões suscitadas, e, bem assim, a
        comunicar ao PediGuia reclamante os resultados do procedimento interno
        de tratamento de reclamações, de uma forma personalizada.
      </p>
      <h2>
        Cláusula 7ª – Restrição, Suspensão ou Cessação da relação com os
        PediGuias
      </h2>
      <p>
        Estes TCGU PediGuias são válidos por um período indeterminado. Cada
        parte pode, portanto, denunciar o presente contrato a qualquer momento,
        sem justificação, mediante um pré-aviso de 2 (dois) meses.
      </p>
      <p>
        Caso o PediGuia não cumpra qualquer uma das suas obrigações, ou qualquer
        dos critérios de avaliação abaixo indicados, a Municípia pode
        restringir, suspender ou cessar a prestação do Serviço nos seguintes
        termos:
      </p>
      <p>
        Caso a Municípia decida restringir ou suspender o acesso ao MarketPlace
        a um determinado PediGuia, notificá-lo-á da fundamentação da sua decisão
        na data em que a restrição ou suspensão operará os seus efeitos.
      </p>
      <p>
        A Municípia poderá suspender o acesso ao MarketPlace, designadamente e
        sem limitar, caso se verifique que a nota média da avaliação global dos
        Visitantes é inferior a 4.0, sendo a escala de 0 a 5.
      </p>
      <p>
        Caso a Municípia decida cessar a prestação dos seus serviços de acesso
        ao MarketPlace a um determinado PediGuia, notificá-lo-á por e-mail,
        notificação na sua conta e/ou SMS, da fundamentação da sua decisão,
        mediante um aviso prévio de 30 (trinta) dias sobre a data em que a
        cessação operará os seus efeitos.
      </p>
      <p>
        A Municípia poderá cessar a prestação dos seus serviços de acesso ao
        MarketPlace, com a consequente desativação da conta do PediGuia,
        designadamente e sem limitar, caso se verifique que a nota média da
        avaliação global dos Visitantes é inferior a 4.0 ou em função do
        cancelamento de reservas conforme descrito na Cláusula 4ª.
      </p>
      <p>
        Em caso de restrição, suspensão ou cessação do acesso ao MarketPlace de
        um PediGuia PS que se encontre associado a um PediGuia PC, o PediGuia PC
        será informado pela Municípia de tal facto. Caso a restrição, suspensão
        ou cessação do acesso ao MarketPlace seja a um PediGuia PC, os PediGuias
        PS que lhes estão associados serão igualmente informados pela Municípia.
      </p>
      <p>
        No caso de violação grave e/ou reiterada por parte do PediGuia das suas
        obrigações, nomeadamente e sem limitar: fraude, comentários ofensivos,
        contrafação, violação dos direitos de terceiros, a Municípia poderá
        rescindir o Serviço sem aviso prévio e sem o PediGuia ter direito a
        qualquer compensação/indemnização, podendo ainda reter o balanço da
        conta corrente para fazer face a reclamações que possam surgir
        futuramente. Neste caso, o PediGuia será notificado pela Municípia da
        fundamentação da sua decisão de rescisão do Serviço por carta registada
        com aviso de receção.
      </p>
      <p>
        No caso de a violação por parte do PediGuia consubstanciar uma atividade
        ilícita, a Municípia informará de imediato as autoridades competentes.
      </p>
      <h2>Cláusula 8ª - Dados Pessoais</h2>
      <p>
        As informações e os dados pessoais relativos ao PediGuia ou de qualquer
        pessoa singular que represente os PediGuias são processados pela
        Municípia; salvo menção em contrário, são indispensáveis para assegurar
        a gestão da conta do PediGuia e do seu acesso ao Serviço. Estas
        informações e dados pessoais são igualmente conservados para finalidades
        de segurança, para assegurar o cumprimento das obrigações legais que
        incumbam, se for caso disso, à Municípia, bem como para permitir à
        Municípia melhorar e personalizar os serviços propostos aos utilizadores
        do Sítio, e, bem assim, as informações enviadas ao PediGuia. Neste
        âmbito, o PediGuia poderá receber informações por correio eletrónico
        relativas ao Serviço, por parte da Municípia.
      </p>
      <p>
        Em função destas finalidades de recolha e tratamento de dados, a
        Municípia poderá com o consentimento dos respetivos titulares reunir
        dados pessoais destes relativos à identificação (Nome, NIF,
        nacionalidade e e-mail), idiomas de conforto, meios de recebimento do
        pagamento (IBAN, Nome, NIF e morada de empresa, se for o caso),
        localização geográfica e competências enquanto guia (história, biologia,
        geografia, “contador de histórias”, etc.).
      </p>
      <p>
        Nos termos da Legislação em vigor sobre a Proteção de Dados Pessoais, o
        PediGuia pode exercer a todo o tempo os seus direitos de acesso,
        retificação, apagamento, limitação, oposição e portabilidade, devendo,
        para tal, enviar um e-mail para privacidade@municípia.pt com a indicação
        do seu apelido, nome, endereço de e-mail.
      </p>
      <p>
        Os dados pessoais do PediGuia serão conservados somente durante o prazo
        necessário à concretização da finalidade que motivou a sua recolha.
      </p>
      <p>
        O PediGuia, enquanto titular dos seus dados pessoais, tem o direito de
        apresentar uma reclamação em matéria de proteção de dados pessoais à
        autoridade de controlo competente, a Comissão Nacional de Proteção de
        Dados (CNPD).
      </p>
      <p>
        A Municípia utiliza cookies para assinalar a passagem do PediGuia no
        Sítio. Os cookies só são utilizados pela Municípia com o intuito de
        melhorar o serviço personalizado que lhe é destinado. Para mais
        informações sobre os cookies consulte o Capítulo E do presente
        documento.
      </p>
      <p>
        No que respeita aos dados relativos às reservas de PediRotas do PediGuia
        recolhidas no âmbito do Serviço, a Municípia informará os Visitantes que
        estes se destinam à Municípia e que as informações necessárias para a
        entrega das PediRotas serão comunicadas, unicamente para esta
        finalidade, ao PediGuia.
      </p>
      <p>
        O PediGuia compromete-se irrevogavelmente a utilizar os dados pessoais
        do Visitante que lhe serão comunicados pela Municípia somente para
        efeitos da prestação do serviço e para lhe permitir o cumprimento das
        obrigações legais decorrentes. O PediGuia compromete-se a não conservar
        qualquer cópia dos referidos dados assim que tenha prestado o(s)
        serviços(s) reservado(s). Qualquer outra utilização, aluguer, venda,
        revenda ou cópia por parte do PediGuia é proibida. Neste âmbito, o
        PediGuia poderá receber informações por correio eletrónico relativas ao
        Serviço da parte da Municípia. O PediGuia obriga-se a cumprir as normas
        legais e regulamentares aplicáveis em matéria de proteção de dados
        pessoais quando tratar os dados pessoais que lhe serão comunicados pela
        Municípia.
      </p>
      <p>
        O PediGuia obriga-se a aplicar, pelo menos, as seguintes medidas
        técnicas, organizativas e de segurança:
      </p>
      <ul>
        <li>
          Proteger os seus computadores, telemóveis e dispositivos com:
          <ul>
            <li>
              Senhas seguras, com caracteres suficientes e compostas por letras
              maiúsculas e minúsculas, números e caracteres especiais, tendo de
              alterá-las, no mínimo, trimestralmente;
            </li>
            <li>Sistemas operativos, aplicações e antivírus atualizados;</li>
            <li>
              Proteger o acesso ao correio eletrónico com senhas seguras, tendo
              de alterá-las, no mínimo, trimestralmente;
            </li>
            <li>
              Cifrar os dispositivos de armazenamento externos que utilizar (pen
              drives, discos rígidos, etc.);
            </li>
            <li>
              Realizar qualquer destruição de documentos de maneira segura.
            </li>
          </ul>
        </li>
      </ul>
      <h2>Cláusula 9ª – Propriedade Intelectual</h2>
      <p>
        Todos os textos, comentários, trabalhos, ilustrações, obras e imagens
        reproduzidas ou representadas no Sítio estão protegidas nos termos do
        direito de autor bem como da propriedade intelectual, por toda a duração
        da proteção de tais direitos e para o mundo inteiro. Nesta conformidade
        e nos termos do disposto no Código dos Direitos de Autor e Direitos
        Conexos, é somente autorizada a utilização privada, sem prejuízo de
        disposições diferentes e porventura mais restritivas constantes do
        referido Código. Qualquer reprodução ou representação total ou parcial
        do Sítio e/ou de todo ou parte dos elementos que se encontrem no Sítio é
        absolutamente proibida.
      </p>
      <p>
        A MUNICÍPIA não será responsável por qualquer violação de Direitos de
        Autor e Direitos Conexos de PediRotas comercializadas no MarketPlace,
        nos termos da legislação aplicável.
      </p>
      <h2>Cláusula 10ª – Responsabilidade</h2>
      <p>
        O PediGuia garante e indemnizará a Municípia à primeira solicitação
        relativamente a qualquer prejuízo sofrido pela Municípia e contra
        qualquer ação de responsabilidade que seja intentada contra a Municípia
        por violação pelo PediGuia de um qualquer direito de terceiro, inclusive
        de um Visitante, quando o prejuízo decorra da prestação do serviço
        associado às PediRotas, da utilização feita pelo PediGuia do Serviço ou
        de qualquer outro facto que lhe seja imputável.
      </p>
      <p>
        A responsabilidade da Municípia para com o PediGuia só pode ser assacada
        por factos que lhe sejam diretamente imputáveis e é, em qualquer
        circunstância, limitada ao montante das reservas efetuadas durante o mês
        anterior. A responsabilidade da Municípia não pode ser assacada por
        prejuízos indiretos, nomeadamente pelos lucros cessantes resultantes da
        comercialização de PediRotas em caso de indisponibilidade do Serviço. A
        responsabilidade da Municípia não pode ser assacada em caso de má
        utilização do Serviço pelo PediGuia.
      </p>
      <p>
        A Municípia declina toda e qualquer responsabilidade relativamente aos
        contratos celebrados entre os PediGuias e os Visitantes por intermédio
        do seu Serviço. Qualquer reclamação relativa aos PediRotas (descrição,
        entrega, condições de utilização, etc.) que seja levada ao conhecimento
        da Municípia será transmitida ao PediGuia em questão que assumirá
        integralmente a responsabilidade. A Municípia não se responsabiliza,
        ainda, por menores deixados sozinhos e/ou desacompanhados durante a
        realização de PediRotas.
      </p>
      <p>
        Na assunção de responsabilidades relativamente a um PediGuia PS que se
        encontre associado a um PediGuia PC, tanto o PediGuia PS como o PediGuia
        PC assumirão a responsabilidade perante a Municípia.
      </p>
      <h2>Cláusula 11ª – Nulidade parcial</h2>
      <p>
        Caso uma ou mais disposições dos presentes TCGU PediGuia sejam
        consideradas inválidas ou declaradas como tal por aplicação da lei ou de
        uma decisão definitiva emanada de um tribunal competente, as restantes
        disposições manter-se-ão válidas e eficazes.
      </p>
      <h2>Cláusula 12ª – Foro e lei aplicável</h2>
      <p>Os presentes TCGU PediGuia estão sujeitos à legislação portuguesa.</p>
      <p>
        Em caso de litígio entre a Municípia e o PediGuia decorrente da
        prestação do Serviço, incluindo quaisquer reclamações que não possam
        resolvidas por meio do procedimento interno de tratamento de reclamações
        a que se refere a Clausula 6.ª supra, qualquer uma das partes pode
        recorrer à mediação com vista à resolução extrajudicial do diferendo.
      </p>
      <p>
        O recurso à mediação é de natureza facultativa e qualquer tentativa de
        alcançar um acordo através de mediação para fins de resolução de
        litígios, nos termos do presente artigo, não afeta os direitos da
        Municípia e do PediGuia de intentarem uma ação judicial em qualquer
        momento, antes, durante ou após o procedimento de mediação.
      </p>
      <p>
        Qualquer litígio relativo à sua interpretação e/ou à sua execução está
        sujeito à competência dos tribunais portugueses. Em caso de diferendo
        que oponha a Municípia a um PediGuia, serão competentes os tribunais da
        Comarca de Lisboa Oeste não obstante a existência eventual de vários
        réus ou a intervenção de terceiros, incluindo no âmbito dos processos
        cautelares.
      </p>
      <h1 id="market">
        C - Termos e Condições Gerais de Utilização do Serviço do MarketPlace
      </h1>
      <p>
        Os presentes Termos e Condições Gerais de Utilização do Serviço do
        MarketPlace (doravante as "TCGUS") aplicam-se em complemento, no que
        respeita aos Visitantes, dos TCGU Visitantes e, no que respeita aos
        PediGuias, tanto os PediGuias pessoas singulares (“PediGuias PS”), como
        os PediGuias pessoas coletivas (“PediGuias PC”), dos TCGU PediGuias, nos
        quais estão definidos os termos em maiúsculas utilizados nos presentes
        TCGUS.
      </p>
      <p>
        Os TCGUS aplicam-se a todas as reservas de PediRotas realizadas por
        intermédio do Serviço entre o PediGuia e o Visitante. Os TCGUS regulam
        as relações entre os PediGuias e os Visitantes, com exceção das relações
        entre os Visitantes e a Municípia ou entre os PediGuias e a Municípia.
        As relações entre os Visitantes e a Municípia são reguladas pelas TCGU
        Visitantes. As relações entre os PediGuias e a Municípia regulam-se
        pelos TCGU PediGuia.
      </p>
      <p>
        A Municípia não é o prestador de serviço das PediRotas reservadas por
        intermédio do Serviço disponibilizado pela plataforma; somente o
        PediGuia, cujo nome está indicado na ficha descritiva da cada PediRota,
        é a parte contratante do Visitante para a compra das referidas
        PediRotas.
      </p>
      <h2 id="cancel-policy">
        Cláusula 1ª – Celebração do contrato de prestação de serviço entre
        PediGuia e Visitante
      </h2>
      <p>Os presentes TCGUS estão sujeitos à legislação portuguesa.</p>
      <ol>
        <li>
          As PediRotas são apresentadas no Sítio com um descritivo que permite
          ao Visitante conhecer as respetivas características essenciais e o seu
          preço.
        </li>
        <li>O Visitante seleciona a(s) PediRota(s) que pretende reservar.</li>
        <li>
          O Visitante confirma a sua escolha de PediRota(s) e toma conhecimento
          e aceita os presentes TCGUS por um clique de validação.
        </li>
        <li>O Visitante efetua o pagamento da(s) PediRota(s).</li>
        <li>
          O Visitante recebe uma mensagem de e-mail a confirmar a aceitação da
          sua reserva.
        </li>
        <li>
          O PediGuia é informado pela Municípia que uma ou mais PediRotas que
          colocou em linha foi objeto de uma reserva, assumindo deste modo o
          PediGuia o compromisso definitivo de prestar o serviço no prazo
          previsto, sem prejuízo das exceções legais que sejam aplicáveis.
        </li>
        <li>
          Cancelamento de reservas:
          <ol type="1">
            <li>
              Em caso de cancelamento da reserva pelo Visitante nos termos e
              condições de livre resolução referidos na cláusula 3.ª infra, o
              contrato celebrado entre o Visitante e o PediGuia é resolvido e
              cada uma das partes fica desonerada das suas obrigações. Caso o
              cancelamento da reserva seja feito após o prazo referido na
              cláusula 3.ª infra, não haverá lugar à devolução do preço pago.
            </li>
            <li>
              Em caso de cancelamento da reserva pelo PediGuia, antes da data e
              hora definida para a realização da PediRota o contrato celebrado
              entre o Visitante e o PediGuia é resolvido, sendo o preço da
              reserva devolvido automaticamente ao Visitante, sendo o PediGuia
              responsável pelas demais consequências da resolução antecipada do
              contrato.
            </li>
          </ol>
        </li>
        <li>
          O Visitante deve confirmar:
          <ul>
            <li>A prestação do(s) serviço(s);</li>
            <li>
              Conformidade do(s) mesmo(s) (conformidade da PediRota entende-se
              que corresponde ao que foi reservado);
            </li>
            <li>
              Condições da prestação do serviço (que corresponde ao descritivo
              no Sítio no momento da reserva). Na falta de confirmação, a
              PediRota presume-se prestada conforme no prazo de 7 (sete) dias a
              contar da data da respetiva prestação de serviço.
            </li>
          </ul>
        </li>
        <li>
          O Visitante é convidado a avaliar o desempenho do PediGuia nos termos
          previstos na cláusula 5.ª.
        </li>
      </ol>
      <h2>Cláusula 2ª – Preço e pagamento</h2>
      <p>
        O valor de reserva da PediRota é determinado pela Municípia, sendo o
        preço indicado em euros com todos os impostos e taxas incluídos na ficha
        descritiva.
      </p>
      <p>
        O pagamento das compras efetuadas através do Serviço realiza-se à
        Municípia que recebe o montante correspondente, em nome e por conta do
        PediGuia.
      </p>
      <h2 id="refund-policy">Cláusula 3ª – Direito de livre resolução</h2>
      <p>
        Nos termos da legislação em vigor, no âmbito de uma reserva realizada a
        um PediGuia, o Visitante tem um prazo de 14 (catorze) dias a contar da
        celebração do contrato (reserva) para exercer, junto do referido
        PediGuia, o seu direito de livre resolução sem pagamento de indemnização
        e sem necessidade de indicar o motivo.
      </p>
      <p>
        Caso, entretanto, o(s) serviço(s) haja(m) sido integralmente
        prestado(s), mediante consentimento expresso do Visitante, registado no
        momento da realização da PediRota, o Visitante não pode resolver
        livremente o(s) contrato(s).
      </p>
      <p>
        Em caso de exercício do direito de livre resolução no prazo
        suprarreferido, são reembolsados somente o preço da(s) PediRotas(s) não
        realizada(s).
      </p>
      <p>
        O Visitante exerce o seu direito de livre resolução diretamente junto do
        PediGuia em questão por intermédio da ferramenta colocada à sua
        disposição no âmbito do Serviço.
      </p>
      <p>
        O reembolso das PediRotas é efetuado pela Municípia ao Visitante no
        prazo mais curto possível e dentro do prazo de 30 (trinta) dias a contar
        da data do exercício do direito de livre resolução.
      </p>
      <h2>Cláusula 4ª – Litígios e reclamações</h2>
      <p>
        O Visitante tem a possibilidade de indicar, no prazo de 7 (sete) dias
        após a prestação do serviço, qualquer reclamação relativa às PediRotas
        reservadas, por aplicação dos seguintes critérios:
      </p>
      <ul>
        <li>Serviço não prestado: o serviço não foi prestado ao Visitante.</li>
        <li>
          Serviço não conforme: o serviço prestado não correspondeu ao
          reservado.
        </li>
      </ul>
      <p>
        Os litígios são resolvidos diretamente entre o Visitante e o PediGuia
        (quer seja PediGuia PC, quer seja PediGuia PS).
      </p>
      <p>
        O Visitante e o PediGuia envidarão os melhores esforços para permitir a
        resolução amigável do litígio.
      </p>
      <p>
        Consoante os casos, o litígio poderá dar lugar à prestação do serviço
        reservado ou ao reembolso.
      </p>
      <h2>Cláusula 5ª – Avaliação dos PediGuias</h2>
      <p>
        A Municípia põe à disposição dos Visitantes meios que lhes permitem
        avaliar o desempenho dos PediGuias no termo da confirmação da realização
        das PediRotas reservadas, permitindo deste modo aos Visitantes
        selecionar as PediRotas junto dos PediGuias que cumprem melhor as
        condições de utilização do Serviço.
      </p>
      <p>
        A avaliação é realizada por aplicação de critérios de avaliação e por
        atribuição de estrela(s) pelos Visitantes. Nesta medida, a Municípia não
        realiza qualquer fiscalização da apreciação feita pelos Visitantes, que
        se limita a armazenar no Sítio. A Municípia pode, no entanto, decidir
        eliminar, sem aviso prévio, qualquer apreciação cujo conteúdo lhe tenha
        sido indicado como sendo de natureza ilícita. As avaliações feitas pelo
        Visitante bem como o seu pseudónimo são visíveis por qualquer visitante
        do Sítio.
      </p>
      <p>
        A Municípia reserva-se o direito de interromper temporária ou
        definitivamente o acesso ao Serviço do PediGuia cuja avaliação seja
        inferior a 4 (quatro), suspendendo ou resolvendo o Serviço nos termos
        previstos nos TCGU PediGuia.
      </p>
      <h2>Cláusula 6ª - Dados Pessoais</h2>
      <p>
        Em complemento do disposto na Cláusula 5.º dos TCGU Visitantes e do
        disposto na Cláusula 8.º dos TCGU PediGuia, o Visitante e o PediGuia são
        informados que os dados recolhidos no âmbito do Serviço no ato da
        colocação das reservas são processados pela Municípia para efeitos do
        processamento das referidas reservas.
      </p>
      <p>
        As informações e os dados dos Visitantes relativos à entrega são
        comunicados pela Municípia aos PediGuias com a finalidade exclusiva de
        lhes permitir a prestação da(s) PediRota(s) reservada(s). Não podem, em
        caso algum, ser utilizados para outras finalidades.
      </p>
      <p>
        Cada PediGuia só tem acesso aos dados pessoais fornecidos pelos
        Visitantes que reservaram as suas PediRotas à Municípia no momento da
        sua reserva.
      </p>
      <p>
        Os PediGuias comprometem-se a assegurar a segurança dos dados pessoais
        que conservam para efeitos do processamento e do seguimento das
        reservas.
      </p>
      <p>
        Em caso de problema suscitado pelo tratamento destes dados, o Visitante
        e o PediGuia podem dirigir-se diretamente à Municípia nas condições
        definidas, consoante o caso, nos TCGU Visitantes ou nos TCGU PediGuia.
      </p>
      <h2>Cláusula 7ª – Segurança</h2>
      <p>
        O Sítio é objeto de um sistema de segurança: a Municípia adotou o
        processo de encriptamento SSL mas também reforçou o conjunto dos
        procedimentos de interferência e de encriptamento para proteger de forma
        mais eficaz todos os dados sensíveis relativos aos meios de pagamento
        utilizados no Sítio.
      </p>
      <h2>Cláusula 8ª – Nulidade parcial</h2>
      <p>
        Caso uma ou várias disposições dos presentes TCGUS sejam consideradas
        inválidas ou declaradas como tal por aplicação de uma legislação ou por
        uma decisão definitiva de um tribunal competente, as restantes
        disposições manter-se-ão válidas e eficazes.
      </p>
      <h2>Cláusula 9ª – Foro e lei aplicável</h2>
      <p>
        As presentes condições gerais estão sujeitas à legislação portuguesa.
        Qualquer litígio relativo à sua interpretação e/ou à sua execução é da
        competência dos tribunais portugueses.
      </p>
      <h1 id="legal">D – Disposições legais relevantes</h1>
      <p>
        <b>Decreto-Lei n.º 7/2004, de 7 de janeiro</b>
      </p>
      <p>
        O Decreto-Lei n.º 7/2004, de 7 de janeiro (doravante “DL n.º 7/2004”),
        destina-se a realizar a transposição da Diretiva n.º 2000/31/CE, do
        Parlamento Europeu e do Conselho, de 8 de junho de 2000, regulando o
        comércio eletrónico, deixando apenas em aberto algumas matérias como: a
        matéria fiscal, a disciplina da concorrência, o regime de tratamento de
        dados pessoais e da proteção da privacidade, o patrocínio judiciário, os
        jogos de fortuna e a atividade notarial ou equiparada.
      </p>
      <p>
        Nos termos do artigo 13.º do DL n.º 7/2004, cabe ao prestador
        intermediário de serviços a obrigação para com as entidades competentes
        de informar de imediato quando tiverem conhecimento de atividades
        ilícitas que se desenvolvam por via dos serviços que prestam; de
        satisfazer os pedidos de identificação dos destinatários dos serviços
        com quem tenham acordos de armazenamento; cumprir prontamente as
        determinações destinadas a prevenir ou pôr termo a uma infração,
        nomeadamente no sentido de remover ou impossibilitar o acesso a uma
        informação; e fornecer listas de titulares de sítios que alberguem,
        quando lhes for solicitado.
      </p>
      <p>
        De acordo com o artigo 14.º do presente DL n.º 7/2004, se o prestador
        intermediário de serviços estiver apenas encarregue da atividade de
        transmissão de informações em rede, ou de facultar o acesso a uma rede
        de comunicações, sem ter qualquer intervenção no conteúdo das mensagens
        transmitidas, é isento de toda a responsabilidade pelas informações
        transmitidas.
      </p>
      <p>
        No entanto, nos termos do artigo 15.º, passa a aplicar-se o regime comum
        de responsabilidade se o prestador intermediário de serviços não
        proceder segundo as regras usais do sector, como: atualização da
        informação e no uso da tecnologia, aproveitando-a para obter dados sobre
        a utilização da informação; ou ainda, nos casos em que chegar ao
        conhecimento do prestador que a informação foi retirada da fonte
        originária ou acesso tornado impossível ou ainda que um tribunal ou
        entidade administrativa com competência sobre o prestador que está na
        origem da informação ordenou essa remoção ou impossibilidade de acesso
        com exequibilidade imediata e o prestador não a retirar ou
        impossibilitar imediatamente o acesso.
      </p>
      <p>
        No entanto, nos casos de armazenagem principal, o artigo 16.º determina
        que o prestador intermediário só é responsável, nos termos comuns, pela
        informação que armazena se tiver conhecimento de atividade ou informação
        cuja ilicitude for manifesta e não retirar ou impossibilitar logo o
        acesso a essa informação. Aplicando-se ainda, as regras de comum de
        responsabilidade sempre que o destinatário do serviço atuar subordinado
        ao prestador ou for por ele controlado.
      </p>
      <p>
        O DL n.º 7/2004 pretende determinar o regime de responsabilidade dos
        prestadores intermediários de serviços. Mais precisamente, visa
        estabelecer as condições de irresponsabilidade destes prestadores face à
        eventual ilicitude das mensagens que disponibilizam.
      </p>
      <p>
        <b>
          Artigo 1.º do Decreto-Lei n.º 238/86, de 19 de agosto alterado pelo
          Decreto-Lei n.º 42/88, de 6 de fevereiro
        </b>
      </p>
      <p>
        As informações sobre a natureza, características e garantias de bens ou
        serviços oferecidos ao público no mercado nacional, quer as constantes
        de rótulos, embalagens, prospetos, catálogos, livros de instruções para
        utilização ou outros meios informativos, quer as facultadas nos locais
        de venda ou divulgadas por qualquer meio publicitário, deverão ser
        prestadas em língua portuguesa.
      </p>
      <p>
        <b>
          Artigo 2.º do Decreto-Lei n.º 238/86, de 19 de agosto alterado pelo
          Decreto-Lei n.º 42/88, de 6 de fevereiro
        </b>
      </p>
      <p>
        No caso de as informações escritas se encontrarem redigidas em língua ou
        línguas estrangeiras aquando da venda de bens ou serviços no mercado
        nacional é obrigatória a sua tradução integral em língua portuguesa,
        devendo, conforme os casos, o texto traduzido ser aposto nos rótulos ou
        embalagens ou aditado aos meios informativos referidos no artigo
        anterior.
      </p>
      <p>
        <b>
          Artigo 3.º do Decreto-Lei n.º 238/86, de 19 de agosto alterado pelo
          Decreto-Lei n.º 42/88, de 6 de fevereiro
        </b>
      </p>
      <p>
        Sem prejuízo de conterem versão em língua ou línguas estrangeiras, os
        contratos que tenham por objeto a venda de bens ou PediRotas ou a
        prestação de serviços no mercado interno, bem como a emissão de faturas
        ou recibos, deverão ser redigidos em língua portuguesa.
      </p>
      <p>
        Artigo 4.º do Decreto-Lei n.º 238/86, de 19 de agosto alterado pelo
        Decreto-Lei n.º 42/88, de 6 de fevereiro
      </p>
      <p>
        1 – As obrigações previstas no presente diploma impedem, no mercado
        interno, sobre o fabricante, embalador, prestador de serviços e todos os
        outros agentes que desenvolvam atividades de comércio por grosso ou a
        retalho.
      </p>
      <p>
        2 – A fiscalização do cumprimento da obrigação de informar em língua
        portuguesa será efetuada quando o bem ou serviço é colocado ao alcance
        do consumidor sem prejuízo da responsabilidade dos restantes agentes
        económicos referidos no número anterior.
      </p>
      <h1 id="privacy">E - Política de Privacidade</h1> 
      <p>
        <b>1. IDENTIFICAÇÃO DO RESPONSÁVEL PELO TRATAMENTO</b>
      </p>
      <ul>
        <li>
          Empresa: Municípia – Empresa de Cartografia e Sistemas de Informação,
          S.A. (adiante “Municípia”)
        </li>
        <li>Sede: Taguspark, Edifício Ciência II, 3ºB, 2740-120 Porto Salvo</li>
        <li>NIPC: 504 475 606</li> 
      </ul>
      <p>
        <b>2. INFORMAÇÃO E CONSENTIMENTO</b>
      </p>
      <p>
        A Lei da Proteção de Dados Pessoais (em diante “LPDP”) e o Regulamento
        Geral de Proteção de Dados (Regulamento (UE) 2016/679 do Parlamento
        Europeu e do Conselho de 27 de abril de 2016, em diante “RGPD”)
        asseguram a proteção das pessoas singulares no que diz respeito ao
        tratamento de dados pessoais e à livre circulação desses dados.
      </p>
      <p>
        Nos termos legais são considerados "dados pessoais" qualquer informação,
        de qualquer natureza e independentemente do respetivo suporte, incluindo
        som e imagem, relativa a uma pessoa singular identificada ou
        identificável, pelo que a proteção não abrange os dados de pessoas
        coletivas.
      </p>
      <p>
        Mediante a aceitação da presente Política de Privacidade o utilizador
        presta o seu consentimento informado, expresso, livre e inequívoco para
        que os dados pessoais fornecidos através do site www.uniquepaths.eu
        sejam incluídos num ficheiro da responsabilidade da Municípia, cujo
        tratamento nos termos da LPDP e do RGPD cumpre as medidas de segurança
        técnicas e organizativas adequadas.
      </p>
      <p>
        A Municípia, mantém uma base de dados com o registo dos seus clientes.
        Os dados presentes nesta base são unicamente os dados prestados pelos
        próprios na altura do seu registo, sendo recolhidos e processados
        automaticamente, nos termos aprovados pela Municípia, entidade
        responsável pelo correspondente ficheiro.
      </p>
      <p>
        Em caso algum será solicitada informação sobre convicções filosóficas ou
        políticas, filiação partidária ou sindical, fé religiosa, vida privada e
        origem racial ou étnica bem como os dados relativos à saúde e à vida
        sexual, incluindo os dados genéticos.
      </p>
      <p>
        Em caso algum levaremos a cabo qualquer das seguintes atividades com os
        dados pessoais que nos sejam facultados através deste site:
      </p>
      <ul>
        <li>
          Ceder a outras pessoas ou outras entidades, sem o consentimento prévio
          do titular dos dados;
        </li>
        <li>
          Transferir para fora do Espaço Económico Europeu (EEE), sem o
          consentimento prévio do titular dos dados.
        </li>
         
      </ul>
      <p>
        <b>3. FINALIDADES DO TRATAMENTO DE DADOS PESSOAIS</b>
      </p>
      <p>
        Os dados pessoais que tratamos através desta página serão unicamente
        utilizados para as seguintes finalidades:
      </p>
      <ul>
        <li>
          Execução e gestão de contrato de compra e venda ou de prestação de
          serviços;
        </li>
        <li>Processamento de reservas;</li>
        <li>Comunicação com clientes e esclarecimento de dúvidas;</li>
        <li>Processamento de pedidos de informação;</li>
        <li>
          Processamento de reclamações, Processos de Reguladores, Processos
          pré-contencioso ou exercício de direitos em processos judiciais;
        </li>
        <li>Atividades de análise estatística;</li>
        <li>
          Verificar, manter e desenvolver sistemas e análises estatísticas;
        </li>
        <li>
          Comunicações de marketing direto (caso tenha consentido no tratamento
          dos seus dados pessoais para esta finalidade);
        </li>
        <li>Prevenção e combate à fraude;</li>
        <li>Solicitação de comentários a PediRotas ou serviços adquiridos;</li>
        <li>Realização de inquéritos de satisfação;</li>
      </ul>
      <p>
        A Municípia garante a confidencialidade de todos os dados fornecidos
        pelos seus clientes. Não obstante a Municípia proceder à recolha e ao
        tratamento de dados de forma segura e que impede a sua perda ou
        manipulação, utilizando as técnicas mais aperfeiçoadas para o efeito,
        informamos que a recolha em rede aberta permite a circulação dos dados
        pessoais sem condições de segurança, correndo o risco de ser vistos e
        utilizados por terceiros não autorizados.
      </p>
      <p>
        Por outro lado, o utilizador consente que se possa aceder à informação
        relativa ao serviço contratado com a Municípia com o fim de lhe poder
        oferecer serviços adicionais ao contratado.
      </p>
      <p>
        No momento da recolha dos dados pessoais, salvo nos campos em que for
        indicado o contrário, o utilizador poderá, voluntariamente,
        disponibilizar os dados pessoais, sem que a falta de resposta implique
        um decréscimo na qualidade ou quantidade dos serviços correspondentes (a
        menos que esteja indicada outra coisa). Não obstante, a falta de
        resposta aos dados, considerados obrigatórios, implicará a
        impossibilidade de aceder ao serviço para o qual os dados foram
        solicitados.
      </p>
      <p>
        Caso não concorde com as condições acima referidas, a Municípia não
        poderá contratar com o utilizador através do Sítio.
      </p>
      <p>
        <b>4. CESSÃO DE DADOS PESSOAIS</b>
      </p>
      <p>
        Por forma a poder cumprir com o objeto do presente Sítio, a Municípia
        irá ceder os seus dados pessoais a outras entidades, que os irão tratar,
        para as seguintes finalidades:
      </p>
      <ul>
        <li>Atividades de gestão e processamento de pagamentos;</li>
        <li>Prestação dos serviços contratados.</li>
      </ul>
      <p>
        As entidades a quem a Municípia irá ceder os seus dados pessoais para os
        tratarem nos termos acima referidos terão a seguinte natureza:
      </p>
      <ul>
        <li>PediGuias;</li>
        <li>
          Entidades terceiras relacionadas com a prestação dos serviços
          contratados;
        </li>
        <li>Entidades de gestão e processamento de pagamentos e faturações.</li>
         
      </ul>
      <p>
        <b>5. MEDIDAS DE SEGURANÇA</b>
      </p>
      <p>
        A Municípia declara que implementou e continuará a implementar as
        medidas de segurança de natureza técnica e organizativa necessárias para
        garantir a segurança dos dados de carácter pessoal que lhe sejam
        fornecidos visando evitar a sua alteração, perda, tratamento e/ou acesso
        não autorizado, tendo em conta o estado atual da tecnologia, a natureza
        dos dados armazenados e os riscos a que estão expostos.
      </p>
      <p>
        A Municípia garante a confidencialidade de todos os dados fornecidos
        pelos seus clientes quer no registo, quer no processo de compra/reserva
        de serviços. A recolha e tratamento de dados realiza-se de forma segura
        e que impede a sua perda ou manipulação. Todos os dados serão inseridos
        num Servidor Seguro (SSL de 128 bits) que os encripta/codifica
        (transforma num código). O utilizador poderá verificar que o seu browser
        é seguro se o símbolo do cadeado aparecer ou se o endereço começar com
        https em vez de http.
      </p>
      <p>
        Os dados pessoais são tratados com o nível de proteção legalmente
        exigível para garantir a segurança dos mesmos e evitar a sua alteração,
        perda, tratamento ou acesso não autorizado, tendo em conta o estado da
        tecnologia, sendo o utilizador consciente e aceitando que as medidas de
        segurança em Internet não são inexpugnáveis.
      </p>
      <p>
        A Municípia, sempre que aceda a qualquer dado pessoal, compromete-se a:
      </p>
      <ul>
        <li>
          Armazená-los por intermédio de medidas de segurança, legalmente
          exigíveis, de natureza técnica e organizacional, que garantam a sua
          segurança, evitando assim a alteração, perda, tratamento ou acesso não
          autorizado, em conformidade com o estado da tecnologia em cada
          momento, a natureza dos dados e os possíveis riscos a que estejam
          expostos;
        </li>
        <li>
          Utilizar os dados exclusivamente para as finalidades previamente
          definidas;
        </li>
        <li>
          Certificar-se de que os dados são tratados unicamente pelos
          trabalhadores cuja intervenção seja necessária para a prestação do
          serviço estando os mesmos obrigados ao dever de sigilo e
          confidencialidade. Existindo a possibilidade de a informação ser
          revelada a terceiros, estes devem ser obrigados a guardar a devida
          confidencialidade em conformidade com o previsto neste documento.
        </li>
      </ul>
      <p>
        <b>6. COMUNICAÇÕES COMERCIAIS E PROMOCIONAIS</b>
      </p>
      <p>
        Um dos propósitos para os quais tratamos dados pessoais fornecidos pelos
        utilizadores é enviar comunicações eletrónicas com informações relativas
        a comunicações comerciais e promocionais.
      </p>
      <p>
        Sempre que fizermos uma comunicação deste tipo, será direcionado
        exclusivamente aos utilizadores que as tenham autorizado previamente e
        de forma expressa.
      </p>
      <p>
        De acordo com o disposto na Decreto-Lei n.º 7/2004, de 7 de janeiro, no
        caso de o utilizador desejar deixar de receber comunicações comerciais
        ou promocionais da Municípia, poderá solicitar a oposição do serviço
        enviando um e-mail a info@uniquepaths.eu.
      </p>
       
      <p>
        <b>7. EXERCÍCIO DOS DIREITOS</b>
      </p>
      <p>
        De acordo com as disposições da LDPD e do RGPD, o utilizador pode
        exercer a todo o tempo os seus direitos de acesso, retificação,
        apagamento, limitação, oposição e portabilidade, através de solicitação
        por qualquer dos seguintes meios:
      </p>
      <ul>
        <li>
          Correio: Av. Prof. Dr. Cavaco Silva, Edifício Ciência II, número 11,
          3º B, Taguspark, 2740-120 Porto Salvo - Portugal
        </li>
        <li>E-mail: info@uniquepaths.eu</li>
        <li>
          Telefone: +351214228200 horário de atendimento das 9:30 às 12:30 e das
          14:00 às 18:00 todos os dias úteis.
        </li>
      </ul>
      <p>
        Caso o utilizador deseje, a qualquer momento, deixar de fazer parte da
        base de dados Municípia poderá exercer esse direito através destes
        contactos.
      </p>
       
      <p>
        <b>8. AUTORIDADE DE CONTROLO</b>
      </p>
      <p>
        Nos termos legais, o titular dos dados tem o direito de apresentar uma
        reclamação em matéria de proteção de dados pessoais à autoridade de
        controlo competente, a Comissão Nacional de Proteção de Dados (CNPD).
      </p>
      <p>
        <b>9. POLÍTICA DE COOKIES </b>
      </p>
      <p>
        Cookies, são pequenos pedaços de dados que são armazenados pelo
        navegador de um utilizador no disco rígido deste por um período de
        tempo.
      </p>
      <p>
        Os cookies podem registar as informações a que um utilizador acede numa
        página da web para simplificar as interações subsequentes deste ou para
        usar as informações para agilizar as transações do utilizador em páginas
        da web relacionadas.
      </p>
      <p>
        Os cookies facilitam a movimentação do usuário entre páginas da web e a
        completar transações na Internet.
      </p>
      <p>
        Usamos "cookies" para tornar o Site e o Aplicativo mais fáceis de usar e
        para tornar a nossa publicidade melhor.
      </p>
      <p>
        O Site e o Aplicativo também usam “cookies” para armazenar e, às vezes,
        rastrear informações para tornar a sua experiência online mais fácil e
        personalizada.
      </p>
      <p>
        A maioria dos principais navegadores da web estão configurados para
        aceitar inicialmente cookies, mas o utilizador pode modificar as
        preferências do seu computador para emitir um alerta quando fizer um
        “download” de um cookie ou para desativar a capacidade de terceiros de o
        fazer. Se o utilizador optar por rejeitar todos os cookies, algumas
        áreas do Site e do Aplicativo poderão não funcionar corretamente.
      </p>
      <p>
        Se tiver dúvidas sobre os cookies empregados no site ou aplicativo,
        entre em contato connosco através do e-mail info@uniquepaths.eu
      </p>
    </>
  );
}
