import classNames from 'classnames';
import React, { useMemo } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Check } from '../../../../shared_assets/icons/check.svg';
import { RootState } from '../../../../store';
import { RouteApprovedNotification } from '../../../../types/notification.interface';
import { Heading, Text } from '../../../Typography';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { NotificationsProps } from '../Notification';

interface NotificationRouteApprovedProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: RouteApprovedNotification;
}

const NotificationRouteApproved: React.FC<NotificationRouteApprovedProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
}) => {
  const history = useHistory();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const { seen, type, routeTitles, routeId } = notification;

  const routeTitle = useMemo(() => {
    const translation = routeTitles.find(t => t.language === activeLanguage);
    return translation ? translation.title : routeTitles[0].title;
  }, [routeTitles, activeLanguage]);
  return (
    <>
      {!simpleVersion && (
        <div className={classes.rowDirection}>
          <Check className={classes.icon} />
          <Heading level={5} noMargin>
            {translate(`notificationType.${type}`).toString()}
          </Heading>
          {!seen && (
            <Badge
              className={classNames(classes.marginLeft, classes.uppercase)}
              type="primary"
              small
            >
              {translate('notifications.new').toString()}
            </Badge>
          )}
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        {!simpleVersion && <Text noMargin>{routeTitle}</Text>}
        <Text variant={!simpleVersion ? 'faded' : 'default'} noMargin>
          {translate('notifications.routeApproved').toString()}
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="link"
          onlyText
          onClick={() => {
            history.push(`/route/${routeId}`);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.seeRoute').toString()}
          </Text>
        </Button>
        <Button
          type="link"
          onlyText
          onClick={() => {
            history.push(`/guide/schedules/`, { preSelectedRouteId: routeId });
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.makeSchedule').toString()}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default withLocalize(NotificationRouteApproved);
