import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookFilled } from '../../../shared_assets/icons/facebook_filled.svg';
import { ReactComponent as InstagramFilled } from '../../../shared_assets/icons/instagram_filled.svg';
import { ReactComponent as YoutubeFilled } from '../../../shared_assets/icons/youtube_filled.svg';
import {
  complexTranslate,
  desktopPadding,
  mobileThreshhold,
} from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-end',
    flex: 0,
    justifyContent: 'flex-end',
    padding: '1rem',
    backgroundColor: 'black',
  },
  logoRow: {
    display: 'flex',
    alignItems: 'center',
  },
  smallLogo: {
    maxHeight: 32,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  noMargin: { margin: 0, lineHeight: 'unset ! important' },
  socialMediaContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
    '& img, & svg': {
      marginRight: '2rem',
      height: '2rem',
      width: '2rem',
      color: '#b0bec5',
    },
    '& > :last-child': {
      '& img, & svg': {
        marginRight: 'unset',
      },
    },
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...desktopPadding,
    },
    socialMediaContainer: {
      marginTop: 'unset',
      marginLeft: '1rem',
    },
  },
});

interface CopyrightFooterProps extends LocalizeContextProps {
  showSocialLinks?: boolean;
}

function CopyrightFooter({ translate, showSocialLinks }: CopyrightFooterProps) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Text color="white" style={{ marginBottom: '0rem' }}>
          {complexTranslate(translate('landing.allRightsReserved').toString(), {
            '{TC}': (
              <Link to="/terms-of-service" key="1">
                <Button
                  type="link"
                  href=""
                  className={classNames(classes.noMargin, classes.inlineBtn)}
                  onlyText
                >
                  <Translate id="landing.termsConditions" />
                </Button>
              </Link>
            ),
            '{PP}': (
              <Link to="/terms-of-service#privacy" key="2">
                <Button
                  type="link"
                  href="/privacyPolicy"
                  className={classNames(classes.noMargin, classes.inlineBtn)}
                  onlyText
                >
                  <Translate id="landing.privacyPolicy" />
                </Button>
              </Link>
            ),
          })}
        </Text>
        {showSocialLinks && (
          <div className={classes.socialMediaContainer}>
            <a href="https://www.facebook.com/uniquePATHS.eu/">
              <FacebookFilled />
            </a>
            <a href="https://www.youtube.com/channel/UCfXl3n5d5uaVL7s8eLkHoww">
              <YoutubeFilled />
            </a>
            <a href="https://www.instagram.com/uniquepaths.eu/">
              <InstagramFilled />
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(withLocalize(CopyrightFooter));
