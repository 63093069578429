import message from 'antd/es/message';
import Axios from 'axios';
import { API_URL } from '../../consts';
import { GuideFormProperty } from '../../types/guide.interface';

export interface EditCompanyDto {
  companyRepresentativeName: string;
  companyName: string;
  companyNIF: string;
  companyEmail: string;
  companyPhoneNumber: number;
  postalCode: string;
  companyLocation: string;
  companyAddress: string;
  ivaExemption: string;
  companyIban: string;
}

export const editCompany = (
  id: string,
  dto: EditCompanyDto,
  document: File | null
) => {
  const formData = new FormData();

  formData.append(
    'editCompanyDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (document) {
    formData.append('document', document);
  }

  return Axios({
    method: 'put',
    url: `${API_URL}/company/${id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      err.response.data.message.forEach(
        (t: { property: GuideFormProperty }) => {
          message.error(t.property);
        }
      );
    }

    return Promise.reject(err);
  });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
