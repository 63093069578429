import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Text } from '../../Typography';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  container: {
    borderRadius: 5,
    padding: '0.5em',
    // minWidth: '60%',
    // maxWidth: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '0 0.5rem',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  text: {
    marginBottom: '1rem !important',
  },
  image: {
    margin: '1rem 0 !important',
    maxWidth: '100%',
    borderRadius: 10,
  },
});

interface TipTextContent {
  text: string;
  image: string;
}

function ImageContent({ text, image }: TipTextContent) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <FallbackImage
        fallback={require('../../../shared_assets/images/route-default.png')}
        src={image}
        alt="place"
        className={classes.image}
      />
      <Text weight="bold" noMargin className={classes.text}>
        {text}
      </Text>
    </div>
  );
}

export default ImageContent;
