import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { GuidePublicProfile } from '../../types/guide-public-profile.interface';

type Hook = (
  id: string | undefined,
  setGuide: (val: SetStateAction<GuidePublicProfile | null>) => void
) => [boolean, boolean];

const requestPublicGuide = (id: string) =>
  Axios.get(`${API_URL}/guide/profile/${id}`);

export const usePublicGuide: Hook = (id, setGuide) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  if (id === 'route') console.trace();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!id) {
        setIsError(true);
        setGuide(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: guide } = await requestPublicGuide(id);
        setGuide(guide as GuidePublicProfile);
        setIsError(false);
      } catch (err) {
        setGuide(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, setGuide]);

  return [isLoading, isError];
};
