import { makeStyles } from '@material-ui/styles';
import Calendar from 'antd/es/calendar';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

import { Heading } from '../../../components/Typography';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow_back.svg';
import { ReactComponent as ArrowBackGrey } from '../../../shared_assets/icons/back_grey.svg';
import { ReactComponent as ArrowForwardBlack } from '../../../shared_assets/icons/forward_black.svg';
import { ReactComponent as ArrowForwardGrey } from '../../../shared_assets/icons/forward_grey.svg';
import { getTheme, mobileThreshhold, useIsMobile } from '../../../utils';

const useStyles = makeStyles({
  datePreviewContainer: () => ({
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'space-between',
    '& .ant-picker-panel': {
      borderTop: 'none',
    },
    '& .ant-picker-content th': {
      color: getTheme().primary,
      fontWeight: 'bold',
      padding: '0.5rem 0 1rem 0',
    },
    '& .ant-picker-cell-disabled': {
      visibility: 'hidden',
    },
  }),
  grey: {
    color: getTheme().neutral2,
  },
  icon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '1.5rem',
  },
  bold: {
    fontWeight: 'bold',
    color: getTheme().defaultTextPrimaryColor,
  },
  disabled: {
    color: getTheme().neutral4,
  },
  dateHeader: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    '& > h1': {
      fontWeight: (`${600} !important` as unknown) as number,
    },
    '& > div': {
      margin: '0 0.5rem',
      '&:last-child': {
        marginRight: 0,
      },
      '&:first-child': {
        marginLeft: 0,
        marginRight: '2rem',
      },
    },
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    datePreviewContainer: {
      flexDirection: 'column',
      marginTop: 'unset',
    },
    icon: {
      marginLeft: 'unset',
    },
    btnsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1rem 0',
    },
    calendarContainer: {
      display: 'flex',
      '& > :first-child': {
        paddingRight: '1rem',
      },
      '& > :last-child': {
        paddingLeft: '1rem',
      },
    },
  },
});

export interface DatePreviewListProps {
  schedules: Date[];
  monthLimit: number;
}

function DatePreviewList({ schedules, monthLimit }: DatePreviewListProps) {
  const classes = useStyles();
  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(0);
  const isMobile = useIsMobile();

  const dateCellRender = useCallback(
    (currentDate: moment.Moment) => {
      function doesDateHaveSchedules(dateImLookingFor: moment.Moment) {
        return schedules.some(schedule => {
          return dateImLookingFor.isSame(schedule.toString(), 'day');
        });
      }

      const hasSchedules = doesDateHaveSchedules(currentDate);
      const actualDate = moment();
      return (
        <div
          className={
            currentDate.isBefore(actualDate, 'day')
              ? classes.disabled
              : hasSchedules
              ? classNames(classes.bold)
              : classNames(classes.grey)
          }
        >
          {currentDate.format('D')}
        </div>
      );
    },
    [classes.bold, classes.grey, classes.disabled, schedules]
  );

  const prevMonth = useCallback(() => {
    if (currentCalendarMonth > 0) {
      setCurrentCalendarMonth(prevState => prevState - 1);
    }
  }, [currentCalendarMonth]);

  const nextMonth = useCallback(() => {
    setCurrentCalendarMonth(prevState => prevState + 1);
  }, []);

  const renderCalendar = useCallback(() => {
    if (isMobile) {
      return (
        <Calendar
          dateFullCellRender={dateCellRender}
          validRange={[
            moment().add(currentCalendarMonth, 'month').startOf('month'),
            moment().add(currentCalendarMonth, 'month').endOf('month'),
          ]}
          value={moment().add(currentCalendarMonth, 'month')}
          fullscreen={false}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            return (
              <div className={classes.dateHeader}>
                <Heading level={4}>
                  {moment()
                    .add(currentCalendarMonth, 'month')
                    .format('MMMM YYYY')}
                </Heading>
                <div>
                  {currentCalendarMonth === 0 ? (
                    <ArrowBackGrey className={classes.icon} />
                  ) : (
                    <ArrowBack
                      className={classes.icon}
                      onClick={() => {
                        prevMonth();
                      }}
                    />
                  )}
                  {currentCalendarMonth >= monthLimit ? (
                    <ArrowForwardGrey className={classes.icon} />
                  ) : (
                    <ArrowForwardBlack
                      className={classes.icon}
                      onClick={() => {
                        nextMonth();
                      }}
                    />
                  )}
                </div>
              </div>
            );
          }}
        />
      );
    } else {
      return (
        <>
          <div className={classes.btnsContainer}>
            {currentCalendarMonth === 0 ? (
              <ArrowBackGrey className={classes.icon} />
            ) : (
              <ArrowBack
                className={classes.icon}
                onClick={() => {
                  prevMonth();
                }}
              />
            )}
            {currentCalendarMonth >= monthLimit ? (
              <ArrowForwardGrey className={classes.icon} />
            ) : (
              <ArrowForwardBlack
                className={classes.icon}
                onClick={() => {
                  nextMonth();
                }}
              />
            )}
          </div>
          <div className={classes.calendarContainer}>
            <Calendar
              dateFullCellRender={dateCellRender}
              validRange={[
                moment().add(currentCalendarMonth, 'month').startOf('month'),
                moment().add(currentCalendarMonth, 'month').endOf('month'),
              ]}
              fullscreen={false}
              value={moment().add(currentCalendarMonth, 'month')}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                return (
                  <div className={classes.dateHeader}>
                    <Heading level={4}>
                      {moment()
                        .add(currentCalendarMonth, 'month')
                        .format('MMMM YYYY')}
                    </Heading>
                  </div>
                );
              }}
            />
            <Calendar
              dateFullCellRender={dateCellRender}
              validRange={[
                moment()
                  .add(currentCalendarMonth + 1, 'month')
                  .startOf('month'),
                moment()
                  .add(currentCalendarMonth + 1, 'month')
                  .endOf('month'),
              ]}
              fullscreen={false}
              value={moment().add(currentCalendarMonth + 1, 'month')}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                return (
                  <div className={classes.dateHeader}>
                    <Heading level={4}>
                      {moment()
                        .add(currentCalendarMonth + 1, 'month')
                        .format('MMMM YYYY')}
                    </Heading>
                  </div>
                );
              }}
            />
          </div>
        </>
      );
    }
  }, [
    isMobile,
    classes.dateHeader,
    classes.icon,
    currentCalendarMonth,
    dateCellRender,
    monthLimit,
    prevMonth,
    classes.btnsContainer,
    classes.calendarContainer,
    nextMonth,
  ]);

  return <div className={classes.datePreviewContainer}>{renderCalendar()}</div>;
}

export default React.memo(DatePreviewList);
