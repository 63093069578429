import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { HelpResponse, HelpType, useHelp } from '../../../hooks/help/useHelp';
import { Heading } from '../../Typography';
import { Loader } from '../Loader';
import { Modal } from '../Modal';

interface HelpModalProps {
  visible: boolean;
  onRequestClose: () => void;
}

const useStyles = makeStyles({
  modalPadder: {
    paddingTop: 20,
  },
});

const HelpModal = ({ visible, onRequestClose }: HelpModalProps) => {
  const [help, setHelp] = useState<HelpResponse>([]);
  const [isLoading] = useHelp(setHelp);
  const classes = useStyles();

  return (
    <Modal
      visible={visible}
      headerTitle={<Translate id="landing.help" />}
      variant="close"
      onRequestClose={onRequestClose}
      contentContainerClassName={classes.modalPadder}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {help.filter(h => h.type === HelpType.EMAIL).length > 0 && (
            <>
              <Heading level={5}>
                <Translate id="landing.emailContacts" />
              </Heading>
              {help
                .filter(h => h.type === HelpType.EMAIL)
                .map(h => {
                  return <a href={`mailto:${h.content}`}>{h.content}</a>;
                })}
            </>
          )}
          {help.filter(h => h.type === HelpType.PHONE_NUMBER).length > 0 && (
            <>
              <Heading level={5} style={{ marginTop: '0.5rem' }}>
                <Translate id="landing.phoneContacts" />
              </Heading>
              {help
                .filter(h => h.type === HelpType.PHONE_NUMBER)
                .map(h => {
                  return <a href={`tel:${h.content}`}>{h.content}</a>;
                })}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default React.memo(HelpModal);
