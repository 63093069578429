import Axios, { CancelToken, CancelTokenSource } from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';

export enum MemberType {
  PREMIUM = 'PREMIUM',
  INSTITUTIONAL = 'INSTITUTIONAL',
}

export interface MemberInterface {
  id: string;
  name: string;
  url: string;
  uniquepathUrl: string;
  type: MemberType;
}

type Hook = (
  setListMembers: (val: SetStateAction<MemberInterface[]>) => void,
  info: {
    search?: string;
  }
) => [boolean, boolean];

const requestMembers = (cancel: CancelToken | undefined, search: string) =>
  Axios.get<MemberInterface[]>(`${API_URL}/member/filter-members`, {
    params: {
      search,
    },
    cancelToken: cancel,
  });

export const useListAdminMembers: Hook = (setListMembers, { search }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestMembers(
          cancel.current?.token,
          search || ''
        );

        setListMembers(list);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setListMembers([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [dispatch, search, setListMembers]);

  return [isLoading, isError];
};
export const getMemberImage = (id: string) =>
  `${API_URL}/member/${id}/image?_=${moment().unix()}`;
