import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import { LogoUrl } from '../../../hooks/customization/getLogo';
import { desktopPadding, getTheme, headerHeight, mobilePadding, mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Button } from '../Button';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  header: { height: headerHeight, display: 'flex', justifyContent: 'center' },
  headerImage: {
    maxHeight: '100%',
  },
  body: {},
  centeredText: {
    textAlign: 'center',
  },
  subContainer: {
    ...mobilePadding,
    marginTop: '1rem',
    backgroundColor: getTheme().neutral6,
  },
  image: {
    maxWidth: '100%',
    marginBottom: '1rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    subContainer: {
      ...desktopPadding,
    },
    image: {
      maxWidth: '50%',
      alignSelf: 'center',
      borderRadius: 10,
    },
  },
});
interface ErrorProps extends LocalizeContextProps {
  title?: string;
  subTitle?: string;
  status?: '404' | undefined;
}

function Error({ title, status, subTitle, translate }: ErrorProps) {
  if (status === '404' && !subTitle) {
    subTitle = translate('error.notFoundSubtitle').toString();
  }
  if (!subTitle) {
    subTitle = translate('error.defaultSubtitle').toString();
  }

  if (!title) {
    title = translate('error.defaultTitle').toString();
  }
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Link to="/">
          <FallbackImage
            className={classes.headerImage}
            src={LogoUrl}
            fallback={require('../Header/assets/logo_cor.png')}
            alt="logo"
          />
        </Link>
      </div>
      <FallbackImage
        className={classes.image}
        src={require('../../../shared_assets/images/lost.png')}
        alt="lost"
      />
      <div className={classes.body}>
        <Heading level={3} className={classes.centeredText}>
          <Translate id="error.defaultTitle" />
        </Heading>
        <Text size={16} className={classes.centeredText}>
          <Translate id="error.defaultSubtitle" />
        </Text>
        <div className={classes.subContainer}>
          <Text weight="semibold">
            <Translate id="error.help" />
          </Text>
          <Link to="/">
            <Button type="link" onlyText>
              <Translate id="error.homepageLinkText" />
            </Button>
          </Link>
          <Link to="/">
            <Button type="link" onlyText>
              <Translate id="error.helpLinkText" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withLocalize(Error);
