import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import { motion } from 'framer-motion';
import React from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { complexTranslate, getTheme } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
      marginLeft: 0,
    },
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  bold: {
    fontWeight: '600 !important' as unknown as number,
  },
});

interface AgreementStepProps extends LocalizeContextProps {
  agreeTosAndPp: boolean;
  agreeGpsSharing: boolean;
  agreePhotoOwnership: boolean;
  setAgreeTosAndPp: (v: boolean) => void;
  setAgreeGpsSharing: (v: boolean) => void;
  setAgreePhotoOwnership: (v: boolean) => void;
}

function AgreementStep({
  translate,
  setAgreeGpsSharing,
  setAgreeTosAndPp,
  setAgreePhotoOwnership,
  agreeGpsSharing,
  agreePhotoOwnership,
  agreeTosAndPp,
}: AgreementStepProps) {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.mainContainer}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Heading disableAutoscale level={2} className={classes.bold}>
        <Translate id="routes.agreementTitle" />
      </Heading>
      <Heading level={5}>
        <Translate id="routes.agreementSubtitle" />
      </Heading>
      <Text color={getTheme().primary} weight="semibold" noMargin>
        <Translate id="routes.agreementParagraph1Title" />
      </Text>
      <Text>
        <Translate id="routes.agreementParagraph1Content" />
      </Text>
      <Text color={getTheme().primary} weight="semibold" noMargin>
        <Translate id="routes.agreementParagraph2Title" />
      </Text>
      <Text>
        {complexTranslate(
          translate('routes.agreementParagraph2Content').toString(),
          {
            '{SERVICE_TAX}': (
              <Link
                to="/terms-of-service#guide-financial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="link" onlyText className={classes.inlineBtn}>
                  <Translate id="routes.serviceTax" />
                </Button>
              </Link>
            ),
          }
        )}
      </Text>
      <Text color={getTheme().primary} weight="semibold" noMargin>
        <Translate id="routes.agreementParagraph3Title" />
      </Text>
      <Text>
        <Translate id="routes.agreementParagraph3Content" />
      </Text>
      <Divider />
      <Text>
        <Translate id="routes.analysisText" />
      </Text>
      <Text weight="bold">
        <Translate id="routes.confirmTitle" />
      </Text>
      <Checkbox
        onChange={e => setAgreeTosAndPp(e.target.checked)}
        checked={agreeTosAndPp}
      >
        <Text variant="faded" inline noMargin>
          {complexTranslate(translate('routes.agreeTerms').toString(), {
            '{TOS}': (
              <Link
                to="/terms-of-service#guide"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="link" onlyText className={classes.inlineBtn}>
                  <Translate id="routes.termsOfService" />
                </Button>
              </Link>
            ),
            '{POP}': (
              //FIXME: Is this right?
              <Link
                to="/terms-of-service#guide-financial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="link" onlyText className={classes.inlineBtn}>
                  <Translate id="routes.paymentPolicy" />
                </Button>
              </Link>
            ),
          })}
        </Text>
      </Checkbox>
      <Checkbox
        onChange={e => setAgreeGpsSharing(e.target.checked)}
        checked={agreeGpsSharing}
      >
        <Text variant="faded" inline noMargin>
          <Translate id="routes.agreeSharingGPS" />
        </Text>
      </Checkbox>
      <Checkbox
        onChange={e => setAgreePhotoOwnership(e.target.checked)}
        checked={agreePhotoOwnership}
      >
        <Text variant="faded" inline noMargin>
          <Translate id="routes.agreePhotoOwnership" />
        </Text>
      </Checkbox>
    </motion.div>
  );
}

export default withLocalize(AgreementStep);
