import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { RouteList } from './RouteList';
import { CreateNewRoute, CreateSuccess } from './RouteCreation';
import { RouteEdit } from './RouteEdit';

export default function MyRoutesRouter() {
  return (
    <Switch>
      <Route path="/guide/my-routes" exact component={RouteList} />,
      <Route path="/guide/my-routes/create/success" component={CreateSuccess} />
      <Route path="/guide/my-routes/create" component={CreateNewRoute} />
      <Route path="/guide/my-routes/edit/:routeId" component={RouteEdit} />
      <Redirect to="/guide/my-routes" />
    </Switch>
  );
}
