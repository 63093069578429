import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button } from '../../../components/App/Button';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import { Loader } from '../../../components/App/Loader';
import { Heading, Text } from '../../../components/Typography';
import {
  FinancialInterface,
  useFinancial,
} from '../../../hooks/administration/useFinancial';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useGuide } from '../../../hooks/guide/UseGuide';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { ReactComponent as Info } from '../../../shared_assets/icons/info.svg';
import { FinancialType } from '../../../types/financial.type';
import { Guide } from '../../../types/guide.interface';
import {
  desktopPadding,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& img, & svg': {
      height: 16,
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  row: {
    display: 'flex',
    '& > img, & svg': {
      marginRight: '1rem',
      height: '1.5rem',
    },
    marginBottom: '2rem',
  },
  centeredRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    margin: '1rem 0',
  },
  title: {
    marginBottom: '2rem !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.2,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.8,
    },
  },
});

export default function ProfilePersonal() {
  const classes = useStyles();
  const [guideProfile, setGuideProfile] = useState<Guide | null>(null);
  const [jwt] = UseJWT();
  const [isLoading, hasError] = useGuide(jwt?.guideId, setGuideProfile);
  const [financial, setFinancial] = useState<FinancialInterface | null>(null);
  useFinancial(setFinancial);

  const history = useHistory();

  const isMobile = useIsMobile();

  if (!jwt) {
    return <Redirect to="/guide" />;
  }

  if (!guideProfile || isLoading) {
    return <Loader />;
  }

  if (hasError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {isMobile && (
        <>
          <div className={classes.header}>
            <div onClick={history.goBack}>
              <ArrowBack />
            </div>
            <Link to="/guide/profile/public">
              <Button>
                <Translate id="profile.publicProfile" />
              </Button>
            </Link>
          </div>
          <Divider className={classes.headerDivider} />
        </>
      )}
      <div className={classNames(classes.body, classes.page)}>
        <Heading className={classes.title} level={2}>
          <Translate id="profile.myProfile" />
        </Heading>
        <div className={classes.bodyContainer}>
          {!isMobile && (
            <div className={classes.backZone}>
              <Link to="/guide/profile/public">
                <Button>
                  <Translate id="profile.publicProfile" />
                </Button>
              </Link>
            </div>
          )}
          <div className={classes.infoZone}>
            <div className={classes.row}>
              <Info />
              <div>
                <Text noMargin>
                  <Translate id="profile.personalInfo" />
                </Text>
                <Link to="/guide/profile/personal/edit">
                  <Button type="link" size="large" onlyText>
                    <Translate id="profile.editProfile" />
                  </Button>
                </Link>
              </div>
            </div>
            <Text weight="semibold" noMargin>
              <Translate id="profile.residence" />
            </Text>
            <Text noMargin variant="faded">
              {guideProfile.address}
            </Text>
            <Text noMargin variant="faded">
              {guideProfile.postalCode}
            </Text>
            <Text noMargin variant="faded">
              {guideProfile.location}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.email" />
            </Text>
            <Text variant="faded" noMargin>
              {guideProfile.email}
            </Text>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.birthDate" />
            </Text>
            <Text variant="faded" noMargin>
              {moment(guideProfile.dateOfBirth).format('L')}
            </Text>
            <Divider className={classes.divider} />
            <Text weight="semibold" noMargin>
              <Translate id="profile.phoneNumber" />
            </Text>
            <Text variant="faded">{guideProfile.phoneNumber}</Text>
            <Divider className={classes.divider} />
            <Text weight="semibold" noMargin>
              <Translate id="profile.gender" />
            </Text>
            <Text variant="faded" noMargin>
              <Translate id={`genders.${guideProfile.gender}`} />
            </Text>
            <Divider className={classes.divider} />
            <div className={classes.centeredRow}>
              <div>
                <Text weight="semibold" noMargin>
                  <Translate id="profile.password" />
                </Text>
                <Text variant="faded" noMargin>
                  ***************
                </Text>
              </div>
              <Link to="/guide/profile/personal/change-password">
                <Button type="ghost" extraWide>
                  <Translate id="profile.change" />
                </Button>
              </Link>
            </div>
            <Divider className={classes.divider} />
            <Text noMargin weight="semibold">
              <Translate id="profile.nationality" />
            </Text>
            <Text noMargin variant="faded">
              {guideProfile.nationality ? (
                <Translate id={`nationalities.${guideProfile.nationality}`} />
              ) : (
                <Translate id="notDefined" />
              )}
            </Text>
            <Divider className={classes.divider} />

            {guideProfile.financialType !== FinancialType.COMPANY ? (
              <>
                <Text weight="semibold" noMargin>
                  <Translate id="profile.iban" />
                </Text>
                <Text variant="faded">{guideProfile.iban}</Text>
                <Divider className={classes.divider} />
                <Text noMargin weight="semibold">
                  <Translate id="profile.nif" />
                </Text>
                <Text noMargin variant="faded">
                  {guideProfile.nif ? (
                    guideProfile.nif
                  ) : (
                    <Translate id="notDefined" />
                  )}
                </Text>
                <Divider className={classes.divider} />
                <Text noMargin weight="semibold">
                  <Translate id="profile.companyName" />
                </Text>
                <Text noMargin variant="faded">
                  {guideProfile.companyName ? (
                    guideProfile.companyName
                  ) : (
                    <Translate id="notDefined" />
                  )}
                </Text>
                <Divider className={classes.divider} />

                <Text weight="semibold" noMargin>
                  <Translate id="onboarding.ivaExemption" />
                </Text>
                <Text variant="faded">
                  {
                    financial?.ivas.find(
                      iva => iva.id === guideProfile.ivaExemptionId
                    )?.invoiceMention
                  }
                </Text>
                <Divider className={classes.divider} />
                <Text weight="semibold" noMargin>
                  <Translate id="onboarding.retention" />
                </Text>
                <Text variant="faded">
                  {
                    financial?.retentions.find(
                      ret => ret.id === guideProfile.retentionId
                    )?.invoiceMention
                  }
                </Text>
                <Divider className={classes.divider} />
              </>
            ) : null}

            <Divider className={classes.divider} />
          </div>
        </div>
      </div>
      <CopyrightFooter showSocialLinks />
    </motion.div>
  );
}
