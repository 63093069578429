import { makeStyles } from '@material-ui/styles';
import message from 'antd/es/message';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { finishRepetition } from '../../../hooks/routes/endRoute';
import {
  getRouteLocationImageUrl,
  useRoute,
} from '../../../hooks/routes/UseRoute';
import { useRepetitionBookings } from '../../../hooks/schedule/useRepetitionBookings';
import { RootState } from '../../../store';
import { Route } from '../../../types/route.interface';
import { ScheduleDateBookings } from '../../../types/ScheduleDateBookings.interface';
import { complexTranslate, mobileThreshhold } from '../../../utils';
import { Heading } from '../../Typography';
import { Button } from '../Button';
import { Modal } from '../Modal';
const useStyles = makeStyles({
  image: {
    maxWidth: '100%',
    minWidth: '100%',
  },
  center: {
    textAlign: 'center',
  },
  routeContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: '10px',
    height: 200,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: '1.5rem 0 3rem 0',
  },
  routeTextBody: {
    position: 'absolute',
    bottom: 0,
    padding: '1rem',
  },
  bold: {
    fontWeight: `600 !important` as unknown as number,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  paddingModal: {
    padding: 24,
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    routeContainer: {
      margin: '3rem 2rem',
      height: 300,
    },
    textInfo: {
      marginBottom: '3rem !important',
    },
  },
});

interface RouteOngoingModalProps extends LocalizeContextProps {
  visible: true;
  repetitionId: string;
  onFinish: () => void;
}

const RouteOngoingModal = ({
  repetitionId,
  translate,
  visible,
  onFinish,
}: RouteOngoingModalProps) => {
  const classes = useStyles();
  const [route, setRoute] = useState<Route | null>(null);
  const [repetition, setRepetition] = useState<ScheduleDateBookings | null>(
    null
  );
  const [repetitionIsLoading] = useRepetitionBookings(
    setRepetition,
    repetitionId
  );
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [routeIsLoading] = useRoute(repetition?.routeId, setRoute);

  const routeTitle = useMemo(() => {
    if (!route) return '';
    const translation = route.titles.find(t => t.language === activeLanguage);
    return translation
      ? translation.title
      : route.titles[0].title
      ? route.titles[0].title
      : '';
  }, [activeLanguage, route]);
  const endRoute = useCallback(() => {
    finishRepetition(repetitionId).then(() => {
      message.success(translate('ongoingRoute.endMessage').toString(), 5);
      onFinish();
    });
  }, [repetitionId, onFinish, translate]);

  return (
    <Modal
      disableNaturalPadding
      contentContainerClassName={classes.paddingModal}
      footer={null}
      visible={visible}
    >
      {route && repetition && !repetitionIsLoading && !routeIsLoading && (
        <>
          <div>
            <Heading className={classes.center} level={2}>
              <Translate id="ongoingRoute.title" />
            </Heading>
            <div
              className={classes.routeContainer}
              style={{
                backgroundImage: `url('${
                  route?.locations.some(loc => loc.photos.length > 0)
                    ? getRouteLocationImageUrl(
                        route.locations
                          .map(loc => loc.photos.map(photo => photo.id))
                          .flat()[0]
                      )
                    : require('../../../shared_assets/images/route-default.png')
                }')`,
              }}
            >
              <div className={classes.routeTextBody}>
                <Heading
                  color="white"
                  level={6}
                  className={classNames(classes.uppercase, classes.bold)}
                >
                  {route.location}
                </Heading>
                <Heading
                  className={classes.bold}
                  color="white"
                  ellipsis={{ rows: 4 }}
                  noMargin
                  level={5}
                >
                  {routeTitle}
                </Heading>
              </div>
            </div>
            <Heading
              level={5}
              className={classNames(classes.center, classes.textInfo)}
            >
              {complexTranslate(translate('ongoingRoute.text').toString(), {
                '{TIME}': moment(repetition.date)
                  .add({ minutes: route.duration })
                  .format('LT'),
              })}
            </Heading>
          </div>
          <div style={{ flex: 1 }}></div>
          <Button type="primary" size="large" onClick={endRoute}>
            <Translate id="ongoingRoute.endRoute" />
          </Button>
        </>
      )}
    </Modal>
  );
};

export default withLocalize(RouteOngoingModal);
