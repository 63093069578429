import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import validator from 'validator';
import isTaxId from 'validator/lib/isTaxID';
import { store } from '../../../store/index';
import { FormEntry, SettableFormEntry } from '../../../types/form.interface';
import { Guide } from '../../../types/guide.interface';

type PersonalProfileFormProperties =
  | 'address'
  | 'zipFirstSegment'
  | 'zipSecondSegment'
  | 'gender'
  | 'nationality'
  | 'companyName'
  | 'nif'
  | 'iban'
  | 'location'
  | 'phoneNumber'
  | 'birthDay'
  | 'birthMonth'
  | 'birthYear'
  | 'retention'
  | 'ivaExemption';

export type PersonalProfileForm = Record<
  PersonalProfileFormProperties,
  SettableFormEntry
>;

export const usePersonalProfileForm = (
  guide?: Guide | null
): PersonalProfileForm => {
  const translate = useMemo(() => getTranslate(store.getState().localize), []);

  const [address, setAddress] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badAddress').toString(),
  });
  const [zipFirstSegment, setZipFirstSegment] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badZip1NoLength').toString(),
  });

  const [zipSecondSegment, setZipSecondSegment] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badZip2NoLength').toString(),
  });

  const [gender, setGender] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.genderMandatory').toString(),
  });

  const [nationality, setNationality] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.nationalityMandatory').toString(),
  });

  const [companyName, setCompanyName] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.companyNameMandatory').toString(),
  });

  const [nif, setNif] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badNif').toString(),
  });

  const [ivaExemption, setIvaExemption] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.ivaExemptionNotSelected').toString(),
  });
  const [retention, setRetention] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.retentionNotSelected').toString(),
  });
  const [iban, setIban] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badIban').toString(),
  });

  const [location, setLocation] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badLocation').toString(),
  });

  const [phoneNumber, setPhoneNumber] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.badPhoneNumber').toString(),
  });

  const [birthDay, setBirthDay] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.birthDateMandatory').toString(),
  });

  const [birthMonth, setBirthMonth] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.birthDateMandatory').toString(),
  });

  const [birthYear, setBirthYear] = useState<FormEntry>({
    value: '',
    isInvalid: true,
    reason: translate('profile.birthDateMandatory').toString(),
  });

  const setValidatedNationality = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.nationalityMandatory').toString();
      }

      setNationality({ value, isInvalid, reason });
    },
    [setNationality, translate]
  );

  const setValidatedAddress = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badAddress').toString();
      }

      setAddress({ value, isInvalid, reason });
    },
    [setAddress, translate]
  );

  const setValidatedZipFirstSegment = useCallback(
    (value: string) => {
      const isInvalid = value.length !== 4;
      let reason: string | undefined = undefined;

      if (isInvalid) {
        reason = translate('profile.badZip1NoLength').toString();
      }

      setZipFirstSegment({ value, isInvalid, reason });
    },
    [setZipFirstSegment, translate]
  );

  const setValidatedZipSecondFragment = useCallback(
    (value: string) => {
      const isInvalid = !value || value.length !== 3;
      let reason: string | undefined = undefined;

      if (isInvalid) {
        reason = translate('profile.badZip2NoLength').toString();
      }

      setZipSecondSegment({ value, isInvalid, reason });
    },
    [setZipSecondSegment, translate]
  );

  const setValidatedGender = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.genderMandatory').toString();
      }

      setGender({ value, isInvalid, reason });
    },
    [setGender, translate]
  );

  const setValidatedCompanyName = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;

      if (isInvalid) {
        reason = translate('profile.companyNameMandatory').toString();
      }

      setCompanyName({ value, isInvalid, reason });
    },
    [translate]
  );

  const setValidatedIban = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badIban').toString();
      }

      setIban({ value, isInvalid, reason });
    },
    [setIban, translate]
  );

  const setValidatedNif = useCallback(
    (value: string) => {
      const isInvalid = !isTaxId(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badNif').toString();
      }

      setNif({ value, isInvalid, reason });
    },
    [setNif, translate]
  );
  const setValidateIvaExemption = useCallback(
    (value: string) => {
      const isInvalid = value === null;
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.ivaExemptionNotSelected').toString();
      }

      setIvaExemption({ value, isInvalid, reason });
    },
    [translate]
  );
  const setValidateRetention = useCallback(
    (value: string) => {
      const isInvalid = value === null;
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.retentionNotSelected').toString();
      }

      setRetention({ value, isInvalid, reason });
    },
    [translate]
  );
  const setValidatedLocation = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.mandatoryLocation').toString();
      }

      setLocation({ value, isInvalid, reason });
    },
    [setLocation, translate]
  );

  const setValidatedPhoneNumber = useCallback(
    (value: string) => {
      const isInvalid = !validator.isMobilePhone(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.badPhoneNumber').toString();
      }

      setPhoneNumber({ value, isInvalid, reason });
    },
    [setPhoneNumber, translate]
  );

  const setValidatedBirthDay = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value) || !validator.isNumeric(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.birthDateMandatory').toString();
      }

      setBirthDay({ value, isInvalid, reason });
    },
    [setBirthDay, translate]
  );

  const setValidatedBirthMonth = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value) || !validator.isNumeric(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.birthDateMandatory').toString();
      }

      setBirthMonth({ value, isInvalid, reason });
    },
    [setBirthMonth, translate]
  );

  const setValidatedBirthYear = useCallback(
    (value: string) => {
      const isInvalid = validator.isEmpty(value) || !validator.isNumeric(value);
      let reason: string | undefined = undefined;
      if (isInvalid) {
        reason = translate('profile.birthDateMandatory').toString();
      }

      setBirthYear({ value, isInvalid, reason });
    },
    [setBirthYear, translate]
  );

  useEffect(() => {
    if (!guide) return;

    setValidatedAddress(guide.address);
    setValidatedCompanyName(guide.companyName);
    setValidatedGender(guide.gender);
    setValidatedIban(guide.iban);
    setValidatedLocation(guide.location);
    setValidatedNationality(guide.nationality);
    setValidatedNif(guide.nif.toString());
    setValidatedPhoneNumber(guide.phoneNumber.toString());
    setValidatedZipFirstSegment(guide.postalCode.split('-')[0]);
    setValidatedZipSecondFragment(guide.postalCode.split('-')[1]);
    setValidateIvaExemption(guide.ivaExemptionId);
    setValidateRetention(guide.retentionId);
  }, [
    guide,
    setValidatedNationality,
    setValidatedNif,
    setValidatedPhoneNumber,
    setValidatedZipFirstSegment,
    setValidatedZipSecondFragment,
    setValidatedAddress,
    setValidatedCompanyName,
    setValidatedGender,
    setValidatedIban,
    setValidatedLocation,
    setValidateIvaExemption,
    setValidateRetention,
  ]);

  return {
    address: {
      ...address,
      set: setValidatedAddress,
    },
    zipFirstSegment: {
      ...zipFirstSegment,
      set: setValidatedZipFirstSegment,
    },
    zipSecondSegment: {
      ...zipSecondSegment,
      set: setValidatedZipSecondFragment,
    },
    gender: {
      ...gender,
      set: setValidatedGender,
    },
    nationality: {
      ...nationality,
      set: setValidatedNationality,
    },
    companyName: {
      ...companyName,
      set: setValidatedCompanyName,
    },
    nif: {
      ...nif,
      set: setValidatedNif,
    },
    ivaExemption: {
      ...ivaExemption,
      set: setValidateIvaExemption,
    },
    retention: {
      ...retention,
      set: setValidateRetention,
    },
    iban: {
      ...iban,
      set: setValidatedIban,
    },
    location: {
      ...location,
      set: setValidatedLocation,
    },
    phoneNumber: {
      ...phoneNumber,
      set: setValidatedPhoneNumber,
    },
    birthDay: { ...birthDay, set: setValidatedBirthDay },
    birthMonth: { ...birthMonth, set: setValidatedBirthMonth },
    birthYear: { ...birthYear, set: setValidatedBirthYear },
  };
};
