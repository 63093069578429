import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
import { JoinUsInterface } from './usePostGuideSelection';

type Hook = (
  setJoinUsTexts: (val: SetStateAction<JoinUsInterface[]>) => void
) => [boolean, boolean];

export const requestRoutes = () =>
  Axios.get(`${API_URL}/content-management/guide-landing-page/join-us-texts`);

export const useJoinUs: Hook = setJoinUsTexts => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: joinUs } = await requestRoutes();
        setJoinUsTexts(joinUs as JoinUsInterface[]);
      } catch (err) {
        setJoinUsTexts([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setJoinUsTexts]);

  return [isLoading, isError];
};
