import React from 'react';
import { Translate } from 'react-localize-redux';

export const getMajorTranslation = (major: number) => {
  switch (major) {
    case 0:
      return <Translate id="routes.create1stStepTitle" />;
    case 1:
      return <Translate id="routes.create2ndStepTitle" />;
    case 2:
      return <Translate id="routes.create3rdStepTitle" />;
    default:
      return null;
  }
};

export const getMinorTranslation = (major: number, minor: number) => {
  switch (major) {
    case 0:
      switch (minor) {
        case 0:
          return <Translate id="routes.aboutYou" />;
        default:
          return null;
      }
    case 1:
      switch (minor) {
        case 0:
          return <Translate id="routes.locationTitle" />;
        case 1:
          return <Translate id="routes.descriptionTitle" />;
        case 2:
          return <Translate id="routes.visitorInfo" />;
        case 3:
          return <Translate id="routes.routeTitleTitle" />;
        default:
          return null;
      }
    case 2:
      switch (minor) {
        case 0:
          return <Translate id="routes.acceptTerms" />;
        default:
          return null;
      }
    default:
      return null;
  }
};
