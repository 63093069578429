import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { mobileThreshhold } from '../../../utils';
import { Button } from '../../App/Button';
import { Text } from '../../Typography';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '10rem',
    alignItems: 'center',
    borderRadius: '25px',
    borderStyle: 'solid',
    padding: '1rem',
    border: '2px',
  },
  imageContainer: {
    flex: '0.25',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  image: {
    maxHeight: '10rem',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  textArea: {
    flex: '0.75',
    marginLeft: '0rem',
    '& a': {
      justifyContent: 'unset',
    },
    paddingTop: '2rem',
    flexDirection: 'column',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    mainContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    textArea: {
      marginLeft: '2rem',
      paddingLeft: '1rem',
    },
  },
});

interface MemberCardProps {
  name: string;
  url: string;
  image: string;
  uniquePathUrl?: string;
}

function MemberCard({ name, url, image, uniquePathUrl }: MemberCardProps) {
  const classes = useStyles();

  return (
    <div className={classNames(classes.mainContainer)}>
      <div className={classNames(classes.imageContainer)}>
        <FallbackImage
          className={classNames(classes.image)}
          alt="logo"
          src={image}
          fallback={require('../../../shared_assets/images/alt_white.png')}
        />
      </div>

      <div className={classNames(classes.textArea)}>
        <Text weight="bold" noMargin>
          {name}
        </Text>
        <Button type="link" onlyText href={`https://${url}`} target="_blank">
          {url}
        </Button>
        {uniquePathUrl ? (
          <Button
            type="link"
            onlyText
            target="_blank"
            href={`https://${uniquePathUrl}`}
          >
            {uniquePathUrl}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default MemberCard;
