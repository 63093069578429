import { makeStyles } from '@material-ui/styles';
import { Marker } from '@react-google-maps/api';
import Divider from 'antd/es/divider';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useLocation } from 'react-router-dom';
import { CopyrightFooter } from '../../components/App/CopyrightFooter';
import { GoogleMap } from '../../components/App/GoogleMap';
import { LinkSection } from '../../components/App/LinkSection';
import { Loader } from '../../components/App/Loader';
import { Heading } from '../../components/Typography';
import {
  HelpResponse as ContactsResponse,
  HelpType,
  useHelp as useContacts,
} from '../../hooks/help/useHelp';
import { ReactComponent as FacebookFilled } from '../../shared_assets/icons/facebook_filled.svg';
import { ReactComponent as InstagramFilled } from '../../shared_assets/icons/instagram_filled.svg';
import { ReactComponent as YoutubeFilled } from '../../shared_assets/icons/youtube_filled.svg';
import { desktopPadding, mobilePadding, mobileThreshhold } from '../../utils';
import { GenericPageTransition } from '../PageUtils';
const useStyles = makeStyles({
  map: {
    marginBottom: '0.3rem',
    minHeight: 400,
  },
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  divider: {
    marginBottom: '2rem',
  },
  introZone: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  contactsContainer: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
  },
  bottomDivider: {
    margin: '1rem 0 0 0',
  },

  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      ...desktopPadding,
    },
    extraPadding: {
      margin: '0 25%',
    },
    stepsContainer: {
      display: 'flex',
    },
    introZone: {
      '& > .text-zone': {
        alignSelf: 'center',
        borderRadius: 16,
      },
    },
  },
  socialMediaContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
    '& img, & svg': {
      marginRight: '2rem',
      height: '2rem',
      width: '2rem',
      color: '#b0bec5',
    },
    '& > :last-child': {
      '& img, & svg': {
        marginRight: 'unset',
      },
    },
  },
});

interface RouteState {
  redirectTo?: string;
}

const ValidRedirections = ['/guide/landing', '/visitor/landing'];

interface ContactsProps extends LocalizeContextProps {}

const Contacts = ({ translate }: ContactsProps) => {
  const headerSize = 4;

  const classes = useStyles();
  const location = useLocation<RouteState | undefined>();
  const [contacts, setContacts] = useState<ContactsResponse>([]);
  const [isLoading] = useContacts(setContacts);

  if (
    !location.state ||
    !location.state.redirectTo ||
    !ValidRedirections.includes(location.state.redirectTo)
  ) {
    //This cannot be in the state since it will come from an outside source
    //history.goBack();
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <motion.div
      className={classes.page}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <div className={classes.introZone}>
        <div>
          <GoogleMap
            mapContainerClassName={classes.map}
            zoom={15}
            center={{
              lat: 38.73726603584888,
              lng: -9.305034130541127,
            }}
            key={'google_maps_map'}
          >
            <Marker
              position={{
                lat: 38.73726603584888,
                lng: -9.305034130541127,
              }}
              title={`Municípia, E.M., S.A.`}
            />
          </GoogleMap>
        </div>
      </div>
      <hr className={classes.divider} />
      <div className={classes.container}>
        <Heading level={headerSize}>
          <Translate id="contactsPage.contactsTittle" />
        </Heading>
        <hr className={classes.divider} />
        {contacts.filter(c => c.type === HelpType.ADDRESS).length > 0 && (
          <>
            <Heading level={5}>
              <Translate id="contactsPage.address" />
            </Heading>
            {contacts
              .filter(c => c.type === HelpType.ADDRESS)
              .map(c => {
                return (
                  <a
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                    href={`http://maps.google.com/?q=1200:${c.content}`}
                  >
                    {c.content}
                  </a>
                );
              })}
          </>
        )}
        <hr className={classes.divider} />
        {contacts.filter(c => c.type === HelpType.PHONE_NUMBER).length > 0 && (
          <>
            <Heading level={5} style={{ marginTop: '0.5rem' }}>
              <Translate id="contactsPage.phone" />
            </Heading>
            {contacts
              .filter(c => c.type === HelpType.PHONE_NUMBER)
              .map(c => {
                return <a href={`tel:${c.content}`}>{c.content}</a>;
              })}
          </>
        )}

        <hr className={classes.divider} />
        {contacts.filter(c => c.type === HelpType.EMAIL).length > 0 && (
          <>
            <Heading level={5}>
              <Translate id="contactsPage.email" />
            </Heading>
            {contacts
              .filter(c => c.type === HelpType.EMAIL)
              .map(c => {
                return <a href={`mailto:${c.content}`}>{c.content}</a>;
              })}
          </>
        )}

        <div style={{ marginTop: '2rem' }}>
          <Heading level={5} style={{ marginTop: '0.5rem' }}>
            <Translate id="contactsPage.socialMedia" />
          </Heading>
          <div className={classes.socialMediaContainer}>
            <a href="https://www.facebook.com/uniquePATHS.eu/">
              <FacebookFilled />
            </a>
            <a href="https://www.youtube.com/channel/UCfXl3n5d5uaVL7s8eLkHoww">
              <YoutubeFilled />
            </a>
            <a href="https://www.instagram.com/uniquepaths.eu/">
              <InstagramFilled />
            </a>
          </div>
        </div>
      </div>

      <Divider />

      <LinkSection />
      <Divider className={classes.bottomDivider} />
      <div style={{ flex: 1 }}></div>
      <CopyrightFooter showSocialLinks={true} />
    </motion.div>
  );
};

export default withLocalize(Contacts);
