import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as WarningYellow } from '../../../../shared_assets/icons/warning_yellow.svg';
import { RootState } from '../../../../store';
import { ScheduleCanceledNotification } from '../../../../types/notification.interface';
import { complexTranslate } from '../../../../utils';
import { Heading, Text } from '../../../Typography';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { NotificationsProps } from '../Notification';

interface NotificationScheduleCancellationProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: ScheduleCanceledNotification;
}

const NotificationScheduleCancellation: React.FC<NotificationScheduleCancellationProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
}) => {
  const history = useHistory();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const { seen, type, routeTitles, scheduleDate, routeId } = notification;
  //complexTranslate('dfssdf {arg}', { '{arg}': moment().format('LL') });

  const routeTitle = useMemo(() => {
    const translation = routeTitles.find(t => t.language === activeLanguage);
    return translation ? translation.title : routeTitles[0].title;
  }, [routeTitles, activeLanguage]);
  return (
    <>
      {!simpleVersion && (
        <div className={classes.rowDirection}>
          <WarningYellow className={classes.icon} />
          <Heading level={5} noMargin>
            {translate(`notificationType.${type}`).toString()}
          </Heading>
          {!seen && (
            <Badge
              className={classNames(classes.marginLeft, classes.uppercase)}
              type="primary"
              small
            >
              {translate('notifications.new').toString()}
            </Badge>
          )}
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        <Text variant={!simpleVersion ? 'faded' : 'default'} noMargin>
          {complexTranslate(
            translate('notifications.scheduleCancellation').toString(),
            {
              '{routeTitle}': (
                <Text className={classes.inlineBtn} noMargin>
                  {routeTitle}
                </Text>
              ),
              '{date}': (
                <Text className={classes.inlineBtn} noMargin>
                  {moment(scheduleDate).format('DD MMMM YYYY')}
                </Text>
              ),
            }
          )}
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="link"
          onlyText
          onClick={() => {
            history.push(`/route/${routeId}`);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.seeRoute').toString()}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default withLocalize(NotificationScheduleCancellation);
