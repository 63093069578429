import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Button from '../../../components/App/Button/Button';
import { CopyrightFooter } from '../../../components/App/CopyrightFooter';
import { Error } from '../../../components/App/Error';
import InformationPopup from '../../../components/App/InformationPopup/InformationPopup';
import { Loader } from '../../../components/App/Loader';
import PublicProfile from '../../../components/App/PublicProfile/PublicProfile';
import { Heading, Text } from '../../../components/Typography';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useGuideReviews } from '../../../hooks/guide/review/UseGuideReviews';
import { useGuidePublicRoutes } from '../../../hooks/guide/UseGuidePublicRoutes';
import { usePublicGuide } from '../../../hooks/guide/UsePublicGuide';
import { PartialRouteInterface } from '../../../hooks/routes/UseSimilarRoutes';
import { ReactComponent as ArrowBack } from '../../../shared_assets/icons/arrow back.svg';
import { ReactComponent as User } from '../../../shared_assets/icons/user.svg';
import { RootState } from '../../../store';
import { GuidePublicProfile } from '../../../types/guide-public-profile.interface';
import { GuideStateType } from '../../../types/guide-state-type.enum';
import { Review } from '../../../types/review.interface';
import {
  desktopPadding,
  headerHeight,
  mobilePadding,
  mobileThreshhold,
  useIsMobile,
} from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },
  noMargin: {
    margin: 0,
  },
  introIcon: {
    marginRight: '1rem',
  },
  row: {
    display: 'flex',
    '& > img, & > svg': {
      marginRight: '1rem',
      height: '2rem',
    },
  },
  fitImage: {
    maxWidth: '100%',
  },
  header: {
    height: headerHeight,
    maxHeight: headerHeight,
    display: 'flex',
    padding: ' 0 5%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& img, & svg': {
      height: 16,
    },
  },
  headerDivider: {
    marginTop: 0,
  },
  title: {
    marginBottom: '2rem !important',
  },
  backZone: { flex: 0.2 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    infoZone: { flex: 0.8 },
  },
});

function PublicProfilePreview({ translate }: LocalizeContextProps) {
  const classes = useStyles();
  const [guideProfile, setGuideProfile] = useState<GuidePublicProfile | null>(
    null
  );
  const [jwt] = UseJWT();
  const [isLoading, hasError] = usePublicGuide(jwt?.guideId, setGuideProfile);
  const history = useHistory();
  const [routes, setRoutes] = useState<PartialRouteInterface[]>([]);
  const [areRoutesLoading, routeHaveError] = useGuidePublicRoutes(
    jwt?.guideId,
    setRoutes
  );
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewsAreLoading] = useGuideReviews(jwt?.guideId, setReviews);

  const isMobile = useIsMobile();

  const isApproved = useSelector(
    (state: RootState) => state.app.lastGuideState === GuideStateType.APPROVED
  );

  if (!jwt) {
    return <Redirect to="/guide" />;
  }

  if (isLoading || areRoutesLoading || reviewsAreLoading) {
    return <Loader />;
  }

  if ((!guideProfile || hasError || routeHaveError) && isApproved)
    return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {isMobile && (
        <>
          <div className={classes.header}>
            <div onClick={history.goBack}>
              <ArrowBack />
            </div>
            <Link to="/guide/profile/personal">
              <Button>
                <Translate id="profile.personalData" />
              </Button>
            </Link>
          </div>
          <Divider className={classes.headerDivider} />
        </>
      )}
      <div className={classNames(classes.body, classes.page)}>
        <Heading className={classes.title} level={2}>
          <Translate id="profile.myPublicProfile" />
        </Heading>
        <div className={classes.contentContainer}>
          {!isMobile && (
            <div className={classes.backZone}>
              <Link to="/guide/profile/personal">
                <Button>
                  <Translate id="profile.personalData" />
                </Button>
              </Link>
            </div>
          )}
          <div className={classes.infoZone}>
            <div className={classes.row}>
              <User />
              <div>
                <Text noMargin>
                  <Translate id="profile.myPublicProfile" />
                </Text>
                <Text noMargin variant="faded" size={12}>
                  <Translate id="profile.publicProfileExplanation" />
                </Text>
                <Link to="/guide/profile/public/edit">
                  <Button type="link" size="large" onlyText>
                    <Translate id="profile.editMyPublicProfile" />
                  </Button>
                </Link>
              </div>
            </div>
            <Divider />
            {isApproved && guideProfile ? (
              <PublicProfile
                reviews={reviews}
                guide={guideProfile}
                guideRoutes={routes}
              />
            ) : (
              <InformationPopup
                visible
                message={translate('profile.notApproved').toString()}
                type="info"
              />
            )}
          </div>
        </div>
      </div>
      <CopyrightFooter showSocialLinks />
    </motion.div>
  );
}

export default withLocalize(PublicProfilePreview);
