import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { API_URL } from '../../consts';

export interface VisitorLandingPageInterface {
  firstTitle: string;

  intro: string;

  experiencesTitle: string;

  featuredRoutesTitle: string;

  interestTitle: string;

  joinCommunityTitle: string;

  joinCommunityText: string;

  callToActionUrl: string;

  language: string;

  searchTitle: string;

  searchSubtitle: string;

  searchPlaceholder: string;

  howToStartSubtitle: string;
  howToStartTitle: string;
}
export interface LandingPageDto {
  landing: VisitorLandingPageInterface[];
}

type Hook = (
  setListLandingPageInformation: (
    val: SetStateAction<VisitorLandingPageInterface[]>
  ) => void
) => [boolean, boolean];

const requestLandingPageInformation = () =>
  Axios.get<VisitorLandingPageInterface[]>(
    `${API_URL}/content-management/visitor-landing-page`
  );

export const useVisitorLandingPage: Hook = setVisitorLandingPage => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestLandingPageInformation();

        setVisitorLandingPage(list);
        setIsError(false);
      } catch (err) {
        setVisitorLandingPage([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setVisitorLandingPage]);

  return [isLoading, isError];
};
export const getHeaderImageUrl = () =>
  `${API_URL}/content-management/visitor-landing-page/header-image?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
