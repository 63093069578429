import { makeStyles } from '@material-ui/styles';
import message from 'antd/es/message';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import validator from 'validator';
import { Error } from '../../../components/App/Error';
import FallbackImage from '../../../components/App/Image/FallbackImage';
import { Loader } from '../../../components/App/Loader';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { LogoUrl } from '../../../hooks/customization/getLogo';
import { editGuide } from '../../../hooks/guide/EditGuide';
import { useGuide } from '../../../hooks/guide/UseGuide';
import { useLanguages } from '../../../hooks/guide/UseLanguages';
import { useSkills } from '../../../hooks/guide/UseSkills';
import { RootState } from '../../../store';
import { FinancialType } from '../../../types/financial.type';
import { GenderType } from '../../../types/gender-type.enum';
import { GuideDescriptionDto } from '../../../types/guide.dtos';
import { Guide } from '../../../types/guide.interface';
import { headerHeight, useIsMobile } from '../../../utils';
import { GenericPageTransition } from '../../PageUtils';
import StepManager from './StepManager';
import AddressStep from './steps/AddressStep';
import BioStep from './steps/BioStep';
import FinalStep from './steps/FinalStep';
import FinancialStep from './steps/FinancialStep';
import IntroStep from './steps/IntroStep';
import LanguageStep from './steps/LanguageStep';
import PersonalStep from './steps/PersonalStep';
import SkillStep from './steps/SkillStep';
import { useOnboardingForm } from './useOnboardingForm';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  header: {
    textAlign: 'center',
  },
  logo: {
    maxHeight: headerHeight,
  },
});

interface OnboardingProps extends LocalizeContextProps {
  onFinishOnboarding: (guide: Guide) => void;
}

function Onboarding({ onFinishOnboarding, translate }: OnboardingProps) {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [languages] = useLanguages();
  const [skills] = useSkills(activeLanguage);

  const introMatch = useRouteMatch('/guide/onboarding/intro');
  const languagesMatch = useRouteMatch('/guide/onboarding/languages');

  const addressMatch = useRouteMatch('/guide/onboarding/address');

  const skillsMatch = useRouteMatch('/guide/onboarding/skills');
  const financialMatch = useRouteMatch('/guide/onboarding/financial');
  const personalMatch = useRouteMatch('/guide/onboarding/personal');
  const bioMatch = useRouteMatch('/guide/onboarding/bio');
  const finishMatch = useRouteMatch('/guide/onboarding/finish');

  const totalSteps = 8;
  let step = 0;

  if (introMatch) {
    step = 1;
  } else if (languagesMatch) {
    step = 2;
  } else if (skillsMatch) {
    step = 3;
  } else if (addressMatch) {
    step = 4;
  } else if (financialMatch) {
    step = 5;
  } else if (personalMatch) {
    step = 6;
  } else if (bioMatch) {
    step = 7;
  } else if (finishMatch) {
    step = 8;
  } else {
    step = 0;
  }

  const [guideProfile, setGuideProfile] = useState<Guide | null>(null);
  const form = useOnboardingForm();
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [certificates, setCertificates] = useState<File[]>([]);
  const [descriptions, setDescriptions] = useState<GuideDescriptionDto[]>([]);
  const [jwt] = UseJWT();
  const [onboardingDone, setOnboardingDone] = useState<boolean>(false);
  const history = useHistory();
  const [isLoading, hasError] = useGuide(jwt?.guideId, setGuideProfile);

  useEffect(() => {
    //Do once! redirect to first page, do not allow a user to open any subroute without a previous render, in other words, always start at the first step
    history.replace('/guide/onboarding');
  }, [history]);

  //Quando mudam linguas cria descrições novas mas mantem as existentes
  useEffect(() => {
    setDescriptions(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, description: '' })),
    ]);
  }, [selectedLanguages]);

  const finishOnboarding = useCallback(async () => {
    try {
      if (!jwt) {
        return;
      }

      if (
        Object.values(form).find(entry => {
          if (entry.isInvalid) {
            message.error(entry.reason);
            return true;
          }
          return false;
        })
      ) {
        return;
      }

      if (selectedLanguages.length === 0) {
        message.error(translate('onboarding.mandatoryLanguages').toString());
        return;
      }

      if (descriptions.some(desc => validator.isEmpty(desc.description))) {
        message.error(translate('onboarding.bioMandatory').toString());
        return;
      }
      const { data: newGuide } = await editGuide(
        jwt.guideId,
        {
          address: form.address.value,
          companyName: form.companyName.value,
          gender: form.gender.value as GenderType,
          nationality: form.nationality.value,
          skills: selectedSkills.map(s => ({
            skillType: s,
            rating: 0,
          })),
          iban: form.iban.value,
          nif: form.nif.value,
          location: form.location.value,
          languages: selectedLanguages.map(l => ({
            language: l,
            rating: 0,
          })),
          postalCode: `${form.zipFirstSegment.value}-${form.zipSecondSegment.value}`,
          descriptions: descriptions,
          phoneNumber: form.phoneNumber.value.slice(-9),
          onboarding: true,
          financialType: form.financialType.value as FinancialType,
          ivaExemption: form.ivaExemption.value,
          retention: form.retention.value,
          company: form.company.value,
        },
        avatar,
        certificates
      );

      onFinishOnboarding(newGuide);

      history.replace('/guide/onboarding/finish');
      setOnboardingDone(true);
    } catch (err) {}
  }, [
    form,
    certificates,
    avatar,
    selectedLanguages,
    selectedSkills,
    descriptions,
    jwt,
    history,
    onFinishOnboarding,
    translate,
  ]);

  if (!languages || !skills || !guideProfile || isLoading) {
    return <Loader />;
  }

  if (hasError) return <Error />;

  if (guideProfile.onboarding) history.replace('/guide');

  if (onboardingDone && !finishMatch)
    //No going back after it's done
    return <Redirect to="/guide/onboarding/finish" />;
  //TODO: reverter
  const nextStep = () => {
    if (introMatch) {
      history.replace('/guide/onboarding/languages');
    } else if (languagesMatch) {
      history.replace('/guide/onboarding/skills');
    } else if (skillsMatch) {
      history.replace('/guide/onboarding/address');
    } else if (addressMatch) {
      history.replace('/guide/onboarding/financial');
    } else if (financialMatch) {
      history.replace('/guide/onboarding/personal');
    } else if (personalMatch) {
      history.replace('/guide/onboarding/bio');
    } else if (bioMatch) {
      history.replace('/guide/onboarding/finish');
    } else if (finishMatch) {
      history.replace('/guide');
    } else {
      history.replace('/guide/onboarding/');
    }
  };

  const previousStep = () => {
    if (introMatch) {
      history.replace('/guide/onboarding/');
    } else if (languagesMatch) {
      history.replace('/guide/onboarding/intro');
    } else if (skillsMatch) {
      history.replace('/guide/onboarding/languages');
    } else if (addressMatch) {
      history.replace('/guide/onboarding/skills');
    } else if (financialMatch) {
      history.replace('/guide/onboarding/address');
    } else if (personalMatch) {
      history.replace('/guide/onboarding/financial');
    } else if (bioMatch) {
      history.replace('/guide/onboarding/personal');
    } else if (finishMatch) {
      history.replace('/guide/onboarding/bio');
    } else {
      history.replace('/guide/onboarding/');
    }
  };

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      {isMobile ? (
        <StepManager step={step} totalSteps={totalSteps} />
      ) : (
        <div className={classes.header}>
          <FallbackImage
            className={classes.logo}
            alt="logo"
            src={LogoUrl}
            fallback={require('../../../components/App/Header/assets/logo_cor.png')}
          />
          <StepManager step={step} totalSteps={totalSteps} />
        </div>
      )}
      <Switch>
        <Route
          path="/guide/onboarding/intro"
          render={() => <IntroStep onRequestNextStep={nextStep} />}
        />

        <Route
          path="/guide/onboarding/languages"
          render={() => (
            <LanguageStep
              remoteLanguages={languages}
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
              onRequestPreviousStep={previousStep}
              onRequestNextStep={nextStep}
            />
          )}
        />
        <Route
          path="/guide/onboarding/skills"
          render={() => (
            <SkillStep
              selectedSkills={selectedSkills}
              setSelectedSkills={setSelectedSkills}
              onRequestPreviousStep={previousStep}
              onRequestNextStep={nextStep}
            />
          )}
        />
        <Route
          path="/guide/onboarding/address"
          render={() => (
            <AddressStep
              form={form}
              onRequestPreviousStep={previousStep}
              onRequestNextStep={nextStep}
            />
          )}
        />
        <Route
          path="/guide/onboarding/financial"
          render={() => (
            <FinancialStep
              form={form}
              certificates={certificates}
              setCertificates={setCertificates}
              onRequestPreviousStep={previousStep}
              onRequestNextStep={nextStep}
            />
          )}
        />
        <Route
          path="/guide/onboarding/personal"
          render={() => (
            <PersonalStep
              avatar={avatar}
              setAvatar={setAvatar}
              form={form}
              onRequestPreviousStep={previousStep}
              onRequestNextStep={nextStep}
              translate={translate}
            />
          )}
        />
        <Route
          path="/guide/onboarding/bio"
          render={() => (
            <BioStep
              descriptions={descriptions}
              setDescriptions={setDescriptions}
              selectedLanguages={selectedLanguages}
              onRequestPreviousStep={previousStep}
              onRequestNextStep={finishOnboarding}
            />
          )}
        />
        <Route
          path="/guide/onboarding/finish"
          render={() => <FinalStep onRequestNextStep={nextStep} />}
        />
        <Redirect to="/guide/onboarding/intro" />
      </Switch>
    </motion.div>
  );
}

export default withLocalize(Onboarding);
