import { makeStyles } from '@material-ui/styles';
import { Divider, Space, Table } from 'antd';
import Button from 'antd/es/button';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';
import { Input } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import {
  IntermediateListInterface,
  useIntermediates,
} from '../../../../../hooks/administration/useIntermediates';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  filterZone: {
    '& > *:nth-child(2)': { flexGrow: 1, marginLeft: '1rem' },
    display: 'flex',
  },
});

const columns = [
  {
    title: <Translate id="adminstration.name" />,
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: <Translate id="adminstration.gender" />,
    dataIndex: 'gender',
    key: 'gender',
  },

  {
    title: <Translate id="adminstration.email" />,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <Translate id="adminstration.phoneNumber" />,
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: <Translate id="adminstration.companyName" />,
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: <Translate id="adminstration.nif" />,
    dataIndex: 'nif',
    key: 'nif',
  },
  {
    title: <Translate id="adminstration.actions" />,
    key: 'action',
    render: (text: any, record: any) => (
      <Space size="middle">
        <Link to={`/admin/area/intermediate/${record.id}`}>
          <Translate id="adminstration.showInformation" />
        </Link>
        <Divider type="vertical" />

        <Link to={`/admin/area/intermediate/${record.id}/list/guides`}>
          <Translate id="adminstration.showLinkedGuides" />
        </Link>
      </Space>
    ),
  },
];

const ListIntermediates = ({ translate }: LocalizeContextProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [intermediates, setListIntermediates] = useState<
    IntermediateListInterface[]
  >([]);
  const [search, setSearch] = useState<string>('');

  const dto = useMemo(
    () => ({
      search,
    }),
    [search]
  );
  const [fetchingIntermediates] = useIntermediates(
    setListIntermediates,
    dto,
    true
  );

  const createIntermediateCallback = useCallback(() => {
    history.push('/admin/area/create-intermediate');
  }, [history]);
  return (
    <>
      <div className={classes.divAddButton}>
        <div className={classes.filterZone}>
          <Input
            value={search}
            placeholder={translate('adminstration.search').toString()}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            createIntermediateCallback();
          }}
        >
          <Translate id="adminstration.createIntermediate" />
        </Button>
      </div>

      {fetchingIntermediates ? (
        <Loader />
      ) : (
        <Table dataSource={intermediates} columns={columns} />
      )}
    </>
  );
};

export default withLocalize(ListIntermediates);
