import { makeStyles } from '@material-ui/styles';
import Badge from 'antd/es/badge';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import { getVisitorImageUrl } from '../../../hooks/visitor/UseVisitor';
import { ReactComponent as CanceledIcon } from '../../../shared_assets/icons/canceled.svg';
import { ReactComponent as CompletedIcon } from '../../../shared_assets/icons/complete_full.svg';
import { ReactComponent as Visitante } from '../../../shared_assets/images/visitante_default.svg';
import { complexTranslate, getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { FallbackAvatar } from '../Avatar';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
    '&:last-child': { marginBottom: 0 },
    '& .ant-badge svg': {
      bottom: 0,
      top: 'initial',
      transform: 'unset',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& > *': {
      marginBottom: 0,
    },
  },
  avatar: {
    marginRight: '1rem',
  },
  statusMarker: {
    height: 14,
    width: 14,
    backgroundColor: 'white',
    borderRadius: 14,
  },
});

interface BookingEntryProps extends LocalizeContextProps {
  groupSize: number;
  visitorName: string;
  canceled: boolean;
  visitorId: string;
}

function BookingEntry({
  visitorName,
  groupSize,
  visitorId,
  canceled,
  translate,
}: BookingEntryProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Badge
        style={{ bottom: -7, right: 7, top: 'unset' }}
        className={classes.avatar}
        count={
          canceled ? (
            <CanceledIcon className={classes.statusMarker} />
          ) : (
            <CompletedIcon className={classes.statusMarker} />
          )
        }
      >
        <FallbackAvatar
          src={
            <FallbackImage
              src={getVisitorImageUrl(visitorId)}
              fallbackComponent={<Visitante />}
            />
          }
        />
      </Badge>
      <div className={classes.column}>
        <Text noMargin weight="semibold">
          {visitorName}
        </Text>
        <Text variant="note" noMargin color={getTheme().neutral1}>
          {/* {canceledAt
            ? complexTranslate(translate('schedules.canceledTime').toString(), {
                '{TIME}': moment
                  .duration(moment(canceledAt).diff(moment()))
                  .humanize(true),
              }) */}
          {groupSize > 1 &&
            complexTranslate(
              translate('schedules.bookingGroupSize').toString(),
              { '{NUM}': groupSize }
            )}
        </Text>
      </div>
    </div>
  );
}

export default withLocalize(BookingEntry);
