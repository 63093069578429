import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';
export enum RelevanceType {
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
}

interface IvaInterface {
  id: string;
  code: string;
  invoiceMention: string;
  applicableStandard: string;
  value: number;
  relevance: RelevanceType;
  listOrder: number;
}

interface RetentionInterface {
  id: string;
  code: string;
  invoiceMention: string;
  applicableStandard: string;
  value: number;
  relevance: RelevanceType;
  listOrder: number;
}

export interface FinancialInterface {
  ivas: IvaInterface[];
  retentions: RetentionInterface[];
}

type Hook = (
  setFinancial: (val: SetStateAction<FinancialInterface | null>) => void
) => [boolean, boolean];

export const requestFinancial = () =>
  Axios.get<FinancialInterface>(`${API_URL}/financial`);

export const useFinancial: Hook = setFinancial => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: financialInterface } = await requestFinancial();
        setFinancial(financialInterface);
      } catch (err) {
        setFinancial(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setFinancial]);

  return [isLoading, isError];
};
