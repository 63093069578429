import { makeStyles } from '@material-ui/styles';
import Collapse from 'antd/es/collapse';
import moment, { Moment } from 'moment';
import React, { SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import {
  AccessibilityWithSingleTranslation,
  useAccessibilities,
} from '../../../hooks/accessibilities/useAccessibilities';
import { useLanguages } from '../../../hooks/guide/UseLanguages';
import { useRouteValues } from '../../../hooks/routes/useRouteValues';
import {
  RouteSubjectWithSingleTranslation,
  useSubjects,
} from '../../../hooks/subjects/useSubjects';
import { ReactComponent as CloseBlue } from '../../../shared_assets/icons/close_blue.svg';
import { FilterRoute } from '../../../types/filter-route.dto';
import { DifficultyType } from '../../../types/route-difficulty.enum';
import { RouteStartingTimeType } from '../../../types/route-starting-time.enum';
import { Text } from '../../Typography';
import { Button } from '../Button';
import { Calendar } from '../Calendar';
import { Checkbox } from '../Checkbox';
import { InputNumber } from '../InputNumber';
import { Loader } from '../Loader';
import PlaceSearchBox, { ReturnedPlace } from '../RouteForm/PlaceSearchBox';
import { Option, Select } from '../Select';
import { Slider } from '../Slider';

const useStyles = makeStyles({
  page: {
    '& .ant-collapse-borderless': {
      backgroundColor: 'transparent',
    },
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .ant-checkbox-wrapper': {
      marginLeft: 0,
    },
  },
  select: {
    width: '100%',
  },
  headerText: {
    // fontWeight: ('600 !important' as unknown) as number,
  },
  leftAlignedCheckBoxContainer: {
    display: 'flex',
    padding: '0.5rem 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *:first-child': {
      paddingTop: 0,
    },
  },
  clearButton: {
    marginBottom: '1rem',
    marginLeft: 16,
    marginTop: 16,
  },
});

const { Panel } = Collapse;

type AccordionKey = keyof FilterRoute;

interface RouteFilterComponentProps extends LocalizeContextProps {
  filter: FilterRoute;
  setFilter: (v: SetStateAction<FilterRoute>) => void;
  allOpen?: boolean;
  setPlaceSearchText: (v: string) => void;
  placeSearchText: string;
  language: string;
}

const RouteFilterComponent: React.FC<RouteFilterComponentProps> = ({
  filter,
  translate,
  setFilter,
  allOpen,
  setPlaceSearchText,
  placeSearchText,
  language,
}) => {
  const [openKey, setOpenKey] = useState<string[] | string | undefined>(
    allOpen
      ? [
          'subjects',
          'languages',
          'accessibilitiesIds',
          'locations',
          'dates',
          'start',
          'duration',
          'price',
          'maxGroupSize',
          'difficulty',
          'accessibilities',
        ]
      : undefined
  );
  const [subjects, setSubjects] = useState<RouteSubjectWithSingleTranslation[]>(
    []
  );
  const [accessibilities, setAccessibilities] = useState<
    AccessibilityWithSingleTranslation[]
  >([]);
  const classes = useStyles();
  const [subjectsAreLoading] = useSubjects(setSubjects, language, true);
  const [accessibilitiesAreLoading] = useAccessibilities(setAccessibilities);
  const [languages] = useLanguages();
  const [routeValues, routeValuesAreLoading] = useRouteValues();

  const getPopupContainer = useCallback((trigger: HTMLElement) => {
    let temp = trigger.parentElement;

    while (temp) {
      if (temp.parentElement?.className.includes('ant-modal'))
        return (temp as HTMLElement) || document.body;
      else temp = temp.parentElement;
    }

    return (
      (document.getElementById('root')?.children[0] as HTMLElement) ||
      document.body
    );
  }, []);

  const calendarSchedules = useMemo(
    () => filter.schedules?.map(sch => moment(sch)) || [],
    [filter.schedules]
  );

  const calendarOnChange = useCallback(
    (newDates: Moment[]) => {
      setFilter(prevFilter => ({
        ...prevFilter,
        schedules: newDates.map(date => date.toDate()),
      }));
    },
    [setFilter]
  );

  const placeSelected = useCallback(
    (place: ReturnedPlace | undefined) => {
      if (!place || !place.geometry) {
        setFilter(prevFilters => ({
          ...prevFilters,
          latitude: undefined,
          longitude: undefined,
        }));
        setPlaceSearchText('');
      } else {
        setFilter(prevFilters => ({
          ...prevFilters,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        }));

        setPlaceSearchText(place.name);
      }
    },
    [setFilter, setPlaceSearchText]
  );
  return (
    <div className={classes.page}>
      {subjectsAreLoading ||
      accessibilitiesAreLoading ||
      routeValuesAreLoading ||
      !routeValues ? (
        <Loader />
      ) : (
        <>
          {Object.keys(filter).length > 0 && (
            <Button
              type="ghost"
              className={classes.clearButton}
              onClick={() => setFilter({})}
            >
              <Text variant="link" inline noMargin>
                <span>
                  <Translate id="search.clearFilter" />
                </span>
                <CloseBlue />
              </Text>
            </Button>
          )}
          <Collapse
            bordered={false}
            accordion={!allOpen}
            expandIconPosition="right"
            activeKey={openKey}
            onChange={newKey => setOpenKey(newKey as AccordionKey)}
          >
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.subjects" />
                </Text>
              }
              key="subjects"
            >
              <Select
                getPopupContainer={getPopupContainer}
                mode="multiple"
                huge
                value={filter.subjectsIds}
                className={classes.select}
                placeholder={translate('search.subjects')}
                onChange={ev => {
                  setFilter(prevFilter => ({
                    ...prevFilter,
                    subjectsIds: ev as unknown as string[],
                  }));
                }}
              >
                {subjects.map(sub => (
                  <Option key={sub.id} value={sub.id} label={sub.name}>
                    <Text inline>{sub.name}</Text>
                  </Option>
                ))}
              </Select>
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.languages" />
                </Text>
              }
              key="languages"
            >
              <Select
                getPopupContainer={getPopupContainer}
                className={classes.select}
                value={filter.languages}
                huge
                placeholder={translate('search.languages').toString()}
                onChange={ev => {
                  setFilter(prevFilter => ({
                    ...prevFilter,
                    languages: ev as unknown as string[],
                  }));
                }}
              >
                {languages.map(lang => (
                  <Option
                    key={lang}
                    value={lang}
                    label={translate(`languages.${lang}`).toString()}
                  >
                    <Text inline>
                      <Translate id={`languages.${lang}`} />
                    </Text>
                  </Option>
                ))}
              </Select>
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.location" />
                </Text>
              }
              key="locations"
            >
              <PlaceSearchBox
                onPlaceSelected={placeSelected}
                value={placeSearchText}
                onChange={setPlaceSearchText}
                clearable
              />
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.dates" />
                </Text>
              }
              key="dates"
            >
              <Calendar
                disablePreviousMonths
                noPadding
                noShadow
                simple
                value={calendarSchedules}
                onChange={calendarOnChange}
              />
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.start" />
                </Text>
              }
              key="start"
            >
              <div className={classes.filterContainer}>
                {Object.keys(RouteStartingTimeType).map(s => (
                  <div key={s} className={classes.leftAlignedCheckBoxContainer}>
                    <div>
                      <Text noMargin>
                        <Translate id={`RouteStartingTimeType.${s}`} />
                      </Text>
                      <Text variant="faded" noMargin>
                        <Translate id={`RouteStartingTimeType.${s}Label`} />
                      </Text>
                    </div>
                    <Checkbox
                      key={s}
                      checked={(filter.start || []).includes(
                        s as RouteStartingTimeType
                      )}
                      onChange={ev => {
                        if (ev.target.checked)
                          setFilter(prevFilter => ({
                            ...prevFilter,
                            start: [
                              ...(prevFilter.start || []),
                              s as RouteStartingTimeType,
                            ],
                          }));
                        else
                          setFilter(prevFilter => ({
                            ...prevFilter,
                            start: (prevFilter.start || []).filter(
                              id => id !== s
                            ),
                          }));
                      }}
                    />
                  </div>
                ))}
              </div>
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.duration" />
                </Text>
              }
              key="duration"
            >
              <Text>{`${
                filter.minDuration === undefined
                  ? Math.floor(routeValues.minDuration / 60)
                  : Math.floor(filter.minDuration / 60)
              }h - ${
                filter.maxDuration === undefined
                  ? Math.round(routeValues.maxDuration / 60)
                  : Math.round(filter.maxDuration / 60)
              }h`}</Text>
              <Slider
                range
                max={Math.round(routeValues.maxDuration / 60)}
                min={Math.floor(routeValues.minDuration / 60)}
                value={[
                  filter.minDuration === undefined
                    ? Math.floor(routeValues.minDuration / 60)
                    : Math.floor(filter.minDuration / 60),
                  filter.maxDuration === undefined
                    ? Math.round(routeValues.maxDuration / 60)
                    : Math.round(filter.maxDuration / 60),
                ]}
                onChange={newValues => {
                  setFilter(prevFilter => ({
                    ...prevFilter,
                    maxDuration: (newValues as number[])[1] * 60,
                    minDuration: (newValues as number[])[0] * 60,
                  }));
                }}
              />
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.price" />
                </Text>
              }
              key="price"
            >
              <Text>{`${
                filter.minPrice === undefined
                  ? Math.floor(routeValues.minPrice)
                  : filter.minPrice
              } - ${
                filter.maxPrice === undefined
                  ? Math.ceil(routeValues.maxPrice)
                  : filter.maxPrice
              }`}</Text>
              <Slider
                range
                min={Math.floor(routeValues.minPrice)}
                max={Math.ceil(routeValues.maxPrice)}
                value={[
                  filter.minPrice === undefined
                    ? Math.floor(routeValues.minPrice)
                    : filter.minPrice,
                  filter.maxPrice === undefined
                    ? Math.ceil(routeValues.maxPrice)
                    : filter.maxPrice,
                ]}
                onChange={newValues => {
                  setFilter(prevFilter => ({
                    ...prevFilter,
                    maxPrice: (newValues as number[])[1],
                    minPrice: (newValues as number[])[0],
                  }));
                }}
              />
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.groupSize" />
                </Text>
              }
              key="maxGroupSize"
            >
              <InputNumber
                placeholder={translate('search.groupSize').toString()}
                huge
                positive
                value={filter.groupSize}
                min={1}
                onChange={ev => {
                  setFilter(prevFilter => ({
                    ...prevFilter,
                    groupSize: ev,
                  }));
                }}
              />
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.difficulty" />
                </Text>
              }
              key="difficulty"
            >
              <Select
                getPopupContainer={getPopupContainer}
                className={classes.select}
                huge
                mode="multiple"
                value={filter.difficulties || []}
                onChange={newDifficulties =>
                  setFilter(prevFilter => ({
                    ...prevFilter,
                    difficulties:
                      newDifficulties as unknown as DifficultyType[],
                  }))
                }
                placeholder={translate('search.difficulty').toString()}
              >
                {Object.keys(DifficultyType).map(d => (
                  <Option
                    key={d}
                    value={d}
                    label={translate(`difficulties.${d}`).toString()}
                  >
                    <Text noMargin>
                      <Translate id={`difficulties.${d}`} />
                    </Text>
                  </Option>
                ))}
              </Select>
            </Panel>
            <Panel
              header={
                <Text weight="bold" noMargin className={classes.headerText}>
                  <Translate id="search.accessibilities" />
                </Text>
              }
              key="accessibilities"
            >
              {accessibilities.map(s => (
                <div
                  key={s.id}
                  className={classes.leftAlignedCheckBoxContainer}
                >
                  <Text inline noMargin>
                    {s.name}
                  </Text>

                  <Checkbox
                    checked={(filter.accessibilitiesIds || []).includes(s.id)}
                    onChange={ev => {
                      if (ev.target.checked)
                        setFilter(prevFilter => ({
                          ...prevFilter,
                          accessibilitiesIds: [
                            ...(prevFilter.accessibilitiesIds || []),
                            s.id,
                          ],
                        }));
                      else
                        setFilter(prevFilter => ({
                          ...prevFilter,
                          accessibilitiesIds: (
                            prevFilter.accessibilitiesIds || []
                          ).filter(id => id !== s.id),
                        }));
                    }}
                  ></Checkbox>
                </div>
              ))}
            </Panel>
          </Collapse>
        </>
      )}
    </div>
  );
};

export default React.memo(withLocalize(RouteFilterComponent));
