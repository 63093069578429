import { makeStyles } from '@material-ui/styles';
import { Space } from 'antd';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import React, { useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import Loader from 'react-spinners/BeatLoader';
import { Button } from '../../../../components/App/Button';
import { Modal } from '../../../../components/App/Modal';
import { AddHelpResponse } from '../../../../hooks/help/addHelp';
import { removeHelp } from '../../../../hooks/help/removeHelp';
import { HelpResponse, useHelp } from '../../../../hooks/help/useHelp';
import HelpModal from './HelpModal';

const useStyles = makeStyles({
  addDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },
  header: {
    paddingTop: 20,
  },
});

const Help = () => {
  const [help, setHelp] = useState<HelpResponse>([]);
  const classes = useStyles();
  const [isLoading] = useHelp(setHelp);

  const [adding, setAdding] = useState<boolean>(false);
  const [editing, setEditing] = useState<string | undefined>();
  const [removing, setRemoving] = useState<string | undefined>();

  const closeAdding = useCallback(() => {
    setAdding(false);
  }, []);

  const onAdd = useCallback((newHelp: AddHelpResponse) => {
    setHelp(prevHelp => [...prevHelp, newHelp]);
    setAdding(false);
  }, []);

  const onEdit = useCallback((newHelp: AddHelpResponse) => {
    setHelp(prevHelp => prevHelp.map(h => (h.id === newHelp.id ? newHelp : h)));
    setEditing(undefined);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: <Translate id="adminstration.helpZone.content" />,
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: <Translate id="adminstration.helpZone.type" />,
        dataIndex: 'type',
        key: 'type',
        render: (text: string) => (
          <Translate id={`adminstration.helpZone.types.${text}`} />
        ),
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <HelpModal
              helpContent={record}
              onEdit={onEdit}
              visible={record.id === editing}
              onRequestClose={() => {
                setEditing(undefined);
              }}
            />
            <Button
              type="link"
              onClick={() => {
                setEditing(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.edit" />
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                setRemoving(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.remove" />
            </Button>
          </Space>
        ),
      },
    ],
    [editing, onEdit]
  );

  const [submitting, setSubmitting] = useState<boolean>(false);

  const onDelete = useCallback(async () => {
    try {
      setSubmitting(true);
      if (removing) await removeHelp(removing);
      setRemoving(undefined);
      setHelp(prev => prev.filter(h => h.id !== removing));
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [removing]);

  return (
    <div>
      <Modal
        visible={!!removing}
        onRequestClose={() => {
          setRemoving(undefined);
        }}
        footer={
          <div className={classes.footer}>
            <Button
              disabled={submitting}
              onClick={() => setRemoving(undefined)}
            >
              <Translate id="button.cancel" />
            </Button>
            <Button disabled={submitting} onClick={onDelete}>
              <Translate id="button.remove" />
            </Button>
          </div>
        }
        contentContainerClassName={classes.header}
      >
        <Translate id="adminstration.helpZone.removeConfirmation" />
      </Modal>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.addDiv}>
            <Button
              type="primary"
              onClick={() => {
                setAdding(true);
              }}
            >
              <Translate id="adminstration.helpZone.add" />
            </Button>
          </div>
          <HelpModal
            onAdd={onAdd}
            onRequestClose={closeAdding}
            visible={adding}
          />
          <Table columns={columns} dataSource={help} />
        </>
      )}
    </div>
  );
};

export default React.memo(Help);
