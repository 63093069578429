import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

export interface ManageWhatWeGiveInterface {
  firstTitle: string;
  text1: string;
  text2: string;
  secondTitle: string;
  text3: string;
  text4: string;

  language: string;
}
export interface StepInterface {
  title: string;
  position: number;
  description: string;
  language: string;
}
export interface WhatWeGiveInterface {
  whatWeGiveTexts: ManageWhatWeGiveInterface[];
  position0: StepInterface[];
  position1: StepInterface[];
  position2: StepInterface[];
  position3: StepInterface[];
}
type Hook = (
  setLookFor: (val: SetStateAction<WhatWeGiveInterface | null>) => void
) => [boolean, boolean];

export const requestList = () =>
  Axios.get<WhatWeGiveInterface>(
    `${API_URL}/content-management/guide-landing-page/what-we-give`
  );

export const useWhatWeGive: Hook = setWhatWeGive => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: whatWeGive } = await requestList();
        setWhatWeGive(whatWeGive as WhatWeGiveInterface);
      } catch (err) {
        setWhatWeGive(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setWhatWeGive]);

  return [isLoading, isError];
};
