import { useState, useEffect } from 'react';
import moment from 'moment';

export const GetYears = (future?: number): number[] => {
  const year: number[] = [];

  for (
    var i = 1900;
    i <= (future ? moment().year() + future : moment().year());
    i++
  ) {
    year.push(i);
  }
  return year;
};

export const GetMonths = (): string[] => {
  return moment.months();
};

type Hook = (month: string | undefined, year: string | undefined) => number[];

export const useDay: Hook = (month, year) => {
  const [days, setDays] = useState<number[]>([]);
  useEffect(() => {
    const tmp: number[] = [];
    const yearValue = year || moment().year();

    let monthDays = 31;
    if (month && year) {
      monthDays = moment(
        `${yearValue}-${parseInt(month || '0') + 1}`,
        'YYYY-MM'
      ).daysInMonth();
    }

    for (let i = 1; i <= monthDays; i++) {
      tmp.push(i);
    }
    setDays(tmp);
  }, [month, year]);

  return days;
};
