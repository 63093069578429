import message from 'antd/es/message';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Error } from '../../../../components/App/Error';
import { Loader } from '../../../../components/App/Loader';
import { RouteForm } from '../../../../components/App/RouteForm';
import { RouteFormResult } from '../../../../components/App/RouteForm/RouteForm';
import { EditRoute } from '../../../../hooks/routes/EditRoute';
import { useRoute } from '../../../../hooks/routes/UseRoute';
import { RouteStateType } from '../../../../types/route-state.enum';
import { Route } from '../../../../types/route.interface';

interface RouteParams {
  routeId: string;
}

function CreateNewRoute() {
  const params = useParams<RouteParams>();
  const history = useHistory();
  const [route, setRoute] = useState<Route | null>(null);
  const [routeIsLoading, routeHasError] = useRoute(params.routeId, setRoute);

  const submitNewRoute = useCallback(
    (status: RouteStateType) => (form: RouteFormResult) => {
      if (!route) return;

      EditRoute(
        {
          accessibilityIds: form.accessibilityIds,
          activities: form.activities.map(act => ({
            ...act,
          })),
          agreeTerms:
            form.agreeGpsSharing &&
            form.agreePhotoOwnership &&
            form.agreeTosAndPp,
          difficulty: form.selectedDifficulty,
          duration: form.selectedDuration,
          extraItems: form.extraItems.map(item => ({
            ...item,
          })),
          groupAge: form.selectedAgeGroup,
          groupSize: form.maxVisitors,
          location: form.routeLocation,
          locations: form.pointLocations.map((pL, i) => ({
            ...pL,
            order: i,
          })),
          languages: form.selectedLanguages.map(lang => ({ language: lang })),
          routeExperience: form.routeExperienceType,
          subjectIds: form.selectedSubjects,
          titles: form.routeTitles,
          descriptions: form.routeDescriptions,
          state: status,
          latitude: form.routeLatitude,
          longitude: form.routeLongitude,
          routeLength: form.routeLength,
        },
        route
      )
        .then(res => {
          if (status === RouteStateType.DRAFT) history.goBack();
          else history.replace('/guide/my-routes/create/success');
        })
        .catch(err => {
          message.error(err.toString());
        });
    },
    [history, route]
  );

  if (routeIsLoading) return <Loader />;
  if (routeHasError) return <Error />;

  return (
    <RouteForm
      route={route || undefined}
      onFinish={submitNewRoute(RouteStateType.PENDING_APPROVAL)}
      onSave={submitNewRoute(RouteStateType.DRAFT)}
    />
  );
}

export default CreateNewRoute;
