import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { getTheme, mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';

const useListStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      flexDirection: 'row',
      '& > :first-child': {
        marginLeft: 0,
      },
      '& > :last-child': {
        marginRight: 0,
      },
    },
  },
});

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  step: () => ({
    height: '3rem',
    width: '3rem',
    borderRadius: '3rem',
    border: `1px solid ${getTheme().primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
  }),
  number: () => ({
    textAlign: 'center',
    marginBottom: 0,
    color: `${getTheme().primary} !important`,
    fontWeight: ('500 !important' as unknown) as number,
  }),
  image: {
    display: 'flex',
    alignSelf: 'center',
    width: '65vw',
    height: '23vh',
    margin: '1rem',
    borderRadius: '10px',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      margin: '0 1rem',
      '&:not(:first-child)': {
        marginTop: 0,
      },
    },
    image: {
      width: '100%',
      height: '30vh',
    },
  },
});

export interface StepProps {
  title: string;
  position?: number;
  number?: number;
  image?: string;
  description: string;
}

export interface StepListProps {
  data: StepProps[];
}

export function StepList({ data }: StepListProps) {
  const classes = useListStyles();
  const sortedData = data.sort(
    (a: StepProps, b: StepProps) =>
      (a.position || a.number || 0) - (b.position || b.number || 0)
  );
  return (
    <div className={classes.container}>
      {sortedData.map((step, i) => (
        <Step key={i.toString()} {...step} />
      ))}
    </div>
  );
}

export function Step({
  title,
  position,
  image,
  description,
  number,
}: StepProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.step}>
        <Heading level={4} noMargin className={classes.number}>
          {position || number}
        </Heading>
      </div>
      <Heading level={4}>{title}</Heading>
      <Text variant="faded" noMargin>
        {description}
      </Text>
      {image && (
        <div
          style={{ backgroundImage: `url('${image}')` }}
          className={classes.image}
        ></div>
      )}
    </div>
  );
}
