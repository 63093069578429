import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Carousel } from 'react-responsive-carousel';

import { LogoUrl } from '../../../hooks/customization/getLogo';
import { GenericPageTransition } from '../../../pages/PageUtils';
import { ReactComponent as ArrowBackBlue } from '../../../shared_assets/icons/arrow_back_blue.svg';
import { ReactComponent as Next } from '../../../shared_assets/icons/next.svg';
import { desktopPadding, getTheme, headerHeight, mobilePadding, mobileThreshhold, useIsMobile } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Button } from '../Button';
import { FallbackImage } from '../Image';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    textAlign: 'center',
  },
  body: {
    alignItems: 'initial',
  },
  carousel: () => ({
    width: '100vw',
    maxWidth: '100vw',
    height: '100%',
    '& > *': {
      height: 'inherit',
      '& > .slider-wrapper': {
        height: 'inherit',
        '& > *': {
          height: 'inherit',
          '& > *': {
            height: 'inherit',
            '& > *': {
              height: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
          },
        },
      },
    },
    '& .control-dots': {
      top: 0,
      bottom: 'unset',
      '& > *': {
        height: 8,
        width: 8,
        margin: '8px 1rem',
        borderRadius: '100%',
        borderWidth: 2,
        backgroundColor: 'transparent',
        display: 'inline-block',
        borderColor: 'white',
        borderStyle: 'solid',
        cursor: 'pointer',
        '&.active': {
          backgroundColor: getTheme().primary,
          borderColor: getTheme().primary,
        },
      },
    },
    '& .slide': {
      backgroundColor: 'transparent',
      '& .content': {
        paddingTop: '1rem',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        '& > ul': {
          alignSelf: 'center',
        },
        ...mobilePadding,
      },
    },
  }),
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100vw',
    textAlign: 'center',
  },
  logo: {
    maxHeight: headerHeight,
  },
  standOutBackground: {
    backgroundColor: '#ffffff',
  },
  fillWidth: {
    width: '100%',
    height: '45vh',
    objectFit: 'cover',
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    height: '70px',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
    bottom: '0',
    left: '0',
    right: '0',
    '& > *': {
      margin: '1rem',
    },
  },
  btnRight: {
    marginLeft: 'auto',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    title: {
      marginBottom: '2rem !important',
    },
    fillWidth: {
      maxWidth: '50%',
      minWidth: '50%',
      maxHeight: '50%',
      minHeight: '50%',
      objectFit: 'cover',
      borderRadius: 10,
    },
    bottomContainer: {
      boxShadow: 'none',
      position: 'relative',
    },
    container: {
      textAlign: 'unset',
    },
  },
});

interface RouteIntroProps extends LocalizeContextProps {
  onFinish: () => void;
}

function RouteIntro({ translate, onFinish }: RouteIntroProps) {
  const classes = useStyles();
  const [slide, setSlide] = useState(0);

  const isMobile = useIsMobile();

  const moveBack = useCallback(() => {
    setSlide(prevslide => --prevslide);
  }, []);

  const moveForward = useCallback(() => {
    if (slide !== 4) {
      setSlide(prevslide => ++prevslide);
    } else {
      onFinish();
    }
  }, [onFinish, slide]);

  const navigation = useMemo(
    () => (
      <>
        <div style={{ flex: 1 }} />
        <div
          className={classNames(
            classes.bottomContainer,
            classes.standOutBackground
          )}
        >
          <Button
            type="ghost"
            size="large"
            hidden={slide === 0}
            onClick={moveBack}
            prefix={<ArrowBackBlue />}
          >
            <Translate id="routeIntro.previous" />
          </Button>
          <Button
            className={classes.btnRight}
            type="primary"
            size="large"
            onClick={moveForward}
            suffix={<Next />}
          >
            {slide === 4
              ? translate('routeIntro.create').toString()
              : translate('routeIntro.next').toString()}
          </Button>
        </div>
      </>
    ),
    [slide, translate, classes, moveForward, moveBack]
  );

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.header}>
        <FallbackImage
          className={classes.logo}
          alt="logo"
          src={LogoUrl}
          fallback={require('../Header/assets/logo_cor.png')}
        />
      </div>

      <div className={classNames(classes.container, classes.body)}>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          selectedItem={slide}
          className={classes.carousel}
          renderIndicator={(onClick, isSelected) => (
            <div
              onClick={onClick}
              className={isSelected ? 'active' : undefined}
            />
          )}
        >
          <div>
            <img
              alt="placeholder"
              src={require('./assets/step1.jpg')}
              className={classNames(classes.fillWidth)}
            />
            <div className={'content'}>
              <Heading level={4} className={classes.title}>
                <Translate id="routeIntro.intro" />
              </Heading>
              <Text noMargin>
                <Translate id="routeIntro.satisfied" />
              </Text>
              <Text>
                <Translate id="routeIntro.introInfo" />
              </Text>
              {!isMobile && navigation}
            </div>
          </div>
          <div>
            <img
              alt="placeholder"
              src={require('./assets/step2.jpg')}
              className={classNames(classes.fillWidth)}
            />
            <div className={'content'}>
              <Heading level={4} className={classes.title}>
                <Translate id="routeIntro.questionRouteOffer" />
              </Heading>
              <Text>
                <Translate id="routeIntro.answerRouteOffer" />
              </Text>
              {!isMobile && navigation}
            </div>
          </div>
          <div>
            <img
              alt="placeholder"
              src={require('./assets/step3.jpg')}
              className={classNames(classes.fillWidth)}
            />
            <div className={'content'}>
              <Heading level={4} className={classes.title}>
                <Translate id="routeIntro.questionGuideOffer" />
              </Heading>
              <Text>
                <Translate id="routeIntro.answerGuideOffer" />
              </Text>
              {!isMobile && navigation}
            </div>
          </div>
          <div>
            <img
              alt="placeholder"
              src={require('./assets/step4.jpg')}
              className={classNames(classes.fillWidth)}
            />
            <div className={'content'}>
              <Heading level={4} className={classes.title}>
                <Translate id="routeIntro.questionGuideOfferMore" />
              </Heading>
              <Text>
                <Translate id="routeIntro.answerGuideOfferMore" />
              </Text>
              {!isMobile && navigation}
            </div>
          </div>
          <div>
            <img
              alt="placeholder"
              src={require('./assets/step5.jpg')}
              className={classNames(classes.fillWidth)}
            />
            <div className={'content'}>
              <Heading level={4} className={classes.title}>
                <Translate id="routeIntro.questionDontLook" />
              </Heading>
              <Text noMargin>
                <Translate id="routeIntro.answerDontLook1" />
              </Text>
              <Text noMargin>
                <Translate id="routeIntro.answerDontLook2" />
              </Text>
              <Text noMargin>
                <Translate id="routeIntro.answerDontLook3" />
              </Text>
              {!isMobile && navigation}
            </div>
          </div>
        </Carousel>
      </div>
      {isMobile && navigation}
    </motion.div>
  );
}

export default withLocalize(RouteIntro); 
