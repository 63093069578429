import Divider from 'antd/es/divider';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Text } from '../../Typography';
import { useStyles } from './EditRouteCollapsible';

interface EditRouteCollapsibleContentProps {
  activeMajorStep: number;
  activeMinorStep: number;
  setMajorStep: (v: number) => void;
  setMinorStep: (v: number) => void;
}

const EditRouteCollapsibleContent = ({
  activeMajorStep,
  activeMinorStep,
  setMajorStep,
  setMinorStep,
}: EditRouteCollapsibleContentProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.majorContainer}>
        <div className="step-container">1</div>
        <Text noMargin weight="bold">
          <Translate id="routes.create1stStepTitle" />
        </Text>
      </div>
      <div className={classes.minorContainer}>
        <div
          onClick={() => {
            setMajorStep(0);
            setMinorStep(0);
          }}
        >
          <Text
            className={
              activeMajorStep === 0 && activeMinorStep === 0
                ? 'active'
                : undefined
            }
          >
            <Translate id="routes.aboutYou" />
          </Text>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.majorContainer}>
        <div className="step-container">2</div>
        <Text noMargin weight="bold">
          <Translate id="routes.create2ndStepTitle" />
        </Text>
      </div>
      <div className={classes.minorContainer}>
        <div
          onClick={() => {
            setMajorStep(1);
            setMinorStep(0);
          }}
        >
          <Text
            className={
              activeMajorStep === 1 && activeMinorStep === 0
                ? 'active'
                : undefined
            }
          >
            <Translate id="routes.routeTitleTitle" />
          </Text>
        </div>
        <div
          onClick={() => {
            setMajorStep(1);
            setMinorStep(1);
          }}
        >
          <Text
            className={
              activeMajorStep === 1 && activeMinorStep === 1
                ? 'active'
                : undefined
            }
          >
            <Translate id="routes.locationTitle" />
          </Text>
        </div>
        <div
          onClick={() => {
            setMajorStep(1);
            setMinorStep(2);
          }}
        >
          <Text
            className={
              activeMajorStep === 1 && activeMinorStep === 2
                ? 'active'
                : undefined
            }
          >
            <Translate id="routes.descriptionTitle" />
          </Text>
        </div>
        <div
          onClick={() => {
            setMajorStep(1);
            setMinorStep(3);
          }}
        >
          <Text
            className={
              activeMajorStep === 1 && activeMinorStep === 3
                ? 'active'
                : undefined
            }
          >
            <Translate id="routes.visitorInfo" />
          </Text>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.majorContainer}>
        <div className="step-container">3</div>
        <Text noMargin weight="bold">
          <Translate id="routes.create3rdStepTitle" />
        </Text>
      </div>
      <div className={classes.minorContainer}>
        <div
          onClick={() => {
            setMajorStep(2);
            setMinorStep(0);
          }}
        >
          <Text
            className={
              activeMajorStep === 2 && activeMinorStep === 0
                ? 'active'
                : undefined
            }
          >
            <Translate id="routes.acceptTerms" />
          </Text>
        </div>
      </div>
    </div>
  );
};

export default EditRouteCollapsibleContent;
