import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as CompleteWhite } from '../../../shared_assets/icons/complete_white.svg';
import { ReactComponent as InfoWhite } from '../../../shared_assets/icons/info_white.svg';
import { ReactComponent as WarningWhite } from '../../../shared_assets/icons/warning_white.svg';
import { getTheme } from '../../../utils';
import { Heading, Text } from '../../Typography';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    borderRadius: '5px',
    '&:first-child': {
      flex: 0.2,
    },
    '&:last-child': {
      flex: 0.8,
    },
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '1rem',
    '& img, & svg': {
      height: '1.5rem',
      width: '1.5rem',
    },
  },
  popupType: ({ type }: { type: string }) => {
    let backgroundColor = getTheme().primary;

    switch (type) {
      case 'error':
        backgroundColor = getTheme().errorBase;
        break;
      case 'success':
        backgroundColor = getTheme().successBase;
        break;
      case 'info':
        backgroundColor = getTheme().infoBase;
        break;
      default:
        break;
    }

    return { backgroundColor: backgroundColor };
  },
});

interface InformationPopupProps {
  visible?: boolean;
  type: 'error' | 'success' | 'info';
  message?: string;
  description?: string;
  icon?: React.ReactNode;
  className?: string;
}

const InformationPopup = ({
  visible,
  type,
  message,
  description,
  icon,
  className,
}: InformationPopupProps) => {
  const classes = useStyles({ type });
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  useEffect(() => {
    if (type === 'info' && visible === undefined) {
      let timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [type, isVisible, visible]);

  const iconChooser = useMemo<React.ReactNode>(() => {
    if (icon) {
      return icon;
    } else {
      switch (type) {
        case 'error':
          return <WarningWhite />;
        case 'success':
          return <CompleteWhite />;
        case 'info':
          return <InfoWhite />;
        default:
          return <></>;
      }
    }
  }, [type, icon]);

  return (
    <>
      {isVisible && (
        <div
          className={classNames(
            classes.container,
            classes.popupType,
            className
          )}
        >
          <div className={classes.icon}>{iconChooser}</div>
          <div style={{ paddingTop: '0.2rem' }}>
            <Heading color="white" level={5}>
              {message}
            </Heading>
            <Text color="white" noMargin>
              {description}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default InformationPopup;
