import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Pediguia } from '../../../shared_assets/images/pediguia_default.svg';
import { ReactComponent as Visitante } from '../../../shared_assets/images/visitante_default.svg';
import { mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { FallbackAvatar } from '../Avatar';
import { Button } from '../Button';
import { FallbackImage } from '../Image';

type Type = 'visitor' | 'guide';

interface ProfileStripProps {
  includeHello?: boolean;
  name: string;
  memberSince: Date;
  imageSource: string;
  edit?: boolean;
  type: Type;
}

const useStyles = makeStyles({
  noMargin: {
    margin: 0,
  },
  avatarPreview: {
    height: '5rem',
    width: '5rem',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    row: { flexDirection: 'column-reverse', textAlign: 'center' },
    avatarPreview: {
      marginBottom: '1rem',
    },
  },
});

export default function GuideStrip({
  name,
  edit,
  memberSince,
  imageSource,
  includeHello,
  type,
}: ProfileStripProps) {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div>
        {includeHello && (
          <Heading level={3}>
            <Translate id="profile.hello" />
          </Heading>
        )}
        <Heading noMargin={includeHello} level={3}>
          {name}
        </Heading>
        <Text noMargin variant="faded">
          {type === 'guide' ? (
            <Translate id="profile.guideSince" />
          ) : (
            <Translate id="profile.visitorSince" />
          )}
          {moment(memberSince).format('MMM, YYYY')}
        </Text>
        {edit && (
          <Link to={type === 'visitor' && edit ? '/visitor/profile/edit' : ''}>
            <Button type="link" onlyText>
              <Text variant="link">
                <Translate id="profile.editProfile" />
              </Text>
            </Button>
          </Link>
        )}
      </div>
      <FallbackAvatar
        className={classes.avatarPreview}
        src={
          <FallbackImage
            src={imageSource}
            fallbackComponent={type === 'guide' ? <Pediguia /> : <Visitante />}
          />
        }
      />
    </div>
  );
}
