import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { Button } from '../../../../components/App/Button';
import { LanguageSelection } from '../../../../components/App/LanguageSelection';
import { Heading, Text } from '../../../../components/Typography';
import { ReactComponent as BackWhite } from '../../../../shared_assets/icons/back_white.svg';
import { ReactComponent as Next } from '../../../../shared_assets/icons/next.svg';
import { castShadow, desktopPadding, mobilePadding, mobileThreshhold } from '../../../../utils';
import { SlideFromRightTransition } from '../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    padding: '1rem 0',
  },
  checkBoxContainer: {
    display: 'inline-flex',
    textAlign: 'initial',
    marginTop: '1rem',
    '& .ant-checkbox': {
      paddingTop: '0.2rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'flex-end',
    margin: '1rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },
    container: {
      padding: '2rem 2rem 3rem 2rem',
      boxShadow: castShadow,
      borderRadius: '10px',
    },
  },
});

interface LanguageStepProps extends LocalizeContextProps {
  setSelectedLanguages: (v: SetStateAction<string[]>) => void;
  selectedLanguages: string[];
  remoteLanguages: string[];
  onRequestPreviousStep: () => void;
  onRequestNextStep: () => void;
}

function LanguageStep({
  remoteLanguages,
  setSelectedLanguages,
  selectedLanguages,
  onRequestPreviousStep,
  onRequestNextStep,
}: LanguageStepProps) {
  const classes = useStyles();
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={SlideFromRightTransition}
    >
      <div className={classes.body}>
        <div className={classes.container}>
          <Heading level={4}>
            <Translate id="onboarding.languages" />
          </Heading>
          <Heading level={5}>
            <Translate id="onboarding.knownLanguages" />
          </Heading>
          <Text variant="faded">
            <Translate id="onboarding.selectKnownLanguages" />
          </Text>
          <LanguageSelection
            onChange={setSelectedLanguages}
            value={selectedLanguages}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          size="large"
          onClick={onRequestPreviousStep}
          prefix={<BackWhite />}
        >
          <Translate id="button.previous" />
        </Button>
        <Button
          type="primary"
          disabled={selectedLanguages.length === 0}
          size="large"
          onClick={onRequestNextStep}
          suffix={<Next />}
        >
          <Translate id="button.next" />
        </Button>
      </div>
    </motion.div>
  );
}

export default React.memo(withLocalize(LanguageStep));
