import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as uuid from 'uuid-browser/v4';

import { API_URL } from '../consts';
import { store } from '../store';

interface GmapsReturn {
  status: string;
  predictions: {
    description: string;
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
  }[];
}

export const fetchPlaces = (
  input: string,
  sessionToken: string,
  cancelToken?: CancelToken
) => {
  const instance = Axios.create();

  delete instance.defaults.headers;

  return instance.get<GmapsReturn>(`${API_URL}/route/locations`, {
    params: {
      input,
      language: store.getState().userConfiguration.activeLanguage.split('_')[0],
      sessionToken,
    },
    cancelToken: cancelToken,
  });
};

type Hook = (input: string, type?: string) => [GmapsReturn, boolean, boolean];

export const usePlacesSearch: Hook = (input, type) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [data, setData] = useState<GmapsReturn>({
    predictions: [],
    status: 'OK',
  });
  const cancel = useRef<CancelTokenSource | undefined>();

  const sessionToken = useMemo(() => uuid(), []);

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    (async () => {
      if (!input) {
        setIsLoading(false);
        setHasError(false);
        setData({ predictions: [], status: 'ZERO_RESULTS' });
        return;
      }
      setIsLoading(true);
      try {
        const { data } = await fetchPlaces(
          input,
          sessionToken,
          cancel.current?.token
        );
        setHasError(false);
        setData(data);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setHasError(true);

          setIsLoading(false);
        }
      }
    })();
  }, [input, type, sessionToken]);

  return [data, isLoading, hasError];
};
