import { makeStyles } from '@material-ui/styles';
import { OverlayView } from '@react-google-maps/api';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { Text } from '../../../components/Typography';
import { ReactComponent as Flag } from '../../../shared_assets/icons/flag.svg';
import { RouteLocation } from '../../../types/route-location.interface';
import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  marker: ({ selected }: { selected: boolean }) => ({
    backgroundColor: getTheme().primary,
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    paddingLeft: '0.5rem',
    transition: 'width 200ms',
    borderRadius: '3rem',
    overflow: 'hidden',
    color: 'white',
    '& > img, & > svg': {
      minHeight: '2rem',
      minWidth: '2rem',
      maxHeight: '2rem',
      maxWidth: '2rem',
      marginRight: selected ? '1rem !important' : 'unset',
    },
  }),
  title: {
    marginRight: '1rem !important',
    whiteSpace: 'nowrap',
    color: 'white',
  },
  selected: {
    width: '100%',
  },
  idle: {
    width: '3rem',
  },
});
interface LocationMarkerProps {
  location: RouteLocation;
  index: number;
  selected: boolean;
  onSelect: (i: number) => void;
}

const LocationMarker: React.FC<LocationMarkerProps> = ({
  location,
  index,
  onSelect,
  selected,
}) => {
  const classes = useStyles({ selected });
  const position = useMemo(
    () => ({
      lat: location.latitude,
      lng: location.longitude,
    }),
    [location]
  );
  return (
    <OverlayView
      key={location.id}
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        className={classNames(
          classes.marker,
          selected ? classes.selected : classes.idle
        )}
        onClick={() => onSelect(index)}
      >
        <Flag />
        {selected && (
          <Text weight="bold" className={classes.title} noMargin>
            {location.address}
          </Text>
        )}
      </div>
    </OverlayView>
  );
};

export default React.memo(LocationMarker);
