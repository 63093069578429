import { makeStyles } from '@material-ui/styles';
import DatePicker from 'antd/es/date-picker';
import message from 'antd/es/message';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Button } from '../../../../components/App/Button';
import { Checkbox } from '../../../../components/App/Checkbox';
import { Modal } from '../../../../components/App/Modal';
import { Option, Select } from '../../../../components/App/Select';
import { Text } from '../../../../components/Typography';
import { createRouteScheduleAdmin } from '../../../../hooks/administration/createRouteSchedule';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { editScheduleRepetition } from '../../../../hooks/schedule/editSchedule';
import { ReactComponent as CalendarIcon } from '../../../../shared_assets/icons/calendar.svg';
import { ReactComponent as Time } from '../../../../shared_assets/icons/time.svg';
import { ErrorCode } from '../../../../types/error.codes.enum';
import { Schedule } from '../../../../types/schedule-new';
import { ScheduleRepetition } from '../../../../types/schedule-repetition';
import { ScheduleType } from '../../../../types/schedule-type.enum';
import { getTheme, headerHeight } from '../../../../utils';

const useStyles = makeStyles({
  routePresentation: () => ({
    backgroundSize: 'cover',
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '1rem',
    position: 'relative',
    marginBottom: '1rem',
    '& .ant-popover-inner-content': {
      padding: '0.5rem',
    },
    marginTop: '1rem',
    borderRadius: 10,
  }),
  moreButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    padding: '2px 4px',
    height: 'unset',
  },
  choosePopover: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '50vh',
    maxWidth: '50vw',
  },
  routeChoice: {
    display: 'flex',
    height: 48,
    maxHeight: 48,
    alignItems: 'center',
    margin: '0.5rem 0',
    '& > *': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  routeImage: {
    width: 32,
    height: 32,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: '1rem',
    display: 'flex',
    borderRadius: 5,
  },
  routeInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1rem',
      '&:last-child': {
        marginRight: 0,
      },
      '& > *:first-child': {
        marginRight: '0.5rem',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: headerHeight,
    alignItems: 'center',
    padding: '0 24px',
    flexShrink: 0,
  },
  datepicker: {
    '& input': {
      fontSize: '14px !important',
    },
    borderColor: getTheme().neutral4 + ' !important',
    borderWidth: 2,
    borderRadius: 5,
  },
  textIcon: {
    height: '1.2rem',
    width: '1.2rem',
  },
});

interface ScheduleAdminModalProps extends LocalizeContextProps {
  guideId: string;
  selectedRouteId: string;
  visible: boolean;
  onRequestClose: () => void;
  onDoAction: (newRepetition: ScheduleRepetition | Schedule) => void;
  selectedDate: Moment;
  repetition?: ScheduleRepetition;
  routeDuration?: number;
}

type BookingTimes = 10 | 30 | 60 | 180 | 360 | 1440 | 2880;

function ScheduleAdminModal({
  guideId,
  visible,
  onRequestClose,
  selectedRouteId,
  selectedDate,
  routeDuration,
  translate,
  repetition,
  onDoAction,
}: ScheduleAdminModalProps) {
  const [jwt] = UseJWT();
  const [selectTimeOptions, setSelectTimeOptions] = useState<any[]>([]);
  const [repetitionType, setRepetitionType] = useState<ScheduleType>(
    ScheduleType.NONE
  );
  const [dontAllowBookingsTime, setDontAllowBookingsTime] =
    useState<BookingTimes>(10);
  const [hours, setHours] = useState(() => {
    const start = moment();
    const remainder = 30 - (start.minute() % 30);
    return moment(start).add(remainder, 'minutes').format('HH:mm');
  });
  const [giveAway, setGiveAway] = useState(false);
  const [referenceDate, setReferenceDate] = useState<Moment>(selectedDate);

  useEffect(() => {
    if (!repetition) return;
    setReferenceDate(moment(repetition.date).startOf('day'));
    setHours(moment(repetition.date).format('HH:mm').toString());
    setDontAllowBookingsTime(repetition.reserveLimit as BookingTimes);
    if (repetition.adultPrice === 0) {
      setGiveAway(true);
    }
  }, [repetition]);
  useEffect(() => {
    let hour = 0;
    let id = 1;
    const options = [];
    while (hour < 24) {
      const minutes = id % 2 === 0 ? 30 : 0;
      const option = (
        <Option
          value={moment({
            hour: hour,
            minutes: minutes,
          }).format('HH:mm')}
          key={id}
          disabled={
            referenceDate.isSame(moment(), 'day') &&
            hours >
              moment({
                hour: hour,
                minutes: minutes,
              }).format('HH:mm')
          }
        >
          {moment({
            hour: hour,
            minutes: minutes,
          }).format('HH:mm')}
        </Option>
      );
      options.push(option);
      if (id % 2 === 0) {
        hour = hour + 1;
      }
      id = id + 1;
    }
    setSelectTimeOptions(options);
  }, [hours, referenceDate]);
  const classes = useStyles();

  const createSchedule = useCallback(async () => {
    if (!jwt) return;
    try {
      const response = await createRouteScheduleAdmin({
        guideId: guideId,
        repetitionType: repetitionType,
        reserveLimit: dontAllowBookingsTime,
        routeId: selectedRouteId,
        start: moment(referenceDate)
          .add(hours.split(':')[0], 'hours')
          .add(hours.split(':')[1], 'minutes')
          .toISOString(),
        giveAway,
      });

      onDoAction(response.data);
    } catch (err) {
      if (err.response.code === 404) {
        message.error(translate(ErrorCode.BAD_SCHEDULE_ID));
      }

      if (
        err?.response?.data?.error &&
        typeof err.response.data.error === 'string'
      ) {
        message.error(
          translate(`error.API_ERROR.${err.response.data.error}`).toString()
        );
      }
    }
  }, [
    jwt,
    guideId,
    repetitionType,
    dontAllowBookingsTime,
    selectedRouteId,
    referenceDate,
    hours,
    giveAway,
    onDoAction,
    translate,
  ]);

  const editSchedule = useCallback(async () => {
    if (!repetition) return;
    try {
      const response = await editScheduleRepetition(repetition.id, {
        newDate: moment(referenceDate)
          .add(parseInt(hours.split(':')[0]), 'hours')
          .add(parseInt(hours.split(':')[1]), 'minutes')
          .utc()
          .toDate(),
        newReserveLimit: dontAllowBookingsTime,
        giveAway,
      });

      onDoAction(response.data);
    } catch (err) {
      if (err.response.code === 404) {
        message.error(translate(ErrorCode.BAD_SCHEDULE_ID));
      }

      if (
        err?.response?.data?.error &&
        typeof err.response.data.error === 'string'
      ) {
        message.error(
          translate(`error.API_ERROR.${err.response.data.error}`).toString()
        );
      }
    }
  }, [
    referenceDate,
    hours,
    dontAllowBookingsTime,
    onDoAction,
    translate,
    repetition,
    giveAway,
  ]);
  return (
    <Modal
      visible={visible}
      onRequestClose={() => {
        if (repetition) {
          setReferenceDate(moment(repetition.date).startOf('day'));
          setHours(moment(repetition.date).format('HH:mm').toString());
        } else {
          setHours(() => {
            const start = moment();
            const remainder = 30 - (start.minute() % 30);
            return moment(start).add(remainder, 'minutes').format('HH:mm');
          });
        }
        onRequestClose();
      }}
      footer={
        <div className={classes.footer}>
          <Button
            onClick={() => {
              if (repetition) {
                setReferenceDate(moment(repetition.date).startOf('day'));
                setHours(moment(repetition.date).format('HH:mm').toString());
              } else {
                setHours(() => {
                  const start = moment();
                  const remainder = 30 - (start.minute() % 30);
                  return moment(start)
                    .add(remainder, 'minutes')
                    .format('HH:mm');
                });
              }

              onRequestClose();
            }}
          >
            <Translate id="schedules.cancel" />
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (repetition) editSchedule();
              else createSchedule();
            }}
          >
            <Translate id="schedules.doSchedule" />
          </Button>
        </div>
      }
      variant="close"
      headerTitle={<Translate id="schedules.scheduleTitle" />}
    >
      <Text weight="semibold">
        <Translate id="schedules.starts" />
      </Text>
      <DatePicker
        suffixIcon={<CalendarIcon />}
        className={classNames(classes.marginBottom, classes.datepicker)}
        size="large"
        value={referenceDate}
        onChange={ev => ev && setReferenceDate(ev)}
        format="L"
        disabledDate={currentDate =>
          currentDate.isBefore(moment().startOf('day'))
        }
        // disabled={!repetition}
        // inputReadOnly={!repetition}
      />
      <Select
        className={classes.marginBottom}
        value={hours}
        onChange={e => {
          setHours(e.toString());
        }}
        huge
      >
        {selectTimeOptions}
      </Select>
      <Text weight="semibold">
        <Translate id="schedules.ends" />
      </Text>
      <div className={classes.row}>
        <Text inline>
          <Time className={classes.textIcon} />
          {moment(referenceDate)
            .add({
              hour: parseInt(hours.split(':')[0]),
              minutes: parseInt(hours.split(':')[1]),
            })
            .add({
              minutes: routeDuration,
            })
            .format('HH:mm')}
        </Text>
        <Text inline>
          <CalendarIcon className={classes.textIcon} />

          {moment(referenceDate)
            .add({
              hour: parseInt(hours.split(':')[0]),
              minutes: parseInt(hours.split(':')[1]),
            })
            .add({
              minutes: routeDuration,
            })
            .format('LL')}
        </Text>
      </div>
      <Text variant="note">
        <Translate id="schedules.estimatedTimeWarning" />
      </Text>
      <Text>
        <Checkbox
          onChange={e => {
            setGiveAway(e.target.checked);
          }}
          checked={giveAway}
        >
          <Text inline variant="faded">
            <Translate id="routes.giveAway" />
          </Text>
        </Checkbox>
      </Text>
      {!repetition && (
        <>
          <Text weight="semibold">
            <Translate id="schedules.repeatLabel" />
          </Text>
          <Select
            className={classes.marginBottom}
            huge
            value={repetitionType}
            onChange={e => setRepetitionType(e.toString() as ScheduleType)}
          >
            {Object.keys(ScheduleType).map(type => (
              <Option
                key={type}
                value={type}
                label={`scheduleRepetitionType.${type}`}
              >
                <Translate id={`scheduleRepetitionType.${type}`} />
              </Option>
            ))}
          </Select>
        </>
      )}
      <Text weight="semibold">
        <Translate id="schedules.dontAllowBookings" />
      </Text>
      <Select
        className={classes.marginBottom}
        huge
        value={dontAllowBookingsTime}
        onChange={ev =>
          setDontAllowBookingsTime(parseInt(ev.toString()) as BookingTimes)
        }
      >
        {[10, 30, 60, 180, 360, 1440, 2880].map(minutes => (
          <Option key={minutes} value={minutes}>
            {moment.duration({ minutes: minutes }).humanize()}
          </Option>
        ))}
      </Select>
    </Modal>
  );
}

export default withLocalize(React.memo(ScheduleAdminModal));
