import Axios from "axios";
import { useState, useEffect } from "react";
import { API_URL } from "../../consts";

type Hook = () => [RouteValues | null, boolean, boolean];

const requestRouteValues = () =>
  Axios.get<RouteValues>(`${API_URL}/route/values`);

interface RouteValues {
  minPrice: number;
  maxPrice: number;
  minDuration: number;
  maxDuration: number;
}

export const useRouteValues: Hook = () => {
  const [routeValues, setRouteValues] = useState<RouteValues | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await requestRouteValues();
        setRouteValues(data);
      } catch (err) {
        setRouteValues(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return [routeValues, isLoading, isError];
};
