import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { ReactComponent as ChangeProfile } from '../../../shared_assets/icons/change_profile.svg';
import { ReactComponent as Logout } from '../../../shared_assets/icons/exit.svg';
import { ReactComponent as Help } from '../../../shared_assets/icons/help.svg';
import { ReactComponent as Language } from '../../../shared_assets/icons/language.svg';
import { ReactComponent as About } from '../../../shared_assets/icons/quem_somos.svg';
import { ReactComponent as Routes } from '../../../shared_assets/icons/routes.svg';
import { HelpModal } from '../HelpModal';
import { DrawerFragmentProps } from './Drawer';
import DrawerItem from './DrawerItem';

const useStyles = makeStyles({
  divider: { margin: '0.5rem 0' },
});

export interface UnauthenticatedGuideDrawerFragmentProps
  extends DrawerFragmentProps {
  setShowRegisterModal: (v: boolean) => void;
  setShowLoginModal: (v: boolean) => void;
}

function UnauthenticatedGuideDrawerFragment({
  reduxActiveLanguage,
  setOpenStatus,
  setShowRegisterModal,
  setShowLoginModal,
  setShowLanguageModal,
}: UnauthenticatedGuideDrawerFragmentProps) {
  const classes = useStyles();
  const [showingHelp, setShowingHelp] = useState<boolean>(false);

  return (
    <>
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setShowRegisterModal(true);
        }}
        title={<Translate id="landing.drawer.signUp" />}
        icon={<Logout />}
      />

      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setShowLoginModal(true);
        }}
        title={<Translate id="landing.drawer.signIn" />}
        icon={<ChangeProfile />}
      />
      <Divider className={classes.divider} />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/guide/route/intro"
        title={<Translate id="landing.drawer.organizeRoute" />}
        icon={<Routes />}
      />
      <Divider className={classes.divider} />

      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setShowLanguageModal(true);
        }}
        title={<Translate id="landing.drawer.language" />}
        subtitle={
          <span>
            <Translate id={`languages.${reduxActiveLanguage}`} />
            {` (${reduxActiveLanguage.split('_')[1]})`}
          </span>
        }
        icon={<Language />}
      />
      <Divider className={classes.divider} />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/guide/about"
        title={<Translate id="landing.drawer.aboutUs" />}
        icon={<About />}
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setShowingHelp(true);
        }}
        title={<Translate id="landing.drawer.help" />}
        icon={<Help />}
      />
      <HelpModal
        visible={showingHelp}
        onRequestClose={() => {
          setShowingHelp(false);
        }}
      />
    </>
  );
}

export default UnauthenticatedGuideDrawerFragment;
