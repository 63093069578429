import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Error } from '../../components/App/Error';
import Loader from '../../components/App/Loader/Loader';
import { RouteOngoingModal } from '../../components/App/RouteOngoingModal';
import { UseJWT } from '../../hooks/authentication/UseJWT';
import { useGuide } from '../../hooks/guide/UseGuide';
import { NotificationOwnerType } from '../../hooks/notifications/useNotifications';
import { RootState } from '../../store';
import { setLastAppZone } from '../../store/App/actions';
import { setOngoingRoute } from '../../store/UserConfiguration/actions';
import { Guide } from '../../types/guide.interface';
import { About } from '../About';
import { Notifications } from '../Notification';
import { RegistrationSuccess } from '../Registration';
import { AwaitingApproval } from './AwaitingApproval';
import { Comments } from './Comments';
import { GuideLanding } from './GuideLanding';
import Router from './MyRoutes/MyRoutesRouter';
import { Onboarding } from './Onboarding';
import { PaymentHistory } from './PaymentHistory';
import { Profile } from './Profile';
import PublicProfile from './Public/PublicProfile';
import { ScheduleRouter } from './Schedules';

interface GuideRouterProps {
  openLogin: () => void;
}

export default function GuideRouter({ openLogin }: GuideRouterProps) {
  const [jwt] = UseJWT();
  const [guideProfile, setGuideProfile] = useState<Guide | null>(null);
  const [isLoading, hasError] = useGuide(jwt?.guideId, setGuideProfile);
  const history = useHistory();
  const onboardingMatch = useRouteMatch('/guide/onboarding');
  const publicProfileMatch = useRouteMatch(
    '/guide/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})'
  );
  const dispatch = useDispatch();

  const onFinishOnboarding = useCallback(
    (guide: Guide) => {
      setGuideProfile(guide);
    },
    [setGuideProfile]
  );

  const activeTour = useSelector(
    (state: RootState) => state.userConfiguration.ongoingRouteId
  );

  useEffect(() => {
    if (!publicProfileMatch) {
      dispatch(setLastAppZone('guide'));
    }
  });

  if (jwt && jwt.guideId) {
    if (isLoading) return <Loader />;

    if (hasError || !guideProfile) return <Error />;

    //If needs to onboard and is not currently onboarding take the user there
    if (!guideProfile.onboarding && !onboardingMatch) {
      history.replace('/guide/onboarding');
    }
  }

  return (
    <>
      {activeTour && (
        <RouteOngoingModal
          visible
          repetitionId={activeTour}
          onFinish={() => {
            dispatch(setOngoingRoute(null));
          }}
        />
      )}

      <Switch>
        <Route
          path="/guide/landing"
          render={() => <GuideLanding openLogin={openLogin} />}
        />
        {
          //if is not authenticated fall into redirect
          jwt &&
            jwt.guideId && [
              <Route
                path="/guide/onboarding"
                key="guide-onboarding"
                render={() => (
                  <Onboarding onFinishOnboarding={onFinishOnboarding} />
                )}
              />,
              <Route
                path="/guide/payment-history"
                key="payment-history"
                component={PaymentHistory}
              />,
              <Route
                path="/guide/profile"
                key="guide-profile"
                component={Profile}
              />,
              <Route
                path="/guide/schedules"
                key="schedules"
                component={ScheduleRouter}
              />,
              <Route
                key="my-routes"
                path="/guide/my-routes"
                component={Router}
              />,
              <Route
                key="my-comments"
                path="/guide/my-comments"
                component={Comments}
              />,
              <Route
                key="my-notifications"
                path="/guide/my-notifications"
                exact
                component={() => (
                  <Notifications user={NotificationOwnerType.GUIDE} />
                )}
              />,
            ]
        }
        <Route
          exact
          path="/guide/awaiting-approval"
          component={AwaitingApproval}
        />

        <Route path="/guide/about" component={About} />
        <Route path="/guide/register/success" component={RegistrationSuccess} />
        <Route
          path="/guide/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"
          component={PublicProfile}
        />
        <Redirect to="/guide/landing" />
      </Switch>
    </>
  );
}
