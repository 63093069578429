import { makeStyles } from '@material-ui/styles';
import ANTDTabs, { TabsProps as ANTDTabsProps } from 'antd/es/tabs';
import classNames from 'classnames';
import React from 'react';

import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  tabs: () => ({
    '& .ant-tabs-tab': {
      color: getTheme().primary,
      marginRight: 0,
      padding: '7px 14px',
      border: `1px ${getTheme().primary} solid`,
      marginBottom: 1,
      transitionProperty: 'color, background-color',
      fontWeight: 600,
      fontSize: 14,
      '&:not(:first-child)': {
        borderLeftWidth: 0,
      },
      '&:first-child': {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      },
      '&.ant-tabs-tab-active': {
        '& > .ant-tabs-tab-btn ': { color: 'white !important' },
        backgroundColor: getTheme().primary,
      },
    },
    '& .ant-tabs-ink-bar': {
      display: 'none !important',
    },
    '& .ant-tabs-bar': {
      borderBottomWidth: 0,
    },
  }),
});

const Tabs: React.FC<ANTDTabsProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <ANTDTabs className={classNames(classes.tabs, className)} {...props} />
  );
};

export default Tabs;
