import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import React from 'react';

import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 0,
    position: 'relative',
    marginTop: '1rem',
  },
  stepBar: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  stretchedDivider: (
    { offsetHeight }: { offsetHeight?: number } = { offsetHeight: 0 }
  ) => ({
    height: offsetHeight
      ? `calc(100% - ${offsetHeight}px)`
      : `calc(100% - 1.7rem)`,
    position: 'absolute',
    margin: '0 0.75rem',
    marginTop: '0.75rem',
    backgroundColor: getTheme().primary,
    borderLeftColor: getTheme().primary,
  }),
  bigPoint: (
    { offsetHeight }: { offsetHeight?: number } = { offsetHeight: 0 }
  ) => ({
    position: 'absolute',
    height: 'calc(1.5rem + 1px)',
    width: 'calc(1.5rem + 1px)',
    borderRadius: 'calc(1.5rem + 1px)',
    backgroundColor: 'white',
    border: `1px ${getTheme().primary} solid`,
    '&:nth-child(3)': {
      transform: offsetHeight
        ? `translateY(calc(-${offsetHeight || 0}px + 1.5rem))`
        : undefined,
    },
  }),
  actualPointContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

interface PointStepperProps {
  lastPointOffset?: number;
}

const PointStepper: React.FC<PointStepperProps> = ({
  children,
  lastPointOffset: offsetHeight,
}) => {
  const classes = useStyles({ offsetHeight });

  return (
    <div className={classes.container}>
      <Divider className={classes.stretchedDivider} type="vertical" />

      <div
        className={classes.bigPoint}
        style={{
          alignSelf: 'flex-start',
        }}
      />
      <div
        className={classes.bigPoint}
        style={{
          justifySelf: 'flex-start',
          alignSelf: 'flex-end',
        }}
      />
      <div className={classes.actualPointContainer}>{children}</div>
    </div>
  );
};

export default PointStepper;
