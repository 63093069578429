import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { ReactComponent as EditIcon } from '../../../shared_assets/icons/edit.svg';
import { getTheme } from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';

const useStyles = makeStyles({
  container: {
    height: 40,
    borderRadius: 5,
    border: `2px ${getTheme().neutral4} solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0',
  },
});

interface GuideLanguageEntryProps {
  code: string;
  onRequestLanguageChange: () => void;
}

const GuideLanguageEntry: React.FC<GuideLanguageEntryProps> = ({
  code,
  onRequestLanguageChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Text noMargin inline>
        <Translate id={`languages.${code}`} />
      </Text>
      <Button
        type="link"
        onlyText
        onClick={onRequestLanguageChange}
        prefix={<EditIcon />}
      >
        <Translate id="button.edit" />
      </Button>
    </div>
  );
};

export default GuideLanguageEntry;
