import Axios from 'axios';
import clonedeep from 'lodash.clonedeep';
import { API_URL } from '../../consts';
import { GroupAgeType } from '../../types/group-age-type.enum';
import { DifficultyType } from '../../types/route-difficulty.enum';
import { RouteExperienceType } from '../../types/route-experience.enum';
import { RouteStateType } from '../../types/route-state.enum';

interface DescriptionDto {
  language: string;
  description: string;
}
interface TitleDto {
  language: string;
  title: string;
}
export interface RouteActivityDto {
  price: number;
  descriptions?: DescriptionDto[];
}

export interface RouteExtraItemDto {
  descriptions?: DescriptionDto[];
}

interface RouteLocationDto {
  order: number;
  latitude: number;
  longitude: number;
  address: string;
  descriptions: DescriptionDto[];
  photos: File[];
  titles: TitleDto[];
}

interface RouteLanguageDto {
  language: string;
}

interface CreateRouteDto {
  guideId: string;
  titles: TitleDto[];
  location: string;
  duration: number;
  languages: RouteLanguageDto[];
  difficulty: DifficultyType;
  accessibilityIds: string[];
  subjectIds: string[];
  groupSize: number;
  routeExperience: RouteExperienceType;
  groupAge: GroupAgeType;
  agreeTerms: boolean;
  state: RouteStateType;
  locations: RouteLocationDto[];
  activities: RouteActivityDto[];
  extraItems: RouteExtraItemDto[];
  descriptions?: DescriptionDto[];
  latitude: number;
  longitude: number;
  routeLength: number;
}

const requestPostNewRoute = (formData: FormData) =>
  Axios.post(`${API_URL}/route`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const SubmitNewRoute = (dto: CreateRouteDto) => {
  const clonedDto = clonedeep(dto); // cloning so we don't mutate the sent dto
  const data = new FormData();
  dto.locations.forEach((loc, i) => {
    loc.photos.forEach(photo => {
      data.append(`location-${i}`, photo);
    });
    //clean json data
    delete clonedDto.locations[i].photos;
  });

  data.append('createRouteDto', JSON.stringify(clonedDto));

  return requestPostNewRoute(data);
};
