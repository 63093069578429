import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UseJWT } from '../../hooks/authentication/UseJWT';
import { setLastAppZone } from '../../store/App/actions';
import { RoleType } from '../../types/role-type.enum';
import { CompanyArea } from './CompanyArea';
import { CompanyLanding } from './CompanyLanding';
import { CompanySignUp } from './CompanySignUp';

export default function CompanyRouter() {
  const [jwt] = UseJWT();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLastAppZone('company'));
  });

  return (
    <>
      <Switch>
        <Route path="/company/landing" render={() => <CompanyLanding />} />
        <Route
          path="/company/sign-up"
          render={() => <CompanySignUp type={RoleType.COMPANY} />}
        />
        {
          //if is not authenticated fall into redirect
          jwt && [
            <Route
              path="/company/area"
              key="company-area"
              component={CompanyArea}
            />,
          ]
        }

        <Redirect to="/company/landing" />
      </Switch>
    </>
  );
}
