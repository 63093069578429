import Axios from 'axios';
import { API_URL } from '../../consts';

export interface JoinUsInterface {
  joinTitle: string;
  joinText: string;
  toKnowMoreText: string;
  toKnowMoreRedirectUrl: string;

  language: string;
}
export interface JoinUsDto {
  guideIds: string[];
  joinUsTexts: JoinUsInterface[];
}

export const postGuideSelection = (dto: JoinUsDto) => {
  return Axios.post(
    `${API_URL}/content-management/guide-landing-page/guide-selection`,
    dto
  )
    .catch(err => {
      return Promise.reject(err.response.data);
    })
    .then(success => {
      return Promise.resolve(success);
    });
};

// Axios.post(url, dto).then(() => {
//   //msg sucess
// }).catch(() => {
//   //msg err
// })
