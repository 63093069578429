import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import { Button } from '../../../../components/App/Button';
import { Heading, Text } from '../../../../components/Typography';
import { desktopPadding, mobilePadding, mobileThreshhold, useIsMobile } from '../../../../utils';
import { GenericPageTransition } from '../../../PageUtils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flex: 1,
  },
  body: {
    textAlign: 'center',
    justifyContent: 'space-between',
  },
  headerImage: {
    flex: 1,
    width: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
  },
  btn: {
    textTransform: 'initial',
    padding: '1.2rem 5rem',
  },
  stretchAndCenter: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    ...mobilePadding,
  },
  titleMargin: {
    margin: '2rem 0 1.5rem 0 !important',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
      flexDirection: 'row',
    },
    footer: {
      boxShadow: 'none',
    },
    headerImage: {
      width: '50%',
      maxWidth: '50%',
      maxHeight: '50%',
      borderRadius: 10,
    },
    sideContainer: {
      flex: 1,
      maxHeight: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    stretchAndCenter: { flex: 0 },
  },
});

function CreateSuccess() {
  const classes = useStyles();

  const isMobile = useIsMobile();

  const footer = useMemo(
    () => (
      <div className={classes.footer}>
        <Link to="/guide/my-routes">
          <Button type="primary" className={classes.btn}>
            <Translate id="button.conclude" />
          </Button>
        </Link>
      </div>
    ),
    [classes.footer, classes.btn]
  );

  return (
    <>
      <motion.div
        className={classNames(classes.container)}
        initial="exit"
        animate="enter"
        exit="exit"
        variants={GenericPageTransition}
      >
        <div className={classNames(classes.body, classes.container)}>
          <img
            src={require('./assets/success.png')}
            alt="place"
            className={classes.headerImage}
          />
          <div className={classes.sideContainer}>
            <div className={classes.stretchAndCenter}>
              <Heading level={4} className={classes.titleMargin}>
                <Translate id="routes.routeCreationSuccessTitle" />
              </Heading>
              <Text>
                <Translate id="routes.routeCreationSuccessContent" />
              </Text>
            </div>
            {!isMobile && footer}
          </div>
        </div>
      </motion.div>
      {isMobile && footer}
    </>
  );
}

export default CreateSuccess;
