import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { AnimatedError } from '../../../components/App/AnimatedError';
import { Button } from '../../../components/App/Button';
import { Checkbox } from '../../../components/App/Checkbox';
import { Error } from '../../../components/App/Error';
import FallbackImage from '../../../components/App/Image/FallbackImage';
import { InformationPopup } from '../../../components/App/InformationPopup';
import { Input } from '../../../components/App/Input';
import { Loader } from '../../../components/App/Loader';
import { Text } from '../../../components/Typography';
import { useAdmin } from '../../../hooks/administration/useAdmin';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { LogoUrl } from '../../../hooks/customization/getLogo';
import { ReactComponent as Email } from '../../../shared_assets/icons/email.svg';
import { ReactComponent as Lock } from '../../../shared_assets/icons/lock.svg';
import { ReactComponent as See } from '../../../shared_assets/icons/see.svg';
import { ReactComponent as Unsee } from '../../../shared_assets/icons/unsee.svg';
import { attemptLogin } from '../../../store/UserConfiguration/actions';
import { Administrator } from '../../../types/administrator.interface';
import { RoleType } from '../../../types/role-type.enum';
import { useLoginForm } from '../../Login/useLoginForm';

const useStyles = makeStyles({
  dot: {
    maxHeight: '50%',
    maxWidth: '50%',
    borderRadius: '100%',
    borderWidth: 2,
    backgroundColor: 'transparent',

    borderColor: 'black',
    borderStyle: 'solid',
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderWidth: 2,
    flex: 1,
    alignSelf: 'center',
    borderColor: '#009CDE',
    borderRadius: '32px',
    borderStyle: 'solid',
    padding: '0 1rem 0 1rem',
    minWidth: '30%',
    maxHeight: '18rem',
  },
  popupMargin: {
    marginBottom: '1.5rem',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  marginBetween: {
    margin: '1rem 0',
  },
  formBottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  div: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  img: {
    maxWidth: '30%',
    objectFit: 'contain',
  },
  h1: {
    color: '#009CDE',
  },
});

interface AdminLandingProps extends LocalizeContextProps {
  type: RoleType;
}

function AdminLanding({ translate, type }: AdminLandingProps) {
  const form = useLoginForm();
  const history = useHistory();
  const classes = useStyles();
  const [jwt] = UseJWT();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [admin, setAdmin] = useState<Administrator | null>(null);
  const [isLoading, hasError] = useAdmin(
    jwt?.roles.includes(RoleType.ADMINISTRATOR) ? jwt?.email : undefined,
    setAdmin
  );
  const [thereWasALoginError, setThereWasALoginError] = useState<boolean>(
    false
  );
  const [isPasswordShowing, setIsPasswordShowing] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();

  const signIn = useCallback(async () => {
    if (
      Object.values(form).some(formEntry =>
        typeof formEntry === 'function' ? false : formEntry.isInvalid
      )
    ) {
      setThereWasALoginError(true);
      return;
    }

    setIsLoggingIn(true);

    try {
      await dispatch(
        attemptLogin({
          email: form.email.value,
          password: form.password.value,
          type: type,
        })
      );
      setThereWasALoginError(false);
      history.replace(`/admin/area/list/guides`);
    } catch (err) {
      setThereWasALoginError(true);
    } finally {
      setIsLoggingIn(false);
    }

    setIsLoggingIn(false);
  }, [form, dispatch, type, history]);

  if (isLoading) return <Loader />;

  if (hasError && jwt?.roles.includes(RoleType.ADMINISTRATOR)) return <Error />;

  return (
    <>
      <div className={classes.div}>
        <FallbackImage
          src={LogoUrl}
          fallback={require('../../../components/App/Header/assets/logo_cor.png')}
          className={classes.img}
          alt=""
        />
        <h1 className={classes.h1}>ADMINISTRATION</h1>
      </div>
      <div className={classes.mainContainer}>
        <InformationPopup
          visible={thereWasALoginError}
          type="error"
          message={translate('login.badLoginMessage').toString()}
          description={translate('login.badLoginDescription').toString()}
          className={classes.popupMargin}
        />
        <Input
          huge
          type="email"
          placeholder={translate('login.email').toString()}
          onChange={event => {
            form.email.set(event.currentTarget.value);
          }}
          value={form.email.value}
          prefix={<Email className={classes.icon} />}
        />
        <AnimatedError
          isVisible={form.email.isInvalid}
          reason={form.email.reason}
        />
        <Input
          huge
          type={isPasswordShowing ? 'text' : 'password'}
          placeholder={translate('login.password').toString()}
          onChange={event => {
            form.password.set(event.currentTarget.value);
          }}
          value={form.password.value}
          prefix={<Lock className={classes.icon} />}
          suffix={
            isPasswordShowing ? (
              <div
                onClick={() => {
                  setIsPasswordShowing(prevState => !prevState);
                }}
              >
                <See className={classes.icon} />
              </div>
            ) : (
              <div
                onClick={() => {
                  setIsPasswordShowing(prevState => !prevState);
                }}
              >
                <Unsee className={classes.icon} />
              </div>
            )
          }
        />
        <AnimatedError
          isVisible={form.password.isInvalid}
          reason={form.password.reason}
        />
        <div>
          <Checkbox>
            <Text inline noMargin>
              <Translate id="login.rememberMe" />
            </Text>
          </Checkbox>
        </div>
        <Button
          className={classes.marginBetween}
          type="primary"
          size="large"
          onClick={signIn}
          disabled={isLoggingIn}
        >
          {isLoggingIn ? <BeatLoader /> : <Translate id="login.signIn" />}
        </Button>
      </div>
      <div className={classes.div}></div>
    </>
  );
}

export default withLocalize(AdminLanding);
