/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles } from '@material-ui/styles';
import { Divider, Space, Table } from 'antd';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import Axios from 'axios';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import { useSubjectsAdmin } from '../../../../../hooks/administration/useSubjectsAdmin';
import { LanguageType } from '../../../../../types/language-type.enum';
import { RouteSubject } from '../../../../../types/route-subject.interface';
import { mobileThreshhold } from '../../../../../utils';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    '& > *:not(:last-child)': {
      marginRight: '1rem',
    },
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});
interface SubjectRemoveInterface {
  name: string;
  id: string;
}
interface ListProps extends LocalizeContextProps {}
const ListSubjects = ({ translate }: ListProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [subjects, setListSubjects] = useState<RouteSubject[]>([]);
  const [fetchingSubjects] = useSubjectsAdmin(setListSubjects);
  const [subjectRemove, setSubjectRemove] = useState<SubjectRemoveInterface>({
    id: '',
    name: '',
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const tableData = subjects.map(s => {
    return {
      key: s.id,
      subject: s.translations?.find(t => {
        return t.language === LanguageType.PT;
      })?.translation,
      translations: s.translations?.map(t => t.language).join(' | '),
    };
  });
  const removeSubject = useCallback((subject: SubjectRemoveInterface) => {
    setSubjectRemove(subject);
    setShowModal(true);
  }, []);
  const createSubjectCallback = useCallback(() => {
    history.push('/admin/area/subject/create');
  }, [history]);
  const highlightSubjectCallback = useCallback(() => {
    history.push('/admin/area/subject/highlight');
  }, [history]);
  const submitRemoveSubject = useCallback(
    async (id: string) => {
      try {
        const { data: subjects } = await Axios.delete(
          `${API_URL}/subject/${id}`
        );
        message.success(
          translate('adminstration.subjectRemovedSuccess').toString()
        );
        setShowModal(false);
        setListSubjects(subjects);
      } catch (err) {
        message.success(
          translate('adminstration.subjectRemovedError').toString()
        );
      }
    },
    [translate]
  );
  const columns = [
    {
      title: <Translate id="adminstration.subject" />,
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: <Translate id="adminstration.translations" />,
      dataIndex: 'translations',
      key: 'translations',
    },

    {
      title: <Translate id="adminstration.actions" />,
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={`/admin/area/subject/${record.key}`}>
            <Translate id="adminstration.admin" />
          </Link>
          <Divider type="vertical" />
          <a
            onClick={() => {
              removeSubject({ id: record.key, name: record.subject });
            }}
          >
            <Translate id="adminstration.subjectRemove" />
          </a>
        </Space>
      ),
    },
  ];
  if (fetchingSubjects) return <Loader />;

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setShowModal(false);
                setSubjectRemove({ id: '', name: '' });
              }}
            >
              <Translate id="button.cancel" />
            </Button>
            <div className={classes.addRemoveBtn}>
              <Button
                type="primary"
                onClick={() => {
                  submitRemoveSubject(subjectRemove.id);
                }}
              >
                <Translate id="button.remove" />
              </Button>
            </div>
          </div>
        }
        visible={showModal}
        closable={false}
      >
        <Text color={'red'} noMargin weight="semibold">
          {`Pretende remover o Tema: ${subjectRemove.name} ?`}
        </Text>
      </Modal>

      <div className={classes.divAddButton}>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            createSubjectCallback();
          }}
        >
          <Translate id="adminstration.createSubject" />
        </Button>
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={ev => {
            highlightSubjectCallback();
          }}
        >
          <Translate id="adminstration.highlightedSubjects" />
        </Button>
      </div>
      <Table dataSource={tableData} columns={columns} />
    </>
  );
};

export default withLocalize(ListSubjects);
