import Axios from 'axios';
import { API_URL } from '../../consts';
import {
  AdEventDescriptionInterface,
  AdEventTitleInterface,
} from './useAdEvent';

interface EditAdEventDto {
  adEventId: string;
  startAd: Date;
  endAd: Date;
  titles: AdEventTitleInterface[];
  descriptions: AdEventDescriptionInterface[];
  location: string;
  latitude: number;
  longitude: number;
  radius: number;
}

export interface EditAdEventResponse {
  id: string;
  startAd: Date;
  endAd: Date;
  titles: AdEventTitleInterface[];
  descriptions: AdEventDescriptionInterface[];
  longitude: number;
  latitude: number;
  location: string;
  active: boolean;
  radius: number;
}

export const editAdEvent = (dto: EditAdEventDto, image?: File | null) => {
  const formData = new FormData();
  formData.append(
    'editAdEventDto',
    JSON.stringify({
      ...dto,
    })
  );
  if (image) {
    formData.append('event-image', image);
  }

  return Axios({
    method: 'put',
    url: `${API_URL}/publicity/edit-ad-event`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      console.log('ERROR ', err); //TODO : APANHAR ERR
    }

    return Promise.reject(err);
  });
};
