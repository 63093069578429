import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { useCurrentSize } from '../../../hooks/useCurrentSize';
import { RootState } from '../../../store';
import { useIsMobile } from '../../../utils';
import AuthenticatedGuideDrawerFragment from './AuthenticatedGuideDrawerFragment';
import AuthenticatedVisitorDrawerFragment from './AuthenticatedVisitorDrawerFragment';
import UnauthenticatedGuideDrawerFragment from './UnauthenticatedGuideDrawerFragment';
import UnauthenticatedVisitorDrawerFragment from './UnauthenticatedVisitorDrawerFragment';

export interface DrawerProps {
  offset: number;
  setOpenStatus: (open: boolean) => void;
  setShowRegisterModal: (v: boolean) => void;
  setShowLoginModal: (v: boolean) => void;
  setShowLanguageModal: (v: boolean) => void;
  setChangingAccountType: (v: boolean) => void;
  guideIsApproved: boolean;
  activeLanguage: string;
}

const useStyles = makeStyles({
  container: ({
    height,
    width,
  }: {
    height: number | undefined;
    width: number | undefined;
  }) => ({
    backgroundColor: 'white',
    maxHeight: height,
    minHeight: height,
    minWidth: width,
    height,
    zIndex: 1001,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }),
});

export interface DrawerFragmentProps {
  setOpenStatus: (open: boolean) => void;
  setShowLanguageModal: (v: boolean) => void;
  reduxActiveLanguage: string;
}

export default function Drawer({
  offset,
  setOpenStatus,
  setShowRegisterModal,
  setShowLoginModal,
  setShowLanguageModal,
  activeLanguage,
  setChangingAccountType,
  guideIsApproved,
}: DrawerProps) {
  const size = useCurrentSize();
  const isMobile = useIsMobile();
  const classes = useStyles({
    height: !isMobile ? undefined : size.height - offset,
    width: !isMobile ? undefined : size.width,
  });

  const [jwt] = UseJWT();

  const { lastAppZone } = useSelector((state: RootState) => state.app);

  return (
    <div className={classes.container}>
      {lastAppZone === 'guide' ? (
        jwt && jwt.guideId ? (
          <AuthenticatedGuideDrawerFragment
            guideIsApproved={guideIsApproved}
            reduxActiveLanguage={activeLanguage}
            setOpenStatus={setOpenStatus}
            setShowLanguageModal={setShowLanguageModal}
            id={jwt.guideId}
            setChangingAccountType={setChangingAccountType}
          />
        ) : (
          <UnauthenticatedGuideDrawerFragment
            setShowLanguageModal={setShowLanguageModal}
            setOpenStatus={setOpenStatus}
            setShowRegisterModal={setShowRegisterModal}
            setShowLoginModal={setShowLoginModal}
            reduxActiveLanguage={activeLanguage}
          />
        )
      ) : jwt && jwt.visitorId ? (
        <AuthenticatedVisitorDrawerFragment
          reduxActiveLanguage={activeLanguage}
          setOpenStatus={setOpenStatus}
          setShowLanguageModal={setShowLanguageModal}
          id={jwt.visitorId}
          setChangingAccountType={setChangingAccountType}
        />
      ) : (
        <UnauthenticatedVisitorDrawerFragment
          reduxActiveLanguage={activeLanguage}
          setShowLanguageModal={setShowLanguageModal}
          setOpenStatus={setOpenStatus}
          setShowRegisterModal={setShowRegisterModal}
          setShowLoginModal={setShowLoginModal}
        />
      )}
    </div>
  );
}
