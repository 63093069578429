import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { Button } from '../../../../components/App/Button';
import FallbackImage from '../../../../components/App/Image/FallbackImage';
import { Heading, Text } from '../../../../components/Typography';
import { LogoUrl } from '../../../../hooks/customization/getLogo';
import { desktopPadding, getTheme, headerHeight, mobileThreshhold, useIsMobile } from '../../../../utils';
import { SlideFromRightTransition } from '../../../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  header: {
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
  },
  logo: {
    maxHeight: headerHeight,
  },
  image: {
    flex: 1,
    minWidth: '100%',
    height: '35vh',
    objectFit: 'cover',
    backgroundImage: `url(${require('../assets/final_step_image.png')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
      backgroundColor: getTheme().neutral6,
      display: 'flex',
    },
    container: {
      padding: '2rem',
      justifyContent: 'center',
    },
    image: {
      height: '100%',
      width: '100%',
      minHeight: '400px',
      minWidth: '400px',
      borderRadius: '15px',
    },
    buttonContainer: {
      ...desktopPadding,
      margin: '1rem 0',
    },
  },
});

interface FinalStepProps extends LocalizeContextProps {
  onRequestNextStep: () => void;
}

const FinalStep = ({ onRequestNextStep }: FinalStepProps) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={SlideFromRightTransition}
    >
      {isMobile && (
        <div className={classes.header}>
          <FallbackImage
            className={classes.logo}
            alt="logo"
            src={LogoUrl}
            fallback={require('../../../../components/App/Header/assets/logo_cor.png')}
          />
        </div>
      )}
      <div className={classes.body}>
        <div className={classNames(classes.image)} />
        <div className={classNames(classes.container)}>
          <Heading level={2}>
            <Translate id="onboarding.thanks" />
          </Heading>
          <Text>
            <Translate id="onboarding.finalInfo" />
          </Text>
          <div className={classes.buttonContainer}>
            <Button type="primary" onClick={onRequestNextStep} size="large">
              <Translate id="button.conclude" />
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(FinalStep);
