import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/App/Button';
import { Input } from '../../../components/App/Input';
import { Modal } from '../../../components/App/Modal';
import { Text } from '../../../components/Typography';
import { cancelVisitorProfile } from '../../../hooks/visitor/cancelVisitorProfile';
import { logout } from '../../../store/UserConfiguration/actions';
import { complexTranslate } from '../../../utils';

interface CancelVisitorProfileModalProps extends LocalizeContextProps {
  visible: boolean;
  onRequestClose: () => void;
  nameToConfirm: string;
}

const useStyles = makeStyles({
  modalContent: { padding: 24 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    paddingTop: 0,
    flexShrink: 0,
    '& > .segment': {
      display: 'flex',
      '& > *:first-child': {
        marginRight: '1rem',
      },
    },
  },
});

const CancelVisitorProfileModal = ({
  visible,
  onRequestClose,
  translate,
  nameToConfirm,
}: CancelVisitorProfileModalProps) => {
  const [nameConfirm, setNameConfirm] = useState<string>('');

  const handleNameChange = useCallback(e => {
    setNameConfirm(e.target.value);
  }, []);

  const classes = useStyles();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const doAction = useCallback(async () => {
    try {
      setSubmitting(true);

      await cancelVisitorProfile();

      dispatch(logout());
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      title={<Translate id="cancelProfile.cancelTitle" />}
      contentContainerClassName={classes.modalContent}
      footer={
        <div className={classes.footer}>
          <Button disabled={submitting} onClick={onRequestClose}>
            <Translate id="button.exit" />
          </Button>
          <Button
            onClick={doAction}
            disabled={nameConfirm !== nameToConfirm || submitting}
          >
            <Translate id="button.confirm" />
          </Button>
        </div>
      }
    >
      <Text>
        <Translate id="cancelProfile.visitorProfileConfirmation" />
      </Text>
      <Text variant="note">
        {complexTranslate(
          translate('cancelProfile.continueConfirmation').toString(),
          {
            '{NAME}': nameToConfirm,
          }
        )}
      </Text>
      <Input
        disabled={submitting}
        value={nameConfirm}
        onChange={handleNameChange}
      />
    </Modal>
  );
};

export default React.memo(withLocalize(CancelVisitorProfileModal));
