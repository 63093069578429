import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { SubjectAdminInterface } from '../../types/SubjectAdmin.interface';

type Hook = (
  id: string | undefined,
  setSubject: (val: SetStateAction<SubjectAdminInterface | null>) => void
) => [boolean, boolean];

const requestSubject = (id: string) =>
  Axios.get<SubjectAdminInterface>(`${API_URL}/subject/${id}`);

export const useSubjectAdmin: Hook = (id, setSubject) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (!id) {
        setIsError(true);
        setSubject(null);
        setIsLoading(false);
        return;
      }
      try {
        const { data: subject } = await requestSubject(id);

        setSubject(subject);
        setIsError(false);
      } catch (err) {
        setSubject(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, id, setSubject]);

  return [isLoading, isError];
};

export const getSubjectImageUrl = (id: string) =>
  `${API_URL}/subject/${id}/image?_=${moment().unix()}`;
