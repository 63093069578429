import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UseJWT } from '../../hooks/authentication/UseJWT';
import { setLastAppZone } from '../../store/App/actions';
import { RoleType } from '../../types/role-type.enum';
import { AdminArea } from './AdminArea';
import { AdminLanding } from './AdminLanding';

export default function AdminRouter() {
  const [jwt] = UseJWT();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLastAppZone('admin'));
  });

  return (
    <>
      <Switch>
        <Route
          path="/admin/landing"
          render={() => <AdminLanding type={RoleType.ADMINISTRATOR} />}
        />
        <Route
          path="/admin/advertiser/landing"
          render={() => <AdminLanding type={RoleType.AD_OWNER} />}
        />
        <Route
          path="/admin/intermediate/landing"
          render={() => <AdminLanding type={RoleType.INTERMEDIATE} />}
        />
        {
          //if is not authenticated fall into redirect
          jwt && [
            <Route path="/admin/area" key="admin-area" component={AdminArea} />,
          ]
        }

        <Redirect to="/admin/landing" />
      </Switch>
    </>
  );
}
