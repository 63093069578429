import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { AccessibilityAdminInterface } from '../../types/accebilityAdmin.interface';

type Hook = (
  id: string | undefined,
  setAccessibility: (
    val: SetStateAction<AccessibilityAdminInterface | null>
  ) => void
) => [boolean, boolean];

const requestAccessibility = (id: string) =>
  Axios.get<AccessibilityAdminInterface>(`${API_URL}/accessibility/${id}`);

export const useAccessibilityAdmin: Hook = (id, setAccessibility) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (!id) {
        setIsError(true);
        setAccessibility(null);
        setIsLoading(false);
        return;
      }
      try {
        const { data: accessibility } = await requestAccessibility(id);

        setAccessibility(accessibility);
        setIsError(false);
      } catch (err) {
        setAccessibility(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, id, setAccessibility]);

  return [isLoading, isError];
};

export const getSubjectImageUrl = (id: string) =>
  `${API_URL}/subject/${id}/image?_=${moment().unix()}`;
