import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import VisitorProfile from './VisitorProfile';
import VisitorProfileEdit from './VisitorProfileEdit';
import ChangePassword from './ChangePassword';

export default function VisitorProfileRouter() {
  return (
    <Switch>
      <Route path="/visitor/profile/" exact component={VisitorProfile} />
      <Route
        path="/visitor/profile/edit"
        exact
        component={VisitorProfileEdit}
      />
      <Route
        path="/visitor/profile/change-password"
        component={ChangePassword}
      />
      <Redirect to="/visitor/profile" />
    </Switch>
  );
}
