import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { FACEBOOK_ID } from '../../../consts';
import { ReactComponent as Chat } from '../../../shared_assets/icons/chat.svg';
import { ReactComponent as Copy } from '../../../shared_assets/icons/copy.svg';
import { ReactComponent as Email } from '../../../shared_assets/icons/email.svg';
import { ReactComponent as FacebookMessenger } from '../../../shared_assets/icons/facebook-messenger.svg';
import { ReactComponent as Facebook } from '../../../shared_assets/icons/facebook-square.svg';
import { ReactComponent as WhatsApp } from '../../../shared_assets/icons/whatsapp.svg';
import { complexTranslate, useIsMobile } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { Modal } from '../Modal';
import ShareModalItem from './ShareModalItem';

export interface ShareModalProps extends LocalizeContextProps {
  routeId: string;
  routeTitle: string;
  title: string;
  isVisible: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  header: {
    margin: '1rem 0 ',
    '& h1': {
      fontWeight: 'bold',
      marginBottom: 'unset',
    },
  },
  container: {
    '& .ant-divider-horizontal': {
      margin: '0.5rem 0',
    },
  },
  icon: {
    height: '1.5rem',
    width: '1.5rem',
    '& img, & svg': {
      height: '1.5rem',
      width: '1.5rem',
    },
  },
});

function ShareModal({
  translate,
  routeId,
  routeTitle,
  title,
  isVisible,
  onClose,
}: ShareModalProps) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Modal
      visible={isVisible}
      variant="close"
      onRequestClose={() => {
        onClose();
      }}
      footer={null}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Heading level={2}>
            <Translate id="share.share" />
          </Heading>
          <Text>{title}</Text>
        </div>
        <CopyToClipboard
          text={window.location.toString()}
          onCopy={() => {
            message.info(translate('share.copiedSuccessfully'));
          }}
        >
          <ShareModalItem
            title={<Translate id="share.copy" />}
            icon={<Copy className={classes.icon} />}
          />
        </CopyToClipboard>
        <Divider />
        <ShareModalItem
          href={`mailto:?subject=${encodeURIComponent(
            translate('share.emailSubject').toString()
          )}&body=${encodeURIComponent(
            complexTranslate(translate('share.emailBody').toString(), {
              '{TITLE}': routeTitle,
              '{URL}': window.location.toString(),
            })
              .join('')
              .toString()
          )}`}
          title={<Translate id="share.sendEmail" />}
          icon={<Email className={classes.icon} />}
        />
        <Divider />
        {isMobile && (
          <>
            <ShareModalItem
              href={`https://wa.me/?text=${encodeURIComponent(
                complexTranslate(translate('share.emailBody').toString(), {
                  '{TITLE}': routeTitle,
                  '{URL}': window.location.toString(),
                })
                  .join('')
                  .toString()
              )}`}
              title={<Translate id="share.whatsapp" />}
              icon={<WhatsApp className={classes.icon} />}
            />
            <Divider />
          </>
        )}
        <ShareModalItem
          title={<Translate id="share.messenger" />}
          onClick={() => {
            if (isMobile) {
              /*MOTHER FUCKING FUCKER HOW IS THIS SO HARD TO GOOGLE*/
              //@ts-ignore
              window.location = `fb-messenger://share/?link=${window.location.toString()}&app_id=${FACEBOOK_ID}`;
            } else {
              //@ts-ignore
              FB.ui({ method: 'send', link: window.location.toString() });
            }
          }}
          icon={<FacebookMessenger className={classes.icon} />}
        />
        <Divider />
        <ShareModalItem
          href={`sms:?body=${
            translate('share.emailSubject').toString() +
            ' ' +
            complexTranslate(translate('share.emailBody').toString(), {
              '{TITLE}': routeTitle,
              '{URL}': window.location.toString(),
            })
              .join('')
              .toString()
          }`}
          title={<Translate id="share.sms" />}
          icon={<Chat className={classes.icon} />}
        />
        <Divider />
        <ShareModalItem
          onClick={() => {
            //@ts-ignore
            FB.ui({
              method: 'feed',
              link: window.location.toString(),
            });
          }}
          title={<Translate id="share.facebook" />}
          icon={<Facebook className={classes.icon} />}
        />
      </div>
    </Modal>
  );
}

export default withLocalize(ShareModal);
