import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Button as LinkButton } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { FallbackImage } from '../../../../../components/App/Image';
import { Loader } from '../../../../../components/App/Loader';
import { TabPane, Tabs } from '../../../../../components/App/Tabs';
import { Text } from '../../../../../components/Typography';
import {
  LandingPageDto,
  postVisitorLandingPage,
  VisitorLandingPageDto,
} from '../../../../../hooks/administration/usePostVisitorlandingPage';
import {
  getHeaderImageUrl,
  useVisitorLandingPage,
} from '../../../../../hooks/administration/useVisitorLandingPage';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as Pediguia } from '../../../../../shared_assets/images/pediguia_default.svg';
import { complexTranslate, maxAllowedImageSize } from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';
const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    '& .ant-tabs-content-holder': {
      overflow: 'auto',
    },
  },
  divider: {
    margin: '1rem 0',
  },
  titleDiv: {
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  avatarArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarPreview: {
    maxHeight: '25rem',
    marginBottom: '1rem',
  },
  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  button: {
    marginLeft: '1rem',
  },
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

interface VisitorLandingPageProps extends LocalizeContextProps {}
const VisitorLandingPage = ({
  languages,
  translate,
}: VisitorLandingPageProps) => {
  const classes = useStyles();
  const [jwt] = UseJWT();

  const imageInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const previewSource = useMemo(() => [image], [image]);
  const imagePreview = useFilePreview(previewSource);
  const [landingPage, setLandingPage] = useState<VisitorLandingPageDto[]>([]);
  const [landingPageLocal, setLandingPageLocal] = useState<
    VisitorLandingPageDto[]
  >([]);
  const [fetchingLandingPageInfo, errorFetchingLandingPageInfo] =
    useVisitorLandingPage(setLandingPage);
  useEffect(() => {
    setLandingPageLocal(
      languages
        .map(la => la.code)
        .map(l => {
          const landingPageTranslation = landingPage.find(
            lp => lp.language === l
          );
          if (landingPageTranslation) {
            return landingPageTranslation;
          }
          return {
            firstTitle: '',
            intro: '',
            experiencesTitle: '',
            featuredRoutesTitle: '',
            interestTitle: '',
            joinCommunityTitle: '',
            joinCommunityText: '',
            callToActionUrl: '',
            searchPlaceholder: '',
            searchTitle: '',
            searchSubtitle: '',
            howToStartSubtitle: '',
            howToStartTitle: '',
            language: l,
          };
        })
    );
  }, [languages, landingPage]);
  const submit = useCallback(async () => {
    if (
      landingPageLocal.some(
        s =>
          validator.isEmpty(s.language) ||
          validator.isEmpty(s.joinCommunityTitle) ||
          validator.isEmpty(s.joinCommunityText) ||
          validator.isEmpty(s.interestTitle) ||
          validator.isEmpty(s.featuredRoutesTitle) ||
          validator.isEmpty(s.experiencesTitle) ||
          validator.isEmpty(s.searchPlaceholder) ||
          validator.isEmpty(s.searchSubtitle) ||
          validator.isEmpty(s.searchTitle)
      )
    ) {
      message.error(
        translate('adminstration.landingPage.emptyMandatoryField').toString()
      );
      return;
    }
    const dto: LandingPageDto = {
      landing: landingPageLocal.map(fs => {
        return {
          experiencesTitle: fs.experiencesTitle,
          featuredRoutesTitle: fs.featuredRoutesTitle,
          firstTitle: fs.firstTitle,
          interestTitle: fs.interestTitle,
          intro: fs.intro,
          joinCommunityText: fs.joinCommunityText,
          joinCommunityTitle: fs.joinCommunityTitle,
          callToActionUrl: fs.callToActionUrl,
          language: fs.language,
          searchPlaceholder: fs.searchPlaceholder,
          searchSubtitle: fs.searchSubtitle,
          searchTitle: fs.searchTitle,
          howToStartSubtitle: fs.howToStartSubtitle,
          howToStartTitle: fs.howToStartTitle,
        };
      }),
    };

    await postVisitorLandingPage(dto, image);
    message.success(
      translate('adminstration.landingPage.updateSuccessful').toString()
    );
  }, [landingPageLocal, image, translate]);
  if (!jwt) {
    return <Redirect to="/admin/landing" />;
  }
  if (fetchingLandingPageInfo) {
    return <Loader />;
  }
  if (errorFetchingLandingPageInfo) {
    return <Error />;
  }
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classes.titleDiv}>
        <Text noMargin weight="semibold">
          <Translate id="adminstration.visitorLandingPage.title" />
        </Text>
      </div>

      <div className={classes.avatarArea}>
        <FallbackImage
          className={classes.avatarPreview}
          src={imagePreview?.[0] || getHeaderImageUrl()}
          fallbackComponent={<Pediguia className={classes.avatarPreview} />}
        />
        <div className={classes.changeAvatarButton}>
          <Translate id="adminstration.changeSubjectImage" />
          <input
            className={classes.button}
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <LinkButton
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </LinkButton>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <Tabs>
        {landingPageLocal.map((state, index) => {
          return (
            <TabPane
              tab={translate(`languages.${state.language}`)}
              key={`${index}`}
            >
              {/* *********************ANCHOR firstTitle ************************ */}

              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.firstTitle" />
              </Text>

              <Input
                value={state.firstTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          firstTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <Divider className={classes.divider} />
              {/* *********************ANCHOR INTRO ************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.intro" />
              </Text>
              <Input
                value={state.intro}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          intro: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <Divider className={classes.divider} />
              {/* *******************ANCHOR experiencesTitle *********************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.experiencesTitle" />
              </Text>
              <Input
                value={state.experiencesTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          experiencesTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.experiencesTitle)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.searchTitle" />
              </Text>
              <Input
                value={state.searchTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          searchTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.searchTitle)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.searchSubtitle" />
              </Text>
              <Input
                value={state.searchSubtitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          searchSubtitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.searchSubtitle)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.searchPlaceholder" />
              </Text>
              <Input
                value={state.searchPlaceholder}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          searchPlaceholder: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.searchPlaceholder)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* ***************ANCHOR featuredRoutesTitle*********************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.featuredRoutesTitle" />
              </Text>
              <Input
                value={state.featuredRoutesTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          featuredRoutesTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.featuredRoutesTitle)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *************ANCHOR interestTitle **************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.interestTitle" />
              </Text>
              <Input
                value={state.interestTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          interestTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.interestTitle)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *************ANCHOR joinCommunityTitle ************************* */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.joinCommunityTitle" />
              </Text>
              <Input
                value={state.joinCommunityTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          joinCommunityTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.joinCommunityTitle)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              <Divider className={classes.divider} />
              {/* *****************ANCHOR call to action text************************ */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.joinCommunityText" />
              </Text>
              <Input
                value={state.joinCommunityText}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          joinCommunityText: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.joinCommunityText)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.callToActionUrl" />
              </Text>
              <Input
                value={state.callToActionUrl}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          callToActionUrl: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError
                  isVisible={validator.isEmpty(state.callToActionUrl)}
                  reason={
                    <Translate id="adminstration.visitorLandingPage.mandatoryField" />
                  }
                />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.howToStartTitle" />
              </Text>
              <Input
                value={state.howToStartTitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          howToStartTitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError />
              </div>
              {/* ************************************************** */}
              <Text noMargin weight="semibold">
                <Translate id="adminstration.visitorLandingPage.howToStartSubtitle" />
              </Text>
              <Input
                value={state.howToStartSubtitle}
                onChange={ev => {
                  ev.persist();
                  setLandingPageLocal(previousStates =>
                    previousStates.map(oldState => {
                      if (oldState.language === state.language)
                        return {
                          ...oldState,
                          howToStartSubtitle: ev.target.value,
                        };
                      else return oldState;
                    })
                  );
                }}
              ></Input>
              <div>
                <AnimatedError />
              </div>
            </TabPane>
          );
        })}
      </Tabs>
      <Divider className={classes.divider} />
      <Button
        type="primary"
        onClick={() => {
          submit();
        }}
      >
        <Translate id="button.save" />
      </Button>
    </motion.div>
  );
};

export default withLocalize(VisitorLandingPage);
