import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import Empty from 'antd/es/empty';
import message from 'antd/es/message';
import Axios from 'axios';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Badge } from '../../../../../components/App/Badge';
import { Button } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { FallbackImage } from '../../../../../components/App/Image';
import { TextArea } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { PointDescriptionList } from '../../../../../components/App/PointDescriptionList';
import { Option, Select } from '../../../../../components/App/Select';
import { VisitorComment } from '../../../../../components/App/VisitorComment';
import { Heading, Text } from '../../../../../components/Typography';
import { API_URL } from '../../../../../consts';
import {
  AccessibilityWithSingleTranslation,
  useAccessibilities,
} from '../../../../../hooks/accessibilities/useAccessibilities';
import { useRouteReview } from '../../../../../hooks/routes/review/UseRouteReviews';
import {
  getRouteLocationImageUrl,
  useRouteAdmin,
} from '../../../../../hooks/routes/useRouteAdmin';
import { useRouteSchedules } from '../../../../../hooks/schedule/UseRouteSchedules';
import {
  RouteSubjectWithSingleTranslation,
  useSubjects,
} from '../../../../../hooks/subjects/useSubjects';
import { ReactComponent as Difficulty } from '../../../../../shared_assets/icons/difficulty.svg';
import { ReactComponent as Group } from '../../../../../shared_assets/icons/group.svg';
import { ReactComponent as Language } from '../../../../../shared_assets/icons/language.svg';
import { ReactComponent as RouteIcon } from '../../../../../shared_assets/icons/route.svg';
import { ReactComponent as StarWhite } from '../../../../../shared_assets/icons/star white.svg';
import { ReactComponent as Time } from '../../../../../shared_assets/icons/time.svg';
import { ReactComponent as Wheelchair } from '../../../../../shared_assets/icons/wheelchair.svg';
import { RootState } from '../../../../../store';
import { setMarkerCount } from '../../../../../store/App/actions';
import { AdminStats } from '../../../../../store/App/types';
import { Review } from '../../../../../types/review.interface';
import { RouteStateType } from '../../../../../types/route-state.enum';
import { Route } from '../../../../../types/route.interface';
import { Schedule } from '../../../../../types/schedule-new';
import {
  desktopPadding,
  getTheme,
  joinWithAnd,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';
import { RouteDatesModal } from '../../../../Route/Dates';
import DatePreview from '../../../../Route/Details/DatePreview';
import LocationsMap from '../../../../Route/Details/LocationsMap';
//TODO: ROUTE PRICE
const useStyles = makeStyles({
  evaluateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  cancelButton: {
    backgroundColor: '#e60000',
    borderColor: '#e60000',
  },
  evaluateApproveButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
    marginLeft: '50px',
  },
  confirmButton: {
    backgroundColor: '#009900',
    borderColor: '#009900',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerMenu: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  headerBody: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '30rem',
  },
  carousel: () => ({
    '& .slide': {
      pointerEvents: 'none',
    },
    '& .control-dots': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: 'unset !important',
      justifyContent: 'center',
      right: 0,
      '& > *': {
        height: 8,
        width: 8,
        margin: '8px 1rem',
        borderRadius: '100%',
        borderWidth: 2,
        backgroundColor: 'transparent',
        display: 'inline-block',
        borderColor: 'white',
        borderStyle: 'solid',
        cursor: 'pointer',
        '&.active': {
          backgroundColor: getTheme().primary,
          borderColor: getTheme().primary,
        },
      },
    },
  }),
  carouselImage: {
    height: '30rem',
    objectFit: 'cover',
    objectPosition: 'center',
    pointerEvents: 'all !important' as 'all',
  },
  headerTextBody: {
    position: 'absolute',
    bottom: 0,
    padding: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    ...mobilePadding,
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
  },
  selectContainer: () => ({
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-select-selector': {
      backgroundColor: `${getTheme().primary} !important`,
      border: `1px solid ${getTheme().primary} !important`,
      color: 'white',
    },
    '& .ant-select-arrow': {
      color: 'white',
    },
  }),
  personalInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  remainingInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  profilePicture: {
    maxWidth: '12rem',
    maxHeight: '12rem',
    borderRadius: '5px',
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    height: '70px',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.75)',
    backgroundColor: '#f1f5f7',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 1000,
    padding: '1rem',
  },
  marginBetween: {
    marginTop: '1.5rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
  statsContainer: {
    marginTop: '1rem',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(150px, 1fr))`,
    gridAutoRows: '1fr',
    columnGap: '1rem',
  },
  statItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    marginBottom: '0.5rem',
  },
  dateButton: {
    // marginTop: '1rem',
  },
  '& .ant-picker td': {
    pointerEvents: 'none',
  },
  bold: {
    fontWeight: `${600} !important` as unknown as number,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  marginFooter: {
    margin: '2rem 0 4rem 0',
  },

  starRating: {
    margin: '0 2px',
    height: 10,
    width: 10,
  },
  blue: () => ({
    color: `${getTheme().primary} !important`,
    marginLeft: '1rem',
  }),
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      ...desktopPadding,
    },

    headerTextBody: {
      ...desktopPadding,
    },
    selectContainer: {
      alignSelf: 'center',
      width: '30rem',
    },
    personalInformation: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      '& > :first-child': {
        flex: 0.3,
        marginRight: '2rem',
      },
      '& > :last-child': {
        flex: 0.7,
      },
    },
    remainingInformation: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      '& > :first-child': {
        flex: 0.4,
        marginRight: '2rem',
      },
      '& > :last-child': {
        flex: 0.6,
      },
    },
    map: {
      height: 400,
    },
    marginBetween: {
      marginTop: '3rem',
    },
    statsContainer: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
});
interface ErrorInterface {
  error: boolean;
  reason: string;
}

interface RouteParams {
  routeId: string;
}

interface RouteDetailsProps extends LocalizeContextProps {
  openLogin: () => void;
}

function RouteDetailsAdmin({ translate, openLogin }: RouteDetailsProps) {
  const { routeId } = useParams<RouteParams>();
  const classes = useStyles();
  const monthLimit = 5;
  const dispatch = useDispatch();

  const initialActiveLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [activeLanguage, setActiveLanguage] = useState<string>(
    initialActiveLanguage
  );
  const [evaluateRoute, setEvaluateRoute] = useState<boolean>(false);
  const [route, setRoute] = useState<Route | null>(null);
  const [reason, setReason] = useState('');
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);
  // const [errorMessage, setErrorMessage] = useState<string>('');
  // const [thereWasABookingError, setThereWasABookingError] = useState<boolean>(
  //   false
  // );

  const [isLoadingRoute, hasErrorRoute] = useRouteAdmin(
    routeId,
    setRoute,
    true
  );
  const [isLoadingSchedules, hasErrorSchedules] = useRouteSchedules(
    routeId,
    setSchedules
  );

  const [isLoadingReviews, hasErrorReviews] = useRouteReview(
    routeId,
    setReviews
  );
  const [remoteSubjects, setRemoteSubjects] = useState<
    RouteSubjectWithSingleTranslation[]
  >([]);
  const [remoteSubjectsAreLoading, remoteSubjectsHaveError] = useSubjects(
    setRemoteSubjects,
    'pt_PT'
  );

  const [remoteAccessibilities, setRemoteAccessibilities] = useState<
    AccessibilityWithSingleTranslation[]
  >([]);
  const [remoteAccessibilitiesAreLoading, remoteAccessibilitiesHaveError] =
    useAccessibilities(setRemoteAccessibilities);

  const [isShowingDateModal, setIsShowingDateModal] = useState(false);

  const photosLocations = useMemo(
    () =>
      route
        ? route.locations
            .filter(location => {
              return location.photos.length > 0;
            })
            .map(location => {
              return location.photos.map(photo => photo.id);
            })
            .flat()
        : [],
    [route]
  );

  const schedulesRoute = useMemo(
    () =>
      schedules
        .map(schedule =>
          schedule.repetitions.map(
            repetitionSchedule => repetitionSchedule.date
          )
        )
        .flat(),
    [schedules]
  );
  const evalError = React.useMemo((): ErrorInterface => {
    if (validator.isEmpty(reason)) {
      return {
        error: true,
        reason: 'Reason should not be empty',
      };
    }
    return { error: false, reason: '' };
  }, [reason]);
  const approveRouteCallBack = useCallback(async () => {
    if (!route) return;

    try {
      const { data: routeReceived } = await Axios.patch(
        `${API_URL}/route/${route.id}/status`,
        {
          status: RouteStateType.APPROVED,
          description: ' ',
        }
      );
      setRoute(routeReceived);
      const { data } = await Axios.get<AdminStats>(
        `${API_URL}/administrator/stats`
      );
      dispatch(setMarkerCount(data as AdminStats));
      setEvaluateRoute(false);
      message.success(translate('adminstration.evalRoute').toString());
    } catch (err) {}
  }, [route, dispatch, translate]);

  const noApproveRouteCallBack = useCallback(async () => {
    if (!route) return;
    if (evalError.error) return;

    try {
      const { data: routeReceived } = await Axios.patch(
        `${API_URL}/route/${route.id}/status`,
        {
          status: RouteStateType.NOT_APPROVED,
          description: reason,
        }
      );
      setRoute(routeReceived);
      const { data } = await Axios.get<AdminStats>(
        `${API_URL}/administrator/stats`
      );
      dispatch(setMarkerCount(data as AdminStats));
      setEvaluateRoute(false);
      setReason('');
      message.success(translate('adminstration.evalRoute').toString());
    } catch (err) {}
  }, [route, evalError.error, reason, dispatch, translate]);
  const routeTitle = useMemo(() => {
    if (!route) return;
    const translation = route.titles.find(t => t.language === activeLanguage);
    return translation
      ? translation.title
      : route.titles[0]
      ? route.titles[0].title
      : '';
  }, [activeLanguage, route]);
  const pointDescriptions = useMemo(
    () =>
      !route
        ? []
        : route.locations
            .sort((a, b) => a.order - b.order)
            .map((location, id) => {
              let position = 'middle';
              if (id === 0) {
                position = 'start';
              } else if (id === route.locations.length - 1) {
                position = 'end';
              }
              return {
                title: location.address,
                description: location.descriptions.find(
                  descriptionLanguage =>
                    descriptionLanguage.language === activeLanguage
                )?.description,
                locationTitle: location.titles.find(
                  titleLanguage => titleLanguage.language === activeLanguage
                )?.title,
                position: position,
                localBusiness: location.localBusiness
                  ? translate(`routeDetails.localBusiness`).toString()
                  : '',
              };
            }),
    [route, activeLanguage, translate]
  );
  const closeDatesModal = useCallback(() => setIsShowingDateModal(false), []);
  const showEvaluateModal = useCallback(() => {
    setEvaluateRoute(true);
  }, []);

  if (
    isLoadingRoute ||
    isLoadingSchedules ||
    isLoadingReviews ||
    remoteSubjectsAreLoading ||
    remoteAccessibilitiesAreLoading
  ) {
    return <Loader />;
  }
  if (
    hasErrorRoute ||
    !route ||
    hasErrorSchedules ||
    !schedules ||
    hasErrorReviews ||
    !reviews ||
    remoteAccessibilitiesHaveError ||
    remoteSubjectsHaveError
  ) {
    return <Error title={translate('error.basic').toString()} />;
  }

  return (
    <>
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        className={classes.page}
        variants={GenericPageTransition}
      >
        <div className={classNames(classes.headerBody)}>
          {photosLocations.length > 0 ? (
            <Carousel
              className={classes.carousel}
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              autoPlay={true}
              renderIndicator={(onClick, isSelected) => (
                <div
                  onClick={onClick}
                  className={isSelected ? 'active' : undefined}
                />
              )}
              infiniteLoop
              axis="vertical"
            >
              {photosLocations.map((photoId, i) => (
                <FallbackImage
                  key={photoId}
                  src={getRouteLocationImageUrl(photoId)}
                  className={classes.carouselImage}
                  fallback={require('../../../../../shared_assets/images/route-default.png')}
                />
              ))}
            </Carousel>
          ) : (
            <FallbackImage
              src={require('../../../../../shared_assets/images/route-default.png')}
              className={classes.carouselImage}
            />
          )}

          <div className={classes.headerTextBody}>
            <Heading color="white" level={6} className={classes.uppercase}>
              {route.location}
            </Heading>
            <Heading
              color={'#FFFFFF'}
              ellipsis={{ rows: 4 }}
              noMargin
              level={3}
            >
              {routeTitle}
            </Heading>
            <Text color="white" className={classes.alignItems}>
              <span className={classes.bold}>{route.averageRating}</span>
              <StarWhite className={classes.starRating} />
              <span>({route.numberOfRatings})</span>
            </Text>
            {route.subjects.map((subject, i) => (
              <span key={i} style={{ marginRight: '0.3rem' }}>
                <Badge key={i}>
                  {remoteSubjects.find(sub => sub.id === subject.id)?.name ||
                    'TRANSLATION NOT FOUND'}
                </Badge>
              </span>
            ))}
          </div>
        </div>

        <div className={classes.container}>
          {route.languages.length > 1 && (
            <div className={classes.selectContainer}>
              <Select
                huge
                value={activeLanguage}
                onChange={value => {
                  setActiveLanguage(value.toString());
                }}
                placeholder={translate('routeDetails.seeOtherLanguage')
                  .toString()
                  .toUpperCase()}
              >
                {route.languages.map((language, i) => (
                  <Option
                    key={i}
                    value={language.toString()}
                    label={translate(
                      `languages.${language.toString()}`
                    ).toString()}
                  >
                    {/* <Text color={'#FFFFFF'} noMargin> */}
                    <Translate id={`languages.${language.toString()}`} />
                    {/* </Text> */}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          <div className={classes.personalInformation}>
            <div>
              <div className={classes.marginBetween}>
                <Heading level={2}>
                  <Translate id="routeDetails.willDo" />
                </Heading>
                <Text variant="faded" noMargin>
                  {
                    route.descriptions.find(
                      descriptionLanguage =>
                        descriptionLanguage.language === activeLanguage
                    )?.description
                  }
                </Text>
              </div>
              <div className={classNames(classes.statsContainer)}>
                <div className={classNames(classes.statItem)}>
                  <Time className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.duration" />
                  </Text>
                  <Text variant="faded">
                    {translate('routeDetails.durationInfo')
                      .toString()
                      .replace('{number}', (route.duration / 60).toString())}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Group className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.visitors" />
                  </Text>
                  <Text variant="faded">
                    {translate('routeDetails.visitorsInfo')
                      .toString()
                      .replace('{number}', route.groupSize.toString())}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Language className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.language" />
                  </Text>
                  <Text variant="faded">
                    {joinWithAnd(
                      route.languages.map(language =>
                        translate(`languages.${language}`).toString()
                      ),
                      translate('and').toString()
                    )}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Difficulty className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.difficulty" />
                  </Text>
                  <Text variant="faded">
                    {translate(`difficulties.${route.difficulty}`).toString()}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <RouteIcon className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.routeExtension" />
                  </Text>
                  <Text variant="faded">
                    {translate('routeDetails.routeExtensionInfo')
                      .toString()
                      .replace(
                        '{number}',
                        route.routeLength.toFixed(2).toString()
                      )}
                  </Text>
                </div>
                <div className={classNames(classes.statItem)}>
                  <Difficulty className={classes.icon} />
                  <Text weight="bold" noMargin>
                    <Translate id="routeDetails.groupAge" />
                  </Text>
                  <Text variant="faded">
                    {translate(`groupAgeType.${route.groupAge}`).toString()}
                  </Text>
                </div>
                {route.accessibilities.length > 0 && (
                  <div className={classNames(classes.statItem)}>
                    <Wheelchair className={classes.icon} />
                    <Text weight="bold">
                      <Translate id="routeDetails.accessibilityConditions" />
                    </Text>
                    <Text variant="faded" noMargin>
                      {joinWithAnd(
                        route.accessibilities.map(
                          acc =>
                            remoteAccessibilities.find(
                              rAcc => rAcc.id === acc.id
                            )?.name || 'NO TRANSLATION FOUND'
                        ),
                        translate('and').toString()
                      )}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.marginBetween}>
            <Heading level={2}>
              <Translate id="routeDetails.experienceLocation" />
            </Heading>
            <div>
              <LocationsMap locations={route.locations} />
            </div>
          </div>
          <div
            className={classNames(
              classes.marginBetween,
              classes.remainingInformation
            )}
          >
            <div>
              <Heading level={2}>
                <Translate id="routeDetails.placeStart" />
              </Heading>
            </div>
            <div>
              <PointDescriptionList
                data={pointDescriptions.filter(pd => pd.position === 'start')}
              />
            </div>
          </div>
          {pointDescriptions.find(pd => pd.position === 'middle') ? (
            <div
              className={classNames(
                classes.marginBetween,
                classes.remainingInformation
              )}
            >
              <div>
                <Heading level={2}>
                  <Translate id="routeDetails.placesVisit" />
                </Heading>
              </div>
              <div>
                <PointDescriptionList
                  data={pointDescriptions.filter(
                    pd => pd.position === 'middle'
                  )}
                />
              </div>
            </div>
          ) : null}

          <div
            className={classNames(
              classes.marginBetween,
              classes.remainingInformation
            )}
          >
            <div>
              <Heading level={2}>
                <Translate id="routeDetails.placeEnd" />
              </Heading>
            </div>
            <div>
              <PointDescriptionList
                data={pointDescriptions.filter(pd => pd.position === 'end')}
              />
            </div>
          </div>
          <div
            className={classNames(
              classes.marginBetween,
              classes.remainingInformation
            )}
          >
            <div>
              <Heading level={2}>
                <Translate id="routeDetails.availableDates" />
              </Heading>
            </div>
            <div>
              <RouteDatesModal
                monthLimit={monthLimit}
                routeId={routeId}
                price={route.adultPrice}
                duration={route.duration}
                isVisible={isShowingDateModal}
                onClose={closeDatesModal}
                openLogin={openLogin}
              />
              <DatePreview schedules={schedulesRoute} monthLimit={monthLimit} />
            </div>
          </div>
          <div
            className={classNames(
              classes.marginBetween,
              classes.remainingInformation
            )}
          >
            <div>
              <Heading level={2}>
                <Translate id="routeDetails.comments" />
              </Heading>
            </div>
            <div>
              {reviews.length > 0 ? (
                <>
                  {reviews.map((review, i) => (
                    <div
                      style={{ margin: '1.5rem 0 0.7rem 0' }}
                      key={review.id}
                    >
                      <VisitorComment
                        visitorId={review.visitorId}
                        commentText={review.description}
                        visitorName={review.visitorName}
                        date={review.dateOfCreation}
                        rating={review.rating}
                      />
                      {
                        //Last comment has no divider
                        i !== reviews.length - 1 && <Divider />
                      }
                    </div>
                  ))}
                  <Button type="link" size="large" onlyText>
                    <Text variant="link" weight="bold" noMargin>
                      <Translate id="routeDetails.showMore" />
                    </Text>
                  </Button>
                </>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <Translate id="routeDetails.dontExistComments" />
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.evaluateButton}>
          {/* //ANCHOR MODAL */}
          <Modal
            fullScreenOnMobile={false}
            contentContainerClassName={classes.modalContent}
            headerTitle={<Translate id="button.addSkill" />}
            footer={
              <div className={classes.footer}>
                <Button
                  onClick={() => {
                    setReason('');
                    setEvaluateRoute(false);
                  }}
                >
                  <Translate id="button.cancel" />
                </Button>
                <div className={classes.addRemoveBtn}>
                  <Button
                    type="primary"
                    onClick={() => {
                      noApproveRouteCallBack();
                    }}
                  >
                    <Translate id="button.save" />
                  </Button>
                </div>
              </div>
            }
            visible={evaluateRoute}
            closable={false}
          >
            <Text weight="semibold">
              <Translate id="adminstration.reason" />
            </Text>
            <TextArea
              value={reason}
              rows={10}
              onChange={ev => {
                ev.persist();
                setReason(ev.target.value);
              }}
            />
            <AnimatedError
              isVisible={evalError.error}
              reason={evalError.reason}
            />
          </Modal>

          {route.state !== RouteStateType.DUPLICATED ? (
            <>
              <Button
                type="primary"
                shape="round"
                className={classes.cancelButton}
                size={'large'}
                onClick={ev => {
                  showEvaluateModal();
                }}
              >
                <Translate id="adminstration.notApprove" />
              </Button>

              <Button
                type="primary"
                shape="round"
                className={classes.evaluateApproveButton}
                size={'large'}
                onClick={ev => {
                  approveRouteCallBack();
                }}
              >
                <Translate id="adminstration.approve" />
              </Button>
            </>
          ) : null}
        </div>
      </motion.div>
    </>
  );
}

export default withLocalize(RouteDetailsAdmin);
