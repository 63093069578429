/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/styles';
import { Space, Table } from 'antd';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import React, { useCallback, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import { Input } from '../../../../../components/App/Input';
import { Loader } from '../../../../../components/App/Loader';
import { Modal } from '../../../../../components/App/Modal';
import { removeMember } from '../../../../../hooks/administration/removeMember';
import {
  MemberInterface,
  useListAdminMembers,
} from '../../../../../hooks/administration/useListAdminMembers';
import { mobileThreshhold } from '../../../../../utils';
import CreateMemberModal from '../CreateMemberModal';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  filterZone: {
    '& > *:nth-child(2)': { flexGrow: 1, marginLeft: '1rem' },
    display: 'flex',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});

const ListMembers = ({ translate }: LocalizeContextProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [members, setListMembers] = useState<MemberInterface[]>([]);
  const [search, setSearch] = useState<string>('');
  const [adding, setAdding] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string | undefined>();
  const [removing, setRemoving] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const dto = useMemo(
    () => ({
      search,
    }),
    [search]
  );
  const [fetchingMembers] = useListAdminMembers(setListMembers, dto);

  const columns = useMemo(() => {
    return [
      {
        title: <Translate id="administration.member.name" />,
        dataIndex: 'name',
        key: 'name',
      },

      {
        title: <Translate id="administration.member.url" />,
        dataIndex: 'url',
        key: 'url',
      },
      {
        title: <Translate id="administration.member.uniquepathURL" />,
        dataIndex: 'uniquepathUrl',
        key: 'uniquepathUrl',
      },
      {
        title: <Translate id="administration.member.type" />,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: <Translate id="administration.member.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <Button
              type="link"
              onClick={() => {
                setEditingId(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.edit" />
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                setRemoving(record.id);
              }}
            >
              <Translate id="adminstration.helpZone.remove" />
            </Button>
          </Space>
        ),
      },
    ];
  }, []);
  const onAdd = useCallback(
    (newMember: MemberInterface) => {
      setListMembers(prevMember => [...prevMember, newMember]);
      setAdding(false);
      message.success(translate('administration.member.memberSuccess'));
    },
    [translate]
  );

  const onEdit = useCallback(
    (changedMember: MemberInterface) => {
      setListMembers(prevHelp =>
        prevHelp.map(cm => (cm.id === changedMember.id ? changedMember : cm))
      );
      setEditingId(undefined);
      message.success(translate('administration.member.memberEditSuccess'));
    },
    [translate]
  );

  const onDelete = useCallback(async () => {
    try {
      setSubmitting(true);
      if (removing) await removeMember(removing);
      setRemoving(undefined);
      setListMembers(prev => prev.filter(h => h.id !== removing));
      message.success(translate('administration.member.memberDeleteSuccess'));
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [removing, translate]);
  const closeAdding = useCallback(() => {
    setAdding(false);
  }, []);

  return (
    <>
      <CreateMemberModal
        memberContent={members.find(m => m.id === editingId)}
        onEdit={onEdit}
        visible={editingId !== undefined}
        onRequestClose={() => {
          setEditingId(undefined);
        }}
      />
      <Modal
        visible={!!removing}
        onRequestClose={() => {
          setRemoving(undefined);
        }}
        footer={
          <div className={classes.footer}>
            <Button
              disabled={submitting}
              onClick={() => setRemoving(undefined)}
            >
              <Translate id="button.cancel" />
            </Button>
            <Button disabled={submitting} onClick={onDelete}>
              <Translate id="button.remove" />
            </Button>
          </div>
        }
        contentContainerClassName={classes.header}
      >
        <Translate id="administration.member.removeWarning" />
      </Modal>
      <div className={classes.divAddButton}>
        <div className={classes.filterZone}>
          <Input
            value={search}
            placeholder={translate('adminstration.search').toString()}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div>
          <Button
            type="primary"
            size={'large'}
            onClick={ev => {
              setAdding(true);
            }}
          >
            <Translate id="adminstration.addMember" />
          </Button>
        </div>
      </div>
      {fetchingMembers ? (
        <Loader />
      ) : (
        <>
          <CreateMemberModal
            onAdd={onAdd}
            onRequestClose={closeAdding}
            visible={adding}
          />
          <Table dataSource={members} columns={columns} />
        </>
      )}
    </>
  );
};

export default withLocalize(ListMembers);
