import React from 'react';
import ANTDSelect from 'antd/es/select';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  hugeInput: {
    height: 48,
  },
});

interface OptionProps {
  huge?: boolean;
  className?: string;
  children?: React.ReactNode;
  value: string | number;
}

function Option({ className, huge, children, ...props }: OptionProps) {
  const classes = useStyles();
  //TODO: This is actually not being called wtf

  return (
    <ANTDSelect.Option
      {...props}
      className={
        huge ? classNames(classes.hugeInput, className) : classNames(className)
      }
    >
      {children}
    </ANTDSelect.Option>
  );
}

export default React.memo(Option);
