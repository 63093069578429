import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

import { mobileThreshhold } from '../../../utils';
import { Heading, Text } from '../../Typography';
import { FallbackImage } from '../Image';

const useListStyles = makeStyles({
  lookForContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    lookForContainer: {
      flexDirection: 'row',
    },
  },
});

const useLookForStyles = makeStyles({
  lookFor: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem',
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    marginBottom: '1.5rem',
  },
  image: {
    width: '30vw',
    height: '20vw',
    marginBottom: '0.5rem',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    lookFor: {
      flex: 1,
      paddingRight: '1rem',
      paddingBottom: 'unset',
      marginBottom: 'unset',
    },
    image: {
      width: 150,
      height: 100,
    },
  },
});

export interface LookForProps {
  image: string;
  title: string;
  description: string;
  position: number;
}

export interface LookForListProps {
  data: LookForProps[];
  className?: string;
}

export function LookForList({ data, className }: LookForListProps) {
  const classes = useListStyles();

  return (
    <div className={classNames(classes.lookForContainer, className)}>
      {data.map((lookFor, i) => (
        <LookFor key={i.toString()} {...lookFor} />
      ))}
    </div>
  );
}

export function LookFor({ image, title, description }: LookForProps) {
  const classes = useLookForStyles();
  return (
    <div className={classes.lookFor}>
      <FallbackImage
        fallback={require('../../../shared_assets/images/route-default.png')}
        src={image}
        alt="place"
        className={classes.image}
      />
      <Heading level={4} className={classes.title}>
        {title}
      </Heading>
      <Text variant="faded" className={classes.description}>
        {description}
      </Text>
    </div>
  );
}
