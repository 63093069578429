import { makeStyles } from '@material-ui/styles';
import Popover from 'antd/es/popover';
import classNames from 'classnames';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { Error } from '../../../components/App/Error';
import { Input } from '../../../components/App/Input';
import Loader from '../../../components/App/Loader/Loader';
import ResultList from '../../../components/App/RouteSearch/ResultList';
import { useSearchResults } from '../../../components/App/RouteSearch/useSearchResults';
import { Heading } from '../../../components/Typography';
import { ReactComponent as Pedirota } from '../../../shared_assets/images/pedirota.svg';
import { castShadow, desktopPadding, mobilePadding, mobileThreshhold } from '../../../utils';

const useStyles = makeStyles({
  search: {
    height: '60px !important',
    alignSelf: 'center',
    '& > input': {
      fontSize: '1.2rem !important',
    },
  },
  mainTitle: {
    fontWeight: '900 !important' as 'bold',
  },
  textShadow: {
    filter: 'drop-shadow(2px 2px 8px black)',
  },
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    ...mobilePadding,
    alignSelf: 'center',
  },
  shadow: {
    borderRadius: 5,
    '& .ant-popover-content': {
      borderRadius: 5,
    },
    '& .ant-popover-inner': {
      borderRadius: 5,
    },
    '& .ant-popover-inner-content': {
      borderRadius: 5,
      boxShadow: castShadow,
    },
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    mainTitle: {
      fontSize: '50px !important',
    },
    container: {
      ...desktopPadding,
      alignSelf: 'stretch',
    },
    search: {
      width: '50%',
      alignSelf: 'flex-start',
    },
  },
});

interface LargeSearchProps extends LocalizeContextProps {
  placeholder: string;
  title: string;
  subtitle: string;
}

const LargeSearch = ({
  translate,
  title,
  subtitle,
  placeholder,
}: LargeSearchProps) => {
  const classes = useStyles();

  const {
    error,
    filterDto,
    loading,
    results,
    search,
    setSearch,
    setSearching,
    searching,
    recent,
  } = useSearchResults();

  const history = useHistory();

  return (
    <div className={classes.container}>
      <Heading
        level={1}
        className={classNames(classes.mainTitle, classes.textShadow)}
        noMargin
        color="white"
        disableAutoscale
      >
        {title}
      </Heading>
      <Heading
        className={classes.textShadow}
        level={1}
        color="white"
        disableAutoscale
      >
        {subtitle}
      </Heading>
      <Popover
        onVisibleChange={setSearching}
        visible={searching}
        trigger={['focus', 'click']}
        placement="bottomLeft"
        autoAdjustOverflow={false}
        overlayClassName={classNames(classes.shadow, 'search-popover')}
        content={
          loading ? (
            <Loader />
          ) : error ? (
            <Error />
          ) : (
            <ResultList
              filterDto={filterDto}
              history={history}
              recent={recent}
              results={results}
              search={search}
              setSearching={setSearching}
              translate={translate}
            />
          )
        }
      >
        <Input
          size="large"
          className={classes.search}
          value={search}
          onChange={e => {
            setSearching(true);
            setSearch(e.target.value);
          }}
          placeholder={placeholder}
          prefix={
            <div style={{ height: 40 }}>
              <Pedirota style={{ height: 40, width: 40 }} />
            </div>
          }
        />
      </Popover>
    </div>
  );
};

export default withLocalize(React.memo(LargeSearch));
