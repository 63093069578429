import Axios from 'axios';
import { API_URL } from '../../consts';
import { GenderType } from '../../types/gender-type.enum';

export interface EditIntermediateDto {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  companyName: string;
  nif: string;
  nationality: string;
  gender: GenderType;
  address: string;
  location: string;
  postalCode: string;
  iban: string;
  email: string;
}

export const editIntermediate = (id: string, dto: EditIntermediateDto) => {
  return Axios({
    method: 'put',
    url: `${API_URL}/intermediate/${id}`,
    data: dto,
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
    }

    return Promise.reject(err);
  });
};
