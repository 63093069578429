import { makeStyles } from '@material-ui/styles';
import { message } from 'antd';
import Divider from 'antd/es/divider';
import { motion } from 'framer-motion';
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'react-spinners/BeatLoader';
import tinycolor from 'tinycolor2';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Button } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { Input } from '../../../../../components/App/Input';
import { API_URL } from '../../../../../consts';
import { createTheme } from '../../../../../hooks/customization/createTheme';
import { editTheme } from '../../../../../hooks/customization/editTheme';
import {
  ThemeResponse,
  useTheme,
} from '../../../../../hooks/customization/useTheme';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as Edit } from '../../../../../shared_assets/icons/edit.svg';
import { ReactComponent as PlusBlue } from '../../../../../shared_assets/icons/plus_blue.svg';
import { RootState } from '../../../../../store';
import { theme } from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

interface ThemeFormProps extends LocalizeContextProps {}

const useStyles = makeStyles({
  container: {
    '& .rc-color-picker-trigger': {
      height: 30,
      width: 30,
    },
  },
  colorContainer: {
    maxWidth: 200,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    '& > *:nth-child(2)': {
      marginLeft: '1rem',
    },
  },
  logoContainer: {
    display: 'flex',
    marginBottom: '2rem',
    '& > *:not([role|="separator"])': {
      flex: 1,
      maxWidth: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > img': {
        maxWidth: '100%',
      },
    },
    '& input': {
      display: 'none',
    },
  },
});

interface RouteParams {
  id?: string;
}

const Form = ({ translate }: ThemeFormProps) => {
  const classes = useStyles();
  const { id } = useParams<RouteParams>();
  const [hostname, setHostname] = useState<string>('');
  const [primary, setPrimary] = useState<string>(theme.primary);
  const [secondary, setSecondary] = useState<string>(theme.secondary);
  const [logo, setLogo] = useState<File | null>(null);
  const [whiteLogo, setWhiteLogo] = useState<File | null>(null);

  const [remoteTheme, setRemoteTheme] = useState<ThemeResponse | undefined>(
    undefined
  );

  const [isLoading, error] = useTheme(id, setRemoteTheme);

  useEffect(() => {
    if (id && remoteTheme) {
      setHostname(remoteTheme.hostname);
      setPrimary(remoteTheme.primary);
      setSecondary(remoteTheme.secondary);
    }
  }, [id, remoteTheme]);

  const fileArray = useMemo(() => [logo, whiteLogo], [logo, whiteLogo]);

  const previews = useFilePreview(fileArray);

  const mainLogoRef = useRef<HTMLInputElement | null>(null);
  const whiteLogoRef = useRef<HTMLInputElement | null>(null);

  const updateHostname = useCallback(e => {
    setHostname(e.target.value);
  }, []);
  const updatePrimary = useCallback(e => {
    setPrimary(e.target.value);
  }, []);
  const updateSecondary = useCallback(e => {
    setSecondary(e.target.value);
  }, []);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const token = useSelector((state: RootState) => state.userConfiguration.jwt);

  const logoPreview = useMemo(() => {
    if (previews?.[0]) return previews[0];
    else {
      if (remoteTheme)
        return `${API_URL}/content-management/theme/${remoteTheme.id}/logo?jwt=${token}`;
    }
  }, [previews, remoteTheme, token]);

  const whiteLogoPreview = useMemo(() => {
    if (previews?.[1]) return previews[1];
    else {
      if (remoteTheme)
        return `${API_URL}/content-management/theme/${remoteTheme.id}/logo-white?jwt=${token}`;
    }
  }, [previews, remoteTheme, token]);

  const history = useHistory();

  const actionCallback = useCallback(async () => {
    if ((!logo || !whiteLogo) && !id) return;

    try {
      setSubmitting(true);
      if (id) {
        await editTheme(id, {
          hostname,
          primary: tinycolor(primary).toRgbString(),
          secondary: tinycolor(secondary).toRgbString(),
          logo,
          logoWhite: whiteLogo,
        });
        message.success(translate('adminstration.theme.edited'));
      } else {
        await createTheme({
          hostname,
          primary: tinycolor(primary).toRgbString(),
          secondary: tinycolor(secondary).toRgbString(),
          logo: logo as File,
          logoWhite: whiteLogo as File,
        });
        message.success(translate('adminstration.theme.created'));
      }

      history.push('/admin/area/theme/list');
    } catch (err) {
      if (err.response.status === 409)
        message.error(translate('adminstration.theme.duplicate'));
      else message.error('adminstration.theme.otherError');
    } finally {
      setSubmitting(false);
    }
  }, [logo, whiteLogo, id, history, hostname, primary, secondary, translate]);

  if (isLoading && id) return <Loader />;

  if (error) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.container}
      variants={GenericPageTransition}
    >
      <Input
        value={hostname}
        onChange={updateHostname}
        placeholder={translate('adminstration.theme.hostname').toString()}
      />
      <AnimatedError
        isVisible={!validator.isURL(hostname, { require_protocol: false })}
        reason={<Translate id="adminstration.theme.badHostname" />}
      />
      <div className={classes.colorContainer}>
        <Input
          value={primary}
          onChange={updatePrimary}
          disabled
          placeholder={translate('adminstration.theme.primary').toString()}
        />
        <ColorPicker
          color={primary}
          onChange={(e: any) => {
            setPrimary(e.color);
          }}
        />
      </div>
      <AnimatedError />
      <div className={classes.colorContainer}>
        <Input
          value={secondary}
          onChange={updateSecondary}
          disabled
          placeholder={translate('adminstration.theme.secondary').toString()}
        />
        <ColorPicker
          onChange={(e: any) => {
            setSecondary(e.color);
          }}
          color={secondary}
        />
      </div>
      <AnimatedError />

      <div className={classes.logoContainer}>
        <div>
          {logoPreview && <img src={logoPreview} alt="logo" />}
          <input
            type="file"
            ref={mainLogoRef}
            onChange={e => {
              if (e.target.files) {
                setLogo(e.target.files[0]);
              }
            }}
          />
          <Button
            onClick={() => mainLogoRef.current?.click()}
            prefix={logo || id ? <Edit /> : <PlusBlue />}
          >
            <Translate id="adminstration.theme.logo" />
          </Button>
          <AnimatedError
            isVisible={!logo && !id}
            reason={<Translate id="adminstration.theme.logoMandatory" />}
          />
        </div>
        <Divider type="vertical" />
        <div>
          {whiteLogoPreview && <img src={whiteLogoPreview} alt="white logo" />}
          <input
            type="file"
            ref={whiteLogoRef}
            onChange={e => {
              if (e.target.files) {
                setWhiteLogo(e.target.files[0]);
              }
            }}
          />
          <Button
            onClick={() => whiteLogoRef.current?.click()}
            prefix={whiteLogo || id ? <Edit /> : <PlusBlue />}
          >
            <Translate id="adminstration.theme.whiteLogo" />
          </Button>
          <AnimatedError
            isVisible={!logo && !id}
            reason={<Translate id="adminstration.theme.logoMandatory" />}
          />
        </div>
      </div>
      <Button
        type="primary"
        disabled={
          submitting ||
          !validator.isURL(hostname, { require_protocol: false }) ||
          (!logo && !id) ||
          (!whiteLogo && !id)
        }
        onClick={actionCallback}
      >
        <Translate id="button.save" />
      </Button>
    </motion.div>
  );
};

export default withLocalize(Form);
