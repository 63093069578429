import { ResultType } from '../../components/App/RouteSearch/useSearchResults';

export interface RecentSearchesState {
  list: ResultType[];
}

export const APPEND_RECENT_SEARCH = 'APPEND_RECENT_SEARCH';

export interface AppendRecentSearchAction {
  type: typeof APPEND_RECENT_SEARCH;
  payload: ResultType;
}

export type RecentSearchesActions = AppendRecentSearchAction;
