import { makeStyles } from '@material-ui/styles';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Error } from '../../../../../components/App/Error';
import { TextArea } from '../../../../../components/App/Input';
import { LanguageSelection } from '../../../../../components/App/LanguageSelection';
import { Loader } from '../../../../../components/App/Loader';
import { TabPane, Tabs } from '../../../../../components/App/Tabs';
import { Text } from '../../../../../components/Typography';
import { useAccessibilityAdmin } from '../../../../../hooks/administration/useAccessibilityAdmin';
import { putAccessibility } from '../../../../../hooks/administration/usePutAccessibility';
import { UseJWT } from '../../../../../hooks/authentication/UseJWT';
import { AccessibilityAdminInterface } from '../../../../../types/accebilityAdmin.interface';
import { TranslationInterface } from '../../../../../types/translation.interface';
import {
  desktopPadding,
  mobilePadding,
  mobileThreshhold,
} from '../../../../../utils';
import { GenericPageTransition } from '../../../../PageUtils';

// ANCHOR: IMPORTS
const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  body: {
    ...mobilePadding,
  },

  headerDivider: {
    marginTop: 0,
  },

  divider: {
    margin: '1rem 0',
  },

  [`@media (min-width: ${mobileThreshhold}px)`]: {
    body: {
      ...desktopPadding,
    },
    bodyContainer: {
      display: 'flex',
    },
    backZone: {
      flex: 0.1,
      minWidth: 150,
    },
    infoZone: {
      flex: 0.9,
    },
  },

  headerArea: {
    display: 'flex',
  },

  avatarArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarPreview: {
    maxHeight: '25rem',
    marginBottom: '1rem',
  },

  changeAvatarButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5rem',
  },
});
interface ParamsInterface {
  accessibilityId: string;
}
interface InformationProps extends LocalizeContextProps {}
const AccessibilityInformation = ({ translate }: InformationProps) => {
  const classes = useStyles();

  const [subject, setAccessibility] =
    useState<AccessibilityAdminInterface | null>(null);

  const [jwt] = UseJWT();
  const params = useParams<ParamsInterface>();

  const [isLoading, hasError] = useAccessibilityAdmin(
    params.accessibilityId,
    setAccessibility
  );

  const [translations, setTranslations] = useState<TranslationInterface[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  // ANCHOR: VARIABLES
  useEffect(() => {
    if (!subject) return;
    setTranslations(subject.translations || []);
    setSelectedLanguages((subject.translations || []).map(t => t.language));
  }, [subject]);
  //Quando mudam linguas cria descrições novas mas mantem as existentes

  useEffect(() => {
    setTranslations(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, translation: '' })),
    ]);
  }, [selectedLanguages]);

  const error = useMemo(() => {
    return translations.some(t => t.translation === '');
  }, [translations]);

  const submit = useCallback(async () => {
    if (!jwt) return;

    if (error) {
      message.error(
        translate('adminstration.accessibilityMandatory').toString()
      );
      return;
    }

    try {
      await putAccessibility(params.accessibilityId, {
        translations: translations.map(t => {
          return { language: t.language, description: t.translation };
        }),
      });

      message.success(
        translate('adminstration.accessibilityCreateSuccess').toString()
      );
    } catch (err) {
      message.success(
        translate('adminstration.accessibilityCreateError').toString()
      );
    } finally {
    }
  }, [error, jwt, params.accessibilityId, translate, translations]);

  if (!subject || isLoading) {
    return <Loader />;
  }

  if (hasError) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className={classes.page}
      variants={GenericPageTransition}
    >
      <div className={classNames(classes.body, classes.page)}>
        <div className={classes.bodyContainer}>
          <div className={classes.infoZone}>
            {/* DESCRIPTION */}
            <Text weight="semibold">
              <Translate id="adminstration.accessibilitiesTranslations" />
            </Text>
            <Tabs>
              {translations.map(iteratedLanguage => (
                <TabPane
                  key={iteratedLanguage.language}
                  tab={translate(`languages.${iteratedLanguage.language}`)}
                >
                  <TextArea
                    value={iteratedLanguage.translation}
                    rows={1}
                    onChange={ev => {
                      ev.persist();
                      setTranslations(previousDescriptions =>
                        previousDescriptions.map(oldDescription => {
                          if (
                            oldDescription.language ===
                            iteratedLanguage.language
                          )
                            return {
                              ...oldDescription,
                              translation: ev.target.value,
                            };
                          else return oldDescription;
                        })
                      );
                    }}
                  />
                  <div>
                    <AnimatedError
                      isVisible={validator.isEmpty(
                        iteratedLanguage.translation
                      )}
                      reason={
                        <Translate id="adminstration.accessibilityMandatory" />
                      }
                    />
                  </div>
                </TabPane>
              ))}
            </Tabs>
            <Divider className={classes.divider} />
            {/* LANGS */}
            <Text weight="semibold">
              <Translate id="adminstration.subjectLanguages" />
            </Text>
            <LanguageSelection
              onChange={setSelectedLanguages}
              value={selectedLanguages}
            />
            <div className={classes.submitButton}>
              <Button
                onClick={() => {
                  submit();
                }}
                type="primary"
              >
                <Translate id="adminstration.edit" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withLocalize(AccessibilityInformation);
