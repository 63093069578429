import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import AnimatedError from '../../components/App/AnimatedError/AnimatedError';
import { Button } from '../../components/App/Button';
import { Input } from '../../components/App/Input';
import { Text } from '../../components/Typography';
import { resetPasswordEmail } from '../../hooks/authentication/resetPassword';
import { ReactComponent as ArrowBackBlue } from '../../shared_assets/icons/arrow_back_blue.svg';
import { ReactComponent as Email } from '../../shared_assets/icons/email.svg';
import { ErrorCode } from '../../types/error.codes.enum';
import { RoleType } from '../../types/role-type.enum';
import { LoginType } from './LoginForgotPasswordModal';

interface ForgotPasswordProps extends LocalizeContextProps {
  classes: {
    marginBetween: string;
    icon: string;
    bottomContainer: string;
    backBtn?: string;
  };
  loginType: LoginType;
  goToLogin: () => void;
  onRequestClose: () => void;
}

interface EmailProps {
  value: string;
  isInvalid?: boolean;
  reason?: string;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  classes,
  loginType,
  translate,
  goToLogin,
  onRequestClose,
}) => {
  const [emailForgotPassword, setEmailForgotPassword] = useState<EmailProps>({
    value: '',
    isInvalid: true,
  });

  const history = useHistory();

  const resetPasswordEmailCallback = useCallback(async () => {
    window.scrollTo(0, 0);

    if (
      validator.isEmpty(emailForgotPassword.value) ||
      !validator.isEmail(emailForgotPassword.value)
    ) {
      setEmailForgotPassword(prevState => ({
        ...prevState,
        reason: translate('register.badEmail').toString(),
        isInvalid: true,
      }));
      return;
    }

    try {
      await resetPasswordEmail({
        email: emailForgotPassword.value,
        type: loginType === 'GUIDE' ? RoleType.GUIDE : RoleType.VISITOR,
      });

      history.replace({
        pathname: loginType === 'GUIDE' ? '/guide/landing' : '/visitor/landing',
        state: {
          alert: 'resetEmailSent',
          resetEmail: emailForgotPassword.value,
        },
      });
      onRequestClose();
    } catch (err) {
      if (
        err.response.data.error === ErrorCode.NO_EMAIL_FOUND ||
        err.response.data.error === ErrorCode.PROFILE_TYPE_NOT_FOUNDED
      ) {
        setEmailForgotPassword(prevState => ({
          ...prevState,
          reason: translate('profile.changePasswordEmailNotFound').toString(),
          isInvalid: true,
        }));
      } else if (
        err.response.data.message ===
        ErrorCode.EMAIL_ALREADY_REGISTERED_WITH_EXTERNAL_PROVIDER
      ) {
        setEmailForgotPassword(prevState => ({
          ...prevState,
          reason: translate(
            'profile.emailAlreadyRegisterWithExternalProfile'
          ).toString(),
          isInvalid: true,
        }));
      } else {
        setEmailForgotPassword(prevState => ({
          ...prevState,
          reason: translate('profile.changePasswordFailedTitle').toString(),
          isInvalid: true,
        }));
      }
    }
  }, [
    emailForgotPassword.value,
    translate,
    loginType,
    history,
    onRequestClose,
  ]);

  return (
    <>
      <Text>
        <Translate id="resetPassword.associatedEmail" />
      </Text>
      <div className={classNames(classes.marginBetween)}>
        <Text weight="bold">
          <Translate id="resetPassword.email" />
        </Text>
        <Input
          huge
          type="email"
          placeholder={translate('resetPassword.email').toString()}
          onChange={ev => {
            ev.persist();
            setEmailForgotPassword(prevState => ({
              ...prevState,
              value: ev.target.value,
            }));
          }}
          value={emailForgotPassword.value}
          prefix={<Email className={classes.icon} />}
        />
        <AnimatedError
          isVisible={emailForgotPassword.isInvalid}
          reason={emailForgotPassword.reason}
        />
      </div>
      <div className={classNames(classes.backBtn)}>
        <Button type="link" size="large" onlyText onClick={goToLogin}>
          <ArrowBackBlue />
          <Translate id="button.backToLogin" />
        </Button>
      </div>
      <div className={classes.bottomContainer}>
        <Button
          type="primary"
          size="large"
          onClick={resetPasswordEmailCallback}
        >
          <Translate id="button.sendResetLink" />
        </Button>
      </div>
    </>
  );
};

export default withLocalize(ForgotPassword);
