import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BenefitList } from '../../components/App/BenefitList';
import { Button } from '../../components/App/Button';
import { CallToActionList } from '../../components/App/CallToActionCards';
import { CopyrightFooter } from '../../components/App/CopyrightFooter';
import { LinkSection } from '../../components/App/LinkSection';
import { Loader } from '../../components/App/Loader';
import { TestimonyList } from '../../components/App/TestimonyList';
import { Heading, Text } from '../../components/Typography';
import { AboutTranslation, useAboutTranslations } from '../../hooks/About/useAboutTranslations';
import { getHeaderImageUrl } from '../../hooks/administration/useAbout';
import { RootState } from '../../store';
import { desktopPadding, getTheme, headerHeight, mobilePadding, mobileThreshhold } from '../../utils';
import { GenericPageTransition } from '../PageUtils';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    flexGrow: 1,
    ...mobilePadding,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  introZone: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 0 2vh 2vh',
    flexGrow: 1,
    backgroundImage: `url(${getHeaderImageUrl()})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '65vh',
    '& img, & picture, & svg': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
    '& > .text-zone': {
      marginTop: headerHeight,
      backgroundColor: getTheme().shadowBackground,
      padding: '1rem',
      '& *': {
        color: 'white !important',
      },
    },
  },
  mainTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  subTitle: {
    fontWeight: ('600 !important' as unknown) as number,
    marginBottom: '1rem !important',
  },
  marginTitle: {
    margin: '2rem 0 1rem 0 !important',
  },
  thinCallout: {
    fontWeight: 'lighter',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  fadedDescription: {
    textAlign: 'center',
  },
  marginBetween: {
    margin: '1rem 0',
  },
  standOutBackground: {
    backgroundColor: getTheme().neutral5,
  },
  makeYourRegistration: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.5rem 1rem',
    margin: '1rem 0',
  },
  bottomDivider: {
    margin: '1rem 0 0 0',
  },
  image: {
    display: 'flex',
    alignSelf: 'center',
    height: '40vh',
    borderRadius: '10px',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  extraPadding: {
    paddingTop: '3rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: {
      ...desktopPadding,
    },
    extraPadding: {
      margin: '0 25%',
    },
    stepsContainer: {
      display: 'flex',
    },
    introZone: {
      '& > .text-zone': {
        alignSelf: 'center',
        borderRadius: 16,
      },
    },
  },
});

interface RouteState {
  redirectTo?: string;
}

const ValidRedirections = ['/guide/landing', '/visitor/landing'];

interface AboutProps extends LocalizeContextProps {}

const About = () => {
  const classes = useStyles();
  const location = useLocation<RouteState | undefined>();

  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [translations, setTranslations] = useState<AboutTranslation | null>(
    null
  );
  const [
    fetchingTranslations,
    errorFetchingTranslations,
  ] = useAboutTranslations(activeLanguage, setTranslations);

  if (
    !location.state ||
    !location.state.redirectTo ||
    !ValidRedirections.includes(location.state.redirectTo)
  ) {
    //This cannot be in the state since it will come from an outside source
    //history.goBack();
  }
  if (fetchingTranslations || !translations) return <Loader />;
  if (errorFetchingTranslations) return <Loader />;
  return (
    <motion.div
      className={classes.page}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <div className={classes.introZone}>
        {(translations.firstTitle || translations.intro) && (
          <div className={classNames('text-zone', classes.body)}>
            <Heading level={2} className={classes.mainTitle}>
              {translations.firstTitle}
            </Heading>
            <Text className={classes.fadedDescription}>
              {translations.intro}
            </Text>
          </div>
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.extraPadding}>
            <Heading level={3} className={classes.marginTitle}>
              {translations.whoWeAreTitle}
            </Heading>
            <Text>{translations.whoWeAreText}</Text>
            <span>{/*magics*/}</span>
            <Heading level={3} className={classes.marginTitle}>
              {translations.whyUniquePathsTitle}
            </Heading>
            {/* <Heading level={4} className={classes.subTitle}>
              {translations.benefitsTitle}
            </Heading> */}
            <BenefitList data={translations.benefits} />
            {/* <Heading level={3} className={classes.marginTitle}>
              {translations.stepTitle}
            </Heading> */}
            {/* <Heading
              level={4}
              color={getTheme().primary}
              className={classes.subTitle}
            >
              " translations.stepSubtitle"
            </Heading> */}
            {/* <div
              style={{
                backgroundImage: `url('${require('./assets/tutorial.png')}')`,
              }}
              className={classes.image}
            ></div> */}
          </div>
          <CallToActionList data={translations.callsToAction} />
          {/* <div className={classes.stepsContainer}> */}

          {/* </div> */}
        </div>

        <div className={classNames(classes.body, classes.extraPadding)}>
          <Heading level={3}>{translations.whatTheySayAboutUsTitle}</Heading>
          {/* <Heading level={4} className={classes.subTitle}>
            {translations.testimonySubtitle}
          </Heading> */}
          <TestimonyList data={translations.testimonies} />
        </div>
      </div>
      <div
        className={classNames(
          classes.standOutBackground,
          classes.makeYourRegistration
        )}
      >
        <Heading level={5} className={classes.subTitle}>
          {translations.footerTitle}
        </Heading>
        <Text>{translations.footerText}</Text>
        <Button
          type="primary"
          href={translations.footerButtonRedirect}
          onClick={() => {}}
        >
          {translations.footerButtonText}
        </Button>
        {/* <Button type="link" onClick={() => {}}>
            <Heading level={4} color={getTheme().primary}>
              {translations.beVisitor}
            </Heading>
          </Button> */}
      </div>
      <Divider />
      <LinkSection />
      <Divider className={classes.bottomDivider} />
      <div style={{ flex: 1 }}></div>
      <CopyrightFooter />
    </motion.div>
  );
};

export default withLocalize(About);
