/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/styles';
import { Space, Tooltip } from 'antd';
import Table from 'antd/es/table';
import React, { useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';
import Badge2 from '../../../../components/App/Badge2/Badge2';
import { Input } from '../../../../components/App/Input';
import { Loader } from '../../../../components/App/Loader';
import { UseJWT } from '../../../../hooks/authentication/UseJWT';
import { useListCompanyGuides } from '../../../../hooks/company/useListCompanyGuides';
import { ReactComponent as Canceled } from '../../../../shared_assets/icons/canceled.svg';
import { ReactComponent as Complete } from '../../../../shared_assets/icons/complete.svg';
import { GuideStateType } from '../../../../types/guide-state-type.enum';
import { ListGuidesAdminInterface } from '../../../../types/list-guide.interface';
import { mobileThreshhold } from '../../../../utils';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  filterZone: {
    '& > *:nth-child(2)': { flexGrow: 1, marginLeft: '1rem' },
    display: 'flex',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});
interface ListGuidesFormProps extends LocalizeContextProps {}

const ListGuides = ({ translate }: ListGuidesFormProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [jwt] = UseJWT();
  const [listCompanyGuides, setListCompanyGuides] = useState<
    ListGuidesAdminInterface[]
  >([]);
  const [search, setSearch] = useState<string>('');

  const dto = useMemo(
    () => ({
      search,
      companyId: jwt?.companyId,
    }),
    [search, jwt]
  );
  const [fetchingGuides, errorFetching] = useListCompanyGuides(
    setListCompanyGuides,
    dto
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'State',
        dataIndex: 'companyState',
        key: 'companyState',
        render: (text: any, record: any) => {
          switch (text) {
            case GuideStateType.APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'success'}
                  text={translate('adminstration.guideApproved').toString()}
                />
              );
            case GuideStateType.NOT_APPROVED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideNotApproved').toString()}
                />
              );
            case GuideStateType.CANCELED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideCanceled').toString()}
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  type={'warning'}
                  text={translate(
                    'adminstration.guideWaitingApproval'
                  ).toString()}
                />
              );
          }
        },

        width: '20%',
      },
      {
        title: <Translate id="adminstration.name" />,
        dataIndex: 'name',
        key: 'name',
      },

      {
        title: <Translate id="adminstration.gender" />,
        dataIndex: 'gender',
        key: 'gender',
      },

      {
        title: <Translate id="adminstration.email" />,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: <Translate id="adminstration.phoneNumber" />,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
      },
      {
        title: <Translate id="adminstration.nif" />,
        dataIndex: 'nif',
        key: 'nif',
      },
      {
        title: <Translate id="adminstration.magnifinanceStatus" />,
        dataIndex: 'partner',
        key: 'partner',
        render: (value: any) => {
          if (!value) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartner" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else if (!value.magnifinanceToken) {
            return (
              <Tooltip title={<Translate id="adminstration.noPartnerToken" />}>
                <Canceled style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={<Translate id="adminstration.magnifinanceOk" />}>
                <Complete style={{ width: 32, height: 32 }} />
              </Tooltip>
            );
          }
        },
      },
      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="middle">
            <Link to={`/company/area/guide/${record.id}`}>
              <Translate id="adminstration.showInformation" />
            </Link>
            {/* <Divider type="vertical" />

            <Link to={`/admin/area/guide/${record.id}/list/routes`}>
              <Translate id="adminstration.showRoutes" />
            </Link>
            <Divider type="vertical" />

            <a onClick={() => setGuideIdRemove(record.id)}>
              <Translate id="adminstration.removeGuide" />
            </a> */}
          </Space>
        ),
      },
    ];
  }, [translate]);

  return (
    <>
      <div className={classes.divAddButton}>
        <div className={classes.filterZone}>
          <Input
            value={search}
            placeholder={translate('adminstration.search').toString()}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          {/* <Checkbox
            checked={showOnlyToApprove}
            onChange={e => setShowOnlyToApprove(e.target.checked)}
          >
            <Translate id="adminstration.showOnlyNotApproved" />
          </Checkbox> */}
        </div>
      </div>

      {fetchingGuides ? (
        <Loader />
      ) : (
        <Table dataSource={listCompanyGuides} columns={columns} />
      )}
    </>
  );
};

export default withLocalize(ListGuides);
