import Axios from 'axios';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BenefitProps } from '../../components/App/BenefitList/BenefitList';
import { CallToActionProps } from '../../components/App/CallToActionCards/callToActionCards';
import { TestimonyProps } from '../../components/App/TestimonyList/TestimonyList';
import { API_URL } from '../../consts';
import { getImage1Url, getImage2Url } from '../administration/useAbout';
import { getVisitorImageUrl } from '../visitor/UseVisitor';
interface TestimonyInterface {
  id: string;
  description: string;
  name: string;
  locality: string;
  date: Date;
  visitorId: string;
}

interface AboutInterface {
  firstTitle: string;
  intro: string;
  whoWeAreTitle: string;
  whoWeAreText: string;
  whyUniquePathsTitle: string;
  argument0Title: string;
  argument0Text: string;
  argument1Title: string;
  argument1Text: string;
  argument2Title: string;
  argument2Text: string;
  information0Title: string;
  information0Text: string;
  information0RedirectUrl: string;
  information1Title: string;
  information1Text: string;
  information1RedirectUrl: string;
  footerTitle: string;
  footerText: string;
  footerButtonText: string;
  footerButtonRedirect: string;
  testimonies: TestimonyInterface[];
  testimonyTitle: string;

  language: string;
}

export interface AboutTranslation {
  firstTitle: string;
  intro: string;
  whoWeAreTitle: string;
  whoWeAreText: string;
  whyUniquePathsTitle: string;
  //benefitsTitle: string;
  benefits: BenefitProps[];
  // stepTitle: string;
  // stepSubtitle: string;
  callsToAction: CallToActionProps[];
  // makeYourRegistrationTitle: string;
  // beGuide: string;
  // beVisitor: string;
  whatTheySayAboutUsTitle: string;
  testimonySubtitle: string;
  testimonies: TestimonyProps[];
  footerTitle: string;
  footerText: string;
  footerButtonText: string;
  footerButtonRedirect: string;
  information0RedirectUrl: string;
  information1RedirectUrl: string;
}

type Hook = (
  language: string,
  setLandingPageTranslation: (
    val: SetStateAction<AboutTranslation | null>
  ) => void
) => [boolean, boolean];

const requestLandingPageTranslation = (language: string) =>
  Axios.get<AboutInterface>(
    `${API_URL}/content-management/about-page/${language}`
  );

export const useAboutTranslations: Hook = (
  language,
  setLandingPageTranslation
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: response } = await requestLandingPageTranslation(
          language
        );
        const translation = response as AboutInterface;

        setLandingPageTranslation({
          firstTitle: translation.firstTitle,
          intro: translation.intro,
          whoWeAreTitle: translation.whoWeAreTitle,
          whoWeAreText: translation.whoWeAreText,
          whyUniquePathsTitle: translation.whyUniquePathsTitle,
          benefits: [
            {
              title: translation.argument0Title,
              description: translation.argument0Text,
            },
            {
              title: translation.argument1Title,
              description: translation.argument1Text,
            },
            {
              title: translation.argument2Title,
              description: translation.argument2Text,
            },
          ],
          whatTheySayAboutUsTitle: translation.testimonyTitle,
          testimonySubtitle: translation.testimonyTitle,
          testimonies: translation.testimonies.map(t => {
            return {
              id: t.id,
              description: t.description,
              image: getVisitorImageUrl(t.visitorId),
              name: t.name,
              locality: t.locality,
              date: moment(t.date),
            };
          }),

          callsToAction: [
            {
              id: '1',
              title: translation.information0Title,
              text: translation.information0Text,
              buttonText: 'Saiba mais',
              link: translation.information0RedirectUrl,
              image: getImage1Url(),
            },
            {
              id: '2',
              title: translation.information1Title,
              text: translation.information1Text,
              buttonText: 'Saiba mais',
              link: translation.information1RedirectUrl,
              image: getImage2Url(),
            },
          ],
          information0RedirectUrl: translation.information0RedirectUrl,
          information1RedirectUrl: translation.information1RedirectUrl,
          footerButtonRedirect: translation.footerButtonRedirect,
          footerButtonText: translation.footerButtonText,
          footerText: translation.footerText,
          footerTitle: translation.footerTitle,
        });
        setIsError(false);
      } catch (err) {
        setLandingPageTranslation(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, setLandingPageTranslation, language]);

  return [isLoading, isError];
};
