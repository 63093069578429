import { useState, SetStateAction, useEffect } from "react";
import { ScheduleDateBookings } from "../../types/ScheduleDateBookings.interface";
import Axios from "axios";
import { API_URL } from "../../consts";

type Hook = (
  setBookings: (v: SetStateAction<ScheduleDateBookings | null>) => void,
  repetitionId?: string
) => [boolean, boolean];

const requestScheduleBookings = (repetitionId: string) =>
  Axios.get<ScheduleDateBookings>(
    `${API_URL}/schedule/repetition/${repetitionId}/bookings`
  );

export const useRepetitionBookings: Hook = (setBookings, repetitionId) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!repetitionId) {
        setBookings(null);
        setIsLoading(false);
        setIsError(false);
        return;
      }

      setIsLoading(true);

      try {
        const { data } = await requestScheduleBookings(repetitionId);
        setBookings(data);
      } catch (err) {
        setBookings(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [repetitionId, setBookings]);

  return [isLoading, isError];
};
