import { makeStyles } from '@material-ui/styles';
import ANTDButton, { ButtonProps as ANTDButtonProps, ButtonSize, ButtonType } from 'antd/es/button';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import tinycolor from 'tinycolor2';

import { getTheme } from '../../../utils';
import { Text } from '../../Typography';

const useStyles = makeStyles({
  button: (props: {
    onlyText: boolean | undefined;
    size: ButtonSize;
    type?: ButtonType;
    color?: string;
    extraWide?: boolean;
  }) => ({
    height: props.onlyText ? 'unset' : props.size === 'large' ? 40 : undefined,
    padding: props.onlyText ? '0 0' : undefined,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: props.onlyText ? 'inherit !important' : undefined,
    borderRadius: 5,
    backgroundColor:
      (!props.onlyText &&
        props.type !== 'link' &&
        props.type !== 'ghost' &&
        props.color) ||
      (props.type === 'primary' && getTheme().primary) ||
      undefined,
    color:
      ((props.onlyText || props.type === 'ghost') &&
        (props.color || getTheme().primary)) ||
      undefined,
    borderColor:
      props.type === 'primary' || !props.type
        ? props.color || getTheme().primary
        : undefined,
    paddingLeft: props.extraWide ? '3rem' : undefined,
    paddingRight: props.extraWide ? '3rem' : undefined,
    '& img, & svg': {
      height: 14,
      '&:first-child': {
        marginRight: '1em',
      },
      '&:last-child': {
        marginLeft: '1em',
      },
    },
    '&:hover, &:focus': {
      backgroundColor:
        (!props.onlyText &&
          props.type !== 'link' &&
          props.type !== 'ghost' &&
          props.color &&
          tinycolor(props.color).lighten().toHexString()) ||
        (props.type === 'primary' &&
          tinycolor(getTheme().primary).lighten().toHexString()) ||
        undefined,
      borderColor:
        props.type === 'primary' || props.type === 'ghost' || !props.type
          ? tinycolor(props.color || getTheme().primary)
              .lighten()
              .toHexString()
          : undefined,
    },
    '&:active': {
      backgroundColor: tinycolor(props.color || getTheme().primary)
        .darken()
        .toHexString(),
    },
  }),
});

interface ButtonProps
  extends Omit<ANTDButtonProps, 'icon' | 'prefix' | 'suffix'> {
  onlyText?: boolean;
  extraWide?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  children?: React.ReactNode;
}

const Button = (
  {
    className,
    onlyText,
    size,
    type,
    color,
    extraWide,
    suffix,
    prefix,
    children,
    disabled,
    danger,
    ...props
  }: ButtonProps,
  ref: any
) => {
  if (danger) {
    type = 'ghost';
    color = getTheme().errorBase;
  }

  const classes = useStyles({ onlyText, size, type, color, extraWide });

  const renderContent = useCallback(() => {
    if (danger)
      return (
        <Text
          inline
          noMargin
          color={disabled ? getTheme().neutral1 : getTheme().errorBase}
        >
          {children}
        </Text>
      );

    switch (type) {
      case 'dashed':
      case 'default':
      case 'ghost':
      case undefined:
        return (
          <Text
            color={disabled ? getTheme().neutral1 : color || getTheme().primary}
            noMargin
            inline
            variant="faded"
          >
            {children}
          </Text>
        );
      case 'link':
        return (
          <Text
            noMargin
            inline
            color={disabled ? getTheme().neutral1 : color || getTheme().primary}
            variant="link"
          >
            {children}
          </Text>
        );
      case 'primary':
        if (!size || size === 'middle' || suffix || prefix)
          return (
            <Text
              noMargin
              inline
              weight={!suffix && !prefix ? 'semibold' : undefined}
              color={disabled ? getTheme().neutral1 : 'white'}
            >
              {children}
            </Text>
          );
        else
          return (
            <Text
              color={disabled ? getTheme().neutral1 : 'white'}
              noMargin
              size={16}
            >
              {children}
            </Text>
          );
    }
  }, [children, color, type, size, prefix, suffix, disabled, danger]);

  return (
    <ANTDButton
      {...props}
      size={size}
      className={classNames(classes.button, className)}
      type={type}
      disabled={disabled}
    >
      {prefix}
      {renderContent()}
      {suffix}
    </ANTDButton>
  );
};

export default React.memo(React.forwardRef(Button));
