import { makeStyles } from '@material-ui/styles';
import { Circle, OverlayView } from '@react-google-maps/api';
import { DatePicker, InputNumber, message, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import validator from 'validator';
import { AnimatedError } from '../../../../../components/App/AnimatedError';
import { Button } from '../../../../../components/App/Button';
import { GoogleMap } from '../../../../../components/App/GoogleMap';
import { FallbackImage } from '../../../../../components/App/Image';
import { LanguageSelection } from '../../../../../components/App/LanguageSelection';
import { Modal } from '../../../../../components/App/Modal';
import PlaceSearchBox, {
  ReturnedPlace,
} from '../../../../../components/App/RouteForm/PlaceSearchBox';
import { TabPane, Tabs } from '../../../../../components/App/Tabs';
import { Text } from '../../../../../components/Typography';
import {
  addAdEvent,
  AddAdEventResponse,
} from '../../../../../hooks/advertiser/addAdEvent';
import {
  editAdEvent,
  EditAdEventResponse,
} from '../../../../../hooks/advertiser/editAdEvent';
import {
  AdEventDescriptionInterface,
  AdEventResponse,
  AdEventTitleInterface,
  getAdImageUrl,
} from '../../../../../hooks/advertiser/useAdEvent';
import { fetchAddress } from '../../../../../hooks/useAddressFromCoordinates';
import { useFilePreview } from '../../../../../hooks/useFilePreview';
import { ReactComponent as MapMarker } from '../../../../../shared_assets/icons/map_marker.svg';
import { ReactComponent as Pediguia } from '../../../../../shared_assets/images/pediguia_default.svg';
import {
  complexTranslate,
  getTheme,
  maxAllowedImageSize,
} from '../../../../../utils';
const { RangePicker } = DatePicker;
const useStyles = makeStyles({
  noMargin: {
    margin: 0,
  },
  inlineBtn: {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  footer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: 20,
  },
  map: {
    marginBottom: '0.3rem',
    minHeight: 400,
  },
  invisible: {
    display: 'none',
  },
  photoPreviewContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowY: 'auto',
    '& > *': {
      minWidth: 150,
      maxWidth: 150,
      minHeight: 150,
      maxHeight: 150,
      display: 'flex',
      backgroundColor: getTheme().standout,
      alignItems: 'center',
      margin: '0.5rem 0.2rem',
      '& > img, & > svg': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    '& > *:first-child': {
      marginLeft: 0,
      marginRight: '0.5rem',
    },
    '& > *:last-child': {
      marginRight: 0,
    },
    '& > .add': {
      '& > img, & > svg': {
        flex: 0.7,
      },
      '& :last-child': {
        flex: 0.3,
      },
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      boxShadow: '0px 0px 7px -3px rgba(0,0,0,0.5)',
      borderRadius: '5px',
      justifyContent: 'center',
      backgroundColor: 'white',
      margin: '0.5rem 0.2rem',
    },
  },
  imageSize: {
    width: '10rem',
  },
  photoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 5,
    '& > img, & > svg': {
      position: 'absolute',
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& > button': {
      zIndex: 1000,
      marginRight: '0.5rem',
      marginTop: '0.5rem',
    },
  },
  modalBody: {
    width: '75vw !important',
    height: '100%',
  },
});

const dateFormat = 'DD/MM/YYYY';

interface CreateAdvertiserModalProps extends LocalizeContextProps {
  visible: boolean;
  onRequestClose: () => void;
  advertiserId?: string;
  adEventContent?: AdEventResponse[0];
  onAdd?: (v: AddAdEventResponse) => void;
  onEdit?: (v: EditAdEventResponse) => void;
}

const CreateAdEventModal = ({
  visible,
  adEventContent,
  onRequestClose,
  onAdd,
  onEdit,
  translate,
  advertiserId,
}: CreateAdvertiserModalProps) => {
  const mapRef = useRef<null | google.maps.Map<Element>>(null);
  const classes = useStyles();
  const DefaultCenter = {
    lat: 38.722317,
    lng: -9.139351,
  };
  const [center, setCenter] = useState(DefaultCenter);
  const [descriptions, setDescriptions] = useState<
    AdEventDescriptionInterface[]
  >([]);
  const [titles, setTitles] = useState<AdEventTitleInterface[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  const [locationInputText, setLocationInputText] = useState<
    string | undefined
  >(undefined);

  const [startAd, setStartAd] = useState<Date>(moment().utc().toDate());
  const [endAd, setEndAd] = useState<Date>(moment().utc().toDate());
  const [longitude, setLongitude] = useState<number | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [location, setLocation] = useState<string>('');

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [radius, setRadius] = useState<number>(1);

  const imageInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const previewSource = useMemo(() => [image], [image]);
  const imagePreview = useFilePreview(previewSource);

  useEffect(() => {
    if (adEventContent) {
      setStartAd(adEventContent.startAd);
      setEndAd(adEventContent.endAd);
      setLongitude(adEventContent.longitude);
      setLatitude(adEventContent.latitude);
      setLocation(adEventContent.location);
      setDescriptions(adEventContent.descriptions);
      setTitles(adEventContent.titles);
      setLocationInputText(adEventContent.location);
      setCenter({
        lat: adEventContent.latitude,
        lng: adEventContent.longitude,
      });
      setRadius(adEventContent.radius);
      setSelectedLanguages(adEventContent.titles.map(t => t.language));
    }
  }, [adEventContent, visible]);

  useEffect(() => {
    setDescriptions(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, description: '' })),
    ]);

    setTitles(oldDescriptions => [
      ...oldDescriptions.filter(newDescription =>
        selectedLanguages.includes(newDescription.language)
      ),
      ...selectedLanguages
        .filter(
          lang => !oldDescriptions.some(newDesc => newDesc.language === lang)
        )
        .map(lang => ({ language: lang, title: '' })),
    ]);
  }, [selectedLanguages]);

  const placeSelected = useCallback((place: ReturnedPlace | undefined) => {
    if (!place || !place.geometry) return;
    setLocation(place.formatted_address);
    setLongitude(place.geometry.location.lng());
    setLatitude(place.geometry.location.lat());
    if (mapRef.current)
      mapRef.current.panTo({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
  }, []);

  const errorDates = useMemo(() => {
    if (moment(startAd).isAfter(endAd)) {
      return translate('adminstration.adEventArea.errorDates');
    }
  }, [endAd, startAd, translate]);
  const errorStartDate = useMemo(() => {
    if (
      moment(startAd, dateFormat).isBefore(moment(moment(), dateFormat), 'days')
    ) {
      return translate('adminstration.adEventArea.errorStartDate');
    }
  }, [startAd, translate]);
  const errorLocation = useMemo(() => {
    if (validator.isEmpty(location)) {
      return translate('adminstration.adEventArea.errorLocation');
    }
  }, [location, translate]);

  const errorImage = useMemo(() => {
    if (image === null && onAdd) {
      return translate('adminstration.adEventArea.mandatoryImage');
    }
  }, [image, onAdd, translate]);
  const errorLanguage = useMemo(() => {
    if (selectedLanguages.length === 0) {
      return translate('adminstration.adEventArea.mandatoryChooseLanguage');
    }
  }, [selectedLanguages, translate]);
  const errorTitles = useMemo(() => {
    if (titles.find(t => validator.isEmpty(t.title))) {
      return translate('adminstration.adEventArea.mandatoryImage');
    }
  }, [titles, translate]);
  const action = useCallback(async () => {
    try {
      if (
        endAd === null ||
        latitude === null ||
        longitude === null ||
        startAd === null ||
        !!errorTitles ||
        !!errorLanguage
      )
        return;
      setSubmitting(true);
      if (adEventContent) {
        //edit

        const { data } = await editAdEvent(
          {
            adEventId: adEventContent.id,
            descriptions,
            endAd,
            startAd,
            latitude,
            longitude,
            location,
            titles,
            radius,
          },
          image
        );
        if (onEdit) onEdit(data);
      } else {
        //add
        //FAZER ERROR
        if (!advertiserId || image === null) return;
        const { data } = await addAdEvent(
          {
            adOwnerId: advertiserId,
            descriptions,
            endAd,
            startAd,
            latitude,
            longitude,
            location,
            titles,
            radius,
          },
          image
        );
        if (onAdd) onAdd(data);
      }
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  }, [
    endAd,
    latitude,
    longitude,
    image,
    advertiserId,
    startAd,
    errorTitles,
    errorLanguage,
    adEventContent,
    descriptions,
    location,
    titles,
    radius,
    onEdit,
    onAdd,
  ]);
  const changeSelectedPosition = useCallback(
    async ev => {
      try {
        const { data } = await fetchAddress(ev.latLng.lat(), ev.latLng.lng());

        if (
          !data.results[0].address_components.some(
            comp => comp.short_name === 'PT'
          )
        ) {
          message.error(translate('routes.outOfBoundsError'));
          return;
        }

        if (data.results[0].types.includes('country')) {
          message.error(translate('routes.notAnActualPlaceError'));
          return;
        }

        setLocation(data.results[0].formatted_address);
        setLongitude(ev.latLng.lng());
        setLatitude(ev.latLng.lat());
        setLocationInputText(data.results[0].formatted_address);

        if (mapRef.current)
          mapRef.current.panTo({ lat: ev.latLng.lat(), lng: ev.latLng.lng() });
      } catch (err) {}
    },
    [translate]
  );

  return (
    <Modal
      className={classes.modalBody}
      onRequestClose={onRequestClose}
      footer={
        <div className={classes.footer}>
          <Button
            type="primary"
            disabled={
              !!errorDates ||
              !!errorStartDate ||
              !!errorImage ||
              !!errorLanguage ||
              !!errorTitles ||
              submitting
            }
            onClick={action}
          >
            <Translate id="button.save" />
          </Button>
          <Button disabled={submitting} type="primary" onClick={onRequestClose}>
            <Translate id="button.cancel" />
          </Button>
        </div>
      }
      visible={visible}
    >
      <div className={classes.content}>
        <Space
          direction="vertical"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
          size="large"
        >
          {adEventContent !== undefined ? (
            <FallbackImage
              className={classes.imageSize}
              fallbackComponent={<Pediguia />}
              src={imagePreview?.[0] || getAdImageUrl(adEventContent.id)}
            />
          ) : (
            <FallbackImage
              className={classes.imageSize}
              src={imagePreview?.[0] || undefined}
              fallback={require('../../../../../shared_assets/images/pediguia_default.svg')}
            />
          )}

          <Translate id="profile.changePhoto" />
          <input
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={ev => {
              if (
                ev.currentTarget.files &&
                ev.currentTarget.files.length > 0 &&
                ev.currentTarget.files[0].size < maxAllowedImageSize
              ) {
                setImage(ev.currentTarget.files[0]);
              } else {
                message.info(
                  complexTranslate(
                    translate('error.imageFileSize').toString(),
                    {
                      '{linkTinyPNG}': (
                        <a key={'tinypnglink'} href="https://tinypng.com/">
                          <Button
                            key="1"
                            type="link"
                            className={classNames(
                              classes.noMargin,
                              classes.inlineBtn
                            )}
                            onlyText
                          >
                            {translate('error.linkTinyPNG').toString()}
                          </Button>
                        </a>
                      ),
                    }
                  )
                );
              }
            }}
          />
          <AnimatedError isVisible={!!errorImage} reason={errorImage} />
        </Space>
        <Text weight="semibold">
          <Translate id="routes.address" />
        </Text>
        <PlaceSearchBox
          value={locationInputText}
          onChange={setLocationInputText}
          onPlaceSelected={placeSelected}
        />
        <AnimatedError isVisible={!!errorLocation} reason={errorLocation} />
        <Text disableAutoscale>
          <Translate id="routes.mapIntroText" />
        </Text>

        <GoogleMap
          mapContainerClassName={classes.map}
          zoom={13}
          onLoad={map => {
            mapRef.current = map;
          }}
          onClick={changeSelectedPosition}
          center={center}
        >
          <OverlayView
            position={{
              lat: latitude ? latitude : center.lat,
              lng: longitude ? longitude : center.lng,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <>
              <MapMarker
                style={{
                  width: 30,
                  height: 30,
                  transform: 'translate(-50%, -100%)',
                }}
              />
              <Circle
                center={{
                  lat: latitude ? latitude : center.lat,
                  lng: longitude ? longitude : center.lng,
                }}
                radius={radius * 1000}
              />
            </>
          </OverlayView>
        </GoogleMap>
        <Space
          direction="vertical"
          style={{
            width: '100%',
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
          size="middle"
        >
          <Text noMargin weight="semibold">
            <Translate id="adminstration.adEventArea.rangeDates" />
          </Text>
          <RangePicker
            defaultValue={[
              moment(moment(startAd).format(dateFormat), dateFormat),
              moment(moment(endAd).format(dateFormat), dateFormat),
            ]}
            format={dateFormat}
            onChange={e => {
              if (e) {
                setStartAd(moment(e[0]).utc().toDate());
                setEndAd(moment(e[1]).utc().toDate());
              }
            }}
          />
          <AnimatedError isVisible={!!errorStartDate} reason={errorStartDate} />

          <Text noMargin weight="semibold">
            <Translate id="adminstration.adEventArea.radius" />
          </Text>
          <InputNumber
            type="number"
            ref={imageInputRef}
            value={radius}
            onChange={ev => {
              if (ev) {
                setRadius(ev as number);
              }
            }}
          />

          <Text weight="semibold">
            <Translate id="adminstration.adEventArea.advertiseIn" />
          </Text>
          <LanguageSelection
            onChange={setSelectedLanguages}
            value={selectedLanguages}
          />
          <AnimatedError isVisible={!!errorLanguage} reason={errorLanguage} />

          {selectedLanguages.length > 0 && (
            <Text weight="semibold">
              <Translate id="adminstration.adEventArea.titles" />
            </Text>
          )}
          <Tabs>
            {titles.map(iteratedLanguage => (
              <TabPane
                key={iteratedLanguage.language}
                tab={translate(`languages.${iteratedLanguage.language}`)}
              >
                <TextArea
                  value={iteratedLanguage.title}
                  rows={1}
                  onChange={ev => {
                    ev.persist();
                    setTitles(previousTitles =>
                      previousTitles.map(oldTitle => {
                        if (oldTitle.language === iteratedLanguage.language)
                          return {
                            ...oldTitle,
                            title: ev.target.value,
                          };
                        else return oldTitle;
                      })
                    );
                  }}
                />
                <div>
                  <AnimatedError
                    isVisible={validator.isEmpty(iteratedLanguage.title)}
                    reason={<Translate id="profile.bioMandatory" />}
                  />
                </div>
              </TabPane>
            ))}
          </Tabs>
          {selectedLanguages.length > 0 && (
            <Text weight="semibold">
              <Translate id="adminstration.adEventArea.descriptions" />
            </Text>
          )}

          <Tabs>
            {descriptions.map(iteratedLanguage => (
              <TabPane
                key={iteratedLanguage.language}
                tab={translate(`languages.${iteratedLanguage.language}`)}
              >
                <TextArea
                  value={iteratedLanguage.description}
                  rows={10}
                  onChange={ev => {
                    ev.persist();
                    setDescriptions(previousDescriptions =>
                      previousDescriptions.map(oldDescription => {
                        if (
                          oldDescription.language === iteratedLanguage.language
                        )
                          return {
                            ...oldDescription,
                            description: ev.target.value,
                          };
                        else return oldDescription;
                      })
                    );
                  }}
                />
              </TabPane>
            ))}
          </Tabs>
        </Space>
      </div>
    </Modal>
  );
};

export default React.memo(withLocalize(CreateAdEventModal));
