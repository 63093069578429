import Axios from 'axios';
import { API_URL } from '../../consts';
import { CancelGuideProfileType } from '../../types/cancel-guide-profile-type.enum';

export const cancelGuideProfile = (
  id: string,
  type: CancelGuideProfileType
) => {
  return Axios.delete(`${API_URL}/guide/cancel-profile/${id}/${type}`);
};
