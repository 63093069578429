import Axios from 'axios';

import { API_URL } from '../../consts';
import { HelpType } from './useHelp';

interface AddHelpDto {
  content: string;
  type: HelpType;
}

export interface AddHelpResponse {
  content: string;
  type: HelpType;
  id: string;
}

export const addHelp = (dto: AddHelpDto) =>
  Axios.post<AddHelpResponse>(`${API_URL}/content-management/help`, dto);
