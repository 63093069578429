import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import moment from 'moment';

export interface LookForInterface {
  title: string;
  description: string;
  language: string;
  position: number;
}
export interface LookFor {
  position1: LookForInterface[];
  position2: LookForInterface[];
  position3: LookForInterface[];
}
type Hook = (
  setLookFor: (val: SetStateAction<LookFor | null>) => void
) => [boolean, boolean];

export const requestRoutes = () =>
  Axios.get<LookForInterface[]>(
    `${API_URL}/content-management/guide-landing-page/look-for`
  );

export const useLookingFor: Hook = setLookFor => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: lookFor } = await requestRoutes();
        const received = lookFor as LookForInterface[];
        setLookFor({
          position1: received
            .map(r => {
              const p1: LookForInterface[] = [];
              if (r.position === 0) {
                p1.push(r);
              }
              return p1;
            })
            .flat(1),
          position2: received
            .map(r => {
              const p2: LookForInterface[] = [];
              if (r.position === 1) {
                p2.push(r);
              }
              return p2;
            })
            .flat(1),
          position3: received
            .map(r => {
              const p3: LookForInterface[] = [];
              if (r.position === 2) {
                p3.push(r);
              }
              return p3;
            })
            .flat(1),
        });
      } catch (err) {
        setLookFor(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setLookFor]);

  return [isLoading, isError];
};
export const getImageUrl = (position: number) =>
  `${API_URL}/content-management/guide-landing-page/look-for-image/${position}?_=${moment().unix()}`; //TODO: POR O URL CORRECTo
