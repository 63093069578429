import moment from 'moment';
import React, { SetStateAction, useMemo, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { RouteAboutToStartModal } from '.';
import { Error } from '../../../../components/App/Error';
import { Loader } from '../../../../components/App/Loader';
import { useGuideScheduleBookings } from '../../../../hooks/guide/useGuideScheduleBookings';
import { useRoute } from '../../../../hooks/routes/UseRoute';
import { RootState } from '../../../../store';
import { Route } from '../../../../types/route.interface';
import { ScheduleDateBookings } from '../../../../types/ScheduleDateBookings.interface';
import { StartRouteModal } from './StartRoute';

export interface RouteAboutToStartProps extends LocalizeContextProps {
  repetitionId: string;
  setIsVisible: (val: SetStateAction<boolean>) => void;
}
const RouteAboutToStart = ({
  repetitionId,
  setIsVisible,
}: RouteAboutToStartProps) => {
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const [isShowingAboutToStartModal, setIsShowingAboutToStartModal] =
    useState(true);
  const [isShowingStartRouteModal, setIsShowingStartRouteModal] =
    useState(false);

  const [scheduleBookings, setScheduleBookings] =
    useState<ScheduleDateBookings | null>(null);

  const [isBookigsLoading, hasBookingsError] = useGuideScheduleBookings(
    repetitionId,
    setScheduleBookings
  );

  const [route, setRoute] = useState<Route | null>(null);
  const [isRouteLoading, hasRouteError] = useRoute(
    scheduleBookings?.routeId,
    setRoute,
    true
  );
  const routeTitle = useMemo(() => {
    if (!route) return '';
    const translation = route.titles.find(t => t.language === activeLanguage);
    return translation
      ? translation.title
      : route.titles[0]
      ? route.titles[0].title
      : '';
  }, [route, activeLanguage]);
  const photosLocations = useMemo(
    () =>
      route
        ? route.locations
            .filter(location => {
              return location.photos.length > 0;
            })
            .map(location => {
              return location.photos.map(photo => photo.id);
            })
            .flat()
        : [],
    [route]
  );

  const openStartRouteModal = () => {
    setIsShowingAboutToStartModal(false);
    setIsShowingStartRouteModal(true);
  };

  const backInAboutToStartModal = () => {
    setIsShowingStartRouteModal(false);
    setIsShowingAboutToStartModal(true);
  };

  if (isBookigsLoading || isRouteLoading) {
    return <Loader />;
  }

  if (hasBookingsError || hasRouteError || !route || !scheduleBookings) {
    return <Error />;
  }

  return (
    <div>
      <RouteAboutToStartModal
        routeTitle={routeTitle}
        routeDuration={route.duration}
        routeImageId={photosLocations[0]}
        scheduleDate={moment(scheduleBookings.date)}
        isVisible={isShowingAboutToStartModal}
        onOpenStartRouteModal={openStartRouteModal}
        onClose={() => {
          setIsShowingAboutToStartModal(false);
          setIsVisible(false);
        }}
      />
      <StartRouteModal
        repetitionId={repetitionId}
        routeTitle={routeTitle}
        routeDuration={route.duration}
        routeImageId={photosLocations[0]}
        scheduleBookings={scheduleBookings}
        isVisible={isShowingStartRouteModal}
        onBackInAboutToStartModal={backInAboutToStartModal}
        onClose={() => {
          setIsShowingStartRouteModal(false);
          setIsVisible(false);
        }}
      />
    </div>
  );
};

export default withLocalize(RouteAboutToStart);
