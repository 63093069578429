import { Layout } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompanySideNav } from '../../../components/Administration/CompanySideNav';
import { UseJWT } from '../../../hooks/authentication/UseJWT';
import { RoleType } from '../../../types/role-type.enum';
import { CompanyGuideInformation } from './Guide';
import { ListGuides } from './ListGuides';

const { Header, Sider, Content } = Layout;
const ApplicationRoutes = () => {
  const [collapse, setCollapse] = useState(false);
  useEffect(() => {
    window.innerWidth <= 760 ? setCollapse(true) : setCollapse(false);
  }, []);

  const [jwt] = UseJWT();

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const updateCounter = async () => {
  //     const { data } = await Axios.get<AdminStats>(
  //       `${API_URL}/administrator/stats`
  //     );
  //     dispatch(setMarkerCount(data));
  //   };
  //   updateCounter();
  // });
  const redirect = useMemo(() => {
    if (!jwt) return;
    return '/company/area/list/guides';
  }, [jwt]);

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapse}>
        <CompanySideNav />
      </Sider>
      <Layout>
        <Header
          className="siteLayoutBackground"
          style={{ padding: 0, background: '#001529' }}
        />
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 'calc(100vh - 114px)',
            background: '#fff',
          }}
        >
          <Switch>
            {jwt &&
              jwt?.loggedType === RoleType.COMPANY && [
                <Route
                  key={'list_guides'}
                  path="/company/area/list/guides"
                  exact={true}
                  component={ListGuides}
                />,
                <Route
                  key={'company_guide'}
                  path="/company/area/guide/:id"
                  exact={true}
                  component={CompanyGuideInformation}
                />,
              ]}
            <Redirect to={`${redirect}`} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
export default ApplicationRoutes;
