import Axios from 'axios';
import { API_URL } from '../../consts';
interface ManageWhatWeGiveInterface {
  firstTitle: string;
  text1: string;
  text2: string;
  secondTitle: string;
  text3: string;
  text4: string;

  language: string;
}
interface StepInterface {
  title: string;
  position: number;
  description: string;
  language: string;
}
export interface WhatWeGiveDto {
  whatWeGive: ManageWhatWeGiveInterface[];
  steps: StepInterface[];
}

export const postWhatWeGive = (dto: WhatWeGiveDto) => {
  return Axios.post(
    `${API_URL}/content-management/guide-landing-page/what-we-give`,
    dto
  )
    .catch(err => {
      console.log('object', err.response.data);
      return Promise.reject(err.response.data);
    })
    .then(success => {
      return Promise.resolve(success);
    });
};
