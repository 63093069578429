export enum SkillType {
  STORY_TELLER = 'STORY_TELLER',
  SINGER = 'SINGER',
  HISTORIAN = 'HISTORIAN',
  FAUNA_SPECIALIST = 'FAUNA_SPECIALIST',
  FLORA_SPECIALIST = 'FLORA_SPECIALIST',
  SPECIALIST_LOCAL_CUSTOMS = 'SPECIALIST_LOCAL_CUSTOMS',
  GASTRONOMY_SPECIALIST = 'GASTRONOMY_SPECIALIST',
  EXTREME_SPORTSMAN = 'EXTREME_SPORTSMAN',
  HIKER = 'HIKER',
  INTERPRETER_GUIDE = 'INTERPRETER_GUIDE',
  OTHER = 'OTHER',
}

export function makeEnumArray(enumObject: any) {
  var all = [];
  for (var key in enumObject) {
    all.push(enumObject[key]);
  }
  // enumObject.all = all;
  return all;
}
interface InterfaceEnum {
  [key: string]: string;
}
export function createEnumObject(enumObject: any): InterfaceEnum[] {
  const arrayEnum: InterfaceEnum[] = [];

  for (const key in enumObject) {
    arrayEnum.push({ [key]: enumObject[key] });
  }
  // enumObject.all = all;
  return arrayEnum;
}
// Object.keys(SkillType_es_ES).map(skill_key => (
//   <Option key={skill_key} value={skill_key}>
//     {SkillType_en_US[skill_key]}
//   </Option>
// ))
