import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { LogoUrl, WhiteLogoUrl } from '../../../hooks/customization/getLogo';
import { desktopPadding, getTheme, headerHeight, mobilePadding, mobileThreshhold } from '../../../utils';
import { Text } from '../../Typography';
import { Button } from '../Button';
import { FallbackImage } from '../Image';
import { Modal } from '../Modal';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    ...mobilePadding,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    minHeight: headerHeight,
    alignItems: 'center',
  },
  blueBackground: () => ({
    backgroundColor: getTheme().primary,
  }),
  logoImage: {
    maxHeight: headerHeight * 0.75,
  },
  btn: {
    textTransform: 'uppercase',
  },
  borderedBtn: {
    borderColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& > *:not(:last-child)': {
      marginRight: 0,
      marginBottom: '1rem',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  content: {
    paddingTop: '1rem',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    container: { ...desktopPadding, paddingBottom: 0 },
    buttonContainer: {
      flexDirection: 'row',
      '& > *:not(:last-child)': {
        marginRight: '1rem',
        marginBottom: 0,
      },
    },
  },
});

interface RouteCreationHeaderProps {
  onSave?: () => void;
  white?: boolean;
}

function RouteCreationHeader({ onSave, white }: RouteCreationHeaderProps) {
  const classes = useStyles();
  const history = useHistory();

  const [confirmOpen, setConfirmOpen] = useState<'leave' | 'draft' | 'none'>(
    'none'
  );

  return (
    <div
      className={classNames(
        classes.container,
        !white ? classes.blueBackground : undefined
      )}
    >
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.content}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                if (confirmOpen === 'draft') {
                  if (onSave) onSave();
                } else if (confirmOpen === 'leave') {
                  history.goBack();
                }
                setConfirmOpen('none');
              }}
            >
              Sim
            </Button>
            <Button
              onClick={() => {
                setConfirmOpen('none');
              }}
            >
              Não
            </Button>
          </div>
        }
        visible={confirmOpen !== 'none'}
      >
        <Text>
          {confirmOpen === 'leave' ? (
            <Translate id="routes.discardConfirmation" />
          ) : (
            <Translate id="routes.saveAndExitConfirmation" />
          )}
        </Text>
      </Modal>
      <FallbackImage
        className={classes.logoImage}
        src={white ? WhiteLogoUrl : LogoUrl}
        fallback={
          white
            ? require('../Header/assets/logo_cor.png')
            : require('../Header/assets/logo_branco.png')
        }
        alt="logo"
      />
      <div className={classes.buttonContainer}>
        <Button
          type="primary"
          className={classNames(
            classes.btn,
            !white ? classes.borderedBtn : undefined
          )}
          onClick={() => {
            setConfirmOpen('leave');
          }}
        >
          <Translate id="routes.discard" />
        </Button>
        {onSave && (
          <Button
            type="primary"
            className={classNames(
              classes.btn,
              !white ? classes.borderedBtn : undefined
            )}
            onClick={() => {
              setConfirmOpen('draft');
            }}
          >
            <Translate id="routes.saveAndExit" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default RouteCreationHeader;
