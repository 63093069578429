import { FullTheme } from '../../hooks/customization/fetchTheme';
import { GuideStateType } from '../../types/guide-state-type.enum';
import {
  AdminStats,
  AppActions,
  LastAppZone,
  SET_GUIDE_UNSEEN_NOTIFICATIONS,
  SET_LAST_APP_ZONE,
  SET_LAST_GUIDE_STATE,
  SET_MARKER_COUNT,
  SET_REACHED_BOTTOM,
  SET_THEME,
} from './types';

export function setReachedBottom(reachedBottom: boolean): AppActions {
  return {
    type: SET_REACHED_BOTTOM,
    payload: reachedBottom,
  };
}

export function setGuideUnseenNotifications(number: number): AppActions {
  return {
    type: SET_GUIDE_UNSEEN_NOTIFICATIONS,
    payload: number,
  };
}

export function setVisitorUnseenNotifications(number: number): AppActions {
  return {
    type: SET_GUIDE_UNSEEN_NOTIFICATIONS,
    payload: number,
  };
}

export function setLastGuideState(state: GuideStateType): AppActions {
  return {
    type: SET_LAST_GUIDE_STATE,
    payload: state,
  };
}

export function setLastAppZone(zone: LastAppZone): AppActions {
  return {
    type: SET_LAST_APP_ZONE,
    payload: zone,
  };
}
export function setMarkerCount(stats: AdminStats | null) {
  return {
    type: SET_MARKER_COUNT,
    payload: stats,
  };
}
export function setTheme(theme: FullTheme): AppActions {
  return {
    type: SET_THEME,
    payload: theme,
  };
}
