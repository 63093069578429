import { makeStyles } from '@material-ui/styles';
import { Space, Table } from 'antd';
import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Link, useHistory } from 'react-router-dom';
import Loader from 'react-spinners/BeatLoader';
import { Button } from '../../../../../components/App/Button';
import { Error } from '../../../../../components/App/Error';
import { Input } from '../../../../../components/App/Input';
import { Modal } from '../../../../../components/App/Modal';
import { Text } from '../../../../../components/Typography';
import { addVideoUrl } from '../../../../../hooks/administration/addVideoUrl';
import {
  ThemeListResponse,
  useThemes,
} from '../../../../../hooks/customization/useThemes';
import { useVideoUrl } from '../../../../../hooks/customization/useVideoUrl';
import { GenericPageTransition } from '../../../../PageUtils';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
    '& > .segment': {
      display: 'flex',
      '& > *:first-child': {
        marginRight: '1rem',
      },
    },
  },
  modalContent: { padding: 24 },
});

const columns = [
  {
    title: <Translate id="adminstration.theme.hostname" />,
    dataIndex: 'hostname',
    key: 'hostname',
  },
  {
    title: <Translate id="adminstration.theme.primary" />,
    render: (color: string) => {
      return (
        <div
          style={{
            backgroundColor: color,
            width: 20,
            height: 20,
            borderRadius: 20,
          }}
        />
      );
    },
    dataIndex: 'primary',
    key: 'primary',
  },
  {
    title: <Translate id="adminstration.theme.secondary" />,
    render: (color: string) => {
      return (
        <div
          style={{
            backgroundColor: color,
            width: 20,
            height: 20,
            borderRadius: 20,
          }}
        />
      );
    },
    dataIndex: 'secondary',
    key: 'secondary',
  },
  {
    title: <Translate id="adminstration.actions" />,
    key: 'id',
    dataIndex: 'id',

    render: (id: any) => (
      <Space size="middle">
        <Link to={`/admin/area/theme/${id}`}>
          <Translate id="button.edit" />
        </Link>
      </Space>
    ),
  },
];

const List = () => {
  const [tableData, setTableData] = useState<ThemeListResponse>([]);
  const classes = useStyles();
  const [isLoading, error] = useThemes(setTableData);
  const [submittingUrl, setSubmittingUrl] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoadingVideoUrl, errorVideoUrl] = useVideoUrl(setVideoUrl);
  const history = useHistory();
  const action = useCallback(async () => {
    try {
      setSubmittingUrl(true);

      await addVideoUrl({
        videoUrl,
      });
    } catch (err) {
    } finally {
      setSubmittingUrl(false);
      setShowModal(false);
    }
  }, [videoUrl]);
  if (isLoading || isLoadingVideoUrl) return <Loader />;

  if (error || errorVideoUrl) return <Error />;

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setSubmittingUrl(false);
                setVideoUrl('');
                setShowModal(false);
              }}
            >
              <Translate id="button.cancel" />
            </Button>

            <Button disabled={submittingUrl} type="primary" onClick={action}>
              <Translate id="button.save" />
            </Button>
          </div>
        }
        visible={showModal}
        closable={true}
      >
        <Text noMargin weight="semibold">
          <Translate id="administration.member.url" />
        </Text>
        <Input
          value={videoUrl}
          onChange={e => {
            setVideoUrl(e.target.value);
          }}
        />
      </Modal>
      <div className={classes.divAddButton}>
        <Button
          type="primary"
          shape="round"
          size="large"
          color="red"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <Translate id="adminstration.theme.addVideoURL" />
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={() => {
            history.push('/admin/area/theme/create');
          }}
        >
          <Translate id="adminstration.theme.create" />
        </Button>
      </div>
      <Table dataSource={tableData} columns={columns} />
    </motion.div>
  );
};

export default List;
