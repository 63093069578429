import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ScheduleManagementModal from '../../../../pages/Guide/Schedules/CreateSchedules/ScheduleManagementModal';
import { ReactComponent as Group } from '../../../../shared_assets/icons/group.svg';
import { ReactComponent as WarningYellow } from '../../../../shared_assets/icons/warning_yellow.svg';
import { RootState } from '../../../../store';
import { BookingCancelNotification } from '../../../../types/notification.interface';
import { complexTranslate } from '../../../../utils';
import { Heading, Text } from '../../../Typography';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { NotificationsProps } from '../Notification';

interface NotificationBookingCancelProps
  extends NotificationsProps,
    LocalizeContextProps {
  notification: BookingCancelNotification;
}

const NotificationBookingCancel: React.FC<NotificationBookingCancelProps> = ({
  translate,
  notification,
  simpleVersion,
  classes,
}) => {
  const { seen, type, bookingGroupSize, scheduleRepetitionWithRoute } =
    notification;
  const { route } = scheduleRepetitionWithRoute;
  const [isModalShowing, setIsModalShowing] = useState(false);
  const history = useHistory();
  const activeLanguage = useSelector(
    (state: RootState) => state.userConfiguration.activeLanguage
  );
  const routeTitle = useMemo(() => {
    const translation = route.titles.find(t => t.language === activeLanguage);
    return translation
      ? translation.title
      : route.titles[0].title
      ? route.titles[0].title
      : '';
  }, [route, activeLanguage]);
  return (
    <>
      {!simpleVersion && (
        <div className={classes.rowDirection}>
          <WarningYellow className={classes.icon} />
          <Heading level={5} noMargin>
            {translate(`notificationType.${type}`).toString()}
          </Heading>
          {!seen && (
            <Badge
              className={classNames(classes.marginLeft, classes.uppercase)}
              type="primary"
              small
            >
              {translate('notifications.new').toString()}
            </Badge>
          )}
        </div>
      )}
      <div style={{ margin: '0.5rem 0' }}>
        {!simpleVersion && <Text noMargin>{routeTitle}</Text>}
        <Text variant={!simpleVersion ? 'faded' : 'default'} noMargin>
          {complexTranslate(
            translate('notifications.bookingCancel').toString(),
            {
              '{date}': (
                <Text key="date" className={classes.inlineBtn} noMargin>
                  {moment(scheduleRepetitionWithRoute.date).format('D MMMM')}
                </Text>
              ),
              '{initHour}': (
                <Text key="start" className={classes.inlineBtn} noMargin>
                  {moment(scheduleRepetitionWithRoute.date).format('LT')}
                </Text>
              ),
              '{endHour}': (
                <Text key="end" className={classes.inlineBtn} noMargin>
                  {moment(scheduleRepetitionWithRoute.date)
                    .add(route.duration, 'hours')
                    .format('LT')}
                </Text>
              ),
            }
          )}
        </Text>
        {!simpleVersion && (
          <div className={classes.rowDirection}>
            <Group className={classes.icon} />
            <Text noMargin>
              {bookingGroupSize === 1
                ? complexTranslate(
                    translate('replaceValue.onePerson').toString(),
                    {
                      '{value}': (
                        <Text
                          key="value"
                          className={classes.inlineBtn}
                          noMargin
                        >
                          {bookingGroupSize}
                        </Text>
                      ),
                    }
                  )
                : complexTranslate(
                    translate('replaceValue.moreThanOnePerson').toString(),
                    {
                      '{value}': (
                        <Text
                          key="value"
                          className={classes.inlineBtn}
                          noMargin
                        >
                          {bookingGroupSize}
                        </Text>
                      ),
                    }
                  )}
            </Text>
          </div>
        )}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="link"
          onlyText
          onClick={() => {
            history.push(`/route/${route.id}`);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.seeRoute').toString()}
          </Text>
        </Button>
        <ScheduleManagementModal
          scheduleRepetition={scheduleRepetitionWithRoute}
          visible={isModalShowing}
          onRequestClose={() => {
            setIsModalShowing(false);
          }}
        />
        <Button
          type="link"
          onlyText
          onClick={() => {
            setIsModalShowing(true);
          }}
        >
          <Text variant="link" noMargin>
            {translate('button.seeSchedule').toString()}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default withLocalize(NotificationBookingCancel);
