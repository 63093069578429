import Axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { API_URL } from '../../consts';

type Hook = (
  setSelectedGuides: (val: SetStateAction<string[]>) => void
) => [boolean, boolean];

export const requestRoutes = () =>
  Axios.get(`${API_URL}/content-management/guide-landing-page/selected-guides`);

export const useSelectedGuides: Hook = setSelectedGuides => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: guideIds } = await requestRoutes();
        setSelectedGuides(guideIds as string[]);
      } catch (err) {
        setSelectedGuides([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setSelectedGuides]);

  return [isLoading, isError];
};
