import { makeStyles } from '@material-ui/styles';
import Input, { TextAreaProps as ANTDTextAreaProps } from 'antd/es/input';
import classNames from 'classnames';
import React from 'react';

import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  textArea: {
    borderColor: getTheme().neutral4,
    borderWidth: 2,
  },
});

interface TextAreaProps extends ANTDTextAreaProps {}

const TextArea: React.FC<TextAreaProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <Input.TextArea
      className={classNames(classes.textArea, className)}
      {...props}
    />
  );
};

export default TextArea;
