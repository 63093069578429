import { makeStyles } from '@material-ui/styles';
import ANTDInput, { InputProps as ANTDInputProps } from 'antd/es/input';
import classNames from 'classnames';
import React from 'react';
import tinycolor from 'tinycolor2';

import { getTheme } from '../../../utils';

const useStyles = makeStyles({
  hugeInput: {
    height: 40,
    borderRadius: 5,
  },
  input: () => ({
    borderColor: getTheme().neutral4,
    borderWidth: 2,
    '&.ant-input-affix-wrapper:hover, &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused': {
      borderColor: getTheme().primary + ' !important',
      borderRightWidth: '2px !important',
      boxShadow: `0 0 0 2px ${tinycolor(getTheme().primary)
        .setAlpha(0.2)
        .toRgbString()}`,
    },
    '& > .ant-input-prefix ': {
      '& *': {
        height: '100%',
      },
      height: 16,
      marginRight: '1em',
    },
    '& > .ant-input-suffix ': {
      '& *': {
        height: '100%',
      },
      height: 16,
      marginLeft: '1em',
    },
    '& > .ant-input': {
      padding: 0,
      fontSize: 14,
    },
    fontSize: 14,
    alignItems: 'center',
  }),
});

interface InputProps extends Omit<ANTDInputProps, 'icon'> {
  huge?: boolean;
}

function Input({ className, huge, size, ...props }: InputProps) {
  const classes = useStyles();

  return (
    <ANTDInput
      {...props}
      size={huge ? 'large' : size}
      className={
        huge
          ? classNames(classes.hugeInput, classes.input, className)
          : classNames(className, classes.input)
      }
    />
  );
}

export default React.memo(Input);
