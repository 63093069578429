import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { ReactComponent as ChangeProfile } from '../../../shared_assets/icons/change_profile.svg';
import { ReactComponent as Contacts } from '../../../shared_assets/icons/contactos.svg';
import { ReactComponent as Logout } from '../../../shared_assets/icons/exit.svg';
import { ReactComponent as Language } from '../../../shared_assets/icons/language.svg';
import { ReactComponent as About } from '../../../shared_assets/icons/quem_somos.svg';
import { DrawerFragmentProps } from './Drawer';
import DrawerItem from './DrawerItem';

const useStyles = makeStyles({
  divider: { margin: '1rem 0' },
});

export interface UnauthenticatedVisitorDrawerFragmentProps
  extends DrawerFragmentProps {
  setShowRegisterModal: (v: boolean) => void;
  setShowLoginModal: (v: boolean) => void;
}

export default function UnauthenticatedVisitorDrawerFragment({
  reduxActiveLanguage,
  setOpenStatus,
  setShowLanguageModal,
  setShowLoginModal,
  setShowRegisterModal,
}: UnauthenticatedVisitorDrawerFragmentProps) {
  const classes = useStyles();
  return (
    <>
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setShowRegisterModal(true);
        }}
        title={<Translate id="landing.drawer.signUp" />}
        icon={<Logout />}
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setShowLoginModal(true);
        }}
        title={<Translate id="landing.drawer.signIn" />}
        icon={<ChangeProfile />}
      />
      <Divider className={classes.divider} />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        onClick={() => {
          setShowLanguageModal(true);
        }}
        title={<Translate id="landing.drawer.language" />}
        subtitle={
          <span>
            <Translate id={`languages.${reduxActiveLanguage}`} />
            {` (${reduxActiveLanguage.split('_')[1]})`}
          </span>
        }
        icon={<Language />}
      />
      <Divider className={classes.divider} />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/about"
        title={<Translate id="landing.whoWeAre" />}
        icon={<About />}
      />
      <DrawerItem
        setOpenStatus={setOpenStatus}
        closeOnClick
        to="/contacts"
        title={<Translate id="landing.drawer.contacts" />}
        icon={<Contacts />}
      />
    </>
  );
}
