import Axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../../consts';

type Hook = (activeLanguage: string) => [string[], boolean, boolean];

const requestSkills = (activeLanguage: string) =>
  Axios.get(`${API_URL}/administrator/skills/${activeLanguage}`);

export const useSkills: Hook = (activeLanguage: string) => {
  const [data, setData] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await requestSkills(activeLanguage);
        setData(data);
      } catch (err) {
        setData([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [activeLanguage]);

  return [data, isLoading, isError];
};
