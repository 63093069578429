import { makeStyles } from '@material-ui/styles';
import message from 'antd/es/message';
import classNames from 'classnames';
import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Loader } from '../../../components/App/Loader';
import {
  FinancialInterface,
  useFinancial,
} from '../../../hooks/administration/useFinancial';
import { useNifExist } from '../../../hooks/guide/useNifExist';
import { OnBoardingForm } from '../../../pages/Guide/Onboarding/useOnboardingForm';
import { ReactComponent as Bill } from '../../../shared_assets/icons/bill.svg';
import { ReactComponent as Upload } from '../../../shared_assets/icons/upload.svg';
import {
  castShadow,
  desktopPadding,
  getTheme,
  maxAllowedFileSize,
  mobilePadding,
  mobileThreshhold,
} from '../../../utils';
import { Heading, Text } from '../../Typography';
import { AnimatedError } from '../AnimatedError';
import { Button } from '../Button';
import { Input } from '../Input';
import { Option, Select } from '../Select';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
    textAlign: 'center',
    ...mobilePadding,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    padding: '1rem 0',
  },
  alignToBottom: {
    alignSelf: 'flex-end',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    margin: '0 1rem',
  },
  inputsContainer: {
    marginTop: '1rem',
    textAlign: 'initial',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'flex-end',
    margin: '1rem 0',
  },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    page: {
      ...desktopPadding,
    },
    body: {
      display: 'flex',
      padding: '0 25%',
      flex: 1,
    },
    container: {
      padding: '2rem 2rem 3rem 2rem',
      boxShadow: castShadow,
      borderRadius: '10px',
    },
  },
  select: {
    width: '100%',
  },
  textAlignInitial: {
    textAlign: 'initial',
  },
  icon: {
    marginRight: '1em',
  },
  titleMargin: {
    marginTop: '2rem',
  },
  invisible: {
    display: 'none',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  file: {
    display: 'flex',
    backgroundColor: getTheme().neutral4,
    borderRadius: '1rem',
    margin: '1rem 0',
    padding: '0.5rem',
    textAlign: 'initial',
  },
  fileIconContainer: {
    flex: 0.1,
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: ' 1rem',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
});

interface PrivateFinancialUserProps {
  form: OnBoardingForm;
  setCertificates: (val: SetStateAction<File[]>) => void;
  certificates: File[];
  setBlockNext: (blockNext: SetStateAction<boolean>) => void;
}
interface PrivateFinancialUserProps extends LocalizeContextProps {}
const PrivateFinancialUser = ({
  translate,
  setCertificates,
  certificates,
  form,
  setBlockNext,
}: PrivateFinancialUserProps) => {
  const classes = useStyles();
  const [financial, setFinancial] = useState<FinancialInterface | null>(null);
  useFinancial(setFinancial);
  let certificateInputRef: HTMLInputElement | null = null;

  const isRegisteredDto = useMemo(
    () => ({
      nif: form.nif.value,
    }),
    [form.nif.value]
  );

  const isRegistered = useNifExist(isRegisteredDto);
  const ivaOptions = useMemo(() => {
    if (!financial) return;

    return financial.ivas.map(i => {
      return (
        <Option key={i.id} value={i.id}>
          {i.relevance === 'MINOR' ? (
            <Text variant="faded">{i.invoiceMention}</Text>
          ) : (
            <Text>{i.invoiceMention}</Text>
          )}
        </Option>
      );
    });
  }, [financial]);
  const retentionsOptions = useMemo(() => {
    if (!financial) return;

    return financial.retentions.map(r => {
      return (
        <Option key={r.id} value={r.id}>
          <Text>{r.invoiceMention}</Text>
        </Option>
      );
    });
  }, [financial]);

  useEffect(() => {
    setBlockNext(
      form.iban.isInvalid ||
        form.nif.isInvalid ||
        form.companyName.isInvalid ||
        form.ivaExemption.isInvalid ||
        form.retention.isInvalid ||
        certificates.length === 0
    );
  }, [
    certificates,
    form.companyName.isInvalid,
    form.iban.isInvalid,
    form.ivaExemption.isInvalid,
    form.nif.isInvalid,
    form.retention.isInvalid,
    setBlockNext,
  ]);

  return (
    <>
      <div className={classes.inputsContainer}>
        <Text weight="bold">
          <Translate id="onboarding.nif" />
        </Text>
        <Input
          placeholder={translate('onboarding.selectNif').toString()}
          type="number"
          onChange={ev => {
            form.nif.set(ev.currentTarget.value);
          }}
          value={form.nif.value}
          huge
        />
        <AnimatedError
          isVisible={form.nif.isInvalid || !!isRegistered}
          reason={
            form.nif.reason ||
            (isRegistered === 'loading' ? (
              <Loader />
            ) : (
              isRegistered && <Translate id="onboarding.nifIsAlreadyInUse" />
            ))
          }
        />
        <Text weight="bold">
          <Translate id="onboarding.companyName" />
        </Text>
        <Input
          placeholder={translate('onboarding.selectACompanyName').toString()}
          onChange={ev => {
            form.companyName.set(ev.currentTarget.value);
          }}
          value={form.companyName.value}
          huge
        />
        <AnimatedError
          isVisible={form.companyName.isInvalid}
          reason={form.companyName.reason}
        />
        <Text weight="bold">
          <Translate id="onboarding.iban" />
        </Text>
        <Input
          huge
          placeholder={translate('onboarding.selectIban').toString()}
          onChange={ev => {
            form.iban.set(ev.currentTarget.value);
          }}
          value={form.iban.value}
        />
        <AnimatedError
          isVisible={form.iban.isInvalid}
          reason={form.iban.reason}
        />
        <Text weight="bold">
          <Translate id="onboarding.ivaExemption" />
        </Text>
        {financial && (
          <>
            <Select
              placeholder={translate(
                'onboarding.ivaExemptionPlaceholder'
              ).toString()}
              className={classes.select}
              value={
                form.ivaExemption.value === ''
                  ? undefined
                  : form.ivaExemption.value
              }
              onChange={ev => form.ivaExemption.set(ev as string)}
              huge
              getPopupContainer={() => document.body}
            >
              {ivaOptions}
            </Select>
            <Text variant="faded">
              {form.ivaExemption.value
                ? financial.ivas.find(i => i.id === form.ivaExemption.value)
                    ?.applicableStandard
                : ''}
            </Text>
            <AnimatedError
              isVisible={form.ivaExemption.isInvalid}
              reason={form.ivaExemption.reason}
            />
          </>
        )}
        <Text weight="bold">
          <Translate id="onboarding.retention" />
        </Text>
        {financial && (
          <>
            <Select
              placeholder={translate(
                'onboarding.retentionPlaceholder'
              ).toString()}
              className={classes.select}
              value={
                form.retention.value === '' ? undefined : form.retention.value
              }
              onChange={ev => form.retention.set(ev as string)}
              huge
              getPopupContainer={() => document.body}
            >
              {retentionsOptions}
            </Select>
            <Text variant="faded">
              {form.retention.value
                ? financial.retentions.find(r => r.id === form.retention.value)
                    ?.applicableStandard
                : ''}
            </Text>
            <AnimatedError
              isVisible={form.retention.isInvalid}
              reason={form.retention.reason}
            />
          </>
        )}
        <Heading level={5} className={classes.titleMargin}>
          <Translate id="onboarding.certificates" />
        </Heading>

        <input
          ref={ref => (certificateInputRef = ref)}
          onChange={ev => {
            if (
              ev.currentTarget.files &&
              ev.currentTarget.files.length > 0 &&
              ev.currentTarget.files[0].size < maxAllowedFileSize
            ) {
              setCertificates([ev.currentTarget.files[0]]);
            } else {
              message.info(translate('error.fileSize').toString());
            }
          }}
          type="file"
          className={classes.invisible}
        />
        <div className={classes.fileContainer}>
          {certificates.map(c => (
            <div className={classes.file}>
              <div className={classes.fileIconContainer}>
                <Bill />
              </div>
              <div className={classes.textContainer}>
                <Text>{c.name}</Text>
                <span>{c.size}</span>
              </div>
            </div>
          ))}
        </div>
        <Button
          className={classes.marginTop}
          type="ghost"
          onClick={() => {
            if (certificateInputRef) certificateInputRef.click();
          }}
          prefix={<Upload className={classes.icon} />}
        >
          <Translate id="onboarding.uploadCertificates" />
        </Button>
        <Text
          className={classNames(classes.textAlignInitial, classes.marginTop)}
        >
          <Translate id="onboarding.certificateSize" />
        </Text>
      </div>
    </>
  );
};
export default withLocalize(PrivateFinancialUser);
