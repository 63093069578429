import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { getTheme, headerHeight } from '../../../utils';

const useStyles = makeStyles({
  container: () => ({
    display: 'flex',
    height: headerHeight,
    justifyContent: 'center',
    alignItems: 'center',
    '& .dots': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0.2rem',
      '& .active': {
        width: 10,
        height: 10,
        borderColor: getTheme().primary,
        backgroundColor: getTheme().primary,
        borderRadius: 10,
      },
      '& .inactive': {
        width: 12,
        height: 12,
        border: `2px solid ${getTheme().neutral2}`,
        borderRadius: 10,
      },
    },
  }),
});

interface StepManagerProps {
  totalSteps: number;
  step: number;
}

const StepManager = ({ step, totalSteps }: StepManagerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Array.from(Array(totalSteps), (_, i) => (
        <div className={'dots'} key={i.toString()}>
          <div className={i + 1 === step ? 'active' : 'inactive'}></div>
        </div>
      ))}
    </div>
  );
};

export default StepManager;
