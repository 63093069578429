/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles } from '@material-ui/styles';
import { Table } from 'antd';
import Button from 'antd/es/button';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import Badge2 from '../../../../../../components/App/Badge2/Badge2';
import { Loader } from '../../../../../../components/App/Loader';
import { Modal } from '../../../../../../components/App/Modal';
import { Text } from '../../../../../../components/Typography';
import { cancelBooking } from '../../../../../../hooks/visitor/booking/cancelBooking';
import {
  useVisitorBookings,
  VisitorBookingInterface,
} from '../../../../../../hooks/visitor/booking/useVisitorBookings';
import { BookingState } from '../../../../../../types/booking-state.enum';
import { mobileThreshhold } from '../../../../../../utils';

const useStyles = makeStyles({
  divAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  modalContent: { padding: 24 },
  [`@media (min-width: ${mobileThreshhold}px)`]: {
    addRemoveBtn: {
      '& > :last-child': {
        marginLeft: '1rem',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    flexShrink: 0,
  },
});

interface ParamsInterface {
  id: string;
}
interface TableDataInterface {
  key: string;
  pediRoute: string;
  date: string;
  groupSize: number;
  price: string;
  state: BookingState;
}
interface ListVisitorBookingsProps extends LocalizeContextProps {}
const ListVisitorBookings = ({ translate }: ListVisitorBookingsProps) => {
  const params = useParams<ParamsInterface>();

  const classes = useStyles();
  const [bookings, setBookings] = useState<VisitorBookingInterface[]>([]);
  const [fetchingBookings] = useVisitorBookings(params.id, setBookings);
  const [showCancelBookingModal, setShowCancelBookingModal] =
    useState<boolean>(false);
  const [cancelBookingId, setCancelBookingId] = useState<string>('');
  const [tableData, setTableData] = useState<TableDataInterface[]>([]);
  useEffect(() => {
    setTableData(
      bookings.map(v => {
        return {
          key: v.id,
          pediRoute: v.routeTitle,
          date: moment(v.repetitionDate).format('LL'),
          groupSize: v.groupSize,
          price: `${v.price} €`,
          state: v.state,
        };
      })
    );
  }, [bookings]);

  const columns = useMemo(() => {
    return [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => {
          switch (record.state) {
            case BookingState.PAYED:
              return (
                <Badge2
                  key={record.key}
                  type={'success'}
                  text={translate('adminstration.guideApproved').toString()}
                />
              );
            case BookingState.CANCELED:
              return (
                <Badge2
                  key={record.key}
                  type={'error'}
                  text={translate('adminstration.guideNotApproved').toString()}
                />
              );
            default:
              return (
                <Badge2
                  key={record.key}
                  type={'warning'}
                  text={translate(
                    'adminstration.guideWaitingApproval'
                  ).toString()}
                />
              );
          }
        },
      },
      {
        title: <Translate id="adminstration.name" />,
        dataIndex: 'pediRoute',
        key: 'pediRoute',
      },
      {
        title: <Translate id="adminstration.nif" />,
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: <Translate id="adminstration.gender" />,
        dataIndex: 'groupSize',
        key: 'groupSize',
      },

      {
        title: <Translate id="adminstration.email" />,
        dataIndex: 'price',
        key: 'price',
      },

      {
        title: <Translate id="adminstration.actions" />,
        key: 'action',
        render: (text: any, record: any) => (
          // MOSTRAR A ROTA !
          <a
            onClick={ev => {
              setCancelBookingId(record.key);
              setShowCancelBookingModal(true);
            }}
          >
            cancelBooking
          </a>
        ),
      },
    ];
  }, [translate]);

  //   const createVisitorCallback = useCallback(() => {
  //     // history.push('/admin/area/create-guide');
  //   }, []);
  //cancelBooking
  const cancelBookingCallback = useCallback(() => {
    cancelBooking(cancelBookingId);
    setShowCancelBookingModal(false);
    setBookings(
      bookings.map(b => {
        return {
          ...b,
          state: b.id === cancelBookingId ? BookingState.CANCELED : b.state,
        };
      })
    );
  }, [cancelBookingId, bookings]);

  if (fetchingBookings) return <Loader />;

  return (
    <>
      <Modal
        fullScreenOnMobile={false}
        contentContainerClassName={classes.modalContent}
        headerTitle={<Translate id="button.addSkill" />}
        footer={
          <div className={classes.footer}>
            <Button
              onClick={() => {
                setCancelBookingId('');
                setShowCancelBookingModal(false);
              }}
            >
              <Translate id="button.cancel" />
            </Button>
            <div className={classes.addRemoveBtn}>
              <Button
                type="primary"
                onClick={() => {
                  cancelBookingCallback();
                }}
              >
                <Translate id="button.save" />
              </Button>
            </div>
          </div>
        }
        visible={showCancelBookingModal}
        closable={false}
      >
        <Text weight="semibold">Tens a certeza ? </Text>
      </Modal>
      <Table dataSource={tableData} columns={columns} />
    </>
  );
};
export default withLocalize(ListVisitorBookings);
