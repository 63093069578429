import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../consts';
import { ListGuidesAdminInterface } from '../../types/list-guide.interface';

type Hook = (
  setListGuides: (val: SetStateAction<ListGuidesAdminInterface[]>) => void,
  info: {
    search?: string;

    companyId?: string;
  }
) => [boolean, boolean];

const requestList = (
  cancel: CancelToken | undefined,
  search: string,

  companyId: string
) =>
  Axios.get<ListGuidesAdminInterface[]>(`${API_URL}/company/list-guides`, {
    params: {
      search,
      companyId,
    },
    cancelToken: cancel,
  });

export const useListCompanyGuides: Hook = (
  setListCompanyGuides,
  { search, companyId }
) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cancel = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    if (cancel.current) cancel.current.cancel();

    cancel.current = Axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: list } = await requestList(
          cancel.current?.token,
          search || '',
          companyId || ''
        );

        setListCompanyGuides(list);
        setIsError(false);
        setIsLoading(false);
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setListCompanyGuides([]);
          setIsError(true);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [dispatch, setListCompanyGuides, search, companyId]);

  return [isLoading, isError];
};
