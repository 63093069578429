import { makeStyles } from '@material-ui/styles';
import Divider from 'antd/es/divider';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';

import { Modal } from '../../components/App/Modal';
import ForgotPassword from './ForgotPassword';
import Login from './Login';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  headerDivider: {
    margin: '0 0 1rem 0',
  },
  marginBetween: {
    margin: '1rem 0',
  },
  popupMargin: {
    marginBottom: '1.5rem',
  },
  forgotPasswordBtn: {
    alignSelf: 'center',
  },
  formBottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  backBtn: {
    marginBottom: '1rem',
  },
  bottomContainer: {
    display: 'flex',
    flex: 1,
    paddingBottom: '3rem',
    '& > *': {
      width: '100%',
    },
  },
  buttonIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
  },
});

export type LoginType = 'VISITOR' | 'GUIDE';

interface LoginProps {
  loginType: LoginType;
  isVisible: boolean;
  openRequestRegister: () => void;
  onRequestClose: () => void;
}

function LoginModal({
  loginType,
  isVisible,
  openRequestRegister,
  onRequestClose,
}: LoginProps) {
  const classes = useStyles();

  const [resettingPassword, setResettingPassword] = useState<boolean>(false);

  return (
    <Modal
      visible={isVisible}
      variant="close"
      onRequestClose={() => {
        onRequestClose();
        setResettingPassword(false);
      }}
      footer={null}
      headerTitle={
        resettingPassword ? (
          <Translate id="resetPassword.resetPasswordEmailTitle" />
        ) : (
          <>
            <Translate id="landing.drawer.signIn" />
            {' - '}
            {loginType === 'GUIDE' ? (
              <Translate id="guide" />
            ) : (
              <Translate id="visitor" />
            )}
          </>
        )
      }
    >
      <Divider className={classNames(classes.headerDivider)} />
      <div className={classes.container}>
        {resettingPassword ? (
          <ForgotPassword
            classes={classes}
            loginType={loginType}
            goToLogin={() => {
              setResettingPassword(false);
            }}
            onRequestClose={onRequestClose}
          />
        ) : (
          <Login
            onForgotPassword={() => {
              setResettingPassword(true);
            }}
            onRequestClose={onRequestClose}
            classes={classes}
            loginType={loginType}
            onRequestRegistration={openRequestRegister}
          />
        )}
      </div>
    </Modal>
  );
}

export default LoginModal;
