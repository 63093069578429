import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../shared_assets/icons/arrow down.svg';
import { ReactComponent as Language } from '../../../shared_assets/icons/language.svg';
import { RootState } from '../../../store';
import { getTheme } from '../../../utils';
import { Button } from '../Button';
import { HelpModal } from '../HelpModal';

const useStyles = makeStyles({
  container: { display: 'flex' },
  smallIcon: {
    width: 10,
  },
});

interface DesktopLinkSectionProps {
  reduxActiveLanguage: string;
  setShowLanguageModal: (v: boolean) => void;
}

const DesktopLinkSection = ({
  reduxActiveLanguage,
  setShowLanguageModal,
}: DesktopLinkSectionProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [showingHelp, setShowingHelp] = useState<boolean>(false);

  const lastAppZone = useSelector((state: RootState) => state.app.lastAppZone);

  return (
    <div className={classes.container}>
      <Button
        type="link"
        color={getTheme().neutral1}
        prefix={<Language />}
        suffix={<ArrowDown className={classes.smallIcon} />}
        onClick={() => setShowLanguageModal(true)}
      >
        <Translate id={`languages.${reduxActiveLanguage}`} />
        {` (${reduxActiveLanguage.split('_')[1]})`}
      </Button>
      <Button
        type="link"
        onClick={() => history.push('/about')}
        color={getTheme().neutral1}
      >
        <Translate id="landing.drawer.aboutUs" />
      </Button>
      <Button
        onClick={() => history.push('/contacts')}
        type="link"
        color={getTheme().neutral1}
      >
        <Translate id="landing.drawer.contacts" />
      </Button>
      {lastAppZone === 'guide' && (
        <>
          <HelpModal
            visible={showingHelp}
            onRequestClose={() => {
              setShowingHelp(false);
            }}
          />
          <Button
            type="link"
            color={getTheme().neutral1}
            onClick={() => setShowingHelp(true)}
          >
            <Translate id="landing.drawer.help" />
          </Button>
        </>
      )}
    </div>
  );
};

export default React.memo(DesktopLinkSection);
