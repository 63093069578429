import message from 'antd/es/message';
import Axios from 'axios';
import { API_URL } from '../../consts';
import { GuideFormProperty } from '../../types/guide.interface';
import { RoleType } from '../../types/role-type.enum';

export interface CreateCompanyDto {
  companyRepresentativeName: string;
  companyName: string;
  companyNIF: string;
  companyEmail: string;
  password: string;
  companyPhoneNumber: number;
  postalCode: string;
  companyLocation: string;
  companyAddress: string;
  type: RoleType;
  ivaExemptionId: string;
  companyIban: string;
}

export const createCompany = (dto: CreateCompanyDto, document: File) => {
  const formData = new FormData();

  formData.append(
    'createCompanyDto',
    JSON.stringify({
      ...dto,
    })
  );
  formData.append('document', document);

  return Axios({
    method: 'post',
    url: `${API_URL}/authentication/sign-up/companies`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(err => {
    if (
      err &&
      err.response &&
      err.response.data &&
      Array.isArray(err.response.data.message)
    ) {
      err.response.data.message.forEach(
        (t: { property: GuideFormProperty }) => {
          message.error(t.property);
        }
      );
    }

    return Promise.reject(err);
  });
};
