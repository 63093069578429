import { FullTheme } from '../../hooks/customization/fetchTheme';
import { GuideStateType } from '../../types/guide-state-type.enum';

export type LastAppZone = 'guide' | 'admin' | 'visitor' | 'company';
export interface AdminStats {
  numberOfRoutesToApprove: number;
  numberOfGuidesToApprove: number;
  numberOfCompaniesToApprove: number;
}

export interface AppState {
  reachedBottom: boolean;
  visitorUnseenNotifications: number;
  guideUnseenNotifications: number;
  lastGuideState: GuideStateType | undefined;
  lastAppZone: LastAppZone;
  theme: FullTheme | undefined;
  count: AdminStats | null;
}

export const SET_REACHED_BOTTOM = 'SET_REACHED_BOTTOM';
export const SET_LAST_GUIDE_STATE = 'SET_LAST_GUIDE_STATE';
export const SET_GUIDE_UNSEEN_NOTIFICATIONS = 'SET_GUIDE_UNSEEN_NOTIFICATIONS';
export const SET_VISITOR_UNSEEN_NOTIFICATIONS =
  'SET_VISITOR_UNSEEN_NOTIFICATIONS';
export const SET_LAST_APP_ZONE = 'SET_LAST_APP_ZONE';
export const SET_THEME = 'SET_THEME';
export const SET_MARKER_COUNT = 'SET_MARKER_COUNT';

export interface SetLastAppZoneAction {
  type: typeof SET_LAST_APP_ZONE;
  payload: LastAppZone;
}

export interface SetReachedBottomAction {
  type: typeof SET_REACHED_BOTTOM;
  payload: boolean;
}

export interface SetGuideUnseenNotificationsAction {
  type: typeof SET_GUIDE_UNSEEN_NOTIFICATIONS;
  payload: number;
}

export interface SetVisitorUnseenNotificationsAction {
  type: typeof SET_VISITOR_UNSEEN_NOTIFICATIONS;
  payload: number;
}

export interface SetLastGuideStateAction {
  type: typeof SET_LAST_GUIDE_STATE;
  payload: GuideStateType;
}

export interface SetThemeAction {
  type: typeof SET_THEME;
  payload: FullTheme;
}
export interface SetMarkerCount {
  type: typeof SET_MARKER_COUNT;
  payload: AdminStats;
}
export type AppActions =
  | SetReachedBottomAction
  | SetGuideUnseenNotificationsAction
  | SetVisitorUnseenNotificationsAction
  | SetLastGuideStateAction
  | SetLastAppZoneAction
  | SetThemeAction
  | SetMarkerCount;
