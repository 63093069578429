import { ResultType } from '../../components/App/RouteSearch/useSearchResults';
import {
  APPEND_RECENT_SEARCH,
  RecentSearchesActions,
  RecentSearchesState,
} from './types';

const INITIAL_STATE: RecentSearchesState = {
  list: [],
};

const RecentSearchesReducer = (
  state: RecentSearchesState = INITIAL_STATE,
  action: RecentSearchesActions
): RecentSearchesState => {
  switch (action.type) {
    case APPEND_RECENT_SEARCH: {
      let previousOccurrence: ResultType | undefined = undefined;

      previousOccurrence = state.list.find(s => {
        if (s.type !== action.payload.type) return false;
        else if (s.type === 'location' && action.payload.type === 'location')
          return s.type === 'location'
            ? s.location.location.lat ===
                action.payload.location.location.lat &&
                s.location.location.lng === action.payload.location.location.lng
            : false;
        else if (s.type === 'route' && action.payload.type === 'route')
          return s.id === action.payload.id;
        else if (s.type === 'subject' && action.payload.type === 'subject')
          return s.id === action.payload.id;
        return false;
      });

      const filteredList = state.list.filter(r => r !== previousOccurrence);

      if (filteredList.length > 4)
        return {
          ...state,
          list: [
            action.payload,
            ...filteredList.slice(state.list.length - 5, state.list.length - 1),
          ],
        };
      else
        return {
          ...state,
          list: [action.payload, ...filteredList],
        };
    }
    default:
      return state;
  }
};

export default RecentSearchesReducer;
