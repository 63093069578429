import { useState, useEffect, SetStateAction } from 'react';
import { API_URL } from '../../consts';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { Administrator } from '../../types/administrator.interface';

type Hook = (
  email: string | undefined,
  setAdmin: (val: SetStateAction<Administrator | null>) => void
) => [boolean, boolean];

const requestAdmin = (email: string) =>
  Axios.get<Administrator>(`${API_URL}/administrator/${email}`);

export const useAdmin: Hook = (email, setAdmin) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!email) {
        setIsError(true);
        setAdmin(null);
        setIsLoading(false);
        return;
      }

      try {
        const { data: admin } = await requestAdmin(email);

        setAdmin(admin);
        setIsError(false);
      } catch (err) {
        setAdmin(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [email, dispatch, setAdmin]);

  return [isLoading, isError];
};
