import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import { useIsMobile } from '../../../utils';
import RouteSearchDesktop from './RouteSearchDesktop';
import RouteSearchMobile from './RouteSearchMobile';
import { useSearchResults } from './useSearchResults';

interface RouteSearchProps extends LocalizeContextProps {
  showBlack?: boolean;
  onSearchStateChange: (isSearching: boolean) => void;
}

const RouteSearch = ({
  translate,
  showBlack,
  onSearchStateChange,
}: RouteSearchProps) => {
  const {
    error,
    filterDto,
    loading,
    results,
    search,
    setSearch,
    setSearching,
    searching,
    recent,
  } = useSearchResults(onSearchStateChange);

  const history = useHistory();

  const isMobile = useIsMobile();

  return !isMobile ? (
    <RouteSearchDesktop
      error={error}
      filterDto={filterDto}
      history={history}
      loading={loading}
      results={results}
      search={search}
      setSearch={setSearch}
      searching={searching}
      setSearching={setSearching}
      showBlack={!!showBlack}
      recent={recent}
      translate={translate}
    />
  ) : (
    <RouteSearchMobile
      error={error}
      recent={recent}
      filterDto={filterDto}
      history={history}
      loading={loading}
      results={results}
      search={search}
      setSearch={setSearch}
      searching={searching}
      setSearching={setSearching}
      showBlack={!!showBlack}
      translate={translate}
    />
  );
};

export default withLocalize(RouteSearch);
